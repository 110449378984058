import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import { renderCellExpand } from '../../DataGridCells/GridCellExpand';

const messages = {
    contractorId: { id: 'app.bankStatement.contractorId' },
    contractorName: { id: 'app.bankStatement.contractorName' },
    address: { id: 'app.bankStatement.address' },
    title: { id: 'app.bankStatement.title' },
    date: { id: 'app.bankStatement.date' },
    incomeAmount: { id: 'app.bankStatement.incomeAmount' },
    withdrawalAmount: { id: 'app.bankStatement.withdrawalAmount' },
    none: { id: 'app.bankStatement.none' }
};

function useColumns({
    renderOptionsCell,
    displayDate
}) {
    const intl = useIntl();

    const isRowIncomplete = (...fields) => ({ row }) => (
        fields.some((field) => !row[field]) && clsx('missing-data')
    );

    return [
        {
            field: 'id',
            headerName: intl.formatMessage({ id: messages.contractorId.id }),
            width: 200,
            headerAlign: 'left',
            cellClassName: isRowIncomplete,
            hide: true
        },
        {
            field: 'contractorName',
            headerName: intl.formatMessage({ id: messages.contractorName.id }),
            flex: 1,
            headerAlign: 'left',
            valueGetter: (params) => params.row.contractorName || params.row.contractorAltName,
            cellClassName: isRowIncomplete('contractorId', 'contractorName'),
            renderCell: renderCellExpand
        },
        {
            field: 'reference',
            headerName: intl.formatMessage({ id: messages.title.id }),
            flex: 1,
            headerAlign: 'left',
            cellClassName: isRowIncomplete('reference'),
            renderCell: renderCellExpand
        },
        {
            field: 'date',
            headerName: intl.formatMessage({ id: messages.date.id }),
            type: 'date',
            headerAlign: 'left',
            width: 150,
            cellClassName: isRowIncomplete('date'),
            valueGetter: ({ value }) => new Date(value),
            renderCell: displayDate
        },
        {
            field: 'amount_deposit',
            headerName: intl.formatMessage({ id: messages.incomeAmount.id }),
            headerAlign: 'left',
            width: 150,
            valueGetter: (params) => (Math.max(params.row.amount, 0) / 100).toFixed(2),
            cellClassName: isRowIncomplete('amount'),
            renderCell: renderCellExpand
        },
        {
            field: 'amout_withdrawal',
            headerName: intl.formatMessage({ id: messages.withdrawalAmount.id }),
            headerAlign: 'left',
            width: 150,
            valueGetter: (params) => (-Math.min(params.row.amount, 0) / 100).toFixed(2),
            cellClassName: isRowIncomplete('amount'),
            renderCell: renderCellExpand
        },
        {
            field: 'options',
            headerName: ' ',
            headerAlign: 'left',
            width: 64,
            renderCell: renderOptionsCell
        }
    ];
}

useColumns.propTypes = {
    renderOptionsCell: PropTypes.func.isRequired
};

export default useColumns;
