import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import formatMoney from '../../../../lib/formatMoney';

const messages = {
    balance: { id: 'app.transactions.balance' }
};

function Item({ title, value }) {
    return (
        <Box>
            {title}
            :&nbsp;
            <Typography variant='span' color={value < 0 ? '#f44336' : ''}>
                {formatMoney(value || 0)}
            </Typography>
        </Box>
    );
}

function BalanceFooterElm({
    totalFirstObj, totalSecondObj, additionalElements, calculateMethod, calculationTranslationId
}) {
    const intl = useIntl();

    return (
        <Box sx={{ display: 'flex', gap: 3 }}>
            {
                additionalElements.map((element) => (
                    <Item key={element.title} title={element.title} value={element.value} />
                ))
            }
            <Item title={totalFirstObj.title} value={totalFirstObj.value} />
            <Item title={totalSecondObj.title} value={totalSecondObj.value} />
            <Item
                title={intl.formatMessage(calculationTranslationId ? { id: calculationTranslationId } : messages.balance)}
                value={calculateMethod(totalFirstObj.value, totalSecondObj.value)}
            />
        </Box>
    );
}

Item.propTypes = {
    title: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired
};

BalanceFooterElm.propTypes = {
    totalFirstObj: PropTypes.object.isRequired,
    totalSecondObj: PropTypes.object.isRequired,
    additionalElements: PropTypes.array,
    calculateMethod: PropTypes.func,
    calculationTranslationId: PropTypes.string
};
BalanceFooterElm.defaultProps = {
    additionalElements: [],
    calculateMethod: (first, second) => first - Math.abs(second),
    calculationTranslationId: null
};

export default BalanceFooterElm;
