import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { TOOLBAR_SELECT } from '../../data/incomesConfig';

const messages = {
    massRemoval: { id: 'app.massRemovalToolbar.massRemoval' }
};

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(1)
    }
}));

function ToolbarNoSelect({ setOption }) {
    const classes = useStyles();
    const intl = useIntl();
    const handleClick = (type) => () => setOption(type);
    return (
        <Button color='primary' size='small' onClick={handleClick(TOOLBAR_SELECT.massRemoval)}>
            <DeleteIcon fontSize='small' className={classes.icon} />
            {intl.formatMessage(messages.massRemoval)}
        </Button>
    );
}

ToolbarNoSelect.propTypes = {
    setOption: PropTypes.func
};

ToolbarNoSelect.defaultProps = {
    setOption: () => {}
};

export default ToolbarNoSelect;
