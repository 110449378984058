import { Buffer } from 'buffer';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import getCurrentDate from '../../../../lib/getCurrentDate';
import GenerateNotificationsDialog from './components/GenerateNotificationsDialog/GenerateNotificationsDialog';

const messages = {
    success: { id: 'app.generateNotifications.success' }
};
interface submitData{
    communities: string[],
    dateOfIncome: string,
    remarks: string,
    sendNotifications: boolean
}

const downloadFile = async (data: Blob, filename = 'zawiadomienie') => {
    const blob = new Blob([data], {
        type: 'application/zip'
    });
    downloadFile(blob, `${filename}-${+new Date()}.zip`);
};
function Notifications() {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [communities, setCommunities] = useState([]);
    const [dateOfIncome, setDateOfIncome] = useState(getCurrentDate);
    const [remarks, setRemarks] = React.useState('');
    const [sendNotifications, setSendNotifications] = useState(false);

    const handleSubmit = async (data: submitData) => {
        const response = (await RestRequestsHelper.generateNotifications(data)).data;
        const zip = new Blob([Buffer.from(response.zip.data)]);
        downloadFile(zip);
        enqueueSnackbar(intl.formatMessage({ id: messages.success.id }), { variant: 'success' });
        history.push('/');
    };

    const handleSendNotificationsChange = (data: boolean): void => {
        setSendNotifications(data);
    };
    return (
        <GenerateNotificationsDialog
            onSubmit={handleSubmit}
            communities={communities}
            onCommunitiesChange={setCommunities}
            remarks={remarks}
            onRemarksChange={setRemarks}
            dateOfIncome={dateOfIncome}
            onDateOfIncomeChange={setDateOfIncome}
            onSendNotificationsChange={handleSendNotificationsChange}
            sendNotifications={sendNotifications}
        />
    );
}

export default Notifications;
