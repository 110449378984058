import { useIntl } from 'react-intl';
import { GridColDef } from '@mui/x-data-grid';
import { renderCellExpand } from '../../../DataGridCells/GridCellExpand';

const messages = {
    infrastructureType: { id: 'app.infrastructures.infrastructureType' },
    name: { id: 'app.payoffs.infrastructureName' },
    ownership: { id: 'app.infrastructures.ownership' },
    owner: { id: 'app.infrastructures.owner' },
    tenant: { id: 'app.infrastructures.tenant' }
};

const useContractorInfrastructuresColumns = () => {
    const intl = useIntl();

    const prepareOwnership = (val: 'owner'|'tenant') => {
        let res: string = val;
        switch (val) {
            case 'owner':
                res = intl.formatMessage(messages.owner);
                break;
            case 'tenant':
                res = intl.formatMessage(messages.tenant);
                break;
            default:
                break;
        }
        return res;
    };

    const col: GridColDef[] = [
        {
            field: 'name',
            headerName: intl.formatMessage(messages.name),
            flex: 1,
            minWidth: 230,
            headerAlign: 'left',
            renderCell: renderCellExpand
        },
        {
            field: 'type',
            headerName: intl.formatMessage(messages.infrastructureType),
            flex: 1,
            minWidth: 230,
            headerAlign: 'left',
            valueGetter: ({ value }) => value?.value,
            renderCell: renderCellExpand
        },
        {
            field: 'ownership',
            headerName: intl.formatMessage(messages.ownership),
            flex: 1,
            headerAlign: 'left',
            valueGetter: ({ value }) => prepareOwnership(value),
            renderCell: renderCellExpand
        }
    ];
    return col;
};

export default useContractorInfrastructuresColumns;
