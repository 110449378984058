import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';
import { Grid, TextField, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import UniversalCombobox, { comboboxTypes } from '../../../../common/Combobox/UniversalCombobox';
import onKeyDetect from '../../../../../lib/onKeyDetect';

const messages = {
    type: { id: 'app.contractors.type' },
    phrase: { id: 'app.contractors.phrase' },
    phraseTooltip: { id: 'app.contractors.phraseFieldTooltip' }
};

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: theme.spacing(1)
    }
}));

function ContractorsFilter({ control }) {
    const intl = useIntl();
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Controller
                        name='type'
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <UniversalCombobox
                                id='type'
                                src={comboboxTypes.contractorTypes()}
                                label={intl.formatMessage({ id: messages.type.id })}
                                multiple
                                onMatch={(option, value) => option?.key === value?.key}
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name='phrase'
                        control={control}
                        defaultValue=''
                        render={({
                            field: {
                                onChange: onChangeField, name, ref
                            }
                        }) => (
                            <Tooltip
                                title={intl.formatMessage({ id: messages.phraseTooltip.id })}
                            >
                                <TextField
                                    label={intl.formatMessage({ id: messages.phrase.id })}
                                    name={name}
                                    inputRef={ref}
                                    fullWidth
                                    onKeyDown={(event) => onKeyDetect(event, () => {
                                        onChangeField(event);
                                    })}
                                />
                            </Tooltip>
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

ContractorsFilter.propTypes = {
    control: PropTypes.object.isRequired
};

export default ContractorsFilter;
