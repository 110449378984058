import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

const messages = {
    invoicePage: { id: 'app.addInvoice.invoicePage' },
    invoiceDelete: { id: 'app.addInvoice.delete' },
    generate: { id: 'app.addIncome.generatePdf' }
};

function DocumentPreviewMenu({
    filesTotal, invoiceIndex, setInvoiceIndex, onInvoiceCancel, manually, onGenerateFile
}) {
    return (
        <Grid item xs={12} sx={{ height: '35px' }}>
            {manually ? (
                <Button onClick={onGenerateFile} variant='outlined'>
                    <FormattedMessage
                        id={messages.generate.id}
                    />
                </Button>
            ) : (
                <>
                    <Button onClick={onInvoiceCancel}>
                        <FormattedMessage
                            id={messages.invoiceDelete.id}
                        />
                    </Button>
                    <Button onClick={() => {
                        if (invoiceIndex > 0) {
                            setInvoiceIndex(invoiceIndex - 1);
                        }
                    }}
                    >
                        {'<'}
                    </Button>

                    <span>
                        <FormattedMessage
                            id={messages.invoicePage.id}
                            values={{
                                invoice: invoiceIndex + 1,
                                invoicesLength: filesTotal
                            }}
                        />
                    </span>
                    <Button onClick={() => {
                        if (invoiceIndex + 1 < filesTotal) {
                            setInvoiceIndex(invoiceIndex + 1);
                        }
                    }}
                    >
                        {'>'}
                    </Button>
                </>
            )}

        </Grid>
    );
}

DocumentPreviewMenu.propTypes = {
    filesTotal: PropTypes.number.isRequired,
    invoiceIndex: PropTypes.number.isRequired,
    setInvoiceIndex: PropTypes.func.isRequired,
    onInvoiceCancel: PropTypes.func.isRequired,
    onGenerateFile: PropTypes.func,
    manually: PropTypes.bool
};

DocumentPreviewMenu.defaultProps = {
    manually: false,
    onGenerateFile: () => {}
};
export default DocumentPreviewMenu;
