import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

const messages = {
    infrastructureType: 'app.infrastructures.infrastructureType'
};
const useStyles = makeStyles(() => ({
    input: {
        width: '100%'
    }
}));

function InfrastructureTypeAutoComplete({
    options,
    name,
    control,
    multiple,
    readOnly
}) {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Controller
            onChange={([, obj]) => obj.key}
            defaultValue={multiple ? [] : null}
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    className={classes.input}
                    value={value}
                    options={options || []}
                    multiple={multiple}
                    getOptionLabel={(option) => {
                        if (options) {
                            const type = options.find((it) => it.key === option);
                            if (type) {
                                return type.value;
                            }
                            return option.value;
                        }
                        return '';
                    }}
                    disabled={readOnly}
                    isOptionEqualToValue={(option, valueData) => option.key === valueData}
                    renderInput={(params) => (
                        <TextField
                            className={classes.input}
                            {...params}
                            fullWidth
                            label={intl.formatMessage({ id: messages.infrastructureType })}
                        />
                    )}
                    onChange={(event, dataObj) => {
                        const data = multiple ? dataObj.map((item) => item?.key || item) : dataObj.key;
                        onChange(data);
                    }}
                />
            )}
        />
    );
}
InfrastructureTypeAutoComplete.propTypes = {
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    multiple: PropTypes.bool,
    readOnly: PropTypes.bool
};

InfrastructureTypeAutoComplete.defaultProps = {
    multiple: false,
    readOnly: false
};

export default InfrastructureTypeAutoComplete;
