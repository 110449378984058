import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import {
    red, blue, grey, green
} from '@mui/material/colors';
import { useIntl } from 'react-intl';
import InfoTooltip from '../../../Combobox/shared/ui/InfoTooltip';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';
import COMBOBOX_INFO from '../../../Combobox/shared/data/comboboxInfo';

const messages = {
    empty: { id: 'app.infrastructures.empty' }
};

const getColor = (type) => {
    switch (type) {
        case 'owner':
            return green[700];
        case 'tenant':
            return blue[700];
        case 'empty':
            return red[600];
        default:
            return grey[600];
    }
};
function DisplayEntities({ entities, type }) {
    return (
        <Typography variant='span' sx={{ color: getColor(type) }}>
            {entities?.map((({ name }) => name)).join(', ') || ''}
        </Typography>
    );
}

function DisplayPremisePeople({ owners, tenants }) {
    const intl = useIntl();
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [contractorData, setContractorData] = useState(null);

    const loadContractorData = async () => {
        if (hoveredItemId) {
            setIsLoadingData(true);
            const { data } = await RestRequestsHelper.getContractorsOnce(null, hoveredItemId);
            setContractorData(data?.[0]);
            setIsLoadingData(false);
        }
    };

    useEffect(() => {
        loadContractorData();
    }, [hoveredItemId]);

    const data = [
        ...tenants.map((elms) => ({ ...elms, type: 'tenant' })),
        ...owners.map((elms) => ({ ...elms, type: 'owner' }))
    ];

    return (
        <Typography variant='span' sx={{ color: grey[600], fontSize: 14 }}>
            {' '}
            (
            <InfoTooltip
                value={contractorData}
                fetcher={COMBOBOX_INFO.contractors}
                show
                loading={isLoadingData}
                multiple={false}
                open
            >
                <Typography variant='span'>
                    {data.map((elm, i) => (
                        <Typography
                            variant='span'
                            sx={{ color: getColor(elm?.type) }}
                            onMouseEnter={() => setHoveredItemId(elm?._id)}
                        >
                            {elm?.name}
                            {i !== data.length - 1 && ', '}
                        </Typography>
                    ))}
                </Typography>
            </InfoTooltip>
            {[...owners, ...tenants].length === 0 && (
                <Typography variant='span' sx={{ color: getColor('empty') }}>
                    {intl.formatMessage({ id: messages.empty.id })}
                </Typography>
            )}
            )
        </Typography>
    );
}

DisplayPremisePeople.propTypes = {
    owners: PropTypes.array.isRequired,
    tenants: PropTypes.array.isRequired
};

DisplayEntities.propTypes = {
    entities: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired
};

export default DisplayPremisePeople;

