import React from 'react';
import PropTypes, {
    bool, func, string, object
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const messages = {
    agree: { id: 'app.confirmDialog.agree' },
    decline: { id: 'app.confirmDialog.decline' }
};

function ConfirmDialog({
    isOpen, handleClose, handleRemove, row, dialogTitle, dialogText, dialogTitleValues, dialogTextValues, isLoading
}) {
    const onAgree = () => {
        handleRemove(row);
        if (isLoading === null) {
            handleClose(false);
        }
    };
    return (
        <Dialog open={isOpen} onClose={() => handleClose(false)}>
            <DialogTitle id='alert-dialog-title'>
                <FormattedMessage id={dialogTitle} values={dialogTitleValues} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    <FormattedMessage id={dialogText} values={dialogTextValues} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} disabled={isLoading}>
                    <FormattedMessage id={messages.decline.id} />
                </Button>
                <LoadingButton color='secondary' onClick={onAgree} loading={isLoading}>
                    <FormattedMessage id={messages.agree.id} />
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
ConfirmDialog.defaultProps = {
    isLoading: null,
    dialogTitleValues: {},
    dialogTextValues: {},
    row: null
};

ConfirmDialog.propTypes = {
    isOpen: bool.isRequired,
    handleClose: func.isRequired,
    handleRemove: func.isRequired,
    dialogTitle: string.isRequired,
    dialogText: string.isRequired,
    row: object,
    isLoading: PropTypes.oneOfType([
        PropTypes.bool,
        null
    ]),
    dialogTitleValues: object,
    dialogTextValues: object
};

export default ConfirmDialog;
