import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl, FormattedMessage } from 'react-intl';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import config from '../../../../config';
import { getToken } from '../../../../lib/restRequestsHelper';
import UniversalCombobox, { comboboxTypes } from '../../../common/Combobox/UniversalCombobox';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        width: '80%',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        marginTop: '20%'
    }
}
));
const messages = {
    download: { id: 'app.reports.media.download' },
    noReadings: { id: 'app.reports.media.noReadings' },
    community: { id: 'app.payoffs.community' },
    title: { id: 'app.reports.media.downloadTitle' },
    alertInfo: { id: 'app.reports.media.downloadInfo' }
};
export const downloadFile = async (data, filename = 'odczyty') => {
    if (!(data instanceof Blob)) return;

    const blob = new Blob([data], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${filename}-${+new Date()}.zip`;
    link.click();
};
const fetchReadings = async (communitiesIds) => {
    const token = getToken();
    const url = `${config.backendHost}:${config.backendPort}/reports/media/readings?communities=${communitiesIds}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    if (res.status === 500) {
        return new Error('No readings found');
    }
    return res.blob();
};
function MediaReadings() {
    const classes = useStyles();
    const history = useHistory();

    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const [communities, setCommunities] = useState([]);

    const [loading, setLoading] = useState(false);
    const loadingFiles = async () => {
        setLoading(true);
        const result = await fetchReadings(communities.map(({ _id }) => _id));
        if (result instanceof Error) {
            enqueueSnackbar(intl.formatMessage({ id: messages.noReadings.id }), { variant: 'error' });
        } else {
            downloadFile(result);
            setCommunities([]);
        }
        setLoading(false);
    };

    const handleClose = () => {
        history.push('/');
    };

    return (
        <Dialog open onClose={handleClose} fullWidth>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <FormattedMessage id={messages.title.id} />
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={handleClose}
                    aria-label='close'
                    className={classes.close}
                    size='large'
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Alert severity='info' sx={{ mb: 2 }}>
                    <FormattedMessage id={messages.alertInfo.id} />
                </Alert>
                <UniversalCombobox
                    id='communities'
                    src={comboboxTypes.communities()}
                    label={intl.formatMessage(messages.community)}
                    multiple
                    value={communities}
                    onChange={(val) => setCommunities(val)}
                    showColors
                    disabled={loading}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant='contained'
                    color='primary'
                    loading={loading}
                    onClick={loadingFiles}
                >
                    {intl.formatMessage({ id: messages.download.id })}
                </LoadingButton>
            </DialogActions>
        </Dialog>

    );
}

export default MediaReadings;
