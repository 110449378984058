import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Container from '@mui/material/Container';
import StepLabel from '@mui/material/StepLabel';
import makeStyles from '@mui/styles/makeStyles';
import PhoneNumberStep from './Steps/PhoneNumberStep';
import SmsCodeStep from './Steps/SmsCodeStep';
import UserFormStep from './Steps/UserFormStep';

const messages = {
    phoneNumberStep: {
        id: 'app.signUpForm.phoneNumber'
    },
    smsCodeStep: {
        id: 'app.signUpForm.smsCode'
    },
    userDataStep: {
        id: 'app.signUpForm.userData'
    }
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    stepContainer: {
        marginTop: theme.spacing(3)
    }
}));

function SignUpPage() {
    const {
        register, getValues, setValue, control
    } = useForm();
    const classes = useStyles();
    const intl = useIntl();
    const [step, setStep] = useState(0);
    const steps = [
        intl.formatMessage({ id: messages.phoneNumberStep.id }),
        intl.formatMessage({ id: messages.smsCodeStep.id }),
        intl.formatMessage({ id: messages.userDataStep.id })
    ];

    const nextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const renderStep = (currentStep) => {
        let stepComponent;
        switch (currentStep) {
            case 0:
                stepComponent = (
                    <PhoneNumberStep
                        register={register}
                        nextStep={nextStep}
                        getValue={getValues}
                        setValue={setValue}
                    />
                );
                break;
            case 1:
                stepComponent = (
                    <SmsCodeStep
                        register={register}
                        nextStep={nextStep}
                        getValue={getValues}
                    />
                );
                break;
            case 2:
                stepComponent = (
                    <UserFormStep
                        register={register}
                        getValue={getValues}
                        control={control}
                    />
                );
                break;
            default:
                stepComponent = '';
                break;
        }
        return stepComponent;
    };

    return (
        <div className={classes.paper}>
            <Container maxWidth='md'>
                <Stepper activeStep={step}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Container>
            <Container className={classes.stepContainer} maxWidth='xs'>
                {renderStep(step)}
            </Container>
        </div>
    );
}

export default SignUpPage;
