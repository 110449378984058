import React from 'react';
import { string, object } from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';

function TrashDatePicker({ label, field }) {
    return (
        <DatePicker
            {...field}
            views={['year', 'month']}
            inputFormat='MMMM YYYY'
            openTo='month'
            label={label}
            disableFuture
            renderInput={(params) => <TextField sx={{ m: 0.5 }} {...params} />}
        />
    );
}

TrashDatePicker.propTypes = {
    label: string.isRequired,
    field: object.isRequired
};

export default TrashDatePicker;
