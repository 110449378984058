import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Fab } from '@mui/material';
import { GridAddIcon } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    addBtnContainer: {
        position: 'fixed',
        zIndex: 10,
        bottom: theme.spacing(10),
        left: theme.spacing(4),
        right: theme.spacing(4),
        textAlign: 'center',
        height: 0
    }
}));

function FixedAddButton({ onClick, redirectTo, hidden }) {
    const classes = useStyles();
    const history = useHistory();

    const handleOnClick = () => {
        onClick();
        if (redirectTo) {
            history.push(redirectTo);
        }
    };

    return (
        <div className={classes.addBtnContainer} hidden={hidden}>
            <Fab aria-label='Add' color='primary' onClick={handleOnClick} id='fixedAddButton'>
                <GridAddIcon />
            </Fab>
        </div>
    );
}

FixedAddButton.propTypes = {
    onClick: PropTypes.func,
    redirectTo: PropTypes.string,
    hidden: PropTypes.bool
};

FixedAddButton.defaultProps = {
    onClick: () => {},
    redirectTo: null,
    hidden: false
};

export default FixedAddButton;
