import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { isEqual } from 'lodash';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';

const messages = {
    group: {
        id: 'app.addInvoice.group'
    }
};

function GroupList({
    value: inputValue, onChange, db, disabled
}) {
    const [values, setValues] = useState([]);
    const [value, setValue] = useState(inputValue);
    const intl = useIntl();
    useEffect(() => {
        RestRequestsHelper.getInvoiceGroups(db)
            .then((result) => {
                setValues(result);
            });
    }, []);
    useEffect(() => {
        if (typeof inputValue === 'number') {
            setValue(values.find((item) => item.id === inputValue));
            return;
        }
        const found = values.find((val) => isEqual(val, inputValue));
        setValue(found ?? '');
    }, [inputValue, values]);

    return (
        <div>
            <Autocomplete
                id='group'
                disableClearable
                value={value}
                options={values}
                onChange={(event, newValue) => {
                    onChange(event, newValue);
                }}
                getOptionLabel={(option) => {
                    if (option) {
                        return `${option.name}`;
                    }
                    return '';
                }}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        /* eslint-disable-next-line */
                        {...params}
                        required
                        label={intl.formatMessage({ id: messages.group.id })}
                        variant='outlined'
                        margin='normal'
                    />
                )}
            />
        </div>
    );
}
GroupList.defaultProps = {
    db: null,
    disabled: false
};
GroupList.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    db: PropTypes.string,
    disabled: PropTypes.bool
};
export default GroupList;
