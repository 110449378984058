import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { FormattedMessage, useIntl } from 'react-intl';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import humanizeDuration from 'humanize-duration';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import dateFormat from '../../../constants/dateFormat';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';

const messages = {
    requestsSettings: {
        id: 'app.settings.requests'
    },
    time: {
        id: 'app.time'
    },
    script: {
        id: 'app.requestScript.type'
    },
    addNew: {
        id: 'app.requestsScript.addNew'
    },
    save: {
        id: 'app.requestsScript.save'
    },
    cancel: {
        id: 'app.requestsScript.cancel'
    },
    successAdding: {
        id: 'app.requestsScript.successAdding'
    },
    successChanging: {
        id: 'app.requestsScript.successChanging'
    },
    active: {
        id: 'app.requestsScript.active'
    },
    startDate: {
        id: 'app.requestsHistory.startDate'
    },
    status: {
        id: 'app.requestsHistory.status'
    },
    apiRequest: {
        id: 'app.requestsHistory.apiRequest'
    },
    downloadedInvoices: {
        id: 'app.requestsHistory.downloadedInvoices'
    },
    ebokResponseTime: {
        id: 'app.requestsHistory.ebokResponseTime'
    },
    loginFailure: {
        id: 'app.requestsHistory.loginFailure'
    },
    processTime: {
        id: 'app.requestsHistory.processTime'
    },
    processedCommunities: {
        id: 'app.requestsHistory.processedCommunities'
    },
    processedContractors: {
        id: 'app.requestsHistory.processedContractors'
    },
    skippedInvoices: {
        id: 'app.requestsHistory.skippedInvoices'
    },
    statusOpen: {
        id: 'app.requestsHistory.statusOpen'
    },
    statusDone: {
        id: 'app.requestsHistory.statusDone'
    },
    statusFail: {
        id: 'app.requestsHistory.statusFail'
    },
    history: {
        id: 'app.requestsHistory.history'
    }
};
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDataGrid-colCellTitle': {
            overflow: 'hidden',
            lineHeight: '150%',
            whiteSpace: 'normal'
        },
        '& .MuiDataGrid-iconButtonContainer': {
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '10px'
        },
        '& .MuiDataGrid-menuIconButton': {
            padding: '1px'
        }
    },
    absolute: {
        position: 'absolute',
        top: theme.spacing(23),
        right: theme.spacing(4)
    },
    main: {
        padding: theme.spacing(2),
        height: '100%'
    },
    table: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '63vh',
        width: '100%'
    }
}));

function RequestsSettings() {
    const classes = useStyles();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [requests, setRequests] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [newSchedule, setNewSchedule] = React.useState({
        script: '',
        time: '20:00'
    });

    const [editId, setEditId] = useState(null);
    const [requestHistory, setRequestHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const locale = useSelector((state) => state.locale);

    const history = useHistory();
    const user = useSelector((state) => state.user);

    const shortLangToHumanize = {
        language: 'short',
        languages: {
            short: {
                d: () => 'd',
                h: () => 'h',
                m: () => 'min',
                s: () => 's'
            }
        },
        delimiter: ' '
    };

    const getValue = ({ field, row }) => {
        const fieldName = field;
        return row.result ? row.result[fieldName] : '';
    };

    const getLocaleDate = ({ row }) => moment(row.createdAt).format(dateFormat.fullDate);

    const recountingProcessTime = ({ row }) => (row.result ? humanizeDuration(row.result.process_time * 1000, { ...shortLangToHumanize, round: true }) : '');

    const recountingResponseTime = ({ row }) => (row.result ? humanizeDuration(row.result.ebok_response_time * 1000, { ...shortLangToHumanize, maxDecimalPoints: 3 }) : '');

    const columns = [
        {
            field: 'createdAt',
            headerName: intl.formatMessage({ id: messages.startDate.id }),
            flex: 1.3,
            headerAlign: 'center',
            valueGetter: getLocaleDate
        },
        {
            field: 'status',
            headerName: intl.formatMessage({ id: messages.status.id }),
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'api_request',
            headerName: intl.formatMessage({ id: messages.apiRequest.id }),
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'downloaded_invoices',
            headerName: intl.formatMessage({ id: messages.downloadedInvoices.id }),
            headerAlign: 'center',
            flex: 0.9,
            valueGetter: getValue
        },
        {
            field: 'ebok_response_time',
            headerName: intl.formatMessage({ id: messages.ebokResponseTime.id }),
            headerAlign: 'center',
            flex: 0.8,
            valueGetter: recountingResponseTime
        },
        {
            field: 'login_failure',
            headerName: intl.formatMessage({ id: messages.loginFailure.id }),
            headerAlign: 'center',
            flex: 0.8,
            valueGetter: getValue
        },
        {
            field: 'process_time',
            headerName: intl.formatMessage({ id: messages.processTime.id }),
            headerAlign: 'center',
            flex: 0.9,
            valueGetter: recountingProcessTime
        },
        {
            field: 'processed_communities',
            headerName: intl.formatMessage({ id: messages.processedCommunities.id }),
            headerAlign: 'center',
            flex: 0.9,
            valueGetter: getValue
        },
        {
            field: 'processed_contractors',
            headerName: intl.formatMessage({ id: messages.processedContractors.id }),
            headerAlign: 'center',
            flex: 0.9,
            valueGetter: getValue
        },
        {
            field: 'skipped_invoices',
            headerName: intl.formatMessage({ id: messages.skippedInvoices.id }),
            headerAlign: 'center',
            flex: 0.9,
            valueGetter: getValue
        }
    ];

    useEffect(() => {
        if (!user) {
            history.push('/');
        } else {
            RestRequestsHelper.getRequests()
                .then((result) => {
                    if (result) {
                        setRequests(result);
                    }
                });
        }
    }, []);

    const statusTranslation = (data) => {
        const copyData = data;
        switch (copyData.status) {
            case 1:
                copyData.status = intl.formatMessage({ id: messages.statusOpen.id });
                break;
            case 2:
                copyData.status = intl.formatMessage({ id: messages.statusDone.id });
                break;
            case 3:
                copyData.status = intl.formatMessage({ id: messages.statusFail.id });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        setLoading(true);
        RestRequestsHelper.getRequestsHistory()
            .then((result) => {
                if (result) {
                    result.forEach((data) => {
                        statusTranslation(data);
                    });
                    setRequestHistory(result);
                    setLoading(false);
                }
            });
    }, [locale]);

    useEffect(() => {
        if (requestHistory.length > 0) {
            setLoading(false);
        }
    }, [requestHistory]);

    useEffect(() => {
        if (open) {
            setEditId(null);
        }
        RestRequestsHelper.getRequests()
            .then((result) => {
                if (result) {
                    setRequests(result);
                }
            });
    }, [open]);

    const editItem = (id) => {
        const req = requests.find((item) => item._id === id);
        setEditId(id);
        setNewSchedule({
            time: req.time,
            script: req.script
        });
    };

    const saveChanges = async (key) => {
        const newTimetable = [...requests];
        const index = newTimetable.findIndex((item) => item._id === key);
        newTimetable[index].time = newSchedule.time;
        newTimetable[index].script = newSchedule.script;

        setRequests(newTimetable);
        if (/^(\d\d:\d\d)$/.test(newSchedule.time)) {
            await RestRequestsHelper.updateRequest(key, newTimetable[index]);
            enqueueSnackbar(intl.formatMessage({ id: messages.successChanging.id }), { variant: 'success' });
            setNewSchedule({
                script: '',
                time: ''
            });
            setEditId(null);
        }
    };

    const deleteRequest = async (key) => {
        const newTimetable = [...requests];
        const index = newTimetable.findIndex((item) => item._id === key);
        newTimetable.splice(index, 1);
        await RestRequestsHelper.deleteRequest(key);
        setRequests(newTimetable);
        enqueueSnackbar(intl.formatMessage({ id: messages.successChanging.id }), { variant: 'success' });
        setEditId(null);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSchedule = async () => {
        if (newSchedule.time !== null) {
            if (/^(\d\d:\d\d)$/.test(newSchedule.time)) {
                await RestRequestsHelper.scheduleRequest(newSchedule);
                handleClose();
                enqueueSnackbar(intl.formatMessage({ id: messages.successAdding.id }), { variant: 'success' });
            }
        }
    };

    const updateForm = (name, value) => {
        if (value !== null) {
            const newForm = { ...newSchedule };
            newForm[name] = value;
            setNewSchedule(newForm);
        }
    };

    const changeActive = async (id, value) => {
        const index = requests.findIndex((item) => item._id === id);
        const newRequest = [...requests];
        newRequest[index].active = value;
        await RestRequestsHelper.changeActiveRequest(id, value);
        setRequests(newRequest);
    };

    return (
        <Paper className={classes.main}>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <FormattedMessage id={messages.addNew.id} />
                </DialogTitle>
                <DialogContent>
                    {/* workaround for padding */}
                    <br />
                    <TextField
                        required
                        label={intl.formatMessage({ id: messages.script.id })}
                        value={newSchedule.script}
                        onChange={(event) => updateForm('script', event.target.value)}
                        sx={{ mr: 1 }}
                    />
                    <TimePicker
                        label={intl.formatMessage({ id: messages.time.id })}
                        mask='__:__'
                        ampm={false}
                        value={moment(`0000-01-01 ${newSchedule.time}`)}
                        onChange={(time) => updateForm('time', time ? time.format('HH:mm') : null)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        <FormattedMessage id={messages.cancel.id} />
                    </Button>
                    <Button onClick={handleSchedule} color='primary'>
                        <FormattedMessage id={messages.save.id} />
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title='Add' disableInteractive>
                <Fab color='primary' className={classes.absolute} onClick={() => setOpen(true)}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Typography variant='h5' align='left'><FormattedMessage id={messages.requestsSettings.id} /></Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                    <FormattedMessage id={messages.script.id} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormattedMessage id={messages.time.id} />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <FormattedMessage id={messages.active.id} />
                </Grid>
                <Grid item xs={12} sm={1} />
                <Grid item xs={12} sm={1} />
                {requests.map((item) => (
                    <React.Fragment key={item._id}>
                        <Grid item xs={12} sm={5}>
                            {editId === item._id
                                ? (
                                    <TextField
                                        required
                                        label={intl.formatMessage({ id: messages.script.id })}
                                        value={newSchedule.script}
                                        onChange={(event) => updateForm('script', event.target.value)}
                                    />
                                )
                                : item.script}

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {editId === item._id
                                ? (
                                    <TimePicker
                                        label={intl.formatMessage({ id: messages.time.id })}
                                        mask='__:__'
                                        ampm={false}
                                        value={moment(`0000-01-01 ${newSchedule.time}`)}
                                        onChange={(time) => updateForm('time', time ? time.format('HH:mm') : null)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                )
                                : item.time}

                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <Checkbox
                                checked={item.active}
                                onChange={(event) => changeActive(item._id, event.target.checked)}
                                name='Active'
                            />

                        </Grid>
                        <Grid item xs={12} sm={1}>
                            {editId === item._id
                                ? (
                                    <IconButton onClick={() => saveChanges(item._id)} size='large'>
                                        <SaveIcon />
                                    </IconButton>
                                )
                                : (
                                    <IconButton onClick={() => editItem(item._id)} size='large'>
                                        <EditIcon />
                                    </IconButton>
                                )}
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <IconButton onClick={() => deleteRequest(item._id)} size='large'>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
            <Divider />
            <Typography variant='h5' align='left'>{intl.formatMessage({ id: messages.history.id })}</Typography>
            <div className={classes.table}>
                <DataGrid
                    className={classes.root}
                    columns={columns}
                    rows={requestHistory}
                    getRowId={(row) => row._id}
                    loading={loading}
                    sortModel={[
                        {
                            field: 'createdAt',
                            sort: 'desc'
                        }
                    ]}
                    columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                />
            </div>
        </Paper>
    );
}

export default RequestsSettings;
