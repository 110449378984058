import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

function AcceptStepButton({ children }) {
    return (
        <Button
            type='submit'
            sx={{ marginTop: '30px' }}
            fullWidth
            variant='contained'
            color='secondary'
        >
            {children}
        </Button>
    );
}

AcceptStepButton.propTypes = {
    children: PropTypes.node.isRequired
};

export default AcceptStepButton;
