import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import requestsMaxLimitValue from '../../../../constants/requests';
import generateFiltersText from '../../../../lib/generateFiltersText';
import CSVExportData from '../../../common/DataGridToolbar/CSVExportData';
import usePageTitle from '../../../common/DataGridToolbar/usePageTitle';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import timeConstants from '../../../../constants/timeConstants';
import formatUTCDate from '../../../../lib/formatUTCDate';
import dataFormat from '../../../../constants/dateFormat';

const messages = {
    concerns: { id: 'app.correspondence.add.concerns' },
    journal: { id: 'app.correspondence.add.journal' },
    contractor: { id: 'app.addInvoice.contractor' },
    startDate: { id: 'app.correspondence.show.startDate' },
    endDate: { id: 'app.correspondence.show.endDate' }
};

function CorrespondenceToolbar({
    searchFiltersState, columns, filtersState
}) {
    const intl = useIntl();
    const [data, setData] = useState();
    const [download, setDownload] = useState(false);
    const [exportFiltersText, setExportFiltersText] = useState('');
    const headers = [];
    columns.map((i) => headers.push({ label: i?.headerName, key: i.field }));

    const getCorrespondence = debounce(async (searchFilters, sortModel, callback) => {
        const result = await RestRequestsHelper.exportCorrespondence(searchFilters, sortModel);
        callback(result);
    }, timeConstants.defaultDebounceDelay);

    const handleClick = () => {
        const searchFilters = searchFiltersState;
        searchFilters.page = 0;
        searchFilters.pageSize = requestsMaxLimitValue;
        getCorrespondence(searchFilters, [{
            field: 'correspondenceDate',
            sort: 'desc'
        }], (result) => {
            const res = result?.data.data;
            res.map((item, i) => {
                res[i].correspondenceDate = formatUTCDate(item?.correspondenceDate, dataFormat.format);
                res[i].type = res[i].type.typ;
                return true;
            });
            setData(res);
        });
    };
    useEffect(() => {
        if (data) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [data]);
    useEffect(() => {
        const _filtersData = [
            {
                text: intl.formatMessage({ id: messages.journal.id }),
                value: filtersState?.journal?.nazwa_kontrahenta
            },
            {
                text: intl.formatMessage({ id: messages.contractor.id }),
                value: filtersState?.contractor?.nazwa_kontrahenta
            },
            {
                text: intl.formatMessage({ id: messages.concerns.id }),
                value: filtersState?.refersTo
            },
            {
                text: intl.formatMessage({ id: messages.startDate.id }),
                value: formatUTCDate(filtersState.startDate, dataFormat.format)
            },
            {
                text: intl.formatMessage({ id: messages.endDate.id }),
                value: formatUTCDate(filtersState.endDate, dataFormat.format)
            }
        ];
        setExportFiltersText(generateFiltersText(_filtersData));
    }, [filtersState]);
    return (
        <GridToolbarContainer>
            <CSVExportData
                onClick={handleClick}
                download={download}
                headers={headers}
                filename={usePageTitle(exportFiltersText)}
                data={data}
            />
        </GridToolbarContainer>
    );
}

CorrespondenceToolbar.propTypes = {
    searchFiltersState: PropTypes.any.isRequired,
    columns: PropTypes.array.isRequired,
    filtersState: PropTypes.array.isRequired
};

export default CorrespondenceToolbar;
