import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { PropTypes } from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import Counters from '../EditInfrastructure/InfrastructureForm/ui/Counters/Counters';
import InfrastructureTypeAutoComplete
    from '../EditInfrastructure/InfrastructureForm/ui/InfrastructureTypeAutoComplete/InfrastructureTypeAutoComplete';
import useInfrastructureTypes from '../hooks/useInfrastructureTypes';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../lib/displaySnackbar';
import SelectedInfrastructureDisplay from '../AddNormsRates/ui/SelectedInfrastructureDisplay';

const messages = {
    addIngredients: { id: 'app.infrastructures.addIngredients' },
    addButton: { id: 'app.menu.add' },
    cancelButton: { id: 'app.menu.cancel' },
    addIngredientsInfo: { id: 'app.infrastructures.addIngredientsInfo' },
    addIngredientsToAllInfo: { id: 'app.infrastructures.addIngredientsToAllInfo' },
    success: { id: 'app.infrastructures.addedIngredient' },
    error: { id: 'app.general.error' }
};

const defaultFormValues = { type_id: [], ingredients: [] };
function AddIngredients({
    open, onClose, parentID, type, selectedNames
}) {
    const intl = useIntl();
    const theme = useTheme();
    const {
        control, register, handleSubmit, reset
    } = useForm({ defaultValues: defaultFormValues });
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ enqueueSnackbar, intl });
    // Get only this types: nieruchomość, budynek, kondygnacja, lokal and show available depend on type of column
    const typesToSearch = [1, 2, 3, 5].filter((item) => item >= type);
    const { types } = useInfrastructureTypes(typesToSearch);

    const handleClose = () => {
        reset(defaultFormValues);
        onClose();
    };
    const handleAdd = async (data) => {
        try {
            const result = await RestRequestsHelper.addIngredientsGlobally({ ...data, parentID });
            if (result.data.success) {
                displaySnackbar('success', messages.success);
                handleClose();
            } else {
                displaySnackbar('error', messages.error);
            }
        } catch (err) {
            displaySnackbar('error', 'err');
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth='lg'
        >
            <form onSubmit={handleSubmit(handleAdd)}>
                <DialogTitle id='form-dialog-title'>
                    {intl.formatMessage(messages.addIngredients)}
                </DialogTitle>
                <DialogContent sx={{ pt: `${theme.spacing(2)} !important` }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SelectedInfrastructureDisplay
                                data={selectedNames}
                                colType={type}
                                addingInfo={intl.formatMessage({ id: messages.addIngredientsInfo.id })}
                                addingAllInfo={intl.formatMessage({ id: messages.addIngredientsToAllInfo.id })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InfrastructureTypeAutoComplete control={control} name='type_id' options={types} multiple />
                        </Grid>
                        <Grid item xs={12}>
                            <Counters
                                control={control}
                                register={register}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={handleClose}
                        color='primary'
                    >
                        <FormattedMessage id={messages.cancelButton.id} />
                    </Button>
                    <Button
                        type='submit'
                        variant='contained'
                        color='secondary'
                    >
                        <FormattedMessage id={messages.addButton.id} />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

AddIngredients.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    parentID: PropTypes.string.isRequired,
    selectedNames: PropTypes.object.isRequired
};

export default AddIngredients;
