import React, { useState, useReducer, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { paginationAllowedValues } from '../../../constants/pagination';
import FixedAddButton from '../../common/FixedAddButton/FixedAddButton';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import { filtersReducer, initialFiltersState } from './reducers';
import SidePanel from './components/SidePanel';
import FiltersComponent from './components/FiltersComponent';
import getCorrespondenceColumns from './CorrespondenceColumns';
import useCorrespondence from './hooks/useCorrespondence';
import { prepareFilters } from './utils/CorrespondenceUtils';
import CorrespondenceToolbar from './components/CorrespondenceToolbar';

const useStyles = makeStyles((theme) => ({
    table: {
        paddingTop: theme.spacing(1),
        height: '70vh',
        width: '95vw',
        margin: 'auto'
    },
    datagrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    }
}));
function CorrespondencePage() {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const [displayDrawer, setDisplayDrawer] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const stateCorrespondence = useSelector((state) => state.correspondence);
    const [files, setFiles] = useState([]);
    const columns = getCorrespondenceColumns(intl);
    const [sortModel, setSortModel] = useState([{
        field: 'correspondenceDate',
        sort: 'desc'
    }]);
    const [filtersState, filtersDispatch] = useReducer(
        filtersReducer,
        initialFiltersState
    );

    const query = new URLSearchParams(window.location.search);
    const id = query.get('id');

    const loadDataById = async () => {
        if (id) {
            const { data } = await RestRequestsHelper.getCorresponenceById(id);
            if (data) {
                setSelectedData(data?.data);
                setDisplayDrawer(true);
            }
        }
    };

    useEffect(() => {
        loadDataById();
    }, [id]);

    useEffect(() => {
        if (stateCorrespondence) {
            setSelectedData(stateCorrespondence);
            setDisplayDrawer(true);
        } else {
            setDisplayDrawer(false);
        }
    }, [stateCorrespondence]);

    const {
        correspondence, count, loading, mutate
    } = useCorrespondence(
        prepareFilters(filtersState),
        filtersState.page,
        filtersState.pageSize,
        sortModel
    );

    const downloadFiles = async (filesArray) => {
        const filesurl = [];
        for (let i = 0; i < filesArray.length; i += 1) {
            const item = filesArray[i];
            const fileData = { name: item.nazwa, family: item.id_rodzina };
            try {
                const resultFile = await RestRequestsHelper.getFile(item.id, 'mongo');
                filesurl.push({ ...fileData, file: resultFile, error: false });
            } catch (err) {
                filesurl.push({
                    ...fileData, file: null, error: err.code !== 550
                });
            }
        }
        setFiles(filesurl);
    };

    const onSelect = ({ row }) => {
        setSelectedData(row);
        setDisplayDrawer(true);
        setFiles([]);
        if (row.illustrations) {
            downloadFiles(row.illustrations);
        } else {
            setFiles([]);
        }
    };

    const handlePaginationModelChange = (newModel) => {
        filtersDispatch({ type: 'paginationModel', value: newModel });
    };

    const handleSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    const closeDrawer = () => {
        setDisplayDrawer(false);
        if (id) {
            query.delete('id');
            history.replace({ pathname: window.location.pathname, search: query.toString() });
        }
    };
    return (
        <div className={classes.table}>
            <SidePanel
                show={displayDrawer}
                onClose={closeDrawer}
                mutate={mutate}
                data={{ files, selectedData }}
            />
            <FiltersComponent filtersState={filtersState} filtersDispatch={filtersDispatch} />
            <FixedAddButton onClick={() => history.push('/correspondence/add')} />
            <DataGrid
                className={classes.datagrid}
                rows={correspondence}
                columns={columns}
                loading={loading}
                paginationMode='server'
                pagination
                paginationModel={{
                    page: filtersState.page,
                    pageSize: filtersState.pageSize
                }}
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={paginationAllowedValues}
                sortingMode='server'
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                onRowClick={onSelect}
                rowCount={count}
                components={{
                    Toolbar: CorrespondenceToolbar
                }}
                componentsProps={{
                    toolbar: {
                        searchFiltersState: prepareFilters(filtersState), columns, filtersState
                    }
                }}
                sx={{ mt: 1 }}
                columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
            />
        </div>
    );
}
export default CorrespondencePage;
