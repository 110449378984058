import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../common/TabPanel/TabPanel';
import FtpSettings from './FtpSettings';
import GlobalSettings from './GlobalSettings';
import RequestsSettings from './RequestsSettings';
import DatabaseSettings from './DatabaseSettings';
import DictionariesSettings from './Dictionaries/DictionariesSettings';

const messages = {
    dbSettings: {
        id: 'app.settings.dbSettings'
    },
    ftpSettings: {
        id: 'app.settings.ftp'
    },
    globalSettings: {
        id: 'app.settings.global'
    },
    requestsSettings: {
        id: 'app.settings.requests'
    },
    dictionaries: {
        id: 'app.settings.dictionaries'
    }
};

function SettingsPage() {
    const intl = useIntl();
    const [tabValue, setTabValue] = useState(0);
    const handleChangeTab = (event, value) => {
        setTabValue(value);
    };
    return (
        <Container maxWidth='100%'>
            <Tabs value={tabValue} onChange={handleChangeTab} indicatorColor='primary' textColor='inherit'>
                <Tab label={intl.formatMessage({ id: messages.globalSettings.id })} value={0} />
                <Tab label={intl.formatMessage({ id: messages.dbSettings.id })} value={1} />
                <Tab label={intl.formatMessage({ id: messages.ftpSettings.id })} value={2} />
                <Tab label={intl.formatMessage({ id: messages.requestsSettings.id })} value={3} />
                <Tab label={intl.formatMessage({ id: messages.dictionaries.id })} value={4} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <GlobalSettings />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <DatabaseSettings />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <FtpSettings />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <RequestsSettings />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
                <DictionariesSettings />
            </TabPanel>
        </Container>
    );
}

export default SettingsPage;
