import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import './DocumentPdf.css';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    controlsContainer: {
        height: 0
    },
    pdfWrapper: {
        overflow: 'scroll'
    },
    pageControls: {
        position: 'relative',
        width: 'max-content',
        left: '50%',
        background: theme.palette.background.default,
        transform: 'translate(-50%, -65px)',
        transition: 'opacity ease-in-out 0.2s',
        boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
        borderRadius: '4px'
    }
}));
const messages = {
    pdfPage: { id: 'app.documentPreview.pdfPage' }
};
function DocumentPdf({
    file, scale, fitImage
}) {
    const style = useStyles();

    const [numOfPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    let element = null;
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        setPageNumber(1);
    }, [file]);
    return (
        <div>
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <div className={style.pdfWrapper}>
                    <Page
                        scale={scale / 100}
                        pageNumber={pageNumber}
                        renderTextLayer
                        inputRef={(ref) => { element = ref; }}
                        onLoadSuccess={() => (element ? fitImage(element.parentElement) : null)}
                    />
                </div>
                <div className={style.controlsContainer}>
                    <div className={style.pageControls}>
                        <Button onClick={() => {
                            if (pageNumber > 1) {
                                setPageNumber(pageNumber - 1);
                            }
                        }}
                        >
                            {'<'}
                        </Button>
                        <span>
                            <FormattedMessage
                                id={messages.pdfPage.id}
                                values={{
                                    page: pageNumber,
                                    pages: numOfPages
                                }}
                            />
                        </span>
                        <Button onClick={() => {
                            if (pageNumber < numOfPages) {
                                setPageNumber(pageNumber + 1);
                            }
                        }}
                        >
                            {'>'}
                        </Button>
                    </div>
                </div>
            </Document>
        </div>
    );
}
DocumentPdf.propTypes = {
    file: PropTypes.object,
    scale: PropTypes.number,
    fitImage: PropTypes.func
};
DocumentPdf.defaultProps = {
    file: undefined,
    scale: 100,
    fitImage: () => {}
};
export default DocumentPdf;
