import React from 'react';
import { makeStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { TOOLBAR_SELECT } from '../../data/invoicesConfig';
import ToolbarMassRemoval from '../../../../../common/DataGridToolbar/ToolbarMassRemoval';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import ToolbarNoSelect from './ToolbarNoSelect';
import ToolbarExportElixir from './ToolbarExportElixir';
import ToolbarChangeFlowState from './ToolbarChangeFlowState';

const useStyles = makeStyles(() => ({
    toolbar: {
        display: 'flex'
    }
}));

function InvoiceCustomToolbar({
    option, setOption,
    invoices, mutateInvoices,
    selectedRows, setSelectedRows,
    exportFiltersText, handleDownloadClick,
    download, dataDownload, headers, filterData, user
}) {
    const classes = useStyles();
    return (
        <div className={classes.toolbar}>
            <GridToolbarContainer>
                {(() => {
                    switch (option) {
                        case TOOLBAR_SELECT.elixir:
                            return (
                                <ToolbarExportElixir
                                    selectedRows={selectedRows}
                                    invoices={invoices}
                                    mutateInvoices={mutateInvoices}
                                    setSelectedRows={setSelectedRows}
                                    setOption={setOption}
                                />
                            );
                        case TOOLBAR_SELECT.changeFlowState:
                            return (
                                <ToolbarChangeFlowState
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setOption={setOption}
                                    invoices={invoices}
                                    mutateInvoices={mutateInvoices}
                                />
                            );
                        case TOOLBAR_SELECT.massRemoval:
                            return (
                                <ToolbarMassRemoval
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setOption={setOption}
                                    mutate={mutateInvoices}
                                    filterData={filterData}
                                    user={user}
                                    defaultOptionId={TOOLBAR_SELECT.noSelect}
                                    deleteRecordFn={(val) => RestRequestsHelper.deleteInvoiceIncome(val)}
                                    deleteRecordsFn={(val) => RestRequestsHelper.deleteInvoicesIncomes(val, 1)}
                                />
                            );
                        case TOOLBAR_SELECT.positionsUngrouped:
                        case TOOLBAR_SELECT.noSelect:
                        default:
                            return (
                                <ToolbarNoSelect
                                    setOption={setOption}
                                    positionsUngrouped={option === TOOLBAR_SELECT.positionsUngrouped}
                                    exportCSVOptions={{
                                        exportFiltersText,
                                        handleDownloadClick,
                                        download,
                                        dataDownload,
                                        headers
                                    }}
                                />
                            );
                    }
                })()}
            </GridToolbarContainer>
        </div>
    );
}

InvoiceCustomToolbar.propTypes = {
    option: PropTypes.number.isRequired,
    setOption: PropTypes.func.isRequired,
    invoices: PropTypes.array.isRequired,
    selectedRows: PropTypes.array.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    mutateInvoices: PropTypes.func.isRequired,
    exportFiltersText: PropTypes.string.isRequired,
    handleDownloadClick: PropTypes.func.isRequired,
    download: PropTypes.bool.isRequired,
    dataDownload: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    filterData: PropTypes.object,
    user: PropTypes.object.isRequired
};

InvoiceCustomToolbar.defaultProps = {
    filterData: {}
};

export default InvoiceCustomToolbar;
