import useSWR from 'swr';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';

const modifyContractors = (contractors) => (
    contractors.map(({
        _id, data, type, ...rest
    }) => ({
        id: _id,
        ...rest,
        ...data,
        ...type,
        type
    }))
);

const useContractors = (filter, page, pageSize) => {
    const { data, error, mutate } = RestRequestsHelper.getContractorsAllData(useSWR, filter, page, pageSize);
    return {
        contractors: modifyContractors(data?.rows || []),
        count: data?.count ?? 0,
        loading: !error && !data,
        mutate
    };
};

export default useContractors;
export { modifyContractors };
