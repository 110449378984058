import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';
import EditInfrastructure from '../../../Infrastructures/EditInfrastructure/EditInfrastructure';
import InfrastructureCard from './components/InfrastructureCard';

const useStyles = makeStyles((theme) => ({
    main: (style) => ({
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        textAlign: 'left',
        display: 'flex',
        height: style.height || '90vh',
        flex: 1,
        alignItems: 'stretch'
    })
}));

const messages = {
    infrastructure: { id: 'app.infrastructures.infrastructure' }
};

function InhabitantInfrastructure() {
    const intl = useIntl();
    const [infrastructure, setInfrastructure] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const styles = useStyles();
    useEffect(() => {
        const getInfrastructure = async () => {
            const response = await RestRequestsHelper.getLoggedInfrastructureData();
            setInfrastructure(response.data.infrastructureObjects);
        };
        getInfrastructure();
    }, []);

    const handleSelectItem = (id) => {
        setSelectedItem(id);
    };

    const handleCloseSidebar = () => {
        setSelectedItem('');
    };

    return (
        <div className={styles.main}>
            <Box sx={{ flexGrow: 1 }} wrap='wrap'>
                <Grid container spacing={2}>
                    { infrastructure.map((infrastructureObject) => (
                        <Grid item xs={6} sm={3} key={infrastructureObject._id}>
                            <InfrastructureCard name={infrastructureObject.name} id={infrastructureObject._id} onClick={handleSelectItem} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <EditInfrastructure
                infrastructureId={selectedItem}
                onClose={handleCloseSidebar}
                open={!!selectedItem}
                drawerHeader={intl.formatMessage({ id: messages.infrastructure.id })}
                readOnly
            />
        </div>
    );
}

export default InhabitantInfrastructure;
