import useSWR from 'swr';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';

// eslint-disable-next-line max-params
const useVindications = (filter, page, pageSize, filtersLoaded, sortModel) => {
    const { data, error, mutate } = RestRequestsHelper.getVindications(useSWR, {
        filter,
        page,
        pageSize,
        filtersLoaded: filter && filtersLoaded,
        sortModel
    });
    return {
        vindications: data?.data ?? [],
        count: data?.count ?? 0,
        loading: !error && !data,
        mutate
    };
};

export default useVindications;
