import React, {
    useReducer, useEffect, useState, forwardRef, useImperativeHandle
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import PropTypes, { bool, element, func } from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHistory } from 'react-router-dom';
import CSVExportData from '../../../../common/DataGridToolbar/CSVExportData';
import usePageTitle from '../../../../common/DataGridToolbar/usePageTitle';
import { paginationAllowedValues } from '../../../../../constants/pagination';
import restRequestsHelper from '../../../../../lib/restRequestsHelper';
import TabPanel from '../../../../common/TabPanel/TabPanel';
import convertColumnsToVisibilityModel from '../../../../../lib/convertColumnsToVisibilityModel';
import useColumns from './hook/useColumns';
import TrashDialog from './ui/TrashDialog';
import uiReducer from './state/ui/reducer';
import uiInitialState from './state/ui/initialState';
import dataReducer from './state/data/reducer';
import dataInitialState from './state/data/initialState';
import TOGGLE_LOADING from './state/ui/types';
import { DATA_FETCHED, SET_TAB_VALUE, TOGGLE_ONLY_CHANGED } from './state/data/types';
import TrashFooter from './ui/TrashFooter';

const messages = {
    getOnlyChanged: {
        id: 'app.reports.onlyChanged'
    },
    onlyChangedTooltip: {
        id: 'app.reports.onlyChangedTooltip'
    }
};

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        width: '80%',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    main: {
        paddingTop: theme.spacing(1),
        height: '80vh',
        margin: 'auto',
        '& .bids': {
            textAlign: 'center'
        },
        '& .bids.first': {
            textAlign: 'left'
        },
        '& .bids.changed': {
            backgroundColor: 'rgba(224, 183, 60, 0.55)',
            color: '#1a3e72',
            fontWeight: '600'
        }
    },
    footer: {
        textAlign: 'right'
    }
}));

function CustomToolbar({ rows, columns }) {
    const [data, setData] = useState();
    const [download, setDownload] = useState(false);
    const headers = [];
    columns.map((i) => headers.push({ label: i?.headerName, key: i.field }));
    const handleClick = () => {
        setData(rows);
    };
    useEffect(() => {
        if (data) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [data]);
    return (
        <GridToolbarContainer>
            <CSVExportData onClick={handleClick} download={download} headers={headers} filename={usePageTitle()} data={data} />
        </GridToolbarContainer>
    );
}

const TrashLayout = forwardRef(({
    isCommon, children, setFields, setRows
}, ref) => {
    const [{ isLoading }, dispatchUiAction] = useReducer(uiReducer, uiInitialState);
    const [{ reportData, onlyChanged, tabValue }, dispatchDataAction] = useReducer(dataReducer, dataInitialState);
    const {
        control, handleSubmit, watch, setValue, getValues
    } = useForm();
    const watchAllFields = watch();
    const columns = useColumns(isCommon, getValues());
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const history = useHistory();
    const summary = reportData[tabValue]?.reduce((acc, item) => acc + Number(item.sum), 0);

    useImperativeHandle(ref, () => ({
        getIsOnlyChanged: () => onlyChanged
    }), [onlyChanged]);

    const getDates = (fields = watchAllFields) => {
        const { firstMonth, secondMonth } = fields;
        const FORMAT_SCHEMA = 'YYYY-MM-DD';
        if (!firstMonth || !secondMonth) {
            return {};
        }
        return {
            firstMonthStart: firstMonth.startOf('month').format(FORMAT_SCHEMA),
            firstMonthEnd: firstMonth.endOf('month').format(FORMAT_SCHEMA),
            secondMonthStart: secondMonth.startOf('month').format(FORMAT_SCHEMA),
            secondMonthEnd: secondMonth.endOf('month').format(FORMAT_SCHEMA)
        };
    };

    useEffect(() => {
        const { firstMonth, secondMonth } = watchAllFields;

        if (firstMonth && secondMonth) {
            if (firstMonth.isAfter(secondMonth)) {
                setValue('firstMonth', secondMonth.subtract(1, 'month'));
            }
        }
    }, [watchAllFields, setValue]);

    const triggerDialog = (shouldExit) => {
        if (shouldExit) {
            history.push('/');
        }
    };

    const onSubmit = async (fields) => {
        dispatchUiAction({ type: TOGGLE_LOADING });
        try {
            const formattedData = getDates(fields);
            const result = isCommon
                ? await restRequestsHelper.getTrashReportAll(formattedData)
                : await restRequestsHelper.getTrashReportIndividual(formattedData);
            setFields(formattedData);
            setRows(result);
            dispatchDataAction({ type: DATA_FETCHED, payload: result });
        } catch (err) {
            enqueueSnackbar('Error while fetching data', { variant: 'error' });
        } finally {
            triggerDialog();
            dispatchUiAction({ type: TOGGLE_LOADING });
        }
    };

    const handleChangeTab = (_, value) => {
        dispatchDataAction({ type: SET_TAB_VALUE, payload: value });
    };

    const handleCheckboxChange = () => {
        dispatchDataAction({ type: TOGGLE_ONLY_CHANGED });
    };

    const getGridData = (name) => {
        const reportedData = reportData[name];
        return onlyChanged ? reportedData.filter((item) => (item.changed)) : reportedData;
    };

    return (
        <div className={classes.rootContainer}>
            {reportData && Object.keys(reportData).length === 0 ? (
                <TrashDialog
                    triggerDialog={triggerDialog}
                    isLoading={isLoading}
                    handleSubmit={handleSubmit(onSubmit)}
                    control={control}
                />
            ) : (
                <>
                    <Tabs value={tabValue} onChange={handleChangeTab} indicatorColor='primary' textColor='inherit'>
                        {Object.keys(reportData).map((item) => (<Tab key={item} label={item} value={item} />))}
                    </Tabs>
                    {Object.keys(reportData).map((item, index) => (
                        reportData[item] && (
                            <TabPanel value={tabValue} index={item} key={index}>
                                <div className={classes.main}>
                                    <DataGrid
                                        rows={getGridData(item)}
                                        columns={columns}
                                        components={{ Toolbar: CustomToolbar, Footer: TrashFooter }}
                                        componentsProps={{ toolbar: { rows: getGridData(item), columns }, footer: { sum: summary } }}
                                        pageSizeOptions={paginationAllowedValues}
                                        columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                                    />
                                    {children}
                                    <div className={classes.footer}>
                                        <Tooltip
                                            title={intl.formatMessage({ id: messages.onlyChangedTooltip.id })}
                                            disableInteractive
                                        >
                                            <FormControlLabel
                                                control={(
                                                    <Checkbox
                                                        checked={onlyChanged}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                )}
                                                label={intl.formatMessage({ id: messages.getOnlyChanged.id })}
                                                labelPlacement='start'
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </TabPanel>
                        )
                    ))}
                </>
            )}
        </div>
    );
});

TrashLayout.propTypes = {
    isCommon: bool,
    children: element,
    setFields: func,
    setRows: func
};

TrashLayout.defaultProps = {
    isCommon: false,
    children: null,
    setFields: () => {},
    setRows: () => {}
};
CustomToolbar.propTypes = {
    rows: PropTypes.any.isRequired,
    columns: PropTypes.array.isRequired
};

export default TrashLayout;
