import useSWR from 'swr';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';

const useInfrastructuresModes = () => {
    const { data, error, mutate } = RestRequestsHelper.getInfrastructuresModes(useSWR);
    return {
        modes: data || [],
        loading: !error && !data,
        mutate
    };
};

export default useInfrastructuresModes;
