import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Grid, TextField, FormGroup, FormControlLabel, Checkbox, Typography, Divider
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { newBankAccountItem } from '../data/newListItems';
import ListItems from '../../../ListItems/ListItems';
import formatBankAccount from '../../../../../lib/formatBankAccount';
import formatInputValue from '../../../../../lib/formatInputValue';
import UniversalCombobox, { comboboxTypes } from '../../../Combobox/UniversalCombobox';

const messages = {
    bankAccounts: { id: 'app.contractors.bankAccounts' },
    name: { id: 'app.contractors.name' },
    type: { id: 'app.contractors.type' },
    bankAccountNumber: { id: 'app.contractors.bankAccountNumber' },
    activeBankAccount: { id: 'app.contractors.activeBankAccount' }
};

const useStyle = makeStyles(() => ({
    checkbox: {
        '& .MuiFormControlLabel-label': {
            fontSize: '0.8rem'
        }
    },
    header: {
        marginTop: 10
    }
}));

function ContractorFormBankAccounts({ control, readOnly }) {
    const classes = useStyle();

    return (
        <Grid item xs={12}>
            <Typography component='h2' variant='h6' className={classes.header} align='left'>
                <FormattedMessage id={messages.bankAccounts.id} />
            </Typography>
            <Divider />
            <Controller
                name='bankAccounts'
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                    <ListItems
                        onNewItem={newBankAccountItem}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        hideActions={readOnly}
                        render={(fields) => (
                            <Grid container>
                                <Grid item xs={2}>
                                    <UniversalCombobox
                                        src={comboboxTypes.bankAccountTypes()}
                                        label={<FormattedMessage {...messages.type} />}
                                        required
                                        {...fields('type')}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        required
                                        label={<FormattedMessage {...messages.name} />}
                                        disabled={readOnly}
                                        {...fields('name')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        required
                                        label={<FormattedMessage {...messages.bankAccountNumber} />}
                                        inputProps={{ pattern: '^[0-9\\s]{32}$' }}
                                        {...fields('number')}
                                        disabled={readOnly}
                                        onChange={(event) => formatInputValue(event, fields('number').onChange, formatBankAccount)}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={(<Checkbox {...fields('active')} checked={fields('active').value} disabled={readOnly} />)}
                                            label={<FormattedMessage {...messages.activeBankAccount} />}
                                            className={classes.checkbox}
                                            labelPlacement='top'
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        )}
                        {...field}
                    />
                )}
            />
        </Grid>
    );
}

ContractorFormBankAccounts.propTypes = {
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool
};

ContractorFormBankAccounts.defaultProps = {
    readOnly: false
};

export default ContractorFormBankAccounts;
