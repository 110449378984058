import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import makeStyles from '@mui/styles/makeStyles';
import { debounce, defaults } from 'lodash';
import {
    DataGrid
} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {
    Box, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useDisplaySnackbar from '../../../../lib/displaySnackbar';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import convertColumnsToVisibilityModel from '../../../../lib/convertColumnsToVisibilityModel';
import CSVExportData from '../../../common/DataGridToolbar/CSVExportData';
import usePageTitle from '../../../common/DataGridToolbar/usePageTitle';
import DictionaryRelationCombobox, { isRelation } from './DictionaryRelationCombobox';
import {
    Position,
    DocumentType,
    Color,
    getDefaultValue,
    convertToType,
    Counter,
    Entity,
    TallyType,
    TallySection,
    Transaction,
    TallySectionLevel,
    Combination, TypeRate, CountedIngredient, Algorithm, Enum,
    DocumentKind
} from './types';
import useColumns from './useColumns';

const messages = {
    dictionaries: { id: 'app.settings.dictionaries' },
    value: { id: 'app.settings.dictionaries.value' },
    key: { id: 'app.settings.dictionaries.key' },
    unit: { id: 'app.settings.dictionaries.unit' },
    changingValue: { id: 'app.settings.dictionaries.changingValue' },
    update: { id: 'app.settings.dictionaries.update' },
    cancel: { id: 'app.settings.dictionaries.cancel' },
    updatedDictionary: { id: 'app.settings.dictionaries.updateNotification' },
    duplicateIDs: { id: 'app.settings.dictionaries.duplicateIDs' },
    color: { id: 'app.settings.dictionaries.color' },
    symbol: { id: 'app.settings.dictionaries.symbol' },
    description: { id: 'app.settings.dictionaries.description' },
    rates: { id: 'app.settings.dictionaries.rates' },
    documentNumber: { id: 'app.settings.dictionaries.documentNumber' },
    costType: { id: 'app.settings.dictionaries.costType' },
    costs_titles: { id: 'app.menu.invoices' },
    income: { id: 'app.menu.income' },
    counter_types: { id: 'app.infrastructures.counterTypes' },
    keywords: { id: 'app.settings.dictionaries.keywords' },
    type: { id: 'app.settings.dictionaries.type' },
    section: { id: 'app.settings.dictionaries.section' },
    name: { id: 'app.settings.dictionaries.name' },
    documentPositionType: { id: 'app.settings.dictionaries.documentPositionType' },
    bankStatementTransactionType: { id: 'app.settings.dictionaries.bankStatementTransactionType' },
    sign: { id: 'app.settings.dictionaries.sign' },
    combinations: { id: 'app.dictionary.combination' },
    advancePayments: { id: 'app.payoffs.advances' },
    algorithm: { id: 'app.dictionary.algorithm' },
    costsTypes: { id: 'app.settings.dictionaries.costType' },
    countedIngredient: { id: 'app.dictionary.countedIngredient' },
    typeRate: { id: 'app.dictionary.typeRate' },
    is_premise_required: { id: 'app.settings.dictionaries.is_premise_required' },
    is_balance: { id: 'app.settings.dictionaries.is_balance' },
    document_kind: { id: 'app.settings.dictionaries.document_kind' },
    sum_position: { id: 'app.settings.dictionaries.sum_position' },
    top: { id: 'app.settings.dictionaries.top' },
    bottom: { id: 'app.settings.dictionaries.bottom' },
    showInPdf: { id: 'app.settings.dictionaries.showInPdf' }
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDataGrid-colCellTitle': {
            overflow: 'hidden',
            lineHeight: '150%',
            whiteSpace: 'normal'
        },
        '& .MuiDataGrid-iconButtonContainer': {
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '10px'
        },
        '& .MuiDataGrid-menuIconButton': {
            padding: '1px'
        }
    },
    absolute: {
        position: 'absolute',
        top: theme.spacing(15),
        right: theme.spacing(4)
    },
    main: {
        padding: theme.spacing(2),
        height: '100%'
    },
    table: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '63vh',
        width: '100%'
    },
    margin: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    noPaddingBottom: {
        paddingBottom: theme.spacing(0)
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto'
    }
}));

const getDictionaryCategories = debounce((callback) => {
    RestRequestsHelper.getDictionaryCategories()
        .then((result) => {
            callback(result);
        });
}, 500);

// All fields that were not specified here will be treated as strings
const getSchemas = (intl) => new Map(Object.entries({
    positionTypes: {
        keywords: [String]
    },
    wasteRecipients: {
        rates: [Number],
        positionTypes: [Position]
    },
    taskStatuses: {
        color: Color
    },
    documentFlowState: {
        color: Color
    },
    documentTypes: {
        is_premise_required: Boolean,
        is_balance: Boolean,
        document_kind: DocumentKind
    },
    mediumTypes: {
        costs_titles: [Position],
        income: [Position],
        counter_types: [Counter]
    },
    counterTypes: {
        costType: Position
    },
    bankTypes: {
        entity: Entity
    },
    tallySections: {
        level: Number,
        children: [TallySectionLevel],
        type: [TallyType],
        sum_position: Enum({
            value: 'top', label: intl.formatMessage(messages.top)
        }, {
            value: 'bottom', label: intl.formatMessage(messages.bottom)
        }),
        showInPdf: Boolean
    },
    tallyPositions: {
        type: [TallyType],
        section: [TallySection],
        documentType: [DocumentType],
        documentPositionType: [Position],
        bankStatementTransactionType: [Transaction],
        sign: Enum({
            value: 'plus', label: 'Plus'
        }, {
            value: 'minus', label: 'Minus'
        }),
        showInPdf: Boolean
    },
    payoffMediumSchemes: {
        combinations: [Combination],
        advancePayments: [Position]
    },
    payoffMediumCombinations: {
        algorithm: Algorithm,
        costsTypes: [Position],
        counterTypes: [Counter]
    },
    payoffMediumAlgorithms: {
        typeRate: TypeRate,
        countedIngredient: CountedIngredient
    }
}));

const getLeanFields = (fields) => (
    fields.map(({ id, ...rest }) => ({
        ...rest
    }))
);

const generateNewField = (fields, schema) => {
    if (fields.length < 1) {
        return null;
    }
    const [field] = getLeanFields(fields);
    const newItem = Object.fromEntries(
        Object.keys(field).map((key) => [key, getDefaultValue(schema?.[key])])
    );
    return newItem;
};

function DictionaryForm({
    fields, selectedRow, control, register, remove
}) {
    const classes = useStyles();
    const intl = useIntl();
    const schemas = getSchemas(intl);
    const schema = schemas.get(selectedRow.category);
    const preparedFields = fields.map((elm) => defaults(elm, generateNewField(fields, schema)));
    return preparedFields.map((field, index) => (
        <div className={classes.margin} key={field.id}>
            <Box sx={{ display: 'flex', gap: 1, '> *': { width: '100%' } }}>
                {Object.entries(field).filter(([key]) => key !== 'id').map(([key, value]) => {
                    const label = messages[key] ? intl.formatMessage({ id: messages[key].id }) : key;
                    const type = schema?.[key] ?? String;
                    switch (true) {
                        case isRelation(type):
                            return (
                                <Controller
                                    key={field.id + key}
                                    control={control}
                                    name={`values.${index}.${key}`}
                                    render={({ field: { onChange } }) => (
                                        <DictionaryRelationCombobox
                                            initialValue={value}
                                            row={field}
                                            onChange={onChange}
                                            type={type}
                                            label={label}
                                        />
                                    )}
                                />
                            );
                        case type.is_enum:
                            return (
                                <FormControl
                                    variant='outlined'
                                    fullWidth
                                    sx={{ minWidth: '100px', '.MuiTextField-root': { height: '100%' }, '.MuiInputBase-root': { height: '100%' } }}
                                >
                                    <InputLabel htmlFor={label}>
                                        {label}
                                    </InputLabel>
                                    <Select
                                        key={field.id + key}
                                        name={`values.${index}.${key}`}
                                        defaultValue={value}
                                        fullWidth
                                        sx={{ minWidth: '150px', '.MuiInputBase-root': { height: '100%' } }}
                                        {...register(`values.${index}.${key}`)}
                                        label={label}
                                    >
                                        {/* eslint-disable-next-line no-shadow */}
                                        {type.options.map(({ value, label }) => (
                                            <MenuItem value={value}>{label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        case type === Boolean:
                            return (
                                <Grid sx={{ textAlign: 'center' }}>
                                    <InputLabel htmlFor={label}>
                                        {label}
                                    </InputLabel>
                                    <FormControl>
                                        <Controller
                                            key={field.id + key}
                                            name={`values.${index}.${key}`}
                                            control={control}
                                            defaultValue={value}
                                            render={({ field: renderField }) => (
                                                <Checkbox
                                                    {...renderField}
                                                    checked={renderField.value}
                                                    onChange={(el) => renderField.onChange(el.target.checked)}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            );
                        default:
                            return (
                                <TextField
                                    name={`values.${index}.${key}`}
                                    defaultValue={value}
                                    fullWidth
                                    sx={{ minWidth: '150px', '.MuiInputBase-root': { height: '100%' } }}
                                    required={key === 'key'}
                                    type={(() => {
                                        if (key === 'key') return 'number';
                                        switch (type) {
                                            case Number:
                                                return 'number';
                                            case Color:
                                                return 'color';
                                            default:
                                                return 'text';
                                        }
                                    })()}
                                    {...register(`values.${index}.${key}`)}
                                    label={label}
                                />
                            );
                    }
                })}
                <IconButton onClick={() => remove(index)} size='large' sx={{ width: 'auto' }}>
                    <DeleteIcon />
                </IconButton>
            </Box>
        </div>
    ));
}

function DictionariesSettings() {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });
    const [dictionaries, setDictionaries] = useState([]);
    const {
        control, register, handleSubmit, getValues
    } = useForm();
    const {
        fields, remove, replace, insert
    } = useFieldArray({
        control,
        name: 'values'
    });

    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [resultsCount, setResultsCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const schemas = getSchemas(intl);
    const schema = schemas.get(selectedRow.category);
    const columns = useColumns();

    const [download, setDownload] = useState(false);
    const [downloadData, setDownloadData] = useState();

    useEffect(() => {
        setLoading(true);
        getDictionaryCategories((result) => {
            setDictionaries(result.categories);
            setResultsCount(result.count);
            setLoading(false);
        });
    }, []);

    const handleClose = () => {
        setOpen(false);
        replace([]);
    };

    const handleRowClick = async ({ row }) => {
        setLoading(true);
        const response = await RestRequestsHelper.getDictionaryValues(row.category, row.subcategory);
        replace(response.values);
        setSelectedRow(row);
        setOpen(true);
        setLoading(false);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const { category } = selectedRow;
        const { subcategory } = selectedRow;
        const values = data.values.map((field) => Object.fromEntries(Object.entries(field).map(([key, value]) => {
            const type = key === 'key' ? Number : schema?.[key] ?? String;
            return [key, convertToType(type, value)];
        })));
        await RestRequestsHelper.updateDictionary({
            category, subcategory, values
        });
        displaySnackbar('success', messages.updatedDictionary);
        setLoading(true);
        setOpen(false);
        getDictionaryCategories((result) => {
            setDictionaries(result.categories);
            setResultsCount(result.count);
            setLoading(false);
        });
        replace([]);
    };

    const handleAdd = () => {
        if (fields.length < 0) throw new Error('Unable to determine schema of dictionary because no values were provided');
        const newItem = generateNewField(fields, schema);
        insert(0, newItem, {
            shouldFocus: true
        });
    };

    const getDictionaryTitle = () => `${selectedRow?.category}${selectedRow?.subcategory ? ` / ${selectedRow.subcategory}` : ''}`;

    const getHeaders = () => {
        if (!fields?.length) {
            return [];
        }
        return Object.keys(generateNewField(fields, schema))
            .map((key) => ({
                label: messages[key] ? intl.formatMessage({ id: messages[key].id }) : key,
                key
            }));
    };

    const handleDownload = () => {
        const result = getValues('values')?.map((row) => Object.fromEntries(
            Object.entries(row).map(([key, value]) => {
                let val = value;

                if (val?.label) {
                    val = val.label;
                } else if (val?.[0]?.label) {
                    val = val?.map((elm) => elm?.label || '')?.join(', ');
                }

                return [key, val || ''];
            })
        ));
        setDownloadData(result);
    };

    useEffect(() => {
        if (downloadData) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [downloadData]);

    return (
        <Paper className={classes.main}>
            <Typography variant='h5' align='left'><FormattedMessage id={messages.dictionaries.id} /></Typography>
            <Dialog open={open} onClose={handleClose} scroll='paper' maxWidth={false} fullWidth>
                <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                    <DialogTitle className={classes.noPaddingBottom}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant='h5' align='left'>
                                <FormattedMessage id={messages.changingValue.id} values={{ val: getDictionaryTitle() }} />
                            </Typography>
                            <IconButton onClick={handleAdd} size='large'>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent dividers>
                        <DictionaryForm
                            fields={fields}
                            selectedRow={selectedRow}
                            control={control}
                            remove={remove}
                            register={register}
                        />
                    </DialogContent>
                    <DialogActions>
                        <CSVExportData
                            onClick={handleDownload}
                            download={download}
                            headers={getHeaders()}
                            filename={usePageTitle(`${intl.formatMessage(messages.dictionaries)} - ${getDictionaryTitle()}`)}
                            data={downloadData}
                            sx={{ mr: 'auto' }}
                        />
                        <Button onClick={handleClose} color='primary'>
                            <FormattedMessage id={messages.cancel.id} />
                        </Button>
                        <Button type='submit' color='primary'>
                            <FormattedMessage id={messages.update.id} />
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <div className={classes.table}>
                <DataGrid
                    className={classes.root}
                    columns={columns}
                    rows={dictionaries}
                    pageSizeOptions=''
                    rowCount={resultsCount}
                    loading={loading}
                    onRowClick={handleRowClick}
                    columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                />
            </div>
        </Paper>
    );
}

export default DictionariesSettings;
