import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import CSVExportData from '../../../../common/DataGridToolbar/CSVExportData';
import convertColumnsToVisibilityModel from '../../../../../lib/convertColumnsToVisibilityModel';

const useStyles = makeStyles(() => ({
    main: {
        width: '90vw',
        height: 'inherit'
    },
    toolbar: {
        display: 'flex'
    }
}));

const messages = {
    number: { id: 'app.payoffs.counterNumber' },
    community: { id: 'app.payoffs.community' },
    premise: { id: 'app.payoffs.premise' },
    owner: { id: 'app.payoffs.owner' },
    usage: { id: 'app.payoffs.usage' },
    rate: { id: 'app.payoffs.rate' },
    cost: { id: 'app.payoffs.cost' },
    sumCost: { id: 'app.payoffs.sumCost' },
    advances: { id: 'app.payoffs.advances' },
    difference: { id: 'app.payoffs.difference' }
};

function CustomToolbar({
    exportFileName, setupHeaders, getDataForExport
}) {
    const [download, setDownload] = useState(false);
    const classes = useStyles();
    return (
        <div className={classes.toolbar}>
            <CSVExportData
                headers={setupHeaders()}
                download={download}
                onClick={() => {
                    setDownload(true);
                    setTimeout(() => {
                        setDownload(false);
                    }, 1000);
                }}
                filename={`${exportFileName}.csv`}
                data={getDataForExport()}
            />
        </div>
    );
}

CustomToolbar.propTypes = {
    exportFileName: PropTypes.string.isRequired,
    setupHeaders: PropTypes.func.isRequired,
    getDataForExport: PropTypes.func.isRequired
};

function ComputeRatesDrawer({
    show, onClose, data, exportFileName
}) {
    const classes = useStyles();
    const intl = useIntl();

    const columns = [
        {
            field: 'community',
            headerName: intl.formatMessage({ id: messages.community.id }),
            flex: 1
        },
        {
            field: 'premise',
            headerName: intl.formatMessage({ id: messages.premise.id }),
            flex: 0.5
        },
        {
            field: 'counterNumber',
            headerName: intl.formatMessage({ id: messages.number.id }),
            flex: 1
        },
        {
            field: 'owner',
            headerName: intl.formatMessage({ id: messages.owner.id }),
            flex: 1
        },
        {
            field: 'sumCost',
            headerName: intl.formatMessage({ id: messages.sumCost.id }),
            flex: 1
        },
        {
            field: 'advances',
            headerName: intl.formatMessage({ id: messages.advances.id }),
            flex: 1
        },
        {
            field: 'advancesDifference',
            headerName: intl.formatMessage({ id: messages.difference.id }),
            flex: 1
        }
    ];
    const getColumns = () => {
        if (data && data.length > 0) {
            const { combinations } = data[0];
            const newColumns = combinations.map((item) => [{
                field: `rate_${item.combinationName}`,
                headerName: intl.formatMessage({ id: messages.rate.id }),
                flex: 0.5

            },
            {
                field: `usage_${item.combinationName}`,
                headerName: item.columnName || intl.formatMessage({ id: messages.usage.id }),
                flex: 1
            },
            {
                field: `cost_${item.combinationName}`,
                headerName: intl.formatMessage({ id: messages.cost.id }),
                flex: 1
            }]).flat();
            const allCols = [...columns];
            allCols.splice(4, 0, ...newColumns);
            return allCols;
        }
        return [];
    };
    const setupHeaders = () => {
        const _headers = [];
        getColumns().forEach((i) => i.field !== 'warnings' && _headers.push({ label: i?.headerName, key: i.field }));
        return _headers;
    };
    const getDataForExport = () => data.map((item) => ({
        ...item
    }));

    const getData = () => {
        if (data && data.length > 0) {
            return data.map((it) => {
                const { combinations } = it;
                const newCols = {};
                combinations.forEach((item) => {
                    newCols[`rate_${item.combinationName}`] = item.rate;
                    newCols[`usage_${item.combinationName}`] = item.usage;
                    newCols[`cost_${item.combinationName}`] = item.cost;
                });
                return {
                    ...it,
                    ...newCols
                };
            });
        }
        return [];
    };
    const columnGrouping = () => {
        if (data && data.length > 0) {
            const { combinations } = data[0];
            return combinations.map((item) => ({
                groupId: item.combinationName,
                children: [{ field: `rate_${item.combinationName}` }, { field: `usage_${item.combinationName}` },
                    { field: `cost_${item.combinationName}` }]
            }));
        }
        return [];
    };
    return (
        <Drawer
            anchor='right'
            open={show}
            onClose={onClose}
        >
            <div className={classes.main}>
                <DataGrid
                    columnGroupingModel={data.length > 0 ? columnGrouping() : undefined}
                    experimentalFeatures={{ columnGrouping: data.length > 0 }}
                    components={{ Toolbar: CustomToolbar }}
                    componentsProps={{ toolbar: { exportFileName, setupHeaders, getDataForExport } }}
                    rows={getData()}
                    columns={getColumns()}
                    columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                />
            </div>

        </Drawer>
    );
}

ComputeRatesDrawer.propTypes = {
    data: PropTypes.array.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    exportFileName: PropTypes.string.isRequired
};

export default ComputeRatesDrawer;
