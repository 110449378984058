import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { FormattedMessage, useIntl } from 'react-intl';
import { clone } from 'lodash';
import Button from '@mui/material/Button';
import { saveAs } from 'file-saver';
import InvoiceForm from '../../../common/InvoiceForm/InvoiceForm';
import FormPickerMaster from '../../../common/FormPicker/FormPickerMaster';
import restRequestsHelper from '../../../../lib/restRequestsHelper';
import { defaultIncomeInvoice } from '../../../common/InvoiceForm/InvoiceDefault';
import { HotkeyProvider } from '../../../common/Hotkeys/Hotkeys';
import log from '../../../../lib/logger';
import DocumentPreview from '../../../common/DocumentPreview/DocumentPreview';
import { responses, statuses } from '../../../../constants/ResponseCodes';
import DocumentPreviewMenu from '../../../common/DocumentPreview/DocumentPreviewMenu/DocumentPreviewMenu';
import closeSnackbarComponent from '../../../common/CustomSnackbars/Utils/closeSnackbarComponent';
import ExcelErrorsSnackbar from '../../../common/CustomSnackbars/ExcelErrorsSnackbar';
import DuplicatedInvoiceDialog from '../../Invoices/AddInvoice/DuplicatedInvoiceDialog/DuplicatedInvoiceDialog';
import useDisplaySnackbar from '../../../../lib/displaySnackbar';
import AddIncomeDialog from './components/AddIncomeDialog/AddIncomeDialog';

const messages = {
    download: { id: 'app.documentPreview.download' },
    successAdding: { id: 'app.importInvoice.success' },
    errorAdding: { id: 'app.importInvoice.error' },
    invoicePage: { id: 'app.addInvoice.invoicePage' },
    notParsedCorrectly: { id: 'app.addInvoice.notParsedCorrectly' },
    invoiceDelete: { id: 'app.addInvoice.delete' },
    alreadyAdded: { id: 'app.addInvoice.alreadyAdded' },
    addingFailure: { id: 'app.addInvoice.addingFailure' },
    docName: { id: 'app.addInvoice.docName' },
    numbers: { id: 'app.addInvoice.numbers' },
    filenameDuplicated: { id: 'app.addInvoice.filenameDuplicated' },
    filesLimitAlmostReached: { id: 'app.addInvoice.filesLimit' },
    ftpInvalidPath: { id: 'app.addInvoice.ftpInvalidPath' },
    ftpInvalidAuth: { id: 'app.addInvoice.ftpInvalidAuth' },
    ftpInvalidAddr: { id: 'app.addInvoice.ftpInvalidAddr' },
    cannotPreparePreview: { id: 'app.addInvoice.cannotPreparePreview' },
    unknownFiletype: { id: 'app.addInvoice.unknownFileType' },
    emptyContent: { id: 'app.addInvoice.emptyContent' },
    documentsNumberInfo: { id: 'app.income.documentsNumberInfo' },
    notGenerated: { id: 'app.income.notGenerated' },
    addedDocuments: { id: 'app.addInvoice.addedDocuments' }
};

function AddIncome() {
    const intl = useIntl();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory();
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });
    const user = useSelector((state) => state.user);
    const [openImportDialog, setOpenImportDialog] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [invoiceFormData, setInvoiceFormData] = useState(defaultIncomeInvoice());
    const [invoiceIndex, setInvoiceIndex] = useState(0);
    const [allIncomes, setAllIncomes] = useState([]);
    const [pdfFile, setPdfFile] = useState(null);
    const [isLoadingFile, setIsLoadingFile] = useState(true);
    const [openDuplicatedDialog, setOpenDuplicatedDialog] = useState(false);
    const [duplicatedInvoiceData, setDuplicatedInvoiceData] = useState(false);

    const downloadFile = () => {
        saveAs(pdfFile, `${invoiceFormData.documentNumber} - ${invoiceFormData.buyer.map((elm) => elm.name).join(', ')}.pdf`);
    };
    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, []);
    const getFile = async (signal) => {
        setIsLoadingFile(true);
        const filePdf = await restRequestsHelper.getIncomeFileView(invoiceFormData, signal);
        setIsLoadingFile(false);
        setPdfFile(filePdf);
    };
    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        if (allIncomes.length > 0 && invoiceFormData) {
            getFile(signal);
        }
        return () => controller.abort();
    }, [invoiceFormData]);

    const wipeIncomeData = () => {
        setInvoiceFormData(defaultIncomeInvoice());
    };

    const addIncomeDirectly = async (communities, dateOfIncome, remarks, documentType) => {
        const result = await restRequestsHelper.addIncomeDirectly(communities, dateOfIncome, remarks, documentType);
        const addedInvoiceNumbers = result.correct;

        if (addedInvoiceNumbers.length > 0) {
            const numbersMsg = intl.formatMessage(messages.numbers);
            enqueueSnackbar(`${intl.formatMessage({ id: messages.successAdding.id })} [${numbersMsg}: ${addedInvoiceNumbers.join(', ')}`, { variant: 'success' });
        }

        if (addedInvoiceNumbers.length === 0 && result.incorrect.length === 0) {
            enqueueSnackbar(`${intl.formatMessage({ id: messages.notGenerated.id })}`, { variant: 'warning' });
        }

        if (result.status === responses.error) {
            enqueueSnackbar(intl.formatMessage({ id: messages.errorAdding.id }), { variant: 'error' });
            setOpenImportDialog(false);
            setInvoiceFormData(defaultIncomeInvoice());
            if (result.incorrect.length > 0) {
                setAllIncomes(result.incorrect);
                setInvoiceFormData(result.incorrect[0]);
                setInvoiceIndex(0);
                setIsLoading(false);
            }
        } else {
            history.push('/');
        }
    };

    useEffect(() => {
        if (allIncomes.length > 0) {
            setInvoiceFormData(allIncomes[invoiceIndex]);
        }
    }, [invoiceIndex, allIncomes]);
    const handleGenerateIncomes = async (data) => {
        setIsLoading(true);
        if (data.verify) {
            setOpenImportDialog(false);
            setInvoiceFormData(defaultIncomeInvoice());
            if (data.communities.length > 0) {
                const response = await restRequestsHelper.getIncomeData(
                    data.communities,
                    data.dateOfIncome,
                    data.documentType.id,
                    data.remarks
                );
                setAllIncomes(response.results);
                if (response.results.length > 0) {
                    setInvoiceFormData(response.results[0]);
                }
                setInvoiceIndex(0);
                setIsLoading(false);
                response.numberOfDocuments.forEach((community) => {
                    enqueueSnackbar(
                        intl.formatMessage(
                            messages.documentsNumberInfo,
                            { name: community.name, numberOfDocuments: community.numberOfDocuments }
                        ),
                        { variant: 'success', autoHideDuration: 60 * 1000, action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar) }
                    );
                });
            }
        } else {
            await addIncomeDirectly(data.communities, data.dateOfIncome, data.remarks, data.documentType.id);
        }
    };

    const addSheetDirectly = async (filesToSave, queryData) => {
        const response = await restRequestsHelper
            .addDocsFromSheetDirectly(filesToSave, queryData);
        if (response.status === 'error') {
            enqueueSnackbar(response.error, { variant: 'error' });
        }

        if (response.status === 'success') {
            if (response.errors) {
                enqueueSnackbar(<ExcelErrorsSnackbar errors={response.errors} />, { variant: 'error', autoHideDuration: 60 * 1000, action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar) });
            }

            if (response.duplicatedData) {
                enqueueSnackbar(<ExcelErrorsSnackbar errors={response.duplicates} />, { variant: 'warning', autoHideDuration: 60 * 1000, action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar) });
                setAllIncomes(response.duplicatedData);
                setInvoiceIndex(0);
                setInvoiceFormData(response.duplicatedData[0]);
                setOpenImportDialog(false);
            }

            if (response.invoiceNumbers) {
                displaySnackbar('success', messages.addedDocuments, { number: response.invoiceNumbers.length });
            }
        }
        setIsLoading(false);
    };

    const handleImportIncomes = async (data) => {
        setIsLoading(true);
        setOpenImportDialog(false);
        const files = Array.from(data.files);
        if (data.verify) {
            setOpenImportDialog(false);
            setInvoiceFormData(defaultIncomeInvoice());

            if (files[0].type.includes('spreadsheet') || files[0].type.includes('numbers')) {
                const filteredFiles = files.filter((file) => file.type.includes('spreadsheet') || file.type.includes('numbers'));
                const response = await restRequestsHelper.getDocumentsFromSheet(
                    filteredFiles,
                    { kind: 2, documentType: data.documentType }
                );
                if (response.error) {
                    enqueueSnackbar(response.error, { variant: 'error' });
                }
                if (response.data?.length > 0) {
                    setAllIncomes(response.data);
                    setInvoiceIndex(0);
                }
                if (response.errors) {
                    enqueueSnackbar(<ExcelErrorsSnackbar errors={response.errors} />, { variant: 'error', autoHideDuration: 60 * 1000, action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar) });
                }
                if (response.duplicates) {
                    enqueueSnackbar(<ExcelErrorsSnackbar errors={response.duplicates} />, { variant: 'error', autoHideDuration: 60 * 1000, action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar) });
                }
                setIsLoading(false);
            }
        } else {
            const filteredFiles = files.filter((file) => file.type.includes('spreadsheet') || file.type.includes('numbers'));
            addSheetDirectly(filteredFiles, { kind: 2, documentType: data.documentType });
        }
    };

    const handleFormChange = (data) => {
        setInvoiceFormData(data);
    };
    const isContentCorrect = (data) => {
        let valid = true;
        data.content.forEach((item) => {
            if (item.title === '' || item.description === '') {
                valid = false;
            }
        });
        return valid;
    };
    const addIncome = async (forceAdd = false) => {
        setIsLoading(true);
        const data = clone(invoiceFormData);

        if (isContentCorrect(data)) {
            const addingResult = await restRequestsHelper.addIncome(data, forceAdd);
            log.info('addingResult', addingResult); // Added only to do not skip build-check

            if (addingResult.status === statuses.alreadyAdded) {
                enqueueSnackbar(intl.formatMessage({ id: messages.alreadyAdded.id }), { variant: 'warning' });
                setOpenDuplicatedDialog(true);
                setDuplicatedInvoiceData(addingResult.foundInvoices[0]);
            } else {
                const successMsg = intl.formatMessage({ id: messages.successAdding.id });
                enqueueSnackbar(
                    successMsg,
                    { variant: 'success', autoHideDuration: 20 * 1000 }
                );
                if (allIncomes.length > 1) {
                    const newIncomes = [...allIncomes];
                    newIncomes.splice(invoiceIndex, 1);
                    setAllIncomes(newIncomes);
                    if (invoiceIndex + 1 > newIncomes.length) {
                        setInvoiceIndex(invoiceIndex - 1);
                    }
                    setInvoiceFormData(newIncomes[invoiceIndex]);
                } else {
                    history.push('/');
                }
            }
        } else {
            enqueueSnackbar(intl.formatMessage({ id: messages.emptyContent.id }), { variant: 'warning' });
        }

        setIsLoading(false);
    };

    const handleSubmitInvoiceForm = (event) => {
        event.preventDefault();
        addIncome();
    };

    const handleForceAdd = () => addIncome(true);

    const handleInvoiceCancel = () => {
        const newIncomes = [...allIncomes];
        newIncomes.splice(invoiceIndex, 1);
        setAllIncomes(newIncomes);
        if (newIncomes.length === 0) {
            wipeIncomeData();
            setOpenImportDialog(true);
            return;
        }
        if (newIncomes.length === invoiceIndex && newIncomes.length > 0) {
            setInvoiceIndex(invoiceIndex - 1);
            return;
        }
        setInvoiceFormData(newIncomes[invoiceIndex]);
    };

    return (
        <div>
            <HotkeyProvider id='invoiceIndex'>
                <FormPickerMaster enable>
                    {openImportDialog}
                    <AddIncomeDialog
                        onGenerate={handleGenerateIncomes}
                        onImport={handleImportIncomes}
                        open={openImportDialog}
                    />
                    <DuplicatedInvoiceDialog
                        open={openDuplicatedDialog}
                        close={() => { setOpenDuplicatedDialog(false); }}
                        invoiceData={duplicatedInvoiceData}
                        forceAdd={handleForceAdd}
                        onCancel={handleInvoiceCancel}
                    />
                    <Grid container>
                        <Grid container item xs={6} style={{ marginTop: '35px' }}>
                            <InvoiceForm
                                incomeForm
                                isLoading={isLoading}
                                onSubmit={handleSubmitInvoiceForm}
                                invoiceFormData={invoiceFormData}
                                onChange={handleFormChange}
                                addingForm
                            />
                        </Grid>
                        <Grid container item xs={6}>

                            <>
                                <DocumentPreviewMenu
                                    onInvoiceCancel={handleInvoiceCancel}
                                    invoiceIndex={invoiceIndex}
                                    setInvoiceIndex={setInvoiceIndex}
                                    filesTotal={allIncomes.length}
                                />
                                {!isLoadingFile && (
                                    <DocumentPreview
                                        topChildren={pdfFile ? (
                                            <Button
                                                onClick={() => downloadFile()}
                                                variant='contained'
                                                color='secondary'
                                            >
                                                <FormattedMessage
                                                    id={messages.download.id}
                                                />
                                            </Button>
                                        ) : null}
                                        file={pdfFile}
                                        filesNumber={allIncomes.length || 1}
                                        fileNumber={invoiceIndex}
                                        setFileNumber={setInvoiceIndex}
                                        onLoadFile={() => {}}
                                        onDeleteFile={() => {}}
                                    />
                                )}
                            </>
                        </Grid>
                    </Grid>
                </FormPickerMaster>
            </HotkeyProvider>
        </div>
    );
}

export default AddIncome;
