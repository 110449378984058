import React, {
    useEffect,
    useContext,
    createContext,
    useState,
    useMemo
} from 'react';
import PropTypes from 'prop-types';

const HotkeyContext = createContext({});
const sealMark = Symbol('hotkey');
const hotkeyProps = {
    on: (phi) => phi,
    off: (phi) => -(phi - 1),
    undef: () => true
};

function createHotkeyEvent(event, id) {
    return {
        id: id ?? '',
        type: sealMark,
        key: event.key,
        ctrl: event.ctrlKey,
        alt: event.altKey,
        shift: event.shiftKey
    };
}

export function createHotkey(keyName, props) {
    return {
        id: props?.id ?? '',
        type: sealMark,
        key: keyName,
        ctrl: props?.ctrl ?? hotkeyProps.undef,
        alt: props?.alt ?? hotkeyProps.undef,
        shift: props?.shift ?? hotkeyProps.undef
    };
}

export function parseHotkey(hotkey) {
    const hotkeyModel = createHotkey('');
    const [id, rest] = hotkey.split(':');
    let hotkeyCode = hotkey;
    if (rest?.length) {
        hotkeyModel.id = id;
        hotkeyCode = rest;
    }
    const tokens = hotkeyCode.replace(/ /g, '').split('+');
    tokens.forEach((token) => {
        const lowerToken = token.toLowerCase();
        switch (lowerToken) {
            case 'ctrl':
                hotkeyModel.ctrl = hotkeyProps.on;
                break;
            case 'alt':
                hotkeyModel.alt = hotkeyProps.on;
                break;
            case 'shift':
                hotkeyModel.shift = hotkeyProps.on;
                break;
            default:
                if (hotkeyModel.key.length) {
                    throw Error('Parsing hotkey failed: Bad hotkey literal. '
                        + 'Make sure you use just one key.');
                }
                hotkeyModel.key = token;
        }
    });
    return hotkeyModel;
}

function validateHotkey(givenHotkey, expectedHotkey, callback) {
    if (givenHotkey === null) return;
    if (givenHotkey?.type !== sealMark) {
        // eslint-disable-next-line no-console
        console.error(`Expected to receive hotkey object. Got: ${givenHotkey.toString()}`);
    }
    if (!expectedHotkey.ctrl(givenHotkey.ctrl)) return;
    if (!expectedHotkey.alt(givenHotkey.alt)) return;
    if (!expectedHotkey.shift(givenHotkey.shift)) return;
    if (expectedHotkey.key !== givenHotkey.key) return;
    callback(givenHotkey);
}

export function useHotkeyListener(expectedHotkey, callback) {
    const expected = (typeof expectedHotkey === 'string')
        ? parseHotkey(expectedHotkey)
        : expectedHotkey;
    const [hot, setHot] = useContext(HotkeyContext);
    useEffect(() => {
        if (hot && (!expectedHotkey.id?.length || expectedHotkey.id === hot.id)) {
            validateHotkey(hot, expected, () => {
                callback();
                setHot(null);
            });
        }
    }, [hot]);
}

export function HotkeyProvider({ children, id }) {
    const [hotkey, setHotkey] = useState(null);
    const hotkeys = useMemo(() => [hotkey, setHotkey], [hotkey]);

    const handleKeydown = (event) => {
        setHotkey(createHotkeyEvent(event), id);
    };

    return (
        <div
            role='button'
            tabIndex={0}
            onKeyDown={handleKeydown}
        >
            <HotkeyContext.Provider value={hotkeys}>
                {children}
            </HotkeyContext.Provider>
        </div>
    );
}

HotkeyProvider.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string
};

HotkeyProvider.defaultProps = {
    id: ''
};

export default {
    createHotkey,
    useHotkeyListener,
    HotkeyProvider
};
