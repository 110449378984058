import React, { useEffect, useState } from 'react';
import { red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import io from 'socket.io-client';
import AppMenu from '../AppMenu/AppMenu';
import { setDatabaseInfo } from '../../../actions';
import config from '../../../config';
import routes from '../../../config/routeTitles';
import { getToken } from '../../../lib/restRequestsHelper';
import useSignOut from '../../../hooks/useSignOut';
import AuthVerify from '../AuthVerify/AuthVerify';
import UserRoles from '../../../constants/UserRoles';
import Articles from '../Articles/Articles';
import LanguageSelection from './LanguageSelection';
import ThemeSelection from './ThemeSelection';
import ReportBug from './ReportBug';
import EnvironmentInformation from './EnvironmentInformation';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    flex: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        width: '45%'
    },
    pageTitle: {
        marginLeft: '1em'
    },
    signInButton: {
        background: '#ae250a'
    },
    link: {
        textDecoration: 'inherit',
        color: 'inherit'
    },
    notProductionAlert: {
        width: '100%',
        background: red[500],
        color: 'black',
        height: '64px',
        paddingTop: '.5em',
        fontSize: '22px'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const messages = {
    signIn: {
        id: 'app.signIn.button',
        defaultMessage: 'Sign In'
    },
    signUp: {
        id: 'app.signUp.button',
        defaultMessage: 'Sign Up'
    },
    signOut: {
        id: 'app.signOut.button',
        defaultMessage: 'Sign Out'
    },
    userLoggedOff: {
        id: 'app.userLoggedOff',
        defaultMessage: 'User logged off'
    },
    dbNotProduction: { id: 'app.db.notProduction' },
    userName: { id: 'app.header.userName' }
};
const getRouteTitle = (path, routesArray) => {
    for (let i = 0; i < routesArray.length; i += 1) {
        if (routesArray[i].path === path) {
            if (routesArray[i].translation) {
                return [routesArray[i].translation];
            }
        }
        if (Array.isArray(routesArray[i].children)) {
            const data = getRouteTitle(path, routesArray[i].children);

            if (data.length > 0) {
                return [routesArray[i].translation].concat(data);
            }
        }
    }
    return [];
};

function Header() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const isProductionDb = useSelector((state) => state.dbInfo.production);
    const signOut = useSignOut(messages.userLoggedOff.id);

    const intl = useIntl();

    const [menuOpen, setMenuOpen] = useState(false);
    const [titles, setTitles] = useState([]);
    const [socket, setSocket] = useState(null);
    useEffect(() => {
        if (user) {
            const newSocket = io(`${config.backendHost}:${config.backendPort}`, {
                query: { token: getToken() }
            });
            newSocket.on('isProductionDb', (data) => {
                dispatch(setDatabaseInfo({ production: data }));
            });
            setSocket(newSocket);
        }
    }, [user]);
    useEffect(() => {
        if (socket) {
            socket.on('isProductionDb', (data) => {
                dispatch(setDatabaseInfo({ production: data }));
            });
        }
    }, [socket]);
    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    const location = useLocation();

    useEffect(
        () => {
            const data = getRouteTitle(location.pathname, routes);
            let preparedTitles = [];
            if (data.length > 2) {
                const lastElements = [data[data.length - 2], data[data.length - 1]];
                preparedTitles = lastElements;
            } else {
                preparedTitles = data;
            }
            setTitles(preparedTitles.map((elm) => intl.formatMessage({ id: elm })).join(' -> '));
        },
        [location]
    );

    useEffect(() => {
        if (titles) {
            document.title = titles;
        } else {
            document.title = 'SYSTEM PLUS';
        }
    }, [titles]);

    return (
        <div id='app-navbar' className={classes.root}>
            <AppBar position='static' enableColorOnDark>
                <Toolbar className={classes.toolbar}>
                    <Box className={classes.flex}>
                        {(user && user.role !== UserRoles.inhabitant) && (
                            <IconButton
                                color='inherit'
                                aria-label='open drawer'
                                onClick={handleMenuOpen}
                                // className={clsx(classes.menuButton, open && classes.hide)}
                                edge='start'
                                size='large'
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        <Typography variant='h6'>
                            <Link className={classes.link} to='/'>System Plus</Link>
                        </Typography>
                        <Typography variant='subtitle1' className={classes.pageTitle}>
                            {titles}
                        </Typography>
                    </Box>
                    {user && (
                        <>
                            <AuthVerify />
                            <Articles />
                        </>
                    )}
                    <Box className={classes.flex} sx={{ justifyContent: 'flex-end', gap: 0.5 }}>
                        {user && (
                            <FormattedMessage
                                id={messages.userName.id}
                                values={{ username: user.username }}
                            />
                        )}
                        <EnvironmentInformation />
                        <ReportBug />
                        <LanguageSelection />
                        <ThemeSelection />
                        {user ? (
                            <Button disableElevation variant='contained' color='primary' onClick={() => signOut()}>
                                <FormattedMessage
                                    id={messages.signOut.id}
                                    defaultMessage={messages.signOut.defaultMessage}
                                />
                            </Button>
                        ) : (
                            <ButtonGroup disableElevation variant='contained'>
                                <Button color='secondary' onClick={() => { history.push('/signUp'); }}>
                                    <FormattedMessage
                                        id={messages.signUp.id}
                                        defaultMessage={messages.signUp.defaultMessage}
                                    />
                                </Button>
                                <Button color='primary' className={classes.signInButton} onClick={() => { history.push('/signIn'); }}>
                                    <FormattedMessage
                                        id={messages.signIn.id}
                                        defaultMessage={messages.signIn.defaultMessage}
                                    />
                                </Button>
                            </ButtonGroup>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <AppMenu open={menuOpen} onClose={handleMenuClose} />
            {!isProductionDb && user && (
                <div className={classes.notProductionAlert}>
                    <FormattedMessage
                        id={messages.dbNotProduction.id}
                    />
                </div>
            )}
        </div>
    );
}

export default Header;
