const KIND = {
    both: 0,
    incomming: 1,
    outcomming: 2
};

const KIND_NAME = {
    both: 'both',
    incomming: 'incomming',
    outcomming: 'outcomming'
};

export default KIND;
export {
    KIND,
    KIND_NAME
};
