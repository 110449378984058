import TOGGLE_LOADING from './types';

export default (state, action) => {
    switch (action.type) {
        case TOGGLE_LOADING:
            return { ...state, isLoading: !state.isLoading };
        default:
            return state;
    }
};
