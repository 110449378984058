import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

const messages = {
    saveChanges: { id: 'app.contractors.saveChanges' },
    clear: { id: 'app.contractors.clearButton' },
    addContractor: { id: 'app.contractors.addContractor' }
};

const useStyle = makeStyles((theme) => ({
    contractorFormFooter: {
        marginTop: 10,
        bottom: theme.spacing(1),
        zIndex: 1,
        position: 'sticky'
    }
}));

function ContractorFormFooter({ isSubmiting, clearData, edit }) {
    const classes = useStyle();
    return (
        <Grid container className={classes.contractorFormFooter}>
            <Grid item xs={6}>
                <Button
                    variant='contained'
                    color='secondary'
                    fullWidth
                    onClick={clearData}
                >
                    <FormattedMessage id={messages.clear.id} />
                </Button>
            </Grid>
            <Grid item xs={6}>
                <LoadingButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    loading={isSubmiting}
                    loadingPosition='start'
                >
                    <FormattedMessage id={edit ? messages.saveChanges.id : messages.addContractor.id} />
                </LoadingButton>
            </Grid>
        </Grid>
    );
}

ContractorFormFooter.propTypes = {
    isSubmiting: PropTypes.bool.isRequired,
    clearData: PropTypes.func.isRequired,
    edit: PropTypes.object.isRequired
};

export default ContractorFormFooter;
