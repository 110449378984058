import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import { FormattedMessage, useIntl } from 'react-intl';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import formatInputValue from '../../../../lib/formatInputValue';
import formatPhoneNumber from '../../../../lib/formatPhoneNumber';
import AcceptStepButton from './AcceptStepButton/AcceptStepButton';
import getErrorMessage from './ErrorsMessages/getErrorMessage';

const messages = {
    phoneNumber: {
        id: 'app.signUpForm.phoneNumber'
    },
    phoneNumberError: {
        id: 'app.signUpError.phoneNumberError'
    },
    nextStep: {
        id: 'app.signUpForm.nextStep'
    }
};

const validatePhoneNumber = (phone) => {
    const pattern = /^\d{9}|\d{3}-|\s\d{3}-|\s\d{3}$/;
    return pattern.test(phone);
};

function PhoneNumberStep({
    register, nextStep, getValue, setValue
}) {
    const [error, setError] = useState('');
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const clearPhoneNumber = (phone) => phone.replace(/-|\s/g, '');

    const acceptPhoneNumber = async () => {
        const phoneNumber = getValue('phoneNumber');
        if (phoneNumber) {
            const isValid = validatePhoneNumber(phoneNumber);
            if (!isValid) {
                setError(intl.formatMessage({ id: messages.phoneNumberError.id }));
                return;
            }
            setValue('username', clearPhoneNumber(phoneNumber));
            const response = await RestRequestsHelper.validatePhoneNumber(phoneNumber);
            if (response?.user) {
                nextStep();
            } else {
                const errorCode = response?.error?.code;
                enqueueSnackbar(intl.formatMessage({ id: getErrorMessage(errorCode) }), { variant: 'error' });
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        acceptPhoneNumber();
    };

    const onChange = (event) => {
        setValue('phoneNumber', event.target.value);
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                type='text'
                variant='outlined'
                margin='normal'
                required
                inputProps={{ pattern: '^[0-9]{3}\\s[0-9]{3}\\s[0-9]{3}$' }}
                fullWidth
                id='phoneNumber'
                label={intl.formatMessage({ id: messages.phoneNumber.id })}
                name='phoneNumber'
                autoFocus
                error={!!error}
                helperText={error}
                {...register('phoneNumber', {
                    onChange: (event) => {
                        if (error) {
                            setError('');
                        }
                        formatInputValue(event, onChange, formatPhoneNumber);
                    }
                })}
            />
            <AcceptStepButton>
                <FormattedMessage id={messages.nextStep.id} />
            </AcceptStepButton>
        </form>
    );
}

PhoneNumberStep.propTypes = {
    register: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired
};

export default PhoneNumberStep;
