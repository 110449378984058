import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import { userSignedIn } from '../../../actions';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

const messages = {
    signIn: {
        id: 'app.signIn.button',
        defaultMessage: 'Sign In'
    },
    password: {
        id: 'app.signInForm.password',
        defaultMessage: 'password'
    },
    username: {
        id: 'app.signInForm.username',
        defaultMessage: 'username'
    },
    rememberMe: {
        id: 'app.signInForm.rememberMe',
        defaultMessage: 'Remember me'
    },
    title: {
        id: 'app.signInForm.title',
        defaultMessage: 'Sign In'
    },
    loginFailure: {
        id: 'app.signInForm.loginFailure',
        defaultMessage: 'Login failed, please check your credentials'
    }
};

function SignInPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const [credentials, setCredentials] = useState({
        username: '',
        password: ''
    });
    const [error, setError] = useState(false);

    const [rememberMe, setRememberMe] = useState(false);

    const signIn = (event) => {
        event.preventDefault();
        RestRequestsHelper.signIn({ remember: rememberMe, ...credentials })
            .then((data) => {
                if (data && data.error) {
                    enqueueSnackbar(intl.formatMessage(messages.loginFailure), { variant: 'error' });
                    setError(true);
                } else {
                    localStorage.setItem('token', data.accessToken);
                    dispatch(userSignedIn(jwtDecode(data.accessToken)));
                    history.push('/');
                }
            });
    };

    const updateForm = (event) => {
        const { name, value } = event.target;
        setError(false);
        setCredentials((item) => {
            const newStateValue = { ...item };
            newStateValue[name] = value;
            return newStateValue;
        });
    };

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    <FormattedMessage
                        id={messages.title.id}
                        defaultMessage={messages.title.defaultMessage}
                    />
                </Typography>
                <form className={classes.form} onSubmit={signIn}>
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='username'
                        label={(
                            <FormattedMessage
                                id={messages.username.id}
                                defaultMessage={messages.username.defaultMessage}
                            />
                        )}
                        name='username'
                        autoComplete='username'
                        autoFocus
                        value={credentials.username}
                        onChange={updateForm}
                        error={error}
                    />
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label={(
                            <FormattedMessage
                                id={messages.password.id}
                                defaultMessage={messages.password.defaultMessage}
                            />
                        )}
                        type='password'
                        id='password'
                        autoComplete='current-password'
                        value={credentials.password}
                        onChange={updateForm}
                        error={error}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={rememberMe} onChange={() => { setRememberMe(!rememberMe); }} />}
                        label={(
                            <FormattedMessage
                                id={messages.rememberMe.id}
                                defaultMessage={messages.rememberMe.defaultMessage}
                            />
                        )}
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='secondary'
                        className={classes.submit}
                    >
                        <FormattedMessage
                            id={messages.signIn.id}
                            defaultMessage={messages.signIn.defaultMessage}
                        />
                    </Button>
                </form>
            </div>
        </Container>
    );
}

export default SignInPage;
