const initialState = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'pl';
// eslint-disable-next-line import/prefer-default-export
export const locale = (state = initialState, action = {}) => {
    switch (action.type) {
        case 'LANG_EN':
            return 'en';
        case 'LANG_PL':
            return 'pl';
        default:
            return state;
    }
};
