import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Chip, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import useSignOut from '../../../hooks/useSignOut';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import { userSignedIn } from '../../../actions';

const messages = {
    jwtExpired: {
        id: 'app.jwtExpired',
        defaultMessage: 'Token has expired. You have been logged out'
    },
    refreshSession: { id: 'app.header.refreshSession' }
};

function AuthVerify() {
    const intl = useIntl();
    const dispatch = useDispatch();

    const signOut = useSignOut(messages.jwtExpired.id);
    const [expirationTime, setExpirationTime] = useState(null);
    const [secs, setSecs] = useState(null);
    const [timeLeft, setTimeLeft] = useState('');
    const getSecondsDiff = (startDate, endDate) => {
        const msInSecond = 1000;
        return Math.round(
            Math.abs(endDate - startDate) / msInSecond
        );
    };
    const check = () => {
        if (localStorage.getItem('token')) {
            try {
                const token = jwtDecode(localStorage.getItem('token'));
                if (token.exp * 1000 < Date.now()) {
                    signOut();
                } else if (token.expirationType === 'SHORT') {
                    setExpirationTime(token.exp);
                    setSecs(getSecondsDiff(
                        new Date(),
                        new Date(token.exp * 1000)
                    ));
                } else {
                    setExpirationTime(null);
                    setSecs(null);
                }
            } catch {
                signOut();
            }
        }
    };
    useEffect(() => {
        check();
    }, []);
    useEffect(() => {
        let interval;
        if (expirationTime) {
            setTimeLeft(new Date(secs * 1000).toISOString().slice(14, 19));
            interval = setInterval(() => {
                setSecs(secs - 1);
                if (secs < 1) {
                    signOut();
                    clearInterval(interval);
                }
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [secs]);

    const refreshSession = async () => {
        const response = await RestRequestsHelper.refreshToken();
        if (response) {
            localStorage.setItem('token', response.accessToken);
            const token = jwtDecode(response.accessToken);
            dispatch(userSignedIn(token));
            setExpirationTime(token.exp);
            setSecs(getSecondsDiff(
                new Date(),
                new Date(token.exp * 1000)
            ));
        }
    };

    return expirationTime && (
        <Tooltip title={intl.formatMessage(messages.refreshSession)}>
            <Chip
                label={timeLeft}
                sx={{ ml: 3, cursor: 'pointer' }}
                onClick={refreshSession}
            />
        </Tooltip>
    );
}
export default withRouter(AuthVerify);
