import formatUTCDate from '../../../../lib/formatUTCDate';

// eslint-disable-next-line import/prefer-default-export
export const prepareFilters = (filtersState) => {
    const searchFilters = { };

    if (filtersState.number) {
        searchFilters.number = filtersState.number;
    }
    if (filtersState.journal && filtersState.journal._id) {
        searchFilters.journal = filtersState.journal._id;
    }
    if (filtersState.contractor) {
        searchFilters.contractor = filtersState.contractor?.map((elm) => elm?._id);
    }

    if (filtersState.concerns) {
        searchFilters.concerns = filtersState.concerns;
    }

    if (filtersState.startDate) {
        searchFilters.startDate = formatUTCDate(filtersState.startDate);
    }
    if (filtersState.endDate) {
        searchFilters.endDate = formatUTCDate(filtersState.endDate);
    }

    return searchFilters;
};
