import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, TextField } from '@mui/material';
import { PropTypes } from 'prop-types';
import { Controller } from 'react-hook-form';
import UniversalCombobox, { comboboxTypes } from '../../../Combobox/UniversalCombobox';
import ContractorFormBuyers from './ContractorFormBuyers';

const messages = {
    firstName: { id: 'app.contractors.firstName' },
    lastName: { id: 'app.contractors.lastName' },
    name: { id: 'app.contractors.name' },
    type: { id: 'app.contractors.type' },
    user: { id: 'app.contractors.user' },
    nip: { id: 'app.contractors.nip' },
    regon: { id: 'app.contractors.regon' },
    pesel: { id: 'app.contractors.pesel' },
    idCard: { id: 'app.contractors.idCard' },
    krs: { id: 'app.contractors.krs' }
};

function ContractorFormHeader({ control, readOnly }) {
    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Controller
                    name='type'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <UniversalCombobox
                            src={comboboxTypes.contractorTypes()}
                            label={<FormattedMessage {...messages.type} />}
                            variant='outlined'
                            fullWidth
                            required
                            disabled={readOnly}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={8}>
                <Controller
                    name='name'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='outlined'
                            fullWidth
                            required
                            label={<FormattedMessage {...messages.name} />}
                            disabled={readOnly}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name='firstName'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='outlined'
                            fullWidth
                            label={<FormattedMessage {...messages.firstName} />}
                            disabled={readOnly}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={8}>
                <Controller
                    name='lastName'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <TextField
                            variant='outlined'
                            fullWidth
                            label={<FormattedMessage {...messages.lastName} />}
                            disabled={readOnly}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name='user'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <UniversalCombobox
                            src={comboboxTypes.users()}
                            variant='outlined'
                            fullWidth
                            label={<FormattedMessage {...messages.user} />}
                            disabled={readOnly}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name='identity_card_number'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <TextField
                            variant='outlined'
                            fullWidth
                            label={<FormattedMessage {...messages.idCard} />}
                            disabled={readOnly}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name='regon'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <TextField
                            variant='outlined'
                            fullWidth
                            label={<FormattedMessage {...messages.regon} />}
                            disabled={readOnly}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name='nip'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <TextField
                            variant='outlined'
                            fullWidth
                            label={<FormattedMessage {...messages.nip} />}
                            disabled={readOnly}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name='pesel'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <TextField
                            variant='outlined'
                            fullWidth
                            label={<FormattedMessage {...messages.pesel} />}
                            disabled={readOnly}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name='krs'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                        <TextField
                            variant='outlined'
                            fullWidth
                            label={<FormattedMessage {...messages.krs} />}
                            disabled={readOnly}
                            {...field}
                        />
                    )}
                />
            </Grid>
            <ContractorFormBuyers control={control} readOnly={readOnly} />
        </Grid>
    );
}

ContractorFormHeader.propTypes = {
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool
};

ContractorFormHeader.defaultProps = {
    readOnly: false
};

export default ContractorFormHeader;
