import { useIntl } from 'react-intl';
import routes from '../../../config/routeTitles';

const getRouteTitle = (path, routesArray) => {
    for (let i = 0; i < routesArray.length; i += 1) {
        if (routesArray[i].path === path) {
            if (routesArray[i].translation) {
                return [routesArray[i].translation];
            }
        }
        if (Array.isArray(routesArray[i].children)) {
            const data = getRouteTitle(path, routesArray[i].children);

            if (data.length > 0) {
                return [routesArray[i].translation].concat(data);
            }
        }
    }
    return [];
};
// generate page path text for csv export file name
const usePageTitle = (additionalText) => {
    const intl = useIntl();
    const data = getRouteTitle(window.location.pathname, routes);
    let titles;
    if (data.length > 2) {
        titles = [data[data.length - 2], data[data.length - 1]];
    } else {
        titles = data;
    }
    let toReturn = '';
    titles.map((item, index) => {
        toReturn += `${intl.formatMessage({ id: item })}${index + 1 < titles.length ? ' - ' : ''}`;
        return true;
    });
    if (additionalText) {
        toReturn += ` - ${additionalText}`;
    }
    toReturn += '.csv';
    return toReturn;
};
export default usePageTitle;
