import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { FormattedMessage, useIntl } from 'react-intl';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { debounce, cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import useSWR from 'swr';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import CustomDatePicker from '../../common/CustomDatePicker/CustomDatePicker';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import { parseFormattedMoney } from '../../common/DataGridCells/GridMoneyCell';
import MoneyTextField from '../../common/MoneyTextField/MoneyTextField';
import formatMoney from '../../../lib/formatMoney';
import generateFiltersText from '../../../lib/generateFiltersText';
import UniversalCombobox, { comboboxTypes } from '../../common/Combobox/UniversalCombobox';
import { paginationDefaultValue, paginationAllowedValues } from '../../../constants/pagination';
import InvoicePositionsTitlesCombobox from '../../common/Combobox/InvoicePositionsTitlesCombobox';
import dateFormat from '../../../constants/dateFormat';
import FixedAddButton from '../../common/FixedAddButton/FixedAddButton';
import DataGridFooter from '../../common/DataGridFooter/DataGridFooter';
import onKeyDetect from '../../../lib/onKeyDetect';
import { setIncome, updateFileDownloadingState } from '../../../actions';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';
import AddSelectDialog from '../../common/AddDialog/AddSelectDialog';
import formatUTCDate from '../../../lib/formatUTCDate';
import validateDate from '../../../lib/validateDate';
import { defaultFilter, TOOLBAR_SELECT } from './shared/data/incomesConfig';
import IncomeCustomToolbar from './shared/ui/IncomeCustomToolbar/IncomeCustomToolbar';
import SidePanel from './shared/ui/SidePanel';
import useColumns from './shared/hooks/useColumns';

const messages = {
    documentNumber: { id: 'app.addInvoice.documentNumber' },
    amountFrom: { id: 'app.invoices.amountFrom' },
    amountTo: { id: 'app.invoices.amountTo' },
    buyer: { id: 'app.invoices.buyer' },
    seller: { id: 'app.invoices.seller' },
    groupFilter: { id: 'app.invoices.groupFilter' },
    flowStatus: { id: 'app.invoices.flowStatus' },
    dateOkButton: { id: 'app.invoiceForm.OkButton' },
    dateCancelButton: { id: 'app.invoiceForm.CancelButton' },
    dateTodayButton: { id: 'app.invoiceForm.todayButton' },
    cancel: { id: 'app.invoices.cancel' },
    single: { id: 'app.addInvoice.content.single' },
    description: { id: 'app.addInvoice.description' },
    saleDate: { id: 'app.addInvoice.saleDate' },
    invoiceDate: { id: 'app.invoices.invoiceDate' },
    paymentDate: { id: 'app.invoices.payementDate' },
    filterDatesBy: { id: 'app.invoices.filterDatesBy' },
    dateFrom: { id: 'app.invoices.dateFrom' },
    dateTo: { id: 'app.invoices.dateTo' },
    premise: { id: 'app.payoffs.premise' },
    import: { id: 'app.addSelectDialog.import' },
    add: { id: 'app.addSelectDialog.add' },
    addDialog: { id: 'app.incomes.addDialog' },
    documentType: { id: 'app.form.documentType' }
};

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '80vh'
    },
    warningIcon: {
        verticalAlign: 'middle'
    },
    warningIconOrange: {
        fill: 'orange'
    },
    warningIconRed: {
        fill: 'red'
    },
    warningPopup: {
        padding: theme.spacing(1),
        margin: theme.spacing(1)
    },
    datagrid: (props) => ({
        marginTop: props.datagridMarginTop,
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    })
}));

// eslint-disable-next-line max-params
const useIncomes = (filterData, page, pageSize, sortModel) => {
    const { data, error, mutate } = RestRequestsHelper.getIncomes(useSWR, filterData, page, pageSize, sortModel);
    return {
        incomes: data?.rows ?? [],
        rowsCount: data?.count ?? 0,
        loading: !error && !data,
        mutate
    };
};

const useSum = (filterData) => {
    const { data } = RestRequestsHelper.getIncomesSum(useSWR, filterData);
    const sum = data && data.total ? data.total : 0;
    return { sum };
};

function Incomes({ buyerFilter, inhabitantView }) {
    const query = new URLSearchParams(window.location.search);
    const queryFilter = JSON.parse(query.get('filter') ?? '{}');
    const intl = useIntl();
    const locale = useSelector((state) => state.locale);
    dayjs.locale(locale);

    const dispatch = useDispatch();
    const theme = useTheme();
    const datagridMarginTop = theme.spacing(3);
    const classes = useStyles({ datagridMarginTop });
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(paginationDefaultValue);
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const [filterData, setFilterData] = useState(defaultFilter(queryFilter));
    const [exportFiltersText, setExportFiltersText] = useState('');
    const [height, setHeight] = useState();

    const [selectedRows, setSelectedRows] = useState([]);
    const [toolbarSelect, setToolbarSelect] = useState(TOOLBAR_SELECT.noSelect);

    const [openAddSelectDialog, setOpenAddSelectDialog] = useState(false);
    const id = query.get('id');

    useEffect(() => {
        if (id) {
            dispatch(setIncome(id));
        }
    }, [id]);

    const handleOnClose = () => {
        dispatch(setIncome(null));
        if (id) {
            query.delete('id');
            history.replace({ pathname: window.location.pathname, search: query.toString() });
        }
    };

    const generateFiles = (params) => {
        dispatch(updateFileDownloadingState(true));
        RestRequestsHelper.generateManyIncomeFiles(params.map((item) => item.id))
            .then((result) => {
                const csvURL = window.URL.createObjectURL(result.data);
                const tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', 'incomes.zip');
                tempLink.click();
                dispatch(updateFileDownloadingState(false));
            });
    };

    const handleGenerateFile = (params) => {
        dispatch(updateFileDownloadingState(true, params.id));
        RestRequestsHelper.generateFile(params.id)
            .then((result) => {
                const csvURL = window.URL.createObjectURL(result.data);
                const tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', `${params.row.buyer.map((elm) => elm.name).join(', ')}.pdf`);
                tempLink.click();

                dispatch(updateFileDownloadingState(false));
            });
    };

    const [sortModel, setSortModel] = useState([
        { field: 'payment_date', sort: 'desc' }
    ]);
    const {
        incomes, loading,
        rowsCount, mutate
    } = useIncomes(filterData, page, pageSize, sortModel);

    const handleUpdate = () => mutate();

    const columns = useColumns({
        filterData,
        handleGenerateFile
    });
    const { sum } = useSum(filterData);

    const handleRowSelected = (params) => {
        setSelectedRows(params);
    };

    const showCheckboxes = (
        toolbarSelect === TOOLBAR_SELECT.massRemoval
    );

    const handleSortModelChange = (newModel) => {
        if (JSON.stringify(newModel) !== JSON.stringify(sortModel)) {
            setSortModel(newModel);
        }
    };

    const [dataDownload, setDataDownload] = useState();
    const [download, setDownload] = useState(false);

    const [headers, setHeaders] = useState([]);

    const disableHidding = true;
    const fullColumns = useColumns({
        filterData,
        handleUpdate,
        handleGenerateFile,
        disableHidding
    });
    const setupHeaders = () => {
        const _headers = [];
        fullColumns.map((i) => !i.disableExport && _headers.push({ label: i?.headerName, key: i.field }));
        setHeaders(_headers);
    };
    const handleDownloadClick = async () => {
        const response = await RestRequestsHelper.getAllIncomes(filterData);
        const res = structuredClone(response?.data?.rows);
        res.map((item, i) => {
            res[i].buyer = item?.buyer.map((elm) => elm.name).join(', ');
            res[i].seller = item?.seller.map((elm) => elm.name).join(', ');
            res[i].total_gross_charges = formatMoney(Math.round(item?.total_gross_charges) / 100);
            res[i].exposure_date = formatUTCDate(item?.exposure_date, dateFormat.format);
            res[i].sell_date = formatUTCDate(item?.sell_date, dateFormat.format);
            res[i].payment_date = formatUTCDate(item?.payment_date, dateFormat.format);
            res[i].state = item?.state.value;
            return true;
        });
        setDataDownload(res);
    };
    useEffect(() => {
        if (dataDownload) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [dataDownload]);

    const dateFilterOptions = ['saleDate', 'invoiceDate', 'paymentDate'];

    useEffect(() => {
        const _filtersData = [
            {
                text: intl.formatMessage({ id: messages.documentNumber.id }),
                value: filterData?.documentNumber?.value
            },
            {
                text: intl.formatMessage({ id: messages.buyer.id }),
                value: filterData?.buyer?.value?.name
            },
            {
                text: intl.formatMessage({ id: messages.seller.id }),
                value: filterData?.seller?.value?.name
            },
            {
                text: intl.formatMessage({ id: messages.filterDatesBy.id }),
                value: intl.formatMessage(messages[filterData?.dateFilter?.value])
            },
            {
                text: intl.formatMessage({ id: messages.dateFrom.id }),
                value: formatUTCDate(filterData.dateFrom.value, dateFormat.format)
            },
            {
                text: intl.formatMessage({ id: messages.dateTo.id }),
                value: formatUTCDate(filterData.dateTo.value, dateFormat.format)
            },
            {
                text: intl.formatMessage({ id: messages.flowStatus.id }),
                value: filterData?.status?.value
            },
            {
                text: intl.formatMessage({ id: messages.single.id }),
                value: filterData?.position?.value
            },
            {
                text: intl.formatMessage({ id: messages.description.id }),
                value: filterData?.description?.value
            },
            {
                text: intl.formatMessage({ id: messages.amountFrom.id }),
                value: formatMoney(parseInt(filterData.amountMinimum.value) / 100, false)
            },
            {
                text: intl.formatMessage({ id: messages.amountTo.id }),
                value: formatMoney(parseInt(filterData.amountMaximum.value) / 100, false)
            },
            {
                text: intl.formatMessage({ id: messages.premise.id }),
                value: filterData?.premise?.name
            }
        ];
        setExportFiltersText(generateFiltersText(_filtersData));
    }, [filterData]);
    useEffect(() => {
        setupHeaders();
    }, [filterData.groupPositions.value]);

    useEffect(() => {
        const offsetTop = document.getElementById('incomes-datagrid-container') !== null ? (parseInt(document.getElementById('incomes-datagrid-container').offsetTop) + parseInt(datagridMarginTop)) : 320;
        setHeight(String(offsetTop));
    }, []);

    const filterClearedAttr = (name, value) => {
        switch (name) {
            case 'documentNumber':
                return value !== '';
            case 'buyer':
                return value && typeof value === 'object' && value.length;
            case 'seller':
                return value && typeof value === 'object' && value.length;
            case 'dateFilter':
                return value !== null;
            case 'dateFrom':
                return value !== null && validateDate(value);
            case 'dateTo':
                return value !== null && validateDate(value);
            case 'status':
                return value && typeof value === 'object' && value.length;
            case 'position':
                return value && typeof value === 'object' && value.length;
            case 'groupPositions':
                return true;
            case 'description':
                return value !== '';
            case 'amountMinimum':
                return typeof value === 'number';
            case 'amountMaximum':
                return typeof value === 'number';
            case 'invoiceType':
                return value && typeof value === 'object' && value.length;
            case 'premise':
                return value && typeof value === 'object';
            default:
                return false;
        }
    };

    const setFilterAttr = debounce((name, value) => {
        if (name === 'groupPositions') {
            setToolbarSelect(value ? TOOLBAR_SELECT.noSelect : TOOLBAR_SELECT.positionsUngrouped);
        }
        setFilterData((newFilterData) => {
            const data = cloneDeep(newFilterData);
            data[name] = {
                value,
                filter: filterClearedAttr(name, value)
            };
            return data;
        });
    }, 500);

    useEffect(() => {
        if (buyerFilter?._id) {
            setFilterAttr('buyer', buyerFilter);
        }
    }, [buyerFilter]);

    const getFilterAttrValue = (name) => (
        filterData[name].value
    );

    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, []);

    const onSelect = ({ field, row }) => {
        if (field !== 'additionalOptions' && !showCheckboxes) {
            dispatch(setIncome(row?.id));
        }
    };

    const handlePaginationModelChange = (data) => {
        setPageSize(data.pageSize);
        setPage(data.page);
    };

    const handleGenerateIncomeFiles = () => {
        generateFiles(incomes);
    };
    return (
        <div className={classes.main}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <TextField
                        variant='outlined'
                        margin='normal'
                        label={intl.formatMessage({ id: messages.documentNumber.id })}
                        fullWidth
                        onKeyDown={(event) => onKeyDetect(event, () => setFilterAttr('documentNumber', event.target.value))}
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        id='buyer'
                        margin='normal'
                        src={comboboxTypes.contractors()}
                        label={intl.formatMessage({ id: messages.buyer.id })}
                        onChange={(value) => setFilterAttr('buyer', value)}
                        value={getFilterAttrValue('buyer')}
                        disabled={inhabitantView}
                        showColors
                        multiple
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        margin='normal'
                        id='seller'
                        src={comboboxTypes.contractors()}
                        label={intl.formatMessage({ id: messages.seller.id })}
                        onChange={(value) => setFilterAttr('seller', value)}
                        value={getFilterAttrValue('seller')}
                        showColors
                        multiple
                        onMatch={(option, value) => option?._id === value?._id}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        variant='outlined'
                        id='dateFilter'
                        options={dateFilterOptions}
                        getOptionLabel={(option) => intl.formatMessage(messages[option]) || option}
                        value={getFilterAttrValue('dateFilter')}
                        onChange={(_event, value) => setFilterAttr('dateFilter', value)}
                        fullWidth
                        disableClearable
                        renderInput={(params) => <TextField {...params} label={intl.formatMessage(messages.filterDatesBy)} variant='outlined' margin='normal' />}
                    />
                </Grid>
                <Grid item xs={1}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        name='dateFrom'
                        id='dateFrom'
                        toolbarTitle='Od'
                        label={intl.formatMessage({ id: messages.dateFrom.id })}
                        showTodayButton
                        value={getFilterAttrValue('dateFrom')}
                        onChange={(date) => setFilterAttr('dateFrom', date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth margin='normal' {...params} />}
                    />
                </Grid>
                <Grid item xs={1}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        name='dateTo'
                        id='dateTo'
                        label={intl.formatMessage({ id: messages.dateTo.id })}
                        showTodayButton
                        value={getFilterAttrValue('dateTo')}
                        onChange={(date) => setFilterAttr('dateTo', date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth margin='normal' {...params} />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        id='status'
                        optionLabel={(option) => option.name}
                        showColors
                        multiple
                        onMatch={(option, value) => option.id === value.id}
                        onChange={(value) => {
                            setFilterAttr('status', value);
                        }}
                        value={getFilterAttrValue('status')}
                        src={comboboxTypes.documentFlowStatuses()}
                        label={intl.formatMessage(messages.flowStatus)}
                        margin='normal'
                    />
                </Grid>
                <Grid item xs={2}>
                    <InvoicePositionsTitlesCombobox
                        invoicesBrowseRender
                        value={getFilterAttrValue('position')}
                        multiple
                        onChange={
                            (event, newValue) => {
                                setFilterAttr('position', newValue);
                            }
                        }
                        db='mongo'
                    />
                </Grid>
                <Grid item xs={1}>
                    <Stack
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Typography variant='body2'>
                            <FormattedMessage id={messages.groupFilter.id} />
                        </Typography>
                        <Switch
                            checked={getFilterAttrValue('groupPositions')}
                            onChange={() => setFilterAttr('groupPositions', !getFilterAttrValue('groupPositions'))}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        variant='outlined'
                        label={intl.formatMessage({ id: messages.description.id })}
                        fullWidth
                        onKeyDown={(event) => onKeyDetect(event, () => setFilterAttr('description', event.target.value))}
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        id='premise'
                        margin='default'
                        src={comboboxTypes.premises()}
                        label={intl.formatMessage({ id: messages.premise.id })}
                        onChange={(value) => setFilterAttr('premise', value)}
                        value={getFilterAttrValue('premise')}
                    />
                </Grid>
                <Grid item xs={1}>
                    <MoneyTextField
                        onChange={(value) => setFilterAttr('amountMinimum', value.length ? 100 * parseFormattedMoney(value) : null)}
                        onKeyDown={(event) => onKeyDetect(event, () => {
                            const { value } = event.target;
                            setFilterAttr('amountMinimum', value.length ? 100 * parseFormattedMoney(value) : null);
                        })}
                        textFieldParams={{
                            label: intl.formatMessage(messages.amountFrom),
                            variant: 'outlined',
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <MoneyTextField
                        onChange={(value) => setFilterAttr('amountMaximum', value.length ? 100 * parseFormattedMoney(value) : null)}
                        onKeyDown={(event) => onKeyDetect(event, () => {
                            const { value } = event.target;
                            setFilterAttr('amountMaximum', value.length ? 100 * parseFormattedMoney(value) : null);
                        })}
                        textFieldParams={{
                            label: intl.formatMessage(messages.amountTo),
                            variant: 'outlined',
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        id='invoiceType'
                        multiple
                        onChange={(val) => setFilterAttr('invoiceType', val)}
                        value={getFilterAttrValue('invoiceType')}
                        src={comboboxTypes.documentTypes('income')}
                        label={intl.formatMessage(messages.documentType)}
                    />
                </Grid>
            </Grid>
            <SidePanel
                onUpdate={handleUpdate}
                onClose={handleOnClose}
                readOnly={inhabitantView}
                mutate={mutate}
            />
            <div id='incomes-datagrid-container' style={{ height: `calc(100vh - ${height}px)` }}>
                <FixedAddButton onClick={() => setOpenAddSelectDialog(true)} />
                <AddSelectDialog
                    open={openAddSelectDialog}
                    onClose={() => setOpenAddSelectDialog(false)}
                    titleTranslationId={messages.addDialog.id}
                    options={[
                        {
                            translationId: messages.add.id,
                            redirectTo: '/income/addManually'
                        },
                        {
                            translationId: messages.import.id,
                            redirectTo: '/income/add'
                        }
                    ]}
                />
                <DataGrid
                    className={classes.datagrid}
                    paginationMode='server'
                    rows={incomes}
                    columns={columns}
                    getRowId={(row) => row.uniqueID || row.id}
                    pageSizeOptions={paginationAllowedValues}
                    rowCount={rowsCount}
                    onCellClick={onSelect}
                    loading={loading}
                    paginationModel={{ page, pageSize }}
                    onPaginationModelChange={handlePaginationModelChange}
                    checkboxSelection={showCheckboxes}
                    onRowSelectionModelChange={handleRowSelected}
                    sortingMode='server'
                    rowSelectionModel={selectedRows}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    components={{
                        Toolbar: IncomeCustomToolbar,
                        Footer: DataGridFooter
                    }}
                    componentsProps={{
                        toolbar: {
                            exportFiltersText,
                            handleDownloadClick,
                            download,
                            dataDownload,
                            headers,
                            option: toolbarSelect,
                            setOption: setToolbarSelect,
                            mutate,
                            selectedRows,
                            setSelectedRows,
                            filterData,
                            user,
                            handleGenerateIncomeFiles
                        },
                        footer: { sum }
                    }}
                    columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                />
            </div>
        </div>
    );
}

Incomes.propTypes = {
    buyerFilter: PropTypes.object,
    inhabitantView: PropTypes.bool
};

Incomes.defaultProps = {
    buyerFilter: {},
    inhabitantView: false
};

export default Incomes;
