import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import { setInspection, setTask } from '../../../actions/index';
import dateFormat from '../../../constants/dateFormat';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import UniversalCombobox, { comboboxTypes } from '../Combobox/UniversalCombobox';
import ListItems from '../ListItems/ListItems';
import { HotkeyProvider } from '../Hotkeys/Hotkeys';
import FormPickerMaster from '../FormPicker/FormPickerMaster';
import FormFileManager, { prepareFilesForUpload, prepareRemarkFilesForUpload } from '../FormFileManager/FormFileManager';
import useDisplaySnackbar from '../../../lib/displaySnackbar';
import userRoles from '../../../constants/UserRoles';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';
import { useSubTasksColumns } from '../TaskForm/shared/hooks/useColumns';
import FormHeader from '../FormHeader/FormHeader';
import useSubInspectionsColumns from './hooks/useColumns';
import defaultInspectionFormValues, { defaultRemarks, defaultReminder } from './defaultInspectionFormValues';

const useStyle = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        '& .MuiGrid-item': {
            padding: '4px'
        }
    },
    select: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    selectItem: {
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(1)
    },
    preview: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    divider: {
        marginTop: '10px'
    },
    toolTip: {
        padding: theme.spacing(0.5)
    },
    metadata: {
        color: theme.palette.grey[500],
        float: 'left',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    loading: {
        alignSelf: 'center',
        marginTop: 1
    },
    buttons: {
        bottom: theme.spacing(1),
        zIndex: 1,
        position: 'sticky',
        marginTop: 'auto'
    },
    accordionSummary: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

const messages = {
    addInspection: { id: 'app.addInspection.add' },
    editInspection: { id: 'app.addInspection.edit' },
    saveChanges: { id: 'app.addInspection.saveChanges' },
    concerns: { id: 'app.addInspection.concerns' },
    filterByConcernsHint: { id: 'app.inspections.filterByConcernsHint' },
    leader: { id: 'app.addInspection.leader' },
    producer: { id: 'app.addInspection.producer' },
    validTo: { id: 'app.addInspection.validTo' },
    remarks: { id: 'app.addInspection.remarks' },
    reminder: { id: 'app.addInspection.reminder' },
    reminderEntity: { id: 'app.addInspection.reminderEntity' },
    reminderContact: { id: 'app.addInspection.reminderContact' },
    clear: { id: 'app.addInspection.clear' },
    confirm: { id: 'app.addInspection.confirm' },
    cancel: { id: 'app.addInspection.cancel' },
    today: { id: 'app.addInspection.today' },
    name: { id: 'app.addInspection.name' },
    parentInspection: { id: 'app.addInspection.parentInspection' },
    inhabitantParentInspectionrror: { id: 'app.addInspection.inhabitantParentInspectionError' },
    subinspections: { id: 'app.addInspection.subinspections' },
    type: { id: 'app.inspections.type' },
    dateOkButton: { id: 'app.addInspection.okButton' },
    dateCancelButton: { id: 'app.addInspection.cancelButton' },
    dateTodayButton: { id: 'app.addInspection.todayButton' },
    dateClearButton: { id: 'app.addInspection.clearButton' },
    addInspectionDate: { id: 'app.inspections.addInspectionDate' },
    editInspectionDate: { id: 'app.inspections.editInspectionDate' },
    author: { id: 'app.inspections.author' },
    updatedAt: { id: 'app.inspections.updatedAt' },
    file: { id: 'app.addInspection.file' },
    downloadFile: { id: 'app.addInspection.downloadFile' },
    documentName: { id: 'app.addInspection.documentName' },
    reminderNow: { id: 'app.inspections.reminderNow' },
    reminderNowHint: { id: 'app.inspections.reminderNowHint' },
    correspondenceType: { id: 'app.correspondence.add.type' },
    assignedTasks: { id: 'app.inspections.assignedTasks' },
    addTask: { id: 'app.inspections.addTask' }
};

function InspectionForm({
    onSubmit, onDelete, filterByConcerns, readOnly, inhabitantMode, applicantFilter
}) {
    const classes = useStyle();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const stateInspection = useSelector((state) => state.inspection);
    const [edit, setEdit] = useState(stateInspection);
    const [subinspections, setSubinspections] = useState([]);
    const subInspectionsColumns = useSubInspectionsColumns(subinspections);
    const [loading, setLoading] = useState(true);
    const {
        register, control,
        handleSubmit, reset,
        setValue, getValues, watch
    } = useForm({ defaultValues: defaultInspectionFormValues(user, edit) });
    const watchReminders = watch('reminders');
    const locale = useSelector((state) => state.locale);
    const [filesField, setFilesField] = useState(['files']);
    const [isSubmiting, setIsSubmiting] = useState(false);

    dayjs.locale(locale);

    const history = useHistory();

    const [assignedTasks, setAssignedTasks] = useState([]);
    const assignedTasksColumns = useSubTasksColumns();

    useEffect(() => {
        setEdit(stateInspection);
    }, [stateInspection]);

    const fileManagerRef = useRef();

    const handleResetForm = () => {
        reset(defaultInspectionFormValues(user, edit));
        fileManagerRef.current.flushFiles();
    };

    const handleSubmitForm = async (data) => {
        setIsSubmiting(true);

        const filesData = prepareFilesForUpload(data?.files);
        const remarksFilesData = prepareRemarkFilesForUpload(data?.remarks);

        const preparedFilesObj = {
            files: filesData.files,
            remarks: remarksFilesData.files
        };

        const preparedData = {
            ...data,
            id: edit?._id,
            // Extract due date if possible
            validTo: data.validTo,
            // Ignore files and assign user ID instead of the whole user object
            // eslint-disable-next-line object-curly-newline
            remarks: data.remarks.map(({ files, user: userData, type, ...rest }) => ({ user: userData?._id, type: type?.key, ...rest })),
            // Metadata of the files assigned to the main task
            filesMetadata: filesData.metadata,
            // Metadata of the files assigned to the remarks
            remarksFilesMetadata: remarksFilesData.metadata,
            // Remove cache from the reminders
            reminders: data.reminders.map(({ _new, _isSent, ...reminder }) => reminder)
        };

        if (await onSubmit(preparedData, preparedFilesObj)) {
            handleResetForm();
        }
        setIsSubmiting(false);
    };

    useEffect(() => {
        const getSubinspections = async (id) => {
            const applicantFilterIds = applicantFilter.map((element) => element._id);
            const filterQuery = !!applicantFilterIds.length && JSON.stringify({ _id: applicantFilterIds });
            setLoading(true);
            const { data } = await RestRequestsHelper.getSubinspections(id, filterQuery || []);
            setLoading(false);
            setSubinspections(data?.data ?? []);
        };
        const getAssignedTasks = async (id) => {
            setLoading(true);
            const { data } = await RestRequestsHelper.getAssignedTasksToInspection(id);
            setLoading(false);
            setAssignedTasks(data?.data ?? []);
        };

        if (edit) {
            getSubinspections(edit._id);
            getAssignedTasks(edit._id);
        }
    }, [edit]);

    const getInspection = async (id) => {
        if (id) {
            const { data } = await RestRequestsHelper.getInspection(id);
            return data.data;
        }
        return null;
    };

    const loadParentInspection = async () => {
        const parentInspection = await getInspection(edit.parent._id);
        if (inhabitantMode && parentInspection?.reportingContractor?.user._id !== user.sub) {
            displaySnackbar('error', messages.inhabitantParentInspectionrror);
            return;
        }
        dispatch(setInspection(parentInspection));
        setEdit(parentInspection);
        reset(defaultInspectionFormValues(user, parentInspection));
    };

    const handleSubInspectionClick = ({ row }) => {
        dispatch(setInspection(row));
        setEdit(row);
        reset(defaultInspectionFormValues(user, row));
    };

    const contactWithOptionLabel = ({ value, typeName }) => `${value} (${typeName})`;

    const communitySrc = (phrase) => comboboxTypes.communities(phrase, 500);

    const contactSrc = (index) => {
        const reminder = watchReminders[index];
        if (reminder?.entity) {
            return comboboxTypes.contactsByName(reminder.entity._id);
        }
        return comboboxTypes.empty();
    };

    const renderRemarkMetadata = (field, value) => {
        if (field === 'user' && value) {
            return `${value.firstName} ${value.lastName}`;
        }
        if (field === 'updatedAt' && value) {
            return dayjs(value).format('DD-MM-YYYY HH:mm');
        }
        return '';
    };

    const userCanEdit = (value) => {
        const id = value?.user?._id;
        if (id) {
            return value?.user?._id === user.sub;
        }
        return true;
    };

    const setReminderNow = (index) => {
        const reminders = [...getValues('reminders')];
        reminders[index].date = dayjs().add(3, 'minute');
        setValue('reminders', reminders);
    };

    const handleRemarksRemove = (newItems, index) => {
        if (filesField[0] === 'remarks') {
            if (filesField[1] === index && index >= 0) {
                if (!newItems.length) {
                    setFilesField(['files']);
                } else {
                    setFilesField(['remarks', index - 1, 'files']);
                }
            }
        }
    };

    const handleFilesFieldChange = (field) => {
        if (isEqual(field, filesField)) {
            setFilesField(['files']);
        } else {
            setFilesField(field);
        }
    };

    const handleAssignedTaskClick = (params) => {
        if (params.field === 'numberUI') {
            const { row } = params;
            dispatch(setTask(row));
            history.push('/tasks/browse');
        }
    };

    const redirectToTaskAdding = () => {
        const predefinedValues = {
            concerns: getValues('concerns'),
            inspectionId: edit._id
        };
        history.push(`/tasks/add?predefinedValues=${encodeURIComponent(JSON.stringify(predefinedValues))}`);
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className={classes.root}>
                <FormPickerMaster>
                    <HotkeyProvider>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FormHeader
                                            label={edit ? messages.editInspection.id : messages.addInspection.id}
                                            hideDelete={!edit || readOnly || inhabitantMode}
                                            onDelete={onDelete}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant='outlined'
                                            fullWidth
                                            label={intl.formatMessage(messages.name)}
                                            inputProps={{ maxLength: 100 }}
                                            disabled={readOnly}
                                            required
                                            {...register('name')}
                                        />
                                    </Grid>
                                    <Grid item xs={edit ? 5 : 6}>
                                        <Controller
                                            name='concerns'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={communitySrc()}
                                                    label={intl.formatMessage(messages.concerns)}
                                                    id='concerns'
                                                    required
                                                    longerList
                                                    displayInfo
                                                    multiple
                                                    disabled={readOnly}
                                                    showColors
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {edit && (
                                        <Grid item xs={1}>
                                            <Tooltip title={intl.formatMessage(messages.filterByConcernsHint)}>
                                                <IconButton
                                                    onClick={() => {
                                                        filterByConcerns(getValues('concerns'));
                                                    }}
                                                    size='large'
                                                    disabled={!getValues('concerns')}
                                                >
                                                    <FilterListIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                    <Grid item xs={edit ? 5 : 6}>
                                        <Controller
                                            name='parentInspection'
                                            control={control}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.inspectionNames()}
                                                    optionLabel={(elm) => elm?.name}
                                                    label={intl.formatMessage(messages.parentInspection)}
                                                    id='parentInspection'
                                                    excluded={edit ? [edit._id] : []}
                                                    longerList
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {edit && (
                                        <Grid item xs={1}>
                                            <IconButton
                                                onClick={() => {
                                                    loadParentInspection();
                                                }}
                                                size='large'
                                                disabled={!edit.parent}
                                            >
                                                <MoveUpIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                                    <Grid item xs={6}>
                                        <Controller
                                            name='validTo'
                                            control={control}
                                            render={({ field }) => (
                                                <CustomDatePicker
                                                    disabled={readOnly}
                                                    showTodayButton
                                                    clearable
                                                    variant='outlined'
                                                    inputFormat={dateFormat.format}
                                                    label={intl.formatMessage(messages.validTo)}
                                                    okLabel={intl.formatMessage(messages.dateOkButton)}
                                                    cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                                    todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                                    clearLabel={intl.formatMessage(messages.dateClearButton)}
                                                    renderInput={(params) => (<TextField fullWidth {...params} name='validTo' required />)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name='type'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.inspectionTypes()}
                                                    label={intl.formatMessage(messages.type)}
                                                    id='type'
                                                    required
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={intl.formatMessage(messages.author)}
                                            variant='outlined'
                                            fullWidth
                                            disabled
                                            value={edit?.authorUI || (!edit ? user?.name : null)}
                                            sx={{ input: { color: edit?.author?.color, WebkitTextFillColor: 'unset !important' } }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name='leader'
                                            control={control}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.users([userRoles.admin, userRoles.user])}
                                                    label={intl.formatMessage(messages.leader)}
                                                    id='leader'
                                                    showColors
                                                    disabled={readOnly}
                                                    required
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name='producers'
                                            control={control}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.producers()}
                                                    label={intl.formatMessage(messages.producer)}
                                                    disabled={readOnly}
                                                    showColors
                                                    required
                                                    multiple
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                            <FormattedMessage id={messages.remarks.id} />
                                        </Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name='remarks'
                                            control={control}
                                            render={({ field }) => (
                                                <ListItems
                                                    onNewItem={() => defaultRemarks(user)}
                                                    canBeRemoved={userCanEdit}
                                                    buttonsPosition='top'
                                                    hideActions={readOnly}
                                                    render={(fields, index, value) => (
                                                        <Grid
                                                            container
                                                            sx={value?.user?.color ? {
                                                                borderColor: value?.user?.color, borderRadius: 1, borderWidth: 2, borderStyle: 'solid', p: 1, m: 0
                                                            } : { p: 1, m: 0 }}
                                                        >
                                                            {edit && (
                                                                <Grid container sx={{ textAlign: 'left' }}>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant='caption'>
                                                                            {`${intl.formatMessage(messages.author)}: ${renderRemarkMetadata('user', value.user)}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant='caption'>
                                                                            {`${intl.formatMessage(messages.updatedAt)}: ${renderRemarkMetadata('updatedAt', value.updatedAt)}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            <Grid container sx={{ mt: 1 }} alignItems='center'>
                                                                <Grid item xs={2}>
                                                                    <UniversalCombobox
                                                                        src={comboboxTypes.remarkTypes()}
                                                                        label={intl.formatMessage(messages.type)}
                                                                        id='type'
                                                                        disabled={!userCanEdit(value) || readOnly}
                                                                        required
                                                                        disableClearable
                                                                        optionLabel={(title) => title?.$name?.slice(0, 3)}
                                                                        {...fields('type')}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField
                                                                        disabled={!userCanEdit(value) || readOnly}
                                                                        required
                                                                        fullWidth
                                                                        multiline
                                                                        variant='outlined'
                                                                        label={intl.formatMessage(messages.remarks)}
                                                                        {...fields('value')}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <IconButton
                                                                        className={classes.gridButton}
                                                                        onClick={() => handleFilesFieldChange(['remarks', index, 'files'])}
                                                                        color={isEqual(filesField, ['remarks', index, 'files']) ? 'primary' : 'default'}
                                                                        size='large'
                                                                    >
                                                                        <FindInPageIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    {
                                                                        value.files?.length || 0
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion sx={{ p: 0 }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
                                                <div className={classes.accordionSummary}>
                                                    <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                                        <FormattedMessage id={messages.reminder.id} />
                                                    </Typography>
                                                    <Typography variant='body2'>{edit?.reminders?.length || 0}</Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ p: 0 }}>
                                                <Controller
                                                    name='reminders'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <ListItems
                                                            canBeRemoved={(value) => !value._isSent}
                                                            newItem={defaultReminder}
                                                            onItemRemove={handleRemarksRemove}
                                                            buttonsPosition='top'
                                                            hideActions={readOnly}
                                                            sortFn={(_a, _b) => (edit ? new Date(_b.date) - new Date(_a.date) : 0)}
                                                            render={(fields, index) => (
                                                                <Grid container alignItems='center'>
                                                                    <Grid item xs={4}>
                                                                        <UniversalCombobox
                                                                            required
                                                                            displayInfo
                                                                            src={comboboxTypes.entitiesContactsByName()}
                                                                            showColors
                                                                            label={intl.formatMessage(messages.reminderEntity)}
                                                                            disabled={fields('_isSent')?.value || readOnly}
                                                                            {...fields('entity')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <UniversalCombobox
                                                                            required
                                                                            src={contactSrc(index)}
                                                                            label={intl.formatMessage(messages.reminderContact)}
                                                                            optionLabel={contactWithOptionLabel}
                                                                            disabled={!watchReminders[index]?.entity || fields('_isSent')?.value || readOnly}
                                                                            {...fields('value')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <DateTimePicker
                                                                            required
                                                                            disabled={fields('_isSent')?.value || readOnly}
                                                                            disablePast
                                                                            ampm={false}
                                                                            showTodayButton
                                                                            variant='outlined'
                                                                            inputFormat={dateFormat.dateWithHoursAndMinutes}
                                                                            margin='normal'
                                                                            okLabel={intl.formatMessage(messages.dateOkButton)}
                                                                            cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                                                            todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                                                            renderInput={(params) => (<TextField fullWidth {...params} />)}
                                                                            {...fields('date')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Tooltip title={intl.formatMessage(messages.reminderNowHint)}>
                                                                            <Button
                                                                                onClick={() => setReminderNow(index)}
                                                                                disabled={fields('_isSent')?.value}
                                                                            >
                                                                                <FormattedMessage id={messages.reminderNow.id} />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    {edit ? (
                                        <>
                                            {(loading || subinspections.length > 0) && (
                                                <Grid item xs={12}>
                                                    <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                                        <FormattedMessage id={messages.subinspections.id} />
                                                    </Typography>
                                                    <Divider />

                                                    {loading
                                                        ? <CircularProgress className={classes.loading} />
                                                        : (
                                                            <DataGrid
                                                                autoHeight
                                                                rows={subinspections}
                                                                columns={subInspectionsColumns}
                                                                onCellClick={handleSubInspectionClick}
                                                                sx={{ mb: 2 }}
                                                                columnVisibilityModel={
                                                                    convertColumnsToVisibilityModel(subInspectionsColumns)
                                                                }
                                                            />
                                                        )}
                                                </Grid>
                                            )}
                                            {(loading || assignedTasks.length > 0) && (
                                                <Grid item xs={12}>
                                                    <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                                        <FormattedMessage id={messages.assignedTasks.id} />
                                                    </Typography>
                                                    <Divider />

                                                    {loading
                                                        ? <CircularProgress className={classes.loading} />
                                                        : (
                                                            <DataGrid
                                                                autoHeight
                                                                rows={assignedTasks}
                                                                columns={assignedTasksColumns}
                                                                sx={{ mb: 2 }}
                                                                onCellClick={handleAssignedTaskClick}
                                                                columnVisibilityModel={
                                                                    convertColumnsToVisibilityModel(assignedTasksColumns)
                                                                }
                                                            />
                                                        )}
                                                </Grid>
                                            )}
                                            <Grid item xs={6}>
                                                <CustomDatePicker
                                                    useLocalTime
                                                    variant='outlined'
                                                    inputFormat={dateFormat.fullDate}
                                                    margin='normal'
                                                    id='addDate'
                                                    label={(
                                                        <FormattedMessage
                                                            id={messages.addInspectionDate.id}
                                                        />
                                                    )}
                                                    value={edit.createdAt}
                                                    renderInput={(params) => (<TextField fullWidth {...params} />)}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <CustomDatePicker
                                                    useLocalTime
                                                    variant='outlined'
                                                    inputFormat={dateFormat.fullDate}
                                                    renderInput={(params) => (<TextField fullWidth {...params} />)}
                                                    margin='normal'
                                                    id='editDate'
                                                    label={(
                                                        <FormattedMessage
                                                            id={messages.editInspectionDate.id}
                                                        />
                                                    )}
                                                    value={edit.updatedAt}
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    ) : null}
                                    {
                                        !readOnly && (
                                            <Grid container className={classes.buttons}>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        fullWidth
                                                        onClick={handleResetForm}
                                                    >
                                                        <FormattedMessage id={messages.clear.id} />
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LoadingButton
                                                        type='submit'
                                                        variant='contained'
                                                        color='primary'
                                                        fullWidth
                                                        loading={isSubmiting}
                                                        loadingPosition='start'
                                                    >
                                                        <FormattedMessage id={edit ? messages.saveChanges.id : messages.addInspection.id} />
                                                    </LoadingButton>
                                                </Grid>
                                                {edit && (
                                                    <Grid item xs={12}>
                                                        <Button
                                                            variant='outlined'
                                                            sx={{ mt: 1, backdropFilter: 'blur(4px)' }}
                                                            fullWidth
                                                            onClick={redirectToTaskAdding}
                                                        >
                                                            <FormattedMessage id={messages.addTask.id} />
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormFileManager
                                    setValue={setValue}
                                    getValues={getValues}
                                    currentField={filesField}
                                    readOnly={readOnly}
                                    ref={fileManagerRef}
                                />
                            </Grid>
                        </Grid>
                    </HotkeyProvider>
                </FormPickerMaster>
            </div>
        </form>
    );
}

InspectionForm.propTypes = {
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    filterByConcerns: PropTypes.func,
    edit: PropTypes.object,
    inhabitantMode: PropTypes.bool,
    readOnly: PropTypes.bool,
    applicantFilter: PropTypes.object
};

InspectionForm.defaultProps = {
    onSubmit: () => {},
    onDelete: () => {},
    filterByConcerns: () => {},
    edit: null,
    inhabitantMode: false,
    readOnly: false,
    applicantFilter: () => {}
};

export default InspectionForm;
