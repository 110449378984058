import React from 'react';
import { useIntl } from 'react-intl';
import { renderCellExpand } from '../../../../common/DataGridCells/GridCellExpand';
import ContractorDataCell from '../ui/ContractorDataCell';

const messages = {
    contractorName: { id: 'app.contractors.contractorName' },
    nip: { id: 'app.contractors.nip' },
    regon: { id: 'app.contractors.regon' },
    address: { id: 'app.contractors.address' },
    phone: { id: 'app.contractors.phone' },
    email: { id: 'app.contractors.email' }
};

const useColumns = () => {
    const intl = useIntl();
    return [
        {
            field: 'name',
            headerName: intl.formatMessage({ id: messages.contractorName.id }),
            flex: 4,
            headerAlign: 'center',
            renderCell: (data) => renderCellExpand({ value: data.value, showPointer: true }, data.row?.color)
        },
        {
            field: 'address',
            headerName: intl.formatMessage({ id: messages.address.id }),
            flex: 2,
            headerAlign: 'center',
            renderCell: ({ value }) => (
                <ContractorDataCell
                    data={value}
                    formatValue={(val) => `${val?.postal_code} ${val?.city} ${val?.street} ${val?.number} (${val?.type?.value})`}
                />
            )
        },
        {
            field: 'contacts_phone',
            headerName: intl.formatMessage({ id: messages.phone.id }),
            flex: 1,
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <ContractorDataCell
                    data={row?.contacts}
                    formatValue={(val) => val?.value}
                    prepareData={
                        (data) => data
                            ?.filter((elm) => [1, 5].includes(elm?.type?.key)) // (5) Telefon komórkowy; (1) Telefon
                            ?.sort((first, second) => ((first?.verified > second?.verified) ? -1 : 1))
                    }
                />
            )
        },
        {
            field: 'contacts_email',
            headerName: intl.formatMessage({ id: messages.email.id }),
            flex: 1,
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <ContractorDataCell
                    data={row?.contacts}
                    formatValue={(val) => val?.value}
                    prepareData={
                        (data) => data
                            ?.filter((elm) => elm?.type?.key === 3) // type 3 - email
                            ?.sort((first, second) => ((first?.verified > second?.verified) ? -1 : 1))
                    }
                />
            )
        },
        {
            field: 'nip',
            headerName: intl.formatMessage({ id: messages.nip.id }),
            flex: 1,
            headerAlign: 'center',
            renderCell: renderCellExpand
        },
        {
            field: 'regon',
            headerName: intl.formatMessage({ id: messages.regon.id }),
            flex: 1,
            headerAlign: 'center',
            renderCell: renderCellExpand
        }
    ];
};

export default useColumns;
