import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Grid, TextField, Typography, Divider
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { newAddressItem } from '../data/newListItems';
import UniversalCombobox, { comboboxTypes } from '../../../Combobox/UniversalCombobox';
import ListItems from '../../../ListItems/ListItems';

const messages = {
    addresses: { id: 'app.contractors.addresses' },
    type: { id: 'app.contractors.type' },
    city: { id: 'app.contractors.city' },
    postalCode: { id: 'app.contractors.postalCode' },
    street: { id: 'app.contractors.street' },
    number: { id: 'app.contractors.number' },
    country: { id: 'app.contractors.country' },
    voivodeship: { id: 'app.contractors.voivodeship' },
    county: { id: 'app.contractors.county' },
    commune: { id: 'app.contractors.commune' },
    post: { id: 'app.contractors.post' }
};

const useStyle = makeStyles(() => ({
    checkbox: {
        '& .MuiFormControlLabel-label': {
            fontSize: '0.8rem'
        }
    },
    header: {
        marginTop: 10
    }
}));

function ContractorFormAddresses({ control, readOnly }) {
    const classes = useStyle();
    return (
        <Grid item xs={12}>
            <Typography component='h2' variant='h6' className={classes.header} align='left'>
                <FormattedMessage id={messages.addresses.id} />
            </Typography>
            <Divider />
            <Controller
                name='address'
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                    <ListItems
                        onNewItem={newAddressItem}
                        variant='outlined'
                        margin='normal'
                        fullWidth
                        hideActions={readOnly}
                        render={(fields) => (
                            <Grid container>
                                <Grid item xs={2}>
                                    <UniversalCombobox
                                        src={comboboxTypes.entitiesAddressTypes()}
                                        variant='outlined'
                                        fullWidth
                                        required
                                        label={<FormattedMessage {...messages.type} />}
                                        disabled={readOnly}
                                        {...fields('type')}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        label={<FormattedMessage {...messages.country} />}
                                        disabled={readOnly}
                                        {...fields('country')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        label={<FormattedMessage {...messages.voivodeship} />}
                                        disabled={readOnly}
                                        {...fields('voivodeship')}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        label={<FormattedMessage {...messages.county} />}
                                        disabled={readOnly}
                                        {...fields('county')}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        label={<FormattedMessage {...messages.commune} />}
                                        disabled={readOnly}
                                        {...fields('commune')}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        label={<FormattedMessage {...messages.post} />}
                                        disabled={readOnly}
                                        {...fields('post')}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        required
                                        label={<FormattedMessage {...messages.postalCode} />}
                                        disabled={readOnly}
                                        {...fields('postal_code')}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        required
                                        label={<FormattedMessage {...messages.city} />}
                                        disabled={readOnly}
                                        {...fields('city')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        required
                                        label={<FormattedMessage {...messages.street} />}
                                        disabled={readOnly}
                                        {...fields('street')}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        required
                                        label={<FormattedMessage {...messages.number} />}
                                        disabled={readOnly}
                                        {...fields('number')}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {...field}
                    />
                )}
            />
        </Grid>
    );
}

ContractorFormAddresses.propTypes = {
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool
};

ContractorFormAddresses.defaultProps = {
    readOnly: false
};

export default ContractorFormAddresses;
