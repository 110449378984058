import React, { useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { setInspection } from '../../../../../../actions';
import InspectionForm from '../../../../../common/InspectionForm/InspectionForm';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../../../lib/displaySnackbar';
import errors from '../../../../../../constants/ResponseCodes';

const useStyles = makeStyles(() => ({
    drawer: {
        width: '90vw',
        overflowX: 'hidden',
        textAlign: 'center'
    }
}));

const messages = {
    editedInspection: { id: 'app.inspections.editedInspection' },
    errorUpdate: { id: 'app.addInspection.errorUpdate' },
    errorFileLimit: { id: 'app.addInspection.errorFileLimit' }
};

function InspectionsSidePanel({
    onClose, onUpdate, filterByConcerns, inhabitantMode, applicantFilter
}) {
    const intl = useIntl();
    const classes = useStyles();
    const inspection = useSelector((state) => state.inspection);
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ enqueueSnackbar, intl });

    const dispatch = useDispatch();
    useEffect(async () => {
        if (typeof inspection === 'string') {
            const { data } = await RestRequestsHelper.getInspection(inspection);
            dispatch(setInspection(data?.data));
        }
    }, [inspection]);

    const handleSubmit = async (updatedInspection, files) => {
        const { data, error } = await RestRequestsHelper.updateInspection(updatedInspection, files);
        if (error) {
            switch (error.code) {
                case errors.filesLimitAlmostReached:
                    displaySnackbar('error', messages.errorFileLimit);
                    break;
                default:
                    displaySnackbar('error', messages.errorUpdate);
                    break;
            }
            return false;
        }
        onClose();
        if (data) {
            displaySnackbar('success', messages.editedInspection, { number: data.value });
            onUpdate();
        }
        return true;
    };

    const handleDelete = async () => {
        await RestRequestsHelper.deleteInspection(inspection._id);
        onClose();
        onUpdate();
    };

    return (
        <Drawer anchor='right' open={inspection?._id} onClose={onClose}>
            <Paper className={classes.drawer}>
                <InspectionForm
                    onSubmit={handleSubmit}
                    onDelete={handleDelete}
                    filterByConcerns={filterByConcerns}
                    inhabitantMode={inhabitantMode}
                    readOnly={inhabitantMode}
                    applicantFilter={applicantFilter}
                />
            </Paper>
        </Drawer>
    );
}

InspectionsSidePanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
    filterByConcerns: PropTypes.func,
    inhabitantMode: PropTypes.bool,
    applicantFilter: PropTypes.object
};

InspectionsSidePanel.defaultProps = {
    onUpdate: () => {},
    filterByConcerns: () => {},
    inhabitantMode: false,
    applicantFilter: () => {}
};

export default InspectionsSidePanel;
