import dayjs from 'dayjs';
import dateFormat from '../constants/dateFormat';
import GetCurrentDateMiddleware from '../types/GetCurrentDateMiddleware';

function formatUTCDate(
    date: string | undefined,
    format = dateFormat.reversedFormat,
    middleware: GetCurrentDateMiddleware = (dateArg) => dateArg
): string | null {
    if (!date) return null;
    return middleware(dayjs.utc(date)).format(format);
}

export default formatUTCDate;
