import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useListener } from 'react-bus';
import formatMoney from '../../../lib/formatMoney';

function MoneyTextField({
    value, setValue, onChange, onKeyDown,
    onBlur, textFieldParams, disabled
}) {
    const inputRef = React.useRef();

    const updateInput = (event) => {
        let val = event.target.value;
        val = formatMoney(val, false);
        onChange(val);
        // eslint-disable-next-line no-param-reassign
        event.target.value = val;
        onBlur(event);
    };

    const handleChange = (event) => {
        if (onKeyDown) {
            return onKeyDown(event);
        }
        if (onChange) {
            return onChange(event);
        }
        return setValue(event.target.value);
    };

    // Update input on form picker state change
    try {
        // eslint-disable-next-line
        useListener(config.bus.pickerChange, () => {
            if (value.length) {
                const event = { target: { value } };
                updateInput(event);
                onChange(event.target.value);
            }
        });
    } catch { /* No Form Picker detected */ }

    return (
        <TextField
            onBlur={updateInput}
            onChange={handleChange}
            onKeyDown={handleChange}
            value={value}
            ref={inputRef}
            disabled={disabled}
            {...textFieldParams}
        />
    );
}

MoneyTextField.propTypes = {
    value: PropTypes.string.isRequired,
    setValue: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onBlur: PropTypes.func,
    textFieldParams: PropTypes.object,
    disabled: PropTypes.bool
};

MoneyTextField.defaultProps = {
    setValue: () => {},
    onChange: null,
    onKeyDown: null,
    onBlur: () => {},
    textFieldParams: {},
    disabled: false
};

export default MoneyTextField;
