import React from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

function AdditionalOptionsColumn({
    onIconClick, onItemClick, anchorEl, setAnchorEl, options
}) {
    return (
        <>
            <IconButton style={{ marginLeft: 'auto' }} onClick={onIconClick} size='large'>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {options.filter((elm) => elm.policyFn()).map((elm) => (
                    <MenuItem key={elm.title} onClick={() => onItemClick(elm.fn, elm?.type)}>
                        {elm.title}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

AdditionalOptionsColumn.propTypes = {
    onIconClick: PropTypes.func,
    onItemClick: PropTypes.func,
    anchorEl: PropTypes.object.isRequired,
    setAnchorEl: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired
};

AdditionalOptionsColumn.defaultProps = {
    onIconClick: () => {},
    onItemClick: () => {}
};

export default AdditionalOptionsColumn;
