import React from 'react';
import Alert from '@mui/material/Alert';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

const messages = {
    contractorAltName: { id: 'app.bankStatement.contractorAltName' },
    noContractorAltName: { id: 'app.bankStatement.noContractorAltName' }
};

const useStyles = makeStyles(() => ({
    contractorAltName: {
        fontWeight: 'bold',
        marginLeft: '1ch'
    }
}));

interface IContractorAlert {
    contractorAltName: string,
    imported: boolean
}
function ContractorAlert({ contractorAltName, imported }: IContractorAlert) {
    const classes = useStyles();

    if (contractorAltName) {
        return (
            <Alert severity='info'>
                <FormattedMessage id={messages.contractorAltName.id} />
                <span className={classes.contractorAltName}>{contractorAltName}</span>
            </Alert>
        );
    }

    if (imported) {
        return (
            <Alert severity='warning'>
                <FormattedMessage id={messages.noContractorAltName.id} />
            </Alert>
        );
    }

    return null;
}

export default ContractorAlert;
