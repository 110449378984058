import { combineReducers } from 'redux';
import { user } from './user';
import { locale } from './locale';
import { theme } from './theme';
import { dbInfo } from './dbInfo';
import { task } from './task';
import { vindication } from './vindication';
import { inspection } from './inspection';
import { income } from './income';
import { invoice } from './invoice';
import { loaders } from './loaders';
import { correspondence } from './correspondence';
// eslint-disable-next-line import/prefer-default-export
export default combineReducers({
    user,
    locale,
    theme,
    dbInfo,
    task,
    vindication,
    inspection,
    income,
    invoice,
    correspondence,
    loaders
});
