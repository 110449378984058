import { useIntl } from 'react-intl';
import { GridColDef } from '@mui/x-data-grid';
import { renderCellExpand } from '../../DataGridCells/GridCellExpand';

const messages = {
    name: { id: 'app.inspections.name' },
    concerns: { id: 'app.inspections.concerns' },
    validTo: { id: 'app.inspections.validTo' }
};

type ConcernElmType = {
    name: string
};

const useSubInspectionsColumns = () => {
    const intl = useIntl();
    const col: GridColDef[] = [
        {
            field: 'concernsUI',
            headerName: intl.formatMessage(messages.concerns),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: ({ row }) => renderCellExpand({ value: row.concerns.map((item: ConcernElmType) => item?.name).join(', ') })
        },
        {
            field: 'name',
            headerName: intl.formatMessage(messages.name),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: renderCellExpand
        },
        {
            field: 'validToUI',
            headerName: intl.formatMessage(messages.validTo),
            width: 140,
            headerAlign: 'center',
            renderCell: renderCellExpand
        }
    ];
    return col;
};

export default useSubInspectionsColumns;
