import useSWR from 'swr';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';

const useInfrastructureTypes = (keys = []) => {
    const { data, error, mutate } = RestRequestsHelper.getInfrastructuresTypes(useSWR);
    const filteredData = keys.length > 0 && data ? data.filter((item) => keys.includes(item.key)) : data;

    return {
        types: filteredData,
        loading: !error && !data,
        mutate
    };
};

export default useInfrastructureTypes;
