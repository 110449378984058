import Grid from '@mui/material/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { forwardRef } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import FilesList from '../FilesList/FilesList';
import UniversalCombobox, { comboboxTypes } from '../Combobox/UniversalCombobox';

const messages = {
    verify: {
        id: 'app.import.verify'
    },
    addDirectly: {
        id: 'app.import.addDirectly'
    },
    uploadPdf: {
        id: 'app.form.upload'
    },
    rtfMany: {
        id: 'app.addInvoice.rtfMany'
    },
    documentType: {
        id: 'app.form.documentType'
    }
};

const ImportDocument = forwardRef(({
    files, onFilesChange, verify, onVerifyChange, documentType, documentKind, onDocumentTypeChange, acceptedFiles
}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    return (
        <Typography component='div'>
            <Grid container alignItems='center' spacing={2}>
                <Grid item><FormattedMessage id={messages.addDirectly.id} /></Grid>
                <Grid item>
                    <Switch
                        checked={verify}
                        onChange={(event) => {
                            onVerifyChange(event.target.checked);
                        }}
                        name='verify'
                        color='secondary'
                    />
                </Grid>
                <Grid item><FormattedMessage id={messages.verify.id} /></Grid>
                <Grid item xs={12}>
                    <UniversalCombobox
                        id='invoiceTypePrompt'
                        required
                        src={comboboxTypes.documentTypes(documentKind)}
                        label={intl.formatMessage(messages.documentType)}
                        onChange={onDocumentTypeChange}
                        value={documentType}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant='contained'
                        component='label'
                    >
                        <FormattedMessage id={messages.uploadPdf.id} />
                        <input
                            ref={ref}
                            type='file'
                            hidden
                            multiple
                            accept={acceptedFiles}
                            onChange={(event) => {
                                const uploadedFiles = [...event.target.files];
                                const rtfCount = uploadedFiles.reduce((total, item) => total + !!item.type.match('rtf'), 0);
                                if (rtfCount > 1) {
                                    enqueueSnackbar(intl.formatMessage(messages.rtfMany), { variant: 'error' });
                                    onFilesChange([]);
                                    return;
                                }
                                onFilesChange(uploadedFiles);
                            }}
                        />
                    </Button>
                    <FilesList value={files} />
                </Grid>
            </Grid>
        </Typography>
    );
});

ImportDocument.propTypes = {
    files: PropTypes.array.isRequired,
    onFilesChange: PropTypes.func.isRequired,
    verify: PropTypes.bool.isRequired,
    onVerifyChange: PropTypes.func.isRequired,
    documentType: PropTypes.object.isRequired,
    documentKind: PropTypes.oneOf(['invoice', 'income']).isRequired,
    onDocumentTypeChange: PropTypes.func.isRequired,
    acceptedFiles: PropTypes.string.isRequired
};

export default ImportDocument;
