import React from 'react';
import PropTypes from 'prop-types';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {
    Button, Checkbox, FormControlLabel, FormGroup
} from '@mui/material';
import { saveAs } from 'file-saver';
import { useIntl } from 'react-intl';
import { FileDownload } from '@mui/icons-material';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';

const messages = {
    hideZeroValues: { id: 'app.payoffs.tally.hideZeroValues' },
    generatePdf: { id: 'app.payoffs.tally.generatePdf' }
};

export default function DataGridToolbar({ hideZeroValues, setHideZeroValues, filter }) {
    const intl = useIntl();

    const handleGeneratePDF = async () => {
        const { data } = await RestRequestsHelper.getPayoffsTallyPDF(JSON.stringify({
            ...filter,
            hideZeroValues
        }));
        saveAs(data, 'payoffs-tally.pdf');
    };

    return (
        <GridToolbarContainer>
            <GridToolbarExport />
            <Button onClick={handleGeneratePDF}>
                <FileDownload />
                <div style={{ marginLeft: 7 }}>
                    {intl.formatMessage(messages.generatePdf)}
                </div>
            </Button>
            <FormGroup sx={{ ml: 2 }}>
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={hideZeroValues}
                            onChange={(_event, val) => setHideZeroValues(val)}
                        />
                    )}
                    label={intl.formatMessage(messages.hideZeroValues)}
                />
            </FormGroup>
        </GridToolbarContainer>
    );
}

DataGridToolbar.propTypes = {
    hideZeroValues: PropTypes.bool.isRequired,
    setHideZeroValues: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired
};
