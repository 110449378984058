import React from 'react';
import PropTypes from 'prop-types';

function ExcelErrorsSnackbar({ errors }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
                Object.keys(errors).map((row) => (
                    <div>
                        {
                            `Wiersz ${row} - ${errors[row].join('; ')}`
                        }
                    </div>
                ))
            }
        </div>
    );
}

ExcelErrorsSnackbar.propTypes = {
    errors: PropTypes.object.isRequired
};
export default ExcelErrorsSnackbar;
