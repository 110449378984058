import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import dateFormat from '../../../../constants/dateFormat';
import onKeyDetect from '../../../../lib/onKeyDetect';
import CustomDatePicker from '../../../common/CustomDatePicker/CustomDatePicker';
import getCurrentDate from '../../../../lib/getCurrentDate';
import UniversalCombobox, { comboboxTypes } from '../../../common/Combobox/UniversalCombobox';

const messages = {
    number: { id: 'app.correspondence.add.number' },
    concerns: { id: 'app.correspondence.add.concerns' },
    concernsHelp: { id: 'app.correspondence.add.concerns.help' },
    journal: { id: 'app.correspondence.add.journal' },
    contractor: { id: 'app.addInvoice.contractor' },
    to: { id: 'app.correspondence.add.toWhom' },
    from: { id: 'app.correspondence.add.fromWho' },
    leader: { id: 'app.correspondence.add.leader' },
    startDate: { id: 'app.correspondence.show.startDate' },
    endDate: { id: 'app.correspondence.show.endDate' }

};

function FiltersComponent({ filtersState, filtersDispatch }) {
    const intl = useIntl();

    const [startDateVal, setStartDateVal] = useState(dayjs().subtract(1, 'year').format(dateFormat.reversedFormat));
    const [endDateVal, setEndDateVal] = useState(getCurrentDate);

    const onChangeJournal = (data) => {
        filtersDispatch({ type: 'journal', value: data });
    };
    const changeStartDate = (date) => {
        if (date <= filtersState.endDate || !filtersState.endDate) {
            filtersDispatch({ type: 'startDate', value: date });
            setStartDateVal(date);
        }
    };
    const changeEndDate = (date) => {
        if (date >= filtersState.startDate || !filtersState.startDate) {
            filtersDispatch({ type: 'endDate', value: date });
            setEndDateVal(date);
        }
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <TextField
                    id='documentNumber'
                    variant='outlined'
                    margin='normal'
                    label={intl.formatMessage({ id: messages.number.id })}
                    fullWidth
                    onKeyDown={(event) => onKeyDetect(event, () => {
                        filtersDispatch({ type: 'number', value: event.target.value });
                    })}
                />
            </Grid>
            <Grid item xs={3}>
                <UniversalCombobox
                    id='journal'
                    src={comboboxTypes.correspondenceContractors()}
                    label={intl.formatMessage({ id: messages.journal.id })}
                    onChange={onChangeJournal}
                    value={filtersState?.journal}
                    showColors
                    margin='normal'
                />
            </Grid>
            <Grid item xs={3}>
                <UniversalCombobox
                    id='contractor'
                    multiple
                    src={comboboxTypes.contractors()}
                    label={intl.formatMessage({ id: messages.contractor.id })}
                    onChange={(value) => filtersDispatch({ type: 'contractor', value })}
                    value={filtersState?.contractor}
                    optionLabel={(elm) => elm?.name}
                    showColors
                    margin='normal'
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    id='concerns'
                    variant='outlined'
                    margin='normal'
                    label={intl.formatMessage({ id: messages.concerns.id })}
                    fullWidth
                    onKeyDown={(event) => onKeyDetect(event, () => {
                        filtersDispatch({ type: 'concerns', value: event.target.value });
                    })}
                />
            </Grid>
            <Grid item xs={6}>
                <CustomDatePicker
                    disableToolbar
                    variant='outlined'
                    inputVariant='outlined'
                    inputFormat={dateFormat.format}
                    margin='normal'
                    name='startdate'
                    id='startdate'
                    label={intl.formatMessage({ id: messages.startDate.id })}
                    showTodayButton
                    value={startDateVal}
                    onChange={changeStartDate}
                    clearable
                    renderInput={(params) => <TextField fullWidth {...params} name='startDate' />}
                />
            </Grid>
            <Grid item xs={6}>
                <CustomDatePicker
                    disableToolbar
                    variant='outlined'
                    inputVariant='outlined'
                    inputFormat={dateFormat.format}
                    margin='normal'
                    name='endate'
                    id='endate'
                    label={intl.formatMessage({ id: messages.endDate.id })}
                    showTodayButton
                    value={endDateVal}
                    onChange={changeEndDate}
                    clearable
                    renderInput={(params) => <TextField fullWidth {...params} name='endDate' />}
                />
            </Grid>
        </Grid>
    );
}

FiltersComponent.propTypes = {
    filtersState: PropTypes.object.isRequired,
    filtersDispatch: PropTypes.func.isRequired
};

export default FiltersComponent;
