import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingButton } from '@mui/lab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSnackbar } from 'notistack';
import { defaultIncomeTypeValue } from '../../../../../../constants/invoiceTypes';
import TabPanel from '../../../../../common/TabPanel/TabPanel';
import GenerateIncomes from '../GenerateIncomes/GenerateIncomes';
import ImportDocument from '../../../../../common/ImportDocument/ImportDocument';
import getCurrentDate from '../../../../../../lib/getCurrentDate';

const messages = {
    importSettings: { id: 'app.income.title' },
    importIncomes: { id: 'app.addIncome.importIncomes' },
    generateIncomes: { id: 'app.addIncome.generateIncomes' },
    confirm: { id: 'app.form.confirm' },
    addManually: { id: 'app.menu.addManually' }
};

const useStyles = makeStyles((theme) => ({
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        verticalAlign: 'top'
    }
}));

function AddIncomeDialog({ open: propOpen, onGenerate, onImport }) {
    const history = useHistory();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [verify, setVerify] = useState(true);
    const [open, setOpen] = useState(propOpen);
    const intl = useIntl();
    const [communities, setCommunities] = useState([]);
    const [dateOfIncome, setDateOfIncome] = useState(getCurrentDate);
    const [isLoading, setIsLoading] = useState(false);
    const [incomeType, setIncomeType] = React.useState(defaultIncomeTypeValue);
    const [remarks, setRemarks] = React.useState('');
    const [files, setFiles] = useState([]);
    const input = useRef(null);

    const [activeTab, setActiveTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    const handleIncomeTypeChange = (value) => {
        setIncomeType(value);
    };
    useEffect(() => {
        setOpen(propOpen);
        if (propOpen) {
            setIsLoading(false);
        }
    }, [propOpen]);

    const handleSubmit = () => {
        setIsLoading(true);
        if (activeTab === 0) {
            const data = {
                verify,
                communities,
                dateOfIncome,
                documentType: incomeType,
                remarks
            };
            onGenerate(data);
        }

        if (activeTab === 1) {
            const data = {
                verify,
                documentType: incomeType.id,
                files
            };
            if (files.length >= 1) {
                onImport(data);
                setOpen(false);
            } else {
                enqueueSnackbar(intl.formatMessage(messages.mustSelectFile, { file: 'xls' }), { variant: 'warning' });
            }
            setFiles([]);
            input.current.value = '';
        }
    };

    const handleClose = () => {
        history.push('/income/browse');
    };

    const disableButton = () => (activeTab === 0 && !communities.length > 0) || (activeTab === 1 && files.length === 0);

    const tabOptions = [
        {
            label: <FormattedMessage id={messages.generateIncomes.id} />,
            content: (
                <GenerateIncomes
                    verify={verify}
                    onVerifyChange={setVerify}
                    incomeType={incomeType}
                    onIncomeTypeChange={handleIncomeTypeChange}
                    communities={communities}
                    onCommunitiesChange={setCommunities}
                    remarks={remarks}
                    onRemarksChange={setRemarks}
                    dateOfIncome={dateOfIncome}
                    onDateOfIncomeChange={setDateOfIncome}
                />
            )
        },
        {
            label: <FormattedMessage id={messages.importIncomes.id} />,
            content: (
                <ImportDocument
                    onFilesChange={setFiles}
                    onVerifyChange={setVerify}
                    onDocumentTypeChange={handleIncomeTypeChange}
                    files={files}
                    verify={verify}
                    documentType={incomeType}
                    documentKind='income'
                    ref={input}
                    acceptedFiles='.xls,.xlsx,.numbers'
                />
            )
        }
    ];

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id='form-dialog-title' onClose={handleClose}>
                    <FormattedMessage id={messages.importSettings.id} />
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={handleClose}
                        aria-label='close'
                        className={classes.close}
                        size='large'
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography component='div'>
                        <Tabs variant='fullWidth' value={activeTab} onChange={handleTabChange}>
                            {
                                tabOptions.map((tab) => <Tab label={tab.label} />)
                            }
                        </Tabs>
                        {tabOptions.map((tab, index) => (
                            <TabPanel key={index} value={activeTab} index={index}>
                                <div style={{ paddingTop: '8px' }}>
                                    {tab.content}
                                </div>
                            </TabPanel>
                        ))}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        id='generate'
                        variant='contained'
                        onClick={handleSubmit}
                        color='primary'
                        disabled={disableButton()}
                        loading={isLoading}
                    >
                        <FormattedMessage id={messages.confirm.id} />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

AddIncomeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onGenerate: PropTypes.func.isRequired,
    onImport: PropTypes.func.isRequired
};

export default AddIncomeDialog;
