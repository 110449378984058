import React from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { renderCellExpand } from '../../../../../common/DataGridCells/GridCellExpand';
import formatMoney from '../../../../../../lib/formatMoney';

const messages = {
    name: { id: 'app.payoffs.tally.name' },
    value: { id: 'app.payoffs.tally.value' },
    actions: { id: 'app.payoffs.tally.actions' },
    costs: { id: 'app.payoffs.tally.costs' },
    incomes: { id: 'app.payoffs.tally.incomes' },
    transactions: { id: 'app.payoffs.tally.transactions' }
};

const handleShowFilterButtonClick = ({ row }, filter, type) => {
    if (row.kind === 'detail') {
        const {
            detailType, documentType, entity
        } = row;
        switch (type) {
            case 'cost':
                window.open(`/invoices/browse?filter=${encodeURIComponent(JSON.stringify({
                    position: [{
                        id: detailType.key,
                        title: `${detailType.symbol} - ${detailType.value}`
                    }],
                    groupPositions: false,
                    dateFrom: dayjs(filter.dateFrom, 'YYYY-MM-DD').format('YYYY/MM/DD'),
                    dateTo: dayjs(filter.dateTo, 'YYYY-MM-DD').format('YYYY/MM/DD'),
                    buyer: [{ ...entity, $name: entity.name }],
                    invoiceType: [{
                        id: documentType.key,
                        name: documentType.value,
                        $name: [documentType.code, documentType.value].join(' '),
                        abbreviation: documentType.code
                    }]
                }))}`);
                break;
            case 'income':
                window.open(`/income/browse?filter=${encodeURIComponent(JSON.stringify({
                    position: [{
                        id: detailType.key,
                        title: `${detailType.symbol} - ${detailType.value}`
                    }],
                    groupPositions: false,
                    dateFrom: dayjs(filter.dateFrom, 'YYYY-MM-DD').format('YYYY/MM/DD'),
                    dateTo: dayjs(filter.dateTo, 'YYYY-MM-DD').format('YYYY/MM/DD'),
                    seller: [{ ...entity, $name: entity.name }],
                    invoiceType: [{
                        id: documentType.key,
                        name: documentType.value,
                        $name: [documentType.code, documentType.value].join(' '),
                        abbreviation: documentType.code
                    }]
                }))}`);
                break;
            case 'transaction':
                window.open(`/bankStatements/browse?filter=${encodeURIComponent(JSON.stringify({
                    type: [{ ...detailType, $name: detailType.value }],
                    dateFrom: dayjs(filter.dateFrom, 'YYYY-MM-DD').format('YYYY/MM/DD'),
                    dateTo: dayjs(filter.dateTo, 'YYYY-MM-DD').format('YYYY/MM/DD'),
                    entity: { ...entity, $name: entity.name }
                }))}`);
                break;
            default:
                break;
        }
    }
};

function RowButtons({ params, filter }) {
    const intl = useIntl();
    const buttonStyle = {
        px: 3,
        margin: '0 5px'
    };
    switch (params.row.type) {
        case 'position':
            return (
                <>
                    <Button color='error' sx={buttonStyle} onClick={() => handleShowFilterButtonClick(params, filter, 'cost')}>
                        {intl.formatMessage(messages.costs)}
                    </Button>
                    <Button color='success' sx={buttonStyle} onClick={() => handleShowFilterButtonClick(params, filter, 'income')}>
                        {intl.formatMessage(messages.incomes)}
                    </Button>
                </>
            );
        case 'transaction':
            return (
                <Button color='secondary' sx={buttonStyle} onClick={() => handleShowFilterButtonClick(params, filter, 'transaction')}>
                    {intl.formatMessage(messages.transactions)}
                </Button>
            );
        default:
            return null;
    }
}

RowButtons.propTypes = {
    params: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired
};

const renderNameCell = (params) => {
    if (params.row.isHeader) {
        return <b>{params.value}</b>;
    }
    return renderCellExpand(params);
};

const renderValueCell = (params) => {
    if (params.row.isHeader) {
        return (
            <b>
                {formatMoney(params.value)}
                &nbsp;PLN
            </b>
        );
    }
    return (
        <span>
            {formatMoney(params.value)}
            &nbsp;PLN
        </span>
    );
};

export default function useColumns(filter) {
    const intl = useIntl();
    return [
        {
            field: 'name',
            headerName: intl.formatMessage(messages.name),
            flex: 2,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params) => renderNameCell(params)
        },
        {
            headerName: intl.formatMessage(messages.actions),
            width: 250,
            maxWidth: 500,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params) => (
                <RowButtons params={params} filter={filter} />
            )
        },
        {
            field: 'sum',
            headerName: intl.formatMessage(messages.value),
            flex: 2,
            sortable: false,
            headerAlign: 'center',
            renderCell: renderValueCell
        }
    ];
}
