import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useIntl } from 'react-intl';
import Collapse from '@mui/material/Collapse';
import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routeTitles from '../../../config/routeTitles';
import prepareRouteTitleChildren from '../../../lib/prepareRouteTitleChildren';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: -drawerWidth
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    },
    submenu: {
        marginLeft: theme.spacing(1)
    }
}));

function MenuNode({ menuItems, root, onClose }) {
    const classes = useStyles();
    const user = useSelector((state) => state.user);
    const history = useHistory();
    const [openMenu, setOpenMenu] = useState([]);
    const intl = useIntl();
    useEffect(() => {
        const newData = [...openMenu];
        menuItems.forEach(() => {
            newData.push(false);
        });
        setOpenMenu(newData);
    }, []);
    const toggleOpenMenu = (index) => {
        const newData = [...openMenu];
        newData[index] = !newData[index];
        setOpenMenu(newData);
    };
    const handleClose = () => {
        const newData = [];
        menuItems.forEach(() => {
            newData.push(false);
        });
        setOpenMenu(newData);
        onClose();
    };

    return (
        <List className={!root ? classes.submenu : null}>
            {menuItems
                .filter((item) => !item.ignoreInMenu)
                .map(({ children, ...rest }) => ({ children: prepareRouteTitleChildren(children), ...rest }))
                .map((item, index) => (
                    (!item.roles || (user && item.roles.includes(user.role))) && (
                        <div key={`menu_${index}_${item.name.toLowerCase()}`}>
                            <ListItem
                                button
                                disabled={item.disabled || false}
                                onClick={() => {
                                    toggleOpenMenu(index);
                                    if (!item.children) {
                                        history.push(item.path);
                                        handleClose();
                                    }
                                }}
                            >
                                <ListItemText primary={intl.formatMessage({ id: item.translation })} />
                                { item.children && openMenu[index] && !item.disabled ? <IconExpandLess /> : null}
                                { item.children && !openMenu[index] && !item.disabled ? <IconExpandMore /> : null}
                            </ListItem>
                            {
                                item.children && item.children.length > 0 ? (
                                    <Collapse in={openMenu[index]} unmountOnExit>
                                        <Divider />
                                        <MenuNode menuItems={item.children} onClose={handleClose} />
                                    </Collapse>
                                ) : null
                            }
                        </div>
                    )
                ))}
        </List>
    );
}

MenuNode.propTypes = {
    menuItems: PropTypes.array.isRequired,
    root: PropTypes.bool,
    onClose: PropTypes.func
};
MenuNode.defaultProps = {
    root: false,
    onClose: () => {}
};

function AppMenu({ open, onClose }) {
    const classes = useStyles();
    const theme = useTheme();

    const closeMenu = () => {
        onClose();
    };
    return (
        <Drawer
            className={classes.drawer}
            variant='persistent'
            anchor='left'
            open={open}
            classes={{
                paper: classes.drawerPaper
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton
                    onClick={() => {
                        closeMenu();
                    }}
                    size='large'
                >
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />
            <MenuNode menuItems={routeTitles} root onClose={closeMenu} />
        </Drawer>
    );
}

AppMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
export default AppMenu;
