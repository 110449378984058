import currency from 'currency.js';

const formatSettings = {
    symbol: '',
    separator: ' ',
    decimal: '.'
};

const formatMoney = (value, zeroWhenEmpty = true) => {
    const val = (value || zeroWhenEmpty) ? currency(value, formatSettings).format() : '';
    return val !== 'NaN' ? val : value.toString();
};
export default formatMoney;
