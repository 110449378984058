import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Add from '@mui/icons-material/Add';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import useInfrastructureTypes from '../../hooks/useInfrastructureTypes';
import useInfrastructuresFromType from '../../hooks/useInfrastructuresFromType';
import useInfrastructuresProperties from '../../hooks/useInfrastructuresProperties';
import userRoles from '../../../../../constants/UserRoles';
import dateFormat from '../../../../../constants/dateFormat';
import UniversalCombobox, { comboboxTypes } from '../../../../common/Combobox/UniversalCombobox';
import HistoryDataGrid from '../../../../common/History/HistoryDataGrid';
import formatMoney from '../../../../../lib/formatMoney';
import formatUTCDate from '../../../../../lib/formatUTCDate';
import InfrastructureTypeAutoComplete from './ui/InfrastructureTypeAutoComplete/InfrastructureTypeAutoComplete';
import ParentInfrastructureInput from './ui/ParentInfrastructureInput/ParentInfrastructureInput';
import PropertiesInput from './ui/PropertiesInput/PropertiesInput';
import Counters from './ui/Counters/Counters';
import EntitiesGroup from './ui/EntitiesGroup/EnititiesGroup';
import getFormattingPropertiesInput from './ui/PropertiesInput/getFormattingPropertiesInput';
import NumberOfPeople from './ui/NumberOfPeople/NumberOfPeople';

const messages = {
    name: { id: 'app.infrastructures.name' },
    save: { id: 'app.infrastructures.save' },
    properties: { id: 'app.infrastructures.properties' },
    counters: { id: 'app.infrastructures.counters' },
    owners: { id: 'app.infrastructures.owners' },
    tenants: { id: 'app.infrastructures.tenants' },
    removeWarning: { id: 'app.infrastructures.removeWarning' },
    sync: { id: 'app.infrastructures.sync' },
    oldId: { id: 'app.infrastructures.oldId' },
    createdAt: { id: 'app.infrastructures.createdAt' },
    updatedAt: { id: 'app.infrastructures.updatedAt' },
    wasteRecipient: { id: 'app.infrastructures.wasteRecipient' },
    numberOfPeople: { id: 'app.infrastructures.numberOfPeople' },
    infrastructureParent: { id: 'app.infrastructures.parent' },
    history: { id: 'app.history.history' },
    numberOfVerifiedPeopleError: { id: 'app.infrastructures.numberOfVerifiedPeopleError' }
};
const useStyles = makeStyles(() => ({
    formMain: {
        width: '100%'
    },
    input: {
        width: '100%'
    },
    gridItem: {
        padding: '1em'
    },
    properties: {
        width: '100%'
    },
    counters: {
        width: '100%'
    },
    owners: {
        width: '100%'
    },
    addButton: {
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    accordionSummary: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const defaultInfrastructuresFormValues = (edit) => (edit
    ? ({
        name: edit.name,
        type_id: edit.type_id,
        parent_type_id: edit.parent_type_id,
        parent_id: edit.parent_id,
        properties: edit.properties,
        ingredients: edit.ingredients.map((ingredient) => ({
            ...ingredient,
            legalizationDate: formatUTCDate(ingredient.legalizationDate),
            norms: ingredient.norms.map((norm) => ({
                ...norm,
                value: formatMoney(norm.value),
                date: formatUTCDate(norm.date)
            })),
            rates: ingredient.rates.map((rate) => ({
                ...rate,
                value: formatMoney(rate.value),
                date: formatUTCDate(rate.date)
            }))
        })),
        owners: edit.owners.map((owner) => ({
            ...owner,
            date_from: formatUTCDate(owner.date_from),
            date_to: formatUTCDate(owner.date_to)
        })),
        tenants: edit.tenants.map((tenant) => ({
            ...tenant,
            date_from: formatUTCDate(tenant.date_from),
            date_to: formatUTCDate(tenant.date_to)
        })),
        will_sync: edit.will_sync,
        old_id: edit.old_id,
        waste_recipient: edit.waste_recipient && edit.waste_recipient[0],
        number_of_people: edit.number_of_people.map((it) => ({
            ...it,
            date: formatUTCDate(it.date)
        }))
    })
    : ({
        name: '',
        type_id: null,
        parent_type_id: null,
        parent_id: null,
        properties: [],
        ingredients: [],
        owners: [],
        tenants: [],
        will_sync: true,
        old_id: null,
        waste_recipient: null,
        number_of_people: []
    })
);

function InfrastructureForm({
    onSubmit,
    data,
    readOnly
}) {
    const classes = useStyles();
    const intl = useIntl();
    const user = useSelector((state) => state.user);
    const isAdmin = user?.role === userRoles.admin;
    const {
        register, control,
        handleSubmit, watch,
        getValues, setValue
    } = useForm({ defaultValues: defaultInfrastructuresFormValues(data) });
    const {
        fields, append, remove
    } = useFieldArray({
        control,
        name: 'properties'
    });

    const watchParentTypeId = watch('parent_type_id');
    const watchProperties = watch('properties');
    const { parentsOptions, loading: loadingParents } = useInfrastructuresFromType(watchParentTypeId);
    const { types, loading: loadingType } = useInfrastructureTypes();
    const { properties, loading: loadingProperties } = useInfrastructuresProperties();
    const [isParentTypeIdInitialized, setIsParentTypeIdInitialized] = useState(false);
    useEffect(() => {
        if (isParentTypeIdInitialized) {
            setValue('parent_id', null);
        } else {
            setIsParentTypeIdInitialized(true);
        }
    }, [watchParentTypeId]);

    const getPropertyType = (key) => properties.filter((item) => item.key === key)[0]?.type || null;

    const handleSubmitForm = (submittedData) => {
        const preparedData = submittedData;
        preparedData.properties = preparedData.properties.map((item) => {
            const resp = item;
            resp.value = getFormattingPropertiesInput(getPropertyType(resp.key)).formatFn(resp.value.toString()) || resp.value;
            return resp;
        });
        onSubmit(submittedData);
    };
    const getPropertiesOptions = (index) => properties
        .filter((item) => (!watchProperties.map((it) => it.key)
            .includes(item.key)) || item.key === watchProperties[index].key);
    const expandProperties = () => {
        append({ key: null, value: '' });
    };
    const handleDeleteProperty = (index) => {
        remove(index);
    };

    return !loadingType && !loadingProperties && (
        <form className={classes.formMain} onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid container>
                <Grid className={classes.gridItem} container item xs={6}>
                    <TextField
                        disabled={readOnly}
                        className={classes.input}
                        label={intl.formatMessage(messages.name)}
                        {...register('name')}
                    />
                </Grid>
                <Grid className={classes.gridItem} container item xs={isAdmin ? 4 : 6}>
                    <InfrastructureTypeAutoComplete control={control} name='type_id' options={types} readOnly={readOnly} />
                </Grid>
                {isAdmin && (
                    <Grid className={classes.gridItem} container item xs={2}>
                        <TextField
                            disabled={readOnly}
                            className={classes.input}
                            label={intl.formatMessage(messages.oldId)}
                            inputProps={{ pattern: '^\\d+$' }}
                            {...register('old_id')}
                        />
                    </Grid>
                )}

                <Grid className={classes.gridItem} container item xs={12}>
                    <Accordion className={classes.owners} expanded sx={{ border: '1px solid gray' }}>
                        <AccordionSummary sx={{ cursor: 'default !important' }}>
                            <div className={classes.accordionSummary}>
                                <FormattedMessage id={messages.infrastructureParent.id} />
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ParentInfrastructureInput
                                control={control}
                                name='parent_type_id'
                                options={types.filter((elm) => elm.key < getValues('type_id'))}
                                parentsOptions={parentsOptions}
                                readOnly={readOnly}
                                loading={loadingParents}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid className={classes.gridItem} item xs={12}>
                    <Controller
                        name='waste_recipient'
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <UniversalCombobox
                                src={comboboxTypes.infrastructureWasteRecipients()}
                                label={intl.formatMessage(messages.wasteRecipient)}
                                id='waste_recipient'
                                {...field}
                            />
                        )}
                    />
                </Grid>

                <Grid className={classes.gridItem} container item xs={6}>
                    <TextField
                        value={dayjs(data?.createdAt).format(dateFormat.fullDate)}
                        label={intl.formatMessage(messages.createdAt)}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid className={classes.gridItem} container item xs={6}>
                    <TextField
                        value={dayjs(data?.updatedAt).format(dateFormat.fullDate)}
                        label={intl.formatMessage(messages.updatedAt)}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid className={classes.gridItem} container item xs={12}>
                    <FormGroup>
                        <Controller
                            name='will_sync'
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Checkbox defaultChecked />}
                                    label={intl.formatMessage(messages.sync)}
                                    checked={field.value}
                                    {...field}
                                />
                            )}
                        />
                    </FormGroup>
                </Grid>
                <Grid className={classes.gridItem} container item xs={12}>
                    <Accordion className={classes.owners}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <div className={classes.accordionSummary}>
                                <FormattedMessage id={messages.owners.id} />
                                <Typography variant='body2'>{getValues('owners').length}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitiesGroup
                                register={register}
                                control={control}
                                name='owners'
                                removeTooltipText={intl.formatMessage(messages.removeWarning)}
                                readOnly={readOnly}
                                required
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid className={classes.gridItem} container item xs={12}>
                    <Accordion className={classes.owners}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <div className={classes.accordionSummary}>
                                <FormattedMessage id={messages.tenants.id} />
                                <Typography variant='body2'>{getValues('tenants').length}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <EntitiesGroup
                                register={register}
                                control={control}
                                name='tenants'
                                removeTooltipText={intl.formatMessage(messages.removeWarning)}
                                readOnly={readOnly}
                                required
                            />
                        </AccordionDetails>
                    </Accordion>

                </Grid>
                <Grid className={classes.gridItem} container item xs={12}>
                    <Accordion className={classes.properties}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <div className={classes.accordionSummary}>
                                <FormattedMessage id={messages.properties.id} />
                                <Typography variant='body2'>{getValues('properties').length}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {fields.map((field, index) => (
                                    <Grid container item xs={12} key={index}>
                                        <PropertiesInput
                                            onDelete={handleDeleteProperty}
                                            register={register}
                                            setValue={setValue}
                                            control={control}
                                            index={index}
                                            name={`properties.${index}.key`}
                                            options={getPropertiesOptions(index)}
                                            readOnly={readOnly}
                                            type={getPropertyType(watchProperties[index].key)}
                                            field={field}
                                        />
                                    </Grid>
                                ))}
                                {
                                    !readOnly && (
                                        <Grid item xs={12} sm={12}>
                                            <IconButton
                                                onClick={expandProperties}
                                                color='primary'
                                                component='span'
                                                className={classes.addButton}
                                                size='large'
                                            >
                                                <Add />
                                            </IconButton>
                                        </Grid>
                                    )
                                }
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid className={classes.gridItem} container item xs={12}>
                    <Accordion className={classes.counters}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <div className={classes.accordionSummary}>
                                <FormattedMessage id={messages.numberOfPeople.id} />
                                <Typography variant='body2'>{getValues('number_of_people')?.length}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <NumberOfPeople
                                control={control}
                                register={register}
                                setValue={setValue}
                                readOnly={readOnly}
                                getValues={getValues}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid className={classes.gridItem} container item xs={12}>
                    <Accordion className={classes.counters}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <div className={classes.accordionSummary}>
                                <FormattedMessage id={messages.counters.id} />
                                <Typography variant='body2'>{getValues('ingredients').length}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Counters
                                control={control}
                                register={register}
                                disableChargedOptionFn={(item) => (item === 'Tenant' && !getValues('tenants').length > 0)}
                                readOnly={readOnly}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {isAdmin && (
                    <Grid className={classes.gridItem} container item xs={12}>
                        <Accordion className={classes.counters}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <div className={classes.accordionSummary}>
                                    <FormattedMessage id={messages.history.id} />
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <HistoryDataGrid collectionName='infrastructures' recordId={data?._id} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                )}

                {
                    !readOnly && (
                        <Grid className={classes.gridItem} container item xs={12}>
                            <Button
                                className={classes.addButton}
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                <FormattedMessage id={messages.save.id} />
                            </Button>
                        </Grid>
                    )
                }

            </Grid>
        </form>
    );
}
InfrastructureForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object,
    readOnly: PropTypes.bool
};
InfrastructureForm.defaultProps = {
    data: undefined,
    readOnly: false
};
export default InfrastructureForm;
