import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useBus, useListener } from 'react-bus';
import IconButton from '@mui/material/IconButton';
import InputIcon from '@mui/icons-material/Input';
import makeStyles from '@mui/styles/makeStyles';
import config from './formConfig';

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative'
    },
    selectionPicker: {
        position: 'absolute',
        display: 'inline-block',
        right: 5,
        top: '55%',
        transform: 'translate(0, -50%)'
    }
}));

function FormPickerElement({ children, name, disabled }) {
    const bus = useBus();
    const classes = useStyles();
    const [selected, setSelected] = useState(false);
    const [enable, setEnable] = useState(false);

    useListener(config.bus.ready, () => {
        bus.emit(config.bus.init, name);
    });

    useListener(config.bus.enable, (value) => {
        setEnable(value);
    });

    useListener(config.bus.pickerChange, (newName) => {
        if (newName === name) {
            setSelected(true);
            return;
        }
        setSelected(false);
    });

    useEffect(() => {
        bus.emit(config.bus.isEnabled);
    }, []);

    const handleClick = () => {
        bus.emit(config.bus.click, name);
    };

    return (
        <div className={classes.container}>
            {children}
            <IconButton
                style={{ display: (enable && !disabled) ? 'block' : 'none' }}
                className={classes.selectionPicker}
                onClick={handleClick}
                color={selected ? 'primary' : 'default'}
                size='large'
            >
                <InputIcon />
            </IconButton>
        </div>
    );
}

FormPickerElement.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
    disabled: PropTypes.bool
};

FormPickerElement.defaultProps = {
    children: null,
    disabled: false
};

export default FormPickerElement;
