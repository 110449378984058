export const newContactItem = () => ({
    type: null,
    value: '',
    content: '',
    verified: false
});

export const newAddressItem = () => ({
    type: null,
    country: '',
    voivodeship: '',
    county: '',
    commune: '',
    post: '',
    city: '',
    postal_code: '',
    street: '',
    number: ''
});

export const newBankAccountItem = () => ({
    name: '',
    number: '',
    active: false
});
