import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import EntitiesAutoComplete
    from '../EditInfrastructure/InfrastructureForm/ui/EntitiesAutoComplete/EntititesAutoComplete';

const messages = {
    addInfrastructure: { id: 'app.infrastructures.addInfrastructure' },
    owners: { id: 'app.infrastructures.owners' },
    tenants: { id: 'app.infrastructures.tenants' },
    addButton: { id: 'app.menu.add' },
    cancelButton: { id: 'app.menu.cancel' },
    infrastructureName: { id: 'app.infrastructures.name' }
};

function AddInfrastructure({
    type, parentID, open, onClose
}) {
    const theme = useTheme();
    const intl = useIntl();
    const {
        control, register, reset, handleSubmit
    } = useForm();

    const handleAdd = async (formData) => {
        const { name, owners, tenants } = formData;
        const data = {
            name,
            owners: owners.map((owner) => owner._id),
            tenants: tenants.map((tenant) => tenant._id),
            parentID,
            type
        };
        await RestRequestsHelper.addInfrastructure(data);
        reset();
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
        >
            <form onSubmit={handleSubmit(handleAdd)}>
                <DialogTitle id='form-dialog-title'>
                    {intl.formatMessage(messages.addInfrastructure)}
                </DialogTitle>
                <DialogContent
                /* Workaround for clipped TextField label https://github.com/mui/material-ui/issues/29892 */
                    sx={{ pt: `${theme.spacing(2)} !important` }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                variant='outlined'
                                required
                                fullWidth
                                label={
                                    intl.formatMessage(
                                        { id: messages.infrastructureName.id }
                                    )
                                }
                                {...register('name')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EntitiesAutoComplete
                                label={intl.formatMessage({ id: messages.owners.id })}
                                multiple
                                control={control}
                                name='owners'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EntitiesAutoComplete
                                label={intl.formatMessage({ id: messages.tenants.id })}
                                multiple
                                control={control}
                                name='tenants'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={onClose}
                        color='primary'
                    >
                        <FormattedMessage id={messages.cancelButton.id} />
                    </Button>
                    <Button
                        type='submit'
                        variant='contained'
                        color='secondary'
                    >
                        <FormattedMessage id={messages.addButton.id} />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

AddInfrastructure.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    parentID: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired
};
export default AddInfrastructure;
