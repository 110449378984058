import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

const messages = {
    infrastructureElement: 'app.infrastructures.element'
};
const useStyles = makeStyles(() => ({
    input: {
        width: '100%'
    }
}));

function InfrastructureParentAutoComplete({
    options,
    name,
    control,
    readOnly,
    loading
}) {
    const intl = useIntl();
    const classes = useStyles();
    return (
        <Controller
            onChange={([, obj]) => obj._id}
            defaultValue={null}
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    className={classes.input}
                    value={value}
                    options={options || []}
                    getOptionLabel={(option) => {
                        if (options) {
                            const type = options.find((it) => it._id === option);
                            if (type) {
                                return type.name;
                            }
                            return option.name;
                        }
                        return '';
                    }}
                    disabled={readOnly}
                    isOptionEqualToValue={(option, valueData) => option.key === valueData}
                    renderInput={(params) => (
                        <TextField
                            className={classes.input}
                            {...params}
                            fullWidth
                            label={intl.formatMessage({ id: messages.infrastructureElement })}
                        />
                    )}
                    onChange={(event, dataObj) => onChange(dataObj._id)}
                    loading={loading}
                />
            )}
        />
    );
}

InfrastructureParentAutoComplete.propTypes = {
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    loading: PropTypes.bool.isRequired
};

InfrastructureParentAutoComplete.defaultProps = {
    readOnly: false
};

export default InfrastructureParentAutoComplete;
