import React from 'react';
import { useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';

const messages = {
    selectAll: { id: 'app.invoiceForm.selectAll' }
};

interface SelectAllButtonProps {
    onSelectAll: () => Promise<void>,
    loading: boolean
}

function SelectAllButton({ onSelectAll, loading }: SelectAllButtonProps) {
    const intl = useIntl();
    return (
        <LoadingButton
            onClick={onSelectAll}
            loading={loading}
            fullWidth
            variant='outlined'
        >
            {intl.formatMessage(messages.selectAll)}
        </LoadingButton>
    );
}

export default SelectAllButton;
