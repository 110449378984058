import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { DateTimePicker } from '@mui/x-date-pickers';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import dateFormat from '../../../constants/dateFormat';
import UniversalCombobox, { comboboxTypes } from '../Combobox/UniversalCombobox';
import ListItems from '../ListItems/ListItems';
import { HotkeyProvider } from '../Hotkeys/Hotkeys';
import FormPickerMaster from '../FormPicker/FormPickerMaster';
import FormHeader from '../FormHeader/FormHeader';
import userRoles from '../../../constants/UserRoles';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';
import FormFileManager, { prepareFilesForUpload, prepareRemarkFilesForUpload } from '../FormFileManager/FormFileManager';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import defaultVindicationFormValues, { defaultRemarks, defaultReminder } from './shared/data/defaultVindicationFormValues';
import useActionsColumns from './shared/hooks/useColumns';
import ACTION_TYPES from './shared/data/actionTypes';

const useStyle = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        '& .MuiGrid-item': {
            padding: '4px'
        },
        width: '100%'
    },
    select: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    selectItem: {
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(1)
    },
    toolTip: {
        padding: theme.spacing(0.5)
    },
    metadata: {
        color: theme.palette.grey[500],
        float: 'left',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    loading: {
        alignSelf: 'center'
    },
    buttons: {
        bottom: theme.spacing(1),
        zIndex: 1,
        position: 'sticky',
        marginTop: 'auto'
    },
    accordionSummary: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

const messages = {
    addVindication: { id: 'app.vindication.addVindication' },
    editVindication: { id: 'app.vindication.editVindication' },
    saveChanges: { id: 'app.vindication.saveChanges' },
    concerns: { id: 'app.vindication.concerns' },
    filterByConcernsHint: { id: 'app.vindication.filterByConcernsHint' },
    leader: { id: 'app.vindication.leader' },
    producer: { id: 'app.vindication.producer' },
    content: { id: 'app.vindication.content' },
    lastChargeDate: { id: 'app.vindication.lastChargeDate' },
    debtor: { id: 'app.vindication.debtor' },
    remarks: { id: 'app.vindication.remarks' },
    reminder: { id: 'app.vindication.reminder' },
    reminderEntity: { id: 'app.vindication.reminderEntity' },
    reminderContact: { id: 'app.vindication.reminderContact' },
    status: { id: 'app.vindication.status' },
    clear: { id: 'app.vindication.clear' },
    confirm: { id: 'app.vindication.confirm' },
    cancel: { id: 'app.vindication.cancel' },
    today: { id: 'app.vindication.today' },
    category: { id: 'app.vindication.category' },
    dateOkButton: { id: 'app.vindication.dateOkButton' },
    dateCancelButton: { id: 'app.vindication.dateCancelButton' },
    dateTodayButton: { id: 'app.vindication.dateTodayButton' },
    dateClearButton: { id: 'app.vindication.dateClearButton' },
    applicantDataTooltip: { id: 'app.vindication.applicantDataTooltip' },
    addVindicationDate: { id: 'app.vindication.addVindicationDate' },
    editVindicationDate: { id: 'app.vindication.editVindicationDate' },
    author: { id: 'app.vindication.author' },
    updatedAt: { id: 'app.vindication.updatedAt' },
    reminderNow: { id: 'app.vindication.reminderNow' },
    reminderNowHint: { id: 'app.vindication.reminderNowHint' },
    statusHistory: { id: 'app.vindication.statusHistory' },
    categoryHistory: { id: 'app.vindication.categoryHistory' },
    type: { id: 'app.vindication.type' }
};

function VindicationForm({
    onSubmit, onDelete, filterByConcerns, readOnly
}) {
    const classes = useStyle();
    const intl = useIntl();
    const user = useSelector((state) => state.user);
    const stateVindication = useSelector((state) => state.vindication);
    const [edit, setEdit] = useState(stateVindication);
    const actionsColumns = useActionsColumns();
    const {
        register, control,
        handleSubmit, reset,
        setValue, getValues, watch
    } = useForm({ defaultValues: defaultVindicationFormValues(user, edit) });
    const watchReminders = watch('reminders');
    const watchConcerns = watch('concerns');
    const locale = useSelector((state) => state.locale);
    // Here we hold a field name holding currently focused files
    // The field name is expressed with an array of strings
    const [filesField, setFilesField] = useState(['files']);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const statusActions = edit?.actions?.filter((el) => el.type === ACTION_TYPES.status);
    const categoryActions = edit?.actions?.filter((el) => el.type === ACTION_TYPES.category);
    dayjs.locale(locale);

    useEffect(() => {
        setEdit(stateVindication);
    }, [stateVindication]);

    const fileManagerRef = useRef();

    const handleResetForm = () => {
        reset(defaultVindicationFormValues(user, edit));
        fileManagerRef.current.flushFiles();
    };

    // Submit the form
    const handleSubmitForm = async (data) => {
        setIsSubmiting(true);
        const filesData = prepareFilesForUpload(data?.files);
        const remarksFilesData = prepareRemarkFilesForUpload(data?.remarks);

        const preparedFilesObj = {
            files: filesData.files,
            remarks: remarksFilesData.files
        };

        const preparedData = {
            ...data,
            id: edit?._id,
            // Extract due date if possible
            lastChargeDate: data.lastChargeDate,
            // Ignore files and assign user ID instead of the whole user object
            // eslint-disable-next-line object-curly-newline
            remarks: data.remarks.map(({ files, user: userData, type, ...rest }) => ({ user: userData?._id, type: type?.key, ...rest })),
            // Metadata of the files assigned to the main vindication
            filesMetadata: filesData.metadata,
            // Metadata of the files assigned to the remarks
            remarksFilesMetadata: remarksFilesData.metadata,
            // Remove cache from the reminders
            reminders: data.reminders.map(({ _new, _isSent, ...reminder }) => reminder),
            files: null
        };
        if (await onSubmit(preparedData, preparedFilesObj)) {
            handleResetForm();
        }
        setIsSubmiting(false);
    };

    const statusOptionLabel = ({ value, description }) => `${value} ${description ? `(${description})` : ''}`;

    const contactWithOptionLabel = ({ value, typeName }) => `${value} (${typeName})`;

    const contactSrc = (index) => {
        const reminder = watchReminders[index];
        if (reminder?.entity) {
            return comboboxTypes.contactsByName(reminder.entity._id);
        }
        return comboboxTypes.empty();
    };

    const renderRemarkMetadata = (field, value) => {
        if (field === 'user' && value) {
            return `${value.firstName} ${value.lastName}`;
        }
        if (field === 'updatedAt' && value) {
            return dayjs(value).format('DD-MM-YYYY HH:mm');
        }
        return '';
    };

    const userCanEdit = (value) => {
        const id = value?.user?._id;
        if (id) {
            return value?.user?._id === user.sub;
        }
        return true;
    };

    const setReminderNow = (index) => {
        const reminders = [...getValues('reminders')];
        reminders[index].date = dayjs().add(3, 'minute');
        setValue('reminders', reminders);
    };

    const handleFilesFieldChange = (field) => {
        if (isEqual(field, filesField)) {
            setFilesField(['files']);
        } else {
            setFilesField(field);
        }
    };

    const handleRemarksRemove = (newItems, index) => {
        if (filesField[0] === 'remarks') {
            if (filesField[1] === index && index >= 0) {
                if (!newItems.length) {
                    setFilesField(['files']);
                } else {
                    setFilesField(['remarks', index - 1, 'files']);
                }
            }
        }
    };

    const entityWithAddressOptionLabel = ({ name, address = [] }) => {
        if (address.length) {
            const [addr] = address;
            const textual = [
                addr.city,
                addr.street,
                addr.number
            ].join(' ');
            return `${name} (${textual})`;
        }
        return name || '';
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className={classes.root}>
                <FormPickerMaster>
                    <HotkeyProvider>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={1} alignItems='center' sx={{ height: '100%' }}>
                                    <Grid item xs={12}>
                                        <FormHeader
                                            label={edit ? messages.editVindication.id : messages.addVindication.id}
                                            values={edit ? { number: edit.numberUI } : null}
                                            hideDelete={readOnly || !edit}
                                            onDelete={onDelete}
                                        />
                                    </Grid>
                                    <Grid item xs={edit ? 11 : 12}>
                                        <Controller
                                            name='concerns'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.communities(500)}
                                                    label={intl.formatMessage(messages.concerns)}
                                                    id='concerns'
                                                    required
                                                    longerList
                                                    displayInfo
                                                    multiple
                                                    selectIfSingleton
                                                    disabled={readOnly}
                                                    showColors
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {edit && (
                                        <Grid item xs={1}>
                                            <Tooltip title={intl.formatMessage(messages.filterByConcernsHint)}>
                                                <IconButton
                                                    onClick={() => {
                                                        filterByConcerns(getValues('concerns'));
                                                    }}
                                                    size='large'
                                                    disabled={!getValues('concerns')}
                                                >
                                                    <FilterListIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                    <Grid item xs={4}>
                                        <TextField
                                            label={intl.formatMessage(messages.author)}
                                            variant='outlined'
                                            fullWidth
                                            disabled
                                            value={edit?.authorUI || (!edit ? user?.name : null)}
                                            sx={{ input: { color: edit?.author?.color, WebkitTextFillColor: 'unset !important' } }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name='debtor'
                                            control={control}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.entitiesOfCommunityByName(watchConcerns?.map?.((el) => el._id))}
                                                    label={intl.formatMessage(messages.debtor)}
                                                    id='debtor'
                                                    optionLabel={entityWithAddressOptionLabel}
                                                    showColors
                                                    required
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name='leader'
                                            control={control}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.users([userRoles.admin, userRoles.user])}
                                                    label={intl.formatMessage(messages.leader)}
                                                    id='leader'
                                                    showColors
                                                    required
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name='lastChargeDate'
                                            control={control}
                                            render={({ field }) => (
                                                <CustomDatePicker
                                                    disabled={readOnly}
                                                    showTodayButton
                                                    clearable
                                                    isRequired
                                                    variant='outlined'
                                                    inputFormat={dateFormat.format}
                                                    label={intl.formatMessage(messages.lastChargeDate)}
                                                    okLabel={intl.formatMessage(messages.dateOkButton)}
                                                    cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                                    todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                                    clearLabel={intl.formatMessage(messages.dateClearButton)}
                                                    renderInput={(params) => (<TextField fullWidth {...params} name='lastChargeDate' required />)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name='category'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.vindicationCategories()}
                                                    label={intl.formatMessage(messages.category)}
                                                    id='category'
                                                    required
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant='outlined'
                                            required
                                            multiline
                                            fullWidth
                                            label={intl.formatMessage(messages.content)}
                                            inputProps={{ maxLength: 300 }}
                                            disabled={!!edit || readOnly}
                                            {...register('content', { required: true })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                            <FormattedMessage id={messages.remarks.id} />
                                        </Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name='remarks'
                                            control={control}
                                            render={({ field }) => (
                                                <ListItems
                                                    onNewItem={() => defaultRemarks(user)}
                                                    onItemRemove={handleRemarksRemove}
                                                    canBeRemoved={userCanEdit}
                                                    buttonsPosition='top'
                                                    hideActions={readOnly}
                                                    render={(fields, index, value) => (
                                                        <Grid
                                                            container
                                                            className={classes.remarks}
                                                            sx={value?.user?.color ? {
                                                                borderColor: value?.user?.color, borderRadius: 1, borderWidth: 2, borderStyle: 'solid', p: 1
                                                            } : { p: 1 }}
                                                        >
                                                            {edit && (
                                                                <Grid container sx={{ textAlign: 'left' }}>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant='caption'>
                                                                            {`${intl.formatMessage(messages.author)}: ${renderRemarkMetadata('user', value.user)}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant='caption'>
                                                                            {`${intl.formatMessage(messages.updatedAt)}: ${renderRemarkMetadata('updatedAt', value.updatedAt)}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            <Grid container sx={{ mt: 1 }} alignItems='center'>
                                                                <Grid item xs={2}>
                                                                    <UniversalCombobox
                                                                        src={comboboxTypes.remarkTypes()}
                                                                        label={intl.formatMessage(messages.type)}
                                                                        id='type'
                                                                        disabled={!userCanEdit(value) || readOnly}
                                                                        required
                                                                        disableClearable
                                                                        optionLabel={(title) => title?.$name?.slice(0, 3)}
                                                                        {...fields('type')}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField
                                                                        disabled={!userCanEdit(value) || readOnly}
                                                                        required
                                                                        fullWidth
                                                                        multiline
                                                                        variant='outlined'
                                                                        label={intl.formatMessage(messages.remarks)}
                                                                        {...fields('value')}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <IconButton
                                                                        className={classes.gridButton}
                                                                        onClick={() => handleFilesFieldChange(['remarks', index, 'files'])}
                                                                        color={isEqual(filesField, ['remarks', index, 'files']) ? 'primary' : 'default'}
                                                                        size='large'
                                                                    >
                                                                        <FindInPageIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    {
                                                                        value.files?.length || 0
                                                                    }
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion sx={{ p: 0 }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
                                                <div className={classes.accordionSummary}>
                                                    <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                                        <FormattedMessage id={messages.reminder.id} />
                                                    </Typography>
                                                    <Typography variant='body2'>{edit?.reminders?.length || 0}</Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ p: 0 }}>
                                                <Controller
                                                    name='reminders'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <ListItems
                                                            canBeRemoved={(value) => !value._isSent}
                                                            onNewItem={defaultReminder}
                                                            buttonsPosition='top'
                                                            hideActions={readOnly}
                                                            sortFn={(_a, _b) => (edit ? new Date(_b.date) - new Date(_a.date) : 0)}
                                                            render={(fields, index) => (
                                                                <Grid container alignItems='center'>
                                                                    <Grid item xs={4}>
                                                                        <UniversalCombobox
                                                                            required
                                                                            displayInfo
                                                                            src={comboboxTypes.entitiesContactsByName()}
                                                                            showColors
                                                                            label={intl.formatMessage(messages.reminderEntity)}
                                                                            disabled={fields('_isSent')?.value || readOnly}
                                                                            {...fields('entity')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <UniversalCombobox
                                                                            required
                                                                            src={contactSrc(index)}
                                                                            label={intl.formatMessage(messages.reminderContact)}
                                                                            optionLabel={contactWithOptionLabel}
                                                                            disabled={!watchReminders[index]?.entity || fields('_isSent')?.value || readOnly}
                                                                            {...fields('value')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <DateTimePicker
                                                                            required
                                                                            disabled={fields('_isSent')?.value || readOnly}
                                                                            disablePast
                                                                            ampm={false}
                                                                            showTodayButton
                                                                            variant='outlined'
                                                                            inputFormat={dateFormat.dateWithHoursAndMinutes}
                                                                            margin='normal'
                                                                            okLabel={intl.formatMessage(messages.dateOkButton)}
                                                                            cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                                                            todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                                                            renderInput={(params) => (<TextField fullWidth {...params} />)}
                                                                            {...fields('date')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Tooltip title={intl.formatMessage(messages.reminderNowHint)}>
                                                                            <Button
                                                                                onClick={() => setReminderNow(index)}
                                                                                disabled={fields('_isSent')?.value}
                                                                            >
                                                                                <FormattedMessage id={messages.reminderNow.id} />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    {edit ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name='producers'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <UniversalCombobox
                                                            multiple
                                                            src={comboboxTypes.producers()}
                                                            label={intl.formatMessage(messages.producer)}
                                                            disabled={readOnly}
                                                            showColors
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controller
                                                    required
                                                    name='status'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <UniversalCombobox
                                                            showColors
                                                            required
                                                            src={comboboxTypes.vindicationStatuses()}
                                                            label={intl.formatMessage(messages.status)}
                                                            optionLabel={statusOptionLabel}
                                                            selectIfSingleton
                                                            disabled={readOnly}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {edit?.actions && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Accordion sx={{ p: 0 }}>
                                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
                                                                <div className={classes.accordionSummary}>
                                                                    <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                                                        <FormattedMessage id={messages.categoryHistory.id} />
                                                                    </Typography>
                                                                    <Typography variant='body2'>{categoryActions.length || 0}</Typography>
                                                                </div>
                                                            </AccordionSummary>
                                                            <AccordionDetails sx={{ p: 0 }}>
                                                                <DataGrid
                                                                    autoHeight
                                                                    rows={categoryActions.map((elm, i) => ({ ...elm, index: i }))}
                                                                    columns={actionsColumns}
                                                                    initialState={{
                                                                        sorting: {
                                                                            sortModel: [{ field: 'date', sort: 'desc' }]
                                                                        }
                                                                    }}
                                                                    getRowId={(row) => row.index}
                                                                    sx={{ mb: 1 }}
                                                                    columnVisibilityModel={convertColumnsToVisibilityModel(actionsColumns)}
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Accordion sx={{ p: 0 }}>
                                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
                                                                <div className={classes.accordionSummary}>
                                                                    <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                                                        <FormattedMessage id={messages.statusHistory.id} />
                                                                    </Typography>
                                                                    <Typography variant='body2'>{statusActions.length || 0}</Typography>
                                                                </div>
                                                            </AccordionSummary>
                                                            <AccordionDetails sx={{ p: 0 }}>
                                                                <DataGrid
                                                                    autoHeight
                                                                    rows={statusActions.map((elm, i) => ({ ...elm, index: i }))}
                                                                    columns={actionsColumns}
                                                                    initialState={{
                                                                        sorting: {
                                                                            sortModel: [{ field: 'date', sort: 'desc' }]
                                                                        }
                                                                    }}
                                                                    getRowId={(row) => row.index}
                                                                    sx={{ mb: 1 }}
                                                                    columnVisibilityModel={convertColumnsToVisibilityModel(actionsColumns)}
                                                                />
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={6}>
                                                <CustomDatePicker
                                                    useLocalTime
                                                    variant='outlined'
                                                    inputFormat={dateFormat.fullDate}
                                                    margin='normal'
                                                    id='addDate'
                                                    label={(
                                                        <FormattedMessage
                                                            id={messages.addVindicationDate.id}
                                                        />
                                                    )}
                                                    value={edit.createdAt}
                                                    renderInput={(params) => (<TextField fullWidth {...params} />)}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <CustomDatePicker
                                                    useLocalTime
                                                    variant='outlined'
                                                    inputFormat={dateFormat.fullDate}
                                                    renderInput={(params) => (<TextField fullWidth {...params} />)}
                                                    margin='normal'
                                                    id='editDate'
                                                    label={(
                                                        <FormattedMessage
                                                            id={messages.editVindicationDate.id}
                                                        />
                                                    )}
                                                    value={edit.updatedAt}
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    ) : null}
                                    {
                                        !readOnly && (
                                            <Grid container className={classes.buttons}>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        fullWidth
                                                        onClick={handleResetForm}
                                                    >
                                                        <FormattedMessage id={messages.clear.id} />
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LoadingButton
                                                        type='submit'
                                                        variant='contained'
                                                        color='primary'
                                                        fullWidth
                                                        loading={isSubmiting}
                                                        loadingPosition='start'
                                                    >
                                                        <FormattedMessage
                                                            id={edit ? messages.saveChanges.id : messages.addVindication.id}
                                                        />
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormFileManager
                                    setValue={setValue}
                                    getValues={getValues}
                                    currentField={filesField}
                                    readOnly={readOnly}
                                    ref={fileManagerRef}
                                />
                            </Grid>
                        </Grid>
                    </HotkeyProvider>
                </FormPickerMaster>
            </div>
        </form>
    );
}

VindicationForm.propTypes = {
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    filterByConcerns: PropTypes.func,
    edit: PropTypes.object,
    inhabitantMode: PropTypes.bool,
    readOnly: PropTypes.bool,
    applicantFilter: PropTypes.object
};

VindicationForm.defaultProps = {
    onSubmit: () => {},
    onDelete: () => {},
    filterByConcerns: () => {},
    edit: null,
    inhabitantMode: false,
    readOnly: false,
    applicantFilter: () => {}
};

export default VindicationForm;
