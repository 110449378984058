import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import RestRequestsHelper from '../../../lib/restRequestsHelper';

const messages = {
    content: {
        id: 'app.addInvoice.content'
    },
    contentSingle: {
        id: 'app.addInvoice.content.single'
    }
};

const getPositionsTitles = (query) => RestRequestsHelper.getInvoicePositionsTitles(query.queryKey[1]);
function InvoicePositionsTitlesCombobox({
    value, onChange, invoicesBrowseRender, multiple, fieldName, db, disabled
}) {
    const [options, setOptions] = useState([]);
    const { data, status } = useQuery(['position-titles', db], getPositionsTitles);
    const intl = useIntl();
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        setInitialized(true);
    }, []);
    useEffect(() => {
        if (status === 'success') {
            setOptions(data.data);
        }
    }, [status]);

    return (
        <div>
            <Autocomplete
                value={value}
                multiple={multiple}
                onChange={(event, newValue) => {
                    onChange(event, newValue, initialized);
                }}
                isOptionEqualToValue={(option, val) => option.id === val.id}
                options={options}
                getOptionLabel={(option) => {
                    if (option) {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.title;
                    }
                    return '';
                }}
                renderOption={(props, option) => (
                    <li {...props}>
                        {' '}
                        {option.title}
                        {' '}
                    </li>
                )}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        /* eslint-disable-next-line */
                        {...params}
                        required={!invoicesBrowseRender}
                        label={intl.formatMessage({ id: invoicesBrowseRender ? messages.contentSingle.id : messages.content.id })}
                        variant='outlined'
                        margin={invoicesBrowseRender ? 'none' : 'normal'}
                        name={fieldName}
                    />
                )}
            />
        </div>
    );
}
InvoicePositionsTitlesCombobox.defaultProps = {
    invoicesBrowseRender: false,
    multiple: false,
    fieldName: null,
    db: null,
    disabled: false
};
InvoicePositionsTitlesCombobox.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    invoicesBrowseRender: PropTypes.bool,
    multiple: PropTypes.bool,
    fieldName: PropTypes.string,
    db: PropTypes.string,
    disabled: PropTypes.bool
};

export default InvoicePositionsTitlesCombobox;
