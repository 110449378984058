import React from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import dateFormat from '../../../constants/dateFormat';

interface CustomDatePicker extends DatePickerProps<string | Dayjs, Dayjs>{
    // eslint-disable-next-line react/require-default-props
    useLocalTime?: boolean,
    onChange: (newDate: string | Dayjs | null | undefined) => void
}

function CustomDatePicker({
    useLocalTime = false, onChange, value, ...props
}: CustomDatePicker) {
    let dateProp;
    if (dayjs.isDayjs(value)) {
        dateProp = value.format();
    } else {
        dateProp = value;
    }
    const dateWithoutTime = dateProp && dateProp.split('T')[0];
    const formattedValue = useLocalTime ? value : dateWithoutTime;

    const handleOnChange = (newValue: Dayjs | null): void => {
        const formattedDate = newValue?.format(dateFormat.reversedFormat);
        onChange(useLocalTime ? newValue : formattedDate);
    };
    return (
        <DatePicker
            value={formattedValue}
            onChange={handleOnChange}
            {...props}
        />
    );
}
export default CustomDatePicker;
