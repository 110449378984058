import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { useIntl, FormattedMessage } from 'react-intl';
import { debounce, cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';
import CustomDatePicker from '../../common/CustomDatePicker/CustomDatePicker';
import { paginationAllowedValues, paginationDefaultValue } from '../../../constants/pagination';
import UniversalCombobox, { comboboxTypes } from '../../common/Combobox/UniversalCombobox';
import dateFormat from '../../../constants/dateFormat';
import MoneyTextField from '../../common/MoneyTextField/MoneyTextField';
import { parseFormattedMoney } from '../../common/DataGridCells/GridMoneyCell';
import onKeyDetect from '../../../lib/onKeyDetect';
import DataGridFooter from '../../common/DataGridFooter/DataGridFooter';
import BalanceFooterElm from '../../common/DataGridFooter/shared/BalanceFooterElm';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';
import SettlementTypes from '../../../constants/SettlementTypes';
import validateDate from '../../../lib/validateDate';
import useColumns from './hooks/useColumns';
import useSettlements from './hooks/useSettlements';
import SettlementsToolbar from './Toolbar/SettlementsToolbar';
import useSums from './hooks/useSums';

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '80vh',
        textAlign: 'left'
    }
}));

export const defaultFilter = () => ({
    entity: {
        value: [],
        filter: false
    },
    contractor: {
        value: [],
        filter: false
    },
    transactionType: {
        value: [],
        filter: false
    },
    documentType: {
        value: [],
        filter: false
    },
    dateFilter: {
        value: 'invoiceDate',
        filter: true
    },
    dateFrom: {
        value: dayjs().startOf('year').format(dateFormat.reversedFormat),
        filter: true
    },
    dateTo: {
        value: dayjs().endOf('year').format(dateFormat.reversedFormat),
        filter: true
    },
    amountFrom: {
        value: 0,
        filter: false
    },
    amountTo: {
        value: 0,
        filter: false
    },
    excludeBalance: {
        value: false,
        filter: true
    }
});

const filterClearedAttr = (name, value) => {
    switch (name) {
        case 'entity':
            return value && typeof value === 'object' && !!value.length;
        case 'contractor':
            return value && typeof value === 'object' && !!value.length;
        case 'transactionType':
            return value && typeof value === 'object' && !!value.length;
        case 'documentType':
            return value && typeof value === 'object' && !!value.length;
        case 'dateFilter':
            return typeof value === 'string';
        case 'dateFrom':
            return value !== null && validateDate(value);
        case 'dateTo':
            return value !== null && validateDate(value);
        case 'amountFrom':
            return typeof value === 'number';
        case 'amountTo':
            return typeof value === 'number';
        case 'excludeBalance':
            return typeof value === 'boolean';
        default:
            return false;
    }
};

const messages = {
    entity: { id: 'app.settlements.entity' },
    contractor: { id: 'app.settlements.contractor' },
    documentType: { id: 'app.form.documentType' },
    transactionType: { id: 'app.transactions.transactionType' },
    dateFrom: { id: 'app.settlements.dateFrom' },
    dateTo: { id: 'app.settlements.dateTo' },
    amountFrom: { id: 'app.settlements.amountFrom' },
    amountTo: { id: 'app.settlements.amountTo' },
    dateOkButton: { id: 'app.datePicker.okButton' },
    dateCancelButton: { id: 'app.datePicker.cancelButton' },
    dateTodayButton: { id: 'app.datePicker.todayButton' },
    sumDocuments: { id: 'app.transactions.sumDocuments' },
    sumTransactions: { id: 'app.transactions.sumTransactions' },
    balance: { id: 'app.settlements.balance' },
    fillEntityFieldToGenerate: { id: 'app.settlements.fillEntityFieldToGenerate' },
    excludeBalance: { id: 'app.settlements.excludeBalance' },
    filterDatesBy: { id: 'app.invoices.filterDatesBy' },
    saleDate: { id: 'app.addInvoice.saleDate' },
    invoiceDate: { id: 'app.invoices.invoiceDate' },
    paymentDate: { id: 'app.invoices.payementDate' }
};

const dateFilterOptions = ['saleDate', 'invoiceDate', 'paymentDate'];

function GenerationDisabledDataGridInfo() {
    return (
        <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'
        }}
        >
            <FormattedMessage id={messages.fillEntityFieldToGenerate.id} />
        </Box>
    );
}

function Settlements() {
    const classes = useStyles();
    const intl = useIntl();
    const [pageSize, setPageSize] = useState(paginationDefaultValue);
    const [page, setPage] = useState(0);
    const [filterData, setFilterData] = useState(defaultFilter());
    const [sortModel] = useState([{ field: 'date', sort: 'desc' }]);

    const getFilterAttrValue = (name) => (
        filterData[name].value
    );

    const disableGeneratingSettlements = !getFilterAttrValue('entity').length > 0;

    const {
        settlements, loading, count
    } = useSettlements(page, pageSize, filterData, sortModel, disableGeneratingSettlements);
    const {
        sumDocuments, sumTransactions
    } = useSums(filterData, disableGeneratingSettlements);

    const columns = useColumns();

    // Sorting temporary off
    // const handleSortModelChange = (newSortModel) => {
    //     setSortModel(newSortModel);
    // };

    const setFilterAttr = debounce((name, value) => {
        setFilterData((newFilterData) => {
            const data = cloneDeep(newFilterData);
            data[name] = {
                value,
                filter: filterClearedAttr(name, value)
            };
            return data;
        });
    }, 500);

    const handleOpenRecord = (row) => {
        switch (row?.type) {
            case SettlementTypes.transaction:
                window.open(`/bankStatements/browse?id=${row?.id}`);
                break;
            case SettlementTypes.invoice:
                window.open(`/invoices/browse?id=${row?.id}`);
                break;
            case SettlementTypes.income:
                window.open(`/income/browse?id=${row?.id}`);
                break;
            default:
                break;
        }
    };

    const handleCellClick = async (params) => {
        if (params.field === 'entity' || params.field === 'contractor') {
            const { value } = params;
            const { data } = await RestRequestsHelper.getContractors('', value[0]._id);
            setFilterAttr(params.field, data);
        } else if (params.field === 'name') {
            handleOpenRecord(params?.row);
        }
    };

    const handlePaginationModelChange = (data) => {
        setPageSize(data.pageSize);
        setPage(data.page);
    };

    const contractorDataSrc = () => {
        const entity = getFilterAttrValue('entity');
        // type 8 - Spółka
        const entitiesIds = entity?.filter((elm) => elm?.type?.key === 8).map(({ _id }) => _id);
        if (entitiesIds.length > 0) {
            return comboboxTypes.entitiesOfCommunityByName(entitiesIds);
        }
        return comboboxTypes.contractors();
    };

    return (
        <div className={classes.main}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <UniversalCombobox
                        id='entity'
                        multiple
                        src={comboboxTypes.communities()}
                        label={intl.formatMessage({ id: messages.entity.id })}
                        onChange={(value) => setFilterAttr('entity', value)}
                        value={getFilterAttrValue('entity')}
                        showColors
                    />
                </Grid>
                <Grid item xs={3}>
                    <UniversalCombobox
                        id='contractor'
                        multiple
                        src={contractorDataSrc()}
                        label={intl.formatMessage({ id: messages.contractor.id })}
                        onChange={(value) => setFilterAttr('contractor', value)}
                        value={getFilterAttrValue('contractor')}
                        optionLabel={(elm) => elm?.name}
                        showColors
                    />
                </Grid>
                <Grid item xs={3}>
                    <UniversalCombobox
                        id='type'
                        multiple
                        src={comboboxTypes.transactionTypes()}
                        label={intl.formatMessage(messages.transactionType)}
                        onChange={(value) => {
                            setFilterAttr('transactionType', value);
                        }}
                        value={getFilterAttrValue('transactionType')}
                        showColors
                    />
                </Grid>
                <Grid item xs={3}>
                    <UniversalCombobox
                        id='type'
                        multiple
                        src={comboboxTypes.documentTypes()}
                        label={intl.formatMessage(messages.documentType)}
                        onChange={(value) => {
                            setFilterAttr('documentType', value);
                        }}
                        value={getFilterAttrValue('documentType')}
                        showColors
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        variant='outlined'
                        id='dateFilter'
                        options={dateFilterOptions}
                        getOptionLabel={(option) => intl.formatMessage(messages[option]) || option}
                        value={getFilterAttrValue('dateFilter')}
                        onChange={(_event, value) => setFilterAttr('dateFilter', value)}
                        fullWidth
                        disableClearable
                        renderInput={(params) => <TextField {...params} label={intl.formatMessage(messages.filterDatesBy)} variant='outlined' />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        label={intl.formatMessage({ id: messages.dateFrom.id })}
                        showTodayButton
                        value={getFilterAttrValue('dateFrom')}
                        onChange={(date) => setFilterAttr('dateFrom', date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth id='dateFrom' {...params} />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        label={intl.formatMessage({ id: messages.dateTo.id })}
                        showTodayButton
                        value={getFilterAttrValue('dateTo')}
                        onChange={(date) => setFilterAttr('dateTo', date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth id='dateTo' {...params} />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <MoneyTextField
                        value={getFilterAttrValue('amountFrom')}
                        onChange={(value) => setFilterAttr('amountFrom', value.length ? 100 * parseFormattedMoney(value) : null)}
                        onKeyDown={(event) => onKeyDetect(event, () => {
                            const { value } = event.target;
                            setFilterAttr('amountFrom', value.length ? 100 * parseFormattedMoney(value) : null);
                        })}
                        textFieldParams={{
                            id: 'amountFrom',
                            label: intl.formatMessage(messages.amountFrom),
                            variant: 'outlined',
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <MoneyTextField
                        value={getFilterAttrValue('amountTo')}
                        onChange={(value) => {
                            setFilterAttr('amountTo', value.length ? 100 * parseFormattedMoney(value) : null);
                        }}
                        onKeyDown={(event) => onKeyDetect(event, () => {
                            const { value } = event.target;
                            setFilterAttr('amountTo', value.length ? 100 * parseFormattedMoney(value) : null);
                        })}
                        textFieldParams={{
                            id: 'amountTo',
                            label: intl.formatMessage(messages.amountTo),
                            variant: 'outlined',
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Stack
                        direction='column'
                        justifyContent='center'
                        alignItems='start'
                    >
                        <Typography variant='body2'>
                            <FormattedMessage id={messages.excludeBalance.id} />
                        </Typography>
                        <Switch
                            checked={getFilterAttrValue('excludeBalance')}
                            onChange={() => setFilterAttr('excludeBalance', !getFilterAttrValue('excludeBalance'))}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <DataGrid
                paginationMode='server'
                sortingMode='server'
                columns={columns}
                columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                rows={settlements}
                rowCount={count}
                paginationModel={{ page, pageSize }}
                pageSizeOptions={paginationAllowedValues}
                onPaginationModelChange={handlePaginationModelChange}
                onCellClick={handleCellClick}
                loading={loading}
                sortModel={sortModel}
                // Sorting temporary off
                // onSortModelChange={handleSortModelChange}
                slots={{
                    toolbar: SettlementsToolbar,
                    footer: DataGridFooter,
                    ...(disableGeneratingSettlements ? {
                        noRowsOverlay: GenerationDisabledDataGridInfo
                    } : {})
                }}
                slotProps={{
                    toolbar: { filter: filterData, columns },
                    footer: {
                        customNode: (
                            <BalanceFooterElm
                                totalFirstObj={{ title: intl.formatMessage(messages.sumDocuments), value: sumDocuments }}
                                totalSecondObj={{ title: intl.formatMessage(messages.sumTransactions), value: sumTransactions }}
                                calculateMethod={(first, second) => first + second}
                                calculationTranslationId={messages.balance.id}
                            />
                        )
                    }
                }}
            />
        </div>
    );
}

export default Settlements;
