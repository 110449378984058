import useSWR from 'swr';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';

const useCounterTypes = () => {
    const { data, error, mutate } = RestRequestsHelper.getCounterTypes(useSWR);
    return {
        counterTypes: data,
        loading: !error && !data,
        mutate
    };
};

export default useCounterTypes;
