import React from 'react';
import { useIntl } from 'react-intl';
import {
    Grid
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { Controller } from 'react-hook-form';
import UniversalCombobox, { comboboxTypes } from '../../../Combobox/UniversalCombobox';

const messages = {
    payer: { id: 'app.addInvoice.payer' }
};

function ContractorFormBuyers({ control, readOnly }) {
    const intl = useIntl();

    return (
        <Grid item xs={12}>
            <Controller
                name='buyers'
                control={control}
                render={({ field }) => (
                    <UniversalCombobox
                        id='buyers'
                        src={comboboxTypes.contractors()}
                        label={intl.formatMessage({ id: messages.payer.id })}
                        control={control}
                        multiple
                        limit={10}
                        disabled={readOnly}
                        {...field}
                    />
                )}
            />
        </Grid>
    );
}

ContractorFormBuyers.propTypes = {
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool
};

ContractorFormBuyers.defaultProps = {
    readOnly: false
};

export default ContractorFormBuyers;
