import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Grid, Typography, Divider
} from '@mui/material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';
import { paginationAllowedValues } from '../../../../../constants/pagination';
import convertColumnsToVisibilityModel from '../../../../../lib/convertColumnsToVisibilityModel';
import useContractorCorrespondenceColumns from '../hooks/useContractorCorrespondenceColumns';

const messages = {
    correspondence: { id: 'app.menu.correspondence' },
    noData: { id: 'app.general.noData' }
};

type Props = {
    edit: {
        id: string
    }
}

type PaginationModel = {
    pageSize: number,
    page: number
}

function ContractorCorrespondence({ edit }: Props) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [count, setCount] = useState(0);

    const columns = useContractorCorrespondenceColumns();

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const { data: res } = await RestRequestsHelper.getContractorCorrespondence(edit?.id, page, pageSize);
            setLoading(false);
            setData(res?.data || []);
            setCount(res?.count || 0);
        };
        getData();
    }, [page, pageSize]);
    const handlePaginationModelChange = (model: PaginationModel) => {
        setPageSize(model.pageSize);
        setPage(model.page);
    };

    const handleCellClick = (params: GridCellParams) => {
        const { row } = params;
        window.open(`/correspondence/browse?id=${row?.id}`);
    };
    return (
        <Grid item xs={12}>
            <Typography component='h2' variant='h6' align='left'>
                <FormattedMessage id={messages.correspondence.id} />
            </Typography>
            <Divider />
            <Grid item xs={12} sx={{ mt: 1 }}>
                {(data.length || loading) ? (
                    <DataGrid
                        autoHeight
                        rows={data}
                        columns={columns}
                        onCellClick={handleCellClick}
                        paginationMode='server'
                        rowCount={count}
                        onPaginationModelChange={handlePaginationModelChange}
                        paginationModel={{ page, pageSize }}
                        pageSizeOptions={paginationAllowedValues}
                        loading={loading}
                        columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                        sx={{
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer'
                            }
                        }}
                    />
                ) : (
                    <Typography sx={{
                        border: '1px solid #616161', borderRadius: 1, pt: 1, pb: 1
                    }}
                    >
                        <FormattedMessage id={messages.noData.id} />
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
}

export default ContractorCorrespondence;
