import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import { paginationAllowedValues, paginationDefaultValue } from '../../../constants/pagination';
import FixedAddButton from '../../common/FixedAddButton/FixedAddButton';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';
import ContractorsFilter from './shared/ui/ContractorsFilter';
import defaultFilter from './shared/data/defaultFilter';
import useContractors from './shared/hooks/useContractors';
import useColumns from './shared/hooks/useColumns';
import CustomToolbar from './shared/ui/CustomToolbar';
import ContractorsSidebar from './shared/ui/ContractorsSidebar';

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '80vh'
    }
}));

function Contractors() {
    const classes = useStyles();
    const [filter, setFilter] = useState(JSON.stringify(defaultFilter()));
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(paginationDefaultValue);
    const [contractor, setContractor] = useState();
    const { control, watch } = useForm({ defaultValues: defaultFilter() });
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const columns = useColumns();
    const watchAll = watch();
    const {
        contractors,
        count,
        loading,
        mutate
    } = useContractors(filter, page, pageSize);

    const updateFilter = useMemo(() => debounce((newFilter, callback) => {
        callback(newFilter);
    }, 300), []);

    useEffect(() => {
        updateFilter(JSON.stringify(watchAll), setFilter);
    }, [watchAll]);

    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, []);

    const handleRowSelect = (params) => {
        // Trigger side panel only if the clicked cell is the contractor name
        if (params.field === 'name') {
            setContractor(params.row);
        }
    };

    const handlePaginationModelChange = (data) => {
        setPageSize(data.pageSize);
        setPage(data.page);
    };

    const handleCloseSidebar = () => {
        setContractor(null);
    };

    const handleUpdateSidebar = () => mutate();

    const redirectToAdding = () => history.push('/contractors/add');
    return (
        <div className={classes.main}>
            <ContractorsSidebar
                contractor={contractor}
                onClose={handleCloseSidebar}
                onUpdate={handleUpdateSidebar}
            />
            <ContractorsFilter
                control={control}
            />
            <FixedAddButton onClick={redirectToAdding} />
            <DataGrid
                paginationMode='server'
                rows={contractors}
                columns={columns}
                pageSizeOptions={paginationAllowedValues}
                rowCount={count}
                onCellClick={handleRowSelect}
                paginationModel={{ page, pageSize }}
                onPaginationModelChange={handlePaginationModelChange}
                loading={loading}
                components={{
                    Toolbar: CustomToolbar
                }}
                componentsProps={{ toolbar: { filter, columns } }}
                columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                getRowHeight={() => 'auto'}
                sx={{ '.MuiDataGrid-row': { minHeight: '50px !important' } }}
            />
        </div>
    );
}

export default Contractors;
