import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import restRequestsHelper from '../../../../lib/restRequestsHelper';
import CSVExportData from '../../../common/DataGridToolbar/CSVExportData';
import usePageTitle from '../../../common/DataGridToolbar/usePageTitle';
import { setInspection } from '../../../../actions/index';
import userRoles from '../../../../constants/UserRoles';
import { paginationDefaultValue, paginationAllowedValues } from '../../../../constants/pagination';
import FixedAddButton from '../../../common/FixedAddButton/FixedAddButton';
import convertColumnsToVisibilityModel from '../../../../lib/convertColumnsToVisibilityModel';
import useColumns from './shared/hooks/useColumns';
import InspectionsFilter from './shared/ui/InspectionsFilter';
import InspectionsSidePanel from './shared/ui/InspectionsSidePanel';
import useInspections from './shared/hooks/useInspections';

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '80vh',
        textAlign: 'left'
    },
    toolbar: {
        padding: theme.spacing(1)
    }
}));

const getAllInspections = debounce(async (filterQuery, callback) => {
    const { data } = await restRequestsHelper.getAllInspections(filterQuery);
    callback(data?.data ?? []);
}, 500);

function GridToolbarContainer({ filterQuery, columns }) {
    const classes = useStyles();
    const [data, setData] = useState();
    const [download, setDownload] = useState(false);

    const getHeaders = () => (
        columns
            .filter(({ field }) => field !== 'export')
            .map(({ headerName, field }) => ({ label: headerName, key: field }))
    );

    const handleClick = () => {
        getAllInspections(filterQuery, (result) => {
            const _result = result.map(({ producers, type, ...rest }) => ({
                ...rest,
                producers: producers.map((i) => i.name).join(', '),
                type: type?.value
            }));
            setData(_result);
        });
    };
    useEffect(() => {
        if (data) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [data]);
    return (
        <div className={classes.toolbar}>
            <CSVExportData onClick={handleClick} download={download} headers={getHeaders()} filename={usePageTitle()} data={data} />
        </div>
    );
}

function Inspections({ defaultApplicantFilter, inhabitantView }) {
    const [page, setPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(null);
    const [pageSize, setPageSize] = useState(paginationDefaultValue);
    const [concernFilter, setConcernFilter] = useState([]);
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const [sortModel, setSortModel] = useState([{
        field: 'validToUI', sort: 'desc'
    }]);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const {
        inspections, count,
        loading, mutate
    } = useInspections(filterQuery, page, pageSize, filtersLoaded, JSON.stringify(sortModel[0]));
    const classes = useStyles();
    const history = useHistory();

    const query = new URLSearchParams(window.location.search);
    const id = query.get('id');

    useEffect(() => {
        if (id) {
            dispatch(setInspection(id));
        }
    }, [id]);

    const handleCloseSidePanel = () => {
        dispatch(setInspection(null));
        if (id) {
            query.delete('id');
            history.replace({ pathname: window.location.pathname, search: query.toString() });
        }
    };

    const handleUpdate = () => mutate();

    const columns = useColumns(handleUpdate);

    const handlePaginationModelChange = (data) => {
        setPageSize(data.pageSize);
        setPage(data.page);
    };

    const handleChangeFilter = (newFilter) => {
        setFilterQuery(JSON.stringify(newFilter));
    };

    const onSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    // Edit selected inspection
    const handleRowSelect = (params) => {
        if (params.field !== 'additionalOptions') {
            const { row } = params;
            dispatch(setInspection(row));
        }
    };

    const redirectAddInspection = () => history.push('/inspections/add');

    const filterByConcerns = (concerns) => {
        handleCloseSidePanel();
        setConcernFilter(concerns);
    };
    return (
        <div className={classes.main}>
            <InspectionsSidePanel
                onClose={handleCloseSidePanel}
                onUpdate={handleUpdate}
                filterByConcerns={filterByConcerns}
                inhabitantMode={inhabitantView}
                applicantFilter={defaultApplicantFilter}
            />
            <InspectionsFilter
                onChange={handleChangeFilter}
                concernFilter={concernFilter}
                setLoaded={setFiltersLoaded}
                applicantFilter={defaultApplicantFilter}
                inhabitantView={inhabitantView}
            />
            <FixedAddButton onClick={redirectAddInspection} hidden={user?.role === userRoles.contractor} />
            <DataGrid
                paginationMode='server'
                rows={inspections}
                columns={columns}
                pageSizeOptions={paginationAllowedValues}
                rowCount={count}
                onCellClick={handleRowSelect}
                onPaginationModelChange={handlePaginationModelChange}
                loading={loading}
                paginationModel={{ page, pageSize }}
                sortingMode='server'
                onSortModelChange={(newSortModel) => onSortModelChange(newSortModel)}
                sortModel={sortModel}
                components={{
                    Toolbar: GridToolbarContainer
                }}
                componentsProps={{ toolbar: { filterQuery, columns, handleUpdate } }}
                columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
            />
        </div>
    );
}

GridToolbarContainer.propTypes = {
    filterQuery: PropTypes.any.isRequired,
    columns: PropTypes.array.isRequired
};

Inspections.propTypes = {
    defaultApplicantFilter: PropTypes.array,
    inhabitantView: PropTypes.bool
};

Inspections.defaultProps = {
    defaultApplicantFilter: [],
    inhabitantView: false
};

export default Inspections;
