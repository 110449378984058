import dayjs from 'dayjs';
import defaultRemarkType from '../../../../../constants/remarkDefaults';
import formatUTCDate from '../../../../../lib/formatUTCDate';

export const defaultRemarks = (user) => ({
    value: '',
    user,
    updatedAt: dayjs(),
    type: defaultRemarkType,
    files: []
});
export const defaultReminder = () => ({
    entity: null, date: dayjs().add(1, 'day'), value: null, _new: true
});

const defaultVindicationFormValues = (user, edit) => (edit
    // Loads ready values in order to prepare them for edition
    ? ({
        user,
        name: edit.name,
        concerns: edit.concerns,
        debtor: edit.debtor,
        leader: edit.leader,
        category: edit.category,
        content: edit.content,
        remarks: edit.remarksData,
        reminders: edit.remindersData
            ? edit.remindersData.map((reminder) => ({ ...reminder, _isSent: reminder?.date && dayjs().isAfter(dayjs(reminder.date)) }))
            : edit.remindersData,
        producers: edit.producers,
        status: edit.status,
        lastChargeDate: formatUTCDate(edit.last_charge_date),
        files: edit.files,
        fileNumber: 0
    })
    : ({
        user,
        name: '',
        concerns: [],
        debtor: null,
        remarks: [],
        reminders: [],
        lastChargeDate: null,
        category: null,
        content: '',
        producers: null,
        files: [],
        fileNumber: 0
    })
);

export default defaultVindicationFormValues;
