import React, {
    useState, forwardRef, useEffect
} from 'react';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';
import { useDebounce } from 'usehooks-ts';
import useSWR from 'swr';
import Requests from '../../../lib/requests';
import COMBOBOX_TYPES from './shared/data/comboboxTypes';
import InfoTooltip from './shared/ui/InfoTooltip';

const MAX_LIMIT = 100;
const request = new Requests();

const useOptions = (url, options, searchPhrase, excluded) => {
    const {
        data, error, mutate
    } = request.swr(useSWR, url, { searchPhrase, ...options });
    const limitedOptions = data?.slice?.(0, MAX_LIMIT) ?? [];
    const excludedOptions = limitedOptions.filter((item) => !excluded.includes(item._id));
    return {
        options: excludedOptions,
        isLoading: !error && !data && url,
        error,
        mutate
    };
};

// The following data is used to set colored input
const useStyles = makeStyles(() => ({
    longerList: {
        maxHeight: '60vh'
    }
}));

// Generic combobox that can be used to render any list of options.
// The way we specify which list of options to use is by utilizing the 'src' parameter
const UniversalCombobox = forwardRef(({
    id, name, onChange, onBlur, required, label,
    optionLabel, src, disabled, value, onMatch,
    freeSolo, onError, margin, selectIfSingleton,
    multiple, longerList, displayInfo, showColors,
    disableClearable, excluded,
    tooltipText, tooltipPosition, limit
}, ref) => {
    // This input state cannot be undefined if we want to unlock controlled mode of autocomplete
    // https://v4.mui.com/components/autocomplete/#controllable-states
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, 500);
    const classes = useStyles();
    const [cleared, setCleared] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    // When there are multiple values then we need to know which one is hovered
    const [mouseHoverValueIndex, setMouseHoverValueIndex] = useState(null);
    const {
        options, isLoading, error
    } = useOptions(src.url, src.query, debouncedInputValue, excluded);

    const handleGetOptionLabel = (option) => {
        if (freeSolo && typeof option === 'string') {
            return option;
        }
        if (option) {
            return optionLabel(option);
        }
        return '';
    };

    const isValueEmpty = (val) => (
        multiple ? !val || val.length === 0 : !val
    );

    const handleOnChange = (_event, val) => {
        if (isValueEmpty(val)) setCleared(true);
        onChange(val);
    };

    const handleOnInputChange = (_event, val) => {
        setInputValue(val);
        if (freeSolo && _event?.type !== 'click') {
            onChange(val);
        }
    };

    const handleHoveredItemChange = (_event, index) => {
        if (multiple && displayInfo) setMouseHoverValueIndex(index);
    };

    useEffect(() => {
        if (isValueEmpty(value)) setCleared(true);
    }, [value]);

    useEffect(() => {
        if (cleared && options.length > 1) {
            setCleared(false);
        }
        if (isValueEmpty(value) && selectIfSingleton && options.length === 1 && !cleared) {
            onChange(multiple ? [options[0]] : options[0]);
        }
    }, [options]);

    useEffect(() => {
        if (error) onError(error);
    }, [error]);

    // This value state cannot be undefined if we want to unlock controlled mode of autocomplete
    // https://v4.mui.com/components/autocomplete/#controllable-states
    let valueControlled = value === undefined ? null : value;
    if (valueControlled === null && multiple) {
        valueControlled = [];
    }

    return (
        <InfoTooltip
            value={value}
            fetcher={src.info}
            show={displayInfo}
            loading={isLoading}
            multiple={multiple}
            index={mouseHoverValueIndex}
            open={tooltipOpen}
        >
            <div>
                <Tooltip
                    title={tooltipText}
                    placement={tooltipPosition}
                    disableInteractive
                >
                    <Autocomplete
                        multiple={multiple}
                        disabled={disabled}
                        disableClearable={disableClearable}
                        id={id}
                        filterOptions={(val) => val}
                        onChange={handleOnChange}
                        onBlur={onBlur}
                        onOpen={() => setTooltipOpen(false)}
                        onClose={() => setTooltipOpen(true)}
                        onInputChange={handleOnInputChange}
                        options={options}
                        getOptionLabel={handleGetOptionLabel}
                        getOptionDisabled={() => (multiple && limit && valueControlled.length >= limit)}
                        isOptionEqualToValue={onMatch}
                        inputValue={inputValue}
                        value={valueControlled}
                        freeSolo={freeSolo}
                        classes={{ listbox: longerList ? classes.longerList : null }}
                        sx={value?.color && showColors ? { '& .MuiAutocomplete-inputRoot': { color: value.color } } : null}
                        renderOption={(props, option) => (
                            <li {...props} key={props.id}>
                                <span style={option?.color && showColors ? { color: option.color } : {}}>
                                    {handleGetOptionLabel(option)}
                                </span>
                            </li>
                        )}
                        renderTags={(val, getTagProps) => val.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                label={handleGetOptionLabel(option)}
                                sx={option?.color && showColors ? { backgroundColor: option.color } : {}}
                                onMouseEnter={(event) => handleHoveredItemChange(event, index)}
                            />
                        ))}
                        renderInput={(params) => (
                            <TextField
                            /* eslint-disable-next-line */
                            {...params}
                                required={required}
                                label={label}
                                name={name}
                                inputRef={ref}
                                variant='outlined'
                                margin={margin}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                    required: required && !value?.length
                                }}
                            />
                        )}
                    />
                </Tooltip>
            </div>
        </InfoTooltip>
    );
});

UniversalCombobox.propTypes = {
    src: PropTypes.shape({
        url: PropTypes.string,
        query: PropTypes.object,
        info: PropTypes.func
    }).isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    onError: PropTypes.func,
    required: PropTypes.bool,
    optionLabel: PropTypes.func,
    disabled: PropTypes.bool,
    onMatch: PropTypes.func,
    freeSolo: PropTypes.bool,
    margin: PropTypes.string,
    selectIfSingleton: PropTypes.bool,
    multiple: PropTypes.bool,
    longerList: PropTypes.bool,
    displayInfo: PropTypes.bool,
    showColors: PropTypes.bool,
    disableClearable: PropTypes.bool,
    excluded: PropTypes.array,
    tooltipText: PropTypes.string,
    tooltipPosition: PropTypes.string,
    limit: PropTypes.oneOfType([
        null,
        PropTypes.number
    ])
};

const defaultMatch = (option, value) => (
    (option?._id && option?._id === value?._id)
    || (option?.id && option?.id === value?.id)
    || (option?.key && option?.key === value?.key)
);

UniversalCombobox.defaultProps = {
    onChange: () => {},
    onBlur: () => {},
    value: null,
    name: '',
    id: '',
    label: '',
    onError: () => {},
    required: false,
    optionLabel: ({ $name }) => $name,
    disabled: false,
    onMatch: defaultMatch,
    freeSolo: false,
    margin: 'none',
    selectIfSingleton: false,
    multiple: false,
    longerList: false,
    displayInfo: false,
    showColors: false,
    disableClearable: false,
    excluded: [],
    tooltipText: null,
    tooltipPosition: 'top',
    limit: null
};

export default UniversalCombobox;
export {
    COMBOBOX_TYPES as comboboxTypes
};
