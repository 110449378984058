import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    logo: {
        width: '150px',
        margin: '0 auto'
    },
    centeredLogo: {
        width: '200px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}));

function MenuLogo({ centered }) {
    const classes = useStyles();
    return (
        <img
            src='/logo.png'
            alt='logo'
            className={centered ? classes.centeredLogo : classes.logo}
        />
    );
}

MenuLogo.propTypes = {
    centered: PropTypes.bool
};

MenuLogo.defaultProps = {
    centered: false
};

export default MenuLogo;
