import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { paginationAllowedValues } from '../../../constants/pagination';
import useHistory from './shared/hooks/useHistory';
import useColumns from './shared/hooks/useColumns';

function HistoryDataGrid({ collectionName, recordId }) {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [sortModel, setSortModel] = useState([{ field: 'createdAt', sort: 'desc' }]);

    const {
        history, count,
        loading
    } = useHistory(collectionName, recordId, page, pageSize, JSON.stringify(sortModel[0]));

    const columns = useColumns();

    const onSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    const handlePaginationModelChange = (data) => {
        setPageSize(data.pageSize);
        setPage(data.page);
    };
    return (
        <Box sx={{ minHeight: '200px' }}>
            <DataGrid
                autoHeight
                paginationMode='server'
                rows={history}
                columns={columns}
                paginationModel={{ page, pageSize }}
                pageSizeOptions={paginationAllowedValues}
                rowCount={count}
                getRowId={(row) => row._id}
                onPaginationModelChange={handlePaginationModelChange}
                loading={loading}
                sortingMode='server'
                onSortModelChange={(newSortModel) => onSortModelChange(newSortModel)}
                sortModel={sortModel}
            />
        </Box>
    );
}
HistoryDataGrid.propTypes = {
    collectionName: PropTypes.string.isRequired,
    recordId: PropTypes.string
};

HistoryDataGrid.defaultProps = {
    recordId: null
};

export default HistoryDataGrid;
