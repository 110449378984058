import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import GridCellExpand from '../../../../common/DataGridCells/GridCellExpand';
import GridMoneyCell from '../../../../common/DataGridCells/GridMoneyCell';
import dateFormat from '../../../../../constants/dateFormat';
import validateBankAccount from '../../../../../lib/validateBankAccount';
import formatBankAccount from '../../../../../lib/formatBankAccount';
import AdditionalOptionsColumn from '../../../../common/AdditionalOptionsColumn/AdditionalOptionsColumn';
import formatUTCDate from '../../../../../lib/formatUTCDate';

const messages = {
    transactionType: { id: 'app.transactions.transactionType' },
    transactionDate: { id: 'app.transactions.transactionDate' },
    entity: { id: 'app.transactions.entity' },
    contractor: { id: 'app.transactions.contractor' },
    amountFrom: { id: 'app.invoices.amountFrom' },
    amountTo: { id: 'app.invoices.amountTo' },
    amount: { id: 'app.transactions.amount' },
    dateFrom: { id: 'app.transactions.dateFrom' },
    dateTo: { id: 'app.transactions.dateTo' },
    reference: { id: 'app.transactions.reference' },
    entityBankNumber: { id: 'app.transactions.entityBankNumber' },
    contractorBankNumber: { id: 'app.transactions.contractorBankNumber' },
    editTransaction: { id: 'app.bankStatement.edit' }
};

const AdditionalOptionsCell = React.memo(({ params, setSelectedRow }) => {
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenEdit = () => {
        setSelectedRow(params?.row);
        setAnchorEl(null);
    };

    const defaultPolicyFn = () => true;

    const additionalOptions = [
        {
            title: intl.formatMessage(messages.editTransaction),
            fn: handleOpenEdit,
            policyFn: defaultPolicyFn
        }
    ];
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onClick = (exportFn) => {
        exportFn();
    };
    return (
        <AdditionalOptionsColumn
            onIconClick={handleClick}
            onItemClick={onClick}
            options={additionalOptions}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
        />
    );
});

AdditionalOptionsCell.propTypes = {
    params: PropTypes.object.isRequired,
    setSelectedRow: PropTypes.func.isRequired
};

const useColumns = ({ setSelectedRow, mutate }) => {
    const intl = useIntl();
    const formatBankAccountIfValid = (value) => (validateBankAccount(value) ? formatBankAccount(value) : value);

    return [
        {
            field: 'type',
            headerName: intl.formatMessage(messages.transactionType),
            headerAlign: 'center',
            width: 150,
            renderCell: (params) => (
                <GridCellExpand
                    width={params.colDef.width}
                    value={params.value.value}
                />
            )
        },
        {
            field: 'date',
            headerName: intl.formatMessage({ id: messages.transactionDate.id }),
            headerAlign: 'center',
            width: 220,
            renderCell: (params) => (
                <GridCellExpand
                    width={params.colDef.width}
                    value={formatUTCDate(params.value, dateFormat.format)}
                />
            )
        },
        {
            field: 'entity',
            headerName: intl.formatMessage({ id: messages.entity.id }),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <GridCellExpand
                    width={params.colDef.width}
                    value={params.value.name}
                    color={params.value?.color}
                />
            )
        },
        {
            field: 'entityBankNumber',
            headerName: intl.formatMessage({ id: messages.entityBankNumber.id }),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <GridCellExpand
                    width={params.colDef.width}
                    value={formatBankAccountIfValid(params.value)}
                />
            )
        },
        {
            field: 'contractor',
            headerName: intl.formatMessage({ id: messages.contractor.id }),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <GridCellExpand
                    width={params.colDef.width}
                    value={params.value.name}
                    color={params.value?.color}
                />
            )
        },
        {
            field: 'contractorBankNumber',
            headerName: intl.formatMessage({ id: messages.contractorBankNumber.id }),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <GridCellExpand
                    width={params.colDef.width}
                    value={formatBankAccountIfValid(params.value)}
                />
            )
        },
        {
            field: 'amount',
            headerName: intl.formatMessage({ id: messages.amount.id }),
            headerAlign: 'center',
            type: 'number',
            width: 200,
            valueGetter: (params) => (params.row.amount / 100).toFixed(2),
            renderCell: (params) => (
                <GridMoneyCell
                    width={params.colDef.width}
                    value={params.value}
                    color={params.value < 0 ? '#f44336' : ''}
                />
            )
        },
        {
            field: 'reference',
            headerName: intl.formatMessage({ id: messages.reference.id }),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <GridCellExpand
                    width={params.colDef.width}
                    value={params.value}
                />
            )
        },
        {
            field: 'additionalOptions',
            headerName: ' ',
            width: 60,
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => <AdditionalOptionsCell params={params} setSelectedRow={setSelectedRow} mutate={mutate} />
        }
    ];
};

export default useColumns;
