import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import { Controller } from 'react-hook-form';
import InfrastructurePropertiesAutoComplete from '../InfrastructurePropertiesAutoComplete/InfrastructurePropertiesAutoComplete';
import formatInputValue from '../../../../../../../lib/formatInputValue';
import MoneyTextField from '../../../../../../common/MoneyTextField/MoneyTextField';
import getFormattingPropertiesInput from './getFormattingPropertiesInput';

const messages = {
    propertiesValue: 'app.infrastructures.propertiesValue'
};
const useStyles = makeStyles((theme) => ({
    gridItem: {
        paddingRight: '1em'
    },
    gridParent: {
        paddingLeft: '1em'
    },
    input: { width: '100%' },
    button: {
        marginTop: theme.spacing(2)
    }
}));
function PropertiesInput({
    register,
    setValue,
    index,
    options,
    name,
    control,
    onDelete,
    readOnly,
    type,
    field
}) {
    const classes = useStyles();
    const intl = useIntl();
    const formatting = getFormattingPropertiesInput(type);
    const registerData = register(`properties.${index}.value`);
    useEffect(() => {
        setValue(`properties.${index}.value`, formatting.formatFn(field.value.toString()));
    }, []);
    return (
        <Grid container>
            <Grid className={classes.gridItem} container item xs={4}>
                <InfrastructurePropertiesAutoComplete control={control} name={name} options={options} readOnly={readOnly} />
            </Grid>
            <Grid className={classes.gridParent} container item xs={7}>
                {type === 'float' ? (
                    <Controller
                        control={control}
                        name={`properties.${index}.value`}
                        render={({ field: fieldController }) => (
                            <MoneyTextField
                                textFieldParams={{
                                    className: classes.input,
                                    label: intl.formatMessage({ id: messages.propertiesValue }),
                                    disabled: readOnly
                                }}
                                {...fieldController}
                            />
                        )}
                    />
                ) : (
                    <TextField
                        className={classes.input}
                        label={intl.formatMessage({ id: messages.propertiesValue })}
                        disabled={readOnly}
                        inputProps={{ pattern: formatting.pattern }}
                        {...registerData}
                        onChange={(event) => formatInputValue(event, registerData.onChange, formatting.formatFn)}
                    />
                )}
            </Grid>
            {
                !readOnly && (
                    <Grid item xs={12} sm={1}>
                        <IconButton
                            className={classes.button}
                            onClick={
                                () => { onDelete(index); }
                            }
                            size='large'
                        >
                            <RemoveIcon fontSize='small' />
                        </IconButton>
                    </Grid>
                )
            }
        </Grid>
    );
}

PropertiesInput.propTypes = {
    index: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    field: PropTypes.object.isRequired
};

PropertiesInput.defaultProps = {
    readOnly: false,
    type: null
};

export default PropertiesInput;
