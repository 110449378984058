import React from 'react';
import PropTypes from 'prop-types';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import formatMoney from '../../../../../../lib/formatMoney';

const useStyles = makeStyles((theme) => ({
    sum: {
        marginLeft: theme.spacing(2)
    }
}));

function TrashFooter({ sum }) {
    const classes = useStyles();
    return (
        <GridFooterContainer>
            <div className={classes.sum}>
                {formatMoney(sum)}
            </div>
            <GridFooter />
        </GridFooterContainer>
    );
}

TrashFooter.propTypes = {
    sum: PropTypes.number
};

TrashFooter.defaultProps = {
    sum: null
};

export default TrashFooter;
