import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import UniversalCombobox, { comboboxTypes } from '../../../common/Combobox/UniversalCombobox';

const messages = {
    bankStatement: { id: 'app.bankStatement.load' },
    chooseContractor: { id: 'app.bankStatement.chooseContractor' },
    chooseAccountNumber: { id: 'app.bankStatement.chooseAccountNumber' },
    confirm: { id: 'app.form.confirm' }
};

const useStyles = makeStyles((theme) => ({
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        verticalAlign: 'top'
    }
}));

// eslint-disable-next-line react/prop-types
const renderCombobox = (isContractor, watchEntity, setAccountType) => (function ({ field }) {
    const intl = useIntl();

    const renderOptionLabel = (option) => (
        `${option.number} (${option.name})`
    );

    return isContractor
        ? (
            <UniversalCombobox
                src={comboboxTypes.communities()}
                label={intl.formatMessage(messages.chooseContractor)}
                showColors
                {...field}
            />
        ) : (
            <UniversalCombobox
                src={setAccountType()}
                label={intl.formatMessage(messages.chooseAccountNumber)}
                optionLabel={renderOptionLabel}
                disabled={!watchEntity}
                {...field}
                displayInfo={false}
                showColors
            />
        );
});

function AddBankStatementDialog({ onSubmit, open }) {
    const { watch, control, handleSubmit } = useForm();
    const classes = useStyles();
    const history = useHistory();
    const watchEntity = watch('entity');
    const watchBankNumber = watch('bankNumber');

    const handleClose = () => {
        history.push('/');
    };

    const setAccountType = () => {
        const bankId = watchEntity?._id;
        if (bankId) {
            return comboboxTypes.bankNumbers(bankId);
        }
        return comboboxTypes.empty();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
            <DialogTitle id='customized-dialog-title'>
                <FormattedMessage id={messages.bankStatement.id} />
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={handleClose}
                    aria-label='close'
                    className={classes.close}
                    size='large'
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography component='div'>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                render={renderCombobox(true, watchEntity, setAccountType)}
                                control={control}
                                name='entity'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                render={renderCombobox(false, watchEntity, setAccountType)}
                                control={control}
                                name='bankNumber'
                            />
                        </Grid>
                    </Grid>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!watchBankNumber}
                    variant='contained'
                    onClick={handleSubmit(onSubmit)}
                    color='primary'
                >
                    <FormattedMessage id={messages.confirm.id} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AddBankStatementDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool
};

AddBankStatementDialog.defaultProps = {
    open: true
};

export default AddBankStatementDialog;
