import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

const messages = {
    measureUnits: 'app.infrastructures.counter.unit'
};
const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
        marginTop: theme.spacing(1)
    }
}));

function MeasurementUnits({
    options,
    value,
    onChange,
    required,
    readOnly
}) {
    const intl = useIntl();
    const classes = useStyles();
    return (
        <Autocomplete
            className={classes.input}
            value={value}
            options={options || []}
            getOptionLabel={(option) => {
                if (options) {
                    const type = options.find((it) => it.key === option);
                    if (type) {
                        return type.value;
                    }
                    return option.value;
                }
                return '';
            }}
            disabled={readOnly}
            isOptionEqualToValue={(option, valueData) => option.key === valueData}
            renderInput={(params) => (
                <TextField
                    required={required}
                    className={classes.input}
                    {...params}
                    fullWidth
                    label={intl.formatMessage({ id: messages.measureUnits })}
                />
            )}
            onChange={(event, dataObj) => onChange(dataObj ? dataObj.key : null)}
        />
    );
}

MeasurementUnits.propTypes = {
    options: PropTypes.array.isRequired,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

MeasurementUnits.defaultProps = {
    readOnly: false,
    required: false
};

export default MeasurementUnits;
