import React from 'react';
import Grid from '@mui/material/Grid';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import Paper from '@mui/material/Paper';
import FormPickerMaster from '../../../common/FormPicker/FormPickerMaster';
import { HotkeyProvider } from '../../../common/Hotkeys/Hotkeys';
import RestRequestHandler from '../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../lib/displaySnackbar';
import InspectionForm from '../../../common/InspectionForm/InspectionForm';
import errors from '../../../../constants/ResponseCodes';

const messages = {
    addedInspection: { id: 'app.addInspection.addedInspection' },
    errorAdd: { id: 'app.addInspection.errorAdd' },
    errorFileLimit: { id: 'app.addInspection.errorFileLimit' }
};

function AddInspection() {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar(messages.addedInspection);
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });
    const handleSubmit = async (formData, files) => {
        const { data, error } = await RestRequestHandler.addInspection(formData, files);
        if (error) {
            switch (error.code) {
                case errors.filesLimitAlmostReached:
                    displaySnackbar('error', messages.errorFileLimit);
                    break;
                default:
                    displaySnackbar('error', messages.errorAdd);
                    break;
            }
            return false;
        }
        if (data) {
            displaySnackbar('success', messages.addedInspection, { number: data.value });
            return true;
        }
        return false;
    };

    return (
        <div>
            <HotkeyProvider>
                <FormPickerMaster>
                    <Grid container justifyContent='center'>
                        <Paper sx={{ width: '100%' }}>
                            <InspectionForm onSubmit={handleSubmit} />
                        </Paper>
                    </Grid>
                </FormPickerMaster>
            </HotkeyProvider>
        </div>
    );
}

export default AddInspection;
