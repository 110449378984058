import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl';
import UniversalCombobox, { comboboxTypes } from '../../../../../common/Combobox/UniversalCombobox';
import dateFormat from '../../../../../../constants/dateFormat';
import CustomDatePicker from '../../../../../common/CustomDatePicker/CustomDatePicker';

const messages = {
    type: { id: 'app.payoffs.tally.type' },
    entity: { id: 'app.payoffs.tally.entity' },
    dateFrom: { id: 'app.payoffs.tally.dateFrom' },
    dateTo: { id: 'app.payoffs.tally.dateTo' }
};

export const defaultFilter = {
    type: null,
    entity: [],
    dateFrom: dayjs().startOf('year').format('YYYY-MM-DD'),
    dateTo: dayjs().endOf('year').format('YYYY-MM-DD')
};

export default function PayoffsTallyFilter({ filter, setFilter }) {
    const { control, watch } = useForm();
    const intl = useIntl();
    const formData = watch();

    useEffect(() => {
        const filterData = {
            type: formData.type?.key ?? null,
            entity: formData.entity?.map((entity) => entity._id) ?? [],
            entityNames: formData.entity?.map((entity) => entity.name) ?? [],
            dateFrom: formData.dateFrom || null,
            dateTo: formData.dateTo || null
        };
        if (isEqual(filter, filterData)) return;
        setFilter(filterData);
    }, [formData]);

    return (
        <form>
            <Grid container spacing={2} sx={{ mb: 2, mt: -1 }}>
                <Grid item xs={3}>
                    <Controller
                        name='type'
                        control={control}
                        defaultValue={defaultFilter.type}
                        render={({ field }) => (
                            <UniversalCombobox
                                src={comboboxTypes.tallyTypes()}
                                label={intl.formatMessage(messages.type)}
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        name='entity'
                        control={control}
                        defaultValue={defaultFilter.entity}
                        render={({ field }) => (
                            <UniversalCombobox
                                src={comboboxTypes.communities()}
                                label={intl.formatMessage(messages.entity)}
                                multiple
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        name='dateFrom'
                        control={control}
                        defaultValue={defaultFilter.dateFrom}
                        render={({ field }) => (
                            <CustomDatePicker
                                disableToolbar
                                variant='outlined'
                                inputFormat={dateFormat.format}
                                name='dateFrom'
                                id='dateFrom'
                                toolbarTitle='Od'
                                showTodayButton
                                renderInput={(params) => <TextField fullWidth {...params} />}
                                label={intl.formatMessage(messages.dateFrom)}
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        name='dateTo'
                        defaultValue={defaultFilter.dateTo}
                        control={control}
                        render={({ field }) => (
                            <CustomDatePicker
                                disableToolbar
                                variant='outlined'
                                inputFormat={dateFormat.format}
                                name='dateTo'
                                id='dateTo'
                                toolbarTitle='Do'
                                showTodayButton
                                renderInput={(params) => <TextField fullWidth {...params} />}
                                label={intl.formatMessage(messages.dateTo)}
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </form>
    );
}

PayoffsTallyFilter.propTypes = {
    filter: PropTypes.shape({
        type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        entity: PropTypes.string,
        dateFrom: PropTypes.string,
        dateTo: PropTypes.string
    }).isRequired,
    setFilter: PropTypes.func.isRequired
};
