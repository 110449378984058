import React, { useState } from 'react';
import useSWR from 'swr';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { TOOLBAR_SELECT } from '../../data/invoicesConfig';
import RRH from '../../../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../../../lib/displaySnackbar';

const messages = {
    submit: { id: 'app.invoices.submit' },
    cancel: { id: 'app.invoices.cancel' },
    chooseState: { id: 'app.invoices.chooseState' },
    success: { id: 'app.invoices.updateFlowStateSuccess' },
    error: { id: 'app.invoices.updateFlowStateError' }
};

const useStyles = makeStyles((theme) => ({
    select: {
        width: 250,
        marginRight: theme.spacing(1),
        textAlign: 'right'
    },
    label: {
        marginLeft: 10
    }
}));

function ToolbarChangeFlowState({
    selectedRows, setSelectedRows,
    setOption, mutateInvoices, invoices
}) {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ enqueueSnackbar, intl });
    const classes = useStyles();
    const [state, setState] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { data: flowStates } = RRH.getInvoiceFlowStates(useSWR);

    const handleChange = (_event, value) => setState(value);

    const getDataByModel = (model) => (
        model.map((id) => invoices.find((invoice) => invoice.id === id))
    );

    const handleClose = () => {
        setSelectedRows([]);
        setOption(TOOLBAR_SELECT.noSelect);
    };

    const handleSubmit = async () => {
        const selected = getDataByModel(selectedRows);
        setIsLoading(true);
        for (let i = 0; i < selected.length; i += 1) {
            const row = selected[i];
            const { error, data } = await RRH.setInvoiceFlowState(row.id, state.id);
            if (error || data.status === 'error') {
                displaySnackbar('error', intl.formatMessage(messages.error));
                setIsLoading(false);
                return;
            }
        }
        setIsLoading(false);
        displaySnackbar('success', intl.formatMessage(messages.success));
        mutateInvoices();
        handleClose();
    };

    return (
        <>
            <Autocomplete
                options={flowStates || []}
                getOptionLabel={(option) => option.name}
                onChange={handleChange}
                className={classes.select}
                fullWidth
                disabled={!selectedRows.length}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={intl.formatMessage(messages.chooseState)}
                        variant='outlined'
                        size='small'
                    />
                )}
            />
            <Button onClick={handleSubmit} color='primary' disabled={!state}>
                {isLoading ? <CircularProgress size={15} /> : intl.formatMessage(messages.submit)}
            </Button>
            <Button onClick={handleClose} color='primary'>
                {intl.formatMessage(messages.cancel)}
            </Button>
        </>
    );
}

ToolbarChangeFlowState.propTypes = {
    selectedRows: PropTypes.array.isRequired,
    invoices: PropTypes.array.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    setOption: PropTypes.func.isRequired,
    mutateInvoices: PropTypes.func.isRequired
};

export default ToolbarChangeFlowState;
