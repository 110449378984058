import RestRequestsHelper from '../../../lib/restRequestsHelper';

const fetchData = (requestHandler) => (
    async (...args) => {
        const { data, error } = await requestHandler(...args);
        return { data, error };
    }
);

const SELECT_TYPES = {
    users: fetchData(RestRequestsHelper.getUsers),
    entitiesByName: fetchData(RestRequestsHelper.getEntitiesByName),
    producers: fetchData(RestRequestsHelper.getProducers),
    taskStatuses: fetchData(RestRequestsHelper.getTaskStatuses),
    taskCategories: fetchData(RestRequestsHelper.getTaskCategories),
    vindicationStatuses: fetchData(RestRequestsHelper.getVindicationStatuses),
    vindicationCategories: fetchData(RestRequestsHelper.getVindicationCategories),
    inspectionTypes: fetchData(RestRequestsHelper.getInspectionTypes),
    industry: fetchData(RestRequestsHelper.getIndustry)
};

export default SELECT_TYPES;
