import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import InfrastructureTypeAutoComplete from '../InfrastructureTypeAutoComplete/InfrastructureTypeAutoComplete';
import InfrastructureParentAutoComplete from '../InfrastructureParentAutoComplete/InfrastructureParentAutoComplete';

const messages = {
    parentInputInfo: { id: 'app.infrastructures.parentInputInfo' }
};

const useStyles = makeStyles(() => ({
    gridItem: {
        paddingRight: '1em'
    },
    gridParent: {
        paddingLeft: '1em'
    }
}));
function ParentInfrastructureInput({
    parentsOptions,
    options,
    name,
    control,
    readOnly,
    loading
}) {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container>
            <Grid item xs={12} sx={{ mb: 3 }}>
                <Alert severity='warning'>
                    {intl.formatMessage(messages.parentInputInfo)}
                </Alert>
            </Grid>

            <Grid className={classes.gridItem} container item xs={4}>
                <InfrastructureTypeAutoComplete control={control} name={name} options={options} readOnly={readOnly} />
            </Grid>
            <Grid className={classes.gridParent} container item xs={8}>
                <InfrastructureParentAutoComplete control={control} name='parent_id' options={parentsOptions} readOnly={readOnly} loading={loading} />
            </Grid>
        </Grid>
    );
}
ParentInfrastructureInput.propTypes = {
    options: PropTypes.array.isRequired,
    parentsOptions: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    loading: PropTypes.bool.isRequired
};

ParentInfrastructureInput.defaultProps = {
    readOnly: false
};
export default ParentInfrastructureInput;
