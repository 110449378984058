import React from 'react';
import { useIntl } from 'react-intl';
import { bool, func, object } from 'prop-types';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TrashDatePicker from './TrashDatePicker';

const useStyles = makeStyles((theme) => ({
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        verticalAlign: 'top'
    }
}));

const messages = {
    dialog: {
        title: 'app.trash.dialogTitle',
        inputLabelStart: 'app.trash.inputLabelStart',
        inputLabelEnd: 'app.trash.inputLabelEnd',
        cancel: 'app.trash.dialogCancel',
        accept: 'app.trash.dialogAccept'
    }
};

function TrashDialog({
    triggerDialog, isLoading, handleSubmit, control
}) {
    const classes = useStyles();
    const intl = useIntl();
    const handleTrigger = (shouldExit) => () => triggerDialog(shouldExit);

    return (
        <Dialog open onClose={handleTrigger(false)}>
            <DialogTitle id='form-dialog-title'>
                {intl.formatMessage({ id: messages.dialog.title })}
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={handleTrigger(true)}
                    aria-label='close'
                    className={classes.close}
                    size='large'
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Controller
                        name='firstMonth'
                        control={control}
                        defaultValue={dayjs().subtract(1, 'month')}
                        render={({ field }) => (
                            <TrashDatePicker
                                label={intl.formatMessage({ id: messages.dialog.inputLabelStart })}
                                field={field}
                            />
                        )}
                    />
                    <Controller
                        name='secondMonth'
                        control={control}
                        defaultValue={dayjs()}
                        render={({ field }) => (
                            <TrashDatePicker
                                label={intl.formatMessage({ id: messages.dialog.inputLabelEnd })}
                                field={field}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type='submit' color='primary'>
                        {isLoading ? <CircularProgress size={15} /> : intl.formatMessage({ id: messages.dialog.accept })}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

TrashDialog.propTypes = {
    triggerDialog: func.isRequired,
    isLoading: bool.isRequired,
    handleSubmit: func.isRequired,
    control: object.isRequired
};

export default TrashDialog;
