import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import { clone } from 'lodash';
import Button from '@mui/material/Button';
import InvoiceForm from '../../../../common/InvoiceForm/InvoiceForm';
import restRequestsHelper from '../../../../../lib/restRequestsHelper';
import DuplicatedInvoiceDialog from '../DuplicatedInvoiceDialog/DuplicatedInvoiceDialog';
import defaultInvoice from '../../../../common/InvoiceForm/InvoiceDefault';
import errors, { statuses } from '../../../../../constants/ResponseCodes';
import FormPickerMaster from '../../../../common/FormPicker/FormPickerMaster';
import { HotkeyProvider } from '../../../../common/Hotkeys/Hotkeys';
import defaultInvoiceTypeValue from '../../../../../constants/invoiceTypes';
import FormFileManager, { prepareFilesForUpload, useFormFilesState } from '../../../../common/FormFileManager/FormFileManager';

const messages = {
    successAdding: { id: 'app.addInvoice.success' },
    errorAdding: { id: 'app.addInvoice.error' },
    alreadyAdded: { id: 'app.addInvoice.alreadyAdded' },
    docName: { id: 'app.addInvoice.docName' },
    filenameDuplicated: { id: 'app.addInvoice.filenameDuplicated' },
    filesLimitAlmostReached: { id: 'app.addInvoice.filesLimit' },
    numbers: { id: 'app.addInvoice.numbers' },
    invoicePage: { id: 'app.addInvoice.invoicePage' },
    invoiceDelete: { id: 'app.addInvoice.delete' },
    ftpInvalidAddr: { id: 'app.addInvoice.ftpInvalidAddr' }

};
// eslint-disable-next-line no-unused-vars
function AddInvoiceManually() {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const location = useLocation();
    const user = useSelector((state) => state.user);
    const [openDuplicatedDialog, setOpenDuplicatedDialog] = useState(false);
    const [duplicatedInvoiceData, setDuplicatedInvoicedata] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [invoiceType, setInvoiceType] = useState(defaultInvoiceTypeValue);
    const [invoiceFormData, setInvoiceFormData] = useState(defaultInvoice());

    // eslint-disable-next-line no-unused-vars
    const [invoices, setInvoices] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [invoiceIndex, setInvoiceIndex] = useState(0);
    const [generatedInvoices, setGeneratedInvoices] = useState(false);
    useEffect(() => {
        if (!user) {
            history.push('/');
        }
        if (location.state && location.state.invoices) {
            setGeneratedInvoices(true);
            setInvoices(location.state.invoices);
            setInvoiceFormData(location.state.invoices[0]);
            setInvoiceType(defaultInvoiceTypeValue);
        }
    }, []);

    useEffect(() => {
        if (invoices.length > 0) {
            setInvoiceFormData(invoices[invoiceIndex]);
        }
    }, [invoiceIndex, invoices]);

    const fileManagerRef = useRef();

    const wipeForm = () => {
        setInvoiceFormData(defaultInvoice());
        fileManagerRef.current.flushFiles();
    };

    const {
        setFilesVal, getFilesVal, filesField, setFilesField
    } = useFormFilesState(['files'], { files: [] });

    const handleFormChange = (data) => {
        setInvoiceFormData(() => data);
    };

    const addInvoice = async (forceAdd = false) => {
        setIsLoading(true);
        const data = clone(invoiceFormData);
        const filesData = prepareFilesForUpload(getFilesVal('files'));

        const preparedData = {
            ...data,
            group: data.group,
            invoiceType: invoiceType.id,
            filesMetadata: filesData.metadata
        };

        restRequestsHelper.addInvoice(preparedData, filesData.files, forceAdd).then((response) => {
            if (response.status === statuses.added) {
                const successMsg = intl.formatMessage({ id: messages.successAdding.id });
                const numbersMsg = intl.formatMessage({ id: messages.numbers.id });
                enqueueSnackbar(
                    `${successMsg} [${numbersMsg}: ${response.invoiceNumber}]`,
                    { variant: 'success', autoHideDuration: 20 * 1000 }
                );
                if (!generatedInvoices || invoices.length === 1) {
                    setInvoiceFormData(defaultInvoice());
                    setGeneratedInvoices(false);
                    setInvoices([]);
                    fileManagerRef.current.flushFiles();
                } else {
                    const newInvoices = [...invoices];
                    newInvoices.splice(invoiceIndex, 1);
                    setInvoices(newInvoices);
                    if (invoiceIndex + 1 > newInvoices.length) {
                        setInvoiceIndex(invoiceIndex - 1);
                    }
                }
            } else if (response.status === statuses.alreadyAdded) {
                enqueueSnackbar(intl.formatMessage({ id: messages.alreadyAdded.id }), { variant: 'warning' });
                setOpenDuplicatedDialog(true);
                setDuplicatedInvoicedata(response.foundInvoices[0]);
            } else if (response.code) {
                let msg = '';
                switch (response.code) {
                    case errors.duplicatedFile:
                        msg = intl.formatMessage({ id: messages.filenameDuplicated.id });
                        break;
                    case errors.filesLimitAlmostReached:
                        msg = intl.formatMessage({ id: messages.filesLimitAlmostReached.id });
                        break;
                    case errors.ftpInvalidAddr:
                        msg = intl.formatMessage({ id: messages.ftpInvalidAddr.id });
                        break;
                    default:
                        break;
                }
                enqueueSnackbar(`[${response.code}] ${msg}`, { variant: 'error' });
            } else {
                enqueueSnackbar(intl.formatMessage({ id: messages.errorAdding.id }), { variant: 'error' });
            }
            setIsLoading(false);
        });
    };

    const handleInvoiceAdd = (event) => {
        event.preventDefault();
        addInvoice();
    };

    const handleForceAdd = () => {
        setIsLoading(true);
        setOpenDuplicatedDialog(false);
        addInvoice(true);
        if (!generatedInvoices) {
            setGeneratedInvoices(false);
            setInvoices([]);
        }
    };

    return (
        <div>
            <HotkeyProvider>
                <FormPickerMaster enable>
                    <DuplicatedInvoiceDialog
                        open={openDuplicatedDialog}
                        close={() => { setOpenDuplicatedDialog(false); }}
                        invoiceData={duplicatedInvoiceData}
                        forceAdd={handleForceAdd}
                    />
                    <Grid container>
                        <Grid container item xs={6}>
                            <InvoiceForm
                                isLoading={isLoading}
                                invoiceFormData={invoiceFormData}
                                onChange={handleFormChange}
                                onClear={wipeForm}
                                onSubmit={handleInvoiceAdd}
                                manySetNames
                                showSetNamesInput
                                isManualInvoice
                                invoiceType={invoiceType}
                                handleInvoiceType={setInvoiceType}
                                addingForm
                            />

                        </Grid>
                        <Grid container item xs={6}>
                            { generatedInvoices && (
                                <Grid item xs={12}>
                                    <Button onClick={() => {
                                        if (invoices.length === invoiceIndex + 1 && invoiceIndex > 0) {
                                            setInvoiceIndex(invoiceIndex - 1);
                                        }
                                        if (invoices.length > 0) {
                                            if (invoices.length === 1) {
                                                setInvoices([]);
                                                setGeneratedInvoices(false);
                                                setInvoiceFormData(defaultInvoice());
                                            }
                                            if (invoices.length > 0) {
                                                if (invoices.length === 1) {
                                                    setInvoices([]);
                                                    setGeneratedInvoices(false);
                                                    setInvoiceFormData(defaultInvoice());
                                                }
                                                setInvoices((oldInvoices) => {
                                                    const newInvoices = [...oldInvoices];
                                                    newInvoices.splice(invoiceIndex, 1);
                                                    return newInvoices;
                                                });
                                            }
                                        }
                                    }}
                                    >
                                        <FormattedMessage
                                            id={messages.invoiceDelete.id}
                                        />
                                    </Button>
                                    <Button onClick={() => {
                                        if (invoiceIndex > 0) {
                                            setInvoiceIndex(invoiceIndex - 1);
                                        }
                                    }}
                                    >
                                        {'<'}
                                    </Button>
                                    <span>
                                        <FormattedMessage
                                            id={messages.invoicePage.id}
                                            values={{
                                                invoice: invoiceIndex + 1,
                                                invoicesLength: invoices.length
                                            }}
                                        />
                                    </span>
                                    <Button onClick={() => {
                                        if (invoiceIndex + 1 < invoices.length) {
                                            setInvoiceIndex(invoiceIndex + 1);
                                        }
                                    }}
                                    >
                                        {'>'}
                                    </Button>
                                </Grid>
                            )}
                            <FormFileManager
                                setValue={setFilesVal}
                                getValues={getFilesVal}
                                setCurrentField={setFilesField}
                                currentField={filesField}
                                ref={fileManagerRef}
                            />
                        </Grid>
                    </Grid>
                </FormPickerMaster>
            </HotkeyProvider>
        </div>
    );
}

export default AddInvoiceManually;
