import { useIntl } from 'react-intl';
import { renderCellExpand } from '../../../common/DataGridCells/GridCellExpand';

const messages = {
    firstLevel: { id: 'app.settings.dictionaries.firstLevel' },
    secondLevel: { id: 'app.settings.dictionaries.secondLevel' }
};

export default function useColumns() {
    const intl = useIntl();
    return [{
        field: 'category',
        headerName: intl.formatMessage({ id: messages.firstLevel.id }),
        flex: 2,
        headerAlign: 'center',
        renderCell: renderCellExpand
    }, {
        field: 'subcategory',
        headerName: intl.formatMessage({ id: messages.secondLevel.id }),
        flex: 2,
        headerAlign: 'center'
    }];
}
