import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import formatMoney from '../../../lib/formatMoney';

const messages = {
    totalCostBurden: { id: 'app.invoices.totalCostBurden' }
};

const useStyles = makeStyles((theme) => ({
    footer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    }
}));

function DataGridFooter({ sum, customNode }) {
    const classes = useStyles();
    const intl = useIntl();
    return (
        <GridFooterContainer>
            <div className={classes.footer}>
                {customNode || (
                    <>
                        {intl.formatMessage(messages.totalCostBurden)}
                        :&nbsp;
                        {formatMoney(sum)}
                    </>
                )}
            </div>
            <GridFooter />
        </GridFooterContainer>
    );
}

DataGridFooter.propTypes = {
    sum: PropTypes.number,
    customNode: PropTypes.node
};

DataGridFooter.defaultProps = {
    sum: null,
    customNode: null
};

export default DataGridFooter;
