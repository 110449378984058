import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import restRequestsHelper from '../../../../lib/restRequestsHelper';
import TrashLayout from './shared/TrashLayout';

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    generateWrapper: {
        position: 'relative',
        display: 'inline'
    },
    footerElementsContainer: {
        marginTop: theme.spacing(1)
    },
    footerElements: {
        textAlign: 'left'
    }
}
));

const messages = {
    generateInvoices: { id: 'app.reports.generateInvoices' },
    generateInvoicesDirectly: { id: 'app.reports.generateInvoicesDirectly' },
    generateInvoicesTooltip: { id: 'app.reports.generateInvoicesTooltip' },
    generateDeclarations: { id: 'app.reports.generateDeclarations' }
};

function TrashCommon() {
    const [isLoadingInvoices, setLoadingInvoices] = useState(false);
    const [isLoadingDirectInvoices, setLoadingDirectInvoices] = useState(false);
    const [isLoadingDeclarations, setLoadingDeclarations] = useState(false);
    const trashLayoutRef = useRef();
    const [fields, setFields] = useState({});
    const [rows, setRows] = useState({});
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();

    const handleGenerateInvoices = async () => {
        setLoadingInvoices(true);
        const { data } = await restRequestsHelper.getTrashInvoices(fields, false);
        setLoadingInvoices(false);
        history.push({
            pathname: '/addInvoice/manually',
            state: { invoices: data }
        });
    };

    const handleGenerateInvoicesDirectly = async () => {
        setLoadingDirectInvoices(true);
        const { data } = await restRequestsHelper.getTrashInvoices(fields, true);
        setLoadingDirectInvoices(false);
        history.push({
            pathname: '/addInvoice/manually',
            state: { invoices: data }
        });
    };

    const handleGenerateDeclarationsPdf = async () => {
        setLoadingDeclarations(true);
        const isChanged = trashLayoutRef?.current?.getIsOnlyChanged();
        const date = fields.secondMonthStart;
        const { data } = await restRequestsHelper.getTrashDeclarationPdf(rows, isChanged, new Date(date));
        setLoadingDeclarations(false);
        const blob = new Blob([data], { type: 'application/zip' });
        saveAs(blob, 'declarations.zip');
    };

    return (
        <TrashLayout isCommon setFields={setFields} setRows={setRows} ref={trashLayoutRef}>
            <Grid container alignItems='center' spacing={2} className={classes.footerElementsContainer}>
                <Grid item xs={12} className={classes.footerElements}>
                    <div className={classes.generateWrapper}>
                        <Tooltip
                            title={intl.formatMessage(messages.generateInvoicesTooltip)}
                            placement='top'
                            disableInteractive
                        >
                            <span>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled={isLoadingInvoices}
                                    onClick={handleGenerateInvoices}
                                >
                                    {intl.formatMessage(messages.generateInvoices)}
                                </Button>
                            </span>
                        </Tooltip>
                        {isLoadingInvoices && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    <div className={classes.generateWrapper}>
                        <Tooltip
                            title={intl.formatMessage(messages.generateInvoicesTooltip)}
                            placement='top'
                            disableInteractive
                        >
                            <span>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled={isLoadingDirectInvoices}
                                    onClick={handleGenerateInvoicesDirectly}
                                    sx={{ ml: 2 }}
                                >
                                    {intl.formatMessage(messages.generateInvoicesDirectly)}
                                </Button>
                            </span>
                        </Tooltip>
                        {isLoadingDirectInvoices && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    <div className={classes.generateWrapper}>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={isLoadingDeclarations}
                            onClick={handleGenerateDeclarationsPdf}
                            sx={{ ml: 2 }}
                        >
                            {intl.formatMessage(messages.generateDeclarations)}
                        </Button>
                        {isLoadingDeclarations && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </Grid>
            </Grid>
        </TrashLayout>
    );
}

export default TrashCommon;

