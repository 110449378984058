import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { userSignedOut } from '../actions';

const useSignOut = (messageId) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    return () => {
        localStorage.removeItem('token');
        dispatch(userSignedOut());
        enqueueSnackbar(intl.formatMessage({ id: messageId }), { variant: 'success' });
        history.push('/');
    };
};

export default useSignOut;
