import React from 'react';
import UniversalCombobox from '../UniversalCombobox';

interface Props {
    [key: string]: string | number | boolean | object
}

function UniversalComboboxWorkaround(props: Props) {
    return (
        <UniversalCombobox
            {...props}
        />
    );
}

export default UniversalComboboxWorkaround;
