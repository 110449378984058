import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardContent, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import dayjs from 'dayjs';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import {
    setTask, setInspection, setVindication, setCorrespondence
} from '../../../../actions';
import dateFormat from '../../../../constants/dateFormat';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflow: 'auto'
    },
    taskCard: {
        textAlign: 'left',
        position: 'relative',
        marginBottom: theme.spacing(1),
        padding: 0
    },
    title: {
        marginBottom: theme.spacing(5)
    },
    items: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
        '&:last-child': {
            paddingBottom: 10
        }
    },
    taskNumber: {
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        textAlign: 'right',
        '&:hover': {
            color: theme.palette.text.primary
        }
    }
}));

const messages = {
    reminders: { id: 'app.homePage.reminders' },
    show: { id: 'app.homePage.reminders.show' },
    task: { id: 'app.homePage.reminders.task' },
    inspection: { id: 'app.homePage.reminders.inspection' },
    vindication: { id: 'app.homePage.reminders.vindication' },
    correspondence: { id: 'app.homePage.reminders.correspondence' }
};

const useUserReminders = (id) => {
    if (id) {
        const { data } = RestRequestsHelper.getUserReminders(useSWR, id);
        return data || [];
    }
    return [];
};

function ReminderCard({ value }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    const goToReminder = () => {
        switch (value?.record_type) {
            case 'task':
                dispatch(setTask(value.record));
                history.push('/tasks/browse');
                break;
            case 'inspection':
                dispatch(setInspection(value.record));
                history.push('/inspections/browse');
                break;
            case 'vindication':
                dispatch(setVindication(value.record));
                history.push('/vindication/browse');
                break;
            case 'correspondence':
                dispatch(setCorrespondence(value.record));
                history.push('/correspondence/browse');
                break;
            default:
                break;
        }
    };

    const getReminderTypeTranslation = () => {
        switch (value?.record_type) {
            case 'task':
                return ` (${intl.formatMessage(messages.task)})`;
            case 'inspection':
                return ` (${intl.formatMessage(messages.inspection)})`;
            case 'vindication':
                return ` (${intl.formatMessage(messages.vindication)})`;
            case 'correspondence':
                return ` (${intl.formatMessage(messages.correspondence)})`;
            default:
                return '';
        }
    };

    return (
        <Card
            variant='outlined'
            className={classes.taskCard}
        >
            <CardContent className={classes.items}>
                <Grid container spacing={0}>
                    <Grid item xs={8}>
                        <Typography variant='h6'>
                            {(() => {
                                switch (value?.record_type) {
                                    case 'correspondence':
                                        return value.record?.concerns;
                                    default:
                                        return value.record?.title;
                                }
                            })()}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                        <Typography
                            variant='h7'
                            color='textSecondary'
                            className={classes.taskNumber}
                            onClick={goToReminder}
                        >
                            {value.number || intl.formatMessage(messages.show)}
                            {getReminderTypeTranslation()}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant='h7'>
                            {(() => {
                                switch (value?.record_type) {
                                    case 'correspondence':
                                        return [
                                            value?.record?.journal?.$name,
                                            value?.entity?.$name
                                        ].join(' - ');
                                    default:
                                        return value.record?.concerns?.map?.((item) => item.$name).join(', ');
                                }
                            })()}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: 'right' }}>
                        <Typography variant='h7' color='textSecondary'>
                            {dayjs(value.date).format(dateFormat.dateWithHoursAndMinutes)}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

ReminderCard.propTypes = {
    value: PropTypes.object.isRequired
};

function IncommingReminders() {
    const user = useSelector((state) => state.user);
    const userReminders = useUserReminders(user.sub);
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant='h4' className={classes.title}>
                <FormattedMessage {...messages.reminders} />
            </Typography>
            {userReminders.map((value, index) => (
                <ReminderCard key={index} value={value} />
            ))}
        </div>
    );
}

export default IncommingReminders;
