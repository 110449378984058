export default function cleanUpSpecialChars(str: string): string {
    return str
        .replace(/Ą/g, 'A')
        .replace(/ą/g, 'a')
        .replace(/Ć/g, 'C')
        .replace(/ć/g, 'c')
        .replace(/Ę/g, 'E')
        .replace(/ę/g, 'e')
        .replace(/Ł/g, 'L')
        .replace(/ł/g, 'l')
        .replace(/Ń/g, 'N')
        .replace(/ń/g, 'n')
        .replace(/Ó/g, 'O')
        .replace(/ó/g, 'o')
        .replace(/Ś/g, 'S')
        .replace(/ś/g, 's')
        .replace(/[ŹŻ]/g, 'Z')
        .replace(/[źż]/g, 'z')
        .replace(/[^a-z0-9_ ]/gi, '');
}
