const SignUpErrors = {
    0: 'app.signUpError.generalError',
    1: 'app.signUpError.phoneNumberError',
    2: 'app.signUpError.entityNotExist',
    3: 'app.signUpError.phoneAlreadyUsed',
    4: 'app.signUpError.failedSendSMS',
    5: 'app.signUpError.invalidCode',
    6: 'app.signUpError.passwordConfirmError',
    7: 'app.signUpError.passwordError',
    8: 'app.signUpError.usernameTaken'
};

export default SignUpErrors;
