import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import 'tippy.js/animations/shift-away-subtle.css';

const cellStyles = makeStyles((theme) => ({
    altValue: {
        opacity: '0.5',
        fontSize: '12px',
        marginLeft: '10px'
    },
    tipContent: {
        padding: theme.spacing(1),
        wordBreak: 'break-word',
        whiteSpace: 'pre-line'
    },
    pointer: {
        cursor: 'pointer'
    }
}));

function TaskStatus({ status, showValueAtTooltip, valueField }) {
    const classes = cellStyles();
    const name = status?.$name ?? status?.name ?? '';
    return (
        <Tippy
            animation='shift-away-subtle'
            placement='top-start'
            content={(
                <Paper elevation={1}>
                    <Typography variant='body2' className={classes.tipContent}>
                        {showValueAtTooltip && `${status && status[valueField]} - `}
                        {name}
                    </Typography>
                </Paper>
            )}
        >
            <div style={{ color: status?.color || 'currentColor' }}>
                {name}
            </div>
        </Tippy>
    );
}

TaskStatus.propTypes = {
    status: PropTypes.any,
    showValueAtTooltip: PropTypes.bool,
    valueField: PropTypes.string
};

TaskStatus.defaultProps = {
    status: null,
    showValueAtTooltip: false,
    valueField: 'value'
};

export default TaskStatus;
