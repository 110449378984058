import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import Grid from '@mui/material/Grid';
import { debounce, cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import useSWR from 'swr';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import { parseFormattedMoney } from '../../common/DataGridCells/GridMoneyCell';
import MoneyTextField from '../../common/MoneyTextField/MoneyTextField';
import formatMoney from '../../../lib/formatMoney';
import generateFiltersText from '../../../lib/generateFiltersText';
import UniversalCombobox, { comboboxTypes } from '../../common/Combobox/UniversalCombobox';
import FixedAddButton from '../../common/FixedAddButton/FixedAddButton';
import { paginationDefaultValue, paginationAllowedValues } from '../../../constants/pagination';
import dateFormat from '../../../constants/dateFormat';
import DataGridFooter from '../../common/DataGridFooter/DataGridFooter';
import BalanceFooterElm from '../../common/DataGridFooter/shared/BalanceFooterElm';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';
import AddSelectDialog from '../../common/AddDialog/AddSelectDialog';
import CustomDatePicker from '../../common/CustomDatePicker/CustomDatePicker';
import formatUTCDate from '../../../lib/formatUTCDate';
import validateDate from '../../../lib/validateDate';
import defaultFilter, { TOOLBAR_SELECT } from './shared/data/transactionsConfig';
import TransactionCustomToolbar from './shared/ui/TransactionCustomToolbar/TransactionCustomToolbar';
import useColumns from './shared/hooks/useColumns';
import TransactionsPanel from './shared/ui/TransactionCustomToolbar/TransactionsPanel';
import AddTransaction from './shared/ui/AddTransaction';

const messages = {
    entity: { id: 'app.transactions.entity' },
    transactionType: { id: 'app.transactions.transactionType' },
    contractor: { id: 'app.transactions.contractor' },
    amountFrom: { id: 'app.invoices.amountFrom' },
    amountTo: { id: 'app.invoices.amountTo' },
    dateFrom: { id: 'app.transactions.dateFrom' },
    dateTo: { id: 'app.transactions.dateTo' },
    reference: { id: 'app.transactions.reference' },
    dateOkButton: { id: 'app.invoiceForm.OkButton' },
    dateCancelButton: { id: 'app.invoiceForm.CancelButton' },
    dateTodayButton: { id: 'app.invoiceForm.todayButton' },
    entityBankNumber: { id: 'app.transactions.entityBankNumber' },
    contractorBankNumber: { id: 'app.transactions.contractorBankNumber' },
    totalIncome: { id: 'app.transactions.totalIncome' },
    totalExpenses: { id: 'app.transactions.totalExpenses' },
    openingBalance: { id: 'app.transactions.openingBalance' },
    import: { id: 'app.addSelectDialog.import' },
    add: { id: 'app.addSelectDialog.add' },
    addDialog: { id: 'app.transactions.addDialog' }
};

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '80vh'
    },
    datagrid: {
        marginTop: theme.spacing(3),
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    }
}));

const useTransactions = (filterData, page, pageSize, sortModel) => {
    const { data, error, mutate } = RestRequestsHelper.getTransactions(useSWR, filterData, page, pageSize, sortModel);
    return {
        transactions: data?.rows || [],
        rowsCount: data?.count || 0,
        totalIncome: data?.totalIncome,
        totalExpenses: data?.totalExpenses,
        openingBalance: data?.openingBalance,
        loading: !error && !data,
        mutate
    };
};

const getDataDownload = async (filterData) => {
    const result = await RestRequestsHelper.getAllTransactions(filterData);
    return result?.data?.rows;
};

function Transactions() {
    const query = new URLSearchParams(window.location.search);
    const queryFilter = JSON.parse(query.get('filter') ?? '{}');
    const intl = useIntl();
    const locale = useSelector((state) => state.locale);
    dayjs.locale(locale);

    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(paginationDefaultValue);
    const [openAddSelectDialog, setOpenAddSelectDialog] = useState(false);
    const [openAddTransactionDialog, setOpenAddTransactionDialog] = useState(false);
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const [filterData, setFilterData] = useState(defaultFilter(queryFilter));
    const [exportFiltersText, setExportFiltersText] = useState('');
    const [height, setHeight] = useState();
    const [sortModel, setSortModel] = useState([
        { field: 'date', sort: 'desc' }
    ]);

    const [selectedRow, setSelectedRow] = useState(null);

    const [toolbarSelect, setToolbarSelect] = useState(TOOLBAR_SELECT.noSelect);
    const [selectedRows, setSelectedRows] = useState([]);

    const {
        transactions, loading,
        rowsCount, totalIncome, totalExpenses, openingBalance,
        mutate
    } = useTransactions(filterData, page, pageSize, sortModel);

    const id = query.get('id');

    const loadFormById = async () => {
        const [entry] = await getDataDownload({ _id: id });
        setSelectedRow(entry);
    };

    const handleClose = () => {
        setSelectedRow(null);
        if (id) {
            query.delete('id');
            history.replace({ pathname: window.location.pathname, search: query.toString() });
        }
    };

    useEffect(() => {
        if (id) {
            loadFormById();
        }
    }, [id]);

    const columns = useColumns({ setSelectedRow });

    const handleSortModelChange = (newModel) => {
        if (JSON.stringify(newModel) !== JSON.stringify(sortModel)) {
            setSortModel(newModel);
        }
    };

    const [dataDownload, setDataDownload] = useState();
    const [download, setDownload] = useState(false);

    const [headers, setHeaders] = useState([]);

    const setupHeaders = () => {
        const _headers = [];
        columns.map((i) => _headers.push({ label: i?.headerName, key: i.field }));
        setHeaders(_headers);
    };
    const handleDownloadClick = async () => {
        const result = await getDataDownload(filterData);
        const res = result.map((item) => {
            const amount = item?.amount.toString().replace(/\.?(\d{2})$/, '.$1');
            return {
                ...item,
                contractor: item.contractor?.name || item.contractor,
                entity: item.entity?.name || item.entity,
                date: formatUTCDate(item?.date, dateFormat.format),
                amount: formatMoney(amount, false)
            };
        });
        setDataDownload(res);
    };
    useEffect(() => {
        if (dataDownload) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [dataDownload]);
    useEffect(() => {
        const _filtersData = [
            {
                text: intl.formatMessage({ id: messages.entity.id }),
                value: filterData?.entity?.value?.name
            },
            {
                text: intl.formatMessage({ id: messages.entityBankNumber.id }),
                value: filterData?.entityBankNumber?.value
            },
            {
                text: intl.formatMessage({ id: messages.contractor.id }),
                value: filterData?.contractor?.value?.name
            },
            {
                text: intl.formatMessage({ id: messages.contractorBankNumber.id }),
                value: filterData?.contractorBankNumber?.value
            },
            {
                text: intl.formatMessage({ id: messages.amountFrom.id }),
                value: formatMoney(filterData.amountMinimum.value, false)
            },
            {
                text: intl.formatMessage({ id: messages.amountTo.id }),
                value: formatMoney(filterData.amountMaximum.value, false)
            },
            {
                text: intl.formatMessage({ id: messages.dateFrom.id }),
                value: formatUTCDate(filterData.dateFrom.value, dateFormat.format)
            },
            {
                text: intl.formatMessage({ id: messages.dateTo.id }),
                value: formatUTCDate(filterData.dateTo.value, dateFormat.format)
            },
            {
                text: intl.formatMessage({ id: messages.reference.id }),
                value: filterData?.reference?.value
            }
        ];
        setExportFiltersText(generateFiltersText(_filtersData));
    }, [filterData]);
    useEffect(() => {
        setupHeaders();
    }, []);
    useEffect(() => {
        if (dataDownload) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [dataDownload]);

    useEffect(() => {
        const headerHeight = document.querySelector('.MuiPaper-root') !== null ? (
            document.querySelector('.MuiPaper-root').getBoundingClientRect().height
        ) : (
            '64'
        );
        const gridHeight = document.querySelector('.MuiGrid-root') !== null ? (
            document.querySelector('.MuiGrid-root').getBoundingClientRect().height
        ) : (
            '96'
        );
        const dataGridHeight = document.querySelector('.MuiDataGrid-root') !== null ? (
            document.querySelector('.MuiDataGrid-root').getBoundingClientRect().height
        ) : (
            '72'
        );
        const dimension = Number(headerHeight) + Number(gridHeight) + Number(dataGridHeight);
        setHeight(String(dimension));
    }, []);

    const filterClearedAttr = (name, value) => {
        switch (name) {
            case 'type':
                return value.length > 0;
            case 'entity':
                return value && typeof value === 'object';
            case 'entityBankNumber':
                return value !== '';
            case 'contractor':
                return value && typeof value === 'object';
            case 'contractorBankNumber':
                return value !== '';
            case 'amountMinimum':
                return typeof value === 'number';
            case 'amountMaximum':
                return typeof value === 'number';
            case 'dateFrom':
                return value !== null && validateDate(value);
            case 'dateTo':
                return value !== null && validateDate(value);
            case 'reference':
                return value !== '';
            default:
                return false;
        }
    };

    const setFilterAttrFn = (name, value) => {
        setFilterData((newFilterData) => {
            const data = cloneDeep(newFilterData);
            data[name] = {
                value,
                filter: filterClearedAttr(name, value)
            };
            return data;
        });
    };

    const setFilterAttr = debounce((name, value) => setFilterAttrFn(name, value), 500);

    const getFilterAttrValue = (name) => (
        filterData[name].value
    );

    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, []);

    const handlePaginationModelChange = (data) => {
        setPageSize(data.pageSize);
        setPage(data.page);
    };

    const handleOpenAddDialog = () => {
        setOpenAddSelectDialog(true);
    };

    const handleAddManually = () => {
        setOpenAddTransactionDialog(true);
        setOpenAddSelectDialog(false);
    };

    const showCheckboxes = (
        toolbarSelect === TOOLBAR_SELECT.massRemoval
    );

    const handleRowSelected = (params) => {
        setSelectedRows(params);
    };

    const prepareBankAccountOptionLabel = (data) => {
        if (data?.number) {
            return `${data?.number} ${data?.name ? `(${data?.name})` : ''}`;
        }
        return data;
    };

    return (
        <div className={classes.main}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <UniversalCombobox
                        id='type'
                        margin='normal'
                        multiple
                        src={comboboxTypes.transactionTypes()}
                        label={intl.formatMessage(messages.transactionType)}
                        onChange={(value) => {
                            setFilterAttr('type', value);
                        }}
                        value={getFilterAttrValue('type')}
                        showColors
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        id='entity'
                        margin='normal'
                        src={comboboxTypes.communities(100, 'transactions')}
                        label={intl.formatMessage(messages.entity)}
                        onChange={(value) => {
                            setFilterAttr('entity', value);
                            setFilterAttrFn('entityBankNumber', '');
                        }}
                        value={getFilterAttrValue('entity')}
                        showColors
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        id='entityBankAccount'
                        value={getFilterAttrValue('entityBankNumber')}
                        options={getFilterAttrValue('entity')?.bank_accounts || []}
                        getOptionLabel={prepareBankAccountOptionLabel}
                        onInputChange={(_e, value) => setFilterAttr('entityBankNumber', value)}
                        onChange={(_e, value) => setFilterAttr('entityBankNumber', value?.number || value || '')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin='normal'
                                label={intl.formatMessage(messages.entityBankNumber)}
                            />
                        )}
                        disabled={!getFilterAttrValue('entity')}
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        id='contractor'
                        margin='normal'
                        src={comboboxTypes.contractors()}
                        label={intl.formatMessage(messages.contractor)}
                        onChange={(value) => {
                            setFilterAttr('contractor', value);
                        }}
                        value={getFilterAttrValue('contractor')}
                        showColors
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        id='contractorBankAccount'
                        freeSolo
                        options={getFilterAttrValue('contractor')?.bank_accounts?.map((item) => item.number) || []}
                        getOptionLabel={prepareBankAccountOptionLabel}
                        onInputChange={(_e, value) => setFilterAttr('contractorBankNumber', value)}
                        onChange={(_e, value) => setFilterAttr('contractorBankNumber', value?.number || value || '')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin='normal'
                                label={intl.formatMessage(messages.contractorBankNumber)}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <MoneyTextField
                        value={getFilterAttrValue('amountMinimum')}
                        onChange={(value) => setFilterAttr('amountMinimum', value.length ? parseFormattedMoney(value) : null)}
                        textFieldParams={{
                            label: intl.formatMessage(messages.amountFrom),
                            variant: 'outlined',
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <MoneyTextField
                        value={getFilterAttrValue('amountMaximum')}
                        onChange={(value) => setFilterAttr('amountMaximum', value.length ? parseFormattedMoney(value) : null)}
                        textFieldParams={{
                            label: intl.formatMessage(messages.amountTo),
                            variant: 'outlined',
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        name='dateFrom'
                        id='dateFrom'
                        label={intl.formatMessage(messages.dateFrom)}
                        showTodayButton
                        value={getFilterAttrValue('dateFrom')}
                        onChange={(date) => setFilterAttr('dateFrom', date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        name='dateTo'
                        id='dateTo'
                        label={intl.formatMessage(messages.dateTo)}
                        showTodayButton
                        value={getFilterAttrValue('dateTo')}
                        onChange={(date) => setFilterAttr('dateTo', date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        variant='outlined'
                        label={intl.formatMessage(messages.reference)}
                        fullWidth
                        onChange={(event) => setFilterAttr('reference', event.target.value)}
                    />
                </Grid>
            </Grid>
            <TransactionsPanel
                data={selectedRow}
                onClose={handleClose}
                onUpdate={() => mutate()}
            />

            <AddTransaction
                open={openAddTransactionDialog}
                onClose={() => setOpenAddTransactionDialog(false)}
                mutate={mutate}
            />

            <AddSelectDialog
                open={openAddSelectDialog}
                onClose={() => setOpenAddSelectDialog(false)}
                titleTranslationId={messages.addDialog.id}
                options={[
                    {
                        translationId: messages.add.id,
                        onClick: handleAddManually
                    },
                    {
                        translationId: messages.import.id,
                        redirectTo: '/bankStatements/import'
                    }
                ]}
            />

            <div style={{ height: `calc(100vh - ${height}px)` }}>
                <DataGrid
                    className={classes.datagrid}
                    paginationMode='server'
                    rows={transactions}
                    columns={columns}
                    columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                    paginationModel={{ page, pageSize }}
                    pageSizeOptions={paginationAllowedValues}
                    rowCount={rowsCount}
                    onPaginationModelChange={handlePaginationModelChange}
                    checkboxSelection={showCheckboxes}
                    onRowSelectionModelChange={handleRowSelected}
                    rowSelectionModel={selectedRows}
                    loading={loading}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    components={{
                        Toolbar: TransactionCustomToolbar,
                        Footer: DataGridFooter
                    }}
                    componentsProps={{
                        toolbar: {
                            exportFiltersText,
                            handleDownloadClick,
                            download,
                            dataDownload,
                            headers,
                            toolbarSelect,
                            setToolbarSelect,
                            selectedRows,
                            setSelectedRows,
                            mutate,
                            filterData,
                            user
                        },
                        footer: {
                            customNode: (
                                <BalanceFooterElm
                                    totalFirstObj={{ title: intl.formatMessage(messages.totalIncome), value: totalIncome }}
                                    totalSecondObj={{ title: intl.formatMessage(messages.totalExpenses), value: totalExpenses }}
                                    additionalElements={[
                                        {
                                            title: intl.formatMessage(messages.openingBalance),
                                            value: openingBalance
                                        }
                                    ]}
                                    calculateMethod={((first, second) => first - Math.abs(second) + openingBalance)}
                                />
                            )
                        }
                    }}
                />
                <FixedAddButton onClick={handleOpenAddDialog} />
            </div>
        </div>
    );
}

export default Transactions;
