import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { clone, debounce } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { HotkeyProvider } from '../../../../../common/Hotkeys/Hotkeys';
import FormPickerMaster from '../../../../../common/FormPicker/FormPickerMaster';
import InvoiceForm from '../../../../../common/InvoiceForm/InvoiceForm';
import DocumentPreviewMenu from '../../../../../common/DocumentPreview/DocumentPreviewMenu/DocumentPreviewMenu';
import DocumentPreview from '../../../../../common/DocumentPreview/DocumentPreview';
import { defaultIncomeInvoice } from '../../../../../common/InvoiceForm/InvoiceDefault';
import restRequestsHelper from '../../../../../../lib/restRequestsHelper';
import log from '../../../../../../lib/logger';

const messages = {
    download: { id: 'app.documentPreview.download' },
    successAdding: { id: 'app.importInvoice.success' },
    errorAdding: { id: 'app.importInvoice.error' },
    invoicePage: { id: 'app.addInvoice.invoicePage' },
    notParsedCorrectly: { id: 'app.addInvoice.notParsedCorrectly' },
    invoiceDelete: { id: 'app.addInvoice.delete' },
    alreadyAdded: { id: 'app.addIncome.alreadyAdded' },
    addingFailure: { id: 'app.addInvoice.addingFailure' },
    docName: { id: 'app.addInvoice.docName' },
    numbers: { id: 'app.addInvoice.numbers' },
    filenameDuplicated: { id: 'app.addInvoice.filenameDuplicated' },
    filesLimitAlmostReached: { id: 'app.addInvoice.filesLimit' },
    ftpInvalidPath: { id: 'app.addInvoice.ftpInvalidPath' },
    ftpInvalidAuth: { id: 'app.addInvoice.ftpInvalidAuth' },
    ftpInvalidAddr: { id: 'app.addInvoice.ftpInvalidAddr' },
    cannotPreparePreview: { id: 'app.addInvoice.cannotPreparePreview' },
    unknownFiletype: { id: 'app.addInvoice.unknownFileType' },
    emptyContent: { id: 'app.addInvoice.emptyContent' },
    documentsNumberInfo: { id: 'app.income.documentsNumberInfo' },
    notGenerated: { id: 'app.income.notGenerated' }
};
const useStyles = makeStyles(() => ({
    loading: {
        width: '100%'
    }
}));
function AddIncomeManually() {
    const classes = useStyles();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const [invoiceType] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [invoiceFormData, setInvoiceFormData] = useState(defaultIncomeInvoice());
    const [pdfFile, setPdfFile] = useState(null);
    const [isLoadingFile, setIsLoadingFile] = useState(true);

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, []);
    const getFile = async (signal) => {
        setIsLoadingFile(true);
        const filePdf = await restRequestsHelper.getIncomeFileView(invoiceFormData, signal);
        setIsLoadingFile(false);
        setPdfFile(filePdf);
    };
    const generateFile = () => {
        const controller = new AbortController();
        const { signal } = controller;
        if (invoiceFormData && isFormValid) {
            getFile(signal);
        }
        return () => controller.abort();
    };
    const getUpdatedFile = debounce(async (signal) => {
        setIsLoadingFile(true);
        const filePdf = await restRequestsHelper.getIncomeFileView(invoiceFormData, signal);
        setIsLoadingFile(false);
        setPdfFile(filePdf);
    }, 2000);
    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        if (invoiceFormData && isFormValid) {
            getUpdatedFile(signal);
        }
        return () => controller.abort();
    }, [invoiceFormData]);

    const wipeInvoiceData = () => {
        setInvoiceFormData(defaultIncomeInvoice());
    };

    const handleFormChange = (data) => {
        setInvoiceFormData(data);
    };
    const isContentCorrect = (data) => {
        let valid = true;
        data.content.forEach((item) => {
            if (item.title === '' || item.description === '') {
                valid = false;
            }
        });
        return valid;
    };
    const addIncome = async (forceAdd = false) => {
        setIsLoading(true);
        const data = clone(invoiceFormData);

        data.invoiceType = invoiceType;
        data.type = data.type.id;

        if (isContentCorrect(data)) {
            const addingResult = await restRequestsHelper.addIncome(data, forceAdd);
            log.info('addingResult', addingResult); // Added only to do not skip build-check
            if (addingResult.status === 'alreadyAdded') {
                const alreadyAddedsMsg = intl.formatMessage({ id: messages.alreadyAdded.id });
                enqueueSnackbar(
                    alreadyAddedsMsg,
                    { variant: 'error', autoHideDuration: 20 * 1000 }
                );
                setIsFormValid(false);
                setIsLoading(false);
                return;
            }
            const successMsg = intl.formatMessage({ id: messages.successAdding.id });
            enqueueSnackbar(
                successMsg,
                { variant: 'success', autoHideDuration: 20 * 1000 }
            );
            setIsFormValid(false);
            wipeInvoiceData();
        } else {
            enqueueSnackbar(intl.formatMessage({ id: messages.emptyContent.id }), { variant: 'warning' });
        }

        setIsLoading(false);
    };

    const handleSubmitInvoiceForm = (event) => {
        event.preventDefault();
        addIncome();
    };

    return (
        <div>
            <HotkeyProvider id='invoiceIndex'>
                <FormPickerMaster enable>
                    <Grid container>
                        <Grid container item xs={6} style={{ marginTop: '35px' }}>
                            <InvoiceForm
                                incomeForm
                                isLoading={isLoading}
                                onSubmit={handleSubmitInvoiceForm}
                                invoiceFormData={invoiceFormData}
                                onChange={handleFormChange}
                                onFormValidChange={(status) => setIsFormValid(status)}
                                addingForm
                            />
                        </Grid>
                        <Grid container item xs={6}>
                            <>
                                <DocumentPreviewMenu manually onGenerateFile={generateFile} />
                                {(!isLoadingFile && isFormValid) && (
                                    <DocumentPreview
                                        file={pdfFile}
                                        onLoadFile={() => {}}
                                        onDeleteFile={() => {}}
                                        showFileName
                                        currentFileName={`${invoiceFormData.documentNumber} - ${invoiceFormData.buyer.map((elm) => elm.name).join(', ')}.pdf`}
                                        disableActions
                                    />
                                )}
                                {(isLoadingFile && isFormValid) && (
                                    <div className={classes.loading}>
                                        <CircularProgress color='inherit' size={50} />
                                    </div>
                                )}

                            </>
                        </Grid>
                    </Grid>
                </FormPickerMaster>
            </HotkeyProvider>
        </div>
    );
}

export default AddIncomeManually;
