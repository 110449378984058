import dayjs from 'dayjs';

const defaultFilter = (query) => ({
    type: {
        value: query.type?.length ? query.type : [],
        filter: Boolean(query.type?.length)
    },
    entity: {
        value: query.entity || null,
        filter: Boolean(query.entity)
    },
    entityBankNumber: {
        value: '',
        filter: false
    },
    contractorBankNumber: {
        value: '',
        filter: false
    },
    contractor: {
        value: null,
        filter: false
    },
    amountMinimum: {
        value: 0,
        filter: false
    },
    amountMaximum: {
        value: 0,
        filter: false
    },
    dateFrom: {
        value: query.dateFrom || dayjs().startOf('year').format('YYYY/MM/DD'),
        filter: Boolean(query.dateFrom)
    },
    dateTo: {
        value: query.dateTo || dayjs().endOf('year').format('YYYY/MM/DD'),
        filter: Boolean(query.dateTo)
    },
    reference: {
        value: '',
        filter: false
    }
});
export default defaultFilter;

export const TOOLBAR_SELECT = {
    noSelect: 0,
    massRemoval: 1
};
