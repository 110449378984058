import { renderCellExpand } from '../../common/DataGridCells/GridCellExpand';
import dateFormat from '../../../constants/dateFormat';
import formatUTCDate from '../../../lib/formatUTCDate';

const getCorrespondenceColumns = (intl) => {
    const messages = {
        concerns: { id: 'app.correspondence.add.concerns' },
        to: { id: 'app.correspondence.add.toWhom' },
        from: { id: 'app.correspondence.add.fromWho' },
        date: { id: 'app.correspondence.add.date' },
        number: { id: 'app.correspondence.number' },
        type: { id: 'app.correspondence.add.type' }

    };
    return [
        {
            field: 'correspondenceDate',
            headerName: intl.formatMessage(messages.date),
            flex: 0.5,
            minWidth: 230,
            headerAlign: 'left',
            renderCell: ({ value }) => formatUTCDate(value, dateFormat.format)
        },
        {
            field: 'internalSignature',
            headerName: intl.formatMessage(messages.number),
            flex: 0.5,
            minWidth: 230,
            headerAlign: 'left',
            renderCell: renderCellExpand
        },
        {
            field: 'from',
            headerName: intl.formatMessage(messages.from),
            flex: 1,
            headerAlign: 'left',
            renderCell: renderCellExpand
        },
        {
            field: 'to',
            headerName: intl.formatMessage(messages.to),
            flex: 1,
            headerAlign: 'left',
            renderCell: renderCellExpand
        },
        {
            field: 'concerns',
            headerName: intl.formatMessage(messages.concerns),
            flex: 1,
            headerAlign: 'left',
            renderCell: renderCellExpand
        },
        {
            field: 'type',
            headerName: intl.formatMessage(messages.type),
            flex: 0.6,
            headerAlign: 'left',
            renderCell: (params) => renderCellExpand(params, null, () => (params.formattedValue.typ || ''))
        }
    ];
};

export default getCorrespondenceColumns;
