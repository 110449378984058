import { useIntl } from 'react-intl';
import { GridColDef } from '@mui/x-data-grid';
import { renderCellExpand } from '../../../DataGridCells/GridCellExpand';
import formatUTCDate from '../../../../../lib/formatUTCDate';
import dateFormat from '../../../../../constants/dateFormat';

const messages = {
    concerns: { id: 'app.correspondence.add.concerns' },
    to: { id: 'app.correspondence.add.toWhom' },
    from: { id: 'app.correspondence.add.fromWho' },
    date: { id: 'app.correspondence.add.date' },
    number: { id: 'app.correspondence.number' }
};

const useContractorCorrespondenceColumns = () => {
    const intl = useIntl();
    const col: GridColDef[] = [
        {
            field: 'correspondenceDate',
            headerName: intl.formatMessage(messages.date),
            flex: 0.5,
            minWidth: 230,
            headerAlign: 'left',
            renderCell: ({ value }) => formatUTCDate(value, dateFormat.format)
        },
        {
            field: 'internalSignature',
            headerName: intl.formatMessage(messages.number),
            flex: 1,
            minWidth: 230,
            headerAlign: 'left',
            renderCell: renderCellExpand
        },
        {
            field: 'from',
            headerName: intl.formatMessage(messages.from),
            flex: 1,
            headerAlign: 'left',
            renderCell: renderCellExpand
        },
        {
            field: 'to',
            headerName: intl.formatMessage(messages.to),
            flex: 1,
            headerAlign: 'left',
            renderCell: renderCellExpand
        },
        {
            field: 'concerns',
            headerName: intl.formatMessage(messages.concerns),
            flex: 0.5,
            headerAlign: 'left',
            renderCell: renderCellExpand
        }
    ];
    return col;
};

export default useContractorCorrespondenceColumns;
