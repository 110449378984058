import PropTypes from 'prop-types';
import React from 'react';
import { useBus } from 'react-bus';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import config from './formConfig';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%'
    }
}));

function FormPickerSelector({ children }) {
    const bus = useBus();
    const styles = useStyles();

    const emitSelection = () => {
        const sel = window.getSelection().toString();
        bus.emit(config.bus.selection, sel);
    };

    return (
        <Paper
            onMouseUp={emitSelection}
            className={styles.root}
        >
            {children}
        </Paper>
    );
}

FormPickerSelector.propTypes = {
    children: PropTypes.element
};

FormPickerSelector.defaultProps = {
    children: null
};

export default FormPickerSelector;
