import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Add from '@mui/icons-material/Add';
import Entities from '../Entities/Entities';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1)
    },

    addButton: {
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
}));
function EntitiesGroup({
    control, register, required,
    name, removeTooltipText, readOnly
}) {
    const classes = useStyles();

    const {
        fields, append, remove
    } = useFieldArray({
        control,
        name: `${name}`
    });
    const handleDeleteNorm = (index) => {
        remove(index);
    };
    const expandNorms = () => {
        append({ entity_id: null, date_from: null, date_to: null });
    };
    return (
        <Grid container>
            {fields.map((field, index) => (
                <Entities
                    key={index}
                    name={name}
                    index={index}
                    register={register}
                    control={control}
                    onDelete={handleDeleteNorm}
                    removeTooltipText={removeTooltipText}
                    readOnly={readOnly}
                    required={required}
                />
            ))}
            { !readOnly
            && (
                <Grid item xs={12} sm={12}>
                    <IconButton
                        onClick={expandNorms}
                        color='primary'
                        component='span'
                        className={classes.addButton}
                        size='large'
                    >
                        <Add />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
}

EntitiesGroup.defaultProps = {
    removeTooltipText: '',
    readOnly: false,
    required: false
};

EntitiesGroup.propTypes = {
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    removeTooltipText: PropTypes.string,
    readOnly: PropTypes.bool
};

export default EntitiesGroup;
