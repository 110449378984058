import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import restRequestsHelper from '../../../../lib/restRequestsHelper';

const messages = {
    thread: { id: 'app.correspondence.thread' },
    add: { id: 'app.common.add' },
    cancel: { id: 'app.common.cancel' },
    name: { id: 'app.common.name' }
};
export default function AddNewThread({ open, onClose }) {
    const [name, setName] = useState('');
    const intl = useIntl();
    const handleClose = () => {
        onClose();
        setName('');
    };

    const handleAddNewThread = async () => {
        await restRequestsHelper.addCorrespondenceThreads({ name });
        handleClose();
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <FormattedMessage id={messages.thread.id} />
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        id='name'
                        label={intl.formatMessage(messages.name)}
                        fullWidth
                        variant='standard'
                        value={name}
                        onChange={(ev) => setName(ev.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <FormattedMessage id={messages.cancel.id} />
                    </Button>
                    <Button onClick={handleAddNewThread}>
                        <FormattedMessage id={messages.add.id} />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

AddNewThread.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};
