import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Incomes from '../../../Income/Incomes';

function InhabitantCosts({ inhabitantData }) {
    const [buyerFilter, setBuyerFilter] = useState({});

    useEffect(() => {
        const buyer = {
            $name: inhabitantData.$name, _id: inhabitantData._id
        };
        setBuyerFilter(buyer);
    }, [inhabitantData]);

    return (
        <Incomes buyerFilter={buyerFilter} inhabitantView />
    );
}

InhabitantCosts.propTypes = {
    inhabitantData: PropTypes.object
};

InhabitantCosts.defaultProps = {
    inhabitantData: () => {}
};

export default InhabitantCosts;
