import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import restRequestsHelper from '../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../lib/displaySnackbar';
import errors, { responses } from '../../../../constants/ResponseCodes';
import BankStatementsForm from '../../../common/BankStatementsForm/BankStatementsForm';
import BankStatementsDialog from './components/BankStatementDialog';

const messages = {
    confirm: { id: 'app.bankStatement.confirm' },
    success: { id: 'app.bankStatement.success' },
    duplicates: { id: 'app.bankStatement.duplicates' },
    successMulti: { id: 'app.bankStatement.successMulti' },
    bankAccount: { id: 'app.bankStatement.bankAccount' },
    fileImportFail: { id: 'app.bankStatement.fileImportFail' },
    wrongRecord: { id: 'app.bankStatement.wrongRecord' },
    bankAccountNotFound: { id: 'app.bankStatement.bankAccountNotFound' },
    noOperations: { id: 'app.bankStatement.noOperations' }
};

function BankStatementsPage() {
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const displaySnackbar = useDisplaySnackbar({ enqueueSnackbar, intl });
    const [openDialog, setOpenDialog] = useState(true);
    const [statements, setStatements] = useState([]);
    const [metadata, setMetadata] = useState([]);

    const getStatements = async ({ files, verify, bankType }) => {
        const result = await restRequestsHelper.getBankStatementsData(files, bankType);
        // Show what files could not have been imported
        if (result.wrongFiles.length) {
            displaySnackbar('error', messages.fileImportFail, {
                filename: result.wrongFiles.join(', ')
            });
        }
        if (result.wrongRecords > 0) {
            displaySnackbar('error', messages.wrongRecord, {
                number: result.wrongRecords
            });
        }
        // Handle further response
        if (result.data?.length && result.data[0]?.length) {
            if (result.duplicates > 0) {
                displaySnackbar('warning', messages.duplicates, {
                    number: result.duplicates
                });
            }
            if (verify) {
                setStatements(result.data);
                setMetadata(result.metadata.map((meta) => ({ ...meta, imported: true, bankType })));
            } else {
                const confirmResult = await restRequestsHelper.confirmBankStatement(result.data.flat(), bankType);
                setOpenDialog(true);
                if (confirmResult.status === responses.added) {
                    displaySnackbar('success', messages.success, { name: result.metadata.map((item) => item.entityName).join(', ') });
                } else {
                    displaySnackbar('error', messages.noOperations);
                }
            }
        } else if (result.error) {
            if (result.code === errors.bankAccountNotFound) {
                displaySnackbar('error', messages.bankAccountNotFound, {
                    filename: result.filename
                });
            }
            setOpenDialog(true);
        } else {
            if (!result.wrongFiles.length) {
                displaySnackbar('error', messages.noOperations, {
                    filename: result.filename
                });
            }
            setOpenDialog(true);
        }
    };

    const handleSubmitDialog = async (data) => {
        setOpenDialog(false);
        getStatements(data);
    };

    const handleChange = ({ amount, fileIndex }) => {
        setMetadata((prevState) => prevState.filter((_item, index) => index !== fileIndex));
        setOpenDialog(!amount);
    };

    return (
        <div>
            <BankStatementsDialog onSubmit={handleSubmitDialog} open={openDialog} />
            <BankStatementsForm
                open={!openDialog}
                data={statements}
                metadata={metadata}
                onChange={handleChange}
                multiple
                editable
                removable
                insertable
            />
        </div>

    );
}
export default BankStatementsPage;
