import React from 'react';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import supportEmails from '../../../constants/supportEmails';

const useStyles = makeStyles(() => ({
    root: {
        margin: 5
    }
}));
const messages = {
    reportBug: { id: 'app.header.reportBug' }
};
function ReportBug() {
    const classes = useStyles();
    const intl = useIntl();
    return (
        <div className={classes.root}>
            <Tooltip title={intl.formatMessage(messages.reportBug)} disableInteractive>
                <IconButton
                    aria-label='report bug'
                    href={`mailto:${supportEmails.reportBug}`}
                    size='large'
                >
                    <ErrorOutlineIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
}
export default ReportBug;
