import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import restRequestsHelper from '../../../../lib/restRequestsHelper';
import CSVExportData from '../../../common/DataGridToolbar/CSVExportData';
import usePageTitle from '../../../common/DataGridToolbar/usePageTitle';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        padding: theme.spacing(1)
    }
}));

function SettlementsToolbar({ filter, columns }) {
    const classes = useStyles();
    const [data, setData] = useState();
    const [download, setDownload] = useState(false);

    const getAllSettlements = debounce(async (filterQuery, callback) => {
        const response = await restRequestsHelper.getAllSettlements(filterQuery);
        callback(response.data?.data ?? []);
    }, 500);

    const getHeaders = () => columns
        .filter(({ disableExport }) => !disableExport)
        .map(({ headerName, field }) => ({ label: headerName, key: field }));

    const prepareData = (settlements) => settlements.map((item) => ({
        ...item,
        entity: item.entity.map((entity) => entity.name).join(', '),
        contractor: item.contractor.map((contractor) => contractor.name).join(', ')
    }));
    const handleClick = () => {
        getAllSettlements(filter, (result) => {
            const preparedData = prepareData(result);
            setData(preparedData);
        });
    };
    useEffect(() => {
        if (data) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [data]);
    return (
        <div className={classes.toolbar}>
            <CSVExportData onClick={handleClick} download={download} headers={getHeaders()} filename={usePageTitle()} data={data} />
        </div>
    );
}

SettlementsToolbar.propTypes = {
    filter: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired
};

export default SettlementsToolbar;
