import { useIntl } from 'react-intl';
import { GridColDef } from '@mui/x-data-grid';
import { renderCellExpand } from '../../../DataGridCells/GridCellExpand';

const messages = {
    number: { id: 'app.vindication.number' },
    concerns: { id: 'app.vindication.concerns' },
    category: { id: 'app.vindication.category' }
};

type ConcernElmType = {
    name: string
};

const useContractorVindicationsColumns = () => {
    const intl = useIntl();
    const col: GridColDef[] = [
        {
            field: 'numberUI',
            headerName: intl.formatMessage(messages.number),
            width: 180,
            headerAlign: 'center',
            renderCell: ({ value }) => renderCellExpand({ value, showPointer: true })
        },
        {
            field: 'concernsUI',
            headerName: intl.formatMessage(messages.concerns),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: ({ row }) => renderCellExpand({ value: row.concerns.map((item: ConcernElmType) => item?.name).join(', ') })
        },
        {
            field: 'categoryUI',
            headerName: intl.formatMessage(messages.category),
            minWidth: 120,
            flex: 0.5,
            headerAlign: 'center',
            renderCell: renderCellExpand
        }
    ];
    return col;
};

export default useContractorVindicationsColumns;
