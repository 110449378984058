// formatting to pattern & calculating caret position
const formatInputValue = (event, onChange, format) => {
    const formattedEvent = event;
    formattedEvent.persist();
    const caretStart = event.target.selectionStart;
    const caretEnd = event.target.selectionEnd;

    const formattedValue = format(formattedEvent.target.value);

    formattedEvent.target.value = formattedValue;

    const spaces = formattedEvent.target.value.match(/([\s]+)/g)?.length || 0;

    if (formattedEvent.target.value.length > caretStart + spaces) {
        formattedEvent.target.setSelectionRange(caretStart, caretEnd);
    }
    onChange(formattedEvent);
};

export default formatInputValue;
