import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        verticalAlign: 'top'
    },
    buttonProgress: {
        position: 'relative',
        left: -50,
        top: 10
    }
}));

const messages = {
    close: { id: 'app.invoices.close' },
    change: { id: 'app.invoices.change' },
    title: { id: 'app.invoices.statusChangeDialogTitle' },
    content: { id: 'app.invoices.statusChangeDialogContent' }
};

function InvoiceStatusChangeDialog({
    open, onClose,
    onSubmit, loading
}) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
            <DialogTitle>
                <FormattedMessage id={messages.title.id} />
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={onClose}
                    aria-label='close'
                    className={classes.close}
                    size='large'
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography component='div'>
                    <Grid container align='center'>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                onClick={onClose}
                                color='secondary'
                                disabled={loading}
                            >
                                <FormattedMessage id={messages.close.id} />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={3}>
                                <Divider />
                            </Box>
                        </Grid>
                        <Grid item xs={12} align='left'>
                            <p>
                                <FormattedMessage id={messages.content.id} />
                            </p>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pb={2}>
                                <Button
                                    variant='contained'
                                    onClick={onSubmit}
                                    disabled={loading}
                                    color='primary'
                                >
                                    <FormattedMessage id={messages.change.id} />
                                </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Box>
                        </Grid>
                    </Grid>
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

InvoiceStatusChangeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool
};

InvoiceStatusChangeDialog.defaultProps = {
    onClose: () => {},
    onSubmit: () => {},
    loading: false
};

export default InvoiceStatusChangeDialog;
