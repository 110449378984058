import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const messages = {
    contractorName: {
        id: 'app.contractors.contractorName'
    },
    place: {
        id: 'app.contractors.place'
    },
    realEstate: {
        id: 'app.contractors.realEstate'
    },
    lastMonth: {
        id: 'app.reports.lastMonth'
    },
    currentMonth: {
        id: 'app.reports.currentMonth'
    },
    getOnlyChanged: {
        id: 'app.reports.onlyChanged'
    },
    correction: {
        id: 'app.reports.correction'
    },
    date: {
        id: 'app.contractors.date'
    },
    rate: {
        id: 'app.reports.rate'
    }
};

const useStyles = makeStyles({
    correctionExistContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }
});

function useColumns(isCommon, { firstMonth, secondMonth }) {
    const locale = useSelector((state) => state.locale);
    const intl = useIntl();
    const classes = useStyles();

    const isFirst = (field) => {
        const sharedCondition = field === 'name';

        if (isCommon) {
            return sharedCondition;
        }

        return sharedCondition || field === 'association';
    };

    const renderMonthNames = (month) => dayjs(month).locale(locale).format('MMMM');

    const changedRows = ({ row: { changed }, colDef: { field } }) => clsx('bids', {
        changed,
        first: isFirst(field)
    });

    const basicColumns = [
        {
            field: 'name',
            headerName: intl.formatMessage(isCommon ? messages.contractorName : messages.realEstate),
            flex: 1,
            headerAlign: isCommon ? 'left' : 'center',
            cellClassName: changedRows,
            displayIndex: 0
        },
        {
            field: 'rate',
            headerName: intl.formatMessage(messages.rate),
            width: 100,
            headerAlign: 'center',
            cellClassName: changedRows,
            displayIndex: 2
        },
        {
            field: 'before',
            headerName: renderMonthNames(firstMonth),
            width: 200,
            headerAlign: 'center',
            renderCell: (cellData) => {
                if (cellData.row.lastHasCorrection) {
                    return (
                        <Tooltip title={intl.formatMessage(messages.correction)} disableInteractive>
                            <div>
                                {cellData.value}
                                {' '}
                                !
                            </div>
                        </Tooltip>
                    );
                }
                return <div>{cellData.value}</div>;
            },
            cellClassName: changedRows,
            displayIndex: 3
        },
        {
            field: 'after',
            headerName: renderMonthNames(secondMonth),
            width: 200,
            headerAlign: 'center',
            renderCell: (cellData) => {
                if (cellData.row.currentHasCorrection) {
                    return (
                        <Tooltip title={intl.formatMessage(messages.correction)} disableInteractive>
                            <div {...(isCommon && { className: classes.correctionExistContainer })}>
                                {cellData.value}
                                {isCommon ? <AutorenewIcon color='error' /> : '!'}
                            </div>
                        </Tooltip>
                    );
                }
                return <div>{cellData.value}</div>;
            },
            cellClassName: changedRows,
            displayIndex: 4
        }
    ];

    if (isCommon) {
        return basicColumns;
    }

    return [...basicColumns, {
        field: 'premise',
        // TODO: Finish integration with MongoDB data
        headerName: intl.formatMessage(messages.place),
        flex: isCommon ? 1 : 0.5,
        headerAlign: 'center',
        cellClassName: changedRows,
        displayIndex: 1
    }].sort((element, nextElement) => element.displayIndex - nextElement.displayIndex);
}

export default useColumns;
