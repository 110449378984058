import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { isEqual, uniqBy } from 'lodash';
import SELECT_TYPES from './IdentitySelectTypes';

const IdentitySelect = forwardRef(({
    id, value, onChange, onBlur,
    label, required, src, optionLabel,
    modifyData, multiple, disabled, showColors, defaultValue
}, ref) => {
    const [options, setOptions] = useState([]);
    const [valueParsed, setValueParsed] = useState(value);
    const [previousDefaultValue, setPreviousDefaultValue] = useState(null);
    const useStyles = makeStyles((theme) => ({
        select: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        selectItem: {
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5)
        }
    }));
    const styles = useStyles();

    const handleDefaultValue = () => {
        if (defaultValue.length && !isEqual(previousDefaultValue, defaultValue)) {
            const _options = options.filter((option) => defaultValue.map((item) => item.key).includes(option.key));
            if (_options.length > 0) {
                setPreviousDefaultValue(defaultValue);
            }
            setValueParsed(_options);
            onChange(_options);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            // eslint-disable-next-line no-shadow
            const { data } = await src();
            setOptions(data || []);
        };
        fetchData();
        handleDefaultValue();
    }, []);
    useEffect(() => {
        handleDefaultValue();
    }, [defaultValue]);
    useEffect(() => {
        setValueParsed(value);
    }, [value]);

    const getOptionLabel = (option) => (
        option ? optionLabel(option) : ''
    );

    const handleAddationalBlur = () => {
        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    };

    const handleDelete = (key) => {
        const updatedValues = value.filter((item) => ((item.key || item._id) !== key));
        onChange(updatedValues);
    };

    const handleRenderValue = (selected) => {
        if (multiple) {
            return (
                <div className={styles.select}>
                    {selected.map((option, index) => (
                        <Chip
                            key={option.key || option.id || option._id || index}
                            label={getOptionLabel(option)}
                            className={styles.selectItem}
                            sx={option.color && showColors ? { backgroundColor: option.color } : {}}
                            onDelete={() => handleDelete(option.key || option._id)}
                            onMouseDown={(ev) => {
                                handleAddationalBlur();
                                ev.stopPropagation();
                            }}
                        />
                    ))}
                </div>
            );
        }
        return getOptionLabel(selected);
    };

    const getValidKey = (firstItem) => {
        const optionLabels = ['_id', 'key'];
        const [validKey] = optionLabels.filter((key) => firstItem[key]);
        return validKey;
    };

    const handleOnChange = (event, ...args) => {
        let array = event.target.value || [];
        const newEvent = { target: { value: array } };

        if (array.length > 0) {
            const [firstItem] = array;
            const validKey = getValidKey(firstItem);
            // If identity select takes multiple values
            // Make sure to get rid of the duplicates
            if (multiple && array.every((item) => item[validKey])) {
                array = uniqBy(array, validKey);
                newEvent.target.value = array;
            }
        }
        return onChange(newEvent, ...args);
    };

    const handleModifyData = (data) => {
        let preparedData = data;
        if (data.length) {
            const validKey = getValidKey(data[0]);

            // remove selected items from select options
            preparedData = data.filter((item) => !(Array.isArray(value) ? value : [value])
                .map((elm) => elm[validKey])
                .includes(item[validKey]));
        }

        return modifyData(preparedData);
    };

    return (
        <FormControl variant='outlined' fullWidth>
            <InputLabel htmlFor={label} required={required}>
                {label}
            </InputLabel>
            <Select
                id={id}
                label={label}
                renderValue={handleRenderValue}
                multiple={multiple}
                inputRef={ref}
                value={valueParsed}
                onChange={handleOnChange}
                onBlur={onBlur}
                disabled={disabled}
                onClose={handleAddationalBlur}
            >
                {handleModifyData(options).map((option, index) => (
                    <MenuItem
                        sx={option.color && showColors ? { color: option.color } : {}}
                        key={index}
                        value={option}
                    >
                        {getOptionLabel(option)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

IdentitySelect.propTypes = {
    id: PropTypes.string,
    src: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    label: PropTypes.string,
    optionLabel: PropTypes.func,
    required: PropTypes.bool,
    modifyData: PropTypes.func,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    showColors: PropTypes.bool,
    defaultValue: PropTypes.array
};

IdentitySelect.defaultProps = {
    id: '',
    onChange: () => {},
    onBlur: () => {},
    label: '',
    required: false,
    multiple: false,
    disabled: false,
    optionLabel: ({ $name }) => $name,
    modifyData: (data) => data,
    showColors: false,
    defaultValue: []
};

export default IdentitySelect;
export {
    SELECT_TYPES as selectTypes
};
