import React from 'react';
import Grid from '@mui/material/Grid';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import Paper from '@mui/material/Paper';
import FormPickerMaster from '../../../common/FormPicker/FormPickerMaster';
import { HotkeyProvider } from '../../../common/Hotkeys/Hotkeys';
import RestRequestHandler from '../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../lib/displaySnackbar';
import VindicationForm from '../../../common/VindicationForm/VindicationForm';
import errors from '../../../../constants/ResponseCodes';

const messages = {
    addedVindication: { id: 'app.addVindication.addedVindication' },
    errorAdd: { id: 'app.addVindication.errorAdd' },
    errorFileLimit: { id: 'app.addVindication.errorFileLimit' }
};

function AddVindication() {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar(messages.addedVindication);
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });

    const handleSubmit = async (formData, files) => {
        const formatData = ({ number, month, year }) => [number, month, year].join('/');
        const { data, error } = await RestRequestHandler.addVindication(formData, files);
        if (error) {
            switch (error.code) {
                case errors.filesLimitAlmostReached:
                    displaySnackbar('error', messages.errorFileLimit);
                    break;
                default:
                    displaySnackbar('error', messages.errorAdd);
                    break;
            }
            return false;
        }
        if (data) {
            displaySnackbar('success', messages.addedVindication, { number: formatData(data.value) });
            return true;
        }
        return false;
    };

    return (
        <div>
            <HotkeyProvider>
                <FormPickerMaster>
                    <Grid container justifyContent='center'>
                        <Paper>
                            <VindicationForm onSubmit={handleSubmit} />
                        </Paper>
                    </Grid>
                </FormPickerMaster>
            </HotkeyProvider>
        </div>
    );
}

export default AddVindication;
