import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import UniversalCombobox, { comboboxTypes } from '../../../../../common/Combobox/UniversalCombobox';
import IdentitySelect, { selectTypes } from '../../../../../common/IdentitySelect/IdentitySelect';
import dateFormat from '../../../../../../constants/dateFormat';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import onKeyDetect from '../../../../../../lib/onKeyDetect';
import userRoles from '../../../../../../constants/UserRoles';
import CustomDatePicker from '../../../../../common/CustomDatePicker/CustomDatePicker';

const messages = {
    nameOrNumber: { id: 'app.tasks.nameOrNumber' },
    concerns: { id: 'app.tasks.concerns' },
    status: { id: 'app.tasks.status' },
    category: { id: 'app.tasks.category' },
    subcategory: { id: 'app.tasks.subcategory' },
    received: { id: 'app.tasks.received' },
    leader: { id: 'app.tasks.leader' },
    producers: { id: 'app.tasks.producers' },
    industry: { id: 'app.tasks.industry' },
    dueDate: { id: 'app.tasks.dueDate' },
    dateOkButton: { id: 'app.tasks.okButton' },
    dateCancelButton: { id: 'app.tasks.cancelButton' },
    dateTodayButton: { id: 'app.tasks.todayButton' },
    dateClearButton: { id: 'app.tasks.clearButton' },
    applicantData: { id: 'app.addTask.applicantData' },
    applicantTooltip: { id: 'app.tasks.applicantTooltip' }
};

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: theme.spacing(1)
    }
}));

function TasksFilter({
    onChange, concernFilter, applicantFilter, inhabitantView, setLoaded, statusFilter
}) {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const isUserTasks = query.has('userTasks');
    const user = useSelector((state) => state.user);
    const {
        control, watch, setValue
    } = useForm();
    const intl = useIntl();
    const classes = useStyles();
    const watchAll = watch();
    const watchCategories = watch('category');

    // If we are on route '/tasks/browse?userTasks' then
    // add this current user to received field
    useEffect(() => {
        const asyncSetup = async () => {
            if (user && isUserTasks) {
                const { data } = await RestRequestsHelper.getUsers('', [userRoles.admin, userRoles.user]);
                const [match] = data.filter(({ _id }) => _id === user.sub);
                setValue('leader', [match]);
            }
        };
        asyncSetup();
    }, [user]);

    useEffect(() => {
        onChange({ ...watchAll });
    }, [watchAll]);

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (concernFilter.length) {
            setValue('concerns', concernFilter);
        }
    }, [JSON.stringify(concernFilter)]);

    useEffect(() => {
        if (applicantFilter.length) {
            setValue('applicantData', applicantFilter);
        }
    }, [JSON.stringify(applicantFilter)]);

    const handleModifyDataSubcategories = () => (
        orderBy(watchCategories?.map(({ subcategories }) => subcategories).flat() || [], ['value'], ['asc'])
    );

    return (
        <div className={classes.main}>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Controller
                        name='nameOrNumber'
                        control={control}
                        defaultValue=''
                        render={({
                            field: {
                                onChange: onChangeField, name, ref
                            }
                        }) => (
                            <TextField
                                fullWidth
                                variant='outlined'
                                name={name}
                                inputRef={ref}
                                label={intl.formatMessage(messages.nameOrNumber)}
                                onKeyDown={(event) => onKeyDetect(event, () => {
                                    onChangeField(event);
                                })}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        name='concerns'
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <UniversalCombobox
                                src={comboboxTypes.communities()}
                                label={intl.formatMessage(messages.concerns)}
                                id='concerns'
                                multiple
                                showColors
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='status'
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <IdentitySelect
                                showColors
                                id='status'
                                src={selectTypes.taskStatuses}
                                label={intl.formatMessage(messages.status)}
                                multiple
                                defaultValue={statusFilter}
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='dueDate'
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <CustomDatePicker
                                showTodayButton
                                clearable
                                variant='outlined'
                                inputFormat={dateFormat.format}
                                label={intl.formatMessage(messages.dueDate)}
                                okLabel={intl.formatMessage(messages.dateOkButton)}
                                cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                clearLabel={intl.formatMessage(messages.dateClearButton)}
                                renderInput={(params) => <TextField fullWidth {...params} name='dueDate' />}
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        name='producer'
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <UniversalCombobox
                                id='producer'
                                src={comboboxTypes.entitiesByName()}
                                label={intl.formatMessage(messages.producers)}
                                showColors
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='industry'
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <IdentitySelect
                                id='industry'
                                src={selectTypes.industry}
                                label={intl.formatMessage(messages.industry)}
                                multiple
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='category'
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <IdentitySelect
                                id='category'
                                src={selectTypes.taskCategories}
                                label={intl.formatMessage(messages.category)}
                                multiple
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='subcategory'
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <IdentitySelect
                                id='subcategory'
                                src={selectTypes.taskCategories}
                                label={intl.formatMessage(messages.subcategory)}
                                modifyData={handleModifyDataSubcategories}
                                disabled={!watchCategories?.length}
                                multiple
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='received'
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <UniversalCombobox
                                id='received'
                                src={comboboxTypes.users([userRoles.admin, userRoles.user])}
                                label={intl.formatMessage(messages.received)}
                                multiple
                                showColors
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='leader'
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <UniversalCombobox
                                id='leader'
                                src={comboboxTypes.users([userRoles.admin, userRoles.user])}
                                label={intl.formatMessage(messages.leader)}
                                showColors
                                multiple
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='applicantData'
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                            <UniversalCombobox
                                id='applicantData'
                                src={comboboxTypes.entitiesByName()}
                                label={intl.formatMessage(messages.applicantData)}
                                multiple
                                disabled={inhabitantView}
                                showColors
                                tooltipText={intl.formatMessage(messages.applicantTooltip)}
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

TasksFilter.propTypes = {
    onChange: PropTypes.func,
    concernFilter: PropTypes.array,
    setLoaded: PropTypes.func,
    applicantFilter: PropTypes.array,
    statusFilter: PropTypes.array,
    inhabitantView: PropTypes.bool
};

TasksFilter.defaultProps = {
    onChange: () => {},
    setLoaded: () => {},
    concernFilter: [],
    applicantFilter: [],
    statusFilter: [],
    inhabitantView: false
};

export default TasksFilter;
