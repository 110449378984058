import React from 'react';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import DetailsType from '../shared/DetailsType';
import formatMoney from '../../../../../lib/formatMoney';
import DataGridFooter from '../../../../common/DataGridFooter/DataGridFooter';
import DataGridCSVToolbar from '../../../../common/DataGridToolbar/DataGridCSVToolbar';
import convertColumnsToVisibilityModel from '../../../../../lib/convertColumnsToVisibilityModel';
import formatUTCDate from '../../../../../lib/formatUTCDate';
import dateFormat from '../../../../../constants/dateFormat';

const useStyles = makeStyles(() => ({
    main: {
        width: '90vw',
        height: 'inherit'
    }
}));

const messages = {
    contractorName: { id: 'app.contractors.contractorName' },
    value: { id: 'app.payoffs.value' },
    counterNumber: { id: 'app.payoffs.counterNumber' },
    buyer: { id: 'app.invoices.buyer' },
    seller: { id: 'app.invoices.seller' },
    title: { id: 'app.bankStatement.title' },
    amount: { id: 'app.addInvoice.contentAmount' },
    description: { id: 'app.addInvoice.description' },
    readingDateFrom: { id: 'app.payoffs.readingDateFrom' },
    readingDateTo: { id: 'app.payoffs.readingDateTo' },
    use: { id: 'app.payoffs.use' },
    startReading: { id: 'app.payoffs.startReading' },
    endReading: { id: 'app.payoffs.endReading' },
    infrastructureName: { id: 'app.payoffs.infrastructureName' }
};

function DetailsDrawer({
    show, onClose, detailsData, type, exportFileName
}) {
    const classes = useStyles();
    const intl = useIntl();

    const columnsReadings = [
        {
            field: 'owner',
            headerName: intl.formatMessage({ id: messages.contractorName.id }),
            flex: 1
        },
        {
            field: 'name',
            headerName: intl.formatMessage({ id: messages.infrastructureName.id }),
            flex: 1
        },
        {
            field: 'counterNumber',
            headerName: intl.formatMessage({ id: messages.counterNumber.id }),
            flex: 1
        },

        {
            field: 'startReadingDate',
            headerName: intl.formatMessage({ id: messages.readingDateFrom.id }),
            flex: 1,
            renderCell: (params) => formatUTCDate(params.value, dateFormat.format)

        },
        {
            field: 'startReading',
            headerName: intl.formatMessage({ id: messages.startReading.id }),
            flex: 1
        },
        {
            field: 'endReadingDate',
            headerName: intl.formatMessage({ id: messages.readingDateTo.id }),
            flex: 1,
            renderCell: (params) => formatUTCDate(params.value, dateFormat.format)
        },
        {
            field: 'endReading',
            headerName: intl.formatMessage({ id: messages.endReading.id }),
            flex: 1
        },
        {
            field: 'difference',
            headerName: intl.formatMessage({ id: messages.use.id }),
            flex: 1
        }
    ];
    const columnsBurdens = [
        {
            field: 'buyer',
            headerName: intl.formatMessage({ id: messages.buyer.id }),
            flex: 2
        },
        {
            field: 'seller',
            headerName: intl.formatMessage({ id: messages.seller.id }),
            flex: 2
        },
        {
            field: 'title',
            headerName: intl.formatMessage({ id: messages.title.id }),
            flex: 2
        },
        {
            field: 'description',
            headerName: intl.formatMessage({ id: messages.description.id }),
            flex: 2
        },
        {
            field: 'value',
            headerName: intl.formatMessage({ id: messages.amount.id }),
            flex: 2,
            renderCell: (params) => params.value
        }
    ];
    const setupHeaders = () => {
        const _headers = [];
        if (type === DetailsType.readings) {
            columnsReadings.forEach((i) => i.field !== 'warnings' && _headers.push({ label: i?.headerName, key: i.field }));
        } else {
            columnsBurdens.forEach((i) => i.field !== 'warnings' && _headers.push({ label: i?.headerName, key: i.field }));
        }
        return _headers;
    };
    const getDataForExport = () => {
        if (type === DetailsType.readings) {
            return detailsData.map((item) => ({
                ...item,
                endReadingDate: formatUTCDate(item.endReadingDate, dateFormat.format),
                startReadingDate: formatUTCDate(item.startReadingDate, dateFormat.format)
            }));
        }
        return detailsData.map((item) => ({
            ...item,
            value: item.value
        }));
    };

    const getSum = () => detailsData.reduce((previousValue, currentValue) => previousValue + currentValue.value, 0);
    return (
        <Drawer
            anchor='right'
            open={show}
            onClose={onClose}
        >
            <div className={classes.main}>
                {type === DetailsType.readings && (
                    <DataGrid
                        components={{ Toolbar: DataGridCSVToolbar }}
                        componentsProps={{
                            toolbar: {
                                fileName: exportFileName,
                                headers: setupHeaders(),
                                data: getDataForExport()
                            }
                        }}
                        rows={detailsData}
                        columns={columnsReadings}
                        columnVisibilityModel={convertColumnsToVisibilityModel(columnsReadings)}
                    />
                )}
                {type === DetailsType.burdens && (
                    <DataGrid
                        components={{
                            Toolbar: DataGridCSVToolbar,
                            Footer: DataGridFooter
                        }}
                        componentsProps={{
                            toolbar: {
                                fileName: exportFileName,
                                headers: setupHeaders(),
                                data: getDataForExport()
                            },
                            footer: { sum: formatMoney(getSum()) }
                        }}
                        rows={detailsData}
                        columns={columnsBurdens}
                        columnVisibilityModel={convertColumnsToVisibilityModel(columnsBurdens)}

                    />
                )}
            </div>

        </Drawer>
    );
}

DetailsDrawer.propTypes = {
    detailsData: PropTypes.array.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.number.isRequired,
    exportFileName: PropTypes.string.isRequired
};

export default DetailsDrawer;
