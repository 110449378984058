import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GridToolbarContainer } from '@mui/x-data-grid';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';
import { modifyContractors } from '../hooks/useContractors';
import usePageTitle from '../../../../common/DataGridToolbar/usePageTitle';
import CSVExportData from '../../../../common/DataGridToolbar/CSVExportData';

const generateFilterText = (filter) => {
    const {
        type, phrase
    } = filter;
    const filterText = [];
    if (type) {
        filterText.push(`type=${type.$name}`);
    }
    if (phrase) {
        filterText.push(`phrase=${phrase}`);
    }
    return filterText.join('; ');
};

function CustomToolbar({ filter, columns }) {
    const [data, setData] = useState();
    const [download, setDownload] = useState(false);

    const headers = [];
    columns.map((i) => headers.push({ label: i?.headerName, key: i.field }));

    const handleClick = async () => {
        const { data: contractors } = await RestRequestsHelper.getContractorsData(filter, 0, Number.MAX_SAFE_INTEGER);
        setData(modifyContractors(contractors?.rows || []));
    };

    useEffect(() => {
        if (data) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [data]);

    return (
        <GridToolbarContainer>
            <CSVExportData
                onClick={handleClick}
                download={download}
                headers={headers}
                filename={usePageTitle(generateFilterText(JSON.parse(filter)))}
                data={data}
            />
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    filter: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired
};

export default CustomToolbar;
