/* eslint-disable max-params */
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import config from '../config';
import requestsMaxLimitValue from '../constants/requests';
import Requests from './requests';
import log from './logger';

const request = new Requests();

export const objToQueryString = (obj) => {
    const keyValuePairs = [];
    Object.keys(obj).forEach((key) => {
        keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    });

    return keyValuePairs.join('&');
};

export const getToken = () => localStorage.getItem('token');

const getSearchResult = async (path, data) => {
    const token = getToken();
    const query = objToQueryString(data);
    try {
        const response = await fetch(`${config.backendHost}:${config.backendPort}${path}?${query}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    } catch {
        return new Promise((res) => {
            res({ error: 'Could not connect to the server' });
        });
    }
};

export default class RestRequestsHelper {
    static async validatePhoneNumber(phone) {
        const response = await fetch(`${config.backendHost}:${config.backendPort}/signUp/verifyPhone/${phone}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.json();
    }

    static async validateSmsCode(code) {
        const response = await fetch(`${config.backendHost}:${config.backendPort}/signUp/verifySmsCode/${code}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.json();
    }

    static async signUp(data = {}) {
        const response = await fetch(`${config.backendHost}:${config.backendPort}/user/signUp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return response.json();
    }

    static async signIn(data = {}) {
        const response = await fetch(`${config.backendHost}:${config.backendPort}/user/signIn`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return response.json();
    }

    static async refreshToken() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/user/refreshToken`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async checkIfSignedIn() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async addInvoice(data, files, forceAdd = false) {
        const token = getToken();
        const query = objToQueryString({
            force: forceAdd
        });
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('file[]', file);
        });
        formData.append('data', JSON.stringify(data || {}));
        const response = await fetch(`${config.backendHost}:${config.backendPort}/invoice?${query}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        });
        return response.json();
    }

    static async changeDbSettings(data = {}) {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/settings/db`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return response.json();
    }

    static async getDbSettings() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/settings/db`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async changeCheckInvoiceSettings(value = true) {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/settings/checkIfInvoiceAlreadyAdded`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ value })
        });
        return response.json();
    }

    static async getCheckInvoiceSettings() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/settings/checkIfInvoiceAlreadyAdded`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async testDbConnection() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/settings/testDb`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async getInvoiceData(file) {
        log.info('getInvoiceData file', file);
        const token = getToken();
        const formData = new FormData();
        formData.append('file', file);
        log.info('getInvoiceData formData', formData);
        const response = await fetch(`${config.backendHost}:${config.backendPort}/invoice/parseInvoice`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        });
        log.info('getInvoiceData response', response);
        return response.json();
    }

    static async addInvoiceFileDirectly(files, invoiceType) {
        const token = getToken();
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('file[]', file);
        });
        formData.append('invoiceType', invoiceType);
        const response = await fetch(`${config.backendHost}:${config.backendPort}/invoice/pdf/addDirectly/`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        });
        return response.json();
    }

    static async addInvoiceRtfFileDirectly(files, invoiceType) {
        const token = getToken();
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('file[]', file);
        });
        formData.append('invoiceType', invoiceType);
        const response = await fetch(`${config.backendHost}:${config.backendPort}/invoice/rtf/addDirectly/`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        });
        return response.json();
    }

    static async addDocsFromSheetDirectly(files, queryData) {
        const token = getToken();
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('file[]', file);
        });
        const query = objToQueryString(queryData);
        const response = await fetch(`${config.backendHost}:${config.backendPort}/invoice/sheet/addDirectly?${query}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        });
        return response.json();
    }

    static async getDocumentsFromSheet(files, queryData) {
        const token = getToken();
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('file[]', file);
        });
        const query = objToQueryString(queryData);
        const response = await fetch(`${config.backendHost}:${config.backendPort}/invoice/sheet/readData?${query}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        });
        return response.json();
    }

    static async getInvoiceFile(id) {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/invoice/invoiceFile/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.blob();
    }

    static async getDocumentStatuses(db = 'mongo') {
        return request.get(`/invoice/statuses?db=${db}`);
    }

    static async getInvoiceGroups(db) {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/invoice/groups?db=${db}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async getInvoicePositionDescription(title) {
        const token = getToken();
        const query = objToQueryString({
            title
        });
        const response = await fetch(`${config.backendHost}:${config.backendPort}/invoice/positionDescription?${query}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async setTargetDb(value) {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/settings/targetDb`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ value })
        });
        return response.json();
    }

    static async getTargetDb() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/settings/targetDb`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async getInvoiceTypes(db) {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/invoice/invoiceTypes?db=${db}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async changeFtpSettings(data = {}) {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/settings/fileStorage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return response.json();
    }

    static async testFtpSettings() {
        return request.get('/settings/testFileStorage');
    }

    static async getFtpSettings() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/settings/fileStorage`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async getRequests() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/requestsTimetable/invoiceImportRequestsTimetable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async updateRequest(id, data) {
        const token = getToken();
        const body = {
            script: data.script,
            time: data.time
        };
        const response = await fetch(`${config.backendHost}:${config.backendPort}/requestsTimetable/invoiceImportRequestsTimetable/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        return response.json();
    }

    static async changeActiveRequest(id, active) {
        const token = getToken();
        const body = {
            active
        };
        const response = await fetch(`${config.backendHost}:${config.backendPort}/requestsTimetable/changeActiveRequestsTimetable/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        return response.json();
    }

    static async scheduleRequest(data) {
        const token = getToken();
        const body = {
            script: data.script,
            time: data.time
        };
        const response = await fetch(`${config.backendHost}:${config.backendPort}/requestsTimetable/invoiceImportRequestsTimetable`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        return response.json();
    }

    static async deleteRequest(id) {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/requestsTimetable/invoiceImportRequestsTimetable/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async getTrashReportIndividual(data) {
        const token = getToken();
        const query = objToQueryString(data);
        const response = await fetch(`${config.backendHost}:${config.backendPort}/reports/buyer?${query}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async getTrashReportAll(data) {
        const token = getToken();
        const query = objToQueryString(data);
        const response = await fetch(`${config.backendHost}:${config.backendPort}/reports/seller?${query}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async getBankStatementsData(files, bankType) {
        const token = getToken();
        const formData = new FormData();
        formData.append('bankType', bankType.key);
        files.forEach((file) => {
            formData.append('file[]', file);
        });
        try {
            const response = await fetch(`${config.backendHost}:${config.backendPort}/bankStatements/parseStatement`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: formData
            });
            return response.json();
        } catch {
            return new Promise((res) => {
                res({ error: '' });
            });
        }
    }

    static async confirmBankStatement(data, bankType) {
        const token = getToken();
        const body = {
            data,
            bankType: bankType.key
        };
        try {
            const response = await fetch(`${config.backendHost}:${config.backendPort}/bankStatements/addStatement`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(body)
            });
            return response.json();
        } catch {
            return new Promise((res) => {
                res({ status: 0 });
            });
        }
    }

    static async _getTrashInvoices() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/reports/invoices/trash`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async getRequestsHistory() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/requestsHistory/import`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async getCorrespondenceOptions() {
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/correspondence/form-options`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return response.json();
    }

    static async addCorrespondence(data, files) {
        const token = getToken();
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('file[]', file);
        });

        formData.append('data', JSON.stringify(data));

        const response = await fetch(`${config.backendHost}:${config.backendPort}/correspondence`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData

        });
        return response.json();
    }

    static getCorrespondence(useSWR, filter, page, pageSize, sortModel) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            page,
            pageSize,
            sortModel: JSON.stringify(sortModel)
        };
        return request.swr(useSWR, '/correspondence', query);
    }

    static exportCorrespondence(filter, sortModel) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            sortModel: JSON.stringify(sortModel)
        };
        return request.get('/correspondence', query);
    }

    static async getFile(id, db) {
        const token = getToken();
        const url = `${config.backendHost}:${config.backendPort}/file/${id}/?db=${db}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        if (!response.ok) {
            const error = await response.json();
            throw error;
        }
        const blob = await response.blob();
        return blob;
    }

    static setPartiallyChangedInvoiceFlowState(id) {
        return request.put(`/invoice/flow-state/${id}`, {
            partiallyChanged: true
        });
    }

    // Formats invoice filter to be URL-encodable
    static _formatFilter(filter) {
        const simpleFilter = cloneDeep(filter);
        Object.entries(filter).forEach(([key, { value }]) => {
            if (value instanceof dayjs) {
                simpleFilter[key].value = value.format('YYYY/MM/DD');
            } else if (['seller', 'buyer'].includes(key) && value) {
                const preparedValue = Array.isArray(value) ? value : [value];
                simpleFilter[key].value = preparedValue.map((item) => ({ old_id: item.old_id, _id: item._id }));
            } else if (['status', 'group', 'position', 'invoiceType'].includes(key)) {
                // eslint-disable-next-line camelcase
                const filterFunction = ({ id, old_id }) => id || old_id;
                simpleFilter[key].value = value && value.map
                    ? value.map(filterFunction)
                    : value;
            }
        });
        return simpleFilter;
    }

    static getTransactions(useSWR, filter, page, pageSize, sortModel) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            page,
            pageSize,
            sortModel: JSON.stringify(sortModel)
        };
        return request.swr(useSWR, '/transaction', query);
    }

    static getAllTransactions(filter, page = 0, pageSize = requestsMaxLimitValue) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            page,
            pageSize
        };
        return request.get('/transaction', query);
    }

    static getInvoices(useSWR, filter, page, pageSize, sortModel) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            page,
            pageSize,
            sortModel: JSON.stringify(sortModel)
        };
        return request.swr(useSWR, '/invoice', query);
    }

    static getIncomes(useSWR, filter, page, pageSize, sortModel) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            page,
            pageSize,
            sortModel: JSON.stringify(sortModel)
        };
        return request.swr(useSWR, '/income', query);
    }

    static generateIncomeNumber(sellerId, type, saleDate) {
        const query = {
            data: JSON.stringify({
                sellerId,
                type,
                saleDate
            })
        };
        return request.get('/income/generateDocumentNumber', query);
    }

    static getArticles(path, language) {
        const query = {
            path,
            language
        };
        return request.get('/articles', query);
    }

    static addArticle(body) {
        return request.post('/articles', { }, body);
    }

    static updateArticle(id, body) {
        return request.put(`/articles/${id}`, { }, body);
    }

    static deleteArticle(id) {
        return request.delete(`/articles/${id}`);
    }

    static getAllInvoices(filter, page = 0, pageSize = requestsMaxLimitValue) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            page,
            pageSize
        };
        return request.get('/invoice', query);
    }

    static getAllIncomes(filter, page = 0, pageSize = requestsMaxLimitValue) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            page,
            pageSize
        };
        return request.get('/income', query);
    }

    static getInvoicesSum(useSWR, filter) {
        const query = { filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)) };
        return request.swr(useSWR, '/invoice/sum-invoices', query);
    }

    static getIncomesSum(useSWR, filter) {
        const query = { filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)) };
        return request.swr(useSWR, '/income/sum-invoices', query);
    }

    static getInvoiceFlowStates(useSWR) {
        return request.swr(useSWR, '/invoice/statuses');
    }

    static setInvoiceFlowState(id, flowState) {
        return request.put(`/invoice/flow-state/${id}`, { flowState });
    }

    static getTrashInvoices(filter, isDirectly = false) {
        return request.get('/reports/invoices/trash', { ...filter, isDirectly });
    }

    static getTrashDeclarationPdf(data, isChanged = false, date = null) {
        return request.post('/reports/declaration/pdf', {}, { data, isChanged, date }, null, (res) => res.blob());
    }

    static getInvoicePositionsTitles(db) {
        return request.get(`/invoice/positions-titles?db=${db}`);
    }

    static getPositionsTypes(useSWR) {
        return request.swr(useSWR, '/invoice/positions-titles');
    }

    static deleteInfrastructure(id) {
        return request.delete(`/infrastructure/id/${id}`);
    }

    static deleteInvoiceIncome(id) {
        return request.delete(`/invoice/id/${id}`);
    }

    static deleteTransaction(id) {
        return request.delete(`/transaction/id/${id}`);
    }

    static deleteInvoicesIncomes(filter, kind) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            kind
        };
        return request.delete('/invoice/invoices', query);
    }

    static deleteTransactions(filter) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter))
        };
        return request.delete('/transaction/transactions', query);
    }

    static getBanks(searchPhrase) {
        return request.get('/bankStatements/bank-list', { searchPhrase });
    }

    static getBankNumbers(id, searchPhrase) {
        return request.get('/list/bank-accounts/entity', { id, searchPhrase });
    }

    static getContractors(useSWR, searchPhrase, id = null) {
        return request.swr(useSWR, '/contractor', { searchPhrase, id });
    }

    static getContractorsOnce(searchPhrase, id = null) {
        return request.get('/contractor', { searchPhrase, id });
    }

    static getContractorData(id, type = null) {
        return request.get('/contractor/data', { id, type });
    }

    static getIndustry(searchPhrase) {
        return request.get('/list/industries', { searchPhrase });
    }

    static getUsers(searchPhrase, roles) {
        return request.get('/list/users', { searchPhrase, roles });
    }

    static getCorrespondenceContractors(searchPhrase) {
        return request.get('/correspondence/journal-list', { searchPhrase });
    }

    static getEntitiesByPhoneNumbers(searchPhrase, entityID) {
        return request.get('/list/entities/phone', { entityID, searchPhrase });
    }

    static getEntitiesByName(searchPhrase) {
        return request.get('/list/entities/name', { searchPhrase });
    }

    static getEntitiesContactsByName(searchPhrase) {
        return request.get('/list/entities/contacts/name', { searchPhrase });
    }

    static getTask(id) {
        return request.get(`/task/id/${id}`);
    }

    static getVindication(id) {
        return request.get(`/vindication/id/${id}`);
    }

    static getInspection(id) {
        return request.get(`/inspection/id/${id}`);
    }

    static getInspectionsList(searchPhrase) {
        return request.get('/list/inspection', { searchPhrase });
    }

    static getTaskCategories(searchPhrase) {
        return request.get('/list/task/categories', { searchPhrase });
    }

    static getVindicationCategories(searchPhrase) {
        return request.get('/list/vindication/categories', { searchPhrase });
    }

    static getInspectionTypes(searchPhrase) {
        return request.get('/list/inspection-types', { searchPhrase });
    }

    static getCommunities(searchPhrase, limit, existsOn) {
        return request.get('/list/contractors', { searchPhrase, limit, existsOn });
    }

    static editContractor(data) {
        return request.put('/contractor/edit', {}, { data });
    }

    static getContactTypes(searchPhrase) {
        return request.get('/list/entities/contact-types', { searchPhrase });
    }

    static addTask(body, files) {
        return request.post('/task/add', { }, body, files);
    }

    static addVindication(body, files) {
        return request.post('/vindication/add', { }, body, files);
    }

    static addInspection(body, files) {
        return request.post('/inspection/add', { }, body, files);
    }

    static updateTask(body, files) {
        return request.put('/task/update', { }, body, files);
    }

    static updateVindication(body, files) {
        return request.put('/vindication/update', { }, body, files);
    }

    static updateInspection(body, files) {
        return request.put('/inspection/update', { }, body, files);
    }

    static updateBankStatement(body) {
        return request.put('/bankStatements/update', { }, body);
    }

    static getTaskStatuses(searchPhrase) {
        return request.get('/list/task-statuses', { searchPhrase });
    }

    static getVindicationStatuses(searchPhrase) {
        return request.get('/list/vindication/statuses', { searchPhrase });
    }

    static getTasks(useSWR, {
        filter, page, pageSize, filtersLoaded, sortModel
    }) {
        return request.swr(useSWR, filtersLoaded ? '/task' : null, {
            filter, page, pageSize, sortModel
        });
    }

    static getVindications(useSWR, {
        filter, page, pageSize, filtersLoaded, sortModel
    }) {
        return request.swr(useSWR, filtersLoaded ? '/vindication' : null, {
            filter, page, pageSize, sortModel
        });
    }

    static getHistory(useSWR, {
        collectionName, recordId, page, pageSize, sortModel
    }) {
        return request.swr(useSWR, `/history/collection/${collectionName}`, {
            recordId, page, pageSize, sortModel
        });
    }

    static getInspections(useSWR, {
        filter, page, pageSize, filtersLoaded, sortModel
    }) {
        return request.swr(useSWR, filtersLoaded ? '/inspection' : null, {
            filter, page, pageSize, sortModel
        });
    }

    static getAllTasks({ filter, filtersLoaded }) {
        return request.get(filtersLoaded ? '/task' : null, {
            filter, page: 0, pageSize: requestsMaxLimitValue
        });
    }

    static getAllVindications({ filter, filtersLoaded }) {
        return request.get(filtersLoaded ? '/vindication' : null, {
            filter, page: 0, pageSize: requestsMaxLimitValue
        });
    }

    static getAllInspections(filter, page = 0, pageSize = requestsMaxLimitValue) {
        return request.get('/inspection', { filter, page, pageSize });
    }

    static getSubtasks(id, filter = {}) {
        return request.get(`/task/subtasks/id/${id}`, { filter });
    }

    static getAssignedTasksToInspection(id) {
        return request.get(`/task/assignedTasksToInspection/id/${id}`);
    }

    static getSubinspections(id, filter = {}) {
        return request.get(`/inspection/subinspections/id/${id}`, { filter });
    }

    static getContractorTasks(id, page = 0, pageSize = requestsMaxLimitValue) {
        return request.get(`/task/contractor/id/${id}`, { page, pageSize });
    }

    static getContractorInfrastructures(id, page = 0, pageSize = requestsMaxLimitValue) {
        return request.get(`/infrastructure/contractor/id/${id}`, { page, pageSize });
    }

    static getContractorIncomes(id, page = 0, pageSize = requestsMaxLimitValue) {
        return request.get(`/income/contractor/id/${id}`, { page, pageSize });
    }

    static getContractorInvoices(id, page = 0, pageSize = requestsMaxLimitValue) {
        return request.get(`/invoice/contractor/id/${id}`, { page, pageSize });
    }

    static getContractorInspections(id, page = 0, pageSize = requestsMaxLimitValue) {
        return request.get(`/inspection/contractor/id/${id}`, { page, pageSize });
    }

    static getContractorVindications(id, page = 0, pageSize = requestsMaxLimitValue) {
        return request.get(`/vindication/contractor/id/${id}`, { page, pageSize });
    }

    static getContractorCorrespondence(id, page = 0, pageSize = requestsMaxLimitValue) {
        return request.get(`/correspondence/contractor/id/${id}`, { page, pageSize });
    }

    static deleteTask(id) {
        return request.delete(`/task/delete/id/${id}`);
    }

    static deleteVindication(id) {
        return request.delete(`/vindication/delete/id/${id}`);
    }

    static deleteInspection(id) {
        return request.delete(`/inspection/delete/id/${id}`);
    }

    static copyTask(id) {
        return request.post(`/task/copy/id/${id}`);
    }

    static copyVindication(id) {
        return request.post(`/vindication/copy/id/${id}`);
    }

    static copyInspection(id) {
        return request.post(`/inspection/copy/id/${id}`);
    }

    static getProducers(searchPhrase) {
        return request.get('/list/producers', { searchPhrase });
    }

    static getEntitiesApplicantData(searchPhrase) {
        return request.get('/list/entities/applicant-data', { searchPhrase });
    }

    static getUserTasks(useSWR, userId) {
        return request.swr(useSWR, '/task/user', { id: userId });
    }

    static getUserReminders(useSWR, userId) {
        return request.swr(useSWR, '/list/reminder/user', { id: userId });
    }

    static async uploadReadings(files) {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('file[]', file);
        });
        const token = getToken();

        const response = await fetch(`${config.backendHost}:${config.backendPort}/reports/media/readings`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: formData
        });
        return response.json();
    }

    static getRealEstates(useSWR) {
        return request.swr(useSWR, '/infrastructure/realEstate');
    }

    static getInfrastructuresForRealEstates(useSWR, realEstateId = '', buildingId = '', storeysId = '') {
        const query = {
            realEstateId,
            buildingId,
            storeysId
        };
        return request.swr(useSWR, '/infrastructure/buildings', query);
    }

    static getReadingsForInfrastructure(id, counterType) {
        const query = {
            counterType
        };
        return request.get(`/readings/infrastructure/${id}`, query);
    }

    static updateReading(body) {
        return request.put('/readings/update', { }, body);
    }

    static deleteReading(id) {
        return request.delete(`/readings/delete/id/${id}`);
    }

    static async addInfrastructure(body) {
        return request.post('/infrastructure', {}, body);
    }

    static async addIngredientsGlobally(body) {
        return request.post('/infrastructure/ingredients', {}, body);
    }

    static async setNormRatesGlobally(body) {
        return request.put('/infrastructure/bulkNormRates', {}, body);
    }

    static async getDictionaryCategories() {
        return getSearchResult('/dictionaries/categories', {});
    }

    static async getDictionaryValues(category, subcategory) {
        let url;
        if (subcategory) url = `/dictionaries/${category}/values/${subcategory}`;
        else url = `/dictionaries/${category}/values`;
        return getSearchResult(url, {});
    }

    static async updateDictionary(data) {
        return request.put('/dictionaries/update/', {}, data);
    }

    static getInfrastructureData(id) {
        return request.get(`/infrastructure/editData/${id}`);
    }

    static getInfrastructuresTypes(useSWR) {
        return request.swr(useSWR, '/infrastructure/types/');
    }

    static getInfrastructuresFromType(useSWR, typeId) {
        return request.swr(useSWR, `/infrastructure/parents/${typeId}`);
    }

    static updateInfrastructure(id, body) {
        return request.put(`/infrastructure/editData/${id}`, { }, body);
    }

    static getInfrastructuresProperties(useSWR) {
        return request.swr(useSWR, '/infrastructure/properties/');
    }

    static getInfrastructuresModes(useSWR) {
        return request.swr(useSWR, '/infrastructure/modes/');
    }

    static getCounterTypes(useSWR) {
        return request.swr(useSWR, '/infrastructure/counterTypes/');
    }

    static getMeasureUnits(useSWR) {
        return request.swr(useSWR, '/infrastructure/measureUnits/');
    }

    static getEntities(useSWR, searchPhrase, initialValue) {
        return request.swr(useSWR, '/list/entities/name', { searchPhrase, initialValue });
    }

    static getTallyTypes(useSWR, searchPhrase) {
        return request.swr(useSWR, '/list/tally-types', { searchPhrase });
    }

    static getTallySections(useSWR, searchPhrase, level) {
        return request.swr(useSWR, '/list/tally-sections', { searchPhrase, level });
    }

    static getTransactionTypes(useSWR, searchPhrase) {
        return request.swr(useSWR, '/list/transaction-types', { searchPhrase });
    }

    static async downloadFile(id, filename, preventDownload = false, getter = (res) => res.blob()) {
        const { data, error } = await request.get(`/file/download/${id}`, {}, getter);
        if (!preventDownload) {
            const downloadUrl = window.URL.createObjectURL(data);
            const anchor = document.createElement('a');
            anchor.href = downloadUrl;
            anchor.download = filename;
            anchor.click();
        }
        return { data, error };
    }

    static getPayoffs(useSWR, query) {
        return request.swr(useSWR, '/payoffs/media/', query);
    }

    static getMediumTypes() {
        return request.get('/list/medium-types');
    }

    static getTaskFiles(taskId) {
        return request.get('/task/files', { id: taskId }, true);
    }

    static getContractorsAllData(useSWR, filter, page, pageSize) {
        return request.swr(useSWR, '/contractor/getContractorData', { filter, page, pageSize });
    }

    static getContractorsData(filter, page, pageSize) {
        return request.get('/contractor/getContractorData', { filter, page, pageSize });
    }

    static addContractor(body) {
        return request.post('/contractor/add', { }, body);
    }

    static getLoggedInhabitantData() {
        return request.get('/contractor/getLoggedInhabitantData');
    }

    static getLoggedInfrastructureData() {
        return request.get('/infrastructure/userInfrastructure');
    }

    static async generatePayoffs(data, counterType, dates, communities) {
        return request.post('/payoffs/generate', {
            counterType: counterType && counterType.key,
            readingDateFrom: dates.readingDateFrom,
            readingDateTo: dates.readingDateTo,
            burdenDateFrom: dates.burdenDateFrom,
            burdenDateTo: dates.burdenDateTo,
            communities
        }, {
            data
        });
    }

    static async getIncomeData(communities, dateOfIncome, documentType, remarks) {
        log.info('getIncomeData communities', communities);
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/income/generate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                communities, dateOfIncome, documentType, remarks
            })
        });
        log.info('getInvoiceData response', response);
        return response.json();
    }

    static async addIncomeDirectly(communities, dateOfIncome, remarks, documentType) {
        log.info('addIncomeDirectly communities', communities);
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/income/addDirectly`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                communities, dateOfIncome, remarks, documentType
            })
        });
        log.info('addIncomeDirectly response', response);
        return response.json();
    }

    static async addIncome(data, forceAdd = false) {
        log.info('addIncome data', data, forceAdd);
        const query = objToQueryString({
            force: forceAdd
        });
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/income/addIncome?${query}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ data })
        });
        log.info('addIncome response', response);

        return response.json();
    }

    static async getIncomeFileView(data, signal) {
        log.info('getIncomeFileView data', data);
        const token = getToken();
        const response = await fetch(`${config.backendHost}:${config.backendPort}/income/getFileView`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ data }),
            signal
        });
        log.info('getIncomeFileView response', response);

        return response.blob();
    }

    static async generateFile(id) {
        log.info('generatefile data', id);
        return request.get(`/income/generateIncomeFile/${id}`, {}, (res) => res.blob());
    }

    static async generateManyIncomeFiles(ids) {
        log.info('generateManyIncomeFiles data', ids);
        return request.post('/income/generateIncomeFiles', {}, { ids }, null, (res) => res.blob());
    }

    static getSettlements(useSWR, filter, sortModel, page, pageSize, disable) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            page,
            pageSize,
            sortModel: JSON.stringify(sortModel[0])
        };
        return request.swr(useSWR, disable ? null : '/settlements', query);
    }

    static getSettlementsSums(useSWR, filter, disable) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter))
        };
        return request.swr(useSWR, disable ? null : '/settlements/sums', query);
    }

    static getAllSettlements(filter, page = 0, pageSize = requestsMaxLimitValue) {
        const query = {
            filter: JSON.stringify(RestRequestsHelper._formatFilter(filter)),
            page,
            pageSize
        };
        return request.get('/settlements', query);
    }

    static addTransaction(data) {
        return request.post('/transaction/add', {}, data);
    }

    static getCorrespondenceThreads() {
        return request.get('/correspondence/threads');
    }

    static addCorrespondenceThreads(data) {
        return request.post('/correspondence/threads', {}, data);
    }

    static updateCorrespondence(id, data, files) {
        return request.put(`/correspondence/${id}`, {}, data, files);
    }

    static deleteCorrespondence(id) {
        return request.delete(`/correspondence/${id}`);
    }

    static getCorresponenceById(id) {
        return request.get(`/correspondence/id/${id}`);
    }

    static generateNotifications(data) {
        return request.post('/generators/generateNotifications', {}, data, null, (res) => res.json());
    }

    static generateCorrespondenceList(data) {
        return request.post('/generators/generateCorrespondenceList', {}, data, null, (res) => res.json());
    }

    static getPayoffsTally(useSWR, filter) {
        return request.swr(useSWR, '/payoffs/tally', { filter });
    }

    static getPayoffsTallyPDF(filter) {
        return request.get('/payoffs/tally/pdf', { filter }, (res) => res.blob());
    }

    static getMediumCombination(useSWR, searchPhrase) {
        return request.swr(useSWR, '/list/combination', { searchPhrase });
    }

    static getMediumAlgorithm(useSWR, searchPhrase) {
        return request.swr(useSWR, '/list/payoffMediumAlgorithms', { searchPhrase });
    }

    static getTypeRate(useSWR, searchPhrase) {
        return request.swr(useSWR, '/list/typeRate', { searchPhrase });
    }

    static getCountedIngredient(useSWR, searchPhrase) {
        return request.swr(useSWR, '/list/countedIngredient', { searchPhrase });
    }

    // kind = 'income' | 'invoice' | null (all)
    static getDocumentTypes(useSWR, searchPhrase, kind) {
        return request.swr(useSWR, '/list/document/types', { searchPhrase, kind });
    }

    static getDocumentKinds(useSWR, searchPhrase) {
        return request.swr(useSWR, '/list/document/kinds', { searchPhrase });
    }
}
