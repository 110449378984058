import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import Add from '@mui/icons-material/Add';

const useStyle = makeStyles(() => ({
    center: {
        display: 'inline-block',
        position: 'relative',
        top: '55%',
        transform: 'translate(0, -50%)',
        minWidth: '85%'
    }
}));

const getValueFromEvent = (eventOrValue) => {
    if (eventOrValue && 'target' in eventOrValue) {
        // Checkbox input type and Radio input type
        // have a `checked` property instead of `value`
        if (eventOrValue.target.type === 'checkbox') {
            return eventOrValue.target.checked;
        }
        return eventOrValue.target.value;
    }
    return eventOrValue;
};

function Buttons({ expandItems }) {
    return (
        <Grid item xs={12} sm={12}>
            <IconButton color='primary' component='span' onClick={expandItems} size='large'>
                <Add />
            </IconButton>
        </Grid>
    );
}

Buttons.propTypes = {
    expandItems: PropTypes.func.isRequired
};

const ListItems = forwardRef(({
    value, onChange, onBlur, onItemAdd, onItemRemove,
    onSelectionChange, onNewItem, render, hasRadio,
    atLeastOne, canBeRemoved, buttonsPosition, sortFn, hideActions
}, ref) => {
    const [selected, setSelected] = useState(0);
    const initialState = () => (atLeastOne ? [onNewItem(1)] : []);
    const [items, setItems] = useState((value && value.length) ? value : initialState());
    const classes = useStyle();

    useEffect(() => {
        if (value && value.length) {
            setItems(value);
        } else if (atLeastOne) {
            onChange(initialState());
        } else {
            setItems([]);
        }
    }, [value]);

    const updateSelection = (index) => () => {
        setSelected(index);
        onSelectionChange(index);
    };

    const removeItem = (index) => () => {
        setItems((oldItems) => {
            const newItems = [...oldItems];
            newItems.splice(index, 1);
            onChange(newItems);
            onItemRemove(newItems, index);
            const max = newItems.length;
            if (selected >= max) {
                setSelected(max - 1);
                onSelectionChange(max - 1);
            }
            return newItems;
        });
    };

    const expandItems = () => {
        setItems((oldItems) => {
            const newItems = [...oldItems];
            newItems.unshift(onNewItem(newItems.length + 1));
            onChange(newItems);
            onItemAdd(newItems, newItems.length - 1);
            return newItems;
        });
    };

    const handleOnChange = (index, name) => (event) => {
        const val = getValueFromEvent(event);
        setItems((oldItems) => {
            const newItems = [...oldItems];
            newItems[index][name] = val;
            onChange(newItems);
            return newItems;
        });
    };

    const renderProp = (index) => (name) => ({
        onChange: handleOnChange(index, name),
        value: items[index][name],
        onBlur
    });

    // Do not sort if sortFn is not provided
    const sorted = sortFn ? items.sort(sortFn) : items;

    const getItemsGridWidth = () => {
        let width = 12;
        if (!hideActions) {
            width -= 1;
        }
        if (hasRadio) {
            width -= 1;
        }
        return width;
    };

    return (
        <div ref={ref}>
            <Grid container>
                {(!hideActions && buttonsPosition === 'top') && (
                    <Buttons expandItems={expandItems} />
                )}
                {sorted.map((item, index) => {
                    if (items.length || (atLeastOne && items.length > 1)) {
                        return (
                            <>
                                {hasRadio ? (
                                    <Grid item xs={1}>
                                        <Radio
                                            className={classes.center}
                                            checked={selected === index}
                                            name='setNameCheckbox'
                                            value={index}
                                            onChange={updateSelection(index)}
                                            color='secondary'
                                        />
                                    </Grid>
                                ) : null}
                                <Grid item xs={getItemsGridWidth()}>
                                    {/* <Grid item xs={12}> */}
                                    {render(renderProp(index), index, items[index])}
                                </Grid>
                                {
                                    !hideActions && (
                                        <Grid item xs={1}>
                                            <IconButton
                                                disabled={!canBeRemoved(item, index)}
                                                className={classes.center}
                                                onClick={removeItem(index)}
                                                size='large'
                                            >
                                                <RemoveIcon fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    )
                                }
                            </>
                        );
                    }
                    return (
                        <Grid item xs={12} sm={12}>
                            {render(renderProp(index))}
                        </Grid>
                    );
                })}
                {(!hideActions && buttonsPosition === 'bottom') && (
                    <Buttons expandItems={expandItems} />
                )}
            </Grid>
        </div>
    );
});

ListItems.propTypes = {
    render: PropTypes.func.isRequired,
    value: PropTypes.array,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onItemAdd: PropTypes.func,
    onItemRemove: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onNewItem: PropTypes.func,
    hasRadio: PropTypes.bool,
    atLeastOne: PropTypes.bool,
    canBeRemoved: PropTypes.func,
    buttonsPosition: PropTypes.oneOf(['top', 'bottom']),
    sortFn: PropTypes.func,
    hideActions: PropTypes.bool
};

ListItems.defaultProps = {
    value: [],
    onBlur: () => {},
    onChange: () => {},
    onItemAdd: () => {},
    onItemRemove: () => {},
    onSelectionChange: () => {},
    onNewItem: () => ({}),
    hasRadio: false,
    atLeastOne: false,
    canBeRemoved: () => true,
    buttonsPosition: 'top',
    sortFn: null,
    hideActions: false
};

export default ListItems;
