const defaultFilter = () => ({
    concerns: [],
    name: '',
    type: [],
    validFrom: null,
    validTo: null,
    producer: null,
    author: [],
    leader: []
});

export default defaultFilter;
