import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { userSignedIn } from '../actions';

const infoTitleStyle = [
    'color: red',
    'font-size: 60px',
    'text-shadow: 2px 2px black',
    'padding: 10px'
].join(';');

const infoDescriptionStyle = [
    'font-size: 18px',
    'text-shadow: 2px 2px black',
    'padding-bottom: 5px',
    'margin-bottom: 10px'
].join(';');

const title = '%cStop!';
const description = [
    '%cThis is a browser feature intended for developers.',
    'Don\'t paste anything here!'
].join('\n');

const useShowConsoleInfo = () => {
    const dispatch = useDispatch();
    return () => {
        // eslint-disable-next-line no-console
        console.log(title, infoTitleStyle);
        // eslint-disable-next-line no-console
        console.log(description, infoDescriptionStyle);
        if (localStorage.getItem('token')) {
            try {
                const data = jwtDecode(localStorage.getItem('token'));
                dispatch(userSignedIn(data));
            } catch {
                localStorage.removeItem('token');
            }
        }
    };
};

export default useShowConsoleInfo;
