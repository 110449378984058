import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import React, {
    useEffect, useReducer, useRef, useState
} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import FormPickerMaster from '../../../../common/FormPicker/FormPickerMaster';
import { HotkeyProvider } from '../../../../common/Hotkeys/Hotkeys';
import { getToken } from '../../../../../lib/restRequestsHelper';
import config from '../../../../../config';
import InvoiceForm from '../../../../common/InvoiceForm/InvoiceForm';
import defaultInvoice from '../../../../common/InvoiceForm/InvoiceDefault';
import invoiceReducer from '../../../../common/InvoiceForm/reducers';
import defaultInvoiceTypeValue from '../../../../../constants/invoiceTypes';
import FormFileManager, { prepareFilesForUpload, useFormFilesState } from '../../../../common/FormFileManager/FormFileManager';

const messages = {
    updatedSuccessfully: { id: 'app.invoiceUpdate.success' }
};
const fetchInvoices = async (data) => {
    const token = getToken();
    if (data.queryKey[1]) {
        const url = `${config.backendHost}:${config.backendPort}/invoice/retrieve/${data.queryKey[1]}`;
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return res.json();
    }
    return {};
};

const updateInvoice = async ({
    id, data, files
}) => {
    const token = getToken();
    const url = `${config.backendHost}:${config.backendPort}/invoice/${id}`;

    const formData = new FormData();
    files.forEach((file) => {
        formData.append('file[]', file || '');
    });
    formData.append('data', JSON.stringify(data || {}));

    const res = await fetch(url, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: formData
    });
    return res.json();
};

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: '90vw',
        height: '100%',
        overflow: 'hidden'
    },
    drawerData: {
        padding: 32,
        textAlign: 'center'
    },
    preview: {
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    maxSize: {
        height: '100vh'
    }
}));

function SidePanel({
    onClose,
    onUpdate,
    mutate
}) {
    const classes = useStyles();

    const id = useSelector((state) => state.invoice);

    const [invoiceType, setInvoiceType] = useState(defaultInvoiceTypeValue);
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const { data, status } = useQuery(['invoices', id], fetchInvoices);
    const queryClient = useQueryClient();

    const fileManagerRef = useRef();

    const {
        setFilesVal, getFilesVal, filesField, setFilesField
    } = useFormFilesState();

    const handleOnClose = () => {
        fileManagerRef.current.flushFiles();
        setFilesVal('files', []);
        setFilesField([]);
        onClose();
    };

    const { mutate: mutateSave } = useMutation(updateInvoice, {
        onSuccess: () => {
            handleOnClose();
            enqueueSnackbar(intl.formatMessage({ id: messages.updatedSuccessfully.id }), { variant: 'success' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('update');
            onUpdate();
        }
    });

    useEffect(() => {
        if (data?.data) {
            setInvoiceType(data.data.type);
        }
    }, [data]);

    const [invoiceState, invoiceDispatch] = useReducer(
        invoiceReducer,
        defaultInvoice()
    );

    useEffect(() => {
        if (status === 'success') {
            invoiceDispatch({ value: data.data });
            if (data?.data?.files) {
                setFilesVal('files', data.data.files);
                setFilesField(['files']);
            }
        }
    }, [status, data]);

    const handleFormChange = (changedData) => {
        invoiceDispatch({ value: changedData });
    };
    const handleSubmitInvoiceForm = (event) => {
        event.preventDefault();
        const filesData = prepareFilesForUpload(getFilesVal('files'));
        const submitData = {
            ...invoiceState,
            buyer: invoiceState.buyer,
            seller: invoiceState.seller,
            flowStatus: invoiceState.flowStatus.id,
            type: invoiceType.id,
            filesMetadata: filesData.metadata
        };

        mutateSave({
            id, data: submitData, files: filesData.files
        });
    };
    function HotkeysController() {
        return null;
    }
    return (
        <Drawer anchor='right' open={id} onClose={handleOnClose}>
            <Paper className={classes.drawerPaper}>
                <HotkeyProvider id='invoiceIndex'>
                    <HotkeysController />
                    <Grid className={classes.drawerData} container spacing={2}>
                        <Grid item xs={6}>
                            <FormPickerMaster enable>
                                <InvoiceForm
                                    className={classes.maxSize}
                                    isLoading={status !== 'success'}
                                    onSubmit={handleSubmitInvoiceForm}
                                    onClose={() => {
                                        onClose();
                                        mutate();
                                    }}
                                    invoiceFormData={{ ...invoiceState, _id: id }}
                                    invoiceType={invoiceType}
                                    onChange={handleFormChange}
                                    handleInvoiceType={setInvoiceType}
                                    showSetNamesInput
                                    isManualInvoice
                                    saveButton
                                    flowStatus
                                />
                            </FormPickerMaster>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.preview}>
                                <FormFileManager
                                    setValue={setFilesVal}
                                    getValues={getFilesVal}
                                    setCurrentField={setFilesField}
                                    currentField={filesField}
                                    ref={fileManagerRef}
                                    dataRealoading
                                />

                            </div>
                        </Grid>
                    </Grid>
                </HotkeyProvider>
            </Paper>
        </Drawer>
    );
}

SidePanel.propTypes = {
    onClose: PropTypes.func,
    onUpdate: PropTypes.func,
    mutate: PropTypes.func
};

SidePanel.defaultProps = {
    onClose: () => {},
    onUpdate: () => {},
    mutate: () => {}
};
export default SidePanel;
