import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import useSWR from 'swr';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import formatMoney from '../../../../lib/formatMoney';
import useColumns from './shared/hooks/useColumns';
import PayoffsTallyFilter, { defaultFilter } from './shared/ui/PayoffsTallyFilter';
import DataGridMessage from './shared/ui/DataGridMessage';
import DataGridToolbar from './shared/ui/DataGridToolbar';

const messages = {
    position: { id: 'app.payoffs.tally.position' },
    transaction: { id: 'app.payoffs.tally.transaction' },
    cost: { id: 'app.payoffs.tally.cost' },
    income: { id: 'app.payoffs.tally.income' }
};

const useStyles = makeStyles((theme) => ({
    main: ({ isProductionDb }) => ({
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: isProductionDb ? 'calc(100vh - 148px)' : 'calc(100vh - 212px)'
    }),
    bodyRow: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200]
    },
    detailRow: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300],
        '&:hover': {
            backgroundColor: [theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200], '!important'].join(' ')
        }
    },
    zeroSumRow: {
        opacity: 0.5
    }
}));

const usePayoffsTally = (filter) => {
    const { data, error, mutate } = RestRequestsHelper.getPayoffsTally(useSWR, JSON.stringify(filter));
    return {
        tally: data !== undefined ? data : [],
        loading: !error && !data,
        mutate
    };
};

export default function PayoffsTally() {
    const [filter, setFilter] = useState(defaultFilter);
    const { tally, loading } = usePayoffsTally(filter);
    const [hideZeroValues, setHideZeroValues] = useState(true);
    const [detailsVisibility, setDetailsVisibility] = useState(new Map());
    const isProductionDb = useSelector((state) => state.dbInfo.production);
    const classes = useStyles({ isProductionDb });
    const columns = useColumns(filter);
    const intl = useIntl();

    const handleCellClick = ({ row }) => {
        if (row.kind === 'row') {
            setDetailsVisibility((prevState) => {
                const value = prevState.get(row.id);
                return new Map(prevState.set(row.id, !value));
            });
        }
    };

    const getKindIntlName = (kindId) => {
        switch (kindId) {
            case 1:
                return intl.formatMessage(messages.cost);
            case 2:
                return intl.formatMessage(messages.income);
            default:
                return null;
        }
    };

    const convertTallyToRows = (tallyData, isHideZeros) => {
        const rows = [];
        tallyData.forEach((section) => {
            const sect = {
                kind: 'section',
                isHeader: true,
                name: `${section.order.join('.')}. ${section.name}`,
                sum: formatMoney(section.sum),
                sum_position: section.sum_position,
                id: section.name
            };
            if (sect.sum_position === 'top') rows.push(sect);
            section.tally.forEach((row) => {
                if (isHideZeros && row.sum === 0) return;
                const id = row.id + section.name;
                rows.push({
                    ...row,
                    kind: 'row',
                    details: row.details,
                    sum: formatMoney(row.sum),
                    id
                });
                if (detailsVisibility.get(id)) {
                    row.details.forEach((detail, index) => {
                        const typeName = intl.formatMessage(messages[detail.type]);
                        const documentKind = getKindIntlName(detail.documentKind);
                        const documentType = detail.documentType?.value ?? '';
                        rows.push({
                            kind: 'detail',
                            entity: detail.entity,
                            type: detail.type,
                            documentKind: detail.documentKind,
                            documentType: detail.documentType,
                            detailType: detail.detailType,
                            name: [[typeName, documentKind, documentType].filter(Boolean).join(' - '), detail.title].join(': '),
                            sum: formatMoney(detail.value),
                            value: detail.value,
                            id: id + index
                        });
                    });
                }
            });
            if (sect.sum_position === 'bottom') rows.push(sect);
        });
        return rows;
    };

    return (
        <div className={classes.main}>
            <PayoffsTallyFilter filter={filter} setFilter={setFilter} />
            <DataGrid
                columns={columns}
                rows={convertTallyToRows(tally, hideZeroValues)}
                onCellClick={handleCellClick}
                loading={loading}
                disableSelectionOnClick
                getRowClassName={(params) => ([
                    (() => {
                        switch (params.row.kind) {
                            case 'section':
                                return '';
                            case 'row':
                                return classes.bodyRow;
                            case 'detail':
                                return classes.detailRow;
                            default:
                                return '';
                        }
                    })(),
                    params.row.sum || params.row.isHeader ? '' : classes.zeroSumRow
                ].join(' '))}
                disableColumnFilter
                disableColumnMenu
                slots={{
                    toolbar: DataGridToolbar,
                    ...(!filter.type ? {
                        noRowsOverlay: DataGridMessage
                    } : {})
                }}
                slotProps={{
                    toolbar: {
                        hideZeroValues,
                        setHideZeroValues,
                        filter
                    }
                }}
            />
        </div>
    );
}

