import { isPlainObject, merge } from 'lodash';
import backendInfo from '../config/index';

class Requests {
    constructor(config) {
        this.config = config;
    }

    static getToken() {
        return localStorage.getItem('token');
    }

    getConfig() {
        return {
            headers: {
                Authorization: `Bearer ${Requests.getToken()}`,
                ...this.config
            }
        };
    }

    static objToQueryString(obj) {
        const keyValuePairs = [];
        Object.keys(obj).forEach((key) => {
            if (obj[key] || obj[key] === 0) {
                if (typeof key === 'object') {
                    throw Error('Query object will be URI-Encoded shallowly. Please remove nested objects');
                }
                keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
            }
        });
        return keyValuePairs.join('&');
    }

    static createAbsolutePath(path) {
        const { backendHost, backendPort } = backendInfo;
        return `${backendHost}:${backendPort}${`/${path}`.replace(/\/+/g, '/')}`;
    }

    static createURL(url, query) {
        const newURL = Requests.createAbsolutePath(url);
        const newQuery = Requests.objToQueryString(query);
        if (newQuery.length) {
            return `${newURL}?${newQuery}`;
        }
        return newURL;
    }

    static swrFetcher(config, query) {
        return (path) => {
            const url = Requests.createURL(path, query);
            return fetch(url, config).then((res) => res.json());
        };
    }

    static async defaultFetcher([...args], getter = (res) => res.json()) {
        const response = await fetch(...args);
        return response.ok
            ? { data: await getter(response) }
            : { error: response };
    }

    static generateBody(body, files) {
        if (files) {
            const formData = new FormData();
            Object.entries(body).forEach(([key, value]) => formData.append(key, JSON.stringify(value || null)));
            if (isPlainObject(files)) {
                Object.keys(files).forEach((key) => {
                    files[key].forEach((file) => formData.append(`files.${key}`, file));
                });
            } else {
                files.forEach((file) => formData.append('file[]', file));
            }

            // preparedGalleryData.filesToAdd.forEach((file) => formData.append('files.gallery', file));
            return { body: formData };
        }
        return {
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        };
    }

    /**
     * Fetch data creating GET request
     * @param {function} SWRHook - SWR Hook (useSWR)
     * @param {string} url - URL of the endpoint
     * @param {object} query - Object query in a form of JS object
     * @returns SWR data bundle
     */
    swr(SWRHook, url, query = {}) {
        const config = { method: 'GET', ...this.getConfig() };
        return SWRHook(url && [url, ...Object.values(query)], Requests.swrFetcher(config, query));
    }

    /**
     * Fetch data creating GET request
     * @param {string} url - URL of the endpoint
     * @param {object} query - Object query in a form of JS object
     * @param {function(object):object} getter - Function to transform response
     * @returns Get data bundle
     */
    get(url, query = {}, getter = (res) => res.json()) {
        const path = Requests.createURL(url, query);
        const config = { method: 'GET', ...this.getConfig() };
        return Requests.defaultFetcher([path, config], getter);
    }

    /**
     * Send data creating POST request
     * @param {string} url - URL of the endpoint
     * @param {object} query - Object query in a form of JS object
     * @param {object} body - JS object to be transfromed to JSON and used as body
     * @returns Get data bundle
     */
    post(url, query = {}, body = {}, files = null, getter = (res) => res.json()) {
        const path = Requests.createURL(url, query);
        const newBody = Requests.generateBody(body, files);
        const config = merge({ method: 'POST' }, newBody, this.getConfig());
        return Requests.defaultFetcher([path, config], getter);
    }

    /**
     * Update data creating PUT request
     * @param {string} url - URL of the endpoint
     * @param {object} query - Object query in a form of JS object
     * @param {object} body - JS object to be transfromed to JSON and used as body
     * @returns Get data bundle
     */
    put(url, query = {}, body = {}, files = null) {
        const path = Requests.createURL(url, query);
        const newBody = Requests.generateBody(body, files);
        const config = merge({ method: 'PUT' }, newBody, this.getConfig());
        return Requests.defaultFetcher([path, config]);
    }

    /**
     * Remove data creating DELETE request
     * @param {string} url - URL of the endpoint
     * @param {object} query - Object query in a form of JS object
     * @returns Get data bundle
     */
    delete(url, query = {}) {
        const path = Requests.createURL(url, query);
        const config = { method: 'DELETE', ...this.getConfig() };
        return Requests.defaultFetcher([path, config]);
    }
}

export default Requests;
