import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../../../lib/displaySnackbar';
import EditBankStatementsDialog from '../../../../../common/BankStatementsForm/ui/EditBankStatementDialog';

const messages = {
    editedTransaction: { id: 'app.bankStatement.editedTransaction' },
    errorUpdate: { id: 'app.bankStatement.errorUpdate' },
    deleteSuccess: { id: 'app.massRemovalToolbar.massDeleteSuccess' }
};

function TransactionsPanel({
    data, onClose, onUpdate
}) {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ enqueueSnackbar, intl });

    const handleSubmit = async (transaction) => {
        const { data: res, error } = await RestRequestsHelper.updateBankStatement(transaction);
        if (error) {
            displaySnackbar('error', messages.errorUpdate);
            return false;
        }
        onClose();
        if (res) {
            displaySnackbar('success', messages.editedTransaction);
            onUpdate();
        }
        return true;
    };
    const handleDeleteRecord = async () => {
        const { data: res } = await RestRequestsHelper.deleteTransaction(data?._id || data?.id);
        if (res?.success) {
            displaySnackbar('success', messages.deleteSuccess);
        }
        onClose();
        onUpdate();
    };
    return (
        <EditBankStatementsDialog
            onSubmit={handleSubmit}
            open={data}
            data={data}
            type={0}
            onClose={onClose}
            allowEditingEntity
            onDelete={handleDeleteRecord}
        />
    );
}

TransactionsPanel.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func
};

TransactionsPanel.defaultProps = {
    data: null,
    onUpdate: () => {}
};

export default TransactionsPanel;
