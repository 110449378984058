import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import ConfirmDialog from '../../../common/ConfirmDialog/ConfirmDialog';
import InfrastructureForm from './InfrastructureForm/InfrastructureForm';

const messages = {
    editInfrastructure: 'app.infrastructures.edit',
    name: 'app.infrastructures.objectName',
    success: 'app.infrastructures.successEdit',
    removal: 'app.infrastructures.removal',
    removalConfirmation: 'app.infrastructures.removalConfirmation',
    deleteError: 'app.infrastructures.deleteError',
    deleteSuccess: 'app.infrastructures.deleteSuccess'

};
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    drawer: {
        width: '55vw',
        display: 'flex'
    },
    drawerData: {
        width: '100%',
        padding: 0
    },
    circularBox: {
        width: '55vw',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

function EditInfrastructure({
    open, onClose, infrastructureId, readOnly, drawerHeader
}) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [infrastructureData, setInfrastructureData] = useState({});

    const [isDeletingDialog, setIsDeletingDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();

    useEffect(() => {
        const getData = async () => {
            if (infrastructureId !== undefined && infrastructureId !== null && infrastructureId.length > 0) {
                setLoading(true);
                const { data } = await RestRequestsHelper.getInfrastructureData(infrastructureId);
                setInfrastructureData(data);
                setLoading(false);
            }
        };
        getData();
    }, [infrastructureId]);
    const handleClose = () => {
        onClose();
        setLoading(true);
    };

    const handleSubmit = async (data) => {
        const result = await RestRequestsHelper.updateInfrastructure(infrastructureId, data);
        if (result) {
            enqueueSnackbar(
                intl.formatMessage({ id: messages.success }),
                { variant: 'success', autoHideDuration: 20 * 1000 }
            );
        }
        handleClose();
    };

    const handleOpenDeletingDialog = () => {
        setIsDeletingDialog(true);
    };
    const handleDelete = async () => {
        setIsDeleting(true);
        const { error, data } = await RestRequestsHelper.deleteInfrastructure(infrastructureId);
        if (error || data.status === 'error') {
            enqueueSnackbar(
                intl.formatMessage({ id: messages.deleteError }),
                { variant: 'success', autoHideDuration: 20 * 1000 }
            );
            setIsDeleting(false);
            return;
        }
        setIsDeleting(false);
        enqueueSnackbar(
            intl.formatMessage({ id: messages.deleteSuccess }),
            { variant: 'success', autoHideDuration: 20 * 1000 }
        );
        setIsDeletingDialog(false);
        handleClose();
    };

    return (
        <div>
            <ConfirmDialog
                isOpen={isDeletingDialog}
                handleClose={() => setIsDeletingDialog(false)}
                handleRemove={handleDelete}
                dialogTitle={messages.removal}
                dialogText={messages.removalConfirmation}
                isLoading={isDeleting}
            />
            <Drawer
                anchor='right'
                open={open}
                onClose={handleClose}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            color='inherit'
                            onClick={handleClose}
                            aria-label='close'
                            size='large'
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' className={classes.title}>
                            {
                                drawerHeader || <FormattedMessage id={messages.editInfrastructure} />
                            }
                        </Typography>
                        <IconButton color='inherit'>
                            <DeleteIcon onClick={handleOpenDeletingDialog} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {!loading ? (
                    <Paper className={classes.drawer}>
                        <Grid container className={classes.drawerData}>
                            <Grid container item xs={12}>
                                <InfrastructureForm readOnly={readOnly} onSubmit={handleSubmit} data={infrastructureData} />
                            </Grid>
                        </Grid>
                    </Paper>
                ) : (
                    <Box className={classes.circularBox}>
                        <CircularProgress />
                    </Box>
                )}
            </Drawer>
        </div>
    );
}
EditInfrastructure.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    infrastructureId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    drawerHeader: PropTypes.string
};

EditInfrastructure.defaultProps = {
    readOnly: false,
    drawerHeader: ''
};

export default EditInfrastructure;
