import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Chip from '@mui/material/Chip';
import routeTitles from '../../../../config/routeTitles';
import prepareRouteTitleChildren from '../../../../lib/prepareRouteTitleChildren';

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5)
        }
    }
}));
function ShortAppMenu() {
    const [pathAndTranslation, setPathAndTranslation] = useState([]);
    const history = useHistory();
    const classes = useStyles();
    const intl = useIntl();
    const user = useSelector((state) => state.user);
    useEffect(() => {
        const getPaths = (cfg, path = [], elements = []) => {
            cfg.forEach((item) => {
                const isRole = !item.roles || item.roles.includes(user.role);
                if (item.path && !item.ignoreInMenu && !prepareRouteTitleChildren(item.children) && isRole) {
                    elements.push({
                        href: item.path,
                        path: [...path, item.translation]
                    });
                    return;
                }

                if (item.children && isRole) {
                    getPaths(item.children, [...path, item.translation], elements);
                }
            });
            return elements;
        };
        setPathAndTranslation(getPaths(routeTitles));
    }, []);

    const createChip = () => (pathAndTranslation.map((item, index) => (
        <Chip
            key={index}
            label={item.path.map((el) => intl.formatMessage({ id: el })).join(' -> ')}
            clickable
            onClick={() => { history.push(item.href); }}
        />
    ))
    );
    return (
        <div className={classes.root}>
            {createChip()}
        </div>
    );
}

export default ShortAppMenu;
