import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import EditBankStatementsDialog, { editTypes } from '../../../../common/BankStatementsForm/ui/EditBankStatementDialog';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../../lib/displaySnackbar';

const defaultData = {
    type: {
        key: 1,
        value: 'Transakcja',
        name: 'Transakcja',
        $name: 'Transakcja'
    }
};

const messages = {
    success: { id: 'app.transactions.added' },
    error: { id: 'app.transactions.error' }
};

export default function AddTransaction({ open, onClose, mutate }) {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ enqueueSnackbar, intl });

    const handleSubmit = async (value) => {
        const result = await RestRequestsHelper.addTransaction(value);
        if (result.error) {
            displaySnackbar('error', messages.error);
        } else {
            displaySnackbar('success', messages.success);
            mutate();
            onClose();
        }
    };

    return (
        <EditBankStatementsDialog
            open={open}
            data={defaultData}
            onClose={onClose}
            onSubmit={handleSubmit}
            allowEditingEntity
            type={editTypes.add}
        />
    );
}

AddTransaction.defaultProps = {
    open: false,
    onClose: () => { },
    mutate: () => { }
};

AddTransaction.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    mutate: PropTypes.func
};
