import React from 'react';
import { useIntl } from 'react-intl';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles(() => ({
    root: {
        margin: 5
    }
}));
function EnvironmentInformation() {
    const classes = useStyles();
    const intl = useIntl();

    const getEnviromentProperties = () => {
        switch (window.location.hostname) {
            case '10.8.0.200':
                return {
                    color: 'success',
                    label: 'PROD',
                    messageId: 'app.header.enviroment.prod'
                };
            case '10.8.0.203':
                return {
                    color: 'warning',
                    label: 'STAGING',
                    messageId: 'app.header.enviroment.staging'
                };
            case 'localhost':
                return {
                    color: 'info',
                    label: 'LOCAL',
                    messageId: 'app.header.enviroment.local'
                };
            default:
                return {
                    color: 'warning',
                    label: 'OTHER',
                    messageId: 'app.header.enviroment.other'
                };
        }
    };
    const properties = getEnviromentProperties();
    return (
        <div className={classes.root}>
            <Tooltip title={intl.formatMessage({ id: properties.messageId })} disableInteractive>
                <Chip label={properties.label} color={properties.color} />
            </Tooltip>
        </div>
    );
}
export default EnvironmentInformation;
