import React from 'react';
import { node, any } from 'prop-types';

function TabPanel({
    children, value, index
}) {
    return (
        <div hidden={value !== index}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: node.isRequired,
    index: any.isRequired,
    value: any.isRequired
};

export default TabPanel;
