import COMBOBOX_INFO from './comboboxInfo';

const argsToQueryObj = (argsKeys, argsValues) => {
    const queryObj = {};
    argsKeys.forEach((key, index) => {
        queryObj[key] = argsValues[index];
    });
    return queryObj;
};

const sourceDescriptor = ({ url, info, args }) => (
    (...queryArgs) => ({ url, query: argsToQueryObj(args ?? [], queryArgs), info })
);

const COMBOBOX_TYPES = {
    correspondenceContractors: sourceDescriptor({
        url: '/correspondence/journal-list'
    }),
    entitiesOfCommunityByName: sourceDescriptor({
        url: '/list/entities/community/name',
        args: ['communities'],
        info: COMBOBOX_INFO.contractors
    }),
    entitiesByPhoneNumbers: sourceDescriptor({
        url: '/list/entities/phone',
        args: ['entityID'],
        info: COMBOBOX_INFO.contractors
    }),
    entitiesApplicantData: sourceDescriptor({
        url: '/list/entities/applicant-data',
        info: COMBOBOX_INFO.contractors
    }),
    entitiesByName: sourceDescriptor({
        url: '/list/entities/name',
        info: COMBOBOX_INFO.contractors
    }),
    entitiesContactsByName: sourceDescriptor({
        url: '/list/entities/contacts/name',
        info: COMBOBOX_INFO.contractors
    }),
    entitiesAddressTypes: sourceDescriptor({
        url: '/list/entities/address-types'
    }),
    documentsForTransaction: sourceDescriptor({
        url: '/list/documents/transaction',
        args: ['amount', 'entityId', 'contractorId']
    }),
    contactsByName: sourceDescriptor({
        url: '/list/contacts',
        args: ['id']
    }),
    communities: sourceDescriptor({
        url: '/list/contractors',
        args: ['limit', 'existsOn'],
        info: COMBOBOX_INFO.contractors
    }),
    communityById: sourceDescriptor({
        url: '/list/contractors/id',
        args: ['id'],
        info: COMBOBOX_INFO.contractors
    }),
    contractors: sourceDescriptor({
        url: '/contractor',
        info: COMBOBOX_INFO.contractors
    }),
    contractorTypes: sourceDescriptor({
        url: '/list/entities/types'
    }),
    bankTypes: sourceDescriptor({
        url: '/list/bank/types'
    }),
    bankNumbers: sourceDescriptor({
        url: '/list/bank-accounts/entity',
        args: ['id']
    }),
    taskNames: sourceDescriptor({
        url: '/list/task'
    }),
    inspectionNames: sourceDescriptor({
        url: '/list/inspection'
    }),
    taskCategories: sourceDescriptor({
        url: '/list/task/categories'
    }),
    taskSubcategories: sourceDescriptor({
        url: '/list/task/subcategories',
        args: ['id']
    }),
    inspectionTypes: sourceDescriptor({
        url: '/list/inspection-types'
    }),
    producers: sourceDescriptor({
        url: '/list/producers'
    }),
    premises: sourceDescriptor({
        url: '/list/premises',
        args: ['id']
    }),
    contactTypes: sourceDescriptor({
        url: '/list/entities/contact-types'
    }),
    taskStatuses: sourceDescriptor({
        url: '/list/task-statuses'
    }),
    documentFlowStatuses: sourceDescriptor({
        url: '/invoice/statuses'
    }),
    addresses: sourceDescriptor({
        url: '/list/contractor-addresses',
        args: ['id']
    }),
    industry: sourceDescriptor({
        url: '/list/industries'
    }),
    contractorEmails: sourceDescriptor({
        url: '/list/contractor-emails',
        args: ['id']
    }),
    users: sourceDescriptor({
        url: '/list/users',
        args: ['roles']
    }),
    remarkTypes: sourceDescriptor({
        url: '/list/remarkTypes'
    }),
    infrastructureWasteRecipients: sourceDescriptor({
        url: '/list/infrastructures/wasteRecipients'
    }),
    transactionTypes: sourceDescriptor({
        url: '/list/transaction-types'
    }),
    documentTypes: sourceDescriptor({
        url: '/list/document/types',
        args: ['kind']
    }),
    referValues: sourceDescriptor({
        url: 'correspondence/referFieldData',
        args: ['journalId', 'contractorId', 'type']
    }),
    correspondenceThreads: sourceDescriptor({
        url: '/correspondence/threads',
        args: []
    }),
    vindicationCategories: sourceDescriptor({
        url: '/list/vindication/categories'
    }),
    vindicationStatuses: sourceDescriptor({
        url: '/list/vindication/statuses'
    }),
    tallyTypes: sourceDescriptor({
        url: '/list/tally-types'
    }),
    bankAccountTypes: sourceDescriptor({
        url: '/list/bank-account-types'
    }),
    empty: sourceDescriptor({
        url: null
    })
};

export default COMBOBOX_TYPES;
