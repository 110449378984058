import React, { useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import FilesList from '../../../common/FilesList/FilesList';
import restRequestsHelper from '../../../../lib/restRequestsHelper';
import closeSnackbarComponent from '../../../common/CustomSnackbars/Utils/closeSnackbarComponent';
import ReadingsErrorsSnackbar from '../../../common/CustomSnackbars/ReadingsErrorsSnackbar';

const messages = {
    confirm: {
        id: 'app.form.confirm'
    },
    incorrect: {
        id: 'app.readings.importIncorrect'
    },
    mustSelectFile: {
        id: 'app.form.mustSelectFile'
    },
    importReadings: {
        id: 'app.readings.import'
    },
    importSuccess: {
        id: 'app.readings.importSuccess'
    },
    importFailure: {
        id: 'app.readings.importFailure'
    },
    upload: {
        id: 'app.form.upload'
    },
    acceptedDateFormat: {
        id: 'app.note.acceptedDateFormat'
    },
    acceptedFormats: {
        id: 'app.note.acceptedFormats'
    },
    downloadReport: {
        id: 'app.readings.downloadReport'
    }
};
const useStyles = makeStyles((theme) => ({
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        verticalAlign: 'top'
    }
}));

function AddReadings() {
    const history = useHistory();
    const classes = useStyles();
    const [readings, setReadings] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [open, setOpen] = useState(true);
    const intl = useIntl();
    const input = useRef(null);

    const prepareErrorsToFile = (errors) => {
        let data = '';
        errors.forEach((file) => {
            data += `Plik: ${file.fileName}\n`;
            file.errors.forEach((error) => {
                data += `${error}\n`;
            });
            data += '\n';
        });
        return data;
    };

    const handleSubmit = async () => {
        if (readings.length >= 1) {
            const response = await restRequestsHelper
                .uploadReadings(readings);
            const errors = prepareErrorsToFile(response.errors);

            if (response.inserted > 0) {
                enqueueSnackbar(intl.formatMessage(messages.importSuccess, { inserted: response.inserted }), { variant: 'success' });
                if (!response.success) {
                    enqueueSnackbar(
                        <ReadingsErrorsSnackbar
                            errors={errors}
                            message={intl.formatMessage(messages.incorrect)}
                            downloadText={intl.formatMessage(messages.downloadReport)}
                            fileName={`${dayjs().format('YYYYMMDD-HHmmss')} - błędy import odczytów`}
                        />,
                        {
                            variant: 'warning',
                            autoHideDuration: 60 * 1000,
                            action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar)
                        }
                    );
                }
                setReadings([]);
                input.current.value = '';
                setOpen(false);
                history.push('/');
            } else {
                enqueueSnackbar(
                    <ReadingsErrorsSnackbar
                        errors={errors}
                        message={intl.formatMessage(messages.importFailure)}
                        downloadText={intl.formatMessage(messages.downloadReport)}
                        fileName={`${dayjs().format('YYYYMMDD-HHmmss')} - błędy import odczytów`}
                    />,
                    {
                        variant: 'error',
                        autoHideDuration: 60 * 1000,
                        action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar)
                    }
                );
            }
        } else {
            enqueueSnackbar(intl.formatMessage(messages.mustSelectFile, { file: 'xls/xlsx' }), { variant: 'warning' });
        }
    };

    const handleClose = () => {
        history.push('/');
    };
    return (
        <div>
            <Dialog fullWidth open={open} onClose={handleClose}>
                <DialogTitle id='form-dialog-title'>
                    <FormattedMessage id={messages.importReadings.id} />
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={handleClose}
                        aria-label='close'
                        className={classes.close}
                        size='large'
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography component='div'>
                        <Grid container alignItems='center' spacing={2}>
                            <Grid item xs={12}>
                                <Alert severity='warning'>
                                    <FormattedMessage id={messages.acceptedDateFormat.id} />
                                </Alert>
                                <Alert severity='info' sx={{ mt: 1 }}>
                                    <FormattedMessage id={messages.acceptedFormats.id} values={{ br: <br /> }} />
                                </Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    component='label'
                                >
                                    <FormattedMessage id={messages.upload.id} />
                                    <input
                                        ref={input}
                                        type='file'
                                        hidden
                                        multiple
                                        accept='.xlsx,.xls,.csv,.numbers'
                                        onChange={(event) => {
                                            const files = [...event.target.files];
                                            setReadings(files);
                                        }}
                                    />
                                </Button>
                                <FilesList value={readings} />
                            </Grid>
                        </Grid>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={handleSubmit}
                        color='primary'
                    >
                        <FormattedMessage id={messages.confirm.id} />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddReadings;
