import React, { useState } from 'react';
import BankStatementsForm from '../../../common/BankStatementsForm/BankStatementsForm';
import AddBankStatementDialog from './AddBankStatementDialog';

function AddBankStatementManually() {
    const [data, setData] = useState();
    const [open, setOpen] = useState(true);

    const handleChange = ({ amount }) => setOpen(!amount);

    return (
        <div>
            <AddBankStatementDialog
                open={open}
                onSubmit={(value) => {
                    setData(value);
                    setOpen(false);
                }}
            />
            <BankStatementsForm
                open
                data={[[]]}
                onChange={handleChange}
                metadata={data && [{
                    entityId: data.entity._id,
                    entityName: data.entity.name,
                    entityBankNumber: data.bankNumber.number
                }]}
                insertable
                editable
                removable
            />
        </div>
    );
}

export default AddBankStatementManually;
