import React from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { renderCellExpand } from '../../../DataGridCells/GridCellExpand';
import dateFormat from '../../../../../constants/dateFormat';

const messages = {
    number: { id: 'app.tasks.number' },
    name: { id: 'app.tasks.name' },
    content: { id: 'app.tasks.content' },
    concerns: { id: 'app.tasks.concerns' },
    status: { id: 'app.tasks.status' },
    dueDate: { id: 'app.tasks.dueDate' },
    date: { id: 'app.tasks.date' },
    prevStatus: { id: 'app.tasks.prevStatus' },
    nextStatus: { id: 'app.tasks.nextStatus' },
    historyAuthor: { id: 'app.tasks.historyAuthor' }
};

const useSubTasksColumns = () => {
    const intl = useIntl();
    return [
        {
            field: 'numberUI',
            headerName: intl.formatMessage(messages.number),
            width: 120,
            headerAlign: 'center',
            renderCell: ({ value }) => renderCellExpand({ value, showPointer: true })
        },
        {
            field: 'concernsUI',
            headerName: intl.formatMessage(messages.concerns),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: ({ row }) => renderCellExpand({ value: row.concerns.map((item) => item.name).join(', ') })
        },
        {
            field: 'name',
            headerName: intl.formatMessage(messages.name),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: renderCellExpand
        },
        {
            field: 'content',
            headerName: intl.formatMessage(messages.content),
            width: 200,
            flex: 2,
            headerAlign: 'center',
            renderCell: renderCellExpand
        },
        {
            field: 'dueDateUI',
            headerName: intl.formatMessage(messages.dueDate),
            width: 140,
            headerAlign: 'center',
            renderCell: renderCellExpand
        },
        {
            field: 'status',
            headerName: intl.formatMessage(messages.status),
            valueGetter: (params) => params.value.$name,
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: (value) => (
                <span style={{ color: value.row.status.color }}>
                    {value.formattedValue}
                </span>
            )
        }
    ];
};

const useActionsColumns = () => {
    const intl = useIntl();
    return [
        {
            field: 'date',
            type: 'date',
            headerName: intl.formatMessage(messages.date),
            width: 180,
            valueGetter: ({ value }) => new Date(value),
            valueFormatter: (params) => dayjs(params.value).format(dateFormat.fullDate)
        },
        {
            field: 'previous_status',
            headerName: intl.formatMessage(messages.prevStatus),
            valueGetter: (params) => params.value.$name,
            width: 180,
            flex: 1
        },
        {
            field: 'next_status',
            headerName: intl.formatMessage(messages.nextStatus),
            valueGetter: (params) => params.value.$name,
            width: 180,
            flex: 1
        },
        {
            field: 'user',
            headerName: intl.formatMessage(messages.historyAuthor),
            valueGetter: (params) => params.value?.$name,
            width: 180
        }
    ];
};

export { useSubTasksColumns, useActionsColumns };
