import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ContractorForm from '../../../../common/ContractorForm/ContractorForm';
import { modifyContractors } from '../../../Contractors/shared/hooks/useContractors';

const useStyles = makeStyles(() => ({
    loadingSpinner: {
        paddingTop: '100px'
    }
}));
function InhabitantData({ inhabitantData }) {
    const styles = useStyles();
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        if (inhabitantData?._id) {
            const modifiedContractor = modifyContractors([inhabitantData]);
            setFormData(modifiedContractor[0]);
        }
    }, [inhabitantData]);
    return (
        <div>
            {
                formData ? (
                    <ContractorForm edit={formData} inhabitantView readOnly />
                ) : (
                    <div className={styles.loadingSpinner}>
                        <CircularProgress size={28} />
                    </div>
                )
            }
        </div>
    );
}

InhabitantData.propTypes = {
    inhabitantData: PropTypes.object
};

InhabitantData.defaultProps = {
    inhabitantData: () => {}
};

export default InhabitantData;
