import React from 'react';
import { useIntl } from 'react-intl';
import { AttachMoney, RequestQuote } from '@mui/icons-material';
import { renderCellExpand } from '../../../common/DataGridCells/GridCellExpand';
import formatMoney from '../../../../lib/formatMoney';
import SettlementTypes from '../../../../constants/SettlementTypes';

const messages = {
    type: { id: 'app.settlements.type' },
    entity: { id: 'app.settlements.entity' },
    contractor: { id: 'app.settlements.contractor' },
    date: { id: 'app.settlements.date' },
    documentAmount: { id: 'app.settlements.documentAmount' },
    transactionAmount: { id: 'app.settlements.transactionAmount' },
    number: { id: 'app.settlements.number' },
    editTransaction: { id: 'app.bankStatement.edit' }
};

const renderSettlementIcon = (type) => {
    switch (type) {
        case SettlementTypes.invoice:
            return <AttachMoney sx={{ color: 'red' }} />;
        case SettlementTypes.income:
            return <AttachMoney sx={{ color: 'green' }} />;
        case SettlementTypes.transaction:
            return <RequestQuote sx={{ color: 'yellow' }} />;
        default:
            return null;
    }
};

const useColumns = () => {
    const intl = useIntl();
    return [
        {
            field: 'type',
            headerName: intl.formatMessage(messages.type),
            width: 40,
            disableExport: true,
            headerAlign: 'center',
            renderCell: ({ row }) => renderSettlementIcon(row.type)
        },
        {
            field: 'entity',
            headerName: intl.formatMessage(messages.entity),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: ({ row }) => renderCellExpand({ data: row.entity, showPointer: true })
        },
        {
            field: 'contractor',
            headerName: intl.formatMessage(messages.contractor),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: ({ row }) => renderCellExpand({ data: row.contractor, showPointer: true })
        },
        {
            field: 'date',
            headerName: intl.formatMessage(messages.date),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            valueGetter: ({ value }) => new Date(value),
            renderCell: ({ row }) => row.date
        },
        {
            field: 'documentAmount',
            headerName: intl.formatMessage(messages.documentAmount),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => renderCellExpand(params, null, (val) => (val ? formatMoney(val) : ''))
        },
        {
            field: 'transactionAmount',
            headerName: intl.formatMessage(messages.transactionAmount),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => renderCellExpand(params, null, (val) => (val ? formatMoney(val) : ''))
        },
        {
            field: 'name',
            headerName: intl.formatMessage(messages.number),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: ({ row }) => renderCellExpand({ data: row.name, showPointer: row.type !== null })
        }
    ];
};

export default useColumns;
