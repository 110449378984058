import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import RestRequestsHelper from '../../../lib/restRequestsHelper';

function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            hidden={value !== index}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const messages = {
    saveBtn: {
        id: 'app.settings.saveBtn'
    },
    user: {
        id: 'app.settings.ftpUsername'
    },
    host: {
        id: 'app.settings.ftpHost'
    },
    port: {
        id: 'app.settings.ftpPort'
    },
    password: {
        id: 'app.settings.ftpPassword'
    },
    errorChanging: {
        id: 'app.settings.errorChangingSettings'
    },
    successChanging: {
        id: 'app.settings.successChangingSettings'
    },
    successfullySettingsUpdated: {
        id: 'app.settings.success'
    },
    ftpSettings: {
        id: 'app.settings.ftp'
    },
    path: {
        id: 'app.settings.path'
    },
    test: {
        id: 'app.settings.test'
    },
    testSuccess: {
        id: 'app.settings.testSuccess'
    }
};

const useStyles = makeStyles((theme) => ({
    main: {
        padding: theme.spacing(2)
    }
}));

function FtpSettings() {
    const classes = useStyles();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [ftpSettings, setFtpSettings] = useState({
        host: '',
        port: '',
        user: '',
        password: '',
        path: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (!user) {
            history.push('/');
        } else {
            RestRequestsHelper.getFtpSettings()
                .then((result) => {
                    if (result) {
                        setFtpSettings({
                            host: result.host,
                            port: result.port,
                            user: result.user,
                            path: result.path,
                            password: ''
                        });
                    }
                });
        }
    }, []);

    const updateForm = (event) => {
        const { name, value } = event.target;
        setFtpSettings((item) => {
            const newStateValue = { ...item };
            newStateValue[name] = value;
            return newStateValue;
        });
    };
    const formSubmit = (event) => {
        event.preventDefault();
        const data = {

            host: ftpSettings.host,
            port: ftpSettings.port,
            user: ftpSettings.user,
            path: ftpSettings.path,
            password: ftpSettings.password

        };
        RestRequestsHelper.changeFtpSettings(data).then((result) => {
            setFtpSettings((oldValue) => {
                const newValue = { ...oldValue };
                newValue.password = '';
                return newValue;
            });
            if (result.status === 'changed') {
                enqueueSnackbar(intl.formatMessage({ id: messages.successChanging.id }), { variant: 'success' });
            } else {
                enqueueSnackbar(intl.formatMessage({ id: messages.errorChanging.id }), { variant: 'error' });
            }
        });
    };

    const testFtp = async () => {
        setIsLoading(true);
        const result = await RestRequestsHelper.testFtpSettings();
        if (result.data.status === 'ok') {
            enqueueSnackbar(intl.formatMessage({ id: messages.testSuccess.id }), { variant: 'success' });
        } else {
            enqueueSnackbar(result.data.error, { variant: 'error' });
        }
        setIsLoading(false);
    };

    return (
        <Paper className={classes.main}>
            <Typography variant='h5' align='left'><FormattedMessage id={messages.ftpSettings.id} /></Typography>
            <form onSubmit={formSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='host'
                            label={(
                                <FormattedMessage
                                    id={messages.host.id}
                                />
                            )}
                            name='host'
                            autoComplete='host'
                            value={ftpSettings.host}
                            onChange={updateForm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='port'
                            label={(
                                <FormattedMessage
                                    id={messages.port.id}
                                />
                            )}
                            name='port'
                            autoComplete='port'
                            value={ftpSettings.port}
                            onChange={updateForm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='path'
                            label={(
                                <FormattedMessage
                                    id={messages.path.id}
                                />
                            )}
                            name='path'
                            autoComplete='path'
                            value={ftpSettings.path}
                            onChange={updateForm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='user'
                            label={(
                                <FormattedMessage
                                    id={messages.user.id}
                                />
                            )}
                            name='user'
                            autoComplete='user'
                            autoFocus
                            value={ftpSettings.user}
                            onChange={updateForm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            type='password'
                            id='password'
                            label={(
                                <FormattedMessage
                                    id={messages.password.id}
                                />
                            )}
                            name='password'
                            autoComplete='password'
                            value={ftpSettings.password}
                            onChange={updateForm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LoadingButton onClick={testFtp} loading={isLoading} variant='contained' color='secondary'>
                            <FormattedMessage id={messages.test.id} />
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button type='submit' variant='contained' color='primary'><FormattedMessage id={messages.saveBtn.id} /></Button>
                    </Grid>
                </Grid>
            </form>

        </Paper>

    );
}

export default FtpSettings;
