import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useIntl, FormattedMessage } from 'react-intl';
import { userSignedOut } from '../../../actions';
import userRoles from '../../../constants/UserRoles';
import TabPanel from '../../common/TabPanel/TabPanel';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import ShortAppMenu from './components/ShortAppMenu';
import MenuLogo from './components/MenuLogo';
import TasksDisplay from './components/TasksDisplay';
import IncommingReminders from './components/IncommingReminders';
import InhabitantData from './components/inhabitants/InhabitantData';
import InhabitantInfrastructure from './components/inhabitants/InhabitantInfrastructure';
import InhabitantCosts from './components/inhabitants/InhabitantCosts';
import InhabitantTasks from './components/inhabitants/InhabitantTasks';

const messages = {
    loggedIn: {
        id: 'app.homePage.loggedIn.message',
        defaultMessage: 'You are logged in as: {username}.'
    },
    notLoggedIn: {
        id: 'app.homePage.notLoggedIn.message',
        defaultMessage: 'You are not logged in'
    },
    shortMenuTitle: { id: 'app.homePage.shortcuts' },
    data: { id: 'app.homePage.data' },
    infrastructure: { id: 'app.homePage.infrastructure' },
    costs: { id: 'app.homePage.costs' },
    tasks: { id: 'app.homePage.tasks' },
    reminders: { id: 'app.homePage.reminders' }
};

const useStyles = makeStyles(() => ({
    root: {
        height: ({ isProductionDb }) => `calc(100vh - ${isProductionDb ? '64' : '128'}px)`
    },
    pane: {
        height: '75%',
        overflowY: 'auto'
    },
    inahbitantHomePage: {
        height: '100%'
    },
    bottomPaneCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    title: {
        marginBottom: 5
    }
}));

function HomePage() {
    const intl = useIntl();
    const isProductionDb = useSelector((state) => state.dbInfo.production);
    const user = useSelector((state) => state.user);
    const [isInhabitant, setIsInhabitant] = React.useState(false);
    const [inhabitantData, setInhabitantData] = React.useState({});
    const [tabIndexInhabitant, setTabIndexInhabitant] = React.useState(0);
    const [tabIndexLeft, setTabIndexLeft] = React.useState(0);
    const [tabIndexRight, setTabIndexRight] = React.useState(0);
    const dispatch = useDispatch();
    const classes = useStyles({ isProductionDb });
    const tabsInhabitant = [
        {
            label: intl.formatMessage(messages.data),
            content: <InhabitantData inhabitantData={inhabitantData} />
        },
        {
            label: intl.formatMessage(messages.tasks),
            content: <InhabitantTasks inhabitantData={inhabitantData} />
        },
        {
            label: intl.formatMessage(messages.costs),
            content: <InhabitantCosts inhabitantData={inhabitantData} />
        },
        {
            label: intl.formatMessage(messages.infrastructure),
            content: <InhabitantInfrastructure />
        }
    ];
    const tabsLeft = useMemo(() => [
        {
            label: intl.formatMessage(messages.tasks),
            content: <TasksDisplay />,
            roles: [userRoles.admin, userRoles.user]
        }
    ].filter(({ roles }) => roles.includes(user?.role)), [user]);

    const tabsRight = useMemo(() => [
        {
            label: intl.formatMessage(messages.reminders),
            content: <IncommingReminders />,
            roles: [userRoles.admin, userRoles.user, userRoles.contractor]
        }
    ].filter(({ roles }) => roles.includes(user?.role)), [user]);

    useEffect(() => {
        if (user) {
            RestRequestsHelper.checkIfSignedIn()
                .then((data) => {
                    if (!(data && data.username)) {
                        dispatch(userSignedOut());
                    }
                });
        }
    }, []);

    useEffect(() => {
        setIsInhabitant(user?.role === userRoles.inhabitant || false);
    }, [user]);

    const getInhabitantData = async () => {
        const { data } = await RestRequestsHelper.getLoggedInhabitantData();
        setInhabitantData(data.inhabitant);
    };

    useEffect(() => {
        getInhabitantData();
    }, [isInhabitant]);

    return (
        <div>
            {user && user.username ? (
                <div>
                    <Grid container className={classes.root}>
                        {
                            isInhabitant ? (
                                <Grid item xs={12} className={isInhabitant ? 'classes.inahbitantHomePage' : 'classes.pane'}>
                                    <Container maxWidth='100%'>
                                        <Tabs variant='fullWidth' value={tabIndexInhabitant} onChange={(_event, value) => setTabIndexInhabitant(value)} indicatorColor='primary' textColor='inherit' centered>
                                            {tabsInhabitant.map((tab, index) => (
                                                <Tab key={index} label={tab.label} />
                                            ))}
                                        </Tabs>
                                        {tabsInhabitant.map((tab, index) => (
                                            <TabPanel key={index} value={tabIndexInhabitant} index={index}>
                                                {tab.content}
                                            </TabPanel>
                                        ))}
                                    </Container>
                                </Grid>
                            )
                                : (
                                    <>
                                        <Grid item xs={6} className={classes.pane}>
                                            <Container maxWidth='100%'>
                                                <Tabs value={tabIndexLeft} onChange={(_event, value) => setTabIndexLeft(value)} indicatorColor='primary' textColor='inherit' centered>
                                                    {tabsLeft.map((tab, index) => (
                                                        <Tab key={index} label={tab.label} />
                                                    ))}
                                                </Tabs>
                                                {tabsLeft.map((tab, index) => (
                                                    <TabPanel key={index} value={tabIndexLeft} index={index}>
                                                        {tab.content}
                                                    </TabPanel>
                                                ))}
                                            </Container>
                                        </Grid>
                                        <Grid item xs={6} className={classes.pane}>
                                            <Container maxWidth='100%'>
                                                <Tabs value={tabIndexRight} onChange={(_event, value) => setTabIndexRight(value)} indicatorColor='primary' textColor='inherit' centered>
                                                    {tabsRight.map((tab, index) => (
                                                        <Tab key={index} label={tab.label} />
                                                    ))}
                                                </Tabs>
                                                {tabsRight.map((tab, index) => (
                                                    <TabPanel key={index} value={tabIndexRight} index={index}>
                                                        {tab.content}
                                                    </TabPanel>
                                                ))}
                                            </Container>
                                        </Grid>
                                    </>
                                )
                        }
                        {
                            !isInhabitant && (
                                <Grid item xs={12} className={classes.leftPane}>
                                    <Typography variant='h4' className={classes.title}>
                                        <FormattedMessage id={messages.shortMenuTitle.id} />
                                    </Typography>
                                    <div className={classes.bottomPaneCenter}>
                                        <ShortAppMenu />
                                    </div>
                                </Grid>
                            )
                        }
                    </Grid>
                </div>
            ) : (
                <MenuLogo centered />
            ) }
        </div>
    );
}

export default HomePage;
