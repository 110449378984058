import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    FormControlLabel,
    Paper,
    Grid,
    Typography,
    Checkbox
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import RestRequestsHelper from '../../../lib/restRequestsHelper';

function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;
    return (
        <div
            hidden={value !== index}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const messages = {
    saveBtn: {
        id: 'app.settings.saveBtn'
    },
    errorChanging: {
        id: 'app.settings.errorChangingSettings'
    },
    successChanging: {
        id: 'app.settings.successChangingSettings'
    },
    successfullySettingsUpdated: {
        id: 'app.settings.success'
    },
    globalSettings: {
        id: 'app.settings.global'
    },
    path: {
        id: 'app.settings.path'
    },
    checkInvoice: {
        id: 'app.settings.checkInvoice'
    },
    globalInfo: {
        id: 'app.settings.info'
    },
    version: {
        id: 'app.settings.info.version'
    },
    buildDate: {
        id: 'app.settings.info.buildDate'
    },
    buildId: {
        id: 'app.settings.info.buildId'
    },
    buildAuthor: {
        id: 'app.settings.info.buildAuthor'
    }
};

const useStyles = makeStyles((theme) => ({
    main: {
        padding: theme.spacing(2)
    },
    section: {
        marginBottom: theme.spacing(2)
    },
    system_info: {
        paddingTop: theme.spacing(5)
    }
}));

function GlobalSettings() {
    const classes = useStyles();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const [checkInvoice, setCheckInvoice] = useState(false);

    useEffect(() => {
        if (!user) {
            history.push('/');
        } else {
            RestRequestsHelper.getCheckInvoiceSettings()
                .then((result) => {
                    if (result) {
                        setCheckInvoice(result.value);
                    }
                });
        }
    }, []);

    const formSubmit = async (event) => {
        event.preventDefault();
        const response = await RestRequestsHelper.changeCheckInvoiceSettings(checkInvoice);
        if (response.status === 'changed') {
            enqueueSnackbar(intl.formatMessage({ id: messages.successfullySettingsUpdated.id }), { variant: 'success' });
        }
    };

    return (
        <Paper className={classes.main}>
            <div className={classes.section}>
                <Typography variant='h5' align='left'><FormattedMessage id={messages.globalSettings.id} /></Typography>
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={checkInvoice}
                            onChange={() => setCheckInvoice(!checkInvoice)}
                            color='secondary'
                        />
                    )}
                    label={intl.formatMessage({ id: messages.checkInvoice.id })}
                />
                <form onSubmit={formSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <Button type='submit' variant='contained' color='primary'><FormattedMessage id={messages.saveBtn.id} /></Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Divider />
            <div className={classes.section}>
                <Typography variant='h5' align='left'><FormattedMessage id={messages.globalInfo.id} /></Typography>
                <div className={classes.system_info}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                            <Typography variant='body1' align='center'>
                                <FormattedMessage
                                    id={messages.version.id}
                                    values={{ version: process.env.REACT_APP_VERSION }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant='body1' align='center'>
                                <FormattedMessage
                                    id={messages.buildDate.id}
                                    values={{ buildDate: process.env.REACT_APP_BUILD_DATE }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant='body1' align='center'>
                                <FormattedMessage
                                    id={messages.buildId.id}
                                    values={{ buildId: process.env.REACT_APP_CONCURRENT_ID }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography variant='body1' align='center'>
                                <FormattedMessage
                                    id={messages.buildAuthor.id}
                                    values={{ buildAuthor: process.env.REACT_APP_BUILD_USER_NAME }}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Paper>
    );
}

export default GlobalSettings;
