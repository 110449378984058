import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import downloadFile from '../../../lib/downloadFile';

function ReadingsErrorsSnackbar({
    message, downloadText, errors, fileName
}) {
    const handleDownload = () => {
        const blob = new Blob([errors], { type: 'text/plain' });
        downloadFile(blob, fileName);
    };
    return (
        <Typography variant='p'>
            {message}
            .
            {' '}
            <Typography variant='span' sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleDownload}>
                {
                    downloadText
                }
            </Typography>
        </Typography>
    );
}

ReadingsErrorsSnackbar.propTypes = {
    errors: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    downloadText: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired
};
export default ReadingsErrorsSnackbar;
