import { Buffer } from 'buffer';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import downloadFile from '../../../../lib/downloadFile';
import GenerateCorrespondenceListDialog from './components/GenerateCorrespondenceListDialog/GenerateCorrespondenceListDialog';

const messages = {
    success: { id: 'app.generateCorrespondenceList.success' },
    error: { id: 'app.generateCorrespondenceList.error' }
};
interface submitData{
    communities: string[],
}

const handleDownload = async (data: Blob, filename = 'Lista korespondencyjna') => {
    const blob = new Blob([data], {
        type: 'application/zip'
    });
    downloadFile(blob, `${filename}-${+new Date()}.zip`);
};

function CorrespondenceList() {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [communities, setCommunities] = useState([]);

    const handleSubmit = async (data: submitData) => {
        try {
            const response = (await RestRequestsHelper.generateCorrespondenceList(data)).data;
            const zip = new Blob([Buffer.from(response.zip.data)]);
            handleDownload(zip);
            enqueueSnackbar(intl.formatMessage({ id: messages.success.id }), { variant: 'success' });
            history.push('/');
        } catch (error) {
            enqueueSnackbar(intl.formatMessage({ id: messages.error.id }), { variant: 'error' });
        }
    };

    return (
        <GenerateCorrespondenceListDialog
            onSubmit={handleSubmit}
            communities={communities}
            onCommunitiesChange={setCommunities}
        />
    );
}

export default CorrespondenceList;
