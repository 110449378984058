import useSWR from 'swr';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';

const useHistory = (collectionName, recordId, page, pageSize, sortModel) => {
    const { data, error, mutate } = RestRequestsHelper.getHistory(useSWR, {
        collectionName,
        recordId,
        page,
        pageSize,
        sortModel
    });
    return {
        history: data?.data ?? [],
        count: data?.count ?? 0,
        loading: !error && !data,
        mutate
    };
};

export default useHistory;
