import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        minWidth: '500px'
    },
    preview: {
        width: '100%',
        height: '76vh',
        overflow: 'scroll'
    }
}));

function ImagePreview({
    file,
    scale,
    fitImage
}) {
    const style = useStyles();

    const fileUrl = file ? URL.createObjectURL(file) : null;
    const image = useRef();
    useEffect(() => {
        if (image && image.current) {
            image.current.style.width = `${scale}%`;
            image.current.style.height = 'auto';
        }
    }, [scale]);
    useEffect(() => {
        if (image && image.current) {
            fitImage(image.current.parentElement);
        }
    }, [file]);

    return (
        <div className={style.root}>
            <div className={style.preview}>
                <img ref={image} alt={file.name} src={fileUrl} />
            </div>
        </div>

    );
}

ImagePreview.propTypes = {
    file: PropTypes.object,
    scale: PropTypes.number,
    fitImage: PropTypes.func
};
ImagePreview.defaultProps = {
    file: undefined,
    scale: 100,
    fitImage: () => {}
};

export default ImagePreview;
