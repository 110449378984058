import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useForm, Controller } from 'react-hook-form';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import MoneyTextField from '../../MoneyTextField/MoneyTextField';
import dateFormat from '../../../../constants/dateFormat';
import UniversalCombobox, { comboboxTypes } from '../../Combobox/UniversalCombobox';
import editBankStatementForm from '../data/editBankStatementForm';
import formatBankAccount from '../../../../lib/formatBankAccount';
import FormHeader from '../../FormHeader/FormHeader';
import ContractorAlert from './ContractorAlert';

const messages = {
    editTitle: { id: 'app.bankStatement.editTitle' },
    addNewTitle: { id: 'app.bankStatement.addNewTitle' },
    confirm: { id: 'app.form.confirm' },
    mustSelectFile: { id: 'app.form.mustSelectFile' },
    dateOkButton: { id: 'app.invoiceForm.OkButton' },
    dateCancelButton: { id: 'app.invoiceForm.CancelButton' },
    dateTodayButton: { id: 'app.invoiceForm.todayButton' },
    documents: { id: 'app.bankStatement.documents' },
    contractorName: { id: 'app.bankStatement.contractorName' },
    entityName: { id: 'app.bankStatement.entity' },
    contractorBankNumber: { id: 'app.bankStatement.contractorBankNumber' },
    entityBankNumber: { id: 'app.bankStatement.entityBankNumber' },
    title: { id: 'app.bankStatement.title' },
    incomeAmount: { id: 'app.bankStatement.incomeAmount' },
    withdrawalAmount: { id: 'app.bankStatement.withdrawalAmount' },
    date: { id: 'app.bankStatement.date' },
    address: { id: 'app.bankStatement.address' },
    type: { id: 'app.bankStatement.type' }
};

const useStyles = makeStyles(() => ({
    button: {
        margin: 'auto',
        textAlign: 'center'
    },
    center: {
        margin: 'auto',
        textAlign: 'center'
    }
}));

function PaperComponent(props) {
    return (
        <Draggable
            handle='#draggable-title'
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const editTypes = {
    edit: 0,
    add: 1
};

const componentTypeMessages = [
    { title: messages.editTitle },
    { title: messages.addNewTitle }
];

// eslint-disable-next-line react/prop-types
const renderCombobox = (isContractor, watchEntity, setAccountType) => (function ({ field }) {
    const intl = useIntl();

    const renderOptionLabel = (option) => {
        if (typeof option === 'string') {
            return option;
        }
        return `${option.number} (${option.name})`;
    };

    return isContractor
        ? (
            <UniversalCombobox
                label={intl.formatMessage(messages.entityName)}
                src={comboboxTypes.communities()}
                optionLabel={({ name }) => name}
                margin='normal'
                required
                showColors
                {...field}
            />
        ) : (
            <UniversalCombobox
                src={setAccountType()}
                label={intl.formatMessage(messages.entityBankNumber)}
                optionLabel={renderOptionLabel}
                disabled={!watchEntity}
                {...field}
                showColors
                selectIfSingleton
                margin='normal'
                required
            />
        );
});

function EditBankStatementsDialog({
    open: propOpen, onSubmit, data, metadata, onClose, type, allowEditingEntity, onDelete
}) {
    const intl = useIntl();
    const classes = useStyles();
    const [open, setOpen] = useState(propOpen);
    const [depositDisable, setDepositDisable] = useState(false);
    const [withdrawDisable, setWithdrawDisable] = useState(false);
    const {
        register, control, handleSubmit, reset, watch
    } = useForm({ defaultValues: editBankStatementForm(data) });
    const watchAmountDeposited = watch('amountDeposited');
    const watchAmountWithdrawn = watch('amountWithdrawn');
    const watchEntity = watch('entity');
    const watchEntityBankNumber = watch('entityBankNumber');
    const watchContractor = watch('contractor');
    const watchContractorBankNumber = watch('contractorBankNumber');
    const disabledContractorField = Boolean(allowEditingEntity && !watchEntity?._id);

    const setAccountType = (phrase) => {
        const bankId = watchEntity?._id;
        if (bankId) {
            return comboboxTypes.bankNumbers(bankId, watchEntityBankNumber || phrase);
        }
        return comboboxTypes.empty();
    };

    const documentsSrc = (phrase) => {
        const entityId = watchEntity?._id;
        const contractorId = watchContractor?._id;
        const amount = watchAmountDeposited || -watchAmountWithdrawn;
        if (entityId && contractorId && amount) {
            return comboboxTypes.documentsForTransaction(amount, entityId, contractorId, phrase);
        }
        return comboboxTypes.empty();
    };

    useEffect(() => {
        const predicate = (value) => (
            (typeof value === 'string' && parseInt(value) !== 0 && value.length)
            || (typeof value === 'number' && value !== 0)
        );
        if (predicate(watchAmountDeposited)) {
            setWithdrawDisable(true);
            setDepositDisable(false);
            return;
        }
        if (predicate(watchAmountWithdrawn)) {
            setWithdrawDisable(false);
            setDepositDisable(true);
            return;
        }
        setWithdrawDisable(false);
        setDepositDisable(false);
    }, [watchAmountDeposited, watchAmountWithdrawn]);

    useEffect(() => {
        setOpen(propOpen);
    }, [propOpen]);

    useEffect(() => {
        reset(editBankStatementForm(data));
    }, [data]);

    const handleSubmitForm = (newData) => {
        const deposited = parseInt(newData.amountDeposited.toString().replace(/[.\s]/g, ''));
        const withdrawn = parseInt(newData.amountWithdrawn.toString().replace(/[.\s]/g, ''));
        onSubmit({
            id: data?.id,
            type: newData.type,
            contractorId: newData.contractor._id,
            contractorName: newData.contractor.name,
            contractorAltName: newData.contractorAltName,
            contractorBankNumber: newData.contractorBankNumber,
            entityId: metadata.entityId || (allowEditingEntity ? newData.entity?._id : undefined),
            entityName: metadata.entityName || (allowEditingEntity ? newData?.entity?.name : undefined),
            entityBankNumber: metadata.entityBankNumber
                || (allowEditingEntity
                    ? (newData?.entityBankNumber?.number || newData?.entityBankNumber)
                    : undefined
                ),
            amount: deposited || -withdrawn,
            date: newData?.date,
            documents: (newData.documents || []).map((document) => document._id),
            reference: newData.reference
        });
        if (type === editTypes.add) {
            reset();
        }
    };

    const handleClose = () => {
        onClose();
    };

    const documentOptionLabel = ({ document_number: docNumber, value }) => (
        `${docNumber} (${value} PLN)`
    );

    const prepareContractorLabel = (val) => {
        const { name, address } = val;
        if (address?.length > 0) {
            // type 1 - siedziba
            const [filtered] = address.filter((elm) => elm?.type.key === 1);
            if (filtered) {
                const {
                    street, number, postal_code: postalCode, city
                } = filtered;
                return `${name} (${street} ${number}, ${postalCode} ${city})`;
            }
        }
        return name;
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                disableEscapeKeyDown
                PaperComponent={PaperComponent}
                aria-labelledby='draggable-title'
                fullWidth
                maxWidth='lg'
            >
                <DialogTitle id='draggable-title' onClose={handleClose}>
                    <FormHeader
                        label={componentTypeMessages[type].title.id}
                        onDelete={onDelete}
                        hideDelete={!onDelete}
                        addationalNode={(
                            <IconButton
                                edge='start'
                                color='inherit'
                                onClick={handleClose}
                                aria-label='close'
                                size='large'
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    />

                </DialogTitle>
                <form onSubmit={handleSubmit(handleSubmitForm)} action='#'>
                    <DialogContent>
                        <ContractorAlert contractorAltName={data?.contractorAltName} imported={metadata?.imported} />
                        <Grid container>
                            {allowEditingEntity && (
                                <>
                                    <Grid item xs={6}>
                                        <Controller
                                            render={renderCombobox(true, watchEntity, setAccountType)}
                                            control={control}
                                            name='entity'
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            render={renderCombobox(false, watchEntity, setAccountType)}
                                            control={control}
                                            name='entityBankNumber'
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name='contractor'
                                    render={({ field }) => (
                                        <UniversalCombobox
                                            label={intl.formatMessage(messages.contractorName)}
                                            src={!disabledContractorField
                                                ? comboboxTypes.entitiesOfCommunityByName([watchEntity?._id])
                                                : comboboxTypes.empty}
                                            optionLabel={prepareContractorLabel}
                                            margin='normal'
                                            required
                                            showColors
                                            disabled={disabledContractorField}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={intl.formatMessage(messages.title)}
                                    margin='normal'
                                    variant='outlined'
                                    size='medium'
                                    fullWidth
                                    required
                                    name='reference'
                                    {...register('reference')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={intl.formatMessage(messages.contractorBankNumber)}
                                    margin='normal'
                                    variant='outlined'
                                    size='medium'
                                    fullWidth
                                    name='contractorBankNumber'
                                    {...register('contractorBankNumber')}
                                    onBlur={(event) => {
                                        // eslint-disable-next-line no-param-reassign
                                        event.target.value = formatBankAccount(watchContractorBankNumber);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name='type'
                                    render={({ field }) => (
                                        <UniversalCombobox
                                            required
                                            margin='normal'
                                            onMatch={(option, value) => option.key === value.key}
                                            label={intl.formatMessage(messages.type)}
                                            src={comboboxTypes.transactionTypes()}
                                            optionLabel={({ value }) => value}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name='date'
                                    render={({ field }) => (
                                        <CustomDatePicker
                                            disableToolbar
                                            variant='outlined'
                                            inputFormat={dateFormat.format}
                                            fullWidth
                                            margin='normal'
                                            id='dateOfDelivery'
                                            label={intl.formatMessage(messages.date)}
                                            okLabel={intl.formatMessage(messages.dateOkButton)}
                                            cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                            todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                            renderInput={(params) => <TextField required {...params} />}
                                            showTodayButton
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name='amountDeposited'
                                    render={({ field }) => (
                                        <MoneyTextField
                                            textFieldParams={{
                                                label: intl.formatMessage(messages.incomeAmount),
                                                margin: 'normal',
                                                variant: 'outlined',
                                                size: 'medium',
                                                fullWidth: true,
                                                name: 'amountDeposited',
                                                disabled: depositDisable
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name='amountWithdrawn'
                                    render={({ field }) => (
                                        <MoneyTextField
                                            textFieldParams={{
                                                label: intl.formatMessage(messages.withdrawalAmount),
                                                margin: 'normal',
                                                variant: 'outlined',
                                                size: 'medium',
                                                fullWidth: true,
                                                name: 'amountWithdrawn',
                                                disabled: withdrawDisable
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name='documents'
                                render={({ field }) => (
                                    <UniversalCombobox
                                        label={intl.formatMessage(messages.documents)}
                                        src={documentsSrc()}
                                        optionLabel={documentOptionLabel}
                                        multiple
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </DialogContent>
                    <Grid container>
                        <Grid item xs={12} className={classes.center}>
                            <Button
                                type='submit'
                                className={classes.button}
                                color='secondary'
                                variant='contained'
                            >
                                {intl.formatMessage({ id: messages.confirm.id })}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <DialogActions />
            </Dialog>
        </div>
    );
}

EditBankStatementsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    metadata: PropTypes.object,
    type: PropTypes.number,
    allowEditingEntity: PropTypes.bool,
    onDelete: PropTypes.func
};

EditBankStatementsDialog.defaultProps = {
    type: editTypes.edit,
    metadata: {},
    allowEditingEntity: false,
    onDelete: null
};

export default EditBankStatementsDialog;
export {
    editTypes
};
