import dayjs from 'dayjs';
import defaultRemarkType from '../../../constants/remarkDefaults';
import formatUTCDate from '../../../lib/formatUTCDate';

export const defaultRemarks = (user) => ({
    value: '',
    user,
    updatedAt: dayjs(),
    type: defaultRemarkType
});
export const defaultReminder = () => (
    {
        entity: null, date: dayjs().add(1, 'day'), value: null, _new: true
    }
);

const defaultInspectionFormValues = (user, edit) => (edit
    // Loads ready values in order to prepare them for edition
    ? ({
        user,
        name: edit.name,
        concerns: edit.concerns,
        parentInspection: edit.parent,
        receiver: edit.received,
        leader: edit.leader,
        type: edit.type,
        remarks: edit.remarksData,
        reminders: edit.remindersData
            ? edit.remindersData.map((reminder) => ({ ...reminder, _isSent: reminder?.date && dayjs().isAfter(dayjs(reminder.date)) }))
            : edit.remindersData,
        producers: edit.producers,
        validTo: formatUTCDate(edit.valid_to),
        files: edit.files,
        fileNumber: 0
    })
    : ({
        user,
        name: '',
        concerns: [],
        parentInspection: null,
        receiver: user,
        remarks: [],
        reminders: [],
        validTo: null,
        type: null,
        producers: [],
        files: [],
        fileNumber: 0
    })
);

export default defaultInspectionFormValues;
