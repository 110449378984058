export const userSignedIn = (user) => ({
    type: 'USER_SIGNED_IN',
    user
});

export const userSignedOut = () => ({
    type: 'USER_SIGNED_OUT'
});

export const changeLangToPl = () => ({
    type: 'LANG_PL'
});

export const changeLangToEn = () => ({
    type: 'LANG_EN'
});

export const setLightTheme = () => ({
    type: 'LIGHT_MODE'
});

export const setDarkTheme = () => ({
    type: 'DARK_MODE'
});

export const setDatabaseInfo = (dbInfo) => ({
    type: 'DB_CHANGE',
    dbInfo
});

export const setTask = (task) => ({
    type: 'CHANGE_SELECTED_TASK',
    task
});

export const setVindication = (vindication) => ({
    type: 'CHANGE_SELECTED_VINDICATION',
    vindication
});

export const setInspection = (inspection) => ({
    type: 'CHANGE_SELECTED_INSPECTION',
    inspection
});

export const setCorrespondence = (correspondence) => ({
    type: 'CHANGE_SELECTED_CORRESPONDENCE',
    correspondence
});

export const updateFileDownloadingState = (value, downloadId) => ({
    type: 'FILE_DOWNLOADING',
    value,
    downloadId
});

export const setIncome = (income) => ({
    type: 'CHANGE_SELECTED_INCOME',
    income
});

export const setInvoice = (invoice) => ({
    type: 'CHANGE_SELECTED_INVOICE',
    invoice
});
