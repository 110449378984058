import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFieldArray } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import makeStyles from '@mui/styles/makeStyles';
import Add from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import dateFormat from '../../../../../../../../../constants/dateFormat';
import useMeasureUnits from '../../../../../../hooks/useMeasureUnits';
import MeasureUnits from '../../../MeasureUnits/MeasureUnits';
import MoneyTextField from '../../../../../../../../common/MoneyTextField/MoneyTextField';
import CustomDatePicker from '../../../../../../../../common/CustomDatePicker/CustomDatePicker';

const messages = {
    value: 'app.infrastructures.counter.value',
    unit: 'app.infrastructures.counter.unit',
    date: 'app.infrastructures.counter.date',
    dateOkButton: 'app.invoiceForm.OkButton',
    dateCancelButton: 'app.invoiceForm.CancelButton',
    dateTodayButton: 'app.invoiceForm.todayButton'

};
const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1)
    },

    addButton: {
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
}));
function Norms({
    ind,
    control, readOnly
}) {
    const intl = useIntl();
    const classes = useStyles();
    const { measureUnits, loading } = useMeasureUnits();
    const {
        fields, append, remove
    } = useFieldArray({
        control,
        name: `ingredients.${ind}.norms`
    });
    const handleDeleteNorm = (index) => {
        remove(index);
    };
    const expandNorms = () => {
        append({ unit: 1, value: '', date: null });
    };
    return (
        <Grid container spacing={1}>
            {!loading && (
                <>
                    {fields.map((field, index) => ({ ...field, _index: index }))
                        .sort((first, second) => dayjs(second?.date) + dayjs(first?.date))
                        .map((field) => (
                            <Grid container item key={field?._index} spacing={1}>
                                <Grid container item xs={3}>
                                    <MeasureUnits
                                        control={control}
                                        name={`ingredients.${ind}.norms.${field?._index}.unit`}
                                        options={measureUnits}
                                        readOnly={readOnly}
                                    />
                                </Grid>
                                <Grid container item xs={4}>
                                    <Controller
                                        control={control}
                                        name={`ingredients.${ind}.norms.${field?._index}.value`}
                                        render={({ field: fieldValue }) => (
                                            <MoneyTextField
                                                textFieldParams={{
                                                    label: intl.formatMessage({ id: messages.value }),
                                                    variant: 'outlined',
                                                    fullWidth: true,
                                                    disabled: readOnly
                                                }}
                                                {...fieldValue}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid container item xs={4}>
                                    <Controller
                                        name={`ingredients.${ind}.norms.${field?._index}.date`}
                                        control={control}
                                        defaultValue={null}
                                        render={({ field: fieldDate }) => (
                                            <CustomDatePicker
                                                disabled={readOnly}
                                                disableToolbar
                                                inputFormat={dateFormat.format}
                                                toolbarTitle='Od'
                                                label={intl.formatMessage({ id: messages.date })}
                                                showTodayButton
                                                fullWidth
                                                okLabel={intl.formatMessage({ id: messages.dateOkButton })}
                                                cancelLabel={intl.formatMessage({ id: messages.dateCancelButton })}
                                                todayLabel={intl.formatMessage({ id: messages.dateTodayButton })}
                                                renderInput={(params) => <TextField {...params} />}
                                                {...fieldDate}
                                            />
                                        )}
                                    />
                                </Grid>
                                {
                                    !readOnly && (
                                        <Grid item xs={12} sm={1}>
                                            <IconButton
                                                className={classes.button}
                                                onClick={
                                                    () => { handleDeleteNorm(field?._index); }
                                                }
                                                size='large'
                                            >
                                                <RemoveIcon fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        ))}
                </>
            )}
            {
                !readOnly && (
                    <Grid item xs={12} sm={12}>
                        <IconButton
                            onClick={expandNorms}
                            color='primary'
                            component='span'
                            className={classes.addButton}
                            size='large'
                        >
                            <Add />
                        </IconButton>
                    </Grid>
                )
            }
        </Grid>
    );
}

Norms.propTypes = {
    ind: PropTypes.number.isRequired,
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool
};

Norms.defaultProps = {
    readOnly: false
};

export default Norms;
