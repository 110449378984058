import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Snackbar } from '@mui/material';
import { useBus, useListener } from 'react-bus';
import { useIntl } from 'react-intl';
import config from './formConfig';

const messages = {
    currentSelection: { id: 'app.addInvoice.currentSelection' }
};

function FormPickerControl({ children, onChange }) {
    const bus = useBus();
    const intl = useIntl();
    const [selection, setSelection] = useState('');
    const [pickerList, setPickerList] = useState([]);
    const [picker, setPicker] = useState({});
    const [pickerPos, setPickerPos] = useState(-1);

    useListener(config.bus.selection, (sel) => setSelection(sel));

    useListener(config.bus.keydown, (obj) => {
        if (!picker.name) return;
        const { key, shiftKey } = obj;
        switch (key) {
            case 'Escape':
                setPickerPos(-1);
                setSelection('');
                bus.emit(config.bus.pickerChange, '');
                break;
            case 'x':
                onChange({ ...picker, value: selection });
                break;
            case 'Tab':
                obj.prevent();
                if (shiftKey && pickerPos > 0) {
                    setPickerPos(pickerPos - 1);
                } else if (!shiftKey && pickerPos < pickerList.length - 1) {
                    setPickerPos(pickerPos + 1);
                }
                break;
            default:
        }
    });

    useEffect(() => {
        if (pickerPos < 0 || pickerPos >= pickerList.length) {
            setPicker({});
            bus.emit(config.bus.pickerChange, '');
        } else {
            const newPicker = pickerList[pickerPos];
            setPicker(newPicker);
            bus.emit(config.bus.pickerChange, newPicker.name);
        }
    }, [pickerPos]);

    const addPicker = (name) => {
        const pickers = pickerList;
        const exists = find(pickerList, ['name', name]);
        if (!exists) {
            const position = pickers.length;
            pickers.push({ name });
            setPickerList(pickers);
            return position;
        }
        return -1;
    };

    useListener(config.bus.click, (name) => {
        if (picker.name === name) {
            setPickerPos(-1);
            setPicker({});
            setSelection('');
            bus.emit(config.bus.pickerChange, '');
            return;
        }
        let pos = pickerList.findIndex((item) => item.name === name);
        if (pos < 0) {
            pos = addPicker(name);
        }
        setPickerPos(pos);
        bus.emit(config.bus.pickerChange, name);
    });

    useListener(config.bus.init, addPicker);

    useEffect(() => {
        bus.emit(config.bus.ready);
    }, []);

    return (
        <>
            {children}
            <Snackbar
                name='selection-picker'
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={selection.length && picker.name}
                message={`${intl.formatMessage({ id: messages.currentSelection.id })}: ${selection}`}
            />
        </>
    );
}

FormPickerControl.propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func
};

FormPickerControl.defaultProps = {
    children: null,
    onChange: () => {}
};

export default FormPickerControl;
