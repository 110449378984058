const errors = {
    duplicatedFile: 1,
    filesLimitAlmostReached: 2,
    ftpInvalidPath: 3,
    ftpInvalidAuth: 4,
    ftpInvalidAddr: 5,
    cannotPreparePreview: 6,
    unknownFiletype: 7,
    emptyTitle: 8,
    bankAccountNotFound: 9
};

export const statuses = {
    added: 'added',
    notParsedCorrectly: 'notParsedCorrectly',
    alreadyAdded: 'alreadyAdded',
    error: 'error'
};

export const responses = {
    parsed: 1,
    added: 2,
    error: 3,
    duplicateFound: 4
};

export default errors;
