import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import { saveAs } from 'file-saver';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DocumentPdf from './DocumentPdf';
import ImagePreview from './ImagePreview';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 'auto',
        padding: theme.spacing(2),
        height: '100%',
        minHeight: '76vh'
    },
    noFile: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    container: {
        padding: 0
    },
    slider: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    title: {
        overflow: 'auto',
        whiteSpace: 'nowrap'
    }
}));
const messages = {
    preview: { id: 'app.documentPreview.preview' },
    filesPage: { id: 'app.documentPreview.filesPage' },
    noFile: { id: 'app.documentPreview.noFile' },
    fileNotExist: { id: 'app.documentPreview.fileNotExist' },
    errorOccurs: { id: 'app.documentPreview.errorOccurs' },
    download: { id: 'app.documentPreview.download' }
};
const defaultScale = 140;

function DocumentsPreview({
    file,
    filesNumber,
    fileNumber,
    setFileNumber,
    className

}) {
    const style = useStyles();
    const [scale, setScale] = useState(defaultScale);

    const image = useRef();

    const downloadFile = (data) => {
        saveAs(data.file, data.name);
    };

    const fitImage = (imgCont, offset = 1) => {
        const deltaTime = 10;
        const increment = 20;
        setTimeout(() => {
            if (imgCont.scrollWidth > imgCont.clientWidth) {
                setScale(scale - offset);
                setTimeout(() => fitImage(imgCont, offset + increment), deltaTime);
            }
        }, deltaTime);
    };
    useEffect(() => {
        if (image && image.current) {
            image.current.style.width = `${scale}%`;
            image.current.style.height = 'auto';
        }
    }, [scale]);
    useEffect(() => {
        setScale(defaultScale);
        if (image && image.current) {
            fitImage(image.current.parentElement);
        }
    }, [file]);

    const bottomPanel = () => (
        <div>
            <div>
                <Button onClick={() => {
                    if (fileNumber > 0) {
                        setFileNumber(fileNumber - 1);
                    }
                }}
                >
                    {'<'}
                </Button>
                <span>
                    <FormattedMessage
                        id={messages.filesPage.id}
                        values={{
                            page: fileNumber + 1,
                            pages: filesNumber
                        }}
                    />
                </span>
                <Button onClick={() => {
                    if (fileNumber + 1 < filesNumber) {
                        setFileNumber(fileNumber + 1);
                    }
                }}
                >
                    {'>'}
                </Button>
            </div>

            <div className={style.slider}>
                <Slider
                    defaultValue={defaultScale}
                    valueLabelDisplay='auto'
                    step={10}
                    marks
                    min={50}
                    max={200}
                    value={scale}
                    onChange={(event, val) => {
                        setScale(val);
                    }}
                />
                {file && (
                    <Button
                        onClick={() => downloadFile(file)}
                        fullWidth
                        variant='contained'
                        color='secondary'
                    >
                        <FormattedMessage
                            id={messages.download.id}
                        />
                    </Button>
                )}
            </div>
        </div>
    );

    const showInfo = (id) => (
        <div className={style.noFile}>
            <FormattedMessage
                id={id}
            />
        </div>
    );

    const getBottomPanel = () => {
        if (file && file.file) {
            return bottomPanel();
        } if (!file) {
            return showInfo(messages.noFile.id);
        } if (!file?.file && !file?.error) {
            return showInfo(messages.fileNotExist.id);
        }
        return showInfo(messages.errorOccurs.id);
    };

    return (
        <Container className={style.container}>
            <Paper className={Object.keys(className).length > 0 ? className : style.paper}>
                <Typography component='h1' variant='h5' className={style.title}>
                    {file && file.name}
                </Typography>
                {file && file.file?.type === 'application/pdf' && (
                    <DocumentPdf
                        file={file.file}
                        scale={scale}
                        fitImage={fitImage}
                    />
                )}
                {file && file.file?.type.includes('image/') ? (
                    <ImagePreview
                        file={file.file}
                        scale={scale}
                        fitImage={fitImage}
                    />
                ) : null}
                {
                    getBottomPanel()
                }

            </Paper>
        </Container>
    );
}

DocumentsPreview.propTypes = {
    file: PropTypes.object,
    filesNumber: PropTypes.number,
    fileNumber: PropTypes.number,
    setFileNumber: PropTypes.func,
    className: PropTypes.object
};
DocumentsPreview.defaultProps = {
    file: undefined,
    filesNumber: 1,
    fileNumber: 1,
    setFileNumber: () => {},
    className: {}
};

export default DocumentsPreview;
