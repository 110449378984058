import { deepOrange } from '@mui/material/colors';

export const lightTheme = {
    palette: {
        mode: 'light',
        primary: {
            main: deepOrange[700]
        },
        secondary: {
            main: '#0277bd'
        }
    }
};

export const darkTheme = {
    palette: {
        mode: 'dark',
        primary: {
            main: deepOrange[900]
        },
        secondary: {
            main: '#388e3c'
        }
    }
};

const getTheme = (theme) => ({
    palette: {
        mode: theme ? 'dark' : 'light',
        ...(theme
            ? {
                // dark mode
                primary: {
                    main: deepOrange[900]
                },
                secondary: {
                    main: '#388e3c'
                }
            }
            : {
                // light mode
                primary: {
                    main: deepOrange[700]
                },
                secondary: {
                    main: '#0277bd'
                }
            })
    }
});
export default getTheme;
