import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHotkeyListener } from '../../Hotkeys/Hotkeys';
import formatBankAccount from '../../../../lib/formatBankAccount';

const messages = {
    note: { id: 'app.bankStatement.note' },
    none: { id: 'app.bankStatement.none' },
    entity: { id: 'app.bankStatement.entity' },
    deleteFile: { id: 'app.addInvoice.delete' },
    confirm: { id: 'app.bankStatement.confirm' },
    filesPage: { id: 'app.documentPreview.filesPage' },
    bankAccount: { id: 'app.bankStatement.bankAccount' },
    tooltipUseArrowLeft: { id: 'app.bankStatement.tooltipUseArrowLeft' },
    tooltipUseArrowRight: { id: 'app.bankStatement.tooltipUseArrowRight' },
    info: { id: 'app.bankStatement.info' }
};

function CustomToolbar({
    fileIndex,
    setFileIndex,
    statements,
    metadata,
    deleteFile,
    confirmStatement,
    isLoading,
    multiple
}) {
    const intl = useIntl();
    const { entityName, entityBankNumber } = metadata || {};

    const prevent = ['INPUT'];
    useHotkeyListener('bankSt:ArrowLeft', () => {
        const { tagName } = document.activeElement;
        if (fileIndex > 0 && !prevent.includes(tagName)) {
            setFileIndex(fileIndex - 1);
        }
    });
    useHotkeyListener('bankSt:ArrowRight', () => {
        const { tagName } = document.activeElement;
        if (fileIndex + 1 < statements.length && !prevent.includes(tagName)) {
            setFileIndex(fileIndex + 1);
        }
    });

    const renderNone = (
        <span style={{ opacity: 0.5 }}>
            <FormattedMessage id={messages.none.id} />
        </span>
    );

    const changeFileByOffset = (offset) => () => {
        if (fileIndex + offset >= 0 && fileIndex + offset < statements.length) {
            setFileIndex(fileIndex + offset);
        }
    };

    return (
        <GridToolbarContainer>
            <Grid container spacing={3}>
                <Grid item xs>
                    <Alert severity='warning'>
                        <FormattedMessage id={messages.note.id} />
                    </Alert>
                </Grid>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                                <FormattedMessage id={messages.entity.id} />
                                :&nbsp;
                                {entityName || renderNone}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h6' component='h6'>
                                <FormattedMessage id={messages.bankAccount.id} />
                                :&nbsp;
                                {formatBankAccount(entityBankNumber) || renderNone}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {multiple ? (
                                <>
                                    <Button onClick={deleteFile}>
                                        <FormattedMessage
                                            id={messages.deleteFile.id}
                                        />
                                    </Button>
                                    <Tooltip title={intl.formatMessage(messages.tooltipUseArrowLeft)} disableInteractive>
                                        <Button onClick={changeFileByOffset(-1)}>
                                            {'<'}
                                        </Button>
                                    </Tooltip>
                                    <div style={{ display: 'inline' }}>
                                        <FormattedMessage
                                            id={messages.filesPage.id}
                                            values={{
                                                page: fileIndex + 1,
                                                pages: statements.length
                                            }}
                                        />
                                    </div>
                                    <Tooltip title={intl.formatMessage(messages.tooltipUseArrowRight)} disableInteractive>
                                        <Button onClick={changeFileByOffset(1)}>
                                            {'>'}
                                        </Button>
                                    </Tooltip>
                                </>
                            ) : null}
                            <Button onClick={confirmStatement} disabled={isLoading}>
                                <FormattedMessage
                                    id={messages.confirm.id}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Alert severity='info'>
                        <FormattedMessage id={messages.info.id} />
                    </Alert>
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

CustomToolbar.propTypes = {
    fileIndex: PropTypes.number.isRequired,
    setFileIndex: PropTypes.func.isRequired,
    statements: PropTypes.array.isRequired,
    metadata: PropTypes.object.isRequired,
    deleteFile: PropTypes.func.isRequired,
    confirmStatement: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    multiple: PropTypes.bool.isRequired
};

export default CustomToolbar;
