import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import React, { useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import FormPickerMaster from '../../../common/FormPicker/FormPickerMaster';
import { HotkeyProvider } from '../../../common/Hotkeys/Hotkeys';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import FormFileManager, { prepareFilesForUpload, useFormFilesState } from '../../../common/FormFileManager/FormFileManager';
import CorrespondenceForm from './CorrespondenceForm';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: '95vw',
        display: 'flex'
    },
    drawerData: {
        width: '40%',
        padding: 32
    },
    preview: {
        width: '50%',
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    formContainer: {
        width: '50%',
        margin: theme.spacing(2)
    }
}));
const messages = {
    success: { id: 'app.correspondence.update.success' },
    error: { id: 'app.correspondence.update.error' }
};
function SidePanel({
    show, onClose, data, mutate
}) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const intl = useIntl();

    const fileManagerRef = useRef();

    const {
        setFilesVal, getFilesVal, filesField, setFilesField
    } = useFormFilesState();

    const handleOnClose = () => {
        fileManagerRef.current.flushFiles();
        onClose();
    };

    useEffect(() => {
        if (data?.selectedData?.files) {
            setFilesVal('files', data.selectedData.files);
            setFilesField(['files']);
        }
    }, [data]);

    const updateCorrespondence = async (newData) => {
        const filesData = prepareFilesForUpload(getFilesVal('files'));

        const preparedData = {
            data: {
                ...newData,
                filesMetadata: filesData.metadata
            }
        };
        const response = await RestRequestsHelper.updateCorrespondence(newData.id, preparedData, filesData.files);
        if (response.data.status === 'success') {
            enqueueSnackbar(intl.formatMessage({ id: messages.success.id }), { variant: 'success' });
            handleOnClose();
            mutate();
        } else {
            enqueueSnackbar(intl.formatMessage({ id: messages.error.id }), { variant: 'error' });
        }
    };
    const handleDelete = async () => {
        await RestRequestsHelper.deleteCorrespondence(data.selectedData.id);
        onClose();
        mutate();
    };

    return (
        <Drawer anchor='right' open={show} onClose={handleOnClose}>
            <Paper className={classes.drawer}>
                <div className={classes.formContainer}>
                    <HotkeyProvider>
                        <FormPickerMaster enable>
                            <CorrespondenceForm
                                edit
                                editData={data.selectedData}
                                onSubmit={(newData) => updateCorrespondence(newData)}
                                onDelete={handleDelete}
                            />
                        </FormPickerMaster>
                    </HotkeyProvider>
                </div>

                <div className={classes.preview}>
                    <FormPickerMaster enable>
                        <HotkeyProvider>
                            <FormFileManager
                                setValue={setFilesVal}
                                getValues={getFilesVal}
                                setCurrentField={setFilesField}
                                currentField={filesField}
                                ref={fileManagerRef}
                            />
                        </HotkeyProvider>
                    </FormPickerMaster>
                </div>
            </Paper>
        </Drawer>
    );
}

SidePanel.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    data: PropTypes.object.isRequired,
    mutate: PropTypes.func
};

SidePanel.defaultProps = {
    onClose: () => {},
    mutate: () => {}
};
export default SidePanel;
