/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import useDisplaySnackbar from '../../../lib/displaySnackbar';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import userRoles from '../../../constants/UserRoles';

const messages = {
    submit: { id: 'app.massRemovalToolbar.submit' },
    cancel: { id: 'app.massRemovalToolbar.cancel' },
    deleteSuccess: { id: 'app.massRemovalToolbar.massDeleteSuccess' },
    deleteError: { id: 'app.massRemovalToolbar.massDeleteError' },
    massRemovalByFilter: { id: 'app.massRemovalToolbar.massRemovalByFilter' },
    massRemoval: { id: 'app.massRemovalToolbar.massRemoval' },
    massRemovalConfimation: { id: 'app.massRemovalToolbar.massRemovalConfirm' }
};

type userType = {
  [key: string]: any
}

type ToolbarMassRemovalProps = {
    selectedRows: Array<any>,
    setSelectedRows: Function,
    setOption: Function,
    mutate: Function,
    filterData: Object,
    user: userType,
    deleteRecordFn: Function,
    deleteRecordsFn: Function,
    defaultOptionId: Number
}

function ToolbarMassRemoval({
    selectedRows,
    setSelectedRows,
    setOption,
    mutate,
    filterData,
    user,
    deleteRecordFn,
    deleteRecordsFn,
    defaultOptionId = 0
} : ToolbarMassRemovalProps) {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });

    const [loading, setLoading] = useState(false);
    const [isRemoveDialog, setIsRemoveDialog] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState<string|null>(null);

    const handleExportSelect = (type: Number) => () => {
        if (type === defaultOptionId) {
            setSelectedRows([]);
        }
        setOption(type);
    };

    const handleMassRemoval = async () => {
        setLoading(true);
        for (let i = 0; i < selectedRows.length; i += 1) {
            const id = selectedRows[i];

            const { error, data } = await deleteRecordFn(id);
            if (error || data.status === 'error') {
                displaySnackbar('error', messages.deleteError);
                setLoading(false);
                return;
            }
        }
        setLoading(false);
        mutate();
        displaySnackbar('success', messages.deleteSuccess);
        handleExportSelect(defaultOptionId)();
    };

    const handleMassRemovalByFilter = async () => {
        setLoading(true);
        const { error, data } = await deleteRecordsFn(filterData);
        if (error || data.status === 'error') {
            displaySnackbar('error', messages.deleteError);
            setLoading(false);
        }
        setLoading(false);
        mutate();
        displaySnackbar('success', messages.deleteSuccess);
        handleExportSelect(defaultOptionId)();
    };

    const handleConfirm = () => {
        switch (selectedMethod) {
            case 'removalByFilter':
                handleMassRemovalByFilter();
                break;
            case 'removalBySelection':
                handleMassRemoval();
                break;
            default:
                break;
        }
    };
    const handleRemove = (method: string) => {
        setSelectedMethod(method);
        setIsRemoveDialog(true);
    };

    return (
        <>
            <ConfirmDialog
                isOpen={isRemoveDialog}
                handleClose={() => setIsRemoveDialog(false)}
                handleRemove={handleConfirm}
                dialogTitle={messages.massRemoval.id}
                dialogText={messages.massRemovalConfimation.id}
                isLoading={loading}
            />

            {user?.role === userRoles.admin && (
                <LoadingButton color='primary' size='small' onClick={() => handleRemove('removalByFilter')} loading={loading}>
                    {intl.formatMessage(messages.massRemovalByFilter)}
                </LoadingButton>
            )}
            <LoadingButton color='primary' size='small' onClick={() => handleRemove('removalBySelection')} disabled={!selectedRows.length} loading={loading}>
                {intl.formatMessage(messages.submit)}
            </LoadingButton>
            <LoadingButton color='primary' size='small' onClick={handleExportSelect(defaultOptionId)} disabled={loading}>
                {intl.formatMessage(messages.cancel)}
            </LoadingButton>
        </>
    );
}

export default ToolbarMassRemoval;
