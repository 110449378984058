import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';
import { Box, CircularProgress, TextField } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { useHotkeyListener } from '../Hotkeys/Hotkeys';
import FormPickerSelector from '../FormPicker/FormPickerSelector';
import './DocumentPdf.css';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '500px'
    },
    preview: {
        width: '100%',
        height: '76vh',
        overflow: 'scroll'
    },
    paper: {
        margin: 'auto',
        padding: theme.spacing(2),
        height: '100%',
        minHeight: '76vh'
    },
    addButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    container: {
        padding: 0
    },
    slider: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    pageControls: {
        position: 'relative',
        width: 'max-content',
        left: '50%',
        background: theme.palette.background.default,
        transform: 'translate(-50%, -65px)',
        transition: 'opacity ease-in-out 0.2s',
        boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
        borderRadius: '4px'
    },
    controlsContainer: {
        height: 0
    },
    pdfWrapper: {
        overflow: 'scroll'
    }
}));
const messages = {
    preview: {
        id: 'app.documentPreview.preview'
    },
    loadFile: {
        id: 'app.documentPreview.loadDocument'
    },
    deleteBtn: {
        id: 'app.documentPreview.deleteFile'
    },
    filesPage: {
        id: 'app.documentPreview.filesPage'
    },
    pdfPage: {
        id: 'app.documentPreview.pdfPage'
    },
    docName: { id: 'app.addInvoice.docName' },
    download: { id: 'app.documentPreview.download' }
};
const defaultScale = 140;

function DocumentPreview({
    file, onLoadFile,
    filesNumber,
    fileNumber,
    setFileNumber,
    onDeleteFile,
    manyFiles,
    children,
    topChildren,
    page,
    disablePageChange,
    disableActions,
    currentFileName,
    handleChangeCurrentFileName,
    allowEditingFileName,
    showFileName,
    loading,
    allowDownloading
}) {
    const style = useStyles();
    const [scale, setScale] = useState(defaultScale);

    const [numOfPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const fileUrl = file ? URL.createObjectURL(file) : null;
    const image = useRef();

    const intl = useIntl();

    useEffect(() => {
        if (image && image.current) {
            image.current.style.width = `${scale}%`;
            image.current.style.height = 'auto';
        }
    }, [scale]);

    const downloadFile = () => {
        saveAs(file, currentFileName);
    };

    const fitImage = (imgCont, offset = 1, previousWidth = 0) => {
        const deltaTime = 10;
        const increment = 20;
        setTimeout(() => {
            // If the image cannot shrink anymore - stop
            if (previousWidth === imgCont.scrollWidth) {
                return;
            }
            if (imgCont.scrollWidth > imgCont.clientWidth) {
                setScale(scale - offset);
                setTimeout(() => fitImage(imgCont, offset + increment, imgCont.scrollWidth), deltaTime);
            }
        }, deltaTime);
    };

    useEffect(() => {
        setScale(defaultScale);
        if (image && image.current) {
            fitImage(image.current.parentElement);
        }
        setPageNumber(1);
    }, [file]);

    const addButton = () => (
        (!disableActions && !loading) && (
            <Button
                component='label'
            >
                <FormattedMessage id={messages.loadFile.id} />
                <input
                    type='file'
                    hidden
                    accept='application/pdf,image/jpeg'
                    onChange={onLoadFile}
                    multiple
                />
            </Button>
        )
    );
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const bottomPanel = () => {
        if (manyFiles) {
            return (
                <div>
                    <div>
                        {
                            !disableActions && (
                                <Button onClick={() => {
                                    onDeleteFile(fileNumber);
                                }}
                                >
                                    <FormattedMessage
                                        id={messages.deleteBtn.id}
                                    />
                                </Button>
                            )
                        }
                        <Button onClick={() => {
                            if (fileNumber > 0) {
                                setFileNumber(fileNumber - 1);
                            }
                        }}
                        >
                            {'<'}
                        </Button>
                        <span>
                            <FormattedMessage
                                id={messages.filesPage.id}
                                values={{
                                    page: fileNumber + 1,
                                    pages: filesNumber
                                }}
                            />
                        </span>
                        <Button onClick={() => {
                            if (fileNumber + 1 < filesNumber) {
                                setFileNumber(fileNumber + 1);
                            }
                        }}
                        >
                            {'>'}
                        </Button>

                        {addButton()}

                    </div>

                    <div className={style.slider}>
                        <Slider
                            defaultValue={defaultScale}
                            valueLabelDisplay='auto'
                            step={10}
                            marks
                            min={50}
                            max={200}
                            value={scale}
                            onChange={(event, val) => {
                                setScale(val);
                            }}
                        />
                    </div>
                </div>
            );
        } if (numOfPages) {
            return (
                <div>
                    <div>
                        {children}
                    </div>

                    <div className={style.slider}>
                        <Slider
                            defaultValue={defaultScale}
                            valueLabelDisplay='auto'
                            step={10}
                            marks
                            min={50}
                            max={200}
                            value={scale}
                            onChange={(event, val) => {
                                setScale(val);
                            }}
                        />
                    </div>
                </div>
            );
        }

        return null;
    };

    const getPage = () => page || pageNumber;
    let pageElement = null;

    const prevent = ['INPUT', 'TEXTAREA'];
    useHotkeyListener('ArrowLeft', () => {
        const { tagName } = document.activeElement;
        if (fileNumber > 0 && !prevent.includes(tagName)) {
            setFileNumber(fileNumber - 1);
        }
    });
    useHotkeyListener('ArrowRight', () => {
        const { tagName } = document.activeElement;
        if (fileNumber + 1 < filesNumber && !prevent.includes(tagName)) {
            setFileNumber(fileNumber + 1);
        }
    });

    return (
        <Container className={style.container}>
            <FormPickerSelector>
                <Paper className={style.paper}>
                    <Typography component='h1' variant='h5'>
                        <FormattedMessage
                            id={messages.preview.id}
                        />
                    </Typography>
                    {topChildren}
                    {(showFileName && !loading && file) && (
                        <Box sx={{ display: 'flex', my: 2, gap: 1 }}>
                            <TextField
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                required
                                label={
                                    intl.formatMessage(messages.docName)
                                }
                                value={currentFileName || ''}
                                onChange={handleChangeCurrentFileName}
                                disabled={!allowEditingFileName}
                                sx={{ m: 0 }}
                            />
                            <Button
                                variant='outlined'
                                startIcon={<DownloadIcon />}
                                sx={{ minWidth: 'fit-content' }}
                                onClick={downloadFile}
                                disabled={!allowDownloading}
                            >
                                <FormattedMessage id={messages.download.id} />
                            </Button>
                        </Box>
                    )}
                    {loading && (
                        <CircularProgress sx={{ m: 1 }} />
                    )}
                    {file && file.type === 'application/pdf' ? (
                        <div>
                            <Document
                                file={file}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <div className={style.pdfWrapper}>
                                    <Page
                                        scale={scale / 100}
                                        pageNumber={getPage()}
                                        renderTextLayer
                                        inputRef={(ref) => { pageElement = ref; }}
                                        onLoadSuccess={() => (pageElement ? fitImage(pageElement.parentElement) : null)}
                                    />
                                </div>
                                {!disablePageChange ? (
                                    <div className={style.controlsContainer}>
                                        <div className={style.pageControls}>
                                            <Button onClick={() => {
                                                if (pageNumber > 1) {
                                                    setPageNumber(pageNumber - 1);
                                                }
                                            }}
                                            >
                                                {'<'}
                                            </Button>
                                            <span>
                                                <FormattedMessage
                                                    id={messages.pdfPage.id}
                                                    values={{
                                                        page: pageNumber,
                                                        pages: numOfPages
                                                    }}
                                                />
                                            </span>
                                            <Button onClick={() => {
                                                if (pageNumber < numOfPages) {
                                                    setPageNumber(pageNumber + 1);
                                                }
                                            }}
                                            >
                                                {'>'}
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}
                            </Document>
                        </div>
                    ) : null}
                    {file && file.type.includes('image/') ? (
                        <div className={style.root}>
                            <div className={style.preview}>
                                <img ref={image} alt={file.name} src={fileUrl} />
                            </div>
                        </div>

                    ) : null}
                    {
                        !file ? (
                            <div className={style.addButton}>
                                {addButton()}
                            </div>
                        )
                            : bottomPanel()
                    }

                </Paper>
            </FormPickerSelector>
        </Container>
    );
}

DocumentPreview.propTypes = {
    file: PropTypes.object,
    onLoadFile: PropTypes.func,
    filesNumber: PropTypes.number,
    fileNumber: PropTypes.number,
    setFileNumber: PropTypes.func,
    onDeleteFile: PropTypes.func,
    manyFiles: PropTypes.bool,
    children: PropTypes.element,
    topChildren: PropTypes.element,
    page: PropTypes.number,
    disablePageChange: PropTypes.bool,
    disableActions: PropTypes.bool,
    currentFileName: PropTypes.string,
    handleChangeCurrentFileName: PropTypes.func,
    allowEditingFileName: PropTypes.bool,
    showFileName: PropTypes.bool,
    loading: PropTypes.bool,
    allowDownloading: PropTypes.bool
};
DocumentPreview.defaultProps = {
    file: undefined,
    onLoadFile: () => {},
    filesNumber: 1,
    fileNumber: 1,
    setFileNumber: () => {},
    onDeleteFile: () => {},
    manyFiles: false,
    children: null,
    topChildren: null,
    page: null,
    disablePageChange: false,
    disableActions: false,
    currentFileName: null,
    handleChangeCurrentFileName: () => {},
    allowEditingFileName: false,
    showFileName: false,
    loading: false,
    allowDownloading: true
};

export default DocumentPreview;
