const DURATION = 20 * 1000;

const useDisplaySnackbar = ({ enqueueSnackbar, intl }) => {
    if (!enqueueSnackbar || !intl) {
        throw Error('useDisplaySnackbar did not receive proper arguments. Expected object config');
    }
    return (variant, message, data = {}, duration = DURATION) => {
        if (typeof message === 'string' && (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development')) {
            throw Error('Please use locale to determine message. Using strings is for debug only.');
        }
        // For debugging purposes; use string to display some debug message
        const msg = typeof message === 'string' ? message : intl.formatMessage(message, data);
        enqueueSnackbar(msg, { variant, autoHideDuration: duration });
    };
};

export default useDisplaySnackbar;
