import React, { useEffect, createRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import GetAppIcon from '@mui/icons-material/GetApp';
import { CSVLink } from 'react-csv';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const messages = {
    exportAll: { id: 'app.export.exportAll' }
};

function CSVExportData({
    onClick, download, headers, filename, data, sx
}) {
    const intl = useIntl();
    const downloadRef = createRef();
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
        setLoading(true);
        onClick();
    };
    useEffect(() => {
        setLoading(false);
        if (download) {
            downloadRef.current.link.click();
        }
    }, [download]);
    const escapeChars = (arr) => arr.map((item) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
            newItem[key] = typeof item[key] === 'string' ? item[key].replaceAll(/"/g, '').replaceAll(/'/g, '') : item[key];
        });
        return newItem;
    });
    return (
        <>
            <LoadingButton
                startIcon={<GetAppIcon />}
                color='primary'
                size='small'
                onClick={handleDownload}
                sx={{ mt: '5px', ...sx }}
                loading={loading}
            >
                {intl.formatMessage(messages.exportAll)}
            </LoadingButton>
            {download && (
                <CSVLink headers={headers} filename={filename} data={escapeChars(data)} ref={downloadRef} enclosingCharacter='"' />
            )}
        </>
    );
}
CSVExportData.propTypes = {
    onClick: PropTypes.func.isRequired,
    download: PropTypes.bool.isRequired,
    headers: PropTypes.array,
    filename: PropTypes.string,
    data: PropTypes.array,
    sx: PropTypes.object
};

CSVExportData.defaultProps = {
    headers: [],
    filename: 'data.csv',
    data: [],
    sx: {}
};
export default CSVExportData;
