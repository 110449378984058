import React from 'react';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import ContractorForm from '../../../common/ContractorForm/ContractorForm';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../lib/displaySnackbar';

const messages = {
    addContractorSuccess: { id: 'app.contractors.addContractorSuccess' },
    addContractorError: { id: 'app.contractors.addContractorError' }
};

function AddContractors() {
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const displaySnackbar = useDisplaySnackbar({ enqueueSnackbar, intl });
    const handleSubmitForm = async (data) => {
        const result = await RestRequestsHelper.addContractor(data);
        if (result?.data?.success) {
            displaySnackbar('success', messages.addContractorSuccess);
            return true;
        }
        displaySnackbar('error', messages.addContractorError);
        return false;
    };

    return (
        <Grid container justifyContent='center'>
            <Paper sx={{ p: '0 30px' }}>
                <ContractorForm onSubmit={handleSubmitForm} />
            </Paper>
        </Grid>
    );
}

export default AddContractors;
