import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import WarningIcon from '@mui/icons-material/Warning';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

const messages = {
    warnNoSellerBankAccNumber: { id: 'app.invoices.warnNoSellerBankAccNumber' },
    warnNoBuyerBankAccNumber: { id: 'app.invoices.warnNoBuyerBankAccNumber' }
};

const useStyles = makeStyles((theme) => ({
    warningIcon: {
        verticalAlign: 'middle'
    },
    warningIconOrange: {
        fill: 'orange'
    },
    warningIconRed: {
        fill: 'red'
    },
    warningPopup: {
        padding: theme.spacing(1),
        margin: theme.spacing(1)
    }
}));

function ExportWarning({ value }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const getValues = () => {
        const newValue = { ...value };
        if (!newValue.sellerAcc) {
            return [
                messages.warnNoSellerBankAccNumber.id,
                classes.warningIconOrange
            ];
        }
        if (!newValue.elixir) {
            return [
                messages.warnNoBuyerBankAccNumber.id,
                classes.warningIconRed
            ];
        }
        return [null, null];
    };

    const [message, icon] = getValues();

    return message || icon ? (
        <div>
            <WarningIcon
                aria-haspopup='true'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                className={[icon, classes.warningIcon].join(' ')}
            />
            <Popper
                id='mouse-over-popover'
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement='right'
            >
                <Paper className={classes.warningPopup}>
                    <FormattedMessage id={message} />
                </Paper>
            </Popper>
        </div>
    ) : '';
}

ExportWarning.propTypes = {
    value: PropTypes.object.isRequired
};

export default ExportWarning;
