import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Grid, Typography, Divider
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { PropTypes } from 'prop-types';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';
import useColumns from '../../../../Pages/Income/shared/hooks/useColumns';
import { paginationAllowedValues } from '../../../../../constants/pagination';
import convertColumnsToVisibilityModel from '../../../../../lib/convertColumnsToVisibilityModel';

const messages = {
    incomes: { id: 'app.contractors.incomes' },
    noData: { id: 'app.general.noData' }
};

function ContractorIncomes({ edit }) {
    const [loading, setLoading] = useState(false);
    const [incomes, setIncomes] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [count, setCount] = useState(0);

    const columns = useColumns({
        statuses,
        previewData: true
    });
    useEffect(() => {
        const getTasks = async () => {
            setLoading(true);
            const { data } = await RestRequestsHelper.getContractorIncomes(edit?.id, page, pageSize);
            setLoading(false);
            setIncomes(data?.rows || []);
            setCount(data?.count || 0);
        };
        getTasks();
    }, [page, pageSize]);

    useEffect(() => {
        RestRequestsHelper.getDocumentStatuses('mongo').then((value) => {
            setStatuses(value);
        });
    }, []);

    const handlePaginationModelChange = (data) => {
        setPageSize(data.pageSize);
        setPage(data.page);
    };

    const handleCellClick = (params) => {
        const { row } = params;
        window.open(`/income/browse?id=${row?.id}`);
    };

    return (
        <Grid item xs={12}>
            <Typography component='h2' variant='h6' align='left'>
                <FormattedMessage id={messages.incomes.id} />
            </Typography>
            <Divider />
            <Grid item xs={12} sx={{ mt: 1 }}>
                {incomes.length || loading > 0 ? (
                    <DataGrid
                        autoHeight
                        rows={incomes}
                        columns={columns}
                        onCellClick={handleCellClick}
                        paginationMode='server'
                        paginationModel={{ page, pageSize }}
                        pageSizeOptions={paginationAllowedValues}
                        rowCount={count}
                        onPaginationModelChange={handlePaginationModelChange}
                        loading={loading}
                        columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                    />
                ) : (
                    <Typography sx={{
                        border: '1px solid #616161', borderRadius: 1, pt: 1, pb: 1
                    }}
                    >
                        <FormattedMessage id={messages.noData.id} />
                    </Typography>
                )}
            </Grid>

        </Grid>
    );
}

ContractorIncomes.propTypes = {
    edit: PropTypes.object.isRequired
};

export default ContractorIncomes;
