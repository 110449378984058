import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { google, ics } from 'calendar-link';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useSnackbar } from 'notistack';
import { renderCellExpand } from '../../../../../common/DataGridCells/GridCellExpand';
import generateInitials from '../../../../../../lib/generateInitials';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import AdditionalOptionsColumn from '../../../../../common/AdditionalOptionsColumn/AdditionalOptionsColumn';

const messages = {
    name: { id: 'app.inspections.name' },
    hierarchy: { id: 'app.inspections.hierarchy' },
    concerns: { id: 'app.inspections.concerns' },
    type: { id: 'app.inspections.type' },
    leader: { id: 'app.inspections.leader' },
    producers: { id: 'app.inspections.producers' },
    validTo: { id: 'app.inspections.validTo' },
    addToCalendar: { id: 'app.inspections.addToCalendar' },
    share: { id: 'app.inspections.share' },
    parentInspectionTooltip: { id: 'app.inspections.parentInspectionTooltip' },
    subinspectionTooltip: { id: 'app.inspections.subinspectionTooltip' },
    delete: { id: 'app.inspections.delete' },
    confirmDeleteTitle: { id: 'app.inspections.confirmDeleteTitle' },
    confirmDeleteText: { id: 'app.inspections.confirmDeleteText' },
    deleteSuccess: { id: 'app.inspections.deleteSuccess' },
    copy: { id: 'app.inspections.copy' },
    copySuccess: { id: 'app.inspections.copySuccess' },
    author: { id: 'app.inspections.author' }
};

const useStyles = makeStyles((theme) => ({
    producersList: {
        paddingLeft: theme.spacing(4),
        margin: '7px 0'
    },
    hierarchyContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    subinspections: {
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

function createProducersList(producersArr, className) {
    const producersListItems = producersArr.map((producer) => <li>{producer.$name}</li>);
    return <ul className={className}>{producersListItems}</ul>;
}

function HierarchyCell({ subinspectionsNumber, parent }) {
    const intl = useIntl();
    const styles = useStyles();
    return (
        <div className={styles.hierarchyContainer}>
            {!!subinspectionsNumber && (
                <Tooltip title={intl.formatMessage({ id: messages.subinspectionTooltip.id })}>
                    <div className={styles.subinspections}>
                        {subinspectionsNumber}
                    </div>
                </Tooltip>
            ) }
            {
                parent && (
                    <Tooltip title={intl.formatMessage({ id: messages.parentInspectionTooltip.id })}>
                        <ArrowUpwardIcon />
                    </Tooltip>
                )
            }
        </div>
    );
}
HierarchyCell.propTypes = {
    subinspectionsNumber: PropTypes.number.isRequired,
    parent: PropTypes.string.isRequired
};

const AdditionalOptionsCell = React.memo(({ params, handleUpdate }) => {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const [err, setErr] = useState(false);

    const shareInspection = (resRow) => {
        window.navigator
            .share({
                title: `${resRow?.concernsUI} - ${resRow?.name}`,
                text: `${resRow?.concernsUI} - ${resRow?.name}`
            });
    };

    const copyInspection = async (resRow) => {
        const { data } = await RestRequestsHelper.copyInspection(resRow._id);
        if (data.success) {
            enqueueSnackbar(intl.formatMessage(messages.copySuccess), { variant: 'success' });
            handleUpdate();
        }
    };

    const checkIfWebShareApiSupported = () => 'share' in window.navigator;
    const defaultPolicyFn = () => true;

    const additionalOptions = [
        {
            title: intl.formatMessage(messages.addToCalendar, { name: 'Apple' }),
            type: 'calendar',
            fn: ics,
            policyFn: defaultPolicyFn
        },
        {
            title: intl.formatMessage(messages.addToCalendar, { name: 'Google' }),
            type: 'calendar',
            fn: google,
            policyFn: defaultPolicyFn
        },
        {
            title: intl.formatMessage(messages.share),
            type: 'share',
            fn: shareInspection,
            policyFn: checkIfWebShareApiSupported
        },
        {
            title: intl.formatMessage(messages.copy),
            type: 'copy',
            fn: copyInspection,
            policyFn: defaultPolicyFn
        }
    ];
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleEventDate = (dueDate) => {
        let date;
        if (dueDate) {
            date = new Date(dueDate);
            date.setHours(date.getHours() + 1);
        } else {
            date = new Date();
        }
        return date;
    };
    const onClick = (exportFn, type) => {
        try {
            setAnchorEl(null);
            const { api } = params;
            const row = api.getSelectedRows();
            const res = [];
            row.forEach((val, key) => {
                res.push({ region: key, value: val });
            });
            const resRow = res[0].value;

            if (type === 'calendar') {
                const event = {
                    title: `${resRow.name}`,
                    // description: resRow.,
                    start: handleEventDate(resRow.valid_to),
                    allDay: true,
                    location: resRow.concernsUI || ''
                };
                const exported = exportFn(event);
                if (exported.startsWith('http')) {
                    window.open(exported, '_blank');
                } else {
                    try {
                        const aElm = document.createElement('a');
                        aElm.setAttribute('download', event.title);
                        aElm.setAttribute('href', exported);
                        aElm.click();
                    } catch {
                        window.open(exported, '_blank');
                    }
                }
            } else {
                exportFn(resRow);
            }
        } catch {
            setErr(true);
        }
    };
    if (err) {
        return null;
    }
    return (
        <AdditionalOptionsColumn
            onIconClick={handleClick}
            onItemClick={onClick}
            options={additionalOptions}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
        />
    );
});

const useColumns = (handleUpdate) => {
    const intl = useIntl();
    const styles = useStyles();
    return [
        {
            field: 'subinspectionsNumber',
            headerName: intl.formatMessage(messages.hierarchy),
            width: 80,
            headerAlign: 'center',
            valueGetter: (params) => {
                const parent = params.row.parent_id;
                const subinspectionsNumber = params.value;
                return { parent, subinspectionsNumber };
            },
            sortable: false,
            renderCell: ({ value }) => HierarchyCell(value)
        },
        {
            field: 'concernsUI',
            headerName: intl.formatMessage(messages.concerns),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: ({ row }) => renderCellExpand({ data: row.concerns })
        },
        {
            field: 'name',
            headerName: intl.formatMessage(messages.name),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            renderCell: renderCellExpand
        },
        {
            field: 'type',
            headerName: intl.formatMessage(messages.type),
            width: 180,
            headerAlign: 'center',
            renderCell: ({ value }) => renderCellExpand({
                value: value?.value
            })
        },
        {
            field: 'leaderUI',
            headerName: intl.formatMessage(messages.leader),
            width: 140,
            headerAlign: 'center',
            renderCell: (params) => renderCellExpand(params, params?.row?.leader?.color, (val) => generateInitials(val))
        },
        {
            field: 'authorUI',
            headerName: intl.formatMessage(messages.author),
            width: 140,
            headerAlign: 'center',
            renderCell: (params) => renderCellExpand(params, params?.row?.author?.color, (val) => generateInitials(val))
        },
        {
            field: 'validToUI',
            headerName: intl.formatMessage(messages.validTo),
            width: 140,
            headerAlign: 'center',
            renderCell: renderCellExpand
        },
        {
            field: 'producers',
            headerName: intl.formatMessage(messages.producers),
            valueGetter: (params) => params.value.length,
            width: 120,
            headerAlign: 'center',
            sortable: false,
            renderCell: (value) => renderCellExpand({
                value: value.formattedValue.toString(),
                alt: createProducersList(value.row.producers, styles.producersList),
                showOnlyAlt: true
            })

        },
        {
            field: 'additionalOptions',
            headerName: ' ',
            width: 60,
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => <AdditionalOptionsCell params={params} handleUpdate={handleUpdate} />
        }
    ];
};

AdditionalOptionsCell.propTypes = {
    params: PropTypes.object.isRequired,
    handleUpdate: PropTypes.func.isRequired
};

export default useColumns;
