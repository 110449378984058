import Grid from '@mui/material/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import requestsMaxLimitValue from '../../../../../../constants/requests';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import { comboboxTypes } from '../../../../../common/Combobox/UniversalCombobox';
import SelectAllButton from '../../../../../common/SelectAllButton/SelectAllButton';
import UniversalComboboxWorkaround from '../../../../../common/Combobox/utils/UniversalComboboxWorkaround';

const messages = {
    contractorName: { id: 'app.contractors.contractorName' },
    dialogTitle: { id: 'app.generateCorrespondenceList.title' },
    confirm: { id: 'app.form.confirm' }
};

interface Communities {
    _id: string
}

interface GenerateCorrespondenceListDialogProps {
    communities: Communities[],
    onCommunitiesChange: Function
    onSubmit: Function
}

const useStyles = makeStyles((theme: Theme) => ({
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        verticalAlign: 'top'
    }
}));
function GenerateCorrespondenceListDialog({
    communities,
    onCommunitiesChange,
    onSubmit
}: GenerateCorrespondenceListDialogProps) {
    const history = useHistory();
    const intl = useIntl();
    const [isSelectingAll, setIsSelectingAll] = useState(false);
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const selectAll = async () => {
        setIsSelectingAll(true);
        const { data } = await RestRequestsHelper.getCommunities('', requestsMaxLimitValue);
        onCommunitiesChange(data);
        setIsSelectingAll(false);
    };

    const handleClose = () => {
        history.push('/');
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const data = {
            communities: communities.map((item: Communities) => item._id)
        };
        await onSubmit(data);
        setIsLoading(false);
    };
    return (
        <Dialog open onClose={handleClose} PaperProps={{ sx: { width: '530px' } }}>
            <DialogTitle>
                <FormattedMessage id={messages.dialogTitle.id} />
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={handleClose}
                    aria-label='close'
                    className={classes.close}
                    size='large'
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={12}>
                        <UniversalComboboxWorkaround
                            id='communities'
                            src={comboboxTypes.communities()}
                            label={intl.formatMessage(messages.contractorName)}
                            value={communities}
                            onChange={(val: string[]) => onCommunitiesChange(val)}
                            multiple
                            required
                            showColors
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectAllButton onSelectAll={selectAll} loading={isSelectingAll} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    id='generate'
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    <FormattedMessage id={messages.confirm.id} />
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

GenerateCorrespondenceListDialog.propTypes = {
    communities: PropTypes.array.isRequired,
    onCommunitiesChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};
export default GenerateCorrespondenceListDialog;
