import getCurrentDate from '../../../lib/getCurrentDate';

const defaultInvoice = () => ({
    contractor: '',
    documentNumber: '',
    buyer: [],
    seller: '',
    content: [{ title: null, value: '', description: '' }],
    dateOfDelivery: getCurrentDate(),
    dateOfInvoice: getCurrentDate(),
    dateOfPayment: getCurrentDate(),
    saleDate: getCurrentDate(),
    bankAccount: '',
    amount: '',
    isMediaInvoice: false,
    group: 4,
    setNames: [{ name: '1', pages: [] }],
    flowStatus: { id: 1, name: 'Wprowadzona / Do zatwierdzenia' }

});

export const defaultIncomeInvoice = () => ({
    contractor: '',
    documentNumber: '',
    buyer: [],
    seller: '',
    content: [{ title: null, value: '', description: '' }],
    dateOfDelivery: getCurrentDate(),
    dateOfInvoice: getCurrentDate(),
    dateOfPayment: getCurrentDate(),
    saleDate: getCurrentDate(),
    bankAccount: '',
    amount: '',
    isMediaInvoice: false,
    group: 4,
    setNames: [{ name: '1', pages: [] }],
    flowStatus: { id: 1, name: 'Wprowadzona / Do zatwierdzenia' },
    type: {
        id: 4, name: 'Rachunek', code: 'RH: ', document_kind: 1, is_premis_required: false, is_balance: false, $name: 'RH: Rachunek'
    },
    remarks: ''

});

export default defaultInvoice;
