import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFieldArray } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';
import RemoveIcon from '@mui/icons-material/Remove';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import userRoles from '../../../../../../../constants/UserRoles';
import dateFormat from '../../../../../../../constants/dateFormat';
import CustomDatePicker from '../../../../../../common/CustomDatePicker/CustomDatePicker';
import getCurrentDate from '../../../../../../../lib/getCurrentDate';

const messages = {
    value: 'app.infrastructures.numberOfPeople.value',
    date: 'app.infrastructures.numberOfPeople.date',
    dateOkButton: 'app.invoiceForm.OkButton',
    dateCancelButton: 'app.invoiceForm.CancelButton',
    dateTodayButton: 'app.invoiceForm.todayButton',
    verified: 'app.infrastructures.numberOfPeople.verified',
    author: 'app.infrastructures.numberOfPeople.author'
};

const useStyles = makeStyles((theme) => ({
    accordion: {
        width: '100%'
    },
    main: {
        width: '100%'
    },
    addButton: {
        marginTop: theme.spacing(5),
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    numberOfPeople: {
        border: 'solid',
        borderColor: theme.palette.grey[500],
        borderRadius: '12px',
        padding: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    accordionSummary: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));
function NumberOfPeople({
    control, register, readOnly, setValue
}) {
    const classes = useStyles();
    const intl = useIntl();
    const user = useSelector((state) => state.user);
    const {
        fields, append, remove
    } = useFieldArray({
        control,
        name: 'number_of_people'
    });
    const handleRemove = (index) => {
        remove(index);
    };

    const addRecord = () => {
        append({
            date: getCurrentDate(),
            value: 0,
            authorId: `${user.firstName} ${user.lastName}`,
            verified: false

        });
    };
    const handleChange = (event, field, index) => {
        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        setValue(`number_of_people.${index}.value`, Number(numericValue));
    };

    return (
        <div className={classes.main}>
            {fields.map((field, index) => (
                <Grid container spacing={1} className={classes.numberOfPeople} key={field.id}>
                    <Grid container item xs={4}>
                        <TextField
                            label={intl.formatMessage({ id: messages.value })}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            disabled={readOnly}
                            {...register(`number_of_people.${index}.value`)}
                            onChange={(event) => handleChange(event, field, index)}
                        />
                    </Grid>
                    <Grid container item xs={3}>
                        <Controller
                            name={`number_of_people.${index}.date`}
                            control={control}
                            defaultValue={null}
                            render={({ field: fieldDate }) => (
                                <CustomDatePicker
                                    disabled={readOnly}
                                    disableToolbar
                                    inputFormat={dateFormat.format}
                                    toolbarTitle='Od'
                                    label={intl.formatMessage({ id: messages.date })}
                                    showTodayButton
                                    fullWidth
                                    okLabel={intl.formatMessage({ id: messages.dateOkButton })}
                                    cancelLabel={intl.formatMessage({ id: messages.dateCancelButton })}
                                    todayLabel={intl.formatMessage({ id: messages.dateTodayButton })}
                                    renderInput={(params) => <TextField {...params} />}
                                    {...fieldDate}
                                />
                            )}
                        />
                    </Grid>
                    <Grid container item xs={4}>
                        <FormControlLabel
                            control={(
                                <Controller
                                    name={`number_of_people.${index}.verified`}
                                    control={control}
                                    render={(dataCheckbox) => (
                                        <Checkbox
                                            {...dataCheckbox.field}
                                            checked={dataCheckbox.field.value}
                                            onChange={(event) => {
                                                const { checked } = event.target;
                                                dataCheckbox.field.onChange(checked);
                                            }}
                                            color='secondary'
                                            disabled={readOnly}
                                        />
                                    )}
                                />
                            )}
                            label={intl.formatMessage({ id: messages.verified })}
                        />

                    </Grid>
                    {
                        !readOnly && (
                            <Grid item xs={12} sm={1}>
                                <IconButton
                                    onClick={
                                        () => { handleRemove(index); }
                                    }
                                    size='large'
                                >
                                    <RemoveIcon fontSize='small' />
                                </IconButton>
                            </Grid>
                        )
                    }

                    {user?.role === userRoles.admin && (
                        <Grid container item xs={12}>
                            <TextField
                                value={field?.authorId}
                                label={intl.formatMessage({ id: messages.author })}
                                disabled
                                fullWidth
                                sx={{ mt: 1 }}
                            />
                        </Grid>
                    )}
                </Grid>
            ))}
            {
                !readOnly && (
                    <Grid item xs={12} sm={12}>
                        <IconButton
                            className={classes.addButton}
                            onClick={addRecord}
                            color='primary'
                            component='span'
                            size='large'
                        >
                            <Add />
                        </IconButton>
                    </Grid>
                )
            }
        </div>
    );
}

NumberOfPeople.defaultProps = {
    readOnly: false
};

NumberOfPeople.propTypes = {
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    getValues: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
};

export default NumberOfPeople;
