import useSWR from 'swr';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';

const useCorrespondence = (filterData, page, pageSize, sortModel) => {
    const { data, error, mutate } = RestRequestsHelper.getCorrespondence(useSWR, filterData, page, pageSize, sortModel);
    return {
        correspondence: data?.data || [],
        count: data?.count ?? 0,
        loading: !error && !data,
        mutate
    };
};

export default useCorrespondence;
