import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector, useDispatch } from 'react-redux';
import Switch from '@mui/material/Switch';
import DarkIcon from '@mui/icons-material/Brightness7';
import LightIcon from '@mui/icons-material/Brightness4';
import { setDarkTheme } from '../../../actions';

const useStyles = makeStyles(() => ({
    switchContainer: {
        marginRight: '10px'
    }
}));

function ThemeSelection() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useSelector((state) => state.theme);

    const setHtmlTheme = (themeBoolean) => {
        document.documentElement.setAttribute('data-color-mode', themeBoolean ? 'dark' : 'light');
    };
    useEffect(() => {
        setHtmlTheme(theme);
    }, []);
    useEffect(() => {
        localStorage.setItem('darkTheme', theme);
        setHtmlTheme(theme);
    }, [theme]);
    return (
        <div className={classes.switchContainer}>
            <Switch
                checkedIcon={<DarkIcon />}
                icon={<LightIcon />}
                checked={theme}
                color='default'
                onChange={() => dispatch(setDarkTheme())}
                inputProps={{ 'aria-label': 'switch with changing theme' }}
            />
        </div>
    );
}
export default ThemeSelection;
