import React, {
    useState, useEffect, forwardRef, useImperativeHandle
} from 'react';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import DocumentPreview from '../DocumentPreview/DocumentPreview';
import RestRequestsHelper from '../../../lib/restRequestsHelper';

const useStyle = makeStyles(() => ({
    preview: {
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    divider: {
        marginTop: '10px'
    }
}));

const messages = {
    file: { id: 'app.addTask.file' },
    downloadFile: { id: 'app.addTask.downloadFile' },
    documentName: { id: 'app.addTask.documentName' },
    viewingRemarkFiles: { id: 'app.addTask.viewingRemarkFiles' },
    fileDeleted: { id: 'app.addTask.fileDeleted' },
    fileDownloadError: { id: 'app.addTask.fileDownloadError' }
};

type FormFileManagerPropsType = {
    currentField: string[],
    setValue: Function,
    getValues: Function,
    readOnly: boolean,
    dataRealoading: boolean
}

type fileRecordType = {
    file: File|undefined,
    id: string
}

const FormFileManager = forwardRef(({
    currentField = ['files'],
    setValue,
    getValues,
    readOnly = false,
    dataRealoading = false
} : FormFileManagerPropsType, ref) => {
    const [previousField, setPreviousField] = useState(currentField);
    const [currentFiles, setCurrentFiles] = useState(currentField ? getValues(currentField.join('.')) : []);
    const [fileNumber, setFileNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const intl = useIntl();
    const classes = useStyle();
    // Save files from our buffer 'currentFiles' to form state
    const flushCurrentFiles = (newFiles = currentFiles, current = false) => {
        const field = current ? currentField : previousField;
        setValue(field.join('.'), newFiles);
    };

    // Load files from form state to our buffer 'currentFiles'
    const handleSwitchCurrentFiles = (field: string[] = []) => {
        flushCurrentFiles(dataRealoading ? getValues(field.join('.')) : currentFiles);
        setFileNumber(0);
        setCurrentFiles(getValues(field.join('.')) || []);
        setPreviousField(field);
    };

    const lazyLoadFiles = async (field: string[]) => {
        setIsLoading(true);
        const path = field.join('.');
        const files: fileRecordType[] = getValues(path) || [];
        if (files.some(({ file }) => file === undefined)) {
            // Downaload files from server
            const downloadedFiles = await Promise.all(files.map((fileId) => (
                RestRequestsHelper.downloadFile(fileId?.id ?? fileId, null, true)
            )));
            // Process downloaded files
            const newFiles = files.map((file, index) => {
                const fileData = downloadedFiles[index].data;
                const type = (fileData?.type === 'application/octet-stream') ? 'application/pdf' : fileData?.type;
                return {
                    ...(typeof file === 'string' ? { id: file } : file),
                    uploaded: true,
                    file: new File([fileData], 'file', { type }),
                    error: !fileData
                };
            });
            setCurrentFiles(newFiles);
            flushCurrentFiles(newFiles, true);
        }
        setIsLoading(false);
    };

    const loadData = () => {
        if (currentField) {
            handleSwitchCurrentFiles(currentField);
            lazyLoadFiles(currentField);
        }
    };
    useImperativeHandle(ref, () => ({
        flushFiles() {
            setCurrentFiles([]);
            flushCurrentFiles([]);
        },
        refresh() {
            loadData();
        }
    }));

    // Load files from form state if we have a new field
    useEffect(() => {
        loadData();
    }, [currentField]);

    // Inserts new files to the currentFiles buffer
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        const rawFiles = [...(files || [])];

        const newFiles = rawFiles.map((file, index) => ({
            file,
            fileName: file.name,
            name: `${intl.formatMessage(messages.file)} ${currentFiles.length + index + 1}`
        }));
        const newCurrentFiles = [...currentFiles, ...newFiles];
        setCurrentFiles(newCurrentFiles);
        flushCurrentFiles(newCurrentFiles);
    };

    // Deletes file from the currentFiles buffer
    const handleDeleteFile = (index: number) => {
        const rawFiles = [...currentFiles];
        rawFiles.splice(index, 1);
        // Decrease file number if it's greater than the number of files
        if (index >= rawFiles.length && rawFiles.length > 0) {
            setFileNumber(index - 1);
        }
        setCurrentFiles(rawFiles);
        flushCurrentFiles(rawFiles);
    };

    // Returns a name of current remark if it's set
    const getFocusedRemarkFilesName = () => {
        const rawName = getValues(currentField.slice(0, 2).join('.'))?.value;
        if (rawName) {
            const name = rawName.length > 100 ? `${rawName.slice(0, 100)}...` : rawName;
            return intl.formatMessage(messages.viewingRemarkFiles, { name });
        }
        return '';
    };
    return (
        <Grid container>
            {/* Perhaps with zIndex */}
            <Grid item xs={12}>
                <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                    {getFocusedRemarkFilesName()}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {(currentFiles?.[fileNumber]?.deleted || currentFiles?.[fileNumber]?.error) && (
                    <Typography component='h2' variant='h6' className={classes.divider} align='left' color='red'>
                        {intl.formatMessage(currentFiles?.[fileNumber]?.deleted ? messages.fileDeleted : messages.fileDownloadError)}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <div className={classes.preview}>
                    <DocumentPreview
                        file={currentFiles?.[fileNumber]?.file}
                        onLoadFile={handleFileChange}
                        onDeleteFile={handleDeleteFile}
                        filesNumber={currentFiles?.length}
                        fileNumber={fileNumber}
                        setFileNumber={setFileNumber}
                        disableActions={readOnly}
                        manyFiles
                        currentFileName={currentFiles?.[fileNumber]?.name}
                        showFileName
                        allowEditingFileName={!readOnly}
                        handleChangeCurrentFileName={(event) => {
                            const newFiles = [...currentFiles];
                            newFiles[fileNumber].name = event.target.value;
                            setCurrentFiles(newFiles);
                            flushCurrentFiles(newFiles);
                        }}
                        loading={isLoading}
                        allowDownloading={!currentFiles?.[fileNumber]?.deleted && !currentFiles?.[fileNumber]?.error}
                    />
                </div>
            </Grid>
        </Grid>
    );
});

type fileDataType = {
    file: File,
    uploaded: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}

type remarkFilesType = {
    files: fileDataType[]
}

const prepareFilesForUpload = (filesData: fileDataType[]) => {
    const metadata = filesData.map(({ file, ...meta }) => ({ ...meta }));
    const files = filesData.map(({ file, uploaded }) => (uploaded ? null : file)).filter((file) => file);
    return { metadata, files };
};

const prepareRemarkFilesForUpload = (remarksFilesData: remarkFilesType[]) => {
    const metadata = remarksFilesData.map(({ files }) => (files || []).map(({ file, ...meta }) => ({ ...meta })));
    const remarkFiles = remarksFilesData
        .map(({ files }) => (files || []).map(({ file, uploaded }) => (uploaded ? null : file))).flat().filter((file) => file);
    return {
        metadata: {
            data: metadata,
            count: remarksFilesData.map(({ files }) => (files || []).length)
        },
        files: remarkFiles
    };
};

// use in case of no using React Hook Form
const useFormFilesState = (defaultFilesField: string[] = [], defaultValues = {}) => {
    const {
        setValue, getValues
    } = useForm({ defaultValues });
    const [filesField, setFilesField] = useState(defaultFilesField);

    return {
        setFilesVal: setValue,
        getFilesVal: getValues,
        filesField,
        setFilesField
    };
};

export default FormFileManager;
export { prepareFilesForUpload, prepareRemarkFilesForUpload, useFormFilesState };
