import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { FormattedMessage, useIntl } from 'react-intl';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { debounce, cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import useSWR from 'swr';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import { parseFormattedMoney } from '../../common/DataGridCells/GridMoneyCell';
import MoneyTextField from '../../common/MoneyTextField/MoneyTextField';
import formatMoney from '../../../lib/formatMoney';
import generateFiltersText from '../../../lib/generateFiltersText';
import UniversalCombobox, { comboboxTypes } from '../../common/Combobox/UniversalCombobox';
import { paginationDefaultValue, paginationAllowedValues } from '../../../constants/pagination';
import InvoicePositionsTitlesCombobox from '../../common/Combobox/InvoicePositionsTitlesCombobox';
import dateFormat from '../../../constants/dateFormat';
import FixedAddButton from '../../common/FixedAddButton/FixedAddButton';
import DataGridFooter from '../../common/DataGridFooter/DataGridFooter';
import onKeyDetect from '../../../lib/onKeyDetect';
import { setInvoice } from '../../../actions';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';
import AddSelectDialog from '../../common/AddDialog/AddSelectDialog';
import CustomDatePicker from '../../common/CustomDatePicker/CustomDatePicker';
import formatUTCDate from '../../../lib/formatUTCDate';
import validateDate from '../../../lib/validateDate';
import { defaultFilter, TOOLBAR_SELECT } from './shared/data/invoicesConfig';
import InvoiceCustomToolbar from './shared/ui/InvoiceCustomToolbar/InvoiceCustomToolbar';
import SidePanel from './shared/ui/SidePanel';
import useColumns from './shared/hooks/useColumns';

const messages = {
    documentNumber: { id: 'app.addInvoice.documentNumber' },
    amountFrom: { id: 'app.invoices.amountFrom' },
    amountTo: { id: 'app.invoices.amountTo' },
    buyer: { id: 'app.invoices.buyer' },
    seller: { id: 'app.invoices.seller' },
    flowStatus: { id: 'app.invoices.flowStatus' },
    group: { id: 'app.invoices.group' },
    groupFilter: { id: 'app.invoices.groupFilter' },
    dateOkButton: { id: 'app.invoiceForm.OkButton' },
    dateCancelButton: { id: 'app.invoiceForm.CancelButton' },
    dateTodayButton: { id: 'app.invoiceForm.todayButton' },
    cancel: { id: 'app.invoices.cancel' },
    pozycja: { id: 'app.addInvoice.content.single' },
    description: { id: 'app.addInvoice.description' },
    saleDate: { id: 'app.addInvoice.saleDate' },
    invoiceDate: { id: 'app.invoices.invoiceDate' },
    paymentDate: { id: 'app.invoices.payementDate' },
    filterDatesBy: { id: 'app.invoices.filterDatesBy' },
    dateFrom: { id: 'app.invoices.dateFrom' },
    dateTo: { id: 'app.invoices.dateTo' },
    import: { id: 'app.addSelectDialog.import' },
    add: { id: 'app.addSelectDialog.add' },
    addDialog: { id: 'app.invoices.addDialog' },
    documentType: { id: 'app.form.documentType' }
};

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '80vh'
    },
    warningIcon: {
        verticalAlign: 'middle'
    },
    warningIconOrange: {
        fill: 'orange'
    },
    warningIconRed: {
        fill: 'red'
    },
    warningPopup: {
        padding: theme.spacing(1),
        margin: theme.spacing(1)
    },
    datagrid: {
        marginTop: theme.spacing(3),
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    }
}));

const useInvoices = (filterData, page, pageSize, sortModel) => {
    const { data, error, mutate } = RestRequestsHelper.getInvoices(useSWR, filterData, page, pageSize, sortModel);
    return {
        invoices: data?.rows ?? [],
        rowsCount: data?.count ?? 0,
        loading: !error && !data,
        mutate
    };
};

const useSum = (filterData) => {
    const { data } = RestRequestsHelper.getInvoicesSum(useSWR, filterData);
    const sum = data && data.total ? data.total : 0;
    return { sum };
};

const getDataDownload = (filterData, callback) => {
    RestRequestsHelper.getAllInvoices(filterData)
        .then((result) => {
            callback(result?.data?.rows);
        });
};

function Invoices() {
    const query = new URLSearchParams(window.location.search);
    const queryFilter = JSON.parse(query.get('filter') ?? '{}');
    const intl = useIntl();
    const locale = useSelector((state) => state.locale);
    dayjs.locale(locale);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(paginationDefaultValue);
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const [filterData, setFilterData] = useState(defaultFilter(queryFilter));
    const [groups, setGroups] = useState([]);
    const [exportSelect, setExportSelect] = useState(TOOLBAR_SELECT.noSelect);
    const [exportFiltersText, setExportFiltersText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [height, setHeight] = useState();
    const [sortModel, setSortModel] = useState([
        { field: 'payment_date', sort: 'desc' }
    ]);

    const [openAddSelectDialog, setOpenAddSelectDialog] = useState(false);
    const id = query.get('id');

    useEffect(() => {
        if (id) {
            dispatch(setInvoice(id));
        }
    }, [id]);

    const handleOnClose = () => {
        dispatch(setInvoice(null));
        if (id) {
            query.delete('id');
            history.replace({ pathname: window.location.pathname, search: query.toString() });
        }
    };

    const {
        invoices, loading,
        rowsCount, mutate
    } = useInvoices(filterData, page, pageSize, sortModel);
    const handleUpdate = () => mutate();
    const columns = useColumns({
        groups,
        exportSelect,
        filterData
    });
    const { sum } = useSum(filterData);

    const handleRowSelected = (params) => {
        setSelectedRows(params);
    };
    const handleSortModelChange = (newModel) => {
        if (JSON.stringify(newModel) !== JSON.stringify(sortModel)) {
            setSortModel(newModel);
        }
    };
    const showCheckboxes = (
        exportSelect === TOOLBAR_SELECT.elixir
        || exportSelect === TOOLBAR_SELECT.changeFlowState
        || exportSelect === TOOLBAR_SELECT.massRemoval
    );

    const [dataDownload, setDataDownload] = useState();
    const [download, setDownload] = useState(false);

    const [headers, setHeaders] = useState([]);

    const disableHidding = true;
    const fullColumns = useColumns({
        groups,
        exportSelect,
        filterData,
        handleUpdate,
        disableHidding
    });
    const setupHeaders = () => {
        const _headers = [];
        fullColumns.map((i) => !i.disableExport && _headers.push({ label: i?.headerName, key: i.field }));
        setHeaders(_headers);
    };
    const handleDownloadClick = () => {
        getDataDownload(filterData, (result) => {
            const res = result;
            res.map((item, i) => {
                res[i].buyer = item?.buyer.map((elm) => elm.name).join(', ');
                res[i].seller = item?.seller.map((elm) => elm.name).join(', ');
                res[i].total_gross_charges = formatMoney(Math.round(item?.total_gross_charges) / 100, false);
                res[i].exposure_date = formatUTCDate(item?.exposure_date, dateFormat.format);
                res[i].sell_date = formatUTCDate(item?.sell_date, dateFormat.format);
                res[i].payment_date = formatUTCDate(item?.payment_date, dateFormat.format);
                res[i].state = item?.state.value;
                res[i].id_group = groups ? groups.find((elm) => elm.id === res[i].id_group)?.name : '';
                return true;
            });
            setDataDownload(res);
        });
    };
    useEffect(() => {
        if (dataDownload) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [dataDownload]);

    const dateFilterOptions = ['saleDate', 'invoiceDate', 'paymentDate'];

    useEffect(() => {
        const _filtersData = [
            {
                text: intl.formatMessage({ id: messages.documentNumber.id }),
                value: filterData?.documentNumber?.value
            },
            {
                text: intl.formatMessage({ id: messages.buyer.id }),
                value: filterData?.buyer?.value.map((item) => item.name).join(', ')
            },
            {
                text: intl.formatMessage({ id: messages.seller.id }),
                value: filterData?.seller?.value.map((item) => item.name).join(', ')
            },
            {
                text: intl.formatMessage({ id: messages.filterDatesBy.id }),
                value: intl.formatMessage(messages[filterData?.dateFilter?.value])
            },
            {
                text: intl.formatMessage({ id: messages.dateFrom.id }),
                value: formatUTCDate(filterData.dateFrom.value, dateFormat.format)
            },
            {
                text: intl.formatMessage({ id: messages.dateTo.id }),
                value: formatUTCDate(filterData.dateTo.value, dateFormat.format)
            },
            {
                text: intl.formatMessage({ id: messages.flowStatus.id }),
                value: filterData?.status?.value
            },
            {
                text: intl.formatMessage({ id: messages.group.id }),
                value: groups ? groups.find((elm) => elm.id === filterData?.group?.value)?.name : filterData?.group?.value
            },
            {
                text: intl.formatMessage({ id: messages.pozycja.id }),
                value: filterData?.position?.value
            },
            {
                text: intl.formatMessage({ id: messages.description.id }),
                value: filterData?.description?.value
            },
            {
                text: intl.formatMessage({ id: messages.amountFrom.id }),
                value: formatMoney(parseInt(filterData.amountMinimum.value) / 100, false)
            },
            {
                text: intl.formatMessage({ id: messages.amountTo.id }),
                value: formatMoney(parseInt(filterData.amountMaximum.value) / 100, false)
            }
        ];
        setExportFiltersText(generateFiltersText(_filtersData));
    }, [filterData]);
    useEffect(() => {
        setupHeaders();
    }, [filterData.groupPositions.value]);

    useEffect(() => {
        const headerHeight = document.querySelector('.MuiPaper-root') !== null ? (
            document.querySelector('.MuiPaper-root').getBoundingClientRect().height
        ) : (
            '64'
        );
        const gridHeight = document.querySelector('.MuiGrid-root') !== null ? (
            document.querySelector('.MuiGrid-root').getBoundingClientRect().height
        ) : (
            '96'
        );
        const dataGridHeight = document.querySelector('.MuiDataGrid-root') !== null ? (
            document.querySelector('.MuiDataGrid-root').getBoundingClientRect().height
        ) : (
            '72'
        );
        const dimension = Number(headerHeight) + Number(gridHeight) + Number(dataGridHeight);
        setHeight(String(dimension));
    }, []);

    useEffect(() => {
        RestRequestsHelper.getInvoiceGroups().then((value) => {
            setGroups(value);
        });
    }, []);

    const filterClearedAttr = (name, value) => {
        switch (name) {
            case 'documentNumber':
                return value !== '';
            case 'buyer':
                return value && typeof value === 'object' && value.length;
            case 'seller':
                return value && typeof value === 'object' && value.length;
            case 'dateFilter':
                return value !== null;
            case 'dateFrom':
                return value !== null && validateDate(value);
            case 'dateTo':
                return value !== null && validateDate(value);
            case 'status':
                return value && typeof value === 'object' && value.length;
            case 'group':
                return value && typeof value === 'object' && value.length;
            case 'groupPositions':
                return true;
            case 'position':
                return value && typeof value === 'object' && value.length;
            case 'description':
                return value !== '';
            case 'amountMinimum':
                return typeof value === 'number';
            case 'amountMaximum':
                return typeof value === 'number';
            case 'invoiceType':
                return value && typeof value === 'object' && value.length;
            default:
                return false;
        }
    };

    const setFilterAttr = debounce((name, value) => {
        if (name === 'groupPositions') {
            setExportSelect(value ? TOOLBAR_SELECT.noSelect : TOOLBAR_SELECT.positionsUngrouped);
        }
        setSelectedRows([]);
        setFilterData((newFilterData) => {
            const data = cloneDeep(newFilterData);
            data[name] = {
                value,
                filter: filterClearedAttr(name, value)
            };
            return data;
        });
    }, 500);

    const getFilterAttrValue = (name) => (
        filterData[name].value
    );

    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, []);

    const onSelect = ({ field, row }) => {
        if (field !== 'additionalOptions' && !showCheckboxes) {
            dispatch(setInvoice(row?.id));
        }
    };

    const handlePaginationModelChange = (data) => {
        setPageSize(data.pageSize);
        setPage(data.page);
    };
    const handleSelectable = ({ row }) => (
        exportSelect === TOOLBAR_SELECT.changeFlowState
        || exportSelect === TOOLBAR_SELECT.massRemoval
        || (exportSelect === TOOLBAR_SELECT.elixir && (row.elixir_buyer && row.seller_bank_account && row.payment_date))
    );

    return (
        <div className={classes.main}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <TextField
                        id='documentNumber'
                        variant='outlined'
                        margin='normal'
                        label={intl.formatMessage({ id: messages.documentNumber.id })}
                        fullWidth
                        onKeyDown={(event) => onKeyDetect(event, () => setFilterAttr('documentNumber', event.target.value))}
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        id='buyer'
                        margin='normal'
                        multiple
                        src={comboboxTypes.contractors()}
                        label={intl.formatMessage({ id: messages.buyer.id })}
                        onChange={(value) => setFilterAttr('buyer', value)}
                        value={getFilterAttrValue('buyer')}
                        showColors
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        margin='normal'
                        multiple
                        id='seller'
                        src={comboboxTypes.contractors()}
                        label={intl.formatMessage({ id: messages.seller.id })}
                        onChange={(value) => setFilterAttr('seller', value)}
                        value={getFilterAttrValue('seller')}
                        showColors
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        variant='outlined'
                        id='dateFilter'
                        options={dateFilterOptions}
                        getOptionLabel={(option) => intl.formatMessage(messages[option]) || option}
                        value={getFilterAttrValue('dateFilter')}
                        onChange={(_event, value) => setFilterAttr('dateFilter', value)}
                        fullWidth
                        disableClearable
                        renderInput={(params) => <TextField {...params} label={intl.formatMessage(messages.filterDatesBy)} variant='outlined' margin='normal' />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        toolbarTitle='Od'
                        label={intl.formatMessage({ id: messages.dateFrom.id })}
                        showTodayButton
                        value={getFilterAttrValue('dateFrom')}
                        onChange={(date) => setFilterAttr('dateFrom', date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth margin='normal' id='dateFrom' {...params} />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        label={intl.formatMessage({ id: messages.dateTo.id })}
                        showTodayButton
                        value={getFilterAttrValue('dateTo')}
                        onChange={(date) => setFilterAttr('dateTo', date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth margin='normal' id='dateTo' {...params} />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        id='status'
                        optionLabel={(option) => option.name}
                        showColors
                        multiple
                        onMatch={(option, value) => option.id === value.id}
                        onChange={(value) => {
                            setFilterAttr('status', value);
                        }}
                        value={getFilterAttrValue('status')}
                        src={comboboxTypes.documentFlowStatuses()}
                        label={intl.formatMessage(messages.flowStatus)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        variant='outlined'
                        id='group'
                        multiple
                        options={groups}
                        getOptionLabel={(option) => option.name}
                        onChange={(_event, value) => setFilterAttr('group', value)}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label={intl.formatMessage(messages.group)} variant='outlined' />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Grid container>
                        <Grid item xs={8}>
                            <InvoicePositionsTitlesCombobox
                                invoicesBrowseRender
                                value={getFilterAttrValue('position')}
                                multiple
                                onChange={
                                    (event, newValue) => {
                                        setFilterAttr('position', newValue);
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Stack
                                direction='column'
                                justifyContent='center'
                                alignItems='center'
                            >
                                <Typography variant='body2'>
                                    <FormattedMessage id={messages.groupFilter.id} />
                                </Typography>
                                <Switch
                                    checked={getFilterAttrValue('groupPositions')}
                                    onChange={() => setFilterAttr('groupPositions', !getFilterAttrValue('groupPositions'))}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        id='description'
                        variant='outlined'
                        label={intl.formatMessage({ id: messages.description.id })}
                        fullWidth
                        onKeyDown={(event) => onKeyDetect(event, () => setFilterAttr('description', event.target.value))}
                    />
                </Grid>
                <Grid item xs={1}>
                    <MoneyTextField
                        onChange={(value) => setFilterAttr('amountMinimum', value.length ? 100 * parseFormattedMoney(value) : null)}
                        onKeyDown={(event) => onKeyDetect(event, () => {
                            const { value } = event.target;
                            setFilterAttr('amountMinimum', value.length ? 100 * parseFormattedMoney(value) : null);
                        })}
                        textFieldParams={{
                            id: 'amountMinimum',
                            label: intl.formatMessage(messages.amountFrom),
                            variant: 'outlined',
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <MoneyTextField
                        onChange={(value) => setFilterAttr('amountMaximum', value.length ? 100 * parseFormattedMoney(value) : null)}
                        onKeyDown={(event) => onKeyDetect(event, () => {
                            const { value } = event.target;
                            setFilterAttr('amountMaximum', value.length ? 100 * parseFormattedMoney(value) : null);
                        })}
                        textFieldParams={{
                            id: 'amountMaximum',
                            label: intl.formatMessage(messages.amountTo),
                            variant: 'outlined',
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <UniversalCombobox
                        id='invoiceType'
                        multiple
                        onChange={(value) => setFilterAttr('invoiceType', value)}
                        value={getFilterAttrValue('invoiceType')}
                        src={comboboxTypes.documentTypes('invoice')}
                        label={intl.formatMessage(messages.documentType)}
                    />
                </Grid>
            </Grid>
            <SidePanel
                onUpdate={handleUpdate}
                onClose={handleOnClose}
                mutate={mutate}
            />
            <div style={{ height: `calc(100vh - ${height}px)` }}>
                <FixedAddButton onClick={() => setOpenAddSelectDialog(true)} />
                <AddSelectDialog
                    open={openAddSelectDialog}
                    onClose={() => setOpenAddSelectDialog(false)}
                    titleTranslationId={messages.addDialog.id}
                    options={[
                        {
                            translationId: messages.add.id,
                            redirectTo: '/addInvoice/manually'
                        },
                        {
                            translationId: messages.import.id,
                            redirectTo: '/addInvoice/import'
                        }
                    ]}
                />
                <DataGrid
                    className={classes.datagrid}
                    paginationMode='server'
                    rows={invoices}
                    columns={columns}
                    getRowId={(row) => row.uniqueID || row.id}
                    pageSizeOptions={paginationAllowedValues}
                    rowCount={rowsCount}
                    onPaginationModelChange={handlePaginationModelChange}
                    onCellClick={onSelect}
                    loading={loading}
                    paginationModel={{ page, pageSize }}
                    checkboxSelection={showCheckboxes}
                    onRowSelectionModelChange={handleRowSelected}
                    rowSelectionModel={selectedRows}
                    isRowSelectable={handleSelectable}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    components={{
                        Toolbar: InvoiceCustomToolbar,
                        Footer: DataGridFooter
                    }}
                    componentsProps={{
                        toolbar: {
                            option: exportSelect,
                            setOption: setExportSelect,
                            mutateInvoices: mutate,
                            invoices,
                            selectedRows,
                            setSelectedRows,
                            exportFiltersText,
                            handleDownloadClick,
                            download,
                            dataDownload,
                            headers,
                            filterData,
                            user
                        },
                        footer: { sum }
                    }}
                    columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                />
            </div>
        </div>
    );
}

export default Invoices;
