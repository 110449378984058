import React, {
    useState, useEffect, forwardRef, useImperativeHandle
} from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { invert } from 'lodash';
import useSWR from 'swr';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import Column from '../../../common/Column/Column';
import INFRASTRUCTURE_TYPES from '../../../../constants/infrastructureTypes';

const messages = {
    realEstate: { id: 'app.infrastructures.realEstate' },
    buildings: { id: 'app.infrastructures.buildings' },
    storeys: { id: 'app.infrastructures.storeys' },
    premises: { id: 'app.infrastructures.premises' }
};

const useStyles = makeStyles((theme) => ({
    main: (style) => ({
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        textAlign: 'left',
        display: 'flex',
        height: style.height || '90vh',
        flex: 1,
        alignItems: 'stretch'
    })
}));

const useRealEstates = () => {
    const { data, error, mutate } = RestRequestsHelper.getRealEstates(useSWR);
    return {
        realEstates: data ?? [],
        loading: !error && !data,
        mutate
    };
};
const useBuildings = (realEstateId, buildingId, storeysId) => {
    const { data, error, mutate } = RestRequestsHelper.getInfrastructuresForRealEstates(useSWR, realEstateId, buildingId, storeysId);
    return {
        buildings: data?.buildings ?? [],
        storeys: data?.storeys ?? [],
        premises: data?.premises ?? [],
        loading: !error && !data,
        mutate
    };
};

const InfrastructuresColumns = forwardRef(({ onDoubleClick, showProperties }, ref) => {
    const intl = useIntl();
    const isProductionDb = useSelector((state) => state.dbInfo.production);
    const [height, setHeight] = useState('');
    const [properties, setProperties] = useState({});
    const [selectedRealEstate, setSelectedRealEstate] = useState('');
    const [selectedBuilding, setSelectedBuilding] = useState('');
    const [selectedStoreys, setSelectedStoreys] = useState('');
    const [selectedPremises, setSelectedPremises] = useState('');
    const [selectedItemsNames, setSelectedItemsNames] = useState({});
    const [counted, setCounted] = useState({});
    const classes = useStyles({ height });

    const { realEstates, loading: loadingRealEstates, mutate: mutateRealEstates } = useRealEstates();
    const {
        buildings, storeys, premises, loading: loadingBuildings, mutate: mutateInfrastructures
    } = useBuildings(selectedRealEstate, selectedBuilding, selectedStoreys);

    const handleUpdate = () => {
        mutateRealEstates();
        mutateInfrastructures();
    };

    useImperativeHandle(ref, () => ({
        handleUpdate
    }));

    const handleChangeRealEstate = (selected) => {
        setSelectedItemsNames((prev) => ({ ...prev, realEstate: selected.name }));
        setSelectedRealEstate(selected._id);
        setSelectedBuilding('');
        setSelectedStoreys('');
        setSelectedPremises('');
    };
    const handleChangeBuilding = (selected) => {
        setSelectedItemsNames((prev) => ({ ...prev, building: selected.name }));
        setSelectedBuilding(selected._id);
        setSelectedStoreys('');
        setSelectedPremises('');
    };
    const handleChangeStoreys = (selected) => {
        setSelectedItemsNames((prev) => ({ ...prev, storeys: selected.name }));
        setSelectedStoreys(selected._id);
        setSelectedPremises('');
    };
    const handleChangePremises = (selected) => {
        setSelectedItemsNames((prev) => ({ ...prev, premises: selected.name }));
        setSelectedPremises(selected._id);
    };

    const countProperties = ({ data, type, selectedItem }) => {
        const filteredData = selectedItem ? data.filter((item) => item._id === selectedItem) : data;
        if (showProperties) {
            const countedProperty = {};
            Object.keys(properties).forEach(((key) => {
                countedProperty[key] = 0;
            }));
            countedProperty['Ilość osób'] = 0;

            if (filteredData.length > 0) {
                filteredData.forEach((element) => {
                    element.properties.forEach((value) => {
                        if (Object.hasOwn(countedProperty, value.key)) {
                            countedProperty[value.key] += Number(value.value);
                        } else {
                            countedProperty[value.key] = Number(value.value);
                        }
                    });
                    if (element?.number_of_people?.length > 0) {
                        countedProperty['Ilość osób'] += element.number_of_people[0].value;
                    }
                });

                setCounted((prevState) => ({ ...prevState, [type]: countedProperty }));
            }
        }
    };

    const getDictionaries = async () => {
        const response = await RestRequestsHelper.getDictionaryValues('properties');
        return response.values;
    };

    const getProperties = async () => {
        const propertiesArray = await getDictionaries();
        const propertiesObject = {};
        propertiesArray?.forEach((element) => {
            propertiesObject[element.key] = element.value;
        });
        setProperties(propertiesObject);
    };

    useEffect(() => {
        getProperties();
        const elHeight = document.getElementById('app-navbar').clientHeight;
        const calculatedHeight = !isProductionDb ? window.innerHeight - elHeight - 64 : window.innerHeight - elHeight;

        setHeight(`${calculatedHeight}px`);
    }, []);

    useEffect(() => {
        if (realEstates.length > 0) {
            countProperties({ data: realEstates, type: 'realEstates' });
        }
    }, [realEstates, properties]);

    useEffect(() => {
        if (buildings.length > 0) {
            countProperties({ data: buildings, type: 'buildings' });
        }
    }, [buildings, properties]);

    useEffect(() => {
        if (storeys.length > 0) {
            countProperties({ data: storeys, type: 'storeys' });
        }
    }, [storeys, properties]);

    useEffect(() => {
        if (premises.length > 0) {
            countProperties({ data: premises, type: 'premises', selectedItem: selectedPremises });
        }
    }, [premises, properties, selectedPremises]);

    const handleDoubleClick = (id) => {
        onDoubleClick(id, selectedItemsNames);
    };

    const getFooterData = (type = 'premises') => {
        const fields = ['Powierzchnia', 'Grunt', 'Wymiar gruntu', 'Przeznaczenie', 'Ilość osób'];
        const propLabels = invert(properties);
        if (!counted[type] || !propLabels) return {};
        return Object.fromEntries(fields.map((fieldName) => {
            const key = propLabels[fieldName];
            return [key, counted[type]?.[fieldName] || counted[type][key]];
        }));
    };

    return (
        <div className={classes.main}>
            <Column
                title={intl.formatMessage({ id: messages.realEstate.id })}
                items={realEstates}
                onSelect={handleChangeRealEstate}
                loading={loadingRealEstates}
                selectedItem={selectedRealEstate}
                activeAddButtons
                onDoubleClick={handleDoubleClick}
                properties={properties}
                footer={getFooterData('realEstates')}
                type={INFRASTRUCTURE_TYPES.REAL_ESTATES}
                onCreate={mutateRealEstates}
            />
            <Column
                title={intl.formatMessage({ id: messages.buildings.id })}
                items={buildings}
                onSelect={handleChangeBuilding}
                loading={selectedRealEstate.length > 0 && loadingBuildings}
                selectedItem={selectedBuilding}
                selectedParent={selectedRealEstate}
                selectedNames={selectedItemsNames}
                onDoubleClick={handleDoubleClick}
                properties={properties}
                footer={getFooterData('buildings')}
                type={INFRASTRUCTURE_TYPES.BUILDINGS}
                onCreate={mutateInfrastructures}
            />
            <Column
                title={intl.formatMessage({ id: messages.storeys.id })}
                items={storeys}
                onSelect={handleChangeStoreys}
                loading={selectedRealEstate.length > 0 && loadingBuildings}
                selectedItem={selectedStoreys}
                selectedParent={selectedBuilding}
                selectedNames={selectedItemsNames}
                onDoubleClick={handleDoubleClick}
                properties={properties}
                footer={getFooterData('storeys')}
                type={INFRASTRUCTURE_TYPES.STOREYS}
                onCreate={mutateInfrastructures}
            />
            <Column
                title={intl.formatMessage({ id: messages.premises.id })}
                items={premises}
                onSelect={handleChangePremises}
                loading={selectedRealEstate.length > 0 && loadingBuildings}
                selectedItem={selectedPremises}
                selectedParent={selectedStoreys}
                selectedNames={selectedItemsNames}
                onDoubleClick={handleDoubleClick}
                properties={properties}
                footer={getFooterData('premises')}
                type={INFRASTRUCTURE_TYPES.PREMISES}
                onCreate={mutateInfrastructures}
            />
        </div>
    );
});

InfrastructuresColumns.propTypes = {
    onDoubleClick: PropTypes.func,
    showProperties: PropTypes.bool
};

InfrastructuresColumns.defaultProps = {
    onDoubleClick: () => {},
    showProperties: false
};

export default InfrastructuresColumns;
