import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import INFRASTRUCTURE_TYPES from '../../../../../constants/infrastructureTypes';

const messages = {
    realEstate: { id: 'app.infrastructures.realEstate' },
    buildings: { id: 'app.infrastructures.buildings' },
    storeys: { id: 'app.infrastructures.storeys' }
};
function SelectedInfrastructureDisplay({
    data, colType, addingInfo, addingAllInfo
}) {
    const intl = useIntl();

    const prepareInfrastructures = (infrastructures) => {
        const preparedData = [];

        if (colType >= INFRASTRUCTURE_TYPES.BUILDINGS && infrastructures?.realEstate) {
            preparedData.push(`${intl.formatMessage({ id: messages.realEstate.id })} - ${infrastructures?.realEstate}`);
        }
        if (colType >= INFRASTRUCTURE_TYPES.STOREYS && infrastructures?.building) {
            preparedData.push(`${intl.formatMessage({ id: messages.buildings.id })} - ${infrastructures?.building}`);
        }
        if (colType >= INFRASTRUCTURE_TYPES.PREMISES && infrastructures?.storeys) {
            preparedData.push(`${intl.formatMessage({ id: messages.storeys.id })} - ${infrastructures?.storeys}`);
        }

        return preparedData;
    };
    const preparedData = prepareInfrastructures(data);

    return (
        <div>
            <Typography>
                {
                    preparedData.length
                        ? addingInfo
                        : addingAllInfo
                }
            </Typography>
            {preparedData && (
                <ul>
                    {preparedData.map((elm, i) => (
                        <li key={i}>{elm}</li>
                    ))}
                </ul>
            )}
        </div>
    );
}
SelectedInfrastructureDisplay.propTypes = {
    data: PropTypes.object,
    colType: PropTypes.number,
    addingInfo: PropTypes.string.isRequired,
    addingAllInfo: PropTypes.string.isRequired
};
SelectedInfrastructureDisplay.defaultProps = {
    data: {},
    colType: null
};
export default SelectedInfrastructureDisplay;
