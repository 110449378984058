import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import {
    Position,
    DocumentType,
    Counter,
    Entity,
    TallyType,
    TallySection,
    Transaction,
    TallySectionLevel,
    AdvancePayments,
    Combination,
    TypeRate, CountedIngredient, Algorithm, DocumentKind
} from './types';

// If you want to add a new relation type, just add it here
const RELATIONS = new Map([
    [Position, {
        fetcher: RestRequestsHelper.getPositionsTypes,
        label: 'app.invoices.positionTitle'
    }],
    [DocumentType, {
        fetcher: RestRequestsHelper.getDocumentTypes,
        label: 'app.form.documentType'
    }],
    [Counter, {
        fetcher: RestRequestsHelper.getCounterTypes,
        label: 'app.infrastructures.counterTypes'
    }],
    [Entity, {
        fetcher: RestRequestsHelper.getEntities,
        label: 'app.transactions.entity'
    }],
    [TallyType, {
        fetcher: RestRequestsHelper.getTallyTypes,
        label: 'app.transactions.entity'
    }],
    [TallySection, {
        fetcher: RestRequestsHelper.getTallySections,
        label: 'app.transactions.entity'
    }],
    [TallySectionLevel, {
        fetcher: RestRequestsHelper.getTallySections,
        getter: (options, row) => options.filter((option) => (row.level ?? 0) + 1 === option.level),
        label: 'app.transactions.entity'
    }],
    [Transaction, {
        fetcher: RestRequestsHelper.getTransactionTypes,
        label: 'app.transactions.entity'
    }],
    [AdvancePayments, {
        fetcher: RestRequestsHelper.getPositionsTypes,
        label: 'app.payoffs.advances'
    }],
    [Combination, {
        fetcher: RestRequestsHelper.getMediumCombination,
        label: 'app.dictionary.combination'
    }],
    [TypeRate, {
        fetcher: RestRequestsHelper.getTypeRate,
        label: 'app.payoffs.advances'
    }],
    [CountedIngredient, {
        fetcher: RestRequestsHelper.getCountedIngredient,
        label: 'app.payoffs.advances'
    }],
    [Algorithm, {
        fetcher: RestRequestsHelper.getMediumAlgorithm,
        label: 'app.payoffs.advances'
    }],
    [DocumentKind, {
        fetcher: RestRequestsHelper.getDocumentKinds
    }]
]);

export const isRelation = (type) => {
    const multiple = Array.isArray(type);
    const item = multiple ? type[0] : type;
    const keys = Array.from(RELATIONS.keys());
    return keys.includes(item);
};

const useRelation = (type, input, initialValue) => {
    if (!RELATIONS.has(type)) throw new Error('Relation not found');
    const { fetcher } = RELATIONS.get(type);
    const { data, error } = fetcher(useSWR, input, initialValue);
    return { data, error };
};

function DictionaryRelationCombobox({
    initialValue, onChange, type,
    label, row
}) {
    const multiple = Array.isArray(type);
    const relationType = multiple ? type[0] : type;
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(multiple ? [] : null);
    const [inputValue, setInputValue] = useState('');
    const [initialised, setInitialised] = useState(false);
    const intl = useIntl();
    const { data, error } = useRelation(relationType, inputValue, initialValue, row);
    const preparedLabel = label || intl.formatMessage({ id: RELATIONS.get(relationType).label });
    const getter = RELATIONS.get(relationType).getter ?? ((val) => val);
    const formattedOptions = getter(options, row);

    useEffect(() => {
        if (error || !data) return;
        const formattedData = data.map((item) => ({
            ...item,
            id: item.id ?? item.key ?? item._id,
            label: item.title ?? item.value ?? item.name
        }));
        setOptions(formattedData);
        if (initialised) return;
        const found = multiple
            ? formattedData.filter((item) => initialValue.includes(item.id))
            : formattedData.find((item) => item.id === initialValue);
        setInitialised(true);
        setValue(found);
    }, [data]);

    useEffect(() => {
        if (!value || !initialised) return;
        onChange(value);
    }, [value]);

    return (
        <Autocomplete
            id='relation-combobox'
            value={value}
            onChange={(_event, newValue) => {
                setValue(newValue);
            }}
            options={formattedOptions}
            sx={{ minWidth: '100px', '.MuiTextField-root': { height: '100%' }, '.MuiInputBase-root': { height: '100%' } }}
            multiple={multiple}
            onInputChange={(_event, newValue) => setInputValue(newValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={preparedLabel}
                />
            )}
        />
    );
}
DictionaryRelationCombobox.defaultProps = {
    initialValue: 0,
    type: String,
    label: null,
    row: null
};
DictionaryRelationCombobox.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.number,
    type: PropTypes.any,
    row: PropTypes.object,
    label: PropTypes.string
};
export default DictionaryRelationCombobox;
