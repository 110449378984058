import userRoles from '../constants/UserRoles';

const messages = {
    invoices: { id: 'app.menu.invoices' },
    records: { id: 'app.menu.records' },
    settings: { id: 'app.menu.settings' },
    browse: { id: 'app.menu.browse' },
    add: { id: 'app.menu.add' },
    manually: { id: 'app.menu.addManually' },
    ocr: { id: 'app.menu.ocr' },
    contractors: { id: 'app.menu.contractors' },
    correspondence: { id: 'app.menu.correspondence' },
    reports: { id: 'app.menu.reports' },
    trash: { id: 'app.menu.trash' },
    individual: { id: 'app.menu.individual' },
    common: { id: 'app.menu.common' },
    bankStatements: { id: 'app.menu.bankStatements' },
    submissions: { id: 'app.menu.submissions' },
    media: { id: 'app.menu.media' },
    tally: { id: 'app.menu.tally' },
    tasks: { id: 'app.menu.tasks' },
    vindication: { id: 'app.menu.vindication' },
    inspections: { id: 'app.menu.inspections' },
    readings: { id: 'app.menu.readings' },
    infrastructures: { id: 'app.menu.infrastructures' },
    aboutApp: { id: 'app.about.title' },
    payoffs: { id: 'app.menu.payoffs' },
    transactions: { id: 'app.menu.transactions' },
    income: { id: 'app.menu.income' },
    settlements: { id: 'app.menu.settlements' },
    generators: { id: 'app.menu.generators' },
    notifications: { id: 'app.menu.notifications' },
    correspondenceList: { id: 'app.menu.correspondenceList' }
};

const routeTitles = [
    {
        path: '/',
        ignoreInMenu: true
    },
    {
        path: '/signIn',
        ignoreInMenu: true
    },
    {
        path: '/signUp',
        ignoreInMenu: true
    },
    {
        name: 'Records',
        translation: messages.records.id,
        children: [
            {
                name: 'Infrastructures',
                translation: messages.infrastructures.id,
                path: '/infrastructures/browse',
                roles: [userRoles.admin, userRoles.user]
            },
            {
                name: 'Contractors',
                translation: messages.contractors.id,
                path: '/contractors/browse',
                roles: [userRoles.admin, userRoles.user],
                children: [
                    {
                        name: 'Add',
                        translation: messages.add.id,
                        path: '/contractors/add',
                        ignoreInMenu: true
                    }
                ]
            },
            {
                name: 'Invoices',
                translation: messages.invoices.id,
                path: '/invoices/browse',
                roles: [userRoles.admin, userRoles.user],
                children: [
                    {
                        name: 'Add',
                        translation: messages.add.id,
                        path: '/addInvoice/import',
                        ignoreInMenu: true
                    },
                    {
                        name: 'AddManually',
                        translation: messages.manually.id,
                        path: '/addInvoice/manually',
                        ignoreInMenu: true
                    }
                ]
            },
            {
                name: 'Income',
                translation: messages.income.id,
                path: '/income/browse',
                roles: [userRoles.admin, userRoles.user],
                children: [
                    {
                        name: 'Add',
                        translation: messages.add.id,
                        path: '/income/add',
                        roles: [userRoles.admin, userRoles.user],
                        ignoreInMenu: true
                    },
                    {
                        name: 'Add',
                        translation: messages.add.id,
                        path: '/income/add/verify',
                        roles: [userRoles.admin, userRoles.user],
                        ignoreInMenu: true
                    },
                    {
                        name: 'AddManually',
                        translation: messages.manually.id,
                        path: '/income/addManually',
                        ignoreInMenu: true,
                        roles: [userRoles.admin, userRoles.user]
                    }
                ]
            },
            {
                name: 'Inspections',
                translation: messages.inspections.id,
                path: '/inspections/browse',
                roles: [userRoles.admin, userRoles.user, userRoles.contractor],
                children: [
                    {
                        name: 'Add',
                        translation: messages.add.id,
                        path: '/inspections/add',
                        roles: [userRoles.admin, userRoles.user],
                        ignoreInMenu: true
                    }
                ]
            },
            {
                name: 'Readings',
                translation: messages.readings.id,
                path: '/readings/browse',
                roles: [userRoles.admin, userRoles.user],
                children: [
                    {
                        name: 'Add',
                        translation: messages.add.id,
                        path: '/readings/add',
                        ignoreInMenu: true
                    }
                ]
            },
            {
                name: 'Correspondence',
                translation: messages.correspondence.id,
                path: '/correspondence/browse',
                roles: [userRoles.admin, userRoles.user],
                children: [
                    {
                        name: 'Add',
                        translation: messages.add.id,
                        path: '/correspondence/add',
                        ignoreInMenu: true
                    }
                ]
            },
            {
                name: 'BankStatements',
                translation: messages.bankStatements.id,
                path: '/bankStatements/browse',
                roles: [userRoles.admin, userRoles.user],
                children: [
                    {
                        name: 'Add',
                        translation: messages.add.id,
                        path: '/bankStatements/import',
                        ignoreInMenu: true
                    },
                    {
                        name: 'AddManual',
                        translation: messages.manually.id,
                        path: '/bankStatements/addManually',
                        ignoreInMenu: true
                    }
                ]
            },
            {
                name: 'Tasks',
                translation: messages.tasks.id,
                path: '/tasks/browse',
                roles: [userRoles.admin, userRoles.user, userRoles.contractor],
                children: [
                    {
                        name: 'Add',
                        translation: messages.add.id,
                        path: '/tasks/add',
                        roles: [userRoles.admin, userRoles.user],
                        ignoreInMenu: true
                    }
                ]
            },
            {
                name: 'Vindication',
                translation: messages.vindication.id,
                path: '/vindication/browse',
                roles: [userRoles.admin, userRoles.user, userRoles.contractor],
                children: [
                    {
                        name: 'Add',
                        translation: messages.add.id,
                        path: '/vindication/add',
                        roles: [userRoles.admin, userRoles.user],
                        ignoreInMenu: true
                    }
                ]
            }
        ]
    },
    {
        name: 'Reports',
        translation: messages.reports.id,
        path: '/reports',
        roles: [userRoles.admin, userRoles.user],
        children: [
            {
                name: 'Trash',
                translation: messages.trash.id,
                path: '/reports/trash',
                children: [
                    {
                        name: 'Individual',
                        translation: messages.individual.id,
                        path: '/reports/trash/individual'
                    },
                    {
                        name: 'Common',
                        translation: messages.common.id,
                        path: '/reports/trash/common'
                    }
                ]
            },
            {
                name: 'Media',
                translation: messages.media.id,
                path: '/reports/media',
                children: [
                    {
                        name: 'Readings',
                        translation: messages.readings.id,
                        path: '/reports/media/readings'
                    }
                ]
            },
            {
                name: 'Reports settlements',
                translation: messages.settlements.id,
                path: '/reports/settlements'
            }
        ]
    },
    {
        name: 'Payoffs',
        translation: messages.payoffs.id,
        path: '/payoffs',
        roles: [userRoles.admin, userRoles.user],
        children: [
            {
                name: 'Media',
                translation: messages.media.id,
                path: '/payoffs/media'
            },
            {
                name: 'Tally',
                translation: messages.tally.id,
                path: '/payoffs/tally'
            }
        ]
    },
    {
        name: 'Generators',
        translation: messages.generators.id,
        path: '/generators',
        roles: [userRoles.admin, userRoles.user],
        children: [
            {
                name: 'Notifications',
                translation: messages.notifications.id,
                path: '/generators/notifications'
            },
            {
                name: 'CorrespondenceList',
                translation: messages.correspondenceList.id,
                path: '/generators/correspondenceList'
            }
        ]
    },
    {
        name: 'Settings',
        translation: messages.settings.id,
        path: '/settings',
        roles: [userRoles.admin]
    },
    {
        name: 'About',
        translation: messages.aboutApp.id,
        path: '/about',
        roles: [userRoles.admin, userRoles.user, userRoles.contractor]
    }
];

export default routeTitles;
