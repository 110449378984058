import formatBankAccount from '../../../../../../../lib/formatBankAccount';
import formatInt from '../../../../../../../lib/formatInt';
import formatMoney from '../../../../../../../lib/formatMoney';

const getFormattingPropertiesInput = (type) => {
    switch (type) {
        case 'string':
            return { pattern: '(.*?)', formatFn: (val) => val };
        case 'int':
            return { pattern: '[0-9]+', formatFn: formatInt };
        case 'float':
            return { pattern: '[0-9]*\\.?[0-9]+', formatFn: formatMoney };
        case 'bank_account':
            return { pattern: '^[0-9\\s]{32}$', formatFn: formatBankAccount };
        default:
            return { pattern: '(.*?)', formatFn: (val) => val };
    }
};

export default getFormattingPropertiesInput;
