import useSWR from 'swr';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';

const useMeasureUnits = () => {
    const { data, error, mutate } = RestRequestsHelper.getMeasureUnits(useSWR);
    return {
        measureUnits: data,
        loading: !error && !data,
        mutate
    };
};

export default useMeasureUnits;
