import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Grid, Typography, Divider
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { PropTypes } from 'prop-types';
import { useSubTasksColumns } from '../../../TaskForm/shared/hooks/useColumns';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';
import { paginationAllowedValues } from '../../../../../constants/pagination';
import convertColumnsToVisibilityModel from '../../../../../lib/convertColumnsToVisibilityModel';

const messages = {
    tasks: { id: 'app.contractors.tasks' },
    noData: { id: 'app.general.noData' }
};

function ContractorTasks({ edit }) {
    const [tasksLoading, setTasksLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const tasksColumns = useSubTasksColumns(tasks);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const getTasks = async () => {
            setTasksLoading(true);
            const { data } = await RestRequestsHelper.getContractorTasks(edit?.id, page, pageSize);
            setTasksLoading(false);
            setTasks(data?.data || []);
            setCount(data?.count || 0);
        };
        getTasks();
    }, [page, pageSize]);

    const handlePaginationModelChange = (data) => {
        setPageSize(data.pageSize);
        setPage(data.page);
    };

    const handleSubtaskClick = (params) => {
        if (params.field === 'numberUI') {
            const { row } = params;
            window.open(`/tasks/browse?id=${row?.id}`);
        }
    };

    return (
        <Grid item xs={12}>
            <Typography component='h2' variant='h6' align='left'>
                <FormattedMessage id={messages.tasks.id} />
            </Typography>
            <Divider />
            <Grid item xs={12} sx={{ mt: 1 }}>
                {tasks.length || tasksLoading > 0 ? (
                    <DataGrid
                        autoHeight
                        rows={tasks}
                        columns={tasksColumns}
                        onCellClick={handleSubtaskClick}
                        paginationMode='server'
                        pageSizeOptions={paginationAllowedValues}
                        rowCount={count}
                        paginationModel={{ page, pageSize }}
                        onPaginationModelChange={handlePaginationModelChange}
                        loading={tasksLoading}
                        columnVisibilityModel={convertColumnsToVisibilityModel(tasksColumns)}
                    />
                ) : (
                    <Typography sx={{
                        border: '1px solid #616161', borderRadius: 1, pt: 1, pb: 1
                    }}
                    >
                        <FormattedMessage id={messages.noData.id} />
                    </Typography>
                )}
            </Grid>

        </Grid>
    );
}

ContractorTasks.propTypes = {
    edit: PropTypes.object.isRequired
};

export default ContractorTasks;
