import useSWR from 'swr';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';

const useEntities = (searchPhrase) => {
    const { data, error, mutate } = RestRequestsHelper.getEntities(useSWR, searchPhrase);
    return {
        entities: data,
        loading: !error && !data,
        mutate
    };
};

export default useEntities;
