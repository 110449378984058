import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import Tasks from '../../../Tasks/BrowseTasks/Tasks';

const useStyles = makeStyles(() => ({
    loadingSpinner: {
        paddingTop: '100px'
    }
}));
function InhabitantTasks({ inhabitantData }) {
    const styles = useStyles();
    const [applicantFilter, setApplicantFilter] = useState([]);

    useEffect(() => {
        if (inhabitantData._id) {
            const applicant = {
                $name: inhabitantData.$name, _id: inhabitantData._id
            };
            setApplicantFilter([applicant]);
        }
    }, [inhabitantData]);

    return (
        <div>
            {
                applicantFilter.length ? (
                    <Tasks defaultApplicantFilter={applicantFilter} inhabitantView />
                ) : (
                    <div className={styles.loadingSpinner}>
                        <CircularProgress size={28} />
                    </div>
                )
            }
        </div>
    );
}

InhabitantTasks.propTypes = {
    inhabitantData: PropTypes.object
};

InhabitantTasks.defaultProps = {
    inhabitantData: () => {}
};

export default InhabitantTasks;
