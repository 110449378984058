/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles(() => ({
    popperElm: {
        marginBottom: '5px',
        fontSize: '12px'
    },
    popperText: {
        fontSize: '12px'
    }
}));

// Messages that are rendered if detected on object in 'dict' component
const messages = {
    firstName: { id: 'app.contractors.firstName' },
    lastName: { id: 'app.contractors.lastName' },
    first_name: { id: 'app.contractors.firstName' },
    last_name: { id: 'app.contractors.lastName' },
    full_name: { id: 'app.contractors.name' },
    identity_card_number: { id: 'app.contractors.idCard' }
};

function InfoComponentRenderer({ components }) {
    if (!components) {
        return null;
    }
    return [...components].map(({ type, data, sx }) => {
        switch (type) {
            case 'text':
                return (<TextComponent data={data} sx={sx} />);
            case 'list':
                return (<ListComponent data={data} />);
            case 'dict':
                return (<DictComponent data={data} />);
            case 'show-if':
                return data && (<InfoComponentRenderer components={data} />);
            default:
                return null;
        }
    });
}

function TextComponent({ data, sx }) {
    const intl = useIntl();
    const classes = useStyle();
    return (
        <div className={classes.popperElm}>
            <Typography className={classes.popperText} sx={sx || {}}>
                {data.map((item) => (
                    <Typography component='span' className={classes.popperText} sx={item?.sx || sx}>
                        {item?.id ? intl.formatMessage(item) : (item?.value || item)}
                    </Typography>
                ))}
            </Typography>
        </div>
    );
}

function ListComponent({ data }) {
    return (
        <ul>
            {data?.map((component) => (
                <li>
                    <InfoComponentRenderer components={[component]} />
                </li>
            ))}
        </ul>
    );
}

function DictComponent({ data }) {
    const toTitleCase = (rawKey) => [rawKey[0].toUpperCase(), ...rawKey.slice(1)].join('');
    return (
        <ul>
            {data && Object.entries(data).map(([rawKey, rawValue]) => {
                const key = messages[rawKey] || toTitleCase(rawKey);
                const value = typeof rawValue === 'object' && rawValue !== null
                    ? JSON.stringify(rawValue)
                    : rawValue || '---';
                return (
                    <li>
                        <TextComponent data={[key, ': ', value]} />
                    </li>
                );
            })}
        </ul>
    );
}

TextComponent.propTypes = {
    data: PropTypes.array.isRequired,
    sx: PropTypes.object
};

TextComponent.defaultProps = {
    sx: {}
};

ListComponent.propTypes = {
    data: PropTypes.array.isRequired
};

DictComponent.propTypes = {
    data: PropTypes.object.isRequired
};

InfoComponentRenderer.propTypes = {
    components: PropTypes.array.isRequired
};

export default InfoComponentRenderer;
export {
    TextComponent,
    ListComponent,
    DictComponent
};
