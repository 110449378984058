import React from 'react';
import PropTypes from 'prop-types';

function SelectedFlowStatus({ value, flowStatuses }) {
    const color = value?.color || flowStatuses.find((status) => status.id === value.id)?.color;

    return <span style={{ color }}>{value.name}</span>;
}

SelectedFlowStatus.propTypes = {
    value: PropTypes.object.isRequired,
    flowStatuses: PropTypes.array.isRequired
};

export default SelectedFlowStatus;
