import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import defaultContractorFormValues from './shared/data/defaultContractorFormValues';
import ContractorFormContacts from './shared/ui/ContractorFormContacts';
import ContractorFormAddresses from './shared/ui/ContractorFormAddresses';
import ContractorFormBankAccounts from './shared/ui/ContractorFormBankAccounts';
import ContractorFormHeader from './shared/ui/ContractorFormHeader';
import ContractorTasks from './shared/ui/ContractorTasks';
import ContractorIncomes from './shared/ui/ContractorIncomes';
import ContractorFormFooter from './shared/ui/ContractorFormFooter';
import ContractorInvoices from './shared/ui/ContractorInvoices';
import ContractorInspections from './shared/ui/ContractorInspections';
import ContractorVindications from './shared/ui/ContractorVindications';
import ContractorCorrespondence from './shared/ui/ContractorCorrespondence';
import ContractorInfrastructures from './shared/ui/ContractorInfrastructures';

const messages = {
    yourData: { id: 'app.contractors.yourData' },
    editContractor: { id: 'app.contractors.editContractor' },
    addContractor: { id: 'app.contractors.addContractor' }
};

const useStyle = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        '& .MuiGrid-item': {
            padding: '4px'
        }
    },
    title: {
        paddingBottom: theme.spacing(2),
        width: '100%',
        textAlign: 'center'
    }
}));

function ContractorForm({
    edit, onSubmit, inhabitantView, readOnly
}) {
    const { control, handleSubmit, reset } = useForm({ defaultValues: defaultContractorFormValues(edit) });
    const classes = useStyle();
    const [isSubmiting, setIsSubmiting] = useState(false);

    const clearForm = () => {
        reset(defaultContractorFormValues(edit));
    };

    // Prepare data that resembles the model structure of the one on backend
    // but implements only the fields that are used in the form
    const prepareData = (data) => ({
        id: edit?.id,
        user_id: data.user?._id,
        type: data.type.key,
        name: data.name,
        data: {
            first_name: data.firstName,
            last_name: data.lastName,
            nip: data.nip,
            regon: data.regon,
            pesel: data.pesel,
            krs: data.krs,
            identity_card_number: data.identity_card_number
        },
        bank_accounts: data.bankAccounts,
        address: data.address.map((address) => ({ ...address, type: address.type.key })),
        contacts: data.contacts.map((contact) => ({ ...contact, type: contact.type.key })),
        buyers: data.buyers.map((item) => item._id)
    });

    const handleSubmitForm = async (data) => {
        setIsSubmiting(true);
        if (await onSubmit(prepareData(data))) {
            if (!edit) {
                clearForm();
            }
        }
        setIsSubmiting(false);
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Typography component='h1' variant='h5' className={classes.title}>
                        {
                            readOnly ? (
                                <FormattedMessage id={messages.yourData.id} />
                            ) : (
                                <FormattedMessage
                                    id={edit ? messages.editContractor.id : messages.addContractor.id}
                                    values={edit ? { number: edit.numberUI } : null}
                                />
                            )
                        }
                    </Typography>
                    <ContractorFormHeader control={control} readOnly={readOnly} />
                    <ContractorFormContacts control={control} readOnly={readOnly} />
                    <ContractorFormAddresses control={control} readOnly={readOnly} />
                    <ContractorFormBankAccounts control={control} readOnly={readOnly} />
                    {
                        !inhabitantView && edit && (
                            <>
                                <ContractorTasks edit={edit} />
                                <ContractorIncomes edit={edit} />
                                <ContractorInvoices edit={edit} />
                                <ContractorInspections edit={edit} />
                                <ContractorVindications edit={edit} />
                                <ContractorCorrespondence edit={edit} />
                                <ContractorInfrastructures edit={edit} />
                            </>
                        )
                    }
                    {
                        !inhabitantView && (
                            <ContractorFormFooter edit={edit} clearData={clearForm} isSubmiting={isSubmiting} />
                        )
                    }
                </Grid>
            </div>
        </form>
    );
}

ContractorForm.propTypes = {
    edit: PropTypes.object,
    onSubmit: PropTypes.func,
    inhabitantView: PropTypes.bool,
    readOnly: PropTypes.bool
};

ContractorForm.defaultProps = {
    edit: null,
    onSubmit: () => true,
    inhabitantView: false,
    readOnly: false
};

export default ContractorForm;
