import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import FilesList from '../../../../common/FilesList/FilesList';
import UniversalCombobox, { comboboxTypes } from '../../../../common/Combobox/UniversalCombobox';

const messages = {
    bankStatement: { id: 'app.bankStatement.load' },
    verify: { id: 'app.import.verify' },
    addDirectly: { id: 'app.import.addDirectly' },
    confirm: { id: 'app.form.confirm' },
    uploadFile: { id: 'app.form.upload' },
    mustSelectFile: { id: 'app.form.mustSelectFile' },
    addManually: { id: 'app.menu.addManually' }
};

const useStyles = makeStyles((theme) => ({
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        verticalAlign: 'top'
    }
}));

function BankStatementsDialog({ open: propOpen, onSubmit }) {
    const history = useHistory();
    const classes = useStyles();
    const [verify, setVerify] = useState(true);
    const [files, setFiles] = useState([]);
    const [bankType, setBankType] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(propOpen);
    const intl = useIntl();
    const input = useRef(null);

    useEffect(() => {
        setOpen(propOpen);
    }, [propOpen]);

    const handleSubmit = () => {
        const data = {
            verify,
            files,
            bankType
        };
        if (files.length >= 1) {
            onSubmit(data);
            setOpen(false);
        } else {
            enqueueSnackbar(intl.formatMessage(messages.mustSelectFile, { file: 'xml, csv' }), { variant: 'warning' });
        }
        setFiles([]);
        input.current.value = '';
    };

    const handleClose = () => {
        history.push('/bankStatements/browse');
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id='form-dialog-title' onClose={handleClose}>
                    <FormattedMessage id={messages.bankStatement.id} />
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={handleClose}
                        aria-label='close'
                        className={classes.close}
                        size='large'
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography component='div'>
                        <Grid container alignItems='center' spacing={2}>
                            <Grid item xs={12}>
                                <UniversalCombobox
                                    src={comboboxTypes.bankTypes()}
                                    onMatch={(left, right) => left.key === right.key}
                                    label='Bank'
                                    name='bank'
                                    value={bankType}
                                    onChange={setBankType}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center' spacing={2}>
                            <Grid item><FormattedMessage id={messages.addDirectly.id} /></Grid>
                            <Grid item>
                                <Switch
                                    checked={verify}
                                    onChange={(event) => {
                                        setVerify(event.target.checked);
                                    }}
                                    name='verify'
                                    color='secondary'
                                />
                            </Grid>
                            <Grid item><FormattedMessage id={messages.verify.id} /></Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    component='label'
                                    disabled={!bankType}
                                >
                                    <FormattedMessage id={messages.uploadFile.id} />
                                    <input
                                        ref={input}
                                        type='file'
                                        hidden
                                        multiple
                                        accept='.xml,.csv'
                                        onChange={(event) => {
                                            const filesArray = Array.from(event.target.files);
                                            setFiles(filesArray);
                                        }}
                                    />
                                </Button>
                                <FilesList value={files} />
                            </Grid>
                        </Grid>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={handleSubmit}
                        color='primary'
                    >
                        <FormattedMessage id={messages.confirm.id} />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

BankStatementsDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default BankStatementsDialog;
