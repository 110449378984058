import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormattedMessage, useIntl } from 'react-intl';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import PasswordTextField from '../../../common/PasswordTextField/PasswordTextField';
import AcceptStepButton from './AcceptStepButton/AcceptStepButton';
import getErrorMessage from './ErrorsMessages/getErrorMessage';

const messages = {
    username: {
        id: 'app.signUpForm.username'
    },
    usernameError: {
        id: 'app.signUpError.usernameError'
    },
    password: {
        id: 'app.signUpForm.password'
    },
    passwordError: {
        id: 'app.signUpError.passwordError'
    },
    passwordConfirm: {
        id: 'app.signUpForm.passwordConfirm'
    },
    passwordConfirmError: {
        id: 'app.signUpError.passwordConfirmError'
    },
    signUpSuccess: {
        id: 'app.signUp.userCreated'
    },
    signUp: {
        id: 'app.signUp.button'
    }
};

const checkPasswordComplexity = (password) => {
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%*?^*()&])[A-Za-z\d!@#$%*?^*()&]{8,}$/;
    return pattern.test(password);
};

const checkPasswordsEquality = (password, confirmPassword) => password === confirmPassword;

function UserFormStep({ register, getValue, control }) {
    const [usernameError, setUsernameError] = useState(false);
    const [passwordEqualityError, setPasswordEqualityError] = useState(false);
    const [passwordComplexityError, setPasswordComplexityError] = useState(false);
    const intl = useIntl();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const resetErrors = () => {
        setPasswordComplexityError(false);
        setPasswordEqualityError(false);
    };
    const handleResetErrors = () => {
        if (passwordComplexityError || passwordEqualityError) {
            resetErrors();
        }
    };

    const acceptUserData = async () => {
        const username = getValue('username');
        const password = getValue('password');
        const confirmPassword = getValue('confirmPassword');
        const phone = getValue('phoneNumber');
        const code = getValue('smsCode');
        const isPasswordComplex = checkPasswordComplexity(password);
        const isPasswordsEqual = checkPasswordsEquality(password, confirmPassword);

        if (!username) {
            setUsernameError(true);
            return;
        }

        if (!isPasswordComplex) {
            setPasswordComplexityError(true);
            return;
        }
        if (!isPasswordsEqual) {
            setPasswordEqualityError(true);
            return;
        }

        const response = await RestRequestsHelper.signUp({
            username,
            password,
            confirmPassword,
            phone,
            code
        });
        if (response?.success) {
            history.push('/login');
            enqueueSnackbar(intl.formatMessage({ id: messages.signUpSuccess.id }), { variant: 'success' });
        } else {
            const errorCode = response?.error?.code;
            enqueueSnackbar(intl.formatMessage({ id: getErrorMessage(errorCode) }), { variant: 'error' });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        acceptUserData();
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='username'
                label={intl.formatMessage({ id: messages.username.id })}
                name='username'
                error={usernameError}
                helperText={usernameError && intl.formatMessage({ id: messages.usernameError.id })}
                autoFocus
                {...register('username', {
                    onChange: () => {
                        setUsernameError(false);
                    }
                })}
            />
            <Controller
                name='password'
                control={control}
                render={({ field }) => (
                    <PasswordTextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        error={passwordComplexityError}
                        helperText={passwordComplexityError && intl.formatMessage({ id: messages.passwordError.id })}
                        label={intl.formatMessage({ id: messages.password.id })}
                        id='password'
                        {...field}
                        onChange={(event) => field.onChange(event.target.value) && handleResetErrors()}
                        ref={null}
                    />
                )}
            />
            <Controller
                name='confirmPassword'
                control={control}
                render={({ field }) => (
                    <PasswordTextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        error={passwordComplexityError}
                        helperText={passwordComplexityError && intl.formatMessage({ id: messages.passwordError.id })}
                        label={intl.formatMessage({ id: messages.password.id })}
                        id='confirmPassword'
                        {...field}
                        onChange={(event) => field.onChange(event.target.value) && handleResetErrors()}
                        ref={null}
                    />
                )}
            />
            <AcceptStepButton>
                <FormattedMessage id={messages.signUp.id} />
            </AcceptStepButton>
        </form>
    );
}

UserFormStep.propTypes = {
    register: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired
};

export default UserFormStep;
