// eslint-disable-next-line import/prefer-default-export
export const user = (state = null, action = {}) => {
    switch (action.type) {
        case 'USER_SIGNED_IN':
            return {
                ...action.user
            };
        case 'USER_SIGNED_OUT':
            return null;
        default:
            return state;
    }
};
