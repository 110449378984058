import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { HotkeyProvider } from '../Hotkeys/Hotkeys';
import GridCellExpand from '../DataGridCells/GridCellExpand';
import { paginationAllowedValues } from '../../../constants/pagination';
import restRequestsHelper from '../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../lib/displaySnackbar';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import dateFormat from '../../../constants/dateFormat';
import FixedAddButton from '../FixedAddButton/FixedAddButton';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';
import formatUTCDate from '../../../lib/formatUTCDate';
import EditBankStatementsDialog, { editTypes } from './ui/EditBankStatementDialog';
import BANK_STATEMENT_RESPONSE from './data/bankStatementResponse';
import BankStatementsCustomToolbar from './ui/BankStatementsCustomToolbar';
import useColumns from './hooks/useColumns';

const useStyles = makeStyles((theme) => ({
    table: {
        paddingTop: theme.spacing(1),
        height: '91vh'
    },
    note: {
        textAlign: 'center'
    },
    main: {
        '& .missing-data': {
            backgroundColor: 'rgba(224, 183, 60, 0.55)',
            color: '#1a3e72',
            fontWeight: '600'
        }
    }
}));

const messages = {
    success: { id: 'app.bankStatement.success' },
    successMulti: { id: 'app.bankStatement.successMulti' },
    duplicatesFound: { id: 'app.bankStatement.duplicates' },
    edit: { id: 'app.bankStatement.edit' },
    remove: { id: 'app.bankStatement.remove' },
    fileImportFail: { id: 'app.bankStatement.fileImportFail' },
    bankAccountNotFound: { id: 'app.bankStatement.bankAccountNotFound' },
    noOperations: { id: 'app.bankStatement.noOperations' },
    omittedOperations: { id: 'app.bankStatement.operationsOmitted' },
    statementFail: { id: 'app.bankStatement.statementFail' },
    emptyStatementError: { id: 'app.bankStatement.emptyStatementError' },
    invalidStatementError: { id: 'app.bankStatement.invalidStatementError' },
    confirmRemoveTitle: { id: 'app.bankStatement.confirmRemoveTitle' },
    confirmRemoveText: { id: 'app.bankStatement.confirmRemoveText' }
};

function BankStatementsForm({
    open, data, metadata, multiple, onChange,
    insertable, removable, editable
}) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();
    const displaySnackbar = useDisplaySnackbar({ enqueueSnackbar, intl });
    const [isLoading, setIsLoading] = useState(false);
    const [fileIndex, setFileIndex] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [itemEditType, setItemEditType] = useState(editTypes.edit);
    // eslint-disable-next-line no-unused-vars
    const [itemToEditData, setItemToEditData] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [statements, setStatements] = useState([]);
    const [isRemoveDialog, setRemoveDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        if (data.length) {
            setIsLoading(false);
            setStatements(data);
            setTransactions(data[0]);
            setFileIndex(0);
            return;
        }
        setIsLoading(true);
    }, [data]);

    const updateData = async () => {
        if (statements.length > 0) {
            setIsLoading(true);
            setTransactions(statements[fileIndex]);
            setIsLoading(false);
            // TODO: Duplicates
            if (statements[fileIndex].omittedOperations) {
                displaySnackbar('warning', messages.omittedOperations);
            }
        }
    };

    useEffect(() => {
        updateData();
    }, [fileIndex, statements]);

    const deleteFile = () => {
        onChange({ amount: statements.length - 1, fileIndex });
        if (statements.length === 1) {
            setFileIndex(0);
            setStatements([]);
            setTransactions([]);
            setIsLoading(true);
        }
        if (statements.length > 1) {
            setStatements((oldStatements) => {
                const newStatements = [...oldStatements];
                newStatements.splice(fileIndex, 1);
                if (statements.length === fileIndex + 1 && fileIndex > 0) {
                    setFileIndex(fileIndex - 1);
                }
                return newStatements;
            });
        }
    };

    const validateStatement = () => {
        const keys = ['contractorId', 'date', 'reference', 'amount'];
        if (!transactions.length) {
            displaySnackbar('error', messages.emptyStatementError);
            return false;
        }
        if (!transactions.every((transaction) => keys.every((key) => transaction[key]))) {
            displaySnackbar('error', messages.invalidStatementError);
            return false;
        }
        return true;
    };

    const confirmStatement = async () => {
        if (!transactions.length) {
            displaySnackbar('error', messages.emptyStatementError);
            return;
        }
        if (!validateStatement()) return;
        setIsLoading(true);
        const bankType = metadata.find(((item) => item.bankType))?.bankType;
        const response = await restRequestsHelper.confirmBankStatement(transactions, bankType);
        switch (response.status) {
            case BANK_STATEMENT_RESPONSE.success:
                displaySnackbar('success', messages.success, {
                    name: metadata[0].entityName
                });
                deleteFile();
                break;
            case BANK_STATEMENT_RESPONSE.duplicate:
                displaySnackbar('warning', messages.duplicatesFound);
                break;
            default:
                displaySnackbar('error', messages.statementFail);
                break;
        }
        setIsLoading(false);
    };

    const handleAddNewStatement = () => {
        setItemEditType(editTypes.add);
        setItemToEditData(null);
        setShowEditDialog(true);
    };

    const generateID = () => {
        const maxId = Math.max(-1, ...transactions.map((item) => item.id));
        return maxId + 1;
    };

    const [anchorEl, setAnchorEl] = useState({});
    const handleClick = (event, id) => {
        const newValue = { ...anchorEl };
        newValue[id] = event.currentTarget;
        setAnchorEl(newValue);
    };

    const handleSubmitEdit = (transaction) => {
        const items = [...transactions];
        setShowEditDialog(false);
        if (itemEditType === editTypes.add) {
            items.unshift({
                ...transaction,
                id: generateID()
            });
        } else {
            const index = items.findIndex((operation) => operation.id === transaction.id);
            items[index] = transaction;
        }
        setTransactions(items);
    };

    const deleteValueAnchor = (item) => {
        const newValueAnchor = { ...anchorEl };
        newValueAnchor[item.id] = null;
        setAnchorEl(newValueAnchor);
    };

    const handleEdit = (gridItem) => {
        const transaction = transactions.find((operation) => operation.id === gridItem.id);
        setItemToEditData(transaction);
        setItemEditType(editTypes.edit);
        setShowEditDialog(true);
        deleteValueAnchor(gridItem);
    };

    const removeItem = (item) => {
        setSelectedRow(item);
        deleteValueAnchor(item);
        setRemoveDialog(true);
    };

    const handleRemove = (item) => {
        const newElements = transactions.filter((statement) => statement.id !== item.id);
        setTransactions(newElements);
    };

    const handleClose = (id) => {
        const newValue = { ...anchorEl };
        newValue[id] = null;
        setAnchorEl(newValue);
    };

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    const renderOptionsCell = (item) => {
        const itemRow = item.row;
        return (
            <>
                <IconButton onClick={(event) => handleClick(event, item.row.id)} size='large'>
                    <MoreVertIcon fontSize='inherit' />
                </IconButton>
                <Menu
                    id={`menu${item.row.id}`}
                    anchorEl={anchorEl[item.row.id]}
                    open={Boolean(anchorEl[item.row.id])}
                    onClose={() => handleClose(item.row.id)}
                >
                    {editable ? (
                        <MenuItem onClick={() => handleEdit(itemRow)}>
                            {intl.formatMessage({ id: messages.edit.id })}
                        </MenuItem>
                    ) : null}
                    {removable ? (
                        <MenuItem onClick={() => removeItem(itemRow)}>
                            {intl.formatMessage({ id: messages.remove.id })}
                        </MenuItem>
                    ) : null}
                </Menu>
            </>
        );
    };

    const displayDate = (params) => (
        <GridCellExpand value={formatUTCDate(params.value, dateFormat.format)} />
    );

    const columns = useColumns({ renderOptionsCell, displayDate });

    return (
        <div className={classes.main}>
            <EditBankStatementsDialog
                onSubmit={handleSubmitEdit}
                open={showEditDialog}
                data={itemToEditData}
                type={itemEditType}
                metadata={metadata[fileIndex]}
                onClose={handleCloseEditDialog}
            />
            <ConfirmDialog
                isOpen={isRemoveDialog}
                handleClose={setRemoveDialog}
                handleRemove={handleRemove}
                row={selectedRow}
                dialogTitle={messages.confirmRemoveTitle.id}
                dialogText={messages.confirmRemoveText.id}
            />
            <HotkeyProvider id='bankSt'>
                {
                    open
                    && (
                        <div className={classes.table}>
                            <DataGrid
                                rows={transactions}
                                columns={columns}
                                components={{
                                    Toolbar: BankStatementsCustomToolbar
                                }}
                                componentsProps={{
                                    toolbar: {
                                        fileIndex,
                                        setFileIndex,
                                        statements,
                                        metadata: metadata[fileIndex],
                                        deleteFile,
                                        confirmStatement,
                                        isLoading,
                                        multiple
                                    }
                                }}
                                // getRowClassName={(params) => isRowIncomplete(params.row) && classes.warning}
                                loading={isLoading}
                                pageSizeOptions={paginationAllowedValues}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'date', sort: 'asc' }]
                                    }
                                }}
                                columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                            />
                            {insertable ? (
                                <FixedAddButton onClick={handleAddNewStatement} />
                            ) : null}
                        </div>
                    )
                }
            </HotkeyProvider>
        </div>
    );
}

BankStatementsForm.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.array,
    metadata: PropTypes.array,
    multiple: PropTypes.bool,
    insertable: PropTypes.bool,
    editable: PropTypes.bool,
    removable: PropTypes.bool,
    onChange: PropTypes.func
};

BankStatementsForm.defaultProps = {
    open: true,
    data: [],
    metadata: [],
    multiple: false,
    insertable: false,
    editable: false,
    removable: false,
    onChange: () => {}
};

export default BankStatementsForm;
