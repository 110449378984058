import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import 'tippy.js/animations/shift-away-subtle.css';

const cellStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .cellValue': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    altValue: {
        opacity: '0.5',
        fontSize: '12px',
        marginLeft: '10px'
    },
    tipContent: {
        padding: theme.spacing(1),
        wordBreak: 'break-word',
        whiteSpace: 'pre-line'
    },
    pointer: {
        cursor: 'pointer'
    }
}));

// This function works like Vue's v-bind:class directive,
// as React does not support this type of class handling
const applyClass = (classes) => (
    Object.entries(classes)
        .map(([name, bool]) => (bool ? name : null))
        .filter((value) => value)
        .join(' ')
);

const GridCellExpand = React.memo(({
    value, data, alt, showPointer, showOnlyAlt, color, modifyValue
}) => {
    const classes = cellStyles();
    const preparedData = Array.isArray(data) ? data : [data];
    return (
        <Tippy
            animation='shift-away-subtle'
            placement='top-start'
            content={(
                <Paper elevation={1}>
                    <Typography variant='body2' className={classes.tipContent} sx={{ color }}>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {showOnlyAlt ? alt
                            : !data ? value : preparedData?.map((item) => (
                                <span style={{ color: item?.color, marginLeft: 3 }}>
                                    {item.name ?? item}
                                </span>
                            )) || '-'}
                        {!showOnlyAlt && (
                            <span className={classes.altValue}>
                                { alt }
                            </span>
                        )}
                    </Typography>
                </Paper>
            )}
        >
            <div className={classes.root}>
                <span className={applyClass({ cellValue: true, [classes.pointer]: showPointer })} style={{ color }}>
                    {!data ? modifyValue(value) : preparedData?.map((item) => (
                        <span style={{ color: item?.color, marginLeft: 3 }}>
                            {item.name ?? item}
                        </span>
                    )) || '-'}
                </span>
            </div>
        </Tippy>
    );
});

GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([
        PropTypes.array,
        null
    ]),
    showPointer: PropTypes.bool,
    showOnlyAlt: PropTypes.bool,
    alt: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    color: PropTypes.string,
    modifyValue: PropTypes.func
};

GridCellExpand.defaultProps = {
    // use data prop for displaying multiple colored entries.
    data: null,
    alt: '',
    showPointer: false,
    showOnlyAlt: false,
    color: '',
    modifyValue: (val) => val
};

export function renderCellExpand(params, color, modifyValue) {
    return (
        <GridCellExpand
            value={params.value ? params.value.toString() : ''}
            data={params?.data}
            showPointer={params.showPointer}
            alt={params.alt}
            showOnlyAlt={params.showOnlyAlt}
            color={color}
            modifyValue={modifyValue}
        />
    );
}

renderCellExpand.propTypes = {
    /**
     * The column of the row that the current cell belongs to.
     */
    colDef: PropTypes.any.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.number,
        PropTypes.object,
        PropTypes.string,
        PropTypes.bool
    ]),
    color: PropTypes.string,
    onClick: PropTypes.func,
    modifyValue: PropTypes.func
};

renderCellExpand.defaultProps = {
    onClick: () => {},
    modifyValue: (val) => (val)
};
export default GridCellExpand;
