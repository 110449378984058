import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import * as PropType from 'prop-types';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        textAlign: 'center',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3)
    }
}));

function InfrastructureCard({ name, id, onClick }) {
    const styles = useStyles();
    return (
        <Card onClick={() => onClick(id)}>
            <CardContent className={styles.cardContent}>
                <Typography variant='body1' component='div'>
                    {name}
                </Typography>
            </CardContent>
        </Card>
    );
}

InfrastructureCard.propTypes = {
    name: PropType.string.isRequired,
    id: PropType.string,
    onClick: PropType.func
};

InfrastructureCard.defaultProps = {
    id: '',
    onClick: () => {}
};

export default InfrastructureCard;
