import useSWR from 'swr';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';

const useInfrastructuresFromType = (typeId) => {
    if (typeId !== null && typeId !== undefined) {
        const { data, error, mutate } = RestRequestsHelper.getInfrastructuresFromType(useSWR, typeId);
        return {
            parentsOptions: data || [],
            loading: !error && !data,
            mutate
        };
    }
    return {
        parentsOptions: [],
        loading: false
    };
};

export default useInfrastructuresFromType;
