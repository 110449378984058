import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import {
    Button, Dialog,
    DialogContent, DialogTitle,
    IconButton, Typography,
    Box, Collapse, Table,
    TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Paper
} from '@mui/material';
import { Close } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { renderCellExpand } from '../../../DataGridCells/GridCellExpand';
import dateFormat from '../../../../../constants/dateFormat';

const messages = {
    history: { id: 'app.history.history' },
    author: { id: 'app.history.author' },
    date: { id: 'app.history.date' },
    showChanges: { id: 'app.history.showChanges' },
    field: { id: 'app.history.field' },
    status: { id: 'app.history.status' },
    before: { id: 'app.history.before' },
    after: { id: 'app.history.after' },
    updated: { id: 'app.history.updated' },
    added: { id: 'app.history.added' },
    removed: { id: 'app.history.removed' }
};

function Row({ row }) {
    const intl = useIntl();
    const [open, setOpen] = useState(false);

    const prepareStatus = (status) => {
        let translation = null;
        switch (status) {
            case 'updated':
                translation = messages.updated;
                break;
            case 'added':
                translation = messages.added;
                break;
            case 'removed':
                translation = messages.removed;
                break;
            default:
                return '-';
        }
        return intl.formatMessage(translation);
    };

    const prepareCellValue = (val) => {
        if (!val) {
            return val?.toString();
        }
        if (typeof val === 'object') {
            return '[Object]';
        }
        return val?.toString();
    };

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component='th' scope='row'>
                    {row.field}
                </TableCell>
                <TableCell>{prepareStatus(row.status)}</TableCell>
                <TableCell align='right'>{prepareCellValue(row?.before)}</TableCell>
                <TableCell align='right'>{prepareCellValue(row?.after)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant='h6' gutterBottom component='div'>
                                <FormattedMessage id={messages.before.id} />
                            </Typography>
                            <pre>
                                {JSON.stringify(row?.before)}
                            </pre>

                            <Typography variant='h6' gutterBottom component='div'>
                                <FormattedMessage id={messages.after.id} />
                            </Typography>
                            <pre>
                                {JSON.stringify(row?.after, null, 2)}
                            </pre>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        field: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        before: PropTypes.any,
        after: PropTypes.any
    }).isRequired
};

const AdditionalOptionsCell = React.memo(({ params }) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <Button sx={{ ml: 'auto' }} variant='outlined' onClick={handleOpen}>
                {intl.formatMessage(messages.showChanges)}
            </Button>
            <Dialog
                fullWidth
                maxWidth='lg'
                open={open}
                onClose={handleClose}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormattedMessage id={messages.history.id} />
                    <IconButton onClick={handleClose} size='large'>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table aria-label='collapsible table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>{intl.formatMessage(messages.field)}</TableCell>
                                    <TableCell>{intl.formatMessage(messages.status)}</TableCell>
                                    <TableCell align='right'>{intl.formatMessage(messages.before)}</TableCell>
                                    <TableCell align='right'>{intl.formatMessage(messages.after)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(params?.row?.data || []).map((row, i) => (
                                    <Row key={i} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </>
    );
});

const useColumns = () => {
    const intl = useIntl();
    return [
        {
            field: 'createdAt',
            headerName: intl.formatMessage(messages.date),
            width: 180,
            headerAlign: 'center',
            type: 'date',
            valueGetter: ({ value }) => new Date(value),
            valueFormatter: (params) => dayjs(params.value).format(dateFormat.fullDate)
        },
        {
            field: 'author',
            headerName: intl.formatMessage(messages.author),
            width: 180,
            headerAlign: 'center',
            renderCell: ({ row }) => renderCellExpand({ data: row?.author })
        },
        {
            field: 'id',
            headerName: ' ',
            flex: 1,
            sortable: false,
            renderCell: (params) => <AdditionalOptionsCell params={params} />
        }
    ];
};

AdditionalOptionsCell.propTypes = {
    params: PropTypes.object.isRequired
};

export default useColumns;
