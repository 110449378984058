import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';
import RemoveIcon from '@mui/icons-material/Remove';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { debounce, has } from 'lodash';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import GroupList from '../../Pages/Invoices/AddInvoice/GroupsList/GroupList';
import InvoicePositionsTitlesCombobox from '../Combobox/InvoicePositionsTitlesCombobox';
import RestRequestsHelper, { getToken } from '../../../lib/restRequestsHelper';
import dateFormat from '../../../constants/dateFormat';
import FormPickerControl from '../FormPicker/FormPickerControl';
import FormPickerElement from '../FormPicker/FormPickerElement';
import MoneyTextField from '../MoneyTextField/MoneyTextField';
import config from '../../../config';
import formatMoney from '../../../lib/formatMoney';
import UniversalCombobox, { comboboxTypes } from '../Combobox/UniversalCombobox';
import MeasurementUnit from '../MeasuermentUnit/MeasurementUnit';
import useMeasureUnits from '../../Pages/Infrastructures/hooks/useMeasureUnits';
import SelectedFlowStatus from '../../Pages/Invoices/shared/ui/SelectedFlowStatus';
import FormHeader from '../FormHeader/FormHeader';
import defaultInvoice from './InvoiceDefault';
import './InvoiceForm.css';

const messages = {
    addInvoice: { id: 'app.addInvoice.header' },
    contractor: { id: 'app.addInvoice.contractor' },
    documentNumber: { id: 'app.addInvoice.documentNumber' },
    buyer: { id: 'app.addInvoice.buyer' },
    content: { id: 'app.addInvoice.content' },
    contentAmount: { id: 'app.addInvoice.contentAmount' },
    dateOfInvoice: { id: 'app.addInvoice.dateOfInvoice' },
    dateOfPayment: { id: 'app.addInvoice.dateOfPayment' },
    bankAccount: { id: 'app.addInvoice.bankAccount' },
    amount: { id: 'app.addInvoice.amount' },
    add: { id: 'app.addInvoice.add' },
    saleDate: { id: 'app.addInvoice.saleDate' },
    seller: { id: 'app.addInvoice.seller' },
    mediaInvoice: { id: 'app.addInvoice.mediaInvoice' },
    group: { id: 'app.addInvoice.group' },
    clear: { id: 'app.addInvoice.clear' },
    description: { id: 'app.addInvoice.description' },
    setName: { id: 'app.addInvoice.setName' },
    dateOkButton: { id: 'app.invoiceForm.OkButton' },
    dateCancelButton: { id: 'app.invoiceForm.CancelButton' },
    dateTodayButton: { id: 'app.invoiceForm.todayButton' },
    dateOfDelivery: { id: 'app.addInvoice.dateOfDelivery' },
    save: { id: 'app.settings.saveBtn' },
    flowStatus: { id: 'app.invoices.flowStatus' },
    contentUnitAmount: { id: 'app.addIncome.contentUnitAmount' },
    contentUnitValue: { id: 'app.addIncome.contentUnitValue' },
    generate: { id: 'app.addIncome.generate' },
    premise: { id: 'app.payoffs.premise' },
    remarks: { id: 'app.correspondence.add.remarks' },
    documentType: { id: 'app.form.documentType' }
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        height: '100%',
        margin: 'auto',
        minHeight: '80vh',
        '& .MuiGrid-item': {
            padding: '4px'
        }
    },
    button: {
        marginTop: theme.spacing(2)
    },
    radio: {
        marginTop: theme.spacing(2)
    },
    form: {
        height: '100%',
        maxHeight: '90vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: 10,
        paddingLeft: 10
    },
    progress: {
        marginTop: 'auto',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    center: {
        display: 'inline-block',
        position: 'relative',
        top: '55%',
        transform: 'translate(0, -50%)'
    },
    left: {
        textAlign: 'left'
    },
    formPickerElementInput: {
        '& textarea': {
            paddingRight: 35
        }
    },
    buttons: {
        bottom: theme.spacing(1),
        zIndex: 1,
        position: 'sticky'
    }
}));
const fetchFlowStatuses = async (db) => {
    const token = getToken();
    const url = `${config.backendHost}:${config.backendPort}/invoice/statuses?db=${db}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });

    return res.json();
};
const getDescription = debounce((title, callback, db) => {
    RestRequestsHelper.getInvoicePositionDescription(title, db)
        .then((response) => {
            callback(response);
        });
}, 500);

function InvoiceForm({
    invoiceFormData,
    onChange,
    onSubmit,
    onClear,
    onClose,
    children,
    isLoading,
    isContentLoading,
    isManualInvoice,
    handleInvoiceType,
    invoiceType,
    saveButton,
    flowStatus,
    className,
    db,
    incomeForm,
    readOnly,
    onFormValidChange,
    addingForm
}) {
    const classes = useStyles();
    const intl = useIntl();
    const [amountsValid, setAmountsValid] = useState(false);
    const locale = useSelector((state) => state.locale);
    const { data: flowStatuses, status: statusOfResponse } = useQuery('flowStatuses', () => fetchFlowStatuses(db));
    const { measureUnits } = useMeasureUnits();

    const convertStringAmountToInteger = (amount) => {
        if (!amount) return 0;
        if (typeof amount === 'string') {
            let result;
            if (amount.includes('.') || amount.includes(',')) {
                let data;
                if (amount.includes('.')) {
                    data = amount.split('.');
                } else if (amount.includes(',')) {
                    data = amount.split(',');
                }

                if (data[1].length === 0) {
                    data[1] = '00';
                } else if (data[1].length === 1) {
                    data[1] = `${data[1]}0`;
                }
                result = Number.parseInt(`${data[0].replace(/\s+/g, '')}${data[1]}`);
            } else {
                result = Number.parseInt(`${amount.replace(/\s+/g, '')}00`);
            }

            return result;
        }
        // eslint-disable-next-line no-bitwise
        return (amount.toFixed(2) * 100 || 0);
    };
    const sumPositions = () => (invoiceFormData.content.reduce((prev, item) => prev + convertStringAmountToInteger(item.value), 0));

    const getValue = (key, index) => {
        const data = invoiceFormData;
        if (!data) {
            return '';
        }
        if (key === 'content' || key === 'setNames') {
            return data[key][index];
        }
        return data[key];
    };

    const isAmountValid = (value) => {
        if (!value) {
            return false;
        }
        // eslint-disable-next-line no-bitwise
        return !Number.isNaN(convertStringAmountToInteger(value).toFixed(2)) || value === '';
    };

    const isFormInvalid = (data) => {
        const formData = data || invoiceFormData;

        const isAmountValidForUpload = (amount) => isAmountValid(amount) && amount !== '';
        const positionAmountsInvalid = formData.content
            ? formData.content.some((item) => !isAmountValidForUpload(item.value)) : false;
        const mainAmountInvalid = !isAmountValidForUpload(getValue('amount'));
        const sumOfPositions = sumPositions();
        const sumIsIncorrect = sumOfPositions !== convertStringAmountToInteger(getValue('amount'));

        let invalidData = false;
        formData.content.forEach((item) => {
            if (!has(item, 'title.id') || !has(item, 'title.title') || !item?.description) {
                invalidData = true;
            }
            if (incomeForm && (!item.unit || !item?.unit_value || !item?.amount || !item?.value)) {
                invalidData = true;
            }
        });

        if (
            !formData?.seller
            || formData?.buyer.length === 0
            || !formData?.documentNumber
            || !formData?.dateOfDelivery
            || !formData?.dateOfInvoice
            || !formData?.dateOfPayment
            || !formData?.saleDate
        ) {
            invalidData = true;
        }

        return positionAmountsInvalid || mainAmountInvalid || sumIsIncorrect || invalidData;
    };

    const handleOnChange = (data) => {
        const isInvalid = isFormInvalid(data);
        onFormValidChange(!isInvalid);
        onChange(data);
    };

    const handleInvoiceFlowStatusChange = (event) => {
        const newValues = { ...invoiceFormData };
        const { value } = event.target;
        newValues.flowStatus = value;
        handleOnChange(newValues);
    };
    moment.locale(locale);

    const submitInvoice = (event) => {
        let valid = true;
        invoiceFormData.content.forEach((item) => {
            if (!has(item, 'title.id') || !has(item, 'title.title')) {
                valid = false;
            }
        });
        if (valid) {
            onSubmit(event);
        } else {
            event.preventDefault();
        }
    };

    const updateForm = (event, index) => {
        const amountRegex = /^-?[\d ]*\.?,?(\d{1,2})?$/;
        const { name, value } = event.target;
        const newValues = { ...invoiceFormData };
        if (name === 'content') {
            newValues.content[index].title = value;
        } else if (name === 'contentAmount') {
            if (amountRegex.test(value)) {
                newValues.content[index].value = value;
            }
        } else if (name === 'description') {
            newValues.content[index].description = value;
        } else if (name === 'setName') {
            newValues.setNames[index].name = value;
        } else if (name === 'amount') {
            if (amountRegex.test(value)) {
                newValues[name] = value;
            }
        } else if (name === 'saleDate' || name === 'dateOfDelivery'
                || name === 'dateOfInvoice' || name === 'dateOfPayment') {
            newValues[name] = value;
        } else if (name === 'group') {
            newValues[name] = value.id;
        } else if (name === 'contentUnit') {
            newValues.content[index].unit = value;
        } else if (name === 'contentUnitAmount') {
            newValues.content[index].amount = value;
        } else if (name === 'contentUnitValue') {
            newValues.content[index].unit_value = value;
        } else if (name === 'infrastructure') {
            newValues.content[index].infrastructure = value;
        } else {
            newValues[name] = value;
        }
        handleOnChange(newValues);
    };

    const expandContent = () => {
        const newValues = { ...invoiceFormData };
        newValues.content.push(defaultInvoice().content[0]);
        handleOnChange(newValues);
    };

    useEffect(() => {
        const totalAmount = convertStringAmountToInteger(getValue('amount'));
        if (invoiceFormData) {
            const content = invoiceFormData.content
                .map((item) => convertStringAmountToInteger(item.value))
                .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
            setAmountsValid(totalAmount === content);
        }
    }, [invoiceFormData]);

    const removeContent = (index) => {
        const newValues = { ...invoiceFormData };
        newValues.content.splice(index, 1);
        handleOnChange(newValues);
    };

    const clearForm = () => {
        onClear();
        handleOnChange(defaultInvoice());
    };

    const updatePosition = (value, index, updateDesc = true) => {
        updateForm({
            target:
            {
                name: 'content',
                value
            }
        }, index);
        if (updateDesc && value) {
            const form = invoiceFormData;
            if (!form.content[index].description.trim().length) {
                getDescription(
                    value.id,
                    (response) => {
                        if (response.description) {
                            updateForm({
                                target: {
                                    name: 'description',
                                    value: response.description
                                }
                            }, index);
                        }
                    },
                    db
                );
            }
        }
    };

    const formPickerChange = (data) => {
        const [name, index] = data.name.split('-');
        const newValues = { ...invoiceFormData };
        if (index?.length) {
            newValues.content[index][name] = data.value;
        } else {
            newValues[name] = data.value;
        }
        handleOnChange(newValues);
    };

    const handleUnitChange = (value, index) => {
        const ev = { target: { name: 'contentUnit', value } };
        updateForm(ev, index);
    };

    const handleMoneySetValue = (name, index) => (value) => {
        if (value?.target) {
            updateForm(value, index);
        } else {
            const event = { target: { value, name } };
            updateForm(event, index);
        }
    };

    const getMoneyValue = (value) => {
        let preparedValue = value;
        if (value === 'NaN') {
            preparedValue = '0,00';
        } else if (!value) {
            preparedValue = '';
        }
        return preparedValue;
    };

    const sumPositionsAutomatically = () => {
        const event = { target: { value: formatMoney(sumPositions() / 100), name: 'amount' } };
        updateForm(event);
    };

    const generateDocumentNumber = async () => {
        const { data } = await RestRequestsHelper.generateIncomeNumber(getValue('seller')?._id, getValue('type')?.id, getValue('saleDate'));
        updateForm({ target: { name: 'documentNumber', value: data?.number } });
    };

    const handlePredictAmount = (index) => {
        const { unit_value: unitValue, amount } = getValue('content', index);

        if (unitValue && amount) {
            const preparedVal = (convertStringAmountToInteger(unitValue) / 100) * (convertStringAmountToInteger(amount) / 100);
            handleMoneySetValue('contentAmount', index)(formatMoney(preparedVal));
        }
    };

    const handlePremiseSetValue = (value, index) => {
        const ev = { target: { name: 'infrastructure', value } };
        updateForm(ev, index);
    };

    const buyerDataSrc = () => {
        const SPOLKA_TYPE = 8;
        const buyer = getValue('seller');
        const idBuyer = buyer?._id;
        if (idBuyer && buyer?.type?.key === SPOLKA_TYPE && incomeForm) {
            return comboboxTypes.entitiesOfCommunityByName([idBuyer]);
        }
        return comboboxTypes.contractors();
    };
    const handleIncomeTypeChange = (value) => {
        const ev = { target: { name: 'type', value } };
        updateForm(ev);
    };

    const bankAccountsSrc = () => {
        const entityId = invoiceFormData?.seller?._id;
        if (entityId) {
            return comboboxTypes.bankNumbers(entityId);
        }
        return comboboxTypes.empty();
    };

    const renderBankAccountOptionLabel = (val) => {
        if (val?.name) {
            return `${val?.number} (${val?.name})`;
        }
        return val?.number || val;
    };

    return (
        <Container component='main' sx={addingForm ? {} : { padding: '0 !important' }}>
            <CssBaseline />
            <Paper className={Object.keys(className).length > 0 ? className : classes.root}>
                {isLoading ? <div className={classes.progress}><CircularProgress /></div> : (
                    <>
                        <FormHeader
                            label={messages.addInvoice.id}
                            onDelete={() => {
                                RestRequestsHelper.deleteInvoiceIncome(invoiceFormData._id);
                                onClose();
                            }}
                            hideDelete={readOnly || addingForm}
                        />
                        <form className={classes.form} onSubmit={submitInvoice}>
                            <Grid container spacing={1}>
                                <FormPickerControl onChange={formPickerChange}>
                                    <Grid item xs={12} sm={6}>
                                        <UniversalCombobox
                                            id='seller'
                                            label={intl.formatMessage(messages.seller)}
                                            src={comboboxTypes.contractors()}
                                            onChange={(data) => {
                                                updateForm({ target: { name: 'seller', value: data } });
                                            }}
                                            value={getValue('seller')}
                                            optionLabel={({ name }) => name}
                                            margin='normal'
                                            displayInfo
                                            required
                                            showColors
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <UniversalCombobox
                                            multiple
                                            limit={incomeForm ? 10 : 1}
                                            id='buyer'
                                            label={intl.formatMessage(messages.buyer)}
                                            src={buyerDataSrc()}
                                            onChange={(data) => {
                                                updateForm({ target: { name: 'buyer', value: data } });
                                            }}
                                            value={getValue('buyer')}
                                            optionLabel={({ name }) => name}
                                            margin='normal'
                                            displayInfo
                                            required
                                            showColors
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    {incomeForm && (
                                        <Grid item xs={12} sm={12}>
                                            <UniversalCombobox
                                                src={bankAccountsSrc()}
                                                label={intl.formatMessage(messages.bankAccount)}
                                                optionLabel={renderBankAccountOptionLabel}
                                                margin='normal'
                                                onChange={(newVal) => updateForm({ target: { name: 'bankAccount', value: newVal } })}
                                                value={getValue('bankAccount')}
                                                freeSolo
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={incomeForm ? 4 : 6}>
                                        <FormPickerElement name='documentNumber' disabled={incomeForm}>
                                            <TextField
                                                variant='outlined'
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='documentNumber'
                                                picker='documentNumber'
                                                multiline
                                                label={(
                                                    <FormattedMessage
                                                        id={messages.documentNumber.id}
                                                    />
                                                )}
                                                name='documentNumber'
                                                autoComplete='documentNumber'
                                                value={getValue('documentNumber')}
                                                onChange={updateForm}
                                                className={classes.formPickerElementInput}
                                                disabled={readOnly}
                                            />
                                        </FormPickerElement>
                                    </Grid>
                                    {
                                        incomeForm && (
                                            <Grid item xs={2} margin='auto'>
                                                <Button disabled={!getValue('seller') || !getValue('type') || !getValue('saleDate')} onClick={generateDocumentNumber}>
                                                    {intl.formatMessage(messages.generate)}
                                                </Button>
                                            </Grid>
                                        )
                                    }
                                    <Grid item xs={readOnly ? 12 : 11} sm={readOnly ? 6 : 5}>
                                        <FormPickerElement name='amount' disabled={incomeForm}>
                                            <MoneyTextField
                                                value={getMoneyValue(getValue('amount'))}
                                                onChange={handleMoneySetValue('amount')}
                                                picker='amount'
                                                textFieldParams={{
                                                    variant: 'outlined',
                                                    margin: 'normal',
                                                    required: true,
                                                    fullWidth: true,
                                                    id: 'amount',
                                                    error: !amountsValid || !isAmountValid(getValue('amount')),
                                                    label: intl.formatMessage(messages.amount),
                                                    name: 'amount',
                                                    multiline: true,
                                                    className: classes.formPickerElementInput
                                                }}
                                                disabled={readOnly}
                                            />
                                        </FormPickerElement>
                                    </Grid>
                                    {
                                        !readOnly && (
                                            <Grid item xs={1} margin='auto'>
                                                <Button onClick={sumPositionsAutomatically}>SUM</Button>
                                            </Grid>
                                        )
                                    }
                                    <Grid item container>
                                        {invoiceFormData.content && !isContentLoading && invoiceFormData.content.map((item, index) => (
                                            <Box sx={{
                                                borderColor: 'gray',
                                                borderRadius: 1,
                                                borderWidth: 2,
                                                borderStyle: 'solid',
                                                p: 1,
                                                pl: 2,
                                                ml: 0,
                                                mr: invoiceFormData.content.length > 1 ? 1 : 0,
                                                my: 0.5,
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}
                                            >
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    sx={{ width: '100%' }}
                                                >
                                                    <Grid item xs={12} sm={incomeForm ? 6 : 3}>
                                                        <InvoicePositionsTitlesCombobox
                                                            onChange={
                                                                (event, newValue, updateDesc) => {
                                                                    updatePosition(newValue, index, updateDesc);
                                                                }
                                                            }
                                                            value={getValue('content', index).title}
                                                            fieldName={`invoicePositionTitle-${index}`}
                                                            db={db}
                                                            disabled={readOnly}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormPickerElement name={`description-${index}`} disabled={incomeForm}>
                                                            <TextField
                                                                variant='outlined'
                                                                margin='normal'
                                                                required
                                                                fullWidth
                                                                id={`description-${index}`}
                                                                label={
                                                                    intl.formatMessage(
                                                                        { id: messages.description.id }
                                                                    )
                                                                }
                                                                name='description'
                                                                value={getValue('content', index).description}
                                                                onChange={
                                                                    (event) => updateForm(event, index)
                                                                }
                                                                multiline
                                                                className={classes.formPickerElementInput}
                                                                disabled={readOnly}
                                                            />
                                                        </FormPickerElement>
                                                    </Grid>

                                                    {incomeForm && (
                                                        <>
                                                            <Grid item xs={12} sm={3}>
                                                                <MeasurementUnit
                                                                    onChange={(newVal) => handleUnitChange(newVal, index)}
                                                                    options={measureUnits || []}
                                                                    value={getValue('content', index).unit}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <MoneyTextField
                                                                    value={getMoneyValue(getValue('content', index).amount)}
                                                                    onChange={(val) => {
                                                                        handleMoneySetValue('contentUnitAmount', index)(val);
                                                                        handlePredictAmount(index);
                                                                    }}
                                                                    picker={`unit_amount-${index}`}
                                                                    textFieldParams={{
                                                                        id: `unit_amount-${index}`,
                                                                        variant: 'outlined',
                                                                        margin: 'normal',
                                                                        required: true,
                                                                        fullWidth: true,
                                                                        label: intl.formatMessage(messages.contentUnitAmount),
                                                                        name: 'contentUnitAmount',
                                                                        multiline: true,
                                                                        className: classes.formPickerElementInput
                                                                    }}
                                                                    disabled={readOnly}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <FormPickerElement name={`unit_value-${index}`} disabled={incomeForm}>
                                                                    <MoneyTextField
                                                                        value={getMoneyValue(getValue('content', index).unit_value)}
                                                                        onChange={(val) => {
                                                                            handleMoneySetValue('contentUnitValue', index)(val);
                                                                            handlePredictAmount(index);
                                                                        }}
                                                                        picker={`unit_value-${index}`}
                                                                        textFieldParams={{
                                                                            id: `unit_value-${index}`,
                                                                            variant: 'outlined',
                                                                            margin: 'normal',
                                                                            required: true,
                                                                            fullWidth: true,
                                                                            label: intl.formatMessage(messages.contentUnitValue),
                                                                            name: 'contentUnitValue',
                                                                            multiline: true,
                                                                            className: classes.formPickerElementInput
                                                                        }}
                                                                        disabled={readOnly}
                                                                    />
                                                                </FormPickerElement>
                                                            </Grid>
                                                        </>
                                                    )}
                                                    <Grid item xs={12} sm={3}>
                                                        <FormPickerElement name={`amount-${index}`} disabled={incomeForm}>
                                                            <MoneyTextField
                                                                value={getMoneyValue(getValue('content', index).value)}
                                                                onChange={handleMoneySetValue('contentAmount', index)}
                                                                picker={`amount-${index}`}
                                                                textFieldParams={{
                                                                    id: `amount-${index}`,
                                                                    variant: 'outlined',
                                                                    margin: 'normal',
                                                                    required: true,
                                                                    fullWidth: true,
                                                                    error: !amountsValid || !isAmountValid(getValue('content', index).value),
                                                                    label: intl.formatMessage(messages.contentAmount),
                                                                    name: 'contentAmount',
                                                                    multiline: true,
                                                                    className: classes.formPickerElementInput
                                                                }}
                                                                disabled={readOnly}
                                                            />
                                                        </FormPickerElement>
                                                    </Grid>
                                                    {incomeForm && (
                                                        <Grid item xs={12}>
                                                            <UniversalCombobox
                                                                id='premise'
                                                                label={intl.formatMessage(messages.premise)}
                                                                src={comboboxTypes.premises(invoiceFormData?.seller?._id)}
                                                                onChange={(newVal) => {
                                                                    handlePremiseSetValue(newVal, index);
                                                                }}
                                                                value={getValue('content', index).infrastructure}
                                                                optionLabel={({ name }) => name}
                                                                required={getValue('type')?.is_premise_required ?? false}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                {((invoiceFormData.content.length > 1 || incomeForm) && !readOnly) && (
                                                    <Grid item xs={12} sm={1}>
                                                        <IconButton
                                                            className={classes.button}
                                                            onClick={
                                                                () => { removeContent(index); }
                                                            }
                                                            size='large'
                                                        >
                                                            <RemoveIcon fontSize='small' />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Box>

                                        ))}
                                    </Grid>
                                    {isContentLoading && (
                                        <Grid item xs={12} sm={12}>
                                            <div className={classes.progress}><CircularProgress /></div>
                                        </Grid>
                                    )}
                                    {
                                        !readOnly && (
                                            <Grid item xs={12} sm={12}>
                                                <IconButton onClick={expandContent} color='primary' component='span' size='large' disabled={readOnly}>
                                                    <Add />
                                                </IconButton>
                                            </Grid>
                                        )
                                    }
                                    <Grid item xs={12} sm={3}>
                                        <CustomDatePicker
                                            disableToolbar
                                            variant='outlined'
                                            inputFormat={dateFormat.format}
                                            required
                                            fullWidth
                                            margin='normal'
                                            id='dateOfDelivery'
                                            label={(
                                                <FormattedMessage
                                                    id={messages.dateOfDelivery.id}
                                                />
                                            )}
                                            value={getValue('dateOfDelivery')}
                                            onChange={(date) => {
                                                updateForm({ target: { name: 'dateOfDelivery', value: date } });
                                            }}
                                            okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                                            cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                                            showTodayButton
                                            todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                                            renderInput={(params) => (<TextField {...params} required id='dateOfDelivery' />)}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <CustomDatePicker
                                            disableToolbar
                                            variant='outlined'
                                            inputFormat={dateFormat.format}
                                            required
                                            fullWidth
                                            margin='normal'
                                            id='dateOfInvoice'
                                            label={(
                                                <FormattedMessage
                                                    id={messages.dateOfInvoice.id}
                                                />
                                            )}
                                            value={getValue('dateOfInvoice')}
                                            onChange={(date) => {
                                                updateForm({ target: { name: 'dateOfInvoice', value: date } });
                                            }}
                                            okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                                            cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                                            showTodayButton
                                            todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                                            renderInput={(params) => (<TextField {...params} required id='dateOfInvoice' />)}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <CustomDatePicker
                                            disableToolbar
                                            variant='outlined'
                                            inputFormat={dateFormat.format}
                                            margin='normal'
                                            id='saleDate'
                                            fullWidth
                                            label={(
                                                <FormattedMessage
                                                    id={messages.saleDate.id}
                                                />
                                            )}
                                            required
                                            value={getValue('saleDate')}
                                            onChange={(date) => {
                                                updateForm({ target: { name: 'saleDate', value: date } });
                                            }}
                                            okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                                            cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                                            showTodayButton
                                            todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                                            renderInput={(params) => (<TextField {...params} required id='saleDate' />)}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <CustomDatePicker
                                            disableToolbar
                                            variant='outlined'
                                            inputFormat={dateFormat.format}
                                            margin='normal'
                                            id='dateOfPayment'
                                            fullWidth
                                            label={(
                                                <FormattedMessage
                                                    id={messages.dateOfPayment.id}
                                                />
                                            )}
                                            required
                                            value={getValue('dateOfPayment')}
                                            onChange={(date) => {
                                                updateForm({ target: { name: 'dateOfPayment', value: date } });
                                            }}
                                            okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                                            cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                                            showTodayButton
                                            todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                                            renderInput={(params) => (<TextField {...params} required id='dateOfPayment' />)}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    {incomeForm && (
                                        <Grid item xs={12} sm={12}>
                                            <FormPickerElement name='remarks' disabled={incomeForm}>
                                                <TextField
                                                    variant='outlined'
                                                    margin='normal'
                                                    fullWidth
                                                    id='remarks'
                                                    multiline
                                                    label={(
                                                        <FormattedMessage
                                                            id={messages.remarks.id}
                                                        />
                                                    )}
                                                    name='remarks'
                                                    autoComplete='remarks'
                                                    value={getValue('remarks')}
                                                    onChange={updateForm}
                                                    className={classes.formPickerElementInput}
                                                    disabled={readOnly}
                                                />
                                            </FormPickerElement>
                                        </Grid>
                                    )}

                                    {isManualInvoice && !incomeForm
                                        ? (
                                            <Grid item xs={12} sm={5}>
                                                <UniversalCombobox
                                                    id='invoiceType'
                                                    onChange={handleInvoiceType}
                                                    value={invoiceType}
                                                    required
                                                    src={comboboxTypes.documentTypes('invoice')}
                                                    label={intl.formatMessage(messages.documentType)}
                                                    disabled={readOnly}
                                                    margin='normal'
                                                />
                                            </Grid>
                                        ) : null}

                                    {!incomeForm && (

                                        <Grid item xs={12} sm={isManualInvoice ? 4 : 12}>
                                            <GroupList
                                                onChange={(event, value) => {
                                                    updateForm({ target: { name: 'group', value } });
                                                }}
                                                value={getValue('group')}
                                                db={db}
                                                disabled={readOnly}
                                            />
                                        </Grid>

                                    )}
                                    {!incomeForm && (
                                        <Grid item xs={12} sm={isManualInvoice ? 3 : 12}>
                                            <FormControlLabel
                                                className={classes.center}
                                                control={(
                                                    <Checkbox
                                                        checked={getValue('isMediaInvoice')}
                                                        onChange={(event) => {
                                                            updateForm({ target: { name: 'isMediaInvoice', value: event.target.checked } });
                                                        }}
                                                        name='mediaInvoice'
                                                        disabled={readOnly}
                                                    />
                                                )}
                                                label={
                                                    intl.formatMessage({ id: messages.mediaInvoice.id })
                                                }
                                            />
                                        </Grid>
                                    )}
                                    {incomeForm && (
                                        <Grid item xs={12}>
                                            <UniversalCombobox
                                                onChange={handleIncomeTypeChange}
                                                value={getValue('type')}
                                                required
                                                src={comboboxTypes.documentTypes('income')}
                                                label={intl.formatMessage(messages.documentType)}
                                            />
                                        </Grid>
                                    )}

                                    {flowStatus && statusOfResponse === 'success' && (
                                        <Grid item xs={12} sm={12}>
                                            <FormControl fullWidth required>
                                                <InputLabel id='flowStatusesSelect'><FormattedMessage id={messages.flowStatus.id} /></InputLabel>
                                                <Select
                                                    label={<FormattedMessage id={messages.flowStatus.id} />}
                                                    labelId='flowStatusesSelect'
                                                    className={classes.left}
                                                    value={(flowStatuses || []).find((item) => item.id === invoiceFormData.flowStatus?.id) || ''}
                                                    onChange={handleInvoiceFlowStatusChange}
                                                    renderValue={(val) => (
                                                        <SelectedFlowStatus value={val} flowStatuses={flowStatuses} />
                                                    )}
                                                >
                                                    {flowStatuses && flowStatuses.map((item) => (
                                                        <MenuItem
                                                            className={classes.left}
                                                            key={item.id}
                                                            value={item}
                                                            sx={{ color: item.color }}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}

                                    {
                                        !readOnly && (
                                            <Grid container className={classes.buttons}>
                                                <Grid item xs={6} sx={{ p: '4px', pl: '9px' }}>
                                                    <Button
                                                        onClick={clearForm}
                                                        fullWidth
                                                        variant='contained'
                                                        color='secondary'
                                                    >
                                                        <FormattedMessage
                                                            id={messages.clear.id}
                                                        />
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6} sx={{ p: '4px' }}>
                                                    <Button
                                                        type='submit'
                                                        fullWidth
                                                        variant='contained'
                                                        color='primary'
                                                        disabled={isFormInvalid()}
                                                    >
                                                        {!saveButton ? (
                                                            <FormattedMessage
                                                                id={messages.add.id}
                                                            />
                                                        ) : (
                                                            <FormattedMessage
                                                                id={messages.save.id}
                                                            />
                                                        )}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    <Grid item xs={12} sm={12}>
                                        {children}
                                    </Grid>
                                </FormPickerControl>
                            </Grid>
                        </form>
                    </>
                )}
            </Paper>
        </Container>
    );
}
InvoiceForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    invoiceFormData: PropTypes.object,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onClose: PropTypes.func,
    children: PropTypes.element,
    isLoading: PropTypes.bool,
    isContentLoading: PropTypes.bool,
    handleInvoiceType: PropTypes.func,
    invoiceType: PropTypes.object,
    isManualInvoice: PropTypes.bool,
    saveButton: PropTypes.bool,
    flowStatus: PropTypes.bool,
    className: PropTypes.object,
    db: PropTypes.string,
    incomeForm: PropTypes.bool,
    readOnly: PropTypes.bool,
    onFormValidChange: PropTypes.func,
    addingForm: PropTypes.bool
};

InvoiceForm.defaultProps = {
    invoiceFormData: null,
    onChange: () => {},
    onClear: () => {},
    onClose: () => {},
    children: null,
    isLoading: false,
    isContentLoading: false,
    invoiceType: {},
    handleInvoiceType: () => {},
    isManualInvoice: null,
    saveButton: false,
    flowStatus: false,
    className: {},
    db: 'mongo',
    incomeForm: false,
    readOnly: false,
    onFormValidChange: () => {},
    addingForm: false
};
export default InvoiceForm;
