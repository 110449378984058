import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import makeStyles from '@mui/styles/makeStyles';
import { changeLangToPl, changeLangToEn } from '../../../actions';

const useStyles = makeStyles((theme) => ({
    buttonGroup: {
        marginRight: '10px',
        color: 'black',
        background: theme.palette.secondary
    }
}));

function LanguageSelection() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const locale = useSelector((state) => state.locale);

    useEffect(() => {
        localStorage.setItem('locale', locale);
    }, [locale]);

    return (
        <div>
            <ButtonGroup className={classes.buttonGroup} disableElevation disableFocusRipple disableRipple color='secondary' size='small' variant='outlined'>
                <Button variant={locale === 'pl' ? 'contained' : 'outlined'} onClick={() => dispatch(changeLangToPl())}>
                    pl
                </Button>
                <Button variant={locale === 'pl' ? 'outlined' : 'contained'} onClick={() => dispatch(changeLangToEn())}>
                    en
                </Button>
            </ButtonGroup>
        </div>
    );
}

export default LanguageSelection;
