import { Buffer } from 'buffer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { clone, isEmpty } from 'lodash';
import InvoiceForm from '../../../../common/InvoiceForm/InvoiceForm';
import FormPickerMaster from '../../../../common/FormPicker/FormPickerMaster';
import restRequestsHelper from '../../../../../lib/restRequestsHelper';
import log from '../../../../../lib/logger';
import InvoicePreview from '../../../../common/DocumentPreview/DocumentPreview';
import DuplicatedInvoiceDialog from '../DuplicatedInvoiceDialog/DuplicatedInvoiceDialog';
import defaultInvoice from '../../../../common/InvoiceForm/InvoiceDefault';
import { useHotkeyListener, HotkeyProvider } from '../../../../common/Hotkeys/Hotkeys';
import errors, { statuses } from '../../../../../constants/ResponseCodes';
import fileType from '../../../../../constants/FileType';
import DocumentPreviewMenu from '../../../../common/DocumentPreview/DocumentPreviewMenu/DocumentPreviewMenu';
import ExcelErrorsSnackbar from '../../../../common/CustomSnackbars/ExcelErrorsSnackbar';
import closeSnackbarComponent from '../../../../common/CustomSnackbars/Utils/closeSnackbarComponent';
import useDisplaySnackbar from '../../../../../lib/displaySnackbar';
import InvoiceProperties from './components/InvoiceProperties/InvoiceProperties';

const messages = {
    successAdding: { id: 'app.importInvoice.success' },
    successAddingSingle: { id: 'app.addInvoice.success' },
    errorAdding: { id: 'app.importInvoice.error' },
    notParsedCorrectly: { id: 'app.addInvoice.notParsedCorrectly' },
    alreadyAdded: { id: 'app.addInvoice.alreadyAdded' },
    addingFailure: { id: 'app.addInvoice.addingFailure' },
    docName: { id: 'app.addInvoice.docName' },
    numbers: { id: 'app.addInvoice.numbers' },
    filenameDuplicated: { id: 'app.addInvoice.filenameDuplicated' },
    filesLimitAlmostReached: { id: 'app.addInvoice.filesLimit' },
    ftpInvalidPath: { id: 'app.addInvoice.ftpInvalidPath' },
    ftpInvalidAuth: { id: 'app.addInvoice.ftpInvalidAuth' },
    ftpInvalidAddr: { id: 'app.addInvoice.ftpInvalidAddr' },
    cannotPreparePreview: { id: 'app.addInvoice.cannotPreparePreview' },
    unknownFiletype: { id: 'app.addInvoice.unknownFileType' },
    emptyContent: { id: 'app.addInvoice.emptyContent' },
    addedDocuments: { id: 'app.addInvoice.addedDocuments' }
};

function ImportInvoice() {
    const intl = useIntl();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory();
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });
    const user = useSelector((state) => state.user);
    const [openImportDialog, setOpenImportDialog] = useState(true);
    const [pdf, setPdf] = useState();
    const [invoiceFiles, setInvoiceFiles] = useState([]);
    const [invoiceType, setInvoiceType] = useState(null);
    const [invoiceIndex, setInvoiceIndex] = useState(0);
    const [openDuplicatedDialog, setOpenDuplicatedDialog] = useState(false);
    const [duplicatedInvoiceData, setDuplicatedInvoicedata] = useState({});
    const [duplicatedInvoiceDataForDirectlyAdding,
        setDuplicatedInvoicedataDirectlyAdding] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [invoiceFormData, setInvoiceFormData] = useState(defaultInvoice());
    const [docName, setDocName] = useState('');
    const [addedInvoiceNumbers, setAddedInvoiceNumbers] = useState([]);
    const [rtfs, setRtfs] = useState([]);
    const [xlsDocs, setXlsDocs] = useState([]);
    const [type, setType] = useState(fileType.pdf);
    const [rtfPreview, setRtfPreview] = useState(null);

    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, []);
    const displayErrorInfo = (error) => {
        log.error(error.msg);
        if (error.code) {
            let msg = '';
            switch (error.code) {
                case errors.duplicatedFile:
                    msg = intl.formatMessage({ id: messages.filenameDuplicated.id });
                    break;
                case errors.filesLimitAlmostReached:
                    msg = intl.formatMessage({ id: messages.filesLimitAlmostReached.id });
                    break;
                case errors.ftpInvalidPath:
                    msg = intl.formatMessage({ id: messages.ftpInvalidPath.id });
                    break;
                case errors.ftpInvalidAuth:
                    msg = intl.formatMessage({ id: messages.ftpInvalidAuth.id });
                    break;
                case errors.ftpInvalidAddr:
                    msg = intl.formatMessage({ id: messages.ftpInvalidAddr.id });
                    break;
                case errors.cannotPreparePreview:
                    msg = intl.formatMessage({ id: messages.cannotPreparePreview.id });
                    break;
                case errors.unknownFiletype:
                    msg = intl.formatMessage({ id: messages.unknownFiletype.id });
                    break;
                default:
                    break;
            }
            enqueueSnackbar(`[${error.code}] ${msg}`, { variant: 'error' });
        } else {
            enqueueSnackbar(intl.formatMessage({ id: messages.errorAdding.id }), { variant: 'error' });
        }
    };
    const getInvoiceData = () => {
        if (invoiceFiles[invoiceIndex]) {
            setIsLoading(true);

            restRequestsHelper.getInvoiceData(invoiceFiles[invoiceIndex])
                .then((response) => {
                    if (response.type === fileType.pdf) {
                        if (isEmpty(response.data.buyer)) {
                            response.data.buyer = [];
                        }
                        if (isEmpty(response.data.seller)) {
                            response.data.seller = null;
                        }
                        setInvoiceFormData(response.data);
                        setType(fileType.pdf);
                    }
                    if (!isUploading) {
                        setIsLoading(false);
                    }
                });
        }
    };

    const updateRtfData = () => {
        setInvoiceFormData(rtfs[invoiceIndex]);
    };

    const updateXlsData = () => {
        if (xlsDocs[invoiceIndex]) {
            setInvoiceFormData(xlsDocs[invoiceIndex]);
        }
    };

    useEffect(() => {
        if (type === fileType.pdf && invoiceFiles.length > 0) {
            setPdf(invoiceFiles[invoiceIndex]);
            setDocName(invoiceFiles[invoiceIndex].name);
            if (duplicatedInvoiceDataForDirectlyAdding.some(
                (item) => item.index === invoiceFiles[invoiceIndex].id
            )) {
                setOpenDuplicatedDialog(true);
                const data = duplicatedInvoiceDataForDirectlyAdding
                    .find((item) => item.index === invoiceFiles[invoiceIndex].id).foundInvoices[0];
                setDuplicatedInvoicedata(data);
                enqueueSnackbar(intl.formatMessage({ id: messages.alreadyAdded.id }), { variant: 'warning' });
                setDuplicatedInvoicedataDirectlyAdding((currentData) => {
                    const newData = [...currentData];
                    newData
                        .splice(newData
                            .findIndex((element) => element.index === invoiceFiles[invoiceIndex].id), 1);
                    return newData;
                });
            }
        } else if (type === fileType.rtf && invoiceFiles.length > 0) {
            if (duplicatedInvoiceDataForDirectlyAdding.some(
                (item) => item.index === rtfs[invoiceIndex].index
            )) {
                setOpenDuplicatedDialog(true);
                const data = duplicatedInvoiceDataForDirectlyAdding
                    .find((item) => item.index === rtfs[invoiceIndex].index).foundInvoices[0];
                setDuplicatedInvoicedata(data);
                enqueueSnackbar(intl.formatMessage({ id: messages.alreadyAdded.id }), { variant: 'warning' });
                setDuplicatedInvoicedataDirectlyAdding((currentData) => {
                    const newData = [...currentData];
                    newData
                        .splice(newData
                            .findIndex((element) => element.index === rtfs[invoiceIndex].index), 1);
                    return newData;
                });
            }
        } else if (type === fileType.xls && xlsDocs.length > 0) {
            if (duplicatedInvoiceDataForDirectlyAdding.some(
                (item) => item.index === rtfs[invoiceIndex].index
            )) {
                setOpenDuplicatedDialog(true);
                const data = duplicatedInvoiceDataForDirectlyAdding
                    .find((item) => item.index === xlsDocs[invoiceIndex].index).foundInvoices[0];
                setDuplicatedInvoicedata(data);
                enqueueSnackbar(intl.formatMessage({ id: messages.alreadyAdded.id }), { variant: 'warning' });
                setDuplicatedInvoicedataDirectlyAdding((currentData) => {
                    const newData = [...currentData];
                    newData
                        .splice(newData
                            .findIndex((element) => element.index === rtfs[invoiceIndex].index), 1);
                    return newData;
                });
            }
        }
    }, [invoiceFormData]);

    useEffect(() => {
        if (type === fileType.pdf) {
            getInvoiceData();
        }
    }, [invoiceFiles]);

    useEffect(() => {
        if (type === fileType.pdf) {
            getInvoiceData();
        } else if (type === fileType.rtf && rtfs.length > 0) {
            updateRtfData();
            setDocName(undefined);
        } else if (type === fileType.xls) {
            updateXlsData();
        }
    }, [invoiceIndex, invoiceFiles, rtfs, xlsDocs]);

    const wipeInvoiceData = () => {
        setInvoiceFormData(defaultInvoice());
        setDocName('');
        setPdf();
    };

    const addPdfDirectly = async (pdfFiles, pdfInvoiceType) => {
        const response = await restRequestsHelper
            .addInvoiceFileDirectly(pdfFiles, pdfInvoiceType);

        if (response.status === statuses.error) {
            displayErrorInfo(response);
        } else if (response.status === statuses.notParsedCorrectly) {
            setOpenImportDialog(false);
            log.warn(response);
            let pdfsData = [];
            const filesIndexes = response.invoiceFiles.map((item) => item.index);
            pdfsData = pdfFiles.filter((item, index) => filesIndexes.includes(index));
            setInvoiceType(pdfInvoiceType);
            wipeInvoiceData();
            if (response.duplicatedInvoices.length > 0) {
                const dupFilesIndexes = response.duplicatedInvoices
                    .map((item) => (item.index));
                const dupFiles = pdfFiles.filter((item, index) => dupFilesIndexes.includes(index));
                const { duplicatedInvoices } = response;
                const start = pdfsData.length;
                pdfsData = pdfsData.concat(dupFiles);
                for (let i = 0; i < duplicatedInvoices.length; i += 1) {
                    const newValue = duplicatedInvoices[i];
                    newValue.index = i + start;
                    duplicatedInvoices[i] = newValue;

                    pdfsData[i + start].id = i + start;
                }
                setDuplicatedInvoicedataDirectlyAdding(duplicatedInvoices);
            }

            setInvoiceIndex(0);
            setInvoiceFiles(pdfsData);
            setPdf(pdfsData[0]);

            const dataResponse = await restRequestsHelper.getInvoiceData(pdfsData[0]);

            setInvoiceFormData(dataResponse.data);
            setType(fileType.pdf);
            if (!isUploading) {
                setIsLoading(false);
            }
            enqueueSnackbar(intl.formatMessage({ id: messages.notParsedCorrectly.id }), { variant: 'warning' });
        } else if (response.duplicatedInvoices.length > 0) {
            const { duplicatedInvoices } = response;
            let pdfsData = [];
            const start = pdfsData.length;
            const dupFilesIndexes = response.duplicatedInvoices
                .map((item) => (item.index));
            const dupFiles = pdfFiles.filter((item, index) => dupFilesIndexes.includes(index));
            pdfsData = pdfsData.concat(dupFiles);
            for (let i = 0; i < duplicatedInvoices.length; i += 1) {
                const newValue = duplicatedInvoices[i];
                newValue.index = i + start;
                duplicatedInvoices[i] = newValue;

                pdfsData[i + start].id = i + start;
            }
            setDuplicatedInvoicedataDirectlyAdding(duplicatedInvoices);
            const filesIndexes = response.duplicatedInvoices
                .map((item) => (item.index));
            const files = pdfFiles.filter((item, index) => filesIndexes.includes(index));
            setInvoiceFiles(files);
            setInvoiceType(pdfInvoiceType);
            wipeInvoiceData();
            setOpenImportDialog(false);
            enqueueSnackbar(intl.formatMessage({ id: messages.addingFailure.id }), { variant: 'warning' });
        } else {
            const successMsg = intl.formatMessage({ id: messages.successAdding.id });
            const numbersMsg = intl.formatMessage(messages.numbers);
            let message = successMsg;
            if (response.invoiceNumber) {
                message = `${successMsg} [${numbersMsg}: ${response.invoiceNumber}]`;
            }
            enqueueSnackbar(
                message,
                { variant: 'success', autoHideDuration: 20 * 1000 }
            );
            setOpenImportDialog(true);
            wipeInvoiceData();
            history.push('/');
        }
        setIsLoading(false);
    };

    const addRtfDirectly = async (file, rtfInvoiceType) => {
        log.info('addRtfDirectly, file', file);
        const response = await restRequestsHelper.addInvoiceRtfFileDirectly([file], rtfInvoiceType);
        log.debug('addRtfDirectly, response', response);
        if (response.status === statuses.error) {
            displayErrorInfo(response);
        } else if (response.status === statuses.notParsedCorrectly) {
            setOpenImportDialog(false);
            log.warn(response);
            wipeInvoiceData();
            const invoiceIndexes = response.invoiceFiles.map((item) => item.index);
            setInvoiceFiles([file]);
            setInvoiceIndex(0);
            setInvoiceType(rtfInvoiceType);
            if (response.duplicatedInvoices.length > 0) {
                const { duplicatedInvoices } = response;
                setDuplicatedInvoicedataDirectlyAdding(duplicatedInvoices);
                duplicatedInvoices.map((item) => item.index).forEach(((item) => {
                    invoiceIndexes.push(item);
                }));
            }
            const rtfData = await restRequestsHelper.getInvoiceData(file);
            log.debug('addRtfDirectly, rtfData', rtfData);
            log.debug('addRtfDirectly, rtfData.data', rtfData.data);
            setRtfs(rtfData.data.filter((item) => invoiceIndexes.includes(item.index)));
            const filePdf = new File([Buffer.from(rtfData.pdf, 'base64')], 'preview.pdf', {
                type: 'application/pdf'
            });
            log.debug('addRtfDirectly, filePdf', filePdf);
            setRtfPreview(filePdf);
            setType(fileType.rtf);
            setInvoiceFormData(rtfData.data.filter((item) => invoiceIndexes.includes(item.index))[0]);
            enqueueSnackbar(intl.formatMessage({ id: messages.notParsedCorrectly.id }), { variant: 'warning' });
        } else if (response.duplicatedInvoices.length > 0) {
            setOpenImportDialog(false);
            setInvoiceFiles([file]);
            setInvoiceIndex(0);
            setInvoiceType(rtfInvoiceType);
            const { duplicatedInvoices } = response;
            setDuplicatedInvoicedataDirectlyAdding(duplicatedInvoices);
            const invoiceIndexes = response.duplicatedInvoices.map((item) => item.index);
            const rtfData = await restRequestsHelper.getInvoiceData(file);
            setRtfs(rtfData.data.filter((item) => invoiceIndexes.includes(item.index)));
            const filePdf = new File([Buffer.from(rtfData.pdf, 'base64')], 'preview.pdf', {
                type: 'application/pdf'
            });
            setRtfPreview(filePdf);
            setType(fileType.rtf);
            setInvoiceFormData(rtfData.data.filter((item) => invoiceIndexes.includes(item.index))[0]);
            enqueueSnackbar(intl.formatMessage({ id: messages.addingFailure.id }), { variant: 'warning' });
        } else {
            const successMsg = intl.formatMessage({ id: messages.successAdding.id });
            const numbersMsg = intl.formatMessage(messages.numbers);
            let message = successMsg;
            if (response.invoiceNumber) {
                message = `${successMsg} [${numbersMsg}: ${response.invoiceNumber}]`;
            }
            enqueueSnackbar(
                message,
                { variant: 'success', autoHideDuration: 20 * 1000 }
            );
            setOpenImportDialog(true);
            wipeInvoiceData();
            history.push('/');
        }
        setIsLoading(false);
    };

    const addSheetDirectly = async (files, queryData) => {
        const response = await restRequestsHelper
            .addDocsFromSheetDirectly(files, queryData);
        wipeInvoiceData();
        setType(fileType.xls);

        setIsLoading(false);

        if (response.status === 'error') {
            enqueueSnackbar(response.error, { variant: 'error' });
        }

        if (response.status === 'success') {
            if (response.errors) {
                enqueueSnackbar(<ExcelErrorsSnackbar errors={response.errors} />, { variant: 'error', autoHideDuration: 60 * 1000, action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar) });
            }

            if (response.duplicatedData) {
                enqueueSnackbar(<ExcelErrorsSnackbar errors={response.duplicates} />, { variant: 'warning', autoHideDuration: 60 * 1000, action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar) });
                setXlsDocs(response.duplicatedData);
                setInvoiceFormData(response.duplicatedData[0]);
                setInvoiceType(queryData.documentType);
                setOpenImportDialog(false);
            }

            if (response.invoiceNumbers) {
                displaySnackbar('success', messages.addedDocuments, { number: response.invoiceNumbers.length });
            }
        }
    };

    const handleSubmitDialog = async (data) => {
        setIsLoading(true);
        const files = Array.from(data.files);
        if (data.verify) {
            setInvoiceType(data.invoiceType);
            setOpenImportDialog(false);
            setInvoiceFormData(defaultInvoice());
            setInvoiceFiles(files);
            setPdf(files[0]);
            if (files[0].type.includes('rtf') || files[0].type.includes('msword') || files[0].type.includes('octet-stream')) {
                const response = await restRequestsHelper.getInvoiceData(files[0]);
                setType(fileType.rtf);
                setRtfs(response.data);
                setInvoiceFormData(response.data[0]);
                const filePdf = new File([Buffer.from(response.pdf, 'base64')], 'preview.pdf', {
                    type: 'application/pdf'
                });
                setRtfPreview(filePdf);
                setIsLoading(false);
            } else if (files[0].type.includes('spreadsheet') || files[0].type.includes('numbers')) {
                setType(fileType.xls);
                const filteredFiles = files.filter((file) => file.type.includes('spreadsheet') || file.type.includes('numbers'));
                const response = await restRequestsHelper.getDocumentsFromSheet(filteredFiles, { kind: 1 });
                if (response.error) {
                    enqueueSnackbar(response.error, { variant: 'error' });
                    setIsLoading(false);
                }
                if (response.data?.length > 0) {
                    setInvoiceFormData(response.data[0]);
                    setXlsDocs(response.data);
                }
                if (response.errors) {
                    enqueueSnackbar(<ExcelErrorsSnackbar errors={response.errors} />, { variant: 'error', autoHideDuration: 60 * 1000, action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar) });
                }
                if (response.duplicates) {
                    enqueueSnackbar(<ExcelErrorsSnackbar errors={response.duplicates} />, { variant: 'error', autoHideDuration: 60 * 1000, action: (snackbarId) => closeSnackbarComponent(snackbarId, closeSnackbar) });
                }
                setIsLoading(false);
            }
        } else if (files[0].type.includes('rtf') || files[0].type.includes('msword') || files[0].type.includes('octet-stream')) {
            addRtfDirectly(files[0], data.invoiceType);
        } else if (files[0].type.includes('pdf')) {
            const filteredFiles = files.filter((file) => file.type.includes('pdf'));
            addPdfDirectly(filteredFiles, data.invoiceType);
        } else {
            const filteredFiles = files.filter((file) => file.type.includes('spreadsheet') || file.type.includes('numbers'));
            addSheetDirectly(filteredFiles, { kind: 1, documentType: data.invoiceType });
        }
    };

    const handleFormChange = (data) => {
        setInvoiceFormData(data);
    };
    const isContentCorrect = (data) => {
        let valid = true;
        data.content.forEach((item) => {
            if (item.title === '' || item.description === '') {
                valid = false;
            }
        });
        return valid;
    };

    const getFilesByType = () => {
        let files;
        switch (type) {
            case fileType.pdf: {
                files = invoiceFiles;
                break;
            }
            case fileType.rtf: {
                files = rtfs;
                break;
            }
            case fileType.xls: {
                files = xlsDocs;
                break;
            }
            default: {
                files = [];
                break;
            }
        }
        return files;
    };

    const handleFilesChange = (filesType) => {
        if (filesType === fileType.pdf) {
            setInvoiceFiles((oldPdfs) => {
                const newPdfs = [...oldPdfs];
                newPdfs.splice(invoiceIndex, 1);
                if (newPdfs.length > 0) {
                    setInvoiceFormData(defaultInvoice());
                }
                return newPdfs;
            });
        } else if (filesType === fileType.rtf) {
            setRtfs((oldRtfs) => {
                const newRtfs = [...oldRtfs];
                newRtfs.splice(invoiceIndex, 1);
                if (newRtfs.length > 0) {
                    setInvoiceFormData(defaultInvoice());
                }
                return newRtfs;
            });
        } else if (filesType === fileType.xls) {
            setXlsDocs((oldXlsDocs) => {
                const newXlsDocs = [...oldXlsDocs];
                newXlsDocs.splice(invoiceIndex, 1);
                if (newXlsDocs.length > 0) {
                    setInvoiceFormData(defaultInvoice());
                }
                return newXlsDocs;
            });
        }
    };

    const handleAddInvoiceResponse = (files, addingResult) => {
        const successMsg = intl.formatMessage({ id: messages.successAdding.id });
        const successSingleMsg = intl.formatMessage({ id: messages.successAddingSingle.id });
        const numbersMsg = intl.formatMessage(messages.numbers);

        if (addingResult.status === statuses.error) {
            displayErrorInfo(addingResult);
        } else if (addingResult.status === statuses.alreadyAdded) {
            enqueueSnackbar(intl.formatMessage({ id: messages.alreadyAdded.id }), { variant: 'warning' });
            setOpenDuplicatedDialog(true);
            setDuplicatedInvoicedata(addingResult.foundInvoices[0]);
        } else if (files.length === 1) {
            if (type === fileType.rtf) {
                setRtfs([]);
            }
            setInvoiceFiles([]);
            setRtfPreview(undefined);
            setType(fileType.pdf);
            setPdf(undefined);
            setOpenImportDialog(true);
            wipeInvoiceData();
            addedInvoiceNumbers.push(addingResult.invoiceNumber);
            enqueueSnackbar(
                `${successMsg} [${numbersMsg}: ${addedInvoiceNumbers.join(', ')}]`,
                { variant: 'success', autoHideDuration: 20 * 1000 }
            );
            setAddedInvoiceNumbers([]);
        } else if (files.length > 1) {
            if (files.length === invoiceIndex + 1) {
                setInvoiceIndex(invoiceIndex - 1);
            }
            handleFilesChange(type);
            const { invoiceNumber } = addingResult;
            addedInvoiceNumbers.push(invoiceNumber);
            let message = successSingleMsg;
            if (invoiceNumber) {
                message = `${successSingleMsg} [${numbersMsg}: ${invoiceNumber}]`;
            }
            enqueueSnackbar(
                message,
                { variant: 'success', autoHideDuration: 20 * 1000 }
            );
        }
    };

    const addInvoice = async (forceAdd = false) => {
        setIsLoading(true);
        setIsUploading(true);
        const data = clone(invoiceFormData);

        data.invoiceType = invoiceType;

        data.filesMetadata = type === fileType.xls ? [] : [{
            fileName: pdf?.name,
            name: docName
        }];
        const files = [pdf];

        if (isContentCorrect(data)) {
            const addingResult = await restRequestsHelper.addInvoice(data, files, forceAdd);
            setIsUploading(false);
            handleAddInvoiceResponse(getFilesByType(), addingResult);
        } else {
            enqueueSnackbar(intl.formatMessage({ id: messages.emptyContent.id }), { variant: 'warning' });
        }

        setIsLoading(false);
    };

    const handleSubmitInvoiceForm = (event) => {
        event.preventDefault();
        addInvoice();
    };

    const handleForceAdd = () => {
        setOpenDuplicatedDialog(false);
        addInvoice(true);
    };

    const handleInvoiceCancel = () => {
        const files = getFilesByType();
        setOpenDuplicatedDialog(false);
        if (files.length === invoiceIndex + 1 && invoiceIndex > 0) {
            setInvoiceIndex(invoiceIndex - 1);
        }
        if (files.length > 0) {
            if (files.length === 1) {
                wipeInvoiceData();
                setInvoiceFiles([]);
                setRtfs([]);
                setXlsDocs([]);
                setType(fileType.pdf);
                setOpenImportDialog(true);
            }
            handleFilesChange(type);
        }
    };

    const previewPdf = () => (
        <>
            <DocumentPreviewMenu
                onInvoiceCancel={handleInvoiceCancel}
                invoiceIndex={invoiceIndex}
                setInvoiceIndex={setInvoiceIndex}
                filesTotal={invoiceFiles.length}
            />
            <InvoicePreview
                file={pdf}
                currentFileName={docName}
                handleChangeCurrentFileName={(event) => setDocName(event?.target?.value)}
                allowEditingFileName
                showFileName
            />
        </>
    );

    const previewRtf = () => (
        <>
            <DocumentPreviewMenu
                onInvoiceCancel={handleInvoiceCancel}
                invoiceIndex={invoiceIndex}
                setInvoiceIndex={setInvoiceIndex}
                filesTotal={rtfs.length}
            />
            <InvoicePreview
                file={rtfPreview}
                page={invoiceFormData.index + 1}
                disablePageChange
            />
        </>
    );

    function HotkeysController() {
        const prevent = ['INPUT'];
        useHotkeyListener('index:ArrowLeft', () => {
            const { tagName } = document.activeElement;
            if (invoiceIndex > 0 && !prevent.includes(tagName)) {
                setInvoiceIndex(invoiceIndex - 1);
            }
        });
        useHotkeyListener('index:ArrowRight', () => {
            const { tagName } = document.activeElement;
            if (invoiceIndex + 1 < invoiceFiles.length && !prevent.includes(tagName)) {
                setInvoiceIndex(invoiceIndex + 1);
            }
        });
        return null;
    }

    return (
        <div>
            <HotkeyProvider id='invoiceIndex'>
                <HotkeysController />
                <FormPickerMaster enable>
                    <DuplicatedInvoiceDialog
                        open={openDuplicatedDialog}
                        close={() => { setOpenDuplicatedDialog(false); }}
                        invoiceData={duplicatedInvoiceData}
                        forceAdd={handleForceAdd}
                        onCancel={handleInvoiceCancel}
                    />
                    <InvoiceProperties
                        open={openImportDialog}
                        onSubmit={handleSubmitDialog}
                    />
                    <Grid container>
                        <Grid container item xs={6} style={{ marginTop: '35px' }}>
                            <InvoiceForm
                                isLoading={isLoading}
                                onSubmit={handleSubmitInvoiceForm}
                                invoiceFormData={invoiceFormData}
                                onChange={handleFormChange}
                                addingForm
                            />
                        </Grid>
                        <Grid container item xs={6}>
                            {type === fileType.pdf && pdf ? previewPdf() : null}
                            {type === fileType.rtf && rtfPreview ? previewRtf() : null}
                            {type === fileType.xls && (
                                <DocumentPreviewMenu
                                    onInvoiceCancel={handleInvoiceCancel}
                                    invoiceIndex={invoiceIndex}
                                    setInvoiceIndex={setInvoiceIndex}
                                    filesTotal={xlsDocs.length}
                                />
                            )}
                        </Grid>
                    </Grid>
                </FormPickerMaster>
            </HotkeyProvider>
        </div>
    );
}

export default ImportInvoice;
