import Grid from '@mui/material/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomDatePicker from '../../../../../common/CustomDatePicker/CustomDatePicker';
import SelectAllButton from '../../../../../common/SelectAllButton/SelectAllButton';
import requestsMaxLimitValue from '../../../../../../constants/requests';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import dateFormat from '../../../../../../constants/dateFormat';
import { comboboxTypes } from '../../../../../common/Combobox/UniversalCombobox';
import UniversalComboboxWorkaround from '../../../../../common/Combobox/utils/UniversalComboboxWorkaround';

const messages = {
    verify: { id: 'app.import.verify' },
    addDirectly: { id: 'app.import.addDirectly' },
    contractorName: { id: 'app.contractors.contractorName' },
    dateOfIncome: { id: 'app.income.date' },
    dateOkButton: { id: 'app.invoiceForm.OkButton' },
    dateCancelButton: { id: 'app.invoiceForm.CancelButton' },
    dateTodayButton: { id: 'app.invoiceForm.todayButton' },
    selectAll: { id: 'app.invoiceForm.selectAll' },
    remarks: { id: 'app.correspondence.add.remarks' },
    importSettings: { id: 'app.generateNotifications.title' },
    importIncomes: { id: 'app.addIncome.importIncomes' },
    generateIncomes: { id: 'app.addIncome.generateIncomes' },
    confirm: { id: 'app.form.confirm' },
    addManually: { id: 'app.menu.addManually' },
    sendNotifications: { id: 'app.generateNotifications.sendNotifications' }
};

interface GenerateNotificationsDialogProps {
    remarks: string,
    onRemarksChange: Function,
    dateOfIncome: string,
    onDateOfIncomeChange: Function,
    communities: never[],
    onCommunitiesChange: Function
    onSubmit: Function
    onSendNotificationsChange: Function,
    sendNotifications: boolean,
}

interface Communities {
    _id: string
}

const useStyles = makeStyles((theme: Theme) => ({
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        verticalAlign: 'top'
    }
}));
function GenerateNotificationsDialog({
    remarks,
    onRemarksChange,
    dateOfIncome,
    onDateOfIncomeChange,
    communities,
    onCommunitiesChange,
    onSubmit,
    sendNotifications,
    onSendNotificationsChange
}: GenerateNotificationsDialogProps) {
    const history = useHistory();
    const intl = useIntl();
    const [isSelectingAll, setIsSelectingAll] = useState(false);
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const selectAll = async () => {
        setIsSelectingAll(true);
        const { data } = await RestRequestsHelper.getCommunities('', requestsMaxLimitValue);
        onCommunitiesChange(data);
        setIsSelectingAll(false);
    };
    const handleClose = () => {
        history.push('/');
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const data = {
            communities: communities.map((item: Communities) => item._id),
            dateOfIncome,
            remarks,
            sendNotifications
        };
        await onSubmit(data);
        setIsLoading(false);
    };
    return (
        <Dialog open onClose={handleClose}>
            <DialogTitle>
                <FormattedMessage id={messages.importSettings.id} />
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={handleClose}
                    aria-label='close'
                    className={classes.close}
                    size='large'
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={12}>
                        <UniversalComboboxWorkaround
                            id='communities'
                            src={comboboxTypes.communities()}
                            label={intl.formatMessage(messages.contractorName)}
                            value={communities}
                            onChange={(val: string[]) => onCommunitiesChange(val)}
                            multiple
                            required
                            showColors
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectAllButton onSelectAll={selectAll} loading={isSelectingAll} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='remarks'
                            multiline
                            label={(
                                <FormattedMessage
                                    id={messages.remarks.id}
                                />
                            )}
                            name='remarks'
                            autoComplete='remarks'
                            value={remarks}
                            onChange={(event) => {
                                onRemarksChange(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomDatePicker
                            inputFormat={dateFormat.format}
                            label={(
                                <FormattedMessage
                                    id={messages.dateOfIncome.id}
                                />
                            )}
                            value={dateOfIncome}
                            onChange={(date) => {
                                onDateOfIncomeChange(date);
                            }}
                            renderInput={(params) => (<TextField {...params} required fullWidth id='dateOfIncome' />)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={sendNotifications}
                                    onChange={(event) => {
                                        onSendNotificationsChange(event.target.checked);
                                    }}
                                    name='sendNotifications'
                                />
                            )}
                            label={
                                intl.formatMessage({ id: messages.sendNotifications.id })
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    id='generate'
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    <FormattedMessage id={messages.confirm.id} />
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

GenerateNotificationsDialog.propTypes = {
    dateOfIncome: PropTypes.string.isRequired,
    onDateOfIncomeChange: PropTypes.func.isRequired,
    communities: PropTypes.array.isRequired,
    onCommunitiesChange: PropTypes.func.isRequired,
    remarks: PropTypes.string.isRequired,
    onRemarksChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSendNotificationsChange: PropTypes.func.isRequired,
    sendNotifications: PropTypes.bool.isRequired
};
export default GenerateNotificationsDialog;
