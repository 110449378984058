import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Tooltip from '@mui/material/Tooltip';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import IconButton from '@mui/material/IconButton';
import { DateTimePicker } from '@mui/x-date-pickers';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { setTask, setInspection } from '../../../actions/index';
import dateFormat from '../../../constants/dateFormat';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import UniversalCombobox, { comboboxTypes } from '../Combobox/UniversalCombobox';
import ListItems from '../ListItems/ListItems';
import { HotkeyProvider } from '../Hotkeys/Hotkeys';
import FormPickerMaster from '../FormPicker/FormPickerMaster';
import useDisplaySnackbar from '../../../lib/displaySnackbar';
import userRoles from '../../../constants/UserRoles';
import convertColumnsToVisibilityModel from '../../../lib/convertColumnsToVisibilityModel';
import FormFileManager, { prepareFilesForUpload, prepareRemarkFilesForUpload } from '../FormFileManager/FormFileManager';
import FormHeader from '../FormHeader/FormHeader';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import { useSubTasksColumns, useActionsColumns } from './shared/hooks/useColumns';
import defaultTaskFormValues, { defaultRemarks, defaultReminder } from './shared/data/defaultTaskFormValues';

const useStyle = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        '& .MuiGrid-item': {
            padding: '4px'
        }
    },
    select: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    selectItem: {
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(1)
    },
    toolTip: {
        padding: theme.spacing(0.5)
    },
    metadata: {
        color: theme.palette.grey[500],
        float: 'left',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    loading: {
        alignSelf: 'center'
    },
    buttons: {
        bottom: theme.spacing(1),
        zIndex: 1,
        position: 'sticky'
    },
    accordionSummary: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

const messages = {
    addTask: { id: 'app.addTask.add' },
    editTask: { id: 'app.addTask.edit' },
    saveChanges: { id: 'app.addTask.saveChanges' },
    concerns: { id: 'app.addTask.concerns' },
    filterByConcernsHint: { id: 'app.tasks.filterByConcernsHint' },
    dateOfIncidence: { id: 'app.addTask.dateOfIncidence' },
    applicantData: { id: 'app.addTask.applicantData' },
    leader: { id: 'app.addTask.leader' },
    producer: { id: 'app.addTask.producer' },
    content: { id: 'app.addTask.content' },
    industry: { id: 'app.addTask.industry' },
    dueDate: { id: 'app.addTask.dueDate' },
    received: { id: 'app.addTask.received' },
    remarks: { id: 'app.addTask.remarks' },
    phone: { id: 'app.addTask.phone' },
    reminder: { id: 'app.addTask.reminder' },
    reminderEntity: { id: 'app.addTask.reminderEntity' },
    reminderContact: { id: 'app.addTask.reminderContact' },
    status: { id: 'app.addTask.status' },
    clear: { id: 'app.addTask.clear' },
    confirm: { id: 'app.addTask.confirm' },
    cancel: { id: 'app.addTask.cancel' },
    today: { id: 'app.addTask.today' },
    name: { id: 'app.addTask.name' },
    parentTask: { id: 'app.addTask.parentTask' },
    inhabitantParentTaskError: { id: 'app.addTask.inhabitantParentTaskError' },
    subtasks: { id: 'app.addTask.subtasks' },
    category: { id: 'app.addTask.category' },
    subcategory: { id: 'app.addTask.subcategory' },
    dateOkButton: { id: 'app.addTask.okButton' },
    dateCancelButton: { id: 'app.addTask.cancelButton' },
    dateTodayButton: { id: 'app.addTask.todayButton' },
    dateClearButton: { id: 'app.addTask.clearButton' },
    applicantDataTooltip: { id: 'app.addTask.applicantDataTooltip' },
    addTaskDate: { id: 'app.tasks.addTaskDate' },
    editTaskDate: { id: 'app.tasks.editTaskDate' },
    author: { id: 'app.tasks.author' },
    updatedAt: { id: 'app.tasks.updatedAt' },
    reminderNow: { id: 'app.tasks.reminderNow' },
    reminderNowHint: { id: 'app.tasks.reminderNowHint' },
    history: { id: 'app.tasks.history' },
    type: { id: 'app.correspondence.add.type' },
    assignedInspection: { id: 'app.inspections.assignedInspection' }
};

function TaskForm({
    onSubmit, onDelete, filterByConcerns, readOnly, inhabitantMode, applicantFilter
}) {
    const classes = useStyle();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const stateTask = useSelector((state) => state.task);
    const [edit, setEdit] = useState(stateTask);
    const [subtasks, setSubtasks] = useState([]);
    const subTasksColumns = useSubTasksColumns();
    const actionsColumns = useActionsColumns();
    const [loading, setLoading] = useState(true);
    const {
        register, control,
        handleSubmit, reset,
        setValue, getValues, watch
    } = useForm({ defaultValues: defaultTaskFormValues(user, edit) });
    const watchApplicantData = watch('applicantData');
    const watchPhone = watch('phone');
    const watchCategory = watch('category');
    const watchConcerns = watch('concerns');
    const watchReminders = watch('reminders');
    const locale = useSelector((state) => state.locale);
    // Here we hold a field name holding currently focused files
    // The field name is expressed with an array of strings
    const [filesField, setFilesField] = useState(['files']);
    const [isSubmiting, setIsSubmiting] = useState(false);
    dayjs.locale(locale);

    const history = useHistory();

    useEffect(() => {
        setEdit(stateTask);
    }, [stateTask]);

    useEffect(() => {
        if (watchPhone && typeof watchPhone === 'object') {
            setValue('applicantData', { ...watchPhone.entity });
        }
    }, [watchPhone]);

    const query = new URLSearchParams(window.location.search);
    const predefinedValues = query.get('predefinedValues');

    const getInspection = async (id) => {
        if (id) {
            const { data } = await RestRequestsHelper.getInspection(id);
            return data.data;
        }
        return null;
    };

    useEffect(() => {
        const getPredefinedValues = async () => {
            if (predefinedValues) {
                const parsedPredefinedValues = JSON.parse(decodeURIComponent(predefinedValues));

                const inspectionData = await getInspection(parsedPredefinedValues?.inspectionId);

                setValue('concerns', parsedPredefinedValues?.concerns);
                setValue('inspection', { ...inspectionData, $name: inspectionData?.name });

                query.delete('predefinedValues');
                history.replace({ pathname: window.location.pathname, search: query.toString() });
            }
        };
        getPredefinedValues();
    }, []);

    const fileManagerRef = useRef();

    const handleResetForm = () => {
        reset(defaultTaskFormValues(user, edit));
        fileManagerRef.current.flushFiles();
        if (!edit) {
            // Workaround for null value of applicantData being set
            setValue('applicantData', {});
        }
    };

    const handleSubmitForm = async (data) => {
        setIsSubmiting(true);

        const filesData = prepareFilesForUpload(data?.files);
        const remarksFilesData = prepareRemarkFilesForUpload(data?.remarks);

        const preparedFilesObj = {
            files: filesData.files,
            remarks: remarksFilesData.files
        };

        const preparedData = {
            ...data,
            id: edit?._id,
            // If the applicant data is an empty object, we set it to null
            // Also if there is a contractor name, we want to extract it from the applicant data
            applicantData: isEmpty(data.applicantData) ? null : data.applicantData?.nazwa_kontrahenta || data.applicantData,
            // Extract due date if possible
            dueDate: data.dueDate,
            // Ignore files and assign user ID instead of the whole user object
            // eslint-disable-next-line object-curly-newline
            remarks: data.remarks.map(({ files, userData, type, ...rest }) => ({ user: userData?._id, type: type?.key, ...rest })),
            // Metadata of the files assigned to the main task
            filesMetadata: filesData.metadata,
            // Metadata of the files assigned to the remarks
            remarksFilesMetadata: remarksFilesData.metadata,
            // Remove cache from the reminders
            reminders: data.reminders.map(({ _new, _isSent, ...reminder }) => reminder),
            files: null
        };

        if (await onSubmit(preparedData, preparedFilesObj)) {
            handleResetForm();
        }
        setIsSubmiting(false);
    };

    const entityWithAddressOptionLabel = ({ name, address = [] }) => {
        if (address.length) {
            const [addr] = address;
            const textual = [
                addr.city,
                addr.street,
                addr.number
            ].join(' ');
            return `${name} (${textual})`;
        }
        return name || '';
    };

    const statusOptionLabel = ({ value, description }) => `${value} ${description ? `(${description})` : ''}`;

    useEffect(() => {
        const getSubtasks = async (id) => {
            const applicantFilterIds = applicantFilter.map((element) => element._id);
            const filterQuery = !!applicantFilterIds.length && JSON.stringify({ _id: applicantFilterIds });
            setLoading(true);
            const { data } = await RestRequestsHelper.getSubtasks(id, filterQuery || []);
            setLoading(false);
            setSubtasks(data?.data ?? []);
        };
        if (edit) {
            getSubtasks(edit._id);
        }
    }, [edit]);

    const getTask = async (id) => {
        if (id) {
            const { data } = await RestRequestsHelper.getTask(id);
            return data.data;
        }
        return null;
    };

    const loadParentTask = async () => {
        const parentTask = await getTask(edit.parent._id);
        if (inhabitantMode && parentTask?.reportingContractor?.user._id !== user.sub) {
            displaySnackbar('error', messages.inhabitantParentTaskError);
            return;
        }
        dispatch(setTask(parentTask));
        setEdit(parentTask);
        reset(defaultTaskFormValues(user, parentTask));
    };

    const loadAssignedInspection = async () => {
        const inspection = await getInspection(edit.inspection._id);
        dispatch(setInspection(inspection));
        history.push('/inspections/browse');
    };

    const handleSubtaskClick = (params) => {
        if (params.field === 'numberUI') {
            const { row } = params;
            dispatch(setTask(row));
            setEdit(row);
            reset(defaultTaskFormValues(user, row));
        }
    };

    const contactWithOptionLabel = ({ value, typeName }) => `${value} (${typeName})`;

    const communitySrc = () => {
        const id = watchApplicantData?._id;
        if (id) return comboboxTypes.communityById(id);
        return comboboxTypes.communities(500);
    };

    const applicantDataSrc = () => {
        const idConcerns = watchConcerns ? watchConcerns.map((item) => item._id) : [];
        if (idConcerns.length > 0) {
            return comboboxTypes.entitiesOfCommunityByName(idConcerns);
        }
        return comboboxTypes.entitiesApplicantData();
    };

    const contactSrc = (index) => {
        const reminder = watchReminders[index];
        if (reminder?.entity) {
            return comboboxTypes.contactsByName(reminder.entity._id);
        }
        return comboboxTypes.empty();
    };

    const renderRemarkMetadata = (field, value) => {
        if (field === 'user' && value) {
            return `${value.firstName} ${value.lastName}`;
        }
        if (field === 'updatedAt' && value) {
            return dayjs(value).format('DD-MM-YYYY HH:mm');
        }
        return '';
    };

    const userCanEdit = (value) => {
        const id = value?.user?._id;
        if (id) {
            return value?.user?._id === user.sub;
        }
        return true;
    };

    const setReminderNow = (index) => {
        const reminders = [...getValues('reminders')];
        reminders[index].date = dayjs().add(3, 'minute');
        setValue('reminders', reminders);
    };

    const handleFilesFieldChange = (field) => {
        if (isEqual(field, filesField)) {
            setFilesField(['files']);
        } else {
            setFilesField(field);
        }
    };

    const handleRemarksRemove = (newItems, index) => {
        if (filesField[0] === 'remarks') {
            if (filesField[1] === index && index >= 0) {
                if (!newItems.length) {
                    setFilesField(['files']);
                } else {
                    setFilesField(['remarks', index - 1, 'files']);
                }
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className={classes.root}>
                <FormPickerMaster>
                    <HotkeyProvider>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs={12}>
                                        <FormHeader
                                            label={edit ? messages.editTask.id : messages.addTask.id}
                                            values={edit && { number: edit.numberUI }}
                                            hideDelete={readOnly || inhabitantMode || !edit}
                                            onDelete={onDelete}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant='outlined'
                                            fullWidth
                                            label={intl.formatMessage(messages.name)}
                                            inputProps={{ maxLength: 100 }}
                                            disabled={readOnly}
                                            {...register('name')}
                                        />
                                    </Grid>
                                    <Grid item xs={edit ? 5 : 6}>
                                        <Controller
                                            name='concerns'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={communitySrc()}
                                                    label={intl.formatMessage(messages.concerns)}
                                                    id='concerns'
                                                    required
                                                    longerList
                                                    displayInfo
                                                    multiple
                                                    selectIfSingleton
                                                    disabled={readOnly}
                                                    showColors
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {edit && (
                                        <Grid item xs={1}>
                                            <Tooltip title={intl.formatMessage(messages.filterByConcernsHint)}>
                                                <IconButton
                                                    onClick={() => {
                                                        filterByConcerns(getValues('concerns'));
                                                    }}
                                                    size='large'
                                                    disabled={!getValues('concerns')}
                                                >
                                                    <FilterListIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                    <Grid item xs={edit ? 5 : 6}>
                                        <Controller
                                            name='parentTask'
                                            control={control}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.taskNames()}
                                                    label={intl.formatMessage(messages.parentTask)}
                                                    id='parentTask'
                                                    excluded={edit ? [edit._id] : []}
                                                    longerList
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {edit && (
                                        <Grid item xs={1}>
                                            <IconButton
                                                onClick={() => {
                                                    loadParentTask();
                                                }}
                                                size='large'
                                                disabled={!edit.parent}
                                            >
                                                <MoveUpIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                                    <Grid item xs={6}>
                                        <Controller
                                            name='phone'
                                            control={control}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.entitiesByPhoneNumbers(watchApplicantData?._id)}
                                                    label={intl.formatMessage(messages.phone)}
                                                    freeSolo
                                                    selectIfSingleton
                                                    id='phone'
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name='applicantData'
                                            control={control}
                                            render={({ field }) => (
                                                <Tooltip
                                                    disableInteractive
                                                    title={intl.formatMessage(messages.applicantDataTooltip)}
                                                    placement='top'
                                                >
                                                    <div>
                                                        <UniversalCombobox
                                                            src={applicantDataSrc()}
                                                            label={intl.formatMessage(messages.applicantData)}
                                                            optionLabel={entityWithAddressOptionLabel}
                                                            freeSolo
                                                            selectIfSingleton
                                                            displayInfo
                                                            disabled={readOnly}
                                                            id='applicantData'
                                                            showColors
                                                            {...field}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label={intl.formatMessage(messages.author)}
                                            variant='outlined'
                                            fullWidth
                                            disabled
                                            value={edit?.authorUI || (!edit ? user?.name : null)}
                                            sx={{ input: { color: edit?.author?.color, WebkitTextFillColor: 'unset !important' } }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name='receiver'
                                            control={control}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.users([userRoles.admin, userRoles.user])}
                                                    label={intl.formatMessage(messages.received)}
                                                    id='receiver'
                                                    showColors
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Controller
                                            name='leader'
                                            control={control}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.users([userRoles.admin, userRoles.user])}
                                                    label={intl.formatMessage(messages.leader)}
                                                    id='leader'
                                                    showColors
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name='industry'
                                            control={control}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.industry()}
                                                    label={intl.formatMessage(messages.industry)}
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name='dueDate'
                                            control={control}
                                            render={({ field }) => (
                                                <CustomDatePicker
                                                    disabled={readOnly}
                                                    showTodayButton
                                                    clearable
                                                    variant='outlined'
                                                    inputFormat={dateFormat.format}
                                                    label={intl.formatMessage(messages.dueDate)}
                                                    okLabel={intl.formatMessage(messages.dateOkButton)}
                                                    cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                                    todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                                    clearLabel={intl.formatMessage(messages.dateClearButton)}
                                                    renderInput={(params) => (<TextField fullWidth {...params} name='dueDate' />)}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name='category'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.taskCategories()}
                                                    label={intl.formatMessage(messages.category)}
                                                    id='category'
                                                    required
                                                    disabled={readOnly}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            name='subcategory'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <UniversalCombobox
                                                    src={comboboxTypes.taskSubcategories(watchCategory?.key)}
                                                    label={intl.formatMessage(messages.subcategory)}
                                                    id='subcategory'
                                                    disabled={!watchCategory || readOnly}
                                                    required
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant='outlined'
                                            required
                                            multiline
                                            fullWidth
                                            label={intl.formatMessage(messages.content)}
                                            inputProps={{ maxLength: 300 }}
                                            disabled={!!edit || readOnly}
                                            {...register('content', { required: true })}
                                        />
                                    </Grid>
                                    {!inhabitantMode && (
                                        <Grid item xs={edit ? 11 : 12}>
                                            <Controller
                                                name='inspection'
                                                control={control}
                                                render={({ field }) => (
                                                    <UniversalCombobox
                                                        src={comboboxTypes.inspectionNames()}
                                                        label={intl.formatMessage(messages.assignedInspection)}
                                                        id='inspection'
                                                        longerList
                                                        disabled={readOnly}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )}
                                    {(edit && !inhabitantMode) && (
                                        <Grid item xs={1}>
                                            <IconButton
                                                onClick={() => {
                                                    loadAssignedInspection();
                                                }}
                                                size='large'
                                                disabled={!edit.inspection}
                                            >
                                                <MoveUpIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                            <FormattedMessage id={messages.remarks.id} />
                                        </Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name='remarks'
                                            control={control}
                                            render={({ field }) => (
                                                <ListItems
                                                    onNewItem={() => defaultRemarks(user)}
                                                    onItemRemove={handleRemarksRemove}
                                                    canBeRemoved={userCanEdit}
                                                    buttonsPosition='top'
                                                    hideActions={readOnly}
                                                    render={(fields, index, value) => (
                                                        <Grid
                                                            container
                                                            className={classes.remarks}
                                                            sx={value?.user?.color ? {
                                                                borderColor: value?.user?.color, borderRadius: 1, borderWidth: 2, borderStyle: 'solid', p: 1
                                                            } : { p: 1 }}
                                                        >
                                                            {edit && (
                                                                <Grid container sx={{ textAlign: 'left' }}>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant='caption'>
                                                                            {`${intl.formatMessage(messages.author)}: ${renderRemarkMetadata('user', value.user)}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography variant='caption'>
                                                                            {`${intl.formatMessage(messages.updatedAt)}: ${renderRemarkMetadata('updatedAt', value.updatedAt)}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            <Grid container sx={{ mt: 1 }} alignItems='center'>
                                                                <Grid item xs={2}>
                                                                    <UniversalCombobox
                                                                        src={comboboxTypes.remarkTypes()}
                                                                        label={intl.formatMessage(messages.type)}
                                                                        id='type'
                                                                        disabled={!userCanEdit(value) || readOnly}
                                                                        required
                                                                        disableClearable
                                                                        optionLabel={(title) => title?.$name?.slice(0, 3)}
                                                                        {...fields('type')}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField
                                                                        disabled={!userCanEdit(value) || readOnly}
                                                                        required
                                                                        fullWidth
                                                                        multiline
                                                                        variant='outlined'
                                                                        label={intl.formatMessage(messages.remarks)}
                                                                        {...fields('value')}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <IconButton
                                                                        className={classes.gridButton}
                                                                        onClick={() => handleFilesFieldChange(['remarks', index, 'files'])}
                                                                        color={isEqual(filesField, ['remarks', index, 'files']) ? 'primary' : 'default'}
                                                                        size='large'
                                                                    >
                                                                        <FindInPageIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    {
                                                                        value.files?.length || 0
                                                                    }
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Accordion sx={{ p: 0 }}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
                                                <div className={classes.accordionSummary}>
                                                    <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                                        <FormattedMessage id={messages.reminder.id} />
                                                    </Typography>
                                                    <Typography variant='body2'>{edit?.reminders?.length || 0}</Typography>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ p: 0 }}>
                                                <Controller
                                                    name='reminders'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <ListItems
                                                            canBeRemoved={(value) => !value._isSent}
                                                            onNewItem={defaultReminder}
                                                            buttonsPosition='top'
                                                            hideActions={readOnly}
                                                            sortFn={(_a, _b) => (edit ? new Date(_b.date) - new Date(_a.date) : 0)}
                                                            render={(fields, index) => (
                                                                <Grid container alignItems='center'>
                                                                    <Grid item xs={4}>
                                                                        <UniversalCombobox
                                                                            required
                                                                            displayInfo
                                                                            src={comboboxTypes.entitiesContactsByName()}
                                                                            showColors
                                                                            label={intl.formatMessage(messages.reminderEntity)}
                                                                            disabled={fields('_isSent')?.value || readOnly}
                                                                            {...fields('entity')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <UniversalCombobox
                                                                            required
                                                                            src={contactSrc(index)}
                                                                            label={intl.formatMessage(messages.reminderContact)}
                                                                            optionLabel={contactWithOptionLabel}
                                                                            disabled={!watchReminders[index]?.entity || fields('_isSent')?.value || readOnly}
                                                                            {...fields('value')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <DateTimePicker
                                                                            required
                                                                            disabled={fields('_isSent')?.value || readOnly}
                                                                            disablePast
                                                                            ampm={false}
                                                                            showTodayButton
                                                                            variant='outlined'
                                                                            inputFormat={dateFormat.dateWithHoursAndMinutes}
                                                                            margin='normal'
                                                                            okLabel={intl.formatMessage(messages.dateOkButton)}
                                                                            cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                                                            todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                                                            renderInput={(params) => (<TextField fullWidth {...params} />)}
                                                                            {...fields('date')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Tooltip title={intl.formatMessage(messages.reminderNowHint)}>
                                                                            <Button
                                                                                onClick={() => setReminderNow(index)}
                                                                                disabled={fields('_isSent')?.value}
                                                                            >
                                                                                <FormattedMessage id={messages.reminderNow.id} />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    {edit ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name='producers'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <UniversalCombobox
                                                            multiple
                                                            src={comboboxTypes.producers()}
                                                            label={intl.formatMessage(messages.producer)}
                                                            disabled={readOnly}
                                                            showColors
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controller
                                                    required
                                                    name='status'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <UniversalCombobox
                                                            showColors
                                                            required
                                                            src={comboboxTypes.taskStatuses()}
                                                            label={intl.formatMessage(messages.status)}
                                                            optionLabel={statusOptionLabel}
                                                            selectIfSingleton
                                                            disabled={readOnly}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {(loading || subtasks.length > 0) && (
                                                <Grid item xs={12}>
                                                    <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                                        <FormattedMessage id={messages.subtasks.id} />
                                                    </Typography>
                                                    <Divider />

                                                    {loading
                                                        ? <CircularProgress className={classes.loading} />
                                                        : (
                                                            <DataGrid
                                                                autoHeight
                                                                rows={subtasks}
                                                                columns={subTasksColumns}
                                                                onCellClick={handleSubtaskClick}
                                                                columnVisibilityModel={convertColumnsToVisibilityModel(subTasksColumns)}
                                                            />
                                                        )}
                                                </Grid>
                                            )}
                                            {edit?.actions && (
                                                <Grid item xs={12}>
                                                    <Accordion sx={{ p: 0 }}>
                                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
                                                            <div className={classes.accordionSummary}>
                                                                <Typography component='h2' variant='h6' className={classes.divider} align='left'>
                                                                    <FormattedMessage id={messages.history.id} />
                                                                </Typography>
                                                                <Typography variant='body2'>{edit.actions.length || 0}</Typography>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails sx={{ p: 0 }}>
                                                            <DataGrid
                                                                autoHeight
                                                                rows={edit.actions.map((elm, i) => ({ ...elm, index: i }))}
                                                                columns={actionsColumns}
                                                                initialState={{
                                                                    sorting: {
                                                                        sortModel: [{ field: 'date', sort: 'desc' }]
                                                                    }
                                                                }}
                                                                getRowId={(row) => row.index}
                                                                sx={{ mb: 1 }}
                                                                columnVisibilityModel={convertColumnsToVisibilityModel(actionsColumns)}
                                                            />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            )}
                                            <Grid item xs={6}>
                                                <CustomDatePicker
                                                    useLocalTime
                                                    variant='outlined'
                                                    inputFormat={dateFormat.fullDate}
                                                    margin='normal'
                                                    id='addDate'
                                                    label={(
                                                        <FormattedMessage
                                                            id={messages.addTaskDate.id}
                                                        />
                                                    )}
                                                    value={edit.createdAt}
                                                    renderInput={(params) => (<TextField fullWidth {...params} />)}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <CustomDatePicker
                                                    useLocalTime
                                                    variant='outlined'
                                                    inputFormat={dateFormat.fullDate}
                                                    renderInput={(params) => (<TextField fullWidth {...params} />)}
                                                    margin='normal'
                                                    id='editDate'
                                                    label={(
                                                        <FormattedMessage
                                                            id={messages.editTaskDate.id}
                                                        />
                                                    )}
                                                    value={edit.updatedAt}
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    ) : null}
                                    {
                                        !readOnly && (
                                            <Grid container className={classes.buttons}>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        fullWidth
                                                        onClick={handleResetForm}
                                                    >
                                                        <FormattedMessage id={messages.clear.id} />
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LoadingButton
                                                        type='submit'
                                                        variant='contained'
                                                        color='primary'
                                                        fullWidth
                                                        loading={isSubmiting}
                                                        loadingPosition='start'
                                                    >
                                                        <FormattedMessage id={edit ? messages.saveChanges.id : messages.addTask.id} />
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormFileManager
                                    setValue={setValue}
                                    getValues={getValues}
                                    setCurrentField={setFilesField}
                                    currentField={filesField}
                                    readOnly={readOnly}
                                    ref={fileManagerRef}
                                />
                            </Grid>
                        </Grid>
                    </HotkeyProvider>
                </FormPickerMaster>
            </div>
        </form>
    );
}

TaskForm.propTypes = {
    onSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    filterByConcerns: PropTypes.func,
    edit: PropTypes.object,
    inhabitantMode: PropTypes.bool,
    readOnly: PropTypes.bool,
    applicantFilter: PropTypes.object
};

TaskForm.defaultProps = {
    onSubmit: () => {},
    onDelete: () => {},
    filterByConcerns: () => {},
    edit: null,
    inhabitantMode: false,
    readOnly: false,
    applicantFilter: () => {}
};

export default TaskForm;
