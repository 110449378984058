import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import restRequestsHelper from '../../../../lib/restRequestsHelper';
import CSVExportData from '../../../common/DataGridToolbar/CSVExportData';
import usePageTitle from '../../../common/DataGridToolbar/usePageTitle';
import { setVindication } from '../../../../actions/index';
import userRoles from '../../../../constants/UserRoles';
import { paginationDefaultValue, paginationAllowedValues } from '../../../../constants/pagination';
import FixedAddButton from '../../../common/FixedAddButton/FixedAddButton';
import convertColumnsToVisibilityModel from '../../../../lib/convertColumnsToVisibilityModel';
import useColumns from './shared/hooks/useColumns';
import VindicationsFilter from './shared/ui/VindicationsFilter';
import VindicationsSidePanel from './shared/ui/VindicationsSidePanel';
import useVindications from './shared/hooks/useVindications';
import SIDE_PANEL_STATUS from './shared/data/sidePanelStatus';

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '80vh',
        textAlign: 'left'
    },
    toolbar: {
        padding: theme.spacing(1)
    }
}));

const prepareDefaultStatuses = (statuses) => statuses.map((status) => ({ key: status }));

const getAllVindications = debounce(async (filter, filtersLoaded, callback) => {
    const { data } = await restRequestsHelper.getAllVindications({ filter, filtersLoaded });
    callback(data?.data ?? []);
}, 500);

function GridToolbarContainer({ filterQuery, filterQueryLoaded, columns }) {
    const classes = useStyles();
    const [data, setData] = useState();
    const [download, setDownload] = useState(false);

    const getHeaders = () => (
        columns
            .filter(({ csvExport }) => csvExport)
            .map(({ headerName, field }) => ({ label: headerName, key: field }))
    );

    const handleClick = () => {
        if (!filterQuery || !filterQueryLoaded) return;
        getAllVindications(filterQuery, filterQueryLoaded, (result) => {
            const resultCSV = result.map((task) => Object.fromEntries(
                Object.entries(task).map(([key, value]) => {
                    const val = value?.name ?? value;
                    const isObject = typeof val === 'object' && !Array.isArray(val);
                    // We do this because the value might be an object with empty name
                    const res = isObject ? '' : val;
                    return [key, res];
                })
            ));
            setData(resultCSV);
        });
    };

    useEffect(() => {
        if (data) {
            setDownload(true);
            setTimeout(() => {
                setDownload(false);
            }, 1000);
        }
    }, [data]);

    return (
        <div className={classes.toolbar}>
            <CSVExportData onClick={handleClick} download={download} headers={getHeaders()} filename={usePageTitle()} data={data} />
        </div>
    );
}

function Vindications({ defaultApplicantFilter, defaultStatusesFilter, inhabitantView }) {
    const [page, setPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(null);
    const [filterQueryLoaded, setFilterQueryLoaded] = useState(false);
    const [pageSize, setPageSize] = useState(paginationDefaultValue);
    const [concernFilter, setConcernFilter] = useState([]);
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const [sidePanelStatus, setSidePanelStatus] = useState(SIDE_PANEL_STATUS.closed);
    const [sortModel, setSortModel] = useState([]);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const {
        vindications, count,
        loading, mutate
    } = useVindications(filterQuery, page, pageSize, filterQueryLoaded, JSON.stringify(sortModel[0]));
    const classes = useStyles();
    const history = useHistory();

    const query = new URLSearchParams(window.location.search);
    const id = query.get('id');

    useEffect(() => {
        if (id) {
            dispatch(setVindication(id));
        }
    }, [id]);

    const handleUpdate = () => mutate();

    const columns = useColumns(handleUpdate);

    const handleChangePageSize = (params) => {
        setPageSize(params);
    };

    const handleChangeFilter = (newFilter) => {
        setFilterQuery(JSON.stringify(newFilter));
        // If the tasksfilter states that it's ready by using `filtersLoaded` variable
        // set to `true` then we have to update the filter query and only then can we say
        // that the filter query is ready as well (this removes the initial load of the tasks)
        setFilterQueryLoaded(filtersLoaded);
    };

    const onSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    // Edit selected task
    const handleRowSelect = async (params) => {
        if (params.field === 'number') {
            const { row } = params;
            setSidePanelStatus(SIDE_PANEL_STATUS.loading);
            const { data } = await restRequestsHelper.getVindication(row.id);
            dispatch(setVindication(data.data));
            setSidePanelStatus(SIDE_PANEL_STATUS.loaded);
        }
    };

    const handleCloseSidePanel = () => {
        setSidePanelStatus(SIDE_PANEL_STATUS.closed);
        dispatch(setVindication(null));
        if (id) {
            query.delete('id');
            history.replace({ pathname: window.location.pathname, search: query.toString() });
        }
    };

    const redirectAddTask = () => history.push('/vindication/add');

    const filterByConcerns = (concerns) => {
        handleCloseSidePanel();
        setConcernFilter(concerns);
    };
    return (
        <div className={classes.main}>
            <VindicationsSidePanel
                onClose={handleCloseSidePanel}
                onUpdate={handleUpdate}
                filterByConcerns={filterByConcerns}
                inhabitantMode={inhabitantView}
                applicantFilter={defaultApplicantFilter}
                status={sidePanelStatus}
            />
            <VindicationsFilter
                onChange={handleChangeFilter}
                concernFilter={concernFilter}
                setLoaded={setFiltersLoaded}
                applicantFilter={defaultApplicantFilter}
                inhabitantView={inhabitantView}
                statusFilter={prepareDefaultStatuses(defaultStatusesFilter)}
            />
            <FixedAddButton onClick={redirectAddTask} hidden={user?.role === userRoles.contractor} />
            <DataGrid
                paginationMode='server'
                rows={vindications}
                columns={columns}
                columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                pageSize={pageSize}
                pageSizeOptions={paginationAllowedValues}
                rowCount={count}
                getRowId={(row) => row.id}
                onCellClick={handleRowSelect}
                onPageChange={setPage}
                onPageSizeChange={handleChangePageSize}
                loading={loading}
                page={page}
                sortingMode='server'
                onSortModelChange={(newSortModel) => onSortModelChange(newSortModel)}
                sortModel={sortModel}
                components={{
                    Toolbar: GridToolbarContainer
                }}
                componentsProps={{
                    toolbar: {
                        filterQuery, filterQueryLoaded, columns, handleUpdate
                    }
                }}
            />
        </div>
    );
}

GridToolbarContainer.propTypes = {
    filterQuery: PropTypes.any.isRequired,
    filterQueryLoaded: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired
};

Vindications.propTypes = {
    defaultApplicantFilter: PropTypes.array,
    defaultStatusesFilter: PropTypes.array,
    inhabitantView: PropTypes.bool
};

Vindications.defaultProps = {
    defaultApplicantFilter: [],
    defaultStatusesFilter: [],
    inhabitantView: false
};

export default Vindications;
