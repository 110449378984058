import React from 'react';
import { useIntl } from 'react-intl';
import GridCellExpand from '../../../../common/DataGridCells/GridCellExpand';
import GridMoneyCell from '../../../../common/DataGridCells/GridMoneyCell';
import ExportWarning from '../ui/ExportWarning';
import { TOOLBAR_SELECT } from '../data/invoicesConfig';
import dateFormat from '../../../../../constants/dateFormat';
import formatUTCDate from '../../../../../lib/formatUTCDate';

const getExportWarning = ({ id, getValue }) => {
    if (getValue) {
        const elixir = getValue(id, 'elixir_buyer');
        const sellerAcc = getValue(id, 'seller_bank_account');
        return {
            elixir: Boolean(elixir),
            sellerAcc: Boolean(sellerAcc)
        };
    }
    return {};
};

const modifiedTotalChargesInExport = ({ value }) => {
    const modiefiedNumber = (value / 100).toFixed(2);
    let correctTotalCharges = '';
    const regex = /([/.])/g;
    const match = regex.exec(modiefiedNumber);
    if (match) {
        correctTotalCharges = modiefiedNumber.replace(regex, ',');
    }
    return correctTotalCharges;
};

const messages = {
    invoiceNumber: { id: 'app.invoices.invoiceNumber' },
    invoiceDate: { id: 'app.invoices.invoiceDate' },
    saleDate: { id: 'app.invoices.saleDate' },
    payementDate: { id: 'app.invoices.payementDate' },
    totalCharges: { id: 'app.invoices.totalCharges' },
    buyer: { id: 'app.invoices.buyer' },
    seller: { id: 'app.invoices.seller' },
    foreignInvoiceNumber: { id: 'app.invoices.foreignInvoiceNumber' },
    flowStatus: { id: 'app.invoices.flowStatus' },
    group: { id: 'app.invoices.group' },
    positionTitle: { id: 'app.invoices.positionTitle' },
    positionText: { id: 'app.invoices.positionText' }
};

const useColumns = ({
    groups,
    exportSelect, filterData, disableHidding = false,
    previewData = false
}) => {
    const intl = useIntl();
    return [
        {
            field: 'warnings',
            headerName: 'Status',
            width: 40,
            disableExport: true,
            valueGetter: getExportWarning,
            hide: exportSelect !== TOOLBAR_SELECT.elixir && disableHidding,
            sortComparator: (_v1, _v2, cellParams1, cellParams2) => (
                getExportWarning(cellParams1) - getExportWarning(cellParams2)
            ),
            renderCell: ExportWarning
        },
        {
            field: 'buyer',
            headerName: intl.formatMessage({ id: messages.buyer.id }),
            headerAlign: 'center',
            width: 280,
            hide: filterData?.buyer?.filter && disableHidding,
            // eslint-disable-next-line
            renderCell: (params) => {
                return (
                    <GridCellExpand
                        onClick={() => {
                            params.api.events.rowClick[0]({ row: params.row });
                        }}
                        width={params.colDef.width}
                        data={params.value}
                        alt={`#${params?.row?.buyer[0]?._id}`}
                    />
                );
            }
        },
        {
            field: 'seller',
            headerName: intl.formatMessage({ id: messages.seller.id }),
            headerAlign: 'center',
            width: 280,
            hide: filterData?.seller?.filter && disableHidding,
            // eslint-disable-next-line
            renderCell: (params) => <GridCellExpand
                onClick={() => {
                    params.api.events.rowClick[0]({ row: params.row });
                }}
                width={params.colDef.width}
                data={params.value}
                alt={`#${params?.row?.seller[0]?._id}`}
            />
        },
        {
            field: 'total_gross_charges',
            headerName: intl.formatMessage({ id: messages.totalCharges.id }),
            headerAlign: 'center',
            flex: 1,
            type: 'number',
            // eslint-disable-next-line
            renderCell: (params) => <GridMoneyCell
                onClick={() => {
                    params.api.events.rowClick[0]({ row: params.row });
                }}
                width={params.colDef.width}
                value={params.value / 100}
            />,
            valueFormatter: modifiedTotalChargesInExport
        },
        {
            field: 'foreign_document_number',
            headerName: intl.formatMessage({ id: messages.foreignInvoiceNumber.id }),
            headerAlign: 'center',
            flex: 1,
            // eslint-disable-next-line
            renderCell: (params) => <GridCellExpand
                onClick={() => {
                    params.api.events.rowClick[0]({ row: params.row });
                }}
                width={params.colDef.width}
                value={params.value}
            />
        },
        {
            field: 'document_number',
            headerName: intl.formatMessage({ id: messages.invoiceNumber.id }),
            headerAlign: 'center',
            flex: 1,
            // eslint-disable-next-line
            renderCell: (params) => <GridCellExpand
                onClick={() => {
                    params.api.events.rowClick[0]({ row: params.row });
                }}
                width={params.colDef.width}
                value={params.value}
            />
        },
        {
            field: 'state',
            headerName: intl.formatMessage({ id: messages.flowStatus.id }),
            headerAlign: 'center',
            flex: 1,
            // eslint-disable-next-line
            renderCell: (params) => <GridCellExpand
                onClick={() => {
                    params.api.events.rowClick[0]({ row: params.row });
                }}
                width={params.colDef.width}
                value={params.value?.value}
                color={params.value?.color}
            />
        },
        {
            field: 'id_group',
            headerName: intl.formatMessage({ id: messages.group.id }),
            headerAlign: 'center',
            flex: 1,
            // eslint-disable-next-line
            renderCell: (params) => <GridCellExpand
                onClick={() => {
                    params.api.events.rowClick[0]({ row: params.row });
                }}
                width={params.colDef.width}
                value={groups ? groups.find((item) => item.id === params.value)?.name : null}
            />
        },
        {
            field: 'exposure_date',
            headerName: intl.formatMessage({ id: messages.invoiceDate.id }),
            headerAlign: 'center',
            flex: 1,
            // eslint-disable-next-line
            renderCell: (params) => <GridCellExpand
                onClick={() => {
                    params.api.events.rowClick[0]({ row: params.row });
                }}
                width={params.colDef.width}
                value={formatUTCDate(params.value, dateFormat.format)}
            />
        },
        {
            field: 'sell_date',
            headerName: intl.formatMessage({ id: messages.saleDate.id }),
            headerAlign: 'center',
            flex: 1,
            // eslint-disable-next-line
            renderCell: (params) => <GridCellExpand
                onClick={() => {
                    params.api.events.rowClick[0]({ row: params.row });
                }}
                width={params.colDef.width}
                value={formatUTCDate(params.value, dateFormat.format)}
            />
        },
        {
            field: 'payment_date',
            headerName: intl.formatMessage({ id: messages.payementDate.id }),
            flex: 1,
            headerAlign: 'center',
            // eslint-disable-next-line
            renderCell: (params) => <GridCellExpand
                onClick={() => {
                    params.api.events.rowClick[0]({ row: params.row });
                }}
                width={params.colDef.width}
                value={formatUTCDate(params.value, dateFormat.format)}
            />
        },
        {
            field: 'positionTitle',
            headerName: intl.formatMessage({ id: messages.positionTitle.id }),
            flex: 1,
            headerAlign: 'center',
            hide: filterData?.groupPositions?.value || previewData,
            disableExport: filterData?.groupPositions?.value,
            renderCell: (params) => params.value
        },
        {
            field: 'positionText',
            headerName: intl.formatMessage({ id: messages.positionText.id }),
            flex: 1,
            headerAlign: 'center',
            hide: filterData?.groupPositions?.value || previewData,
            disableExport: filterData?.groupPositions?.value,
            renderCell: (params) => params.value
        }
    ];
};

export default useColumns;
