import { DATA_FETCHED, SET_TAB_VALUE, TOGGLE_ONLY_CHANGED } from './types';

export default (state, action) => {
    switch (action.type) {
        case DATA_FETCHED:
            return { ...state, reportData: action.payload, tabValue: Object.keys(action.payload)[0] };
        case TOGGLE_ONLY_CHANGED:
            return { ...state, onlyChanged: !state.onlyChanged };
        case SET_TAB_VALUE:
            return { ...state, tabValue: action.payload };
        default:
            return state;
    }
};
