import React, { ReactNode, useState } from 'react';
import {
    Box, IconButton, SxProps, Typography
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const messages = {
    deleteTitle: { id: 'app.common.deleteTitle' },
    deleteText: { id: 'app.common.deleteText' }
};

type FormHeaderProps = {
    label: string,
    // any values are accepted by intl types declaration
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values?: Record<string, any>,
    onDelete?: Function,
    hideDelete?: boolean,
    addationalNode?: ReactNode,
    sx?: SxProps
}

export default function FormHeader({
    label,
    values,
    onDelete,
    hideDelete,
    addationalNode,
    sx
}: FormHeaderProps) {
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = async () => {
        if (onDelete) {
            setIsDeleting(true);
            await onDelete();
            setIsDeleting(false);
        }
    };

    return (
        <>
            <ConfirmDialog
                isOpen={isDeleting}
                handleRemove={handleDelete}
                handleClose={() => setIsDeleting(false)}
                dialogTitle={messages.deleteTitle.id}
                dialogText={messages.deleteText.id}
            />
            <Typography component='h1' variant='h5' sx={{ position: 'relative', ...sx }}>
                <Box>
                    <FormattedMessage id={label} values={values} />
                </Box>
                <Box sx={{
                    display: 'flex', gap: 1, position: 'absolute', top: -3, right: -3
                }}
                >
                    {!hideDelete
                    && (
                        <IconButton color='primary' onClick={() => setIsDeleting(true)}>
                            <Delete />
                        </IconButton>
                    )}
                    {addationalNode}
                </Box>
            </Typography>
        </>
    );
}

FormHeader.defaultProps = {
    onDelete: () => {},
    values: null,
    hideDelete: false,
    addationalNode: null,
    sx: {}
};
