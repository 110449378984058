import React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Controller } from 'react-hook-form';
import EntitiesAutoComplete from '../EntitiesAutoComplete/EntititesAutoComplete';
import dateFormat from '../../../../../../../constants/dateFormat';
import CustomDatePicker from '../../../../../../common/CustomDatePicker/CustomDatePicker';

const messages = {
    dateFrom: 'app.infrastructures.dateFrom',
    dateTo: 'app.infrastructures.dateTo',
    dateOkButton: 'app.invoiceForm.OkButton',
    dateCancelButton: 'app.invoiceForm.CancelButton',
    dateTodayButton: 'app.invoiceForm.todayButton'

};
const useStyles = makeStyles(() => ({
    gridItem: {
        paddingRight: '1em'
    },
    gridParent: {
        paddingLeft: '1em'
    },
    input: { width: '100%' },
    button: {
        marginTop: 'auto',
        marginBottom: 'auto'
    }
}));
function Entities({
    name,
    index,
    control,
    onDelete,
    removeTooltipText,
    readOnly,
    required
}) {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Grid container>
            <Grid className={classes.gridItem} container item xs={5}>
                <EntitiesAutoComplete
                    control={control}
                    name={`${name}.${index}.entity_id`}
                    readOnly={readOnly}
                    required={required}
                />
            </Grid>
            <Grid className={classes.gridParent} container item xs={3}>
                <Controller
                    name={`${name}.${index}.date_from`}
                    control={control}
                    defaultValue={null}
                    render={({ field: fieldDate }) => (
                        <CustomDatePicker
                            disabled={readOnly}
                            disableToolbar
                            inputFormat={dateFormat.format}
                            toolbarTitle='Od'
                            label={intl.formatMessage({ id: messages.dateFrom })}
                            showTodayButton
                            fullWidth
                            okLabel={intl.formatMessage({ id: messages.dateOkButton })}
                            cancelLabel={intl.formatMessage({ id: messages.dateCancelButton })}
                            todayLabel={intl.formatMessage({ id: messages.dateTodayButton })}
                            renderInput={(params) => <TextField {...params} />}
                            {...fieldDate}
                        />
                    )}
                />

            </Grid>
            <Grid className={classes.gridParent} container item xs={3}>
                <Controller
                    name={`${name}.${index}.date_to`}
                    control={control}
                    defaultValue={null}
                    render={({ field: fieldDate }) => (
                        <CustomDatePicker
                            disabled={readOnly}
                            disableToolbar
                            inputFormat={dateFormat.format}
                            toolbarTitle='Od'
                            label={intl.formatMessage({ id: messages.dateTo })}
                            showTodayButton
                            fullWidth
                            okLabel={intl.formatMessage({ id: messages.dateOkButton })}
                            cancelLabel={intl.formatMessage({ id: messages.dateCancelButton })}
                            todayLabel={intl.formatMessage({ id: messages.dateTodayButton })}
                            renderInput={(params) => <TextField {...params} />}
                            {...fieldDate}
                        />
                    )}
                />

            </Grid>
            { !readOnly
            && (
                <Grid item xs={12} sm={1} sx={{ display: 'flex' }}>
                    <Tooltip placement='top' title={removeTooltipText}>
                        <IconButton
                            className={classes.button}
                            onClick={
                                () => { onDelete(index); }
                            }
                            size='large'
                        >
                            <RemoveIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    );
}

Entities.defaultProps = {
    removeTooltipText: '',
    readOnly: false,
    required: false
};

Entities.propTypes = {
    index: PropTypes.number.isRequired,
    onDelete: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    removeTooltipText: PropTypes.string,
    readOnly: PropTypes.bool
};

export default Entities;
