import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import {
    DataGrid, GridActionsCellItem, GridRowModes, GridRowEditStopReasons, GRID_DATE_COL_DEF
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';
import dateFormat from '../../../../../constants/dateFormat';
import DataGridCSVToolbar from '../../../../common/DataGridToolbar/DataGridCSVToolbar';
import useCounterTypes from '../../../Infrastructures/hooks/useCounterTypes';
import CounterTypes from '../../../Infrastructures/EditInfrastructure/InfrastructureForm/ui/CounterTypes/CounterTypes';
import useDisplaySnackbar from '../../../../../lib/displaySnackbar';
import { responses } from '../../../../../constants/ResponseCodes';
import ConfirmDialog from '../../../../common/ConfirmDialog/ConfirmDialog';
import GridEditDateCell from '../../../../common/DataGridEditCells/GridEditDateCell';
import convertColumnsToVisibilityModel from '../../../../../lib/convertColumnsToVisibilityModel';
import FixedAddButton from '../../../../common/FixedAddButton/FixedAddButton';
import formatUTCDate from '../../../../../lib/formatUTCDate';

const messages = {
    counterTypes: { id: 'app.infrastructures.counterTypes' },
    readings: { id: 'app.infrastructures.readings' },
    value: { id: 'app.readings.value' },
    date: { id: 'app.readings.date' },
    entered: { id: 'app.readings.entered' },
    updated: { id: 'app.readings.updated' },
    removed: { id: 'app.readings.removed' },
    updateError: { id: 'app.readings.updateError' },
    removeError: { id: 'app.readings.removeError' },
    confirmDeleteTitle: { id: 'app.readings.confirmDeleteTitle' },
    confirmDeleteText: { id: 'app.readings.confirmDeleteText' }
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    root: {
        marginLeft: '10px',
        width: '50%'
    },
    cancel: {
        marginLeft: theme.spacing(3)
    },
    drawer: {
        width: '45vw',
        position: 'relative',
        display: 'flex',
        height: '100%'
    },
    filterInput: {
        flex: 0,
        marginTop: theme.spacing(1.5)
    },
    dataGrid: {
        flex: 1,
        display: 'grid'
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    }
}));

function Readings({
    id, open, onClose, exportFileName
}) {
    const classes = useStyles();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar(messages.addedTask);
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });
    const [loading, setLoading] = useState(false);
    const [readings, setReadings] = useState([]);
    const { counterTypes, loading: loadingTypes } = useCounterTypes();
    const {
        control, watch, getValues
    } = useForm();
    const watchCounterType = watch('counterType');

    const [rowModesModel, setRowModesModel] = useState({});

    const [isRemoveDialog, setIsRemoveDialog] = useState(false);
    const [removeId, setRemoveId] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const fetchReadings = async () => {
        if (id) {
            const type = getValues('counterType');
            setLoading(true);
            const response = await RestRequestsHelper.getReadingsForInfrastructure(id, type);
            setReadings(response.data.readings);
            setLoading(false);
        }
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            // eslint-disable-next-line no-param-reassign
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (rowId) => () => {
        setRowModesModel({ ...rowModesModel, [rowId]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (rowId) => () => {
        setRowModesModel({ ...rowModesModel, [rowId]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (rowId) => async () => {
        setRemoveId(rowId);
        setIsRemoving(false);
        setIsRemoveDialog(true);
    };

    const handleCancelClick = (rowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [rowId]: { mode: GridRowModes.View, ignoreModifications: true }
        });
    };

    const processRowUpdate = async (newRow) => {
        const updatedRow = {
            ...newRow,
            createdAt: dayjs(newRow?.createdAt).toISOString(),
            date: formatUTCDate(newRow?.date)
        };

        const response = await RestRequestsHelper.updateReading(updatedRow);
        if (response?.data?.status === responses.error) {
            displaySnackbar('error', messages.updateError);
        } else {
            displaySnackbar('success', messages.updated);
        }

        fetchReadings();

        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleDeleteReading = async (rowId) => {
        setIsRemoving(true);

        const { data } = await RestRequestsHelper.deleteReading(rowId);
        if (data.success) {
            displaySnackbar('success', messages.removed);
        } else {
            displaySnackbar('error', messages.removeError);
        }

        fetchReadings();
        setIsRemoving(false);
        setIsRemoveDialog(false);
    };

    useEffect(() => {
        fetchReadings();
    }, [id, watchCounterType]);

    const columns = [{
        field: 'counterType',
        headerName: intl.formatMessage({ id: messages.counterTypes.id }),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        hide: getValues('counterType')
    }, {
        field: 'date',
        ...GRID_DATE_COL_DEF,
        headerName: intl.formatMessage({ id: messages.date.id }),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ value }) => new Date(value),
        renderCell: ({ value }) => formatUTCDate(value, dateFormat.format),
        renderEditCell: (params) => <GridEditDateCell {...params} />,
        editable: true
    }, {
        field: 'createdAt',
        type: 'date',
        headerName: intl.formatMessage({ id: messages.entered.id }),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueGetter: ({ value }) => new Date(value),
        renderCell: ({ value }) => dayjs(value).format(dateFormat.format)
    }, {
        field: 'value',
        headerName: intl.formatMessage({ id: messages.value.id }),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        editable: true
    }, {
        field: 'actions',
        type: 'actions',
        headerName: '',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id: rowId }) => {
            const isInEditMode = rowModesModel[rowId]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        sx={{
                            color: 'primary.main'
                        }}
                        onClick={handleSaveClick(rowId)}
                    />,
                    <GridActionsCellItem
                        icon={<CloseIcon />}
                        onClick={handleCancelClick(rowId)}
                        color='inherit'
                    />
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label='Edit'
                    className='textPrimary'
                    onClick={handleEditClick(rowId)}
                    color='inherit'
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label='Delete'
                    onClick={handleDeleteClick(rowId)}
                    color='inherit'
                />
            ];
        }
    }];

    const handleClose = () => {
        setRowModesModel({});
        onClose();
    };
    const setData = () => (readings || []).map((reading) => ({
        date: formatUTCDate(reading?.date, dateFormat.format),
        createdAt: dayjs(reading?.createdAt).format(dateFormat.format),
        value: reading?.value
    }));

    const setHeaders = () => columns.map((column) => ({ label: column.headerName, key: column.field }));

    return (
        <div>
            <FixedAddButton redirectTo='/readings/add' />

            <ConfirmDialog
                isOpen={isRemoveDialog}
                handleClose={() => setIsRemoveDialog(false)}
                handleRemove={handleDeleteReading}
                row={removeId}
                dialogTitle={messages.confirmDeleteTitle.id}
                dialogText={messages.confirmDeleteText.id}
                isLoading={isRemoving}
            />
            <Drawer
                anchor='right'
                open={open}
                onClose={handleClose}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            color='inherit'
                            onClick={handleClose}
                            aria-label='close'
                            size='large'
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' className={classes.title}>
                            <FormattedMessage id={messages.readings.id} />
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Paper className={classes.drawer}>
                    {
                        loadingTypes
                            ? (
                                <CircularProgress className={classes.loading} />
                            )
                            : (
                                <Grid container spacing={1.5} direction='column'>
                                    <Grid item xs={12} className={classes.filterInput}>
                                        <CounterTypes control={control} name='counterType' options={counterTypes} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.dataGrid}>
                                        <DataGrid
                                            initialState={{
                                                sorting: {
                                                    sortModel: [{ field: 'date', sort: 'desc' }]
                                                }
                                            }}
                                            rows={readings}
                                            columns={columns}
                                            loading={loading}
                                            getRowId={(row) => row._id}
                                            components={{ Toolbar: DataGridCSVToolbar }}
                                            componentsProps={{
                                                toolbar: {
                                                    fileName: exportFileName,
                                                    headers: setHeaders(),
                                                    data: setData()
                                                }
                                            }}
                                            editMode='row'
                                            rowModesModel={rowModesModel}
                                            onRowModesModelChange={handleRowModesModelChange}
                                            onRowEditStop={handleRowEditStop}
                                            processRowUpdate={processRowUpdate}
                                            sx={{ '.MuiDataGrid-cell--editing': { backgroundColor: '#5b5414 !important' } }}
                                            columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
                                        />
                                    </Grid>
                                </Grid>
                            )
                    }
                </Paper>
            </Drawer>
        </div>
    );
}
Readings.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    exportFileName: PropTypes.string.isRequired
};

export default Readings;
