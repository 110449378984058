import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { SaveAlt as SaveAltIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import CSVExportData from '../../../../../common/DataGridToolbar/CSVExportData';
import usePageTitle from '../../../../../common/DataGridToolbar/usePageTitle';
// import { GridToolbarExport } from '@material-ui/data-grid';
import { TOOLBAR_SELECT } from '../../data/invoicesConfig';

const messages = {
    generateElixir: { id: 'app.invoices.generateElixir' },
    changeFlowState: { id: 'app.invoices.changeFlowState' },
    massRemoval: { id: 'app.massRemovalToolbar.massRemoval' }
};

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(1)
    }
}));

function ToolbarNoSelect({
    setOption, exportCSVOptions: {
        handleDownloadClick, download, dataDownload, headers, exportFiltersText
    },
    positionsUngrouped
}) {
    const classes = useStyles();
    const intl = useIntl();
    const handleClick = (type) => () => setOption(type);
    return (
        <>
            {/* <GridToolbarExport /> */}
            <CSVExportData
                onClick={handleDownloadClick}
                download={download}
                headers={headers}
                filename={usePageTitle(exportFiltersText)}
                data={dataDownload}
            />
            {!positionsUngrouped && (
                <>
                    <Button color='primary' size='small' onClick={handleClick(TOOLBAR_SELECT.elixir)}>
                        <SaveAltIcon fontSize='small' className={classes.icon} />
                        {intl.formatMessage(messages.generateElixir)}
                    </Button>
                    <Button color='primary' size='small' onClick={handleClick(TOOLBAR_SELECT.changeFlowState)}>
                        <EditIcon fontSize='small' className={classes.icon} />
                        {intl.formatMessage(messages.changeFlowState)}
                    </Button>
                    <Button color='primary' size='small' onClick={handleClick(TOOLBAR_SELECT.massRemoval)}>
                        <DeleteIcon fontSize='small' className={classes.icon} />
                        {intl.formatMessage(messages.massRemoval)}
                    </Button>
                </>
            )}

        </>
    );
}

ToolbarNoSelect.propTypes = {
    setOption: PropTypes.func,
    exportCSVOptions: PropTypes.object.isRequired,
    positionsUngrouped: PropTypes.bool
};

ToolbarNoSelect.defaultProps = {
    setOption: () => {},
    positionsUngrouped: false
};

export default ToolbarNoSelect;
