import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import { CircularProgress, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { setVindication } from '../../../../../../actions';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../../../lib/displaySnackbar';
import errors from '../../../../../../constants/ResponseCodes';
import VindicationForm from '../../../../../common/VindicationForm/VindicationForm';
import SIDE_PANEL_STATUS from '../data/sidePanelStatus';

const useStyles = makeStyles(() => ({
    drawer: {
        width: '90vw',
        overflowX: 'hidden',
        textAlign: 'center'
    },
    progress: {
        width: '90vw',
        marginTop: 'auto',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    }
}));

const messages = {
    editedTask: { id: 'app.vindication.editedTask' },
    errorUpdate: { id: 'app.addTask.errorUpdate' },
    errorFileLimit: { id: 'app.addTask.errorFileLimit' }
};

function VindicationsSidePanel({
    onClose, onUpdate, filterByConcerns, inhabitantMode, applicantFilter, status
}) {
    const intl = useIntl();
    const classes = useStyles();
    const vindication = useSelector((state) => state.vindication);
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ enqueueSnackbar, intl });
    const [isVindicationLoading, setIsVindicationLoading] = useState(false);

    const dispatch = useDispatch();
    useEffect(async () => {
        if (typeof vindication === 'string') {
            setIsVindicationLoading(true);
            const { data } = await RestRequestsHelper.getVindication(vindication);
            dispatch(setVindication(data?.data));
            setIsVindicationLoading(false);
        }
    }, [vindication]);

    const handleSubmit = async (updatedVindication, files) => {
        const { data, error } = await RestRequestsHelper.updateVindication(updatedVindication, files);
        if (error) {
            switch (error.code) {
                case errors.filesLimitAlmostReached:
                    displaySnackbar('error', messages.errorFileLimit);
                    break;
                default:
                    displaySnackbar('error', messages.errorUpdate);
                    break;
            }
            return false;
        }
        onClose();
        if (data) {
            displaySnackbar('success', messages.editedTask, { number: data.value });
            onUpdate();
        }
        return true;
    };

    const handleDelete = async () => {
        await RestRequestsHelper.deleteVindication(vindication._id);
        onClose();
        onUpdate();
    };

    const isPanelOpen = status === SIDE_PANEL_STATUS.loading || status === SIDE_PANEL_STATUS.loaded || vindication !== null;

    return (
        <Drawer anchor='right' open={isPanelOpen} onClose={onClose}>
            {(status === SIDE_PANEL_STATUS.loading || isVindicationLoading) ? (
                <div className={classes.progress}><CircularProgress /></div>
            ) : (
                <Paper className={classes.drawer}>
                    <VindicationForm
                        onSubmit={handleSubmit}
                        onDelete={handleDelete}
                        filterByConcerns={filterByConcerns}
                        inhabitantMode={inhabitantMode}
                        readOnly={inhabitantMode}
                        applicantFilter={applicantFilter}
                    />
                </Paper>
            )}
        </Drawer>
    );
}

VindicationsSidePanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    status: PropTypes.string,
    onUpdate: PropTypes.func,
    filterByConcerns: PropTypes.func,
    inhabitantMode: PropTypes.bool,
    applicantFilter: PropTypes.object
};

VindicationsSidePanel.defaultProps = {
    status: SIDE_PANEL_STATUS.closed,
    onUpdate: () => {},
    filterByConcerns: () => {},
    inhabitantMode: false,
    applicantFilter: () => {}
};

export default VindicationsSidePanel;
