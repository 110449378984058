import formatMoney from '../../../../lib/formatMoney';
import formatBankAccount from '../../../../lib/formatBankAccount';
import getCurrentDate from '../../../../lib/getCurrentDate';
import formatUTCDate from '../../../../lib/formatUTCDate';

const editBankStatementForm = (data) => (data ? {
    entity: data?.entityId ? {
        _id: data?.entityId,
        name: data?.entityName || data?.entity?.name,
        bankNumber: data?.entityBankNumber
    } : null,
    entityAltName: data?.entityAltName,
    entityBankNumber: data?.entityBankNumber && formatBankAccount(data.entityBankNumber),
    contractor: data?.contractorId ? {
        _id: data?.contractorId,
        name: data?.contractorName || data?.contractor?.name,
        bankNumber: data?.contractorBankNumber
    } : null,
    contractorAltName: data?.contractorAltName,
    contractorBankNumber: data?.contractorBankNumber && formatBankAccount(data.contractorBankNumber),
    reference: data?.reference,
    amountWithdrawn: data?.amount < 0 ? formatMoney(-data.amount / 100) : 0,
    amountDeposited: data?.amount > 0 ? formatMoney(data.amount / 100) : 0,
    documents: data?.documents,
    date: formatUTCDate(data?.date),
    type: data?.type
} : {
    contractor: null,
    reference: '',
    amountWithdrawn: 0,
    amountDeposited: 0,
    documents: [],
    date: getCurrentDate()
});

export default editBankStatementForm;
