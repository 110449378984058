import { isPlainObject } from 'lodash';

const createType = (symbolName: string) => Symbol(symbolName);

export const Position = createType('Document/Position/Type');
export const DocumentType = createType('Document/Type');
export const Transaction = createType('Transaction');
export const Entity = createType('Entity');
export const Color = createType('Atomic/Color');
export const Counter = createType('Dictionary/Counter');
export const TallyType = createType('Dictionary/TallyType');
export const TallySection = createType('Dictionary/TallySection');
export const TallySectionLevel = createType('Dictionary/TallySection/Level');
export const Combination = createType('Combination');
export const Algorithm = createType('Algorithm');
export const AdvancePayments = createType('AdvancePayments');
export const TypeRate = createType('TypeRate');
export const CountedIngredient = createType('CountedIngredient');
export const DocumentKind = createType('DocumentKind');

type EnumOption = { value: unknown; label: string };
interface EnumType {
    is_enum: true;
    options: EnumOption[];
}
export const Enum = (...options: (string | EnumOption)[]): EnumType => {
    if (options.every((option) => typeof option === 'string')) {
        const opts = options as string[];
        return {
            is_enum: true,
            options: opts.map((option) => ({ value: option, label: option }))
        };
    }
    return {
        is_enum: true,
        options: options as EnumOption[]
    };
};

type TypeSignature = Symbol | BooleanConstructor | NumberConstructor | EnumType;

// This function converts string along with mongoose schema types into the target type
export const convertToType = (type: TypeSignature, value: string | unknown): unknown => {
    if (Array.isArray(type)) {
        if (Array.isArray(value)) {
            return value.map((item) => convertToType(type[0], item));
        }
        if (typeof value === 'string') {
            return value.split(/\s*,\s*/).map((item) => convertToType(type[0], item));
        }
        throw new Error('convertToType: value is not a string nor an array');
    }
    if (type === Boolean) {
        return value === 'true' || value === true;
    }
    if (type === Number) {
        return parseFloat(value as string);
    }
    if (isPlainObject(value)) {
        const id = (value as { id?: unknown })?.id;
        return id || value;
    }
    return value;
};

export const getDefaultValue = (type: TypeSignature) => {
    if (Array.isArray(type)) {
        return [];
    }
    if (type === Boolean) {
        return false;
    }
    if (type === Number) {
        return 0;
    }
    if (type === Color) {
        return '#000000';
    }
    return '';
};
