import React from 'react';
import { Typography, Grid, Fab } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import TaskStatus from '../../../common/TaskStatus/TaskStatus';
import dateFormat from '../../../../constants/dateFormat';
import formatUTCDate from '../../../../lib/formatUTCDate';

const messages = {
    tasks: { id: 'app.homePage.tasks' },
    concerns: { id: 'app.homePage.concerns' },
    content: { id: 'app.homePage.content' },
    dueDate: { id: 'app.homePage.dueDate' },
    category: { id: 'app.homePage.category' },
    subcategory: { id: 'app.homePage.subcategory' }
};

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflow: 'auto',
        display: 'contents'
    },
    title: {
        marginBottom: theme.spacing(5),
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.text.secondary
        }
    },
    taskCard: {
        textAlign: 'left',
        position: 'relative',
        marginBottom: theme.spacing(1),
        padding: 0
    },
    taskNumber: {
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        textAlign: 'right',
        '&:hover': {
            color: theme.palette.text.primary
        }
    },
    items: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
        '&:last-child': {
            paddingBottom: 10
        }
    },
    addBtn: {
        bottom: theme.spacing(1),
        zIndex: 1,
        position: 'sticky'
    },
    column: {
        flexDirection: 'column'
    }
}));

const useUserTasks = (id) => {
    if (id) {
        const { data } = RestRequestsHelper.getUserTasks(useSWR, id);
        return data?.data || [];
    }
    return [];
};

function TaskCard({ task }) {
    const classes = useStyles();

    const goToTask = (taskID) => {
        window.open(`/tasks/browse?id=${taskID}`);
    };
    return (
        <Card
            variant='outlined'
            className={classes.taskCard}
        >
            <CardContent className={classes.items}>
                <Grid container>
                    <Grid item xs={9}>
                        <Typography variant='h6'>{task.content}</Typography>
                        <Typography variant='h7'>{task.concerns.map((item) => item.$name).join(', ')}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        sx={{
                            textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'
                        }}
                    >
                        <Typography
                            variant='h7'
                            color='textSecondary'
                            className={classes.taskNumber}
                            onClick={() => goToTask(task._id)}
                        >
                            {task.numberUI}
                        </Typography>
                        <Typography variant='h8'>
                            <TaskStatus status={task.status} />
                        </Typography>
                        {task.due_date && (
                            <Typography variant='h8'>
                                {formatUTCDate(task.due_date, dateFormat.format)}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

function TasksDisplay() {
    const isProductionDb = useSelector((state) => state.dbInfo.production);
    const classes = useStyles({ isProductionDb });
    const user = useSelector((state) => state.user);
    const userTasks = useUserTasks(user.sub);
    const history = useHistory();

    const redirectAddTask = () => history.push('/tasks/add');

    const goToTasks = () => {
        history.push('/tasks/browse?userTasks');
    };
    return (
        <div className={classes.root}>
            <Typography variant='h4' className={classes.title} onClick={goToTasks}>
                <FormattedMessage id={messages.tasks.id} />
            </Typography>
            {userTasks.map((task, idx) => (
                <TaskCard task={task} key={idx} />
            ))}
            <Fab size='small' color='primary' className={classes.addBtn} onClick={redirectAddTask}>
                <AddIcon />
            </Fab>
        </div>
    );
}

TaskCard.propTypes = {
    task: PropTypes.object.isRequired
};

export default TasksDisplay;
