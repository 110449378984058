import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import defaultInvoiceTypeValue from '../../../../../../../constants/invoiceTypes';
import ImportDocument from '../../../../../../common/ImportDocument/ImportDocument';

const messages = {
    importSettings: {
        id: 'app.import.settings'
    },
    verify: {
        id: 'app.import.verify'
    },
    addDirectly: {
        id: 'app.import.addDirectly'
    },
    confirm: {
        id: 'app.form.confirm'
    },
    uploadPdf: {
        id: 'app.form.upload'
    },
    mustSelectFile: {
        id: 'app.form.mustSelectFile'
    },
    rtfMany: {
        id: 'app.addInvoice.rtfMany'
    },
    addManually: {
        id: 'app.menu.addManually'
    }
};

const useStyles = makeStyles((theme) => ({
    close: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        verticalAlign: 'top'
    },
    addManually: {
        position: 'absolute',
        left: theme.spacing(1),
        bottom: theme.spacing(1)
    }
}));

function InvoiceProperties({ open: propOpen, onSubmit }) {
    const history = useHistory();
    const classes = useStyles();
    const [verify, setVerify] = useState(true);
    const [invoiceType, setInvoiceType] = useState(defaultInvoiceTypeValue);
    const [files, setFiles] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(propOpen);
    const intl = useIntl();
    const input = useRef(null);

    useEffect(() => {
        setOpen(propOpen);
    }, [propOpen]);

    const handleSubmit = () => {
        const data = {
            verify,
            invoiceType: invoiceType.id,
            files
        };
        if (files.length >= 1) {
            onSubmit(data);
            setOpen(false);
        } else {
            enqueueSnackbar(intl.formatMessage(messages.mustSelectFile, { file: 'pdf/rtf/xls' }), { variant: 'warning' });
        }
        setFiles([]);
        input.current.value = '';
    };

    const handleDocumentTypeChange = (value) => {
        setInvoiceType(value);
    };

    const handleClose = () => {
        history.push('/invoices/browse');
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id='form-dialog-title' onClose={handleClose}>
                    <FormattedMessage id={messages.importSettings.id} />
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={handleClose}
                        aria-label='close'
                        className={classes.close}
                        size='large'
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ImportDocument
                        onFilesChange={setFiles}
                        onVerifyChange={setVerify}
                        onDocumentTypeChange={handleDocumentTypeChange}
                        files={files}
                        verify={verify}
                        documentType={invoiceType}
                        documentKind='invoice'
                        ref={input}
                        acceptedFiles='application/pdf,.rtf,.xls,.xlsx,.numbers'
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={handleSubmit}
                        color='primary'
                    >
                        <FormattedMessage id={messages.confirm.id} />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

InvoiceProperties.propTypes = {
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default InvoiceProperties;
