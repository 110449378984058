import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import CSVExportData from './CSVExportData';

const useStyles = makeStyles(() => ({
    toolbar: {
        display: 'flex'
    }
}));

function DataGridCSVToolbar({ headers, fileName, data }) {
    const classes = useStyles();
    const [download, setDownload] = useState(false);
    return (
        <div className={classes.toolbar}>
            <CSVExportData
                headers={headers}
                download={download}
                onClick={() => {
                    setDownload(true);
                    setTimeout(() => {
                        setDownload(false);
                    }, 1000);
                }}
                filename={`${fileName}.csv`}
                data={data}
            />
        </div>
    );
}

DataGridCSVToolbar.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object).isRequired,
    fileName: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DataGridCSVToolbar;
