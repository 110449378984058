import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import React, {
    useEffect, useState, useRef
} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useQueryClient, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import FormPickerMaster from '../../../../common/FormPicker/FormPickerMaster';
import { HotkeyProvider } from '../../../../common/Hotkeys/Hotkeys';
import restRequestsHelper, { getToken } from '../../../../../lib/restRequestsHelper';
import config from '../../../../../config';
import InvoiceForm from '../../../../common/InvoiceForm/InvoiceForm';
import defaultInvoice from '../../../../common/InvoiceForm/InvoiceDefault';
import DocumentPreview from '../../../../common/DocumentPreview/DocumentPreview';

const messages = {
    updatedSuccessfully: { id: 'app.invoiceUpdate.success' },
    updateError: { id: 'app.invoiceUpdate.error' }
};
const fetchIncomes = async (data) => {
    const token = getToken();
    if (data.queryKey[1]) {
        const url = `${config.backendHost}:${config.backendPort}/income/retrieve/${data.queryKey[1]}`;
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return res.json();
    }
    throw new Error('id undefined');
};

const updateIncome = async ({ id, data }) => {
    const token = getToken();
    const url = `${config.backendHost}:${config.backendPort}/income/${id}`;
    const res = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
    });
    const result = await res.json();
    if (!res.ok) {
        throw new Error(result?.error);
    }
    return result;
};

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: '90vw',
        height: '100%',
        overflow: 'hidden'
    },
    drawerData: {
        padding: 32,
        textAlign: 'center'
    },
    preview: {
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    maxSize: {
        height: '100vh'
    }
}));

function SidePanel({
    onClose, readOnly, onUpdate, mutate
}) {
    const classes = useStyles();

    const id = useSelector((state) => state.income);

    const { enqueueSnackbar } = useSnackbar();
    const intl = useIntl();

    const [data, setData] = useState(defaultInvoice());
    const content = useRef([]);
    const [status, setStatus] = useState('loading');
    const [isLoading, setIsLoading] = useState(false);
    const [pdfFile, setPdfFile] = useState(null);
    const [isLoadingFile, setIsLoadingFile] = useState(true);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (id !== undefined) {
            setStatus('loading');
            fetchIncomes({ queryKey: ['incomes', id] })
                .then((resp) => {
                    const positions = resp.data.content;
                    const newData = resp;
                    newData.data.content = [];
                    content.current = positions;
                    setData(newData.data);
                    setStatus('success');
                });
        } else {
            setIsLoading(true);
            setData(defaultInvoice());
        }
    }, [id]);

    useEffect(() => {
        setTimeout(() => {
            if (id && data.content.length === 0) {
                setIsLoading(true);
                setData((prevState) => {
                    const newVal = prevState;
                    newVal.content = content.current;
                    return newVal;
                });
                setIsLoading(false);
            }
        });
    }, [data]);

    const queryClient = useQueryClient();
    const { mutate: mutateSave } = useMutation(updateIncome, {
        onSuccess: () => {
            onClose();
            onUpdate();
            enqueueSnackbar(intl.formatMessage({ id: messages.updatedSuccessfully.id }), { variant: 'success' });
        },
        onError: () => {
            enqueueSnackbar(intl.formatMessage({ id: messages.updateError.id }), { variant: 'error' });
        },
        onSettled: () => {
            queryClient.invalidateQueries('update');
        }
    });

    const getUpdatedFile = debounce(async (signal) => {
        setIsLoadingFile(true);
        const filePdf = await restRequestsHelper.getIncomeFileView(data, signal);
        setIsLoadingFile(false);
        setPdfFile(filePdf);
    }, 2000);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        if (data && isFormValid) {
            getUpdatedFile(signal);
        }
        return () => controller.abort();
    }, [data]);

    const handleFormChange = (changedData) => {
        setData(changedData);
    };

    const handleSubmitIncomeForm = (event) => {
        event.preventDefault();
        const submitData = {
            ...data,
            buyer: data.buyer,
            seller: data.seller,
            flowStatus: data.flowStatus.id,
            type: data.type.id ?? data.type
        };
        mutateSave({ id, data: submitData });
    };

    function HotkeysController() {
        return null;
    }

    return (
        <Drawer anchor='right' open={id} onClose={onClose}>
            <Paper className={classes.drawerPaper}>
                <HotkeyProvider id='incomeIndex'>
                    <HotkeysController />
                    <Grid className={classes.drawerData} container spacing={2}>
                        <Grid item xs={6} sx={{ paddingLeft: '0 !important' }}>
                            <FormPickerMaster enable>
                                <InvoiceForm
                                    className={classes.maxSize}
                                    isLoading={status !== 'success'}
                                    isContentLoading={isLoading}
                                    onSubmit={handleSubmitIncomeForm}
                                    onClose={() => {
                                        onClose();
                                        mutate();
                                    }}
                                    invoiceFormData={{ ...data, _id: id }}
                                    onFormValidChange={(formStatus) => setIsFormValid(formStatus)}
                                    onChange={handleFormChange}
                                    showSetNamesInput
                                    isManualInvoice
                                    saveButton
                                    flowStatus
                                    incomeForm
                                    db='mongo'
                                    readOnly={readOnly}
                                />
                            </FormPickerMaster>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.preview}>
                                {isFormValid && (
                                    <DocumentPreview
                                        file={pdfFile}
                                        loading={isLoadingFile}
                                        disableActions
                                        showFileName
                                        currentFileName={`${data?.buyer?.map((elm) => elm.name).join(', ')}.pdf`}
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>

                </HotkeyProvider>
            </Paper>
        </Drawer>
    );
}

SidePanel.propTypes = {
    onClose: PropTypes.func,
    onUpdate: PropTypes.func,
    readOnly: PropTypes.bool,
    mutate: PropTypes.func
};

SidePanel.defaultProps = {
    onClose: () => {},
    onUpdate: () => {},
    mutate: () => {},
    readOnly: false
};
export default SidePanel;
