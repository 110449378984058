import React, { useState, useRef } from 'react';
import InfrastructuresColumns from '../../Infrastructures/InfrastructuresColumns/InfrastructuresColumns';
import Readings from './Readings/Readings';

function BrowseReadings() {
    const columnsRef = useRef();
    const [selectedToEdit, setSelectedToEdit] = useState('');
    const [selectedItems, setSelectedItems] = useState({});

    const handleCloseEdit = () => {
        setSelectedToEdit('');
    };

    const createFileName = () => {
        const firstPart = `Odczyty - ${selectedItems.realEstate}`;
        let secondPart = '';
        if (selectedItems.premises) {
            secondPart = ` - lokal ${selectedItems.premises}`;
        } else if (selectedItems.storeys) {
            secondPart = ` - kondygnacja ${selectedItems.storeys}`;
        } else if (selectedItems.building) {
            secondPart = ` - budynek ${selectedItems.building}`;
        }
        return `${firstPart}${secondPart}`;
    };

    const handleDoubleClick = (item, selected) => {
        setSelectedToEdit(item._id);
        setSelectedItems(selected);
    };

    return (
        <>
            <InfrastructuresColumns ref={columnsRef} onDoubleClick={handleDoubleClick} />
            <Readings
                id={selectedToEdit}
                exportFileName={createFileName()}
                onClose={handleCloseEdit}
                open={selectedToEdit.length > 0}
            />
        </>
    );
}
export default BrowseReadings;
