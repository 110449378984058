import useSWR from 'swr';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';

const useSums = (filterData, disable) => {
    const { data, error } = RestRequestsHelper.getSettlementsSums(useSWR, filterData, disable);

    return {
        sumDocuments: data?.sumDocuments || 0,
        sumTransactions: data?.sumTransactions || 0,
        loading: !error && !data && !disable
    };
};

export default useSums;
