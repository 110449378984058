import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.extend(localeData);

const setUpperMonthNames = (month) => month.charAt(0).toUpperCase() + month.slice(1);

// Set month names to be uppercase
dayjs.locale('pl');
dayjs.updateLocale('pl', {
    months: dayjs.months().map(setUpperMonthNames),
    monthsShort: dayjs.monthsShort().map(setUpperMonthNames)
});

