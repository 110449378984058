import Grid from '@mui/material/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import Switch from '@mui/material/Switch';
import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UniversalCombobox, { comboboxTypes } from '../../../../../common/Combobox/UniversalCombobox';
import dateFormat from '../../../../../../constants/dateFormat';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import requestsMaxLimitValue from '../../../../../../constants/requests';
import CustomDatePicker from '../../../../../common/CustomDatePicker/CustomDatePicker';

const messages = {
    verify: { id: 'app.import.verify' },
    addDirectly: { id: 'app.import.addDirectly' },
    contractorName: { id: 'app.contractors.contractorName' },
    dateOfIncome: { id: 'app.income.date' },
    dateOkButton: { id: 'app.invoiceForm.OkButton' },
    dateCancelButton: { id: 'app.invoiceForm.CancelButton' },
    dateTodayButton: { id: 'app.invoiceForm.todayButton' },
    selectAll: { id: 'app.invoiceForm.selectAll' },
    remarks: { id: 'app.correspondence.add.remarks' },
    documentType: { id: 'app.form.documentType' }
};

function GenerateIncomes({
    verify,
    onVerifyChange,
    remarks,
    onRemarksChange,
    dateOfIncome,
    onDateOfIncomeChange,
    incomeType,
    onIncomeTypeChange,
    communities,
    onCommunitiesChange
}) {
    const intl = useIntl();
    const [isSelectingAll, setIsSelectingAll] = useState(false);

    const selectAll = async () => {
        setIsSelectingAll(true);
        const { data } = await RestRequestsHelper.getCommunities('', requestsMaxLimitValue);
        onCommunitiesChange(data);
        setIsSelectingAll(false);
    };

    return (
        <Grid container alignItems='center' spacing={2}>
            <Grid item><FormattedMessage id={messages.addDirectly.id} /></Grid>
            <Grid item>
                <Switch
                    checked={verify}
                    onChange={(event) => {
                        onVerifyChange(event.target.checked);
                    }}
                    name='verify'
                    color='secondary'
                    id='toggle'
                />
            </Grid>
            <Grid item><FormattedMessage id={messages.verify.id} /></Grid>
            <Grid item xs={12}>
                <UniversalCombobox
                    id='communities'
                    fieldName='invoiceTypePrompt2'
                    src={comboboxTypes.communities()}
                    label={intl.formatMessage(messages.contractorName)}
                    multiple
                    required
                    value={communities}
                    onChange={(val) => onCommunitiesChange(val)}
                    showColors
                />
            </Grid>
            <Grid item xs={12}>
                <UniversalCombobox
                    id='incomeType'
                    onChange={(val) => onIncomeTypeChange(null, val)}
                    value={incomeType}
                    required
                    src={comboboxTypes.documentTypes('income')}
                    label={intl.formatMessage(messages.documentType)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='remarks'
                    multiline
                    label={(
                        <FormattedMessage
                            id={messages.remarks.id}
                        />
                    )}
                    name='remarks'
                    autoComplete='remarks'
                    value={remarks}
                    onChange={(event) => {
                        onRemarksChange(event.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <LoadingButton onClick={selectAll} loading={isSelectingAll} fullWidth variant='outlined'>
                    {intl.formatMessage(messages.selectAll)}
                </LoadingButton>
            </Grid>
            <Grid item xs={12}>
                <CustomDatePicker
                    disableToolbar
                    variant='outlined'
                    inputFormat={dateFormat.format}
                    required
                    margin='normal'
                    id='dateOfIncome'
                    label={(
                        <FormattedMessage
                            id={messages.dateOfIncome.id}
                        />
                    )}
                    value={dateOfIncome}
                    onChange={(date) => {
                        onDateOfIncomeChange(date);
                    }}
                    okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                    cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                    showTodayButton
                    todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                    renderInput={(params) => (<TextField {...params} required fullWidth id='dateOfIncome' />)}
                />
            </Grid>
        </Grid>
    );
}

GenerateIncomes.propTypes = {
    verify: PropTypes.bool.isRequired,
    onVerifyChange: PropTypes.func.isRequired,
    dateOfIncome: PropTypes.string.isRequired,
    onDateOfIncomeChange: PropTypes.func.isRequired,
    incomeType: PropTypes.object.isRequired,
    onIncomeTypeChange: PropTypes.func.isRequired,
    communities: PropTypes.array.isRequired,
    onCommunitiesChange: PropTypes.func.isRequired,
    remarks: PropTypes.string.isRequired,
    onRemarksChange: PropTypes.func.isRequired
};
export default GenerateIncomes;
