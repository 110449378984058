import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import useEntities from '../../../../hooks/useEntities';

const messages = {
    entityName: 'app.infrastructures.entityName'
};
const useStyles = makeStyles(() => ({
    input: {
        width: '100%'
    }
}));

function EntitiesAutoComplete({
    name,
    control,
    readOnly,
    multiple,
    required,
    label
}) {
    const intl = useIntl();
    const classes = useStyles();
    const [initialized, setInitialized] = useState(false);
    const [isValueSet, setIsvalueSet] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { entities: options, loading: entitiesLoading } = useEntities(inputValue);
    const filterOptions = (opts) => opts;
    useEffect(() => {
        if (!initialized && !entitiesLoading) {
            setInitialized(true);
        }
    }, [entitiesLoading]);
    const getOptionLabel = (option) => {
        if (options) {
            const type = options.find((it) => it._id === option._id);
            if (type) {
                return type.name;
            }
            return option.name;
        }
        return '';
    };

    return initialized && (
        <Controller
            onChange={([, obj]) => obj}
            defaultValue={multiple ? [] : null}
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    filterOptions={filterOptions}
                    className={classes.input}
                    sx={value?.color ? { '& .MuiAutocomplete-inputRoot': { color: value.color } } : null}
                    value={value}
                    options={options || []}
                    disabled={readOnly}
                    multiple={multiple}
                    isOptionEqualToValue={(option, valueData) => option._id === valueData._id}
                    renderInput={(params) => (
                        <TextField
                            className={classes.input}
                            {...params}
                            fullWidth
                            label={label || intl.formatMessage({ id: messages.entityName })}
                            required={required}
                            InputProps={{
                                ...params.InputProps,
                                required: required && !value?.length
                            }}
                        />
                    )}
                    getOptionLabel={getOptionLabel}
                    renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                            <span style={option?.color ? { color: option.color } : {}}>
                                {getOptionLabel(option)}
                            </span>
                        </li>
                    )}
                    onChange={(event, dataObj) => onChange(dataObj)}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        if (!isValueSet || event) {
                            setInputValue(newInputValue);
                            setIsvalueSet(true);
                        }
                    }}
                />
            )}
        />
    );
}

EntitiesAutoComplete.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    multiple: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string
};

EntitiesAutoComplete.defaultProps = {
    readOnly: false,
    multiple: false,
    required: false,
    label: ''
};

export default EntitiesAutoComplete;
