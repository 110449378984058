import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import GridCellExpand from '../../../../common/DataGridCells/GridCellExpand';
import GridMoneyCell from '../../../../common/DataGridCells/GridMoneyCell';
import dateFormat from '../../../../../constants/dateFormat';
import formatUTCDate from '../../../../../lib/formatUTCDate';

const modifiedTotalChargesInExport = ({ value }) => {
    const modiefiedNumber = (value / 100).toFixed(2);
    let correctTotalCharges = '';
    const regex = /([/.])/g;
    const match = regex.exec(modiefiedNumber);
    if (match) {
        correctTotalCharges = modiefiedNumber.replace(regex, ',');
    }
    return correctTotalCharges;
};
const getFormattedDate = (date) => {
    const formattedDate = dayjs(date);
    return formattedDate.format('DD-MM-YYYY HH:mm:ss');
};
const messages = {
    invoiceNumber: { id: 'app.invoices.invoiceNumber' },
    invoiceDate: { id: 'app.invoices.invoiceDate' },
    saleDate: { id: 'app.invoices.saleDate' },
    payementDate: { id: 'app.invoices.payementDate' },
    totalCharges: { id: 'app.invoices.totalCharges' },
    buyer: { id: 'app.invoices.buyer' },
    seller: { id: 'app.invoices.seller' },
    foreignInvoiceNumber: { id: 'app.invoices.foreignInvoiceNumber' },
    flowStatus: { id: 'app.invoices.flowStatus' },
    group: { id: 'app.invoices.group' },
    positionTitle: { id: 'app.invoices.positionTitle' },
    positionText: { id: 'app.invoices.positionText' },
    generate: { id: 'app.incomes.generatePdf' },
    generateHeader: { id: 'app.incomes.generatePdfHeader' },
    lastDownload: { id: 'app.incomes.lastDownload' }
};

const useColumns = ({
    filterData, handleGenerateFile, disableHidding = false, previewData = false
}) => {
    const intl = useIntl();
    const loaders = useSelector((state) => state.loaders);

    return [
        {
            field: 'generateFile',
            headerName: intl.formatMessage({ id: messages.generateHeader.id }),
            width: 100,
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => (
                <Tooltip
                    title={params.row.downloadedAt ? ` ${intl.formatMessage({ id: messages.lastDownload.id })} ${getFormattedDate(params.row.downloadedAt)}` : undefined}
                >
                    <LoadingButton
                        loading={loaders.fileDownloading && loaders.downloadId === params.id}
                        disabled={loaders.fileDownloading}
                        type='button'
                        onClick={(event) => {
                            event.stopPropagation();
                            handleGenerateFile(params);
                        }}
                    >
                        {intl.formatMessage({ id: messages.generate.id })}
                    </LoadingButton>
                </Tooltip>
            ),
            hide: !filterData?.groupPositions?.value
        },
        {
            field: 'buyer',
            headerName: intl.formatMessage({ id: messages.buyer.id }),
            headerAlign: 'center',
            width: 280,
            hide: filterData?.buyer?.filter && disableHidding,
            renderCell: (params) => (
                <GridCellExpand
                    onClick={() => {
                        params.api.events.rowClick[0]({ row: params.row });
                    }}
                    width={params.colDef.width}
                    data={params.value}
                />
            )
        },
        {
            field: 'seller',
            headerName: intl.formatMessage({ id: messages.seller.id }),
            headerAlign: 'center',
            width: 280,
            hide: filterData?.seller?.filter && disableHidding,
            renderCell: (params) => (
                <GridCellExpand
                    onClick={() => {
                        params.api.events.rowClick[0]({ row: params.row });
                    }}
                    width={params.colDef.width}
                    data={params.value}
                    alt={`#${params?.row?.seller[0]?._id}`}
                />
            )
        },
        {
            field: 'total_gross_charges',
            headerName: intl.formatMessage({ id: messages.totalCharges.id }),
            headerAlign: 'center',
            flex: 1,
            type: 'number',
            renderCell: (params) => (
                <GridMoneyCell
                    onClick={() => {
                        params.api.events.rowClick[0]({ row: params.row });
                    }}
                    width={params.colDef.width}
                    value={params.value / 100}
                />
            ),
            valueFormatter: modifiedTotalChargesInExport
        },
        {
            field: 'foreign_document_number',
            headerName: intl.formatMessage({ id: messages.foreignInvoiceNumber.id }),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <GridCellExpand
                    onClick={() => {
                        params.api.events.rowClick[0]({ row: params.row });
                    }}
                    width={params.colDef.width}
                    value={params.value}
                />
            )
        },
        {
            field: 'document_number',
            headerName: intl.formatMessage({ id: messages.invoiceNumber.id }),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <GridCellExpand
                    onClick={() => {
                        params.api.events.rowClick[0]({ row: params.row });
                    }}
                    width={params.colDef.width}
                    value={params.value}
                />
            )
        },
        {
            field: 'state',
            headerName: intl.formatMessage({ id: messages.flowStatus.id }),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <GridCellExpand
                    onClick={() => {
                        params.api.events.rowClick[0]({ row: params.row });
                    }}
                    width={params.colDef.width}
                    value={params.value?.value}
                    color={params.value?.color}
                />
            )
        },
        {
            field: 'exposure_date',
            headerName: intl.formatMessage({ id: messages.invoiceDate.id }),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <GridCellExpand
                    onClick={() => {
                        params.api.events.rowClick[0]({ row: params.row });
                    }}
                    width={params.colDef.width}
                    value={formatUTCDate(params.value, dateFormat.format)}
                />
            )
        },
        {
            field: 'sell_date',
            headerName: intl.formatMessage({ id: messages.saleDate.id }),
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => (
                <GridCellExpand
                    onClick={() => {
                        params.api.events.rowClick[0]({ row: params.row });
                    }}
                    width={params.colDef.width}
                    value={formatUTCDate(params.value, dateFormat.format)}
                />
            )
        },
        {
            field: 'payment_date',
            headerName: intl.formatMessage({ id: messages.payementDate.id }),
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => (
                <GridCellExpand
                    onClick={() => {
                        params.api.events.rowClick[0]({ row: params.row });
                    }}
                    width={params.colDef.width}
                    value={formatUTCDate(params.value, dateFormat.format)}
                />
            )
        },
        {
            field: 'positionTitle',
            headerName: intl.formatMessage({ id: messages.positionTitle.id }),
            flex: 1,
            headerAlign: 'center',
            hide: filterData?.groupPositions?.value || previewData,
            disableExport: filterData?.groupPositions?.value,
            renderCell: (params) => params.value
        },
        {
            field: 'positionText',
            headerName: intl.formatMessage({ id: messages.positionText.id }),
            flex: 1,
            headerAlign: 'center',
            hide: filterData?.groupPositions?.value || previewData,
            disableExport: filterData?.groupPositions?.value,
            renderCell: (params) => params.value
        }
    ];
};

export default useColumns;
