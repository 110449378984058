import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Grid, TextField, FormGroup, FormControlLabel, Checkbox, Typography, Divider
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import { newContactItem } from '../data/newListItems';
import UniversalCombobox, { comboboxTypes } from '../../../Combobox/UniversalCombobox';
import ListItems from '../../../ListItems/ListItems';

const messages = {
    contacts: { id: 'app.contractors.contacts' },
    type: { id: 'app.contractors.type' },
    value: { id: 'app.contractors.value' },
    description: { id: 'app.contractors.description' },
    verified: { id: 'app.contractors.verified' }
};

const useStyle = makeStyles(() => ({
    checkbox: {
        '& .MuiFormControlLabel-label': {
            fontSize: '0.8rem'
        }
    },
    header: {
        marginTop: 10
    }
}));

function ContractorFormContacts({ control, readOnly }) {
    const classes = useStyle();
    return (
        <Grid item xs={12}>
            <Typography component='h2' variant='h6' className={classes.header} align='left'>
                <FormattedMessage id={messages.contacts.id} />
            </Typography>
            <Divider />
            <Controller
                name='contacts'
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                    <ListItems
                        onNewItem={newContactItem}
                        variant='outlined'
                        fullWidth
                        hideActions={readOnly}
                        render={(fields) => (
                            <Grid container>
                                <Grid item xs={2}>
                                    <UniversalCombobox
                                        src={comboboxTypes.contactTypes()}
                                        variant='outlined'
                                        fullWidth
                                        required
                                        label={<FormattedMessage {...messages.type} />}
                                        disabled={readOnly}
                                        {...fields('type')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        required
                                        label={<FormattedMessage {...messages.value} />}
                                        disabled={readOnly}
                                        {...fields('value')}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        label={<FormattedMessage {...messages.description} />}
                                        disabled={readOnly}
                                        {...fields('content')}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={(<Checkbox {...fields('verified')} checked={fields('verified').value} disabled={readOnly} />)}
                                            label={<FormattedMessage {...messages.verified} />}
                                            className={classes.checkbox}
                                            labelPlacement='top'
                                        />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        )}
                        {...field}
                    />
                )}
            />
        </Grid>
    );
}

ContractorFormContacts.propTypes = {
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool
};

ContractorFormContacts.defaultProps = {
    readOnly: false
};

export default ContractorFormContacts;
