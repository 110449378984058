import { green, red } from '@mui/material/colors';

const COMBOBOX_INFO = {
    // eslint-disable-next-line
    contractors: (data, { text, list, dict, showIf }) => [
        showIf(data.address && data.address.length, () => [
            text([{ id: 'app.UniversalCombobox.addresses' }, ':'], { fontWeight: 'bold' }),
            list(data.address.map((addr) => text([
                [
                    addr.city,
                    addr.postal_code,
                    addr.street,
                    addr.number,
                    `(${addr.type.$name})`
                ].join(' ')
            ])))
        ]),
        showIf(data.contacts && data.contacts.length, () => [
            text([{ id: 'app.UniversalCombobox.contacts' }, ':'], { fontWeight: 'bold' }),
            list(data?.contacts?.sort((first, second) => ((first?.verified > second?.verified) ? -1 : 1))?.map((contact) => text([
                [
                    contact.value,
                    `(${contact?.type?.$name})`
                ].join(' ')
            ], { color: contact?.verified ? green[500] : red[400] })))
        ]),
        showIf(data.type, () => [
            text([{ id: 'app.UniversalCombobox.type', sx: { fontWeight: 'bold' } }, ': ', data.type?.value])
        ]),
        showIf(data.data, () => [
            text([{ id: 'app.UniversalCombobox.data' }, ':']),
            dict(data.data)
        ])
    ]
};

export default COMBOBOX_INFO;
