import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import {
    TextField, Button, Box, Typography, Chip
} from '@mui/material';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import dateFormat from '../../../constants/dateFormat';
import RestRequestsHelper from '../../../lib/restRequestsHelper';

const messages = {
    title: { id: 'app.article.title' },
    content: { id: 'app.article.content' },
    cancel: { id: 'app.article.cancel' },
    save: { id: 'app.article.save' },
    createdAt: { id: 'app.article.createdAt' },
    updatedAt: { id: 'app.article.updatedAt' }
};

function EditArticle({
    data, onClose, refetch, path, language
}) {
    const intl = useIntl();

    const [editorValue, setEditorValue] = useState(data?.content);

    const {
        register, handleSubmit
    } = useForm({ defaultValues: data });

    const [isUpdating, setIsUpdating] = useState(false);

    const handleEdit = async (formData) => {
        setIsUpdating(true);
        const preparedData = {
            ...formData,
            content: editorValue,
            path,
            language
        };

        if (!data) {
            await RestRequestsHelper.addArticle({ data: preparedData });
        } else {
            await RestRequestsHelper.updateArticle(data._id, { data: preparedData });
        }
        setIsUpdating(false);
        refetch();
        onClose();
    };

    return (
        <form onSubmit={handleSubmit(handleEdit)} style={{ width: '80%' }}>
            <Box sx={{
                display: 'flex', flexDirection: 'column', alignContent: 'center', gap: 2
            }}
            >
                <Box sx={{ display: 'flex', gap: 3, mb: 2 }}>
                    {data?.createdAt && (
                        <Typography sx={{ display: 'flex', gap: 1 }}>
                            {intl.formatMessage(messages.createdAt, { date: dayjs(data?.createdAt).format(dateFormat.fullDate) })}
                            <Chip
                                label={data?.creator?.username}
                                size='small'
                                sx={{ color: data?.creator?.color }}
                            />
                        </Typography>
                    )}
                    {data?.updatedAt && (
                        <Typography sx={{ display: 'flex', gap: 1 }}>
                            {intl.formatMessage(messages.updatedAt, { date: dayjs(data?.updatedAt).format(dateFormat.fullDate) })}
                            <Chip
                                label={data?.updatedBy?.username}
                                size='small'
                                sx={{ color: data?.updatedBy?.color }}
                            />
                        </Typography>
                    )}
                </Box>

                <TextField
                    variant='outlined'
                    required
                    fullWidth
                    label={intl.formatMessage(messages.title)}
                    disabled={isUpdating}
                    {...register('title')}
                />
                <div className='document-editor'>
                    <div className='document-editor__toolbar' />
                    <div className='document-editor__editable-container'>
                        <CKEditor
                            onReady={(editor) => {
                                window.editor = editor;
                                const toolbarContainer = document.querySelector('.document-editor__toolbar');
                                toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                            }}
                            config={{
                                language: 'pl',
                                toolbar: ['undo', 'redo', '|', 'heading', '|', 'fontsize', 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'link', 'insertTable', 'blockQuote', '|', 'alignment', '|', 'bulletedList', 'numberedList', 'outdent', 'indent']
                            }}
                            onChange={(_event, editor) => {
                                const val = editor.getData();
                                setEditorValue(val);
                            }}
                            editor={DecoupledEditor}
                            data={data?.content || ''}
                        />
                    </div>
                </div>
                <Box sx={{
                    display: 'flex', width: '100%', gap: 2, mb: 3
                }}
                >
                    <Button
                        variant='contained'
                        onClick={onClose}
                        color='primary'
                        fullWidth
                        disabled={isUpdating}
                    >
                        {intl.formatMessage(messages.cancel)}
                    </Button>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        color='secondary'
                        fullWidth
                        loading={isUpdating}
                        disabled={!editorValue?.length}
                    >
                        {intl.formatMessage(messages.save)}
                    </LoadingButton>
                </Box>
            </Box>
        </form>
    );
}
EditArticle.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    refetch: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
};
EditArticle.defaultProps = {
    data: null
};

export default EditArticle;
