import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type OptionType = {
    translationId: `app.${string}`,
    onClick?: Function,
    redirectTo?: string
}

type Params = {
    open: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClose: (event?: any) => void,
    titleTranslationId: string,
    options: OptionType[]
}

function AddSelectDialog({
    open, onClose, titleTranslationId, options
}: Params) {
    const history = useHistory();

    const handleClick = (item: OptionType) => {
        const { onClick, redirectTo } = item;
        if (onClick) {
            onClick();
        }
        if (redirectTo) {
            history.push(redirectTo);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs'>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <FormattedMessage id={titleTranslationId} />
                <IconButton
                    edge='start'
                    color='inherit'
                    onClick={onClose}
                    aria-label='close'
                    size='large'
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogActions>
                {options.map((item, i) => (
                    <Button variant='contained' onClick={() => handleClick(item)} color={i % 2 === 0 ? 'primary' : 'success'}>
                        <FormattedMessage id={item.translationId} />
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
}

export default AddSelectDialog;
