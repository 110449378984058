const defaultContractorFormValues = (edit) => (edit ? {
    id: edit.id,
    firstName: edit.first_name,
    lastName: edit.last_name,
    name: edit.name,
    user: edit.user,
    type: edit.type,
    contacts: edit.contacts,
    address: edit.address,
    nip: edit.nip,
    regon: edit.regon,
    krs: edit.krs,
    bankAccounts: edit.bank_accounts,
    pesel: edit.pesel,
    identity_card_number: edit.identity_card_number,
    buyers: edit.buyers

} : {
    firstName: '',
    lastName: '',
    name: '',
    user: null,
    type: null,
    contacts: [],
    address: [],
    nip: '',
    regon: '',
    krs: '',
    bankAccounts: [],
    pesel: '',
    identity_card_number: '',
    buyers: []
});

export default defaultContractorFormValues;
