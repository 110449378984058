import React, { useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';
import FormPickerMaster from '../../common/FormPicker/FormPickerMaster';
import { HotkeyProvider } from '../../common/Hotkeys/Hotkeys';
import restRequestsHelper from '../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../lib/displaySnackbar';
import errors from '../../../constants/ResponseCodes';
import FormFileManager, { prepareFilesForUpload, useFormFilesState } from '../../common/FormFileManager/FormFileManager';
import CorrespondenceForm from './components/CorrespondenceForm';

const messages = {
    docName: { id: 'app.addInvoice.docName' },
    success: { id: 'app.correspondence.add.success' },
    filesLimitAlmostReached: { id: 'app.addInvoice.filesLimit' },
    ftpInvalidAddr: { id: 'app.addInvoice.ftpInvalidAddr' },
    ftpInvalidPath: { id: 'app.addInvoice.ftpInvalidPath' },
    generalError: { id: 'app.general.error' }
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '99vw',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    preview: {
        width: '50%',
        textAlign: 'center'
    },
    formContainer: {
        width: '50%',
        margin: theme.spacing(2)
    }
}));

function AddCorrespondence() {
    const intl = useIntl();
    const styles = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ enqueueSnackbar, intl });

    const fileManagerRef = useRef();
    const {
        setFilesVal, getFilesVal, filesField, setFilesField
    } = useFormFilesState(['files'], { files: [] });

    const submitHandler = async (formData) => {
        const filesData = prepareFilesForUpload(getFilesVal('files'));

        const data = {
            ...formData,
            filesMetadata: filesData.metadata
        };
        const response = await restRequestsHelper.addCorrespondence(data, filesData.files);
        if (response.status === 'success') {
            fileManagerRef.current.flushFiles();
            displaySnackbar('success', messages.success, { signature: response.signature });
            return response;
        } if (response.status === 'error' && response.code === errors.filesLimitAlmostReached) {
            displaySnackbar('error', messages.filesLimitAlmostReached);
        } else if (response.status === 'error' && response.code === errors.ftpInvalidAddr) {
            displaySnackbar('error', messages.ftpInvalidAddr);
        } else if (response.status === 'error' && response.code === errors.ftpInvalidPath) {
            displaySnackbar('error', messages.ftpInvalidPath);
        } else if (response.status === 'error') {
            displaySnackbar('error', messages.generalError);
        }
        return null;
    };

    return (
        <HotkeyProvider>
            <FormPickerMaster enable>
                <Box className={styles.root}>
                    <div className={styles.formContainer}>
                        <CorrespondenceForm
                            onSubmit={submitHandler}
                            onClear={() => fileManagerRef.current.flushFiles()}
                        />
                    </div>
                    <div className={styles.preview}>
                        <FormFileManager
                            setValue={setFilesVal}
                            getValues={getFilesVal}
                            setCurrentField={setFilesField}
                            currentField={filesField}
                            ref={fileManagerRef}
                        />
                    </div>
                </Box>
            </FormPickerMaster>
        </HotkeyProvider>
    );
}

export default AddCorrespondence;
