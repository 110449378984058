import React from 'react';
import { makeStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import CSVExportData from '../../../../../common/DataGridToolbar/CSVExportData';
import usePageTitle from '../../../../../common/DataGridToolbar/usePageTitle';
import { TOOLBAR_SELECT } from '../../data/transactionsConfig';
import ToolbarMassRemoval from '../../../../../common/DataGridToolbar/ToolbarMassRemoval';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';

const useStyles = makeStyles(() => ({
    toolbar: {
        display: 'flex'
    }
}));

const messages = {
    massRemoval: { id: 'app.massRemovalToolbar.massRemoval' }
};

function TransactionCustomToolbar({
    exportFiltersText, handleDownloadClick,
    download, dataDownload, headers,
    toolbarSelect, setToolbarSelect,
    selectedRows, setSelectedRows,
    mutate, filterData, user
}) {
    const classes = useStyles();
    const intl = useIntl();

    const handleChangeToolbarSelect = (type) => () => setToolbarSelect(type);

    return (
        <div className={classes.toolbar}>
            <GridToolbarContainer>
                <CSVExportData
                    onClick={handleDownloadClick}
                    download={download}
                    headers={headers}
                    filename={usePageTitle(exportFiltersText)}
                    data={dataDownload}
                />
                {(() => {
                    switch (toolbarSelect) {
                        case TOOLBAR_SELECT.massRemoval:
                            return (
                                <ToolbarMassRemoval
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setOption={setToolbarSelect}
                                    mutate={mutate}
                                    filterData={filterData}
                                    user={user}
                                    defaultOptionId={TOOLBAR_SELECT.noSelect}
                                    deleteRecordFn={(val) => RestRequestsHelper.deleteTransaction(val)}
                                    deleteRecordsFn={(val) => RestRequestsHelper.deleteTransactions(val)}
                                />
                            );
                        case TOOLBAR_SELECT.noSelect:
                        default:
                            return (
                                <Button color='primary' size='small' onClick={handleChangeToolbarSelect(TOOLBAR_SELECT.massRemoval)}>
                                    <DeleteIcon fontSize='small' className={classes.icon} />
                                    {intl.formatMessage(messages.massRemoval)}
                                </Button>
                            );
                    }
                })()}
            </GridToolbarContainer>
        </div>
    );
}

TransactionCustomToolbar.propTypes = {
    exportFiltersText: PropTypes.string.isRequired,
    handleDownloadClick: PropTypes.func.isRequired,
    download: PropTypes.bool.isRequired,
    dataDownload: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    toolbarSelect: PropTypes.func.isRequired,
    setToolbarSelect: PropTypes.func.isRequired,
    selectedRows: PropTypes.array.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
    filterData: PropTypes.object,
    user: PropTypes.object.isRequired
};

TransactionCustomToolbar.defaultProps = {
    filterData: {}
};

export default TransactionCustomToolbar;
