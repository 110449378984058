import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import EditInfrastructure from './EditInfrastructure/EditInfrastructure';
import InfrastructuresColumns from './InfrastructuresColumns/InfrastructuresColumns';

function Infrastructures() {
    const columnsRef = useRef();
    const history = useHistory();
    const [selectedToEdit, setSelectedToEdit] = useState('');

    const query = new URLSearchParams(window.location.search);
    const id = query.get('id');

    useEffect(() => {
        if (id) {
            setSelectedToEdit(id);
        }
    }, [id]);

    const handleDoubleClick = (item) => {
        setSelectedToEdit((item._id));
    };

    const handleCloseEdit = () => {
        setSelectedToEdit('');
        columnsRef.current.handleUpdate();
        if (id) {
            query.delete('id');
            history.replace({ pathname: window.location.pathname, search: query.toString() });
        }
    };

    return (
        <>
            <InfrastructuresColumns ref={columnsRef} onDoubleClick={handleDoubleClick} showProperties />
            <EditInfrastructure
                infrastructureId={selectedToEdit}
                onClose={handleCloseEdit}
                open={selectedToEdit.length > 0}
            />
        </>
    );
}

export default Infrastructures;
