import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { green, red } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import RestRequestsHelper from '../../../lib/restRequestsHelper';

function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;
    return (
        <div
            hidden={value !== index}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const messages = {
    pgConnection: {
        id: 'app.settings.pgConnectionSettings'
    },
    pgConnectionType: {
        id: 'app.settings.pgConnectionType'
    },
    local: {
        id: 'app.settings.localConnection'
    },
    external: {
        id: 'app.settings.externalConnection'
    },
    testDb: {
        id: 'app.settings.testDb'
    },
    testBtn: {
        id: 'app.settings.testBtn'
    },
    saveBtn: {
        id: 'app.settings.saveBtn'
    },
    username: {
        id: 'app.settings.username'
    },
    host: {
        id: 'app.settings.host'
    },
    port: {
        id: 'app.settings.port'
    },
    password: {
        id: 'app.settings.password'
    },
    database: {
        id: 'app.settings.db'
    },
    errorChanging: {
        id: 'app.settings.errorChangingSettings'
    },
    successChanging: {
        id: 'app.settings.successChangingSettings'
    },
    successConnection: {
        id: 'app.settings.successConnection'
    },
    errorConnection: {
        id: 'app.settings.errorConnection'
    },
    checkInvoice: {
        id: 'app.settings.checkInvoice'
    },
    targetDb: {
        id: 'app.settings.targetDb'
    },
    dbSettings: {
        id: 'app.settings.dbSettings'
    },
    ftpSettings: {
        id: 'app.settings.ftp'
    },
    globalSettings: {
        id: 'app.settings.global'
    },
    requestsSettings: {
        id: 'app.settings.requests'
    }
};

const connectionTypes = {
    local: 0,
    external: 1
};

const dbTypes = {
    mongo: 0,
    pg: 1
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700]
        }
    },
    buttonError: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700]
        }
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    main: {
        padding: theme.spacing(2)
    }
}));

function DatabaseSettings() {
    const classes = useStyles();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [dbSettings, setDbSettings] = useState({
        connectionType: connectionTypes.local,
        host: '',
        port: '',
        username: '',
        password: '',
        database: ''

    });
    const [targetDb, setTargetDb] = useState(dbTypes.mongo);

    const history = useHistory();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (!user) {
            history.push('/');
        } else {
            RestRequestsHelper.getDbSettings()
                .then((result) => {
                    if (result && result.dbSettings) {
                        setDbSettings({
                            connectionType: result.connectionType,
                            host: result.dbSettings.host,
                            port: result.dbSettings.port,
                            username: result.dbSettings.user,
                            database: result.dbSettings.database,
                            password: ''
                        });
                    }
                });
            RestRequestsHelper.getTargetDb()
                .then((result) => {
                    if (result) {
                        setTargetDb(result.value);
                    }
                });
        }
    }, []);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [pgError, setError] = useState(false);

    const updateForm = (event) => {
        const { name, value } = event.target;
        setDbSettings((item) => {
            const newStateValue = { ...item };
            newStateValue[name] = value;
            return newStateValue;
        });
    };

    const handleConnectionTypeChange = (event) => {
        const value = Number.parseInt(event.target.value);

        updateForm({ target: { value, name: 'connectionType' } });
    };

    const handleTargetDbChange = (event) => {
        const value = Number.parseInt(event.target.value);
        RestRequestsHelper.setTargetDb(value)
            .then((result) => {
                if (result.error) {
                    enqueueSnackbar(intl.formatMessage({ id: messages.errorChanging.id }), { variant: 'error' });
                } else {
                    setTargetDb(value);
                    enqueueSnackbar(intl.formatMessage({ id: messages.successChanging.id }), { variant: 'success' });
                }
            });
    };

    const formSubmit = (event) => {
        event.preventDefault();
        const data = {
            connectionType: dbSettings.connectionType,
            pgConnection: {
                host: dbSettings.host,
                port: dbSettings.port,
                user: dbSettings.username,
                database: dbSettings.database,
                password: dbSettings.password
            }
        };
        RestRequestsHelper.changeDbSettings(data).then((result) => {
            setDbSettings((oldValue) => {
                const newValue = { ...oldValue };
                newValue.password = '';
                return newValue;
            });
            if (result.status === 'added') {
                enqueueSnackbar(intl.formatMessage({ id: messages.successChanging.id }), { variant: 'success' });
            } else {
                enqueueSnackbar(intl.formatMessage({ id: messages.errorChanging.id }), { variant: 'error' });
            }
        });
    };

    const testDb = () => {
        setLoading(true);
        setSuccess(false);
        setError(false);
        RestRequestsHelper.testDbConnection().then((result) => {
            if (!result.error) {
                setSuccess(true);
                enqueueSnackbar(intl.formatMessage({ id: messages.successConnection.id }), { variant: 'success' });
            } else {
                setError(true);
                enqueueSnackbar(intl.formatMessage({ id: messages.errorConnection.id }), { variant: 'error' });
            }
            setLoading(false);
        });
    };

    const buttonStyle = () => {
        if (success) {
            return classes.buttonSuccess;
        } if (pgError) {
            return classes.buttonError;
        }
        return null;
    };
    return (
        <Paper className={classes.main}>
            <Typography variant='h5' align='left'><FormattedMessage id={messages.pgConnection.id} /></Typography>
            <form onSubmit={formSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <FormControl component='fieldset'>
                            <FormLabel component='legend'><FormattedMessage id={messages.pgConnectionType.id} /></FormLabel>
                            <RadioGroup row name='type' value={dbSettings.connectionType} onChange={handleConnectionTypeChange}>
                                <FormControlLabel
                                    value={connectionTypes.local}
                                    control={<Radio color='secondary' />}
                                    label={<FormattedMessage id={messages.local.id} />}
                                />
                                <FormControlLabel
                                    value={connectionTypes.external}
                                    control={<Radio color='secondary' />}
                                    label={<FormattedMessage id={messages.external.id} />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={dbSettings.connectionType !== connectionTypes.external}
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='host'
                            label={(
                                <FormattedMessage
                                    id={messages.host.id}
                                />
                            )}
                            name='host'
                            autoComplete='host'
                            value={dbSettings.host}
                            onChange={updateForm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={dbSettings.connectionType !== connectionTypes.external}
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='port'
                            label={(
                                <FormattedMessage
                                    id={messages.port.id}
                                />
                            )}
                            name='port'
                            autoComplete='port'
                            value={dbSettings.port}
                            onChange={updateForm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={dbSettings.connectionType !== connectionTypes.external}
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='database'
                            label={(
                                <FormattedMessage
                                    id={messages.database.id}
                                />
                            )}
                            name='database'
                            autoComplete='database'
                            value={dbSettings.database}
                            onChange={updateForm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={dbSettings.connectionType !== connectionTypes.external}
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            id='username'
                            label={(
                                <FormattedMessage
                                    id={messages.username.id}
                                />
                            )}
                            name='username'
                            autoComplete='username'
                            autoFocus
                            value={dbSettings.username}
                            onChange={updateForm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            disabled={dbSettings.connectionType !== connectionTypes.external}
                            variant='outlined'
                            margin='normal'
                            fullWidth
                            type='password'
                            id='password'
                            label={(
                                <FormattedMessage
                                    id={messages.password.id}
                                />
                            )}
                            name='password'
                            autoComplete='password'
                            value={dbSettings.password}
                            onChange={updateForm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button type='submit' variant='contained' color='primary'><FormattedMessage id={messages.saveBtn.id} /></Button>
                    </Grid>
                </Grid>
            </form>
            <Divider />
            <Typography variant='h6'><FormattedMessage id={messages.testDb.id} /></Typography>
            <div className={classes.wrapper}>
                <Button
                    onClick={testDb}
                    disabled={loading}
                    variant='contained'
                    className={buttonStyle()}
                    color='primary'
                >
                    <FormattedMessage id={messages.testBtn.id} />
                </Button>
                {loading
                    && (
                        <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                        />
                    )}
            </div>
            <Divider />
            <Typography variant='h6'><FormattedMessage id={messages.targetDb.id} /></Typography>
            <FormControl component='fieldset'>
                <RadioGroup row name='type' value={targetDb} onChange={handleTargetDbChange}>
                    <FormControlLabel
                        value={dbTypes.mongo}
                        control={<Radio />}
                        label='Mongo'
                    />
                    <FormControlLabel
                        value={dbTypes.pg}
                        control={<Radio />}
                        label='Postgres'
                    />
                </RadioGroup>
            </FormControl>
        </Paper>
    );
}

export default DatabaseSettings;
