import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { green, red } from '@mui/material/colors';

function ContractorDataCell({ data, formatValue, prepareData }) {
    const getColor = (elm) => {
        if ('verified' in elm) {
            return elm?.verified ? green[400] : red[400];
        }
        return '';
    };
    return (
        <div style={{ overflow: 'hidden' }}>
            {prepareData(data)?.map((elm) => (
                <Tooltip placement='top' title={formatValue(elm)}>
                    <p style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign: 'left',
                        color: getColor(elm)
                    }}
                    >
                        {formatValue(elm)}
                    </p>
                </Tooltip>
            ))}
        </div>
    );
}

ContractorDataCell.propTypes = {
    data: PropTypes.array.isRequired,
    formatValue: PropTypes.func,
    prepareData: PropTypes.func
};

ContractorDataCell.defaultProps = {
    formatValue: (val) => val,
    prepareData: (elms) => elms
};

export default ContractorDataCell;
