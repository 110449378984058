import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    DataGrid, GridFooter, GridFooterContainer
} from '@mui/x-data-grid';
import { useIntl, FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import useSWR from 'swr';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CustomDatePicker from '../../../common/CustomDatePicker/CustomDatePicker';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import UniversalCombobox, { comboboxTypes } from '../../../common/Combobox/UniversalCombobox';
import dateFormat from '../../../../constants/dateFormat';
import convertColumnsToVisibilityModel from '../../../../lib/convertColumnsToVisibilityModel';
import getCurrentDate from '../../../../lib/getCurrentDate';
import DetailsDrawer from './DetailsDrawer/DetailsDrawer';
import DetailsType from './shared/DetailsType';
import ComputeRatesDrawer from './ComputeRatesDrawer/ComputeRatesDrawer';

const messages = {
    contractorName: { id: 'app.contractors.contractorName' },
    date: { id: 'app.payoffs.date' },
    main: { id: 'app.payoffs.main' },
    local: { id: 'app.payoffs.local' },
    difference: { id: 'app.payoffs.difference' },
    factor: { id: 'app.payoffs.factor' },
    readingDateFrom: { id: 'app.payoffs.readingDateFrom' },
    readingDateTo: { id: 'app.payoffs.readingDateTo' },
    burdenDateTo: { id: 'app.payoffs.burdenDateTo' },
    burdenDateFrom: { id: 'app.payoffs.burdenDateFrom' },
    dateOkButton: { id: 'app.invoiceForm.OkButton' },
    dateCancelButton: { id: 'app.invoiceForm.CancelButton' },
    dateTodayButton: { id: 'app.invoiceForm.todayButton' },
    burdensSum: { id: 'app.payoffs.burdensSum' },
    incomesSum: { id: 'app.payoffs.incomesSum' },
    mediumType: { id: 'app.payoffs.mediumType' },
    rateSettlement: { id: 'app.payoffs.rateSettlement' },
    compute: { id: 'app.payoffs.compute' }

};

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '80vh'
    }
}));

const usePayoffs = (communities, counterType, {
    readingDateFrom, readingDateTo, burdenDateFrom, burdenDateTo
}) => {
    const { data, error, mutate } = RestRequestsHelper
        .getPayoffs(useSWR, {
            communities: communities ? communities.map((it) => it._id) : [],
            counterType: counterType ? counterType.key : null,
            readingDateFrom,
            readingDateTo,
            burdenDateFrom,
            burdenDateTo
        });
    return {
        payoffs: data !== undefined ? data : [],
        loading: !error && !data,
        mutate
    };
};

function PayoffsFooter({ generatePayoffs }) {
    return (
        <GridFooterContainer>
            <div>
                <Button onClick={generatePayoffs}>
                    <FormattedMessage id={messages.compute.id} />
                </Button>
            </div>
            <GridFooter />
        </GridFooterContainer>
    );
}

PayoffsFooter.propTypes = {
    generatePayoffs: PropTypes.func.isRequired
};

function PayoffsMedia() {
    const classes = useStyles();
    const [communities, setCommunities] = useState([]);
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const [counterType, setCounterType] = useState({ key: 1, value: '' });
    const [mediumOptions, setMediumOptions] = useState([]);
    const [readingDateFrom, setReadingDateFrom] = useState(getCurrentDate);
    const [readingDateTo, setReadingDateTo] = useState(getCurrentDate);
    const [burdenDateFrom, setBurdenDateFrom] = useState(getCurrentDate);
    const [burdenDateTo, setBurdenDateTo] = useState(getCurrentDate);
    const { payoffs, loading } = usePayoffs(communities, counterType, {
        readingDateFrom,
        readingDateTo,
        burdenDateFrom,
        burdenDateTo
    });
    const [settlementByRatio, setSettlementByRatio] = useState([]);
    const [detailsData, setDetailsData] = useState([]);
    const [detailsCommunityName, setDetailsCommunityName] = useState('');
    const [showDetails, setShowDetails] = useState(false);
    const [detailsType, setDetailsType] = useState(DetailsType.readings);
    const [ratesData, setRatesData] = useState([]);
    const [showRates, setShowRates] = useState(false);
    const handleOnCellClick = (params) => {
        if (params.field === 'sumOfCommunity') {
            setDetailsData(params.row.communityDetails);
            setDetailsType(DetailsType.readings);
            setShowDetails(true);
        } else if (params.field === 'sumOfPremises') {
            setDetailsData(params.row.premisesDetails);
            setDetailsType(DetailsType.readings);
            setShowDetails(true);
        } else if (params.field === 'sumOfBurdens') {
            setDetailsData(params.row.burdensDetails);
            setDetailsType(DetailsType.burdens);
            setShowDetails(true);
        }
        setDetailsCommunityName(params.row.community);
    };
    const closeDetails = () => {
        setShowDetails(false);
    };
    const intl = useIntl();

    const toggleCheckbox = (id) => {
        const found = settlementByRatio.find((item) => item.id === id);
        if (found) {
            found.value = !found.value;
        }
    };

    const getRatioChecked = (id) => {
        if (settlementByRatio) {
            const found = settlementByRatio.find((item) => item.id === id);
            if (found) {
                return found.value;
            }
            setSettlementByRatio([
                ...settlementByRatio,
                { id, value: true }
            ]);
        }

        return true;
    };

    const closeRates = () => {
        setShowRates(false);
    };

    const columns = [
        {
            field: 'community',
            headerName: intl.formatMessage({ id: messages.contractorName.id }),
            flex: 2
        },

        {
            field: 'sumOfCommunity',
            headerName: intl.formatMessage({ id: messages.main.id }),
            flex: 1,
            cellClassName: 'clickable'
        },
        {
            field: 'sumOfPremises',
            headerName: intl.formatMessage({ id: messages.local.id }),
            flex: 1,
            cellClassName: 'clickable'
        },
        {
            field: 'usage',
            headerName: intl.formatMessage({ id: messages.difference.id }),
            flex: 1
        },
        {
            field: 'factor',
            headerName: intl.formatMessage({ id: messages.factor.id }),
            flex: 1
        },
        {
            field: 'sumOfBurdens',
            headerName: intl.formatMessage({ id: messages.burdensSum.id }),
            flex: 1,
            renderCell: (params) => params.value,
            cellClassName: 'clickable'
        },
        {
            field: 'sumOfIncomes',
            headerName: intl.formatMessage({ id: messages.incomesSum.id }),
            flex: 1,
            renderCell: (params) => params.value,
            cellClassName: 'clickable'
        },
        {
            field: 'settlementByTheRatio',
            headerName: intl.formatMessage({ id: messages.rateSettlement.id }),
            flex: 1,
            renderCell: (params) => <Checkbox checked={getRatioChecked(params.id)} onChange={() => toggleCheckbox(params.id)} />
        }
    ];

    useEffect(() => {
        if (!user) {
            history.push('/');
        } else {
            RestRequestsHelper.getMediumTypes()
                .then((result) => {
                    const data = result.data.sort((first, second) => first.key > second.key);
                    setMediumOptions(data);
                    if (data.length > 0) {
                        setCounterType(data[0]);
                    }
                });
        }
    }, []);

    const generatePayoffs = async () => {
        try {
            const { data } = await RestRequestsHelper.generatePayoffs(settlementByRatio, counterType, {
                readingDateFrom,
                readingDateTo,
                burdenDateFrom,
                burdenDateTo
            }, communities ? communities.map((it) => it._id) : []);
            setRatesData(data);
        } catch {
            setRatesData({ status: 0 });
        }
        setShowRates(true);
    };
    return (
        <div className={classes.main}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <UniversalCombobox
                        src={comboboxTypes.communities()}
                        label={intl.formatMessage(messages.contractorName)}
                        multiple
                        value={communities}
                        onChange={(val) => setCommunities(val)}
                        showColors
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        disableClearable
                        defaultValue={{ key: 1, value: 'ZIMNA WODA' }}
                        value={counterType}
                        onChange={(event, newValue) => {
                            setCounterType(newValue);
                        }}
                        options={mediumOptions}
                        getOptionLabel={(option) => {
                            if (option) {
                                return option.value;
                            }
                            return '';
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={intl.formatMessage({ id: messages.mediumType.id })}
                                variant='outlined'
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        margin='normal'
                        name='dateFrom'
                        id='dateFrom'
                        toolbarTitle='Od'
                        label={intl.formatMessage({ id: messages.readingDateFrom.id })}
                        showTodayButton
                        value={readingDateFrom}
                        onChange={(date) => setReadingDateFrom(date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        margin='normal'
                        name='dateFrom'
                        id='dateFrom'
                        toolbarTitle='Od'
                        label={intl.formatMessage({ id: messages.readingDateTo.id })}
                        showTodayButton
                        value={readingDateTo}
                        onChange={(date) => setReadingDateTo(date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        margin='normal'
                        name='dateFrom'
                        id='dateFrom'
                        toolbarTitle='Od'
                        label={intl.formatMessage({ id: messages.burdenDateFrom.id })}
                        showTodayButton
                        value={burdenDateFrom}
                        onChange={(date) => setBurdenDateFrom(date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <CustomDatePicker
                        disableToolbar
                        variant='outlined'
                        inputFormat={dateFormat.format}
                        margin='normal'
                        name='dateFrom'
                        id='dateFrom'
                        toolbarTitle='Od'
                        label={intl.formatMessage({ id: messages.burdenDateTo.id })}
                        showTodayButton
                        value={burdenDateTo}
                        onChange={(date) => setBurdenDateTo(date)}
                        okLabel={intl.formatMessage({ id: messages.dateOkButton.id })}
                        cancelLabel={intl.formatMessage({ id: messages.dateCancelButton.id })}
                        todayLabel={intl.formatMessage({ id: messages.dateTodayButton.id })}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                </Grid>
            </Grid>
            <DataGrid
                sx={{
                    m: 0.5,
                    '& .clickable': { cursor: 'pointer' }
                }}
                rows={payoffs}
                columns={columns}
                loading={loading}
                onCellClick={handleOnCellClick}
                components={{
                    Footer: PayoffsFooter
                }}
                componentsProps={{
                    footer: { generatePayoffs }
                }}
                columnVisibilityModel={convertColumnsToVisibilityModel(columns)}
            />

            <DetailsDrawer
                show={showDetails}
                detailsData={detailsData}
                onClose={closeDetails}
                type={detailsType}
                exportFileName={`${detailsCommunityName}-${counterType.value}`}
            />
            <ComputeRatesDrawer
                onClose={closeRates}
                show={showRates}
                exportFileName={`Zużycia-${dayjs().toISOString()}`}
                data={ratesData}
            />
        </div>

    );
}

export default PayoffsMedia;
