import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { google, ics } from 'calendar-link';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { renderCellExpand } from '../../../../../common/DataGridCells/GridCellExpand';
import generateInitials from '../../../../../../lib/generateInitials';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import AdditionalOptionsColumn from '../../../../../common/AdditionalOptionsColumn/AdditionalOptionsColumn';

const messages = {
    number: { id: 'app.vindication.number' },
    hierarchy: { id: 'app.vindication.hierarchy' },
    content: { id: 'app.vindication.content' },
    concerns: { id: 'app.vindication.concerns' },
    category: { id: 'app.vindication.category' },
    subcategory: { id: 'app.vindication.subcategory' },
    industry: { id: 'app.vindication.industry' },
    leader: { id: 'app.vindication.leader' },
    producers: { id: 'app.vindication.producers' },
    lastChargeDate: { id: 'app.vindication.lastChargeDate' },
    addToCalendar: { id: 'app.vindication.addToCalendar' },
    share: { id: 'app.vindication.share' },
    parentTaskTooltip: { id: 'app.vindication.parentTaskTooltip' },
    subtaskTooltip: { id: 'app.vindication.subtaskTooltip' },
    debtor: { id: 'app.vindication.debtor' },
    delete: { id: 'app.vindication.delete' },
    confirmDeleteTitle: { id: 'app.vindication.confirmDeleteTitle' },
    confirmDeleteText: { id: 'app.vindication.confirmDeleteText' },
    deleteSuccess: { id: 'app.vindication.deleteSuccess' },
    copy: { id: 'app.vindication.copy' },
    copySuccess: { id: 'app.vindication.copySuccess' }
};

const useStyles = makeStyles((theme) => ({
    producersList: {
        paddingLeft: theme.spacing(4),
        margin: '7px 0'
    }
}));

function createProducersList(producersArr, className) {
    const producersListItems = producersArr.map((producer) => <li>{producer}</li>);
    return <ul className={className}>{producersListItems}</ul>;
}

const AdditionalOptionsCell = React.memo(({ params, handleUpdate }) => {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const [err, setErr] = useState(false);

    const shareVindication = (resRow) => {
        window.navigator
            .share({
                title: `${resRow?.concerns} - ${resRow?.number} - ${resRow?.name}`,
                text: resRow.content
            });
    };

    const copyVindication = async (resRow) => {
        const { data } = await RestRequestsHelper.copyVindication(resRow._id ?? resRow.id);
        if (data.success) {
            enqueueSnackbar(intl.formatMessage(messages.copySuccess), { variant: 'success' });
            handleUpdate();
        }
    };

    const checkIfWebShareApiSupported = () => 'share' in window.navigator;
    const defaultPolicyFn = () => true;

    const additionalOptions = [
        {
            title: intl.formatMessage(messages.addToCalendar, { name: 'Apple' }),
            type: 'calendar',
            fn: ics,
            policyFn: defaultPolicyFn
        },
        {
            title: intl.formatMessage(messages.addToCalendar, { name: 'Google' }),
            type: 'calendar',
            fn: google,
            policyFn: defaultPolicyFn
        },
        {
            title: intl.formatMessage(messages.share),
            type: 'share',
            fn: shareVindication,
            policyFn: checkIfWebShareApiSupported
        },
        {
            title: intl.formatMessage(messages.copy),
            type: 'copy',
            fn: copyVindication,
            policyFn: defaultPolicyFn
        }
    ];
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleEventDate = (lastChargeDate) => {
        let date;
        if (lastChargeDate) {
            date = new Date(lastChargeDate);
            date.setHours(date.getHours() + 1);
        } else {
            date = new Date();
        }
        return date;
    };
    const onClick = (exportFn, type) => {
        try {
            setAnchorEl(null);
            const { api } = params;
            const row = api.getSelectedRows();
            const res = [];
            row.forEach((val, key) => {
                res.push({ region: key, value: val });
            });
            const resRow = res[0].value;
            if (type === 'calendar') {
                const event = {
                    title: resRow.name?.length ? `${resRow.number} - ${resRow.name}` : resRow.number.toString(),
                    description: resRow.content,
                    start: handleEventDate(resRow.last_charge_date),
                    allDay: true,
                    location: resRow.concerns ? resRow.concerns.join(', ') : ''
                };
                const exported = exportFn(event);
                if (exported.startsWith('http')) {
                    window.open(exported, '_blank');
                } else {
                    try {
                        const aElm = document.createElement('a');
                        aElm.setAttribute('download', event.title);
                        aElm.setAttribute('href', exported);
                        aElm.click();
                    } catch {
                        window.open(exported, '_blank');
                    }
                }
            } else {
                exportFn(resRow);
            }
        } catch {
            setErr(true);
        }
    };
    if (err) {
        return null;
    }
    return (
        <AdditionalOptionsColumn
            onIconClick={handleClick}
            onItemClick={onClick}
            options={additionalOptions}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
        />
    );
});

const useColumns = (handleUpdate) => {
    const intl = useIntl();
    const styles = useStyles();
    return [
        {
            field: 'number',
            headerName: intl.formatMessage(messages.number),
            width: 180,
            headerAlign: 'center',
            csvExport: true,
            renderCell: ({ value }) => renderCellExpand({ value, showPointer: true })
        },
        {
            field: 'concerns',
            headerName: intl.formatMessage(messages.concerns),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            csvExport: true,
            renderCell: ({ row }) => renderCellExpand({ data: row.concerns })
        },
        {
            field: 'debtor',
            headerName: intl.formatMessage(messages.debtor),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            csvExport: true,
            renderCell: ({ row }) => renderCellExpand({ data: row?.debtor })
        },
        {
            field: 'content',
            headerName: intl.formatMessage(messages.content),
            width: 200,
            flex: 2,
            headerAlign: 'center',
            csvExport: true,
            renderCell: renderCellExpand
        },
        {
            field: 'leader',
            headerName: intl.formatMessage(messages.leader),
            flex: 0.5,
            headerAlign: 'center',
            csvExport: true,
            renderCell: ({ value: { name, color } }) => renderCellExpand({ value: name }, color, (val) => generateInitials(val))
        },
        {
            field: 'lastChargeDate',
            headerName: intl.formatMessage(messages.lastChargeDate),
            width: 140,
            headerAlign: 'center',
            csvExport: true,
            renderCell: renderCellExpand
        },
        {
            field: 'category',
            headerName: intl.formatMessage(messages.category),
            minWidth: 120,
            flex: 0.5,
            headerAlign: 'center',
            csvExport: true,
            renderCell: renderCellExpand
        },
        {
            field: 'producers',
            headerName: intl.formatMessage(messages.producers),
            valueGetter: (params) => params.value.length,
            width: 80,
            headerAlign: 'center',
            sortable: false,
            csvExport: true,
            renderCell: (value) => renderCellExpand({
                value: value.formattedValue.toString(),
                alt: createProducersList(value.row.producers, styles.producersList),
                showOnlyAlt: true
            })
        },
        {
            field: 'additionalOptions',
            headerName: ' ',
            width: 60,
            headerAlign: 'center',
            sortable: false,
            csvExport: false,
            renderCell: (params) => <AdditionalOptionsCell params={params} handleUpdate={handleUpdate} />
        }
    ];
};

AdditionalOptionsCell.propTypes = {
    params: PropTypes.object.isRequired,
    handleUpdate: PropTypes.func.isRequired
};

export default useColumns;
