import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import userRoles from '../../../constants/UserRoles';
import routeTitles from '../../../config/routeTitles';

// Pre-generate a 'path' oriented map in order to increase efficiency
// so that rendering algorithm takes time complexity of O(n)
const generatePermissionMap = (userRole) => {
    const result = new Map();
    const generatePermissionListAux = (node) => {
        // If no role was set - allow users with all roles and guest (null)
        const newRoles = node.roles || [...Object.values(userRoles), null];
        if (node.path) {
            result.set(node.path, newRoles);
        }
        // Traverse tree recursively only if parent is accessible by this user
        if (node.children && newRoles.includes(userRole)) {
            node.children.forEach((child) => {
                generatePermissionListAux(child);
            });
        }
    };
    routeTitles.forEach(generatePermissionListAux);
    return result;
};

function RoutesPermissions({ children, userRole }) {
    const permissionMap = useMemo(() => generatePermissionMap(userRole), [userRole]);

    const renderChildren = () => (
        children.filter((child) => {
            /* eslint-disable react/prop-types */
            const props = child?.props;
            if (props && props.path && props.component) {
                return permissionMap.has(props.path) && permissionMap.get(props.path).includes(userRole);
            }
            return true;
            /* eslint-enable */
        })
    );

    return (
        <>
            {renderChildren()}
        </>
    );
}

RoutesPermissions.propTypes = {
    children: PropTypes.any,
    userRole: PropTypes.any
};

RoutesPermissions.defaultProps = {
    children: [],
    userRole: null
};

export default RoutesPermissions;
