import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import dateFormat from '../../../../../constants/dateFormat';

const messages = {
    number: { id: 'app.tasks.number' },
    name: { id: 'app.tasks.name' },
    content: { id: 'app.tasks.content' },
    concerns: { id: 'app.tasks.concerns' },
    status: { id: 'app.tasks.status' },
    dueDate: { id: 'app.tasks.dueDate' },
    date: { id: 'app.tasks.date' },
    prevStatus: { id: 'app.tasks.prevStatus' },
    nextStatus: { id: 'app.tasks.nextStatus' },
    historyAuthor: { id: 'app.tasks.historyAuthor' }
};

export default function useActionsColumns() {
    const intl = useIntl();
    return [
        {
            field: 'date',
            type: 'date',
            headerName: intl.formatMessage(messages.date),
            width: 180,
            valueGetter: ({ value }) => new Date(value),
            valueFormatter: (params) => dayjs(params.value).format(dateFormat.fullDate)
        },
        {
            field: 'previous_state',
            headerName: intl.formatMessage(messages.prevStatus),
            valueGetter: (params) => params.value?.$name,
            width: 180,
            flex: 1
        },
        {
            field: 'next_state',
            headerName: intl.formatMessage(messages.nextStatus),
            valueGetter: (params) => params.value?.$name,
            width: 180,
            flex: 1
        },
        {
            field: 'user',
            headerName: intl.formatMessage(messages.historyAuthor),
            valueGetter: (params) => params.value?.$name,
            width: 180
        }
    ];
}
