const formPickerConfig = {
    pickerLabel: 'picker',
    bus: {
        keydown: 'formpicker-keydown',
        selection: 'formpicker-selection',
        init: 'formpicker-init',
        isEnabled: 'formpicker-isEnabled',
        enable: 'formpicker-enable',
        click: 'formpicker-click',
        ready: 'formpicker-ready',
        pickerChange: 'form-picker-pickerChange'
    }
};
export default formPickerConfig;
