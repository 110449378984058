import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        borderRadius: '10px',
        maxHeight: '200px',
        overflowY: 'auto',
        position: 'relative'
    },
    item: {
        padding: theme.spacing(0.75),
        margin: theme.spacing(0.5)
    },
    label: {
        display: 'inline-block',
        position: 'relative',
        bottom: '40px',
        left: 'calc(100% - 20px)',
        transform: 'translate(-100%, 0)',
        padding: '5px',
        borderRadius: '10px'
    },
    add: {
        maxWidth: 32,
        '& .MuiChip-label': {
            paddingLeft: 0
        }
    },
    icon: {
        width: '15px',
        height: '15px',
        filter: (props) => (props.isDark ? '' : 'invert(1)')
    },
    filename: {
        verticalAlign: 'middle'
    }
}));

const messages = {
    chooseFiles: {
        id: 'app.form.upload'
    }
};

const fileTypeIcons = {
    pdf: '/icons/pdf-icon.png',
    xml: '/icons/xml-icon.svg',
    rtf: '/icons/rtf-icon.svg',
    xls: '/icons/xls-icon.png',
    numbers: '/icons/numbers-icon.png',
    file: '/icons/file-icon.svg'
};
function FilesList({
    value, mutable, onChange, accept
}) {
    const isDark = useSelector((state) => state.theme);
    const classes = useStyles({ isDark });
    const intl = useIntl();
    const input = useRef(null);

    const getFileType = (type, name) => {
        if (type?.match('pdf')) {
            return fileTypeIcons.pdf;
        }
        if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || type === 'application/vnd.ms-excel') {
            return fileTypeIcons.xls;
        }
        if (type === 'application/vnd.apple.numbers' || name?.match(/\.numbers$/)) {
            return fileTypeIcons.numbers;
        }
        if (type?.match('xml')) {
            return fileTypeIcons.xml;
        }
        if (type?.match('rtf')) {
            return fileTypeIcons.rtf;
        }
        if (type?.match('xls') || type?.match('xlsx')) {
            return fileTypeIcons.xls;
        }
        return fileTypeIcons.file;
    };

    const handleDelete = (index) => {
        const newValue = [...value];
        newValue.splice(index, 1);
        onChange(newValue);
    };

    const handleChange = (event) => {
        onChange([...value, ...event.target.files]);
        input.current.value = [];
    };

    const listFiles = [...value].map((file, index) => (
        <Chip
            key={index}
            className={classes.item}
            icon={(<img src={getFileType(file.type)} alt='' className={classes.icon} />)}
            label={file.name}
            onDelete={mutable && (() => handleDelete(index))}
        />
    ));

    return (
        <div>
            <div className={classes.root}>
                {listFiles}
                {mutable ? (
                    <>
                        <Chip
                            icon={(<AddIcon />)}
                            className={classes.item}
                            classes={{ root: value.length > 0 && classes.add }}
                            label={value.length === 0 && intl.formatMessage(messages.chooseFiles)}
                            onClick={() => input.current.click()}
                            clickable
                        />
                        <input
                            ref={input}
                            type='file'
                            hidden
                            multiple
                            accept={accept.join(',')}
                            onChange={handleChange}
                        />
                    </>
                ) : null}
            </div>
            <div className={classes.label}>
                {value.length}
            </div>
        </div>
    );
}

FilesList.propTypes = {
    value: PropTypes.array.isRequired,
    accept: PropTypes.array,
    mutable: PropTypes.bool,
    onChange: PropTypes.func
};

FilesList.defaultProps = {
    mutable: false,
    accept: [],
    onChange: () => {}
};

export default FilesList;
