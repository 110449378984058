import dayjs from 'dayjs';
import { paginationDefaultValue } from '../../../constants/pagination';
import getCurrentDate from '../../../lib/getCurrentDate';
import dateFormat from '../../../constants/dateFormat';

export const initialFiltersState = {
    number: '',
    journal: null,
    contractor: [],
    concerns: '',
    startDate: dayjs().subtract(1, 'year').format(dateFormat.reversedFormat),
    endDate: getCurrentDate(),
    pageSize: paginationDefaultValue,
    page: 0
};
export function filtersReducer(state, action) {
    switch (action.type) {
        case 'number':
            return { ...state, number: action.value, page: 0 };
        case 'journal':
            return { ...state, journal: action.value, page: 0 };
        case 'contractor':
            return { ...state, contractor: action.value, page: 0 };
        case 'concerns':
            return { ...state, concerns: action.value, page: 0 };
        case 'startDate':
            return { ...state, startDate: action.value, page: 0 };
        case 'endDate':
            return { ...state, endDate: action.value, page: 0 };
        case 'rowsCount':
            return { ...state, rowsCount: action.value };
        case 'paginationModel':
            return { ...state, pageSize: action.value.pageSize, page: action.value.page };
        default:
            return { ...state };
    }
}

