import dayjs from 'dayjs';

export const defaultFilter = (query) => ({
    documentNumber: {
        value: '',
        filter: false
    },
    buyer: {
        value: [],
        filter: false
    },
    seller: {
        value: query.seller || [],
        filter: Boolean(query.seller)
    },
    dateFilter: {
        value: 'saleDate',
        filter: true
    },
    dateFrom: {
        value: query.dateFrom || dayjs().startOf('year').format('YYYY/MM/DD'),
        filter: true
    },
    dateTo: {
        value: query.dateTo || dayjs().endOf('year').format('YYYY/MM/DD'),
        filter: true
    },
    status: {
        value: [],
        filter: false
    },
    group: {
        value: [],
        filter: false
    },
    groupPositions: {
        value: query.groupPositions ?? true,
        filter: query.groupPositions ?? true
    },
    amountMinimum: {
        value: 0,
        filter: false
    },
    amountMaximum: {
        value: 0,
        filter: false
    },
    invoiceType: {
        value: query.invoiceType?.length ? query.invoiceType : [],
        filter: Boolean(query.invoiceType?.length)
    },
    position: {
        value: query.position?.length ? query.position : [],
        filter: Boolean(query.position?.length)
    },
    premise: {
        value: null,
        filter: false
    }
});

export const TOOLBAR_SELECT = {
    noSelect: 0,
    massRemoval: 1,
    positionsUngrouped: 2
};
