import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Chip, Dialog, AppBar, Typography, Toolbar, IconButton, Box, CircularProgress, Paper, Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import RestRequestsHelper from '../../../lib/restRequestsHelper';
import userRoles from '../../../constants/UserRoles';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import dateFormat from '../../../constants/dateFormat';
import EditArticle from './EditArticle';
import './styles.css';

const messages = {
    help: { id: 'app.articles.help' },
    articles: { id: 'app.articles.articles' },
    confirmRemoveTitle: { id: 'app.article.confirmRemoveTitle' },
    confirmRemoveText: { id: 'app.article.confirmRemoveText' },
    empty: { id: 'app.article.empty' },
    createdAt: { id: 'app.article.createdAt' },
    updatedAt: { id: 'app.article.updatedAt' }
};

function Articles() {
    const intl = useIntl();

    const locale = useSelector((state) => state.locale);
    const user = useSelector((state) => state.user);

    const [path, setPath] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [articles, setArticles] = useState(null);

    const [isRemoveDialog, setIsRemoveDialog] = useState(false);
    const [toRemoveId, setToRemoveId] = useState(null);

    const [isEditOpen, setIsEditOpen] = useState(false);
    const [editData, setEditData] = useState(null);

    const fetchData = async () => {
        setIsLoading(true);
        const { data } = await RestRequestsHelper.getArticles(window.location.pathname, locale);
        setArticles(data);
        setIsLoading(false);
    };

    const handleModalOpen = () => {
        setIsEditOpen(false);
        setEditData(null);
        setPath(window.location.pathname);
        setArticles(null);
        setIsOpen(true);
        fetchData();
    };

    const handleModalClose = () => {
        setIsOpen(false);
    };

    const handleRemoveDialog = (id) => {
        setToRemoveId(id);
        setIsRemoveDialog(true);
    };

    const handleRemove = async () => {
        await RestRequestsHelper.deleteArticle(toRemoveId);
        fetchData();
    };

    const handleOpenEdit = (data) => {
        setEditData(data);
        setIsEditOpen(true);
    };

    const handleCloseEdit = () => {
        setIsEditOpen(false);
        setEditData(null);
    };

    const verifyEditAccess = (creator) => user?.role === userRoles.admin || user?.sub === creator;

    return (
        <div>
            <ConfirmDialog
                isOpen={isRemoveDialog}
                handleClose={setIsRemoveDialog}
                handleRemove={handleRemove}
                dialogTitle={messages.confirmRemoveTitle.id}
                dialogText={messages.confirmRemoveText.id}
            />
            <Dialog
                fullWidth
                maxWidth='xl'
                open={isOpen}
                onClose={handleModalClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            color='inherit'
                            onClick={handleModalClose}
                            aria-label='close'
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                            {intl.formatMessage(messages.articles)}
                            <Chip sx={{ ml: 1 }} label={`${locale.toUpperCase()} - ${path}`} />
                        </Typography>
                        {[userRoles.user, userRoles.admin].includes(user?.role) && (
                            <IconButton size='large' disabled={isEditOpen}>
                                <AddCircleOutlineIcon onClick={() => handleOpenEdit(null)} />
                            </IconButton>
                        )}

                    </Toolbar>
                </AppBar>
                <Box sx={{
                    display: 'flex', justifyContent: 'center', minHeight: '50vh', mt: 5, overflowY: 'scroll'
                }}
                >
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <Box sx={{
                            width: '100%', display: 'flex', justifyContent: 'center'
                        }}
                        >
                            {isEditOpen ? (
                                <EditArticle data={editData} onClose={handleCloseEdit} refetch={fetchData} path={path} language={locale} />
                            ) : (
                                <Box sx={{
                                    width: '95%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3
                                }}
                                >
                                    {articles?.data.map((elm, i) => (
                                        <Paper key={i} sx={{ width: '100%', padding: '10px' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box>
                                                    <Typography variant='h5'>{elm?.title}</Typography>
                                                    <Box sx={{ display: 'flex', gap: 3 }}>
                                                        <Typography sx={{
                                                            fontSize: 12, color: '#9e9e9e', display: 'flex', gap: 1, alignItems: 'center'
                                                        }}
                                                        >
                                                            {intl.formatMessage(
                                                                messages.createdAt,
                                                                { date: dayjs(elm?.createdAt).format(dateFormat.fullDate) }
                                                            )}
                                                            <Chip
                                                                label={elm?.creator?.username}
                                                                size='small'
                                                                sx={{ color: elm?.creator?.color, fontSize: 12 }}
                                                            />
                                                        </Typography>
                                                        <Typography sx={{
                                                            fontSize: 12, color: '#9e9e9e', display: 'flex', gap: 1, alignItems: 'center'
                                                        }}
                                                        >
                                                            {intl.formatMessage(
                                                                messages.updatedAt,
                                                                { date: dayjs(elm?.updatedAt).format(dateFormat.fullDate) }
                                                            )}
                                                            <Chip
                                                                label={elm?.updatedBy?.username}
                                                                size='small'
                                                                sx={{ color: elm?.updatedBy?.color, fontSize: 12 }}
                                                            />
                                                        </Typography>
                                                    </Box>

                                                </Box>
                                                {verifyEditAccess(elm?.creator?._id) && (
                                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                                        <IconButton onClick={() => handleOpenEdit(elm)} sx={{ margin: 'auto' }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleRemoveDialog(elm._id)} sx={{ margin: 'auto' }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                )}

                                            </Box>
                                            <Divider sx={{ mt: 1, mb: 1 }} />
                                            {/* eslint-disable-next-line react/no-danger */}
                                            <div className='ck-content' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(elm?.content) }} />

                                        </Paper>
                                    ))}
                                    {articles?.data.length === 0 && (
                                        <Typography>
                                            {intl.formatMessage(messages.empty)}
                                        </Typography>
                                    )}
                                </Box>
                            )}

                        </Box>
                    )}
                </Box>

            </Dialog>

            <Chip
                label={intl.formatMessage(messages.help)}
                sx={{ cursor: 'pointer' }}
                onClick={handleModalOpen}
            />
        </div>
    );
}
export default Articles;
