import dayjs from 'dayjs';
import defaultRemarkType from '../../../../../constants/remarkDefaults';
import formatUTCDate from '../../../../../lib/formatUTCDate';

export const defaultRemarks = (user) => ({
    value: '',
    user,
    updatedAt: dayjs(),
    type: defaultRemarkType,
    files: []
});
export const defaultReminder = () => ({
    entity: null, date: dayjs().add(1, 'day'), value: null, _new: true
});

export const defaultUser = (user) => ({
    $name: user.name,
    firstName: user.firstName,
    lastName: user.lastName,
    _id: user.sub
});

const defaultTaskFormValues = (user, edit) => (edit
    // Loads ready values in order to prepare them for edition
    ? ({
        user,
        name: edit.name,
        concerns: edit.concerns,
        parentTask: edit.parent,
        phone: edit.data.phone,
        applicantData: edit.reportingContractor || edit.applicantDataName,
        receiver: edit.received,
        leader: edit.leader,
        industry: edit.industry,
        category: edit.category,
        subcategory: edit.subcategory,
        content: edit.content,
        remarks: edit.remarksData,
        reminders: edit.remindersData
            ? edit.remindersData.map((reminder) => ({ ...reminder, _isSent: reminder?.date && dayjs().isAfter(dayjs(reminder.date)) }))
            : edit.remindersData,
        producers: edit.producers,
        status: edit.status,
        dueDate: formatUTCDate(edit.due_date),
        files: edit.files,
        fileNumber: 0,
        inspection: edit.inspection
    })
    : ({
        user,
        name: '',
        concerns: [],
        parentTask: null,
        receiver: defaultUser(user),
        remarks: [],
        reminders: [],
        dueDate: null,
        industry: null,
        category: null,
        subcategory: null,
        content: '',
        producers: null,
        phone: '',
        applicantData: null,
        files: [],
        fileNumber: 0,
        inspection: null
    })
);

export default defaultTaskFormValues;
