import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

const messages = {
    infrastructureMode: 'app.infrastructures.mode'
};
const useStyles = makeStyles(() => ({
    input: {
        width: '100%'
    }
}));

function IngredientsModeInput({
    options,
    name,
    control,
    readOnly,
    required
}) {
    const intl = useIntl();
    const classes = useStyles();
    return (
        <Controller
            onChange={([, obj]) => obj.key}
            defaultValue={null}
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    className={classes.input}
                    value={value}
                    options={options || []}
                    getOptionLabel={(option) => {
                        if (options) {
                            const type = options.find((it) => it.key === Number(option));

                            if (type) {
                                return type.value;
                            }
                            return option.value;
                        }
                        return '';
                    }}
                    disabled={readOnly}
                    isOptionEqualToValue={(option, valueData) => option?.key === valueData}
                    renderInput={(params) => (
                        <TextField
                            className={classes.input}
                            {...params}
                            fullWidth
                            label={intl.formatMessage({ id: messages.infrastructureMode })}
                            required={required}
                        />
                    )}
                    onChange={(event, dataObj) => onChange(dataObj?.key)}
                    onInputChange={(event, text) => text !== '' && event && onChange(null)}
                />
            )}
        />
    );
}

IngredientsModeInput.propTypes = {
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool
};

IngredientsModeInput.defaultProps = {
    readOnly: false,
    required: false
};

export default IngredientsModeInput;
