const initialTheme = () => {
    const userTheme = localStorage.getItem('darkTheme') || false;
    const userThemeBool = userTheme === 'true';
    return userThemeBool;
};
// eslint-disable-next-line import/prefer-default-export
export const theme = (state = initialTheme(), action = {}) => {
    switch (action.type) {
        case 'DARK_MODE':
            return !state;
        default:
            return state;
    }
};
