import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import MarkdownPreview from '@uiw/react-markdown-preview';

const messages = {
    aboutApp: { id: 'app.about.title' }
};

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '80vh'
    },
    md: {
        padding: 15,
        maxWidth: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
        border: '1px solid #0d1117',
        borderRadius: '5px',
        marginTop: 15,
        marginBottom: 15,
        '& table': {
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    }
}));

function About() {
    const intl = useIntl();
    const classes = useStyles();

    const files = ['ABOUT.md', 'STATUS.md', 'CHANGELOG.md'];
    const [markdowns, setMarkdowns] = useState([]);

    useEffect(() => {
        const mdData = [];
        files.forEach(async (file, index) => {
            const res = await fetch(`/${file}`);
            const text = await res.text();
            mdData[index] = text;
            if (index === files.length - 1) {
                setMarkdowns(mdData);
            }
        });
    }, []);

    return (
        <div className={classes.main}>
            <h1>{intl.formatMessage({ id: messages.aboutApp.id })}</h1>
            <div>
                {markdowns.map((markdown, index) => (
                    <MarkdownPreview key={index} id={index} className={classes.md} source={markdown} />
                ))}
            </div>
        </div>
    );
}
export default About;
