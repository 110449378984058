import { DateTimePicker } from '@mui/x-date-pickers';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useForm, Controller } from 'react-hook-form';
import 'dayjs/locale/pl';
import 'dayjs/locale/en-gb';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import restRequestsHelper from '../../../../lib/restRequestsHelper';
import UniversalCombobox, { comboboxTypes } from '../../../common/Combobox/UniversalCombobox';
import FormPickerControl from '../../../common/FormPicker/FormPickerControl';
import FormPickerElement from '../../../common/FormPicker/FormPickerElement';
import { KIND_NAME } from '../../../../constants/correspondenceType';
import dateFormat from '../../../../constants/dateFormat';
import ListItems from '../../../common/ListItems/ListItems';
import { defaultRemarks } from '../../../common/TaskForm/shared/data/defaultTaskFormValues';
import { defaultReminder } from '../../../common/InspectionForm/defaultInspectionFormValues';
import FormHeader from '../../../common/FormHeader/FormHeader';
import CustomDatePicker from '../../../common/CustomDatePicker/CustomDatePicker';
import getCurrentDate from '../../../../lib/getCurrentDate';
import formatUTCDate from '../../../../lib/formatUTCDate';
import AddNewThread from './AddNewThread';

const messages = {
    header: { id: 'app.correspondence.add.header' },
    editHeader: { id: 'app.correspondence.edit.header' },
    type: { id: 'app.correspondence.add.type' },
    incomming: { id: 'app.correspondence.add.incomming' },
    outcomming: { id: 'app.correspondence.add.outcomming' },
    journal: { id: 'app.correspondence.add.journal' },
    fromWho: { id: 'app.correspondence.add.fromWho' },
    toWhom: { id: 'app.correspondence.add.toWhom' },
    signature: { id: 'app.correspondence.add.signature' },
    address: { id: 'app.correspondence.add.address' },
    addressEmail: { id: 'app.correspondence.add.addressEmail' },
    leader: { id: 'app.correspondence.add.leader' },
    concerns: { id: 'app.correspondence.add.concerns' },
    date: { id: 'app.correspondence.add.date' },
    dateOfOutcome: { id: 'app.correspondence.add.dateOfOutcome' },
    dateOfIncome: { id: 'app.correspondence.add.dateOfIncome' },
    description: { id: 'app.correspondence.add.description' },
    remarks: { id: 'app.correspondence.add.remarks' },
    confirm: { id: 'app.correspondence.add.confirm' },
    cancel: { id: 'app.correspondence.add.cancel' },
    today: { id: 'app.correspondence.add.today' },
    add: { id: 'app.correspondence.add.add' },
    save: { id: 'app.article.save' },
    clear: { id: 'app.correspondence.add.clear' },
    setName: { id: 'app.addInvoice.setName' },
    thread: { id: 'app.correspondence.thread' },
    author: { id: 'app.tasks.author' },
    updatedAt: { id: 'app.tasks.updatedAt' },
    reminder: { id: 'app.addInspection.reminder' },
    reminderEntity: { id: 'app.addInspection.reminderEntity' },
    reminderContact: { id: 'app.addInspection.reminderContact' },
    reminderNow: { id: 'app.inspections.reminderNow' },
    reminderNowHint: { id: 'app.inspections.reminderNowHint' },
    dateOkButton: { id: 'app.addInspection.okButton' },
    dateCancelButton: { id: 'app.addInspection.cancelButton' },
    dateTodayButton: { id: 'app.addInspection.todayButton' },
    dateClearButton: { id: 'app.addInspection.clearButton' }
};

const defaultCorrespondence = (editData, edit = false) => {
    const reminders = edit && (editData.remindersData || editData.reminders);
    return edit ? {
        ...editData,
        dateInOut: formatUTCDate(editData.dateInOut),
        date: formatUTCDate(editData.date),
        reminders: reminders?.map?.((reminder) => ({
            ...reminder, _isSent: reminder?.date && dayjs().isAfter(dayjs(reminder.date))
        })) ?? []
    } : {
        kind: KIND_NAME.incomming,
        type: '',
        journal: '',
        fromTo: '',
        foreignSignature: '',
        address: '',
        leader: '',
        concerns: '',
        reminders: [],
        date: getCurrentDate(),
        dateInOut: getCurrentDate(),
        comments: '',
        description: '',
        remarks: [],
        setNames: ['1'],
        threadId: null
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        padding: theme.spacing(3),
        '& .MuiGrid-item': {
            padding: '4px'
        }
    },
    header: {
        marginBottom: theme.spacing(4)
    },
    select: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    selectItem: {
        marginRight: theme.spacing(0.5)
    },
    center: {
        top: '50%',
        transform: 'translate(0, -50%)'
    },
    accordionSummary: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    centerAlign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttons: {
        bottom: theme.spacing(1),
        zIndex: 1,
        position: 'sticky'
    }
}));

function CorrespondenceForm({
    edit,
    editData,
    onSubmit,
    onDelete,
    onClear,
    isFileNameEmpty
}) {
    const intl = useIntl();
    const styles = useStyles();
    const {
        control, watch,
        reset, getValues, setValue,
        handleSubmit
    } = useForm({ defaultValues: defaultCorrespondence(editData, edit) });
    const formData = watch();
    const [types, setTypes] = useState([]);
    const [openNewThreadDialog, setOpenNewThreadDialog] = useState(false);
    const [leaders, setLeaders] = useState([]);
    const userData = useSelector((state) => state.user);
    const locale = useSelector((state) => state.locale);
    const watchReminders = watch('reminders');

    dayjs.locale(locale);

    useEffect(() => {
        restRequestsHelper.getCorrespondenceOptions()
            .then((result) => {
                setTypes(result.types);
                setLeaders(result.users);
                if (userData && !edit) {
                    const selected = result.users.find((item) => item.id === userData.employeeId);
                    setValue('leader', selected);
                }
            });
    }, []);

    const clearData = () => {
        reset(defaultCorrespondence());
        onClear();
    };

    const isFormInvalid = () => (
        isFileNameEmpty
        + !formData.type
        + !formData.journal
        + !formData.fromTo
        + !formData.leader
    );

    const submitForm = (data) => {
        const newData = { ...data };
        newData.address = newData.address?.$name || newData.address;
        newData.remarks = newData.remarks.map(({
            files, user, type, ...rest
        }) => ({ user: user?._id, type: type?.key, ...rest }));

        onSubmit(newData)
            .then((resp) => {
                if (resp) {
                    clearData();
                }
            });
    };

    const handleFormPicker = ({ name, value }) => setValue(name, value);

    const userCanEdit = (value) => {
        const id = value?.user?._id;
        if (id) {
            return value?.user?._id === userData.sub;
        }
        return true;
    };

    const renderRemarkMetadata = (field, value) => {
        if (field === 'user' && value) {
            return `${value.firstName} ${value.lastName}`;
        }
        if (field === 'updatedAt' && value) {
            return dayjs(value).format('DD-MM-YYYY HH:mm');
        }
        return '';
    };

    const setReminderNow = (index) => {
        const reminders = [...getValues('reminders')];
        reminders[index].date = dayjs().add(3, 'minute');
        setValue('reminders', reminders);
    };

    const contactWithOptionLabel = ({ value, typeName }) => `${value} (${typeName})`;
    const contactSrc = (index) => {
        const reminder = watchReminders[index];
        if (reminder?.entity) {
            return comboboxTypes.contactsByName(reminder.entity._id);
        }
        return comboboxTypes.empty();
    };

    return (
        <Paper className={styles.root}>
            <FormPickerControl onChange={handleFormPicker}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <FormHeader
                                label={edit ? messages.editHeader.id : messages.header.id}
                                values={edit ? { number: editData?.internalSignature } : null}
                                hideDelete={!edit}
                                onDelete={onDelete}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2 }}>
                            <Controller
                                name='concerns'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        label={intl.formatMessage(messages.concerns)}
                                        variant='outlined'
                                        size='medium'
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name='kind'
                                defaultValue={KIND_NAME.incomming}
                                control={control}
                                render={({ field }) => (
                                    <FormControl component='fieldset'>
                                        <RadioGroup {...field}>
                                            <FormControlLabel
                                                value={KIND_NAME.incomming}
                                                control={<Radio color='secondary' />}
                                                label={intl.formatMessage(messages.incomming)}
                                            />
                                            <FormControlLabel
                                                value={KIND_NAME.outcomming}
                                                control={<Radio color='secondary' />}
                                                label={intl.formatMessage(messages.outcomming)}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name='type'
                                control={control}
                                render={({ field }) => (
                                    <FormControl variant='outlined' fullWidth>
                                        <InputLabel htmlFor={intl.formatMessage({ id: messages.type.id })} required>
                                            <FormattedMessage id={messages.type.id} />
                                        </InputLabel>
                                        <Select
                                            label={intl.formatMessage({ id: messages.type.id })}
                                            renderValue={(selected) => (
                                                <div className={styles.select}>
                                                    {selected.typ}
                                                </div>
                                            )}
                                            {...field}
                                        >
                                            {types.map((type) => (
                                                <MenuItem key={type.id} value={type}>
                                                    {type.typ}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name='journal'
                                control={control}
                                render={({ field }) => (
                                    <UniversalCombobox
                                        src={comboboxTypes.correspondenceContractors()}
                                        showColors
                                        required
                                        label={intl.formatMessage(messages.journal)}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name='fromTo'
                                control={control}
                                render={({ field }) => (
                                    <UniversalCombobox
                                        id='fromTo'
                                        src={comboboxTypes.contractors()}
                                        required
                                        label={intl.formatMessage(
                                            formData.kind === KIND_NAME.incomming
                                                ? messages.fromWho
                                                : messages.toWhom
                                        )}
                                        {...field}
                                        displayInfo
                                        showColors
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name='foreignSignature'
                                control={control}
                                render={({ field }) => (
                                    <FormPickerElement name='foreignSignature'>
                                        <TextField
                                            label={intl.formatMessage(messages.signature)}
                                            variant='outlined'
                                            size='medium'
                                            fullWidth
                                            {...field}
                                        />
                                    </FormPickerElement>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name='address'
                                control={control}
                                render={({ field }) => (
                                    !formData.type || formData.type.typ.toLowerCase() !== 'mail' ? (
                                        <UniversalCombobox
                                            disabled={!formData.fromTo}
                                            label={intl.formatMessage(messages.address)}
                                            src={comboboxTypes.addresses(formData.fromTo?._id || '')}
                                            freeSolo
                                            {...field}
                                        />
                                    ) : (
                                        <UniversalCombobox
                                            disabled={!formData.fromTo}
                                            label={intl.formatMessage(messages.addressEmail)}
                                            src={comboboxTypes.contractorEmails(formData.fromTo?._id || '')}
                                            freeSolo
                                            {...field}
                                        />
                                    )
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Controller
                                name='leader'
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <FormControl variant='outlined' fullWidth>
                                        <InputLabel htmlFor={intl.formatMessage(messages.leader)} required>
                                            <FormattedMessage id={messages.leader.id} />
                                        </InputLabel>
                                        <Select
                                            label={intl.formatMessage(messages.leader)}
                                            renderValue={(selected) => (
                                                <div className={styles.select}>
                                                    {selected.firstName}
                                                    {' '}
                                                    {selected.lastName}
                                                </div>
                                            )}
                                            {...field}
                                        >
                                            {leaders.map((item) => (
                                                <MenuItem key={item.id} value={item}>
                                                    {`${item.firstName} ${item.lastName}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                label={intl.formatMessage(messages.author)}
                                variant='outlined'
                                fullWidth
                                disabled
                                value={editData?.author?.$name || (!edit ? userData?.name : null)}
                                sx={{ input: { color: editData?.author?.color, WebkitTextFillColor: 'unset !important' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name='date'
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <CustomDatePicker
                                        disableToolbar
                                        variant='outlined'
                                        inputFormat={dateFormat.format}
                                        required
                                        margin='normal'
                                        okLabel={intl.formatMessage({ id: messages.confirm.id })}
                                        cancelLabel={intl.formatMessage({ id: messages.cancel.id })}
                                        todayLabel={intl.formatMessage({ id: messages.today.id })}
                                        showTodayButton
                                        label={intl.formatMessage(messages.date)}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name='dateInOut'
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <CustomDatePicker
                                        disableToolbar
                                        variant='outlined'
                                        inputFormat={dateFormat.format}
                                        required
                                        margin='normal'
                                        okLabel={intl.formatMessage(messages.confirm)}
                                        cancelLabel={intl.formatMessage(messages.cancel)}
                                        todayLabel={intl.formatMessage(messages.today)}
                                        showTodayButton
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        label={intl.formatMessage(
                                            formData.kind === KIND_NAME.incomming
                                                ? messages.dateOfIncome
                                                : messages.dateOfOutcome
                                        )}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='description'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        label={intl.formatMessage(messages.description)}
                                        variant='outlined'
                                        size='medium'
                                        fullWidth
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component='h2' variant='h6' className={styles.divider} align='left'>
                                <FormattedMessage id={messages.remarks.id} />
                            </Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} alignItems='center'>
                            <Controller
                                name='remarks'
                                control={control}
                                render={({ field }) => (
                                    <ListItems
                                        onNewItem={() => defaultRemarks(userData)}
                                        canBeRemoved={userCanEdit}
                                        buttonsPosition='top'
                                        render={(fields, _index, value) => (
                                            <Grid
                                                container
                                                sx={value?.user?.color ? {
                                                    borderColor: value?.user?.color, borderRadius: 1, borderWidth: 2, borderStyle: 'solid', p: 1, m: 0
                                                } : { p: 1, m: 0 }}
                                                alignItems='center'
                                            >

                                                <Grid container sx={{ textAlign: 'left' }}>
                                                    <Grid item xs={6}>
                                                        <Typography variant='caption'>
                                                            {`${intl.formatMessage(messages.author)}: ${renderRemarkMetadata('user', value.user)}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant='caption'>
                                                            {`${intl.formatMessage(messages.updatedAt)}: ${renderRemarkMetadata('updatedAt', value.updatedAt)}`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{ mt: 1 }}>
                                                    <Grid item xs={2}>
                                                        <UniversalCombobox
                                                            src={comboboxTypes.remarkTypes()}
                                                            label={intl.formatMessage(messages.type)}
                                                            id='type'
                                                            disabled={!userCanEdit(value)}
                                                            required
                                                            disableClearable
                                                            optionLabel={(title) => title?.$name?.slice(0, 3)}
                                                            {...fields('type')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <TextField
                                                            disabled={!userCanEdit(value)}
                                                            required
                                                            fullWidth
                                                            multiline
                                                            variant='outlined'
                                                            label={intl.formatMessage(messages.remarks)}
                                                            {...fields('value')}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        )}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion sx={{ p: 0 }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
                                    <div className={styles.accordionSummary}>
                                        <Typography component='h2' variant='h6' className={styles.divider} align='left'>
                                            <FormattedMessage id={messages.reminder.id} />
                                        </Typography>
                                        <Typography variant='body2'>{formData?.reminders?.length || 0}</Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ p: 0 }}>
                                    <Controller
                                        name='reminders'
                                        control={control}
                                        render={({ field }) => (
                                            <ListItems
                                                canBeRemoved={(value) => !value._isSent}
                                                newItem={defaultReminder}
                                                buttonsPosition='top'
                                                sortFn={(_a, _b) => (new Date(_b.date) - new Date(_a.date))}
                                                render={(fields, index) => (
                                                    <Grid container alignItems='center'>
                                                        <Grid item xs={4}>
                                                            <UniversalCombobox
                                                                required
                                                                displayInfo
                                                                src={comboboxTypes.entitiesContactsByName()}
                                                                showColors
                                                                label={intl.formatMessage(messages.reminderEntity)}
                                                                disabled={fields('_isSent')?.value}
                                                                {...fields('entity')}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <UniversalCombobox
                                                                required
                                                                src={contactSrc(index)}
                                                                label={intl.formatMessage(messages.reminderContact)}
                                                                optionLabel={contactWithOptionLabel}
                                                                disabled={!watchReminders[index]?.entity || fields('_isSent')?.value}
                                                                {...fields('value')}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <DateTimePicker
                                                                required
                                                                disabled={fields('_isSent')?.value}
                                                                disablePast
                                                                ampm={false}
                                                                showTodayButton
                                                                variant='outlined'
                                                                inputFormat={dateFormat.dateWithHoursAndMinutes}
                                                                margin='normal'
                                                                okLabel={intl.formatMessage(messages.dateOkButton)}
                                                                cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                                                todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                                                renderInput={(params) => (<TextField fullWidth {...params} />)}
                                                                {...fields('date')}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Tooltip title={intl.formatMessage(messages.reminderNowHint)}>
                                                                <Button
                                                                    onClick={() => setReminderNow(index)}
                                                                    disabled={fields('_isSent')?.value}
                                                                >
                                                                    <FormattedMessage id={messages.reminderNow.id} />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {...field}
                                            />
                                        )}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12} sm={11}>
                            {
                                !openNewThreadDialog && (
                                    <Controller
                                        name='threadId'
                                        control={control}
                                        render={({ field }) => (
                                            <UniversalCombobox
                                                id='threadId'
                                                src={comboboxTypes.correspondenceThreads()}
                                                label={intl.formatMessage(messages.thread)}
                                                {...field}
                                                displayInfo
                                                showColors
                                                optionLabel={(option) => option.name}
                                            />
                                        )}
                                    />
                                )
                            }

                        </Grid>
                        <Grid item xs={12} sm={1} className={styles.centerAlign}>
                            <IconButton
                                onClick={
                                    () => { setOpenNewThreadDialog(true); }
                                }
                                size='large'
                            >
                                <AddIcon fontSize='small' />
                            </IconButton>
                        </Grid>
                        <Grid container className={styles.buttons}>
                            <Grid item xs={6}>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    fullWidth
                                    onClick={clearData}
                                >
                                    <FormattedMessage id={messages.clear.id} />
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    disabled={isFormInvalid()}
                                >
                                    {edit ? <FormattedMessage id={messages.save.id} /> : <FormattedMessage id={messages.add.id} />}
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </form>
            </FormPickerControl>
            <AddNewThread
                open={openNewThreadDialog}
                onClose={() => setOpenNewThreadDialog(false)}
            />
        </Paper>
    );
}

CorrespondenceForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onClear: PropTypes.func,
    edit: PropTypes.bool,
    editData: PropTypes.object,
    isFileNameEmpty: PropTypes.bool
};

CorrespondenceForm.defaultProps = {
    onDelete: () => {},
    onClear: () => {},
    edit: false,
    editData: {},
    isFileNameEmpty: false
};

export default CorrespondenceForm;
