import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import restRequestsHelper from '../../../../../lib/restRequestsHelper';
import { HotkeyProvider } from '../../../../common/Hotkeys/Hotkeys';
import FormPickerMaster from '../../../../common/FormPicker/FormPickerMaster';
import DocumentsPreview from '../../../../common/InvoicePreview/DocumentsPreview';

const messages = {
    invoiceData: {
        id: 'app.duplicatedDialog.invoiceData'
    },
    addInvoice: {
        id: 'app.addInvoice.header'
    },
    contractor: {
        id: 'app.addInvoice.contractor'
    },
    documentNumber: {
        id: 'app.addInvoice.documentNumber'
    },
    buyer: {
        id: 'app.addInvoice.buyer'
    },
    content: {
        id: 'app.addInvoice.content'
    },
    dateOfInvoice: {
        id: 'app.addInvoice.dateOfInvoice'
    },
    dateOfPayment: {
        id: 'app.addInvoice.dateOfPayment'
    },
    dateOfDelivery: {
        id: 'app.addInvoice.dateOfDelivery'
    },
    bankAccount: {
        id: 'app.addInvoice.bankAccount'
    },
    amount: {
        id: 'app.addInvoice.amount'
    },
    saleDate: {
        id: 'app.addInvoice.saleDate'
    },
    seller: {
        id: 'app.addInvoice.seller'
    },
    mediaInvoice: {
        id: 'app.addInvoice.mediaInvoice'
    },
    forceAdd: {
        id: 'app.addInvoice.forceAdd'
    },
    cancel: {
        id: 'app.addInvoice.delete'
    },
    fileFamily: { id: 'app.correspondence.show.fileFamily' }

};
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    button: {
        margin: 'auto'
    },
    root: {
        marginLeft: '10px',
        width: '50%'
    },
    cancel: {
        marginLeft: theme.spacing(3)
    },
    drawer: {
        width: '55vw',
        display: 'flex'
    },
    drawerData: {
        width: '30%',
        padding: 0
    },
    preview: {
        width: '70%',
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    list: {
        width: '100%'
    }
}));

function DuplicatedInvoiceDialog({
    open, close, invoiceData, forceAdd, onCancel
}) {
    const classes = useStyles();
    const intl = useIntl();
    const [files, setFiles] = useState([]);
    const [fileNumber, setFileNumber] = useState(0);
    const [selectedFileFamily, setSelectedFileFamily] = useState('1');
    const getFiles = () => files.filter((item) => item.family === selectedFileFamily);
    const getFileFamilies = () => {
        const result = [];

        if (invoiceData.illustrations) {
            invoiceData.illustrations.forEach((item) => {
                if (result.find((family) => family === item.id_rodzina) === undefined) {
                    result.push(item.id_rodzina);
                }
            });
        }
        return result;
    };
    useEffect(() => {
        const families = getFileFamilies();
        if (families.length > 0) {
            setSelectedFileFamily(families[0]);
        }
    }, [invoiceData]);

    const handleChangeFamily = (event) => {
        setFileNumber(0);
        setSelectedFileFamily(event.target.value);
    };

    useEffect(() => {
        const downloadFiles = async (filesArray) => {
            const filesurl = [];
            for (let i = 0; i < filesArray.length; i += 1) {
                const item = filesArray[i];
                const fileData = { name: item.nazwa, family: item.id_rodzina };
                try {
                    const resultFile = await restRequestsHelper.getFile(item.id);
                    filesurl.push({ ...fileData, file: resultFile, error: false });
                } catch (err) {
                    filesurl.push({
                        ...fileData, file: null, error: err.code !== 550
                    });
                }
            }
            setFiles(filesurl);
        };
        if (invoiceData.illustrations) {
            downloadFiles(invoiceData.illustrations);
        }
    }, [invoiceData]);
    const handleClose = () => {
        close();
    };

    const handleForceAdd = () => {
        forceAdd();
    };

    return (
        <div>
            <Drawer
                anchor='right'
                open={open}
                onClose={handleClose}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge='start'
                            color='inherit'
                            onClick={handleClose}
                            aria-label='close'
                            size='large'
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant='h6' className={classes.title}>
                            <FormattedMessage id={messages.invoiceData.id} />
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Paper className={classes.drawer}>
                    <Grid container className={classes.drawerData}>
                        <Grid container item xs={12}>
                            <List className={classes.list}>
                                <ListItem>
                                    <ListItemText
                                        primary={intl.formatMessage({ id: messages.seller.id })}
                                        secondary={invoiceData.seller}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={intl.formatMessage({ id: messages.buyer.id })}
                                        secondary={invoiceData.buyer}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={intl.formatMessage({ id: messages.documentNumber.id })}
                                        secondary={invoiceData.documentNumber}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={intl.formatMessage({ id: messages.dateOfDelivery.id })}
                                        secondary={moment(invoiceData.dateOfDelivery).format('YYYY-MM-DD')}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={intl.formatMessage({ id: messages.dateOfInvoice.id })}
                                        secondary={moment(invoiceData.dateOfInvoice).format('YYYY-MM-DD')}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={intl.formatMessage({ id: messages.dateOfPayment.id })}
                                        secondary={moment(invoiceData.dateOfPayment).format('YYYY-MM-DD')}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={intl.formatMessage({ id: messages.saleDate.id })}
                                        secondary={moment(invoiceData.sellDate).format('YYYY-MM-DD')}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <ListItemText
                                        primary={intl.formatMessage({ id: messages.amount.id })}
                                        secondary={invoiceData.amount / 100}
                                    />
                                </ListItem>
                                <ListItem>
                                    {getFileFamilies().length > 0 && (
                                        <Grid item xs={12}>
                                            <FormControl variant='outlined' className={classes.formControl}>
                                                <InputLabel id='family'>{intl.formatMessage({ id: messages.fileFamily.id })}</InputLabel>
                                                <Select
                                                    labelId='family'
                                                    id='familyItems'
                                                    value={selectedFileFamily}
                                                    onChange={handleChangeFamily}
                                                    label={intl.formatMessage({ id: messages.fileFamily.id })}
                                                >
                                                    {getFileFamilies().map((item) => <MenuItem value={item}>{item}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}
                                </ListItem>
                                <ListItem>
                                    <Button
                                        className={classes.button}
                                        onClick={handleForceAdd}
                                        color='primary'
                                        variant='contained'
                                    >
                                        <FormattedMessage id={messages.forceAdd.id} />
                                    </Button>
                                    {onCancel != null ? (
                                        <Button
                                            onClick={onCancel}
                                            color='secondary'
                                            variant='contained'
                                            className={classes.cancel}
                                        >
                                            <FormattedMessage id={messages.cancel.id} />
                                        </Button>
                                    ) : null}
                                </ListItem>

                            </List>
                        </Grid>
                    </Grid>

                    <div className={classes.preview}>
                        <FormPickerMaster enable>
                            <HotkeyProvider>
                                <DocumentsPreview
                                    file={getFiles()[fileNumber]}
                                    fileNumber={fileNumber}
                                    setFileNumber={setFileNumber}
                                    filesNumber={getFiles().length}
                                    manyFiles
                                    previewOnly
                                />
                            </HotkeyProvider>
                        </FormPickerMaster>
                    </div>
                </Paper>
            </Drawer>
        </div>
    );
}
DuplicatedInvoiceDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    invoiceData: PropTypes.object.isRequired,
    forceAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func
};
DuplicatedInvoiceDialog.defaultProps = {
    onCancel: null
};
export default DuplicatedInvoiceDialog;
