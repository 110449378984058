import defaultInvoice from './InvoiceDefault';

export default function invoiceReducer(state, action) {
    if (action === 'clean') {
        return defaultInvoice();
    }
    switch (action.field) {
        case 'contractor':
            return { ...state, contractor: action.value };
        case 'documentNumber':
            return { ...state, documentNumber: action.value };
        case 'buyer':
            return { ...state, buyer: action.value };
        case 'seller':
            return { ...state, seller: action.value };
        case 'content':
            return { ...state, content: action.value };
        case 'dateOfDelivery':
            return { ...state, dateOfDelivery: action.value };
        case 'dateOfInvoice':
            return { ...state, dateOfInvoice: action.value };
        case 'dateOfPayment':
            return { ...state, dateOfPayment: action.value };
        case 'saleDate':
            return { ...state, saleDate: action.value };
        case 'bankAccount':
            return { ...state, bankAccount: action.value };
        case 'amount':
            return { ...state, amount: action.value };
        case 'isMediaInvoice':
            return { ...state, isMediaInvoice: action.value };
        case 'group':
            return { ...state, group: action.value };
        case 'setNames':
            return { ...state, setNames: action.value };
        case 'flowStatus':
            return { ...state, flowStatus: action.value };
        default:
            return { ...state, ...action.value };
    }
}
