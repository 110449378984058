import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { google, ics } from 'calendar-link';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useSnackbar } from 'notistack';
import { renderCellExpand } from '../../../../../common/DataGridCells/GridCellExpand';
import TaskStatus from '../../../../../common/TaskStatus/TaskStatus';
import generateInitials from '../../../../../../lib/generateInitials';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import AdditionalOptionsColumn from '../../../../../common/AdditionalOptionsColumn/AdditionalOptionsColumn';

const messages = {
    number: { id: 'app.tasks.number' },
    name: { id: 'app.tasks.name' },
    hierarchy: { id: 'app.tasks.hierarchy' },
    content: { id: 'app.tasks.content' },
    concerns: { id: 'app.tasks.concerns' },
    status: { id: 'app.tasks.status' },
    category: { id: 'app.tasks.category' },
    subcategory: { id: 'app.tasks.subcategory' },
    industry: { id: 'app.tasks.industry' },
    leader: { id: 'app.tasks.leader' },
    producers: { id: 'app.tasks.producers' },
    dueDate: { id: 'app.tasks.dueDate' },
    addToCalendar: { id: 'app.tasks.addToCalendar' },
    share: { id: 'app.tasks.share' },
    parentTaskTooltip: { id: 'app.tasks.parentTaskTooltip' },
    subtaskTooltip: { id: 'app.tasks.subtaskTooltip' },
    applicantData: { id: 'app.addTask.applicantData' },
    delete: { id: 'app.tasks.delete' },
    confirmDeleteTitle: { id: 'app.tasks.confirmDeleteTitle' },
    confirmDeleteText: { id: 'app.tasks.confirmDeleteText' },
    deleteSuccess: { id: 'app.tasks.deleteSuccess' },
    copy: { id: 'app.tasks.copy' },
    copySuccess: { id: 'app.tasks.copySuccess' }
};

const useStyles = makeStyles((theme) => ({
    producersList: {
        paddingLeft: theme.spacing(4),
        margin: '7px 0'
    },
    hierarchyContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    subtasks: {
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

function createProducersList(producersArr, className) {
    const producersListItems = producersArr.map((producer) => <li>{producer}</li>);
    return <ul className={className}>{producersListItems}</ul>;
}

function HierarchyCell({ subtasksNumber, parent }) {
    const intl = useIntl();
    const styles = useStyles();
    return (
        <div className={styles.hierarchyContainer}>
            {!!subtasksNumber && (
                <Tooltip title={intl.formatMessage({ id: messages.subtaskTooltip.id })}>
                    <div className={styles.subtasks}>
                        {subtasksNumber}
                    </div>
                </Tooltip>
            ) }
            {
                parent && (
                    <Tooltip title={intl.formatMessage({ id: messages.parentTaskTooltip.id })}>
                        <ArrowUpwardIcon />
                    </Tooltip>
                )
            }
        </div>
    );
}
HierarchyCell.propTypes = {
    subtasksNumber: PropTypes.number.isRequired,
    parent: PropTypes.string.isRequired
};

const AdditionalOptionsCell = React.memo(({ params, handleUpdate }) => {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const [err, setErr] = useState(false);

    const shareTask = (resRow) => {
        window.navigator
            .share({
                title: `${resRow?.concerns} - ${resRow?.number} - ${resRow?.name}`,
                text: resRow.content
            });
    };

    const copyTask = async (resRow) => {
        const { data } = await RestRequestsHelper.copyTask(resRow.id);
        if (data.success) {
            enqueueSnackbar(intl.formatMessage(messages.copySuccess), { variant: 'success' });
            handleUpdate();
        }
    };

    const checkIfWebShareApiSupported = () => 'share' in window.navigator;
    const defaultPolicyFn = () => true;

    const additionalOptions = [
        {
            title: intl.formatMessage(messages.addToCalendar, { name: 'Apple' }),
            type: 'calendar',
            fn: ics,
            policyFn: defaultPolicyFn
        },
        {
            title: intl.formatMessage(messages.addToCalendar, { name: 'Google' }),
            type: 'calendar',
            fn: google,
            policyFn: defaultPolicyFn
        },
        {
            title: intl.formatMessage(messages.share),
            type: 'share',
            fn: shareTask,
            policyFn: checkIfWebShareApiSupported
        },
        {
            title: intl.formatMessage(messages.copy),
            type: 'copy',
            fn: copyTask,
            policyFn: defaultPolicyFn
        }
    ];
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleEventDate = (dueDate) => {
        let date;
        if (dueDate) {
            date = new Date(dueDate);
            date.setHours(date.getHours() + 1);
        } else {
            date = new Date();
        }
        return date;
    };
    const onClick = (exportFn, type) => {
        try {
            setAnchorEl(null);
            const { api } = params;
            const row = api.getSelectedRows();
            const res = [];
            row.forEach((val, key) => {
                res.push({ region: key, value: val });
            });
            const resRow = res[0].value;

            if (type === 'calendar') {
                const event = {
                    title: `${resRow.number} - ${resRow?.name || ''}`,
                    description: resRow.content,
                    start: handleEventDate(resRow.due_date),
                    allDay: true,
                    location: resRow?.concerns?.toString() || ''
                };

                const exported = exportFn(event);
                if (exported.startsWith('http')) {
                    window.open(exported, '_blank');
                } else {
                    try {
                        const aElm = document.createElement('a');
                        aElm.setAttribute('download', event.title);
                        aElm.setAttribute('href', exported);
                        aElm.click();
                    } catch {
                        window.open(exported, '_blank');
                    }
                }
            } else {
                exportFn(resRow);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            setErr(true);
        }
    };
    if (err) {
        return null;
    }
    return (
        <AdditionalOptionsColumn
            onIconClick={handleClick}
            onItemClick={onClick}
            options={additionalOptions}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
        />
    );
});

const useColumns = (handleUpdate) => {
    const intl = useIntl();
    const styles = useStyles();
    return [
        {
            field: 'number',
            headerName: intl.formatMessage(messages.number),
            width: 180,
            headerAlign: 'center',
            csvExport: true,
            renderCell: ({ value }) => renderCellExpand({ value, showPointer: true })
        },
        {
            field: 'hierarchy',
            headerName: intl.formatMessage(messages.hierarchy),
            width: 80,
            headerAlign: 'center',
            sortable: false,
            csvExport: false,
            renderCell: ({ value }) => HierarchyCell(value)
        },
        {
            field: 'concerns',
            headerName: intl.formatMessage(messages.concerns),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            csvExport: true,
            renderCell: ({ row }) => renderCellExpand({ data: row.concerns })
        },
        {
            field: 'applicantData',
            headerName: intl.formatMessage(messages.applicantData),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            csvExport: true,
            renderCell: ({ row }) => renderCellExpand({ data: row?.applicantData })
        },
        {
            field: 'name',
            headerName: intl.formatMessage(messages.name),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            csvExport: true,
            renderCell: renderCellExpand
        },
        {
            field: 'content',
            headerName: intl.formatMessage(messages.content),
            width: 200,
            flex: 2,
            headerAlign: 'center',
            csvExport: true,
            renderCell: renderCellExpand
        },
        {
            field: 'leader',
            headerName: intl.formatMessage(messages.leader),
            flex: 0.5,
            headerAlign: 'center',
            csvExport: true,
            renderCell: ({ value: { name, color } }) => renderCellExpand({ value: name }, color, (val) => generateInitials(val))
        },
        {
            field: 'industry',
            headerName: intl.formatMessage(messages.industry),
            width: 180,
            flex: 1,
            headerAlign: 'center',
            sortable: false,
            csvExport: true,
            renderCell: (params) => renderCellExpand(params)
        },
        {
            field: 'dueDate',
            headerName: intl.formatMessage(messages.dueDate),
            width: 140,
            headerAlign: 'center',
            csvExport: true,
            renderCell: renderCellExpand
        },
        {
            field: 'status',
            headerName: intl.formatMessage(messages.status),
            minWidth: 120,
            flex: 0.5,
            headerAlign: 'center',
            csvExport: true,
            renderCell: (value) => (
                <TaskStatus status={value.row.status} showValueAtTooltip valueField='description' />
            )
        },
        {
            field: 'producers',
            headerName: intl.formatMessage(messages.producers),
            valueGetter: (params) => params.value.length,
            width: 80,
            headerAlign: 'center',
            sortable: false,
            csvExport: true,
            renderCell: (value) => renderCellExpand({
                value: value.formattedValue.toString(),
                alt: createProducersList(value.row.producers, styles.producersList),
                showOnlyAlt: true
            })
        },
        {
            field: 'additionalOptions',
            headerName: ' ',
            width: 60,
            headerAlign: 'center',
            sortable: false,
            csvExport: false,
            renderCell: (params) => <AdditionalOptionsCell params={params} handleUpdate={handleUpdate} />
        }
    ];
};

AdditionalOptionsCell.propTypes = {
    params: PropTypes.object.isRequired,
    handleUpdate: PropTypes.func.isRequired
};

export default useColumns;
