import React from 'react';
import { makeStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import CSVExportData from '../../../../../common/DataGridToolbar/CSVExportData';
import usePageTitle from '../../../../../common/DataGridToolbar/usePageTitle';
import { TOOLBAR_SELECT } from '../../data/incomesConfig';
import ToolbarMassRemoval from '../../../../../common/DataGridToolbar/ToolbarMassRemoval';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import ToolbarNoSelect from './ToolbarNoSelect';

const useStyles = makeStyles(() => ({
    toolbar: {
        display: 'flex'
    }
}));

const messages = {
    generateIncomes: { id: 'app.incomes.generateIncomes' },
    generateAll: { id: 'app.incomes.generateAll' }
};
function IncomeCustomToolbar({
    exportFiltersText, handleDownloadClick,
    download, dataDownload, headers,
    option, setOption, mutate, selectedRows,
    setSelectedRows, filterData, user, handleGenerateIncomeFiles
}) {
    const classes = useStyles();
    const intl = useIntl();
    const loaders = useSelector((state) => state.loaders);

    return (
        <div className={classes.toolbar}>
            <GridToolbarContainer>
                <Tooltip
                    title={intl.formatMessage({ id: messages.generateAll.id })}
                >
                    <LoadingButton
                        loading={loaders.fileDownloading && loaders.downloadId === undefined}
                        disabled={loaders.fileDownloading}
                        onClick={handleGenerateIncomeFiles}
                    >
                        {intl.formatMessage({ id: messages.generateIncomes.id })}
                    </LoadingButton>
                </Tooltip>
                <CSVExportData
                    onClick={handleDownloadClick}
                    download={download}
                    headers={headers}
                    filename={usePageTitle(exportFiltersText)}
                    data={dataDownload}
                />
                {(() => {
                    switch (option) {
                        case TOOLBAR_SELECT.massRemoval:
                            return (
                                <ToolbarMassRemoval
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    setOption={setOption}
                                    mutate={mutate}
                                    filterData={filterData}
                                    user={user}
                                    defaultOptionId={TOOLBAR_SELECT.noSelect}
                                    deleteRecordFn={(val) => RestRequestsHelper.deleteInvoiceIncome(val)}
                                    deleteRecordsFn={(val) => RestRequestsHelper.deleteInvoicesIncomes(val, 2)}
                                />
                            );
                        case TOOLBAR_SELECT.positionsUngrouped:
                            return null;
                        case TOOLBAR_SELECT.noSelect:
                        default:
                            return (
                                <ToolbarNoSelect setOption={setOption} />
                            );
                    }
                })()}
            </GridToolbarContainer>
        </div>
    );
}

IncomeCustomToolbar.propTypes = {
    exportFiltersText: PropTypes.string.isRequired,
    handleDownloadClick: PropTypes.func.isRequired,
    download: PropTypes.bool.isRequired,
    dataDownload: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    option: PropTypes.number.isRequired,
    setOption: PropTypes.func.isRequired,
    selectedRows: PropTypes.array.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
    filterData: PropTypes.object,
    user: PropTypes.object.isRequired,
    handleGenerateIncomeFiles: PropTypes.func.isRequired
};

IncomeCustomToolbar.defaultProps = {
    filterData: {}
};

export default IncomeCustomToolbar;
