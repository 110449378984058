import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFieldArray } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import { useIntl, FormattedMessage } from 'react-intl';
import Accordion from '@mui/material//Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import RemoveIcon from '@mui/icons-material/Remove';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import dateFormat from '../../../../../../../constants/dateFormat';
import useCounterTypes from '../../../../hooks/useCounterTypes';
import CounterTypes from '../CounterTypes/CounterTypes';
import IngredientsModeInput from '../IngredientsModesInput/IngredientsModeInput';
import useInfrastructuresModes from '../../../../hooks/useInfrastructuresModes';
import ChargedValues from '../../../../../../../constants/ChargedValues';
import userRoles from '../../../../../../../constants/UserRoles';
import CustomDatePicker from '../../../../../../common/CustomDatePicker/CustomDatePicker';
import Norms from './ui/Norms/Norms';
import Rates from './ui/Rates/Rates';

const messages = {
    number: 'app.infrastructures.counter.number',
    legalisationDate: 'app.infrastructures.counter.legalizationDate',
    dateOkButton: 'app.invoiceForm.OkButton',
    dateCancelButton: 'app.invoiceForm.CancelButton',
    dateTodayButton: 'app.invoiceForm.todayButton',
    norms: 'app.infrastructures.counter.norms',
    rates: 'app.infrastructures.rates',
    readings: 'app.infrastructures.readings',
    remote: 'app.infrastructures.remote',
    charged: 'app.infrastructures.charged',
    tenant: 'app.infrastructures.tenant',
    owner: 'app.infrastructures.owner'
};

const useStyles = makeStyles((theme) => ({
    accordion: {
        width: '100%'
    },
    main: {
        width: '100%'
    },
    addButton: {
        marginTop: theme.spacing(5),
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    counter: {
        border: 'solid',
        borderColor: theme.palette.grey[500],
        borderRadius: '12px',
        padding: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    charged: {
        width: '100%'
    },
    accordionSummary: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));
function Counters({
    control, register, disableChargedOptionFn, readOnly
}) {
    const classes = useStyles();
    const intl = useIntl();
    const user = useSelector((state) => state.user);
    const {
        fields, append, remove
    } = useFieldArray({
        control,
        name: 'ingredients'
    });
    const { counterTypes, loading: loadingTypes } = useCounterTypes();
    const { modes, loading: loadingModes } = useInfrastructuresModes();
    const handleRemove = (index) => {
        remove(index);
    };
    const expandCounters = () => {
        append({
            number: '',
            mode: null,
            type: null,
            legalizationDate: null,
            norms: [],
            rates: [],
            remote: false,
            readings: false

        });
    };
    const getTranslationById = (id) => {
        let result;
        Object.keys(ChargedValues).forEach((item) => {
            if (ChargedValues[item] === Number(id)) {
                result = messages[item.toLowerCase()];
            }
        });
        return result;
    };
    return (
        <div className={classes.main}>
            {!loadingTypes && !loadingModes && (
                <>
                    {fields.map((field, index) => (
                        <Grid container spacing={1} className={classes.counter} key={field.id}>
                            <Grid container item xs={5}>
                                <CounterTypes control={control} name={`ingredients.${index}.type`} options={counterTypes} readOnly={readOnly} required />
                            </Grid>
                            <Grid container item xs={4}>
                                <TextField
                                    label={intl.formatMessage({ id: messages.number })}
                                    disabled={readOnly}
                                    {...register(`ingredients.${index}.number`)}
                                />
                            </Grid>
                            <Grid container item xs={2}>
                                <Controller
                                    name={`ingredients.${index}.legalizationDate`}
                                    control={control}
                                    defaultValue={null}
                                    render={({ field: fieldDate }) => (
                                        <CustomDatePicker
                                            disabled={readOnly}
                                            disableToolbar
                                            inputFormat={dateFormat.format}
                                            toolbarTitle='Od'
                                            label={intl.formatMessage({ id: messages.legalisationDate })}
                                            showTodayButton
                                            fullWidth
                                            okLabel={intl.formatMessage({ id: messages.dateOkButton })}
                                            cancelLabel={intl.formatMessage({ id: messages.dateCancelButton })}
                                            todayLabel={intl.formatMessage({ id: messages.dateTodayButton })}
                                            renderInput={(params) => <TextField {...params} />}
                                            {...fieldDate}
                                        />
                                    )}
                                />
                            </Grid>
                            {
                                !readOnly && (
                                    <Grid item xs={12} sm={1}>
                                        <IconButton
                                            onClick={
                                                () => { handleRemove(index); }
                                            }
                                            size='large'
                                        >
                                            <RemoveIcon fontSize='small' />
                                        </IconButton>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12}>
                                <IngredientsModeInput control={control} name={`ingredients.${index}.mode`} options={modes} readOnly={readOnly} required />
                            </Grid>
                            <Grid container item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Controller
                                            name={`ingredients.${index}.readings`}
                                            control={control}
                                            render={(dataCheckbox) => (
                                                <Checkbox
                                                    {...dataCheckbox.field}
                                                    checked={dataCheckbox.field.value}
                                                    onChange={(event) => dataCheckbox.field.onChange(event.target.checked)}
                                                    color='secondary'
                                                    disabled={readOnly}
                                                />
                                            )}
                                        />
                                    )}
                                    label={intl.formatMessage({ id: messages.readings })}
                                />

                            </Grid>
                            <Grid container item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Controller
                                            name={`ingredients.${index}.remote`}
                                            control={control}
                                            render={(dataCheckbox) => (
                                                <Checkbox
                                                    {...dataCheckbox.field}
                                                    checked={dataCheckbox.field.value}
                                                    onChange={(event) => dataCheckbox.field.onChange(event.target.checked)}
                                                    color='secondary'
                                                    disabled={readOnly}
                                                />
                                            )}
                                        />
                                    )}
                                    label={intl.formatMessage({ id: messages.remote })}
                                />

                            </Grid>
                            <Grid container item xs={4}>
                                <FormControlLabel
                                    className={classes.charged}
                                    control={(
                                        <Controller
                                            name={`ingredients.${index}.charged`}
                                            control={control}
                                            defaultValue={ChargedValues.Owner}
                                            render={(checkedData) => (
                                                <FormControl fullWidth>
                                                    <InputLabel><FormattedMessage id={messages.charged} /></InputLabel>
                                                    <Select
                                                        renderValue={(val) => intl.formatMessage({ id: getTranslationById(val) })}
                                                        disabled={readOnly}
                                                        {...checkedData.field}
                                                    >
                                                        {Object.keys(ChargedValues).map((item, indexCharged) => (
                                                            <MenuItem
                                                                key={indexCharged}
                                                                value={ChargedValues[item]}
                                                                disabled={disableChargedOptionFn(item)}
                                                            >
                                                                <FormattedMessage id={messages[item.toLowerCase()]} />
                                                            </MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>
                                            )}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid container item xs={12}>
                                <Accordion className={classes.accordion}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <div className={classes.accordionSummary}>
                                            <FormattedMessage id={messages.norms} />
                                            <Typography variant='body2'>{field?.norms?.length || 0}</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Norms ind={index} control={control} readOnly={readOnly} />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid container item xs={12}>
                                <Accordion className={classes.accordion}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <div className={classes.accordionSummary}>
                                            <FormattedMessage id={messages.rates} />
                                            <Typography variant='body2'>{field?.rates?.length || 0}</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Rates ind={index} control={control} readOnly={readOnly} />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {user?.role === userRoles.admin && (
                                <Grid container item xs={12}>
                                    <TextField value={field?._id} label='ID' disabled fullWidth sx={{ mt: 1 }} />
                                </Grid>
                            )}
                        </Grid>
                    ))}
                    {
                        !readOnly && (
                            <Grid item xs={12} sm={12}>
                                <IconButton
                                    className={classes.addButton}
                                    onClick={expandCounters}
                                    color='primary'
                                    component='span'
                                    size='large'
                                >
                                    <Add />
                                </IconButton>
                            </Grid>
                        )
                    }
                </>
            )}
        </div>
    );
}

Counters.defaultProps = {
    disableChargedOptionFn: () => false,
    readOnly: false
};

Counters.propTypes = {
    control: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    disableChargedOptionFn: PropTypes.func,
    readOnly: PropTypes.bool
};

export default Counters;
