import useSWR from 'swr';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';

const useSettlements = (page, pageSize, filterData, sortModel, disable) => {
    const { data, error, mutate } = RestRequestsHelper.getSettlements(useSWR, filterData, sortModel, page, pageSize, disable) || {};

    return {
        settlements: data?.data || [],
        count: data?.count || 0,
        loading: !error && !data && !disable,
        mutate
    };
};

export default useSettlements;
