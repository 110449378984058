import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import { Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import RestRequestsHelper from '../../../../../lib/restRequestsHelper';
import useDisplaySnackbar from '../../../../../lib/displaySnackbar';
import ContractorForm from '../../../../common/ContractorForm/ContractorForm';

const messages = {
    editContractorSuccess: { id: 'app.contractors.editContractorSuccess' },
    editContractorError: { id: 'app.contractors.editContractorError' }
};

const useStyles = makeStyles(() => ({
    drawer: {
        width: '90vw',
        overflowX: 'hidden',
        textAlign: 'center'
    }
}));

function ContractorsSidebar({ contractor, onUpdate, onClose }) {
    const classes = useStyles();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });

    const handleSubmitForm = async (data) => {
        const { error } = await RestRequestsHelper.editContractor(data);
        onClose();
        if (error) {
            displaySnackbar('error', messages.editContractorError);
            return false;
        }
        displaySnackbar('success', messages.editContractorSuccess);
        onUpdate();
        return true;
    };

    return (
        <Drawer anchor='right' open={contractor} onClose={onClose}>
            <Paper className={classes.drawer}>
                <ContractorForm edit={contractor} onSubmit={handleSubmitForm} />
            </Paper>
        </Drawer>
    );
}

ContractorsSidebar.propTypes = {
    contractor: PropTypes.object,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func
};

ContractorsSidebar.defaultProps = {
    contractor: null,
    onUpdate: () => {},
    onClose: () => {}
};

export default ContractorsSidebar;
