import React from 'react';
import PropTypes from 'prop-types';
import formatMoney from '../../../lib/formatMoney';
import GridCellExpand from './GridCellExpand';

const parseFormattedMoney = (value) => {
    if (!value.length) return 0;
    const commas = value.match(/,/g)?.length ?? 0;
    const dots = value.match(/\./g)?.length ?? 0;
    // eslint-disable-next-line no-bitwise
    if (!!commas ^ !!dots) {
        return Number.parseFloat(value.replace(/[.,]/g, '.').replace(/[^\d\-.]/g, ''));
    }
    const asDot = dots === 1 ? /\./ : /,/;
    return Number.parseFloat(value.replace(dots === 1 ? /,/g : /\./g, '').replace(asDot, '.').replace(/[^\d\-.]/g, ''));
};

function GridMoneyCell({ value, width, color }) {
    return (
        <GridCellExpand
            value={formatMoney(value)}
            width={width}
            color={color}
        />
    );
}

GridMoneyCell.propTypes = {
    width: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string
};
GridMoneyCell.defaultProps = {
    color: ''
};

export default GridMoneyCell;
export {
    parseFormattedMoney
};
