import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import { FormattedMessage, useIntl } from 'react-intl';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import AcceptStepButton from './AcceptStepButton/AcceptStepButton';
import getErrorMessage from './ErrorsMessages/getErrorMessage';

const messages = {
    smsCode: {
        id: 'app.signUpForm.smsCode'
    },
    smsCodeError: {
        id: 'app.signUpError.smsCodeError'
    },
    nextStep: {
        id: 'app.signUpForm.nextStep'
    }
};

const checkSmsCode = (phone) => {
    const pattern = /^\d{6}$/;
    return pattern.test(phone);
};

function SmsCodeStep({ register, nextStep, getValue }) {
    const [error, setError] = useState('');
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const acceptSmsCode = async () => {
        const smsCode = getValue('smsCode');
        const isValid = checkSmsCode(smsCode);
        if (!isValid) {
            setError(intl.formatMessage({ id: messages.smsCodeError.id }));
            return;
        }

        const response = await RestRequestsHelper.validateSmsCode(smsCode);
        if (response?.success) {
            nextStep();
        } else {
            const errorCode = response?.error?.code;
            enqueueSnackbar(intl.formatMessage({ id: getErrorMessage(errorCode) }), { variant: 'error' });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        acceptSmsCode();
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='smsCode'
                label={intl.formatMessage({ id: messages.smsCode.id })}
                name='smsCode'
                autoFocus
                error={!!error}
                helperText={error}
                {...register('smsCode', {
                    onChange: () => {
                        if (error) {
                            setError('');
                        }
                    }
                })}
            />
            <AcceptStepButton>
                <FormattedMessage id={messages.nextStep.id} />
            </AcceptStepButton>
        </form>
    );
}

SmsCodeStep.propTypes = {
    register: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired
};

export default SmsCodeStep;
