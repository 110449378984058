/* eslint-disable */
import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import InfoComponentRenderer from './InfoComponents';

const useStyle = makeStyles(() => ({
    infoPopper: {
        padding: '5px',
        fontSize: '12px',
        '& ul': {
            padding: 0,
            margin: '2px',
            marginLeft: '15px'
        }
    }
}));

const comboboxInfoComponents = () => ({
    // Text component that accepts string values
    // as well as intl messages wrapped in a message object
    // eg. { id: '...' }.
    text(messages, sx) {
        return {
            type: 'text',
            data: messages,
            sx
        };
    },
    // List component that renders
    // subcomponents in a list
    list(items) {
        return {
            type: 'list',
            data: items
        };
    },
    // Dictionary component that renders 
    // key and values of given custom object
    dict(object) {
        return {
            type: 'dict',
            data: object || {}
        }
    },
    // Conditionally show data
    // if given condition is met
    showIf(condition, callbackItems = () => null) {
        return {
            type: 'show-if',
            data: condition ? callbackItems() : null
        }
    }
});

const InfoRenderer = ({ children, loading }) => {
    const classes = useStyle();
    return (
        <div className={classes.infoPopper}>
            {loading && <CircularProgress />}
            {!loading && (
                <InfoComponentRenderer components={children} />
            )}
        </div>
    );
};

InfoRenderer.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool.isRequired
};

InfoRenderer.defaultProps = {
    children: null
};

export default InfoRenderer;
export {
    comboboxInfoComponents
};
