import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider, useBus, useListener } from 'react-bus';
import config from './formConfig';

function FormPickerMasterControl({ children, enable }) {
    const bus = useBus();

    const eventEmit = (event) => {
        event.persist();
        bus.emit(config.bus.keydown, {
            altKey: event.altKey,
            ctrlKey: event.ctrlKey,
            shiftKey: event.shiftKey,
            key: event.key,
            prevent: event.preventDefault.bind(event)
        });
    };

    useEffect(() => {
        bus.emit(config.bus.enable, enable);
    }, []);

    useListener(config.bus.isEnabled, () => {
        bus.emit(config.bus.enable, enable);
    });

    return (
        <div
            role='button'
            tabIndex={0}
            onKeyDown={eventEmit}
        >
            {children}
        </div>
    );
}

function FormPickerMaster({ children, enable }) {
    return (
        <Provider>
            <FormPickerMasterControl enable={enable}>
                {children}
            </FormPickerMasterControl>
        </Provider>
    );
}

FormPickerMasterControl.propTypes = {
    children: PropTypes.node,
    enable: PropTypes.bool
};

FormPickerMasterControl.defaultProps = {
    children: null,
    enable: false
};

FormPickerMaster.propTypes = {
    children: PropTypes.node,
    enable: PropTypes.bool
};

FormPickerMaster.defaultProps = {
    children: null,
    enable: false
};

export default FormPickerMaster;
