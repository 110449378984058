import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import InfoRenderer, { comboboxInfoComponents } from './InfoRenderer';

// Renders little tooltip that shows more details
// about selected item inside of the UniversalCombobox
function InfoTooltip({
    children, value, fetcher, show, loading, multiple, index, open
}) {
    const [openInfo, setOpenInfo] = useState(false);
    const [data, setData] = useState(null);

    const isValueEmpty = () => {
        if (multiple) return !value || !value.length;
        return !value;
    };

    const setNewInfoData = (info) => {
        if (info && !isValueEmpty()) {
            if (multiple && !value[index]) return;
            const valueData = (multiple && value.length)
                ? value[index ?? 0]?._doc || value[index ?? 0]
                : value._doc || value;
            setData(info(valueData, comboboxInfoComponents()));
        }
    };
    // Traverse the info structure
    // and check if it's displayable
    const isInfoDisplayable = (info) => {
        if (!info) {
            return false;
        }
        if (info.length) {
            const nonDisplayable = info.every((item) => !isInfoDisplayable(item));
            return !nonDisplayable;
        }
        if (!info.data) {
            return false;
        }
        return true;
    };

    const handleShowInfo = () => {
        if (show && isInfoDisplayable(data)) {
            setOpenInfo(true);
        }
    };

    const handleHideInfo = () => {
        if (show) {
            setOpenInfo(false);
        }
    };

    useEffect(() => {
        setNewInfoData(fetcher);
    }, [value, fetcher, index]);

    return (
        <Tooltip
            disableInteractive
            placement='bottom'
            arrow
            open={openInfo && open}
            onOpen={handleShowInfo}
            onClose={handleHideInfo}
            title={<InfoRenderer loading={loading}>{data}</InfoRenderer>}
        >
            {children}
        </Tooltip>
    );
}
InfoTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.any,
    fetcher: PropTypes.func,
    show: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    multiple: PropTypes.bool.isRequired,
    index: PropTypes.number,
    open: PropTypes.bool.isRequired
};

InfoTooltip.defaultProps = {
    value: null,
    fetcher: null,
    index: 0
};

export default InfoTooltip;
