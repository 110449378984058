import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useSnackbar } from 'notistack';
import useDisplaySnackbar from '../../../../../../lib/displaySnackbar';
import RestRequests from '../../../../../../lib/restRequestsHelper';
import { TOOLBAR_SELECT } from '../../data/invoicesConfig';
import InvoiceStatusChangeDialog from '../InvoiceStatusChangeDialog';
import cleanUpSpecialChars from '../../../../../../lib/cleanUpSpecialChars';

const messages = {
    submit: { id: 'app.invoices.submit' },
    cancel: { id: 'app.invoices.cancel' },
    updateFlowStateSuccess: { id: 'app.invoices.updateFlowStateSuccess' },
    updateFlowStateError: { id: 'app.invoices.updateFlowStateError' }
};

function ElixirExport({
    selectedRows,
    setOption, invoices,
    setSelectedRows, mutateInvoices
}) {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });

    const [openDialog, setOpenDialog] = useState(false);
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);

    const getDataByModel = (model) => (
        model.map((id) => invoices.find((invoice) => invoice.id === id))
    );

    const handleExportSelect = (type) => () => {
        if (type === TOOLBAR_SELECT.noSelect) {
            setSelectedRows([]);
        }
        setOption(type);
    };

    const ensureValue = (item, empty = '', callback = (id) => id, config = {}) => {
        let result = item;
        if (!Number.isNaN(result)) {
            result = result.toString();
        }
        // eslint-disable-next-line no-param-reassign
        config = {
            separator: ',',
            newSeparator: ';',
            ignoreSymbols: '"',
            replaceSeparator: false,
            ...config
        };
        if (item === null || item === undefined) {
            return empty;
        }
        if (config.replaceSeparator) {
            result = result.replace(new RegExp(config.separator, 'g'), config.newSeparator);
        }
        [...config.ignoreSymbols].forEach((symbol) => {
            result = result.replace(new RegExp(symbol, 'g'), '');
        });
        return callback(result);
    };

    const hasDifferentBuyers = (rows) => {
        // Optimized for bigger datasets - no jump instructions used
        const firstItem = rows[0].nabywca;
        const allItems = rows.reduce((row, total) => row.nabywca + total);
        return firstItem * rows.length !== allItems;
    };

    const createCSVFile = (csvData) => (
        csvData.map((item) => [
            110,
            ensureValue(item.payment_date, '', (value) => dayjs.utc(value).format('YYYYMMDD')),
            ensureValue(item.total_gross_charges, 0, (value) => parseInt(value)),
            ensureValue(item.buyer_bank_account, '', (value) => value.replace(/\s+/g, '').slice(2, 10)),
            0,
            ensureValue(item.buyer_bank_account, '', (value) => value.replace(/\s+/g, '')),
            ensureValue(item.seller_bank_account, '', (value) => value.replace(/\s+/g, '')),
            ensureValue(item.buyer.map((elm) => elm.name).join(', '), '', (name) => name, { replaceSeparator: true }).slice(0, 35),
            ensureValue(item.seller.map((elm) => elm.name).join(', '), '', (name) => name, { replaceSeparator: true }).slice(0, 35),
            0,
            ensureValue(item.seller_bank_account, '', (value) => value.replace(/\s+/g, '').slice(2, 10)),
            ensureValue(item.foreign_document_number, '', (nr) => nr, { replaceSeparator: true }),
            '',
            '',
            51
        ].join(',')).join('\n')
    );

    const handleExportElixir = async () => {
        const rows = getDataByModel(selectedRows);
        const time = dayjs().format('YYYYMMDD-HHmmss');
        if (hasDifferentBuyers(rows)) {
            const zip = new JSZip();
            const elements = {};
            rows.forEach((row) => {
                const name = row.buyer.map((elm) => elm.name).join(', ');
                elements[name] = elements[name] ?? [];
                elements[name].push(row);
            });
            Object.entries(elements).forEach(([key, value]) => {
                zip.file(`${cleanUpSpecialChars(key)}.csv`, createCSVFile(value));
            });
            const total = Object.values(elements).map((value) => createCSVFile(value)).join('\n');
            zip.file('zbiorczy.csv', total);
            const blob = await zip.generateAsync({ type: 'blob' });
            saveAs(blob, `${time}-elixir.zip`);
        } else {
            const data = createCSVFile(rows);
            const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, `${time}-elixir.csv`);
        }
        setOpenDialog(true);
        setSelected(rows);
    };

    const handleCloseChangeDialog = () => {
        setOpenDialog(false);
        handleExportSelect(TOOLBAR_SELECT.noSelect)();
    };

    const handleSubmitChangeDialog = async () => {
        setLoading(true);
        for (let i = 0; i < selected.length; i += 1) {
            const row = selected[i];
            const { error, data } = await RestRequests.setPartiallyChangedInvoiceFlowState(row.id);
            if (error || data.status === 'error') {
                displaySnackbar('error', messages.updateFlowStateError);
                setLoading(false);
                return;
            }
        }
        setLoading(false);
        mutateInvoices();
        displaySnackbar('success', messages.updateFlowStateSuccess);
        handleExportSelect(TOOLBAR_SELECT.noSelect)();
        setOpenDialog(false);
    };

    return (
        <>
            <InvoiceStatusChangeDialog
                open={openDialog}
                onClose={handleCloseChangeDialog}
                onSubmit={handleSubmitChangeDialog}
                loading={loading}
            />
            <Button color='primary' size='small' onClick={handleExportElixir} disabled={!selectedRows.length}>
                {intl.formatMessage(messages.submit)}
            </Button>
            <Button color='primary' size='small' onClick={handleExportSelect(TOOLBAR_SELECT.noSelect)}>
                {intl.formatMessage(messages.cancel)}
            </Button>
        </>
    );
}

ElixirExport.propTypes = {
    selectedRows: PropTypes.array.isRequired,
    invoices: PropTypes.array.isRequired,
    setOption: PropTypes.func.isRequired,
    setSelectedRows: PropTypes.func.isRequired,
    mutateInvoices: PropTypes.func.isRequired
};

export default ElixirExport;
