import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from '@mui/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import RestRequestsHelper from '../../../../lib/restRequestsHelper';
import NormRates from '../../../../constants/NormRates';
import useCounterTypes from '../hooks/useCounterTypes';
import useMeasureUnits from '../hooks/useMeasureUnits';
import useDisplaySnackbar from '../../../../lib/displaySnackbar';
import CounterTypes from '../EditInfrastructure/InfrastructureForm/ui/CounterTypes/CounterTypes';
import MeasureUnits from '../EditInfrastructure/InfrastructureForm/ui/MeasureUnits/MeasureUnits';
import MoneyTextField from '../../../common/MoneyTextField/MoneyTextField';
import dateFormat from '../../../../constants/dateFormat';
import CustomDatePicker from '../../../common/CustomDatePicker/CustomDatePicker';
import getCurrentDate from '../../../../lib/getCurrentDate';
import SelectedInfrastructureDisplay from './ui/SelectedInfrastructureDisplay';

const messages = {
    addButton: { id: 'app.menu.add' },
    cancelButton: { id: 'app.menu.cancel' },
    addNormsRates: { id: 'app.infrastructures.addNormsRates' },
    value: { id: 'app.infrastructures.normRatesValue' },
    norm: { id: 'app.infrastructures.norm' },
    rate: { id: 'app.infrastructures.rate' },
    date: { id: 'app.infrastructures.date' },
    updateSuccess: { id: 'app.infrastructures.updateNormRatesSuccess' },
    updateError: { id: 'app.infrastructures.updateNormRatesError' },
    addNormRatesInfo: { id: 'app.infrastructures.addNormRatesInfo' },
    addNormRatesToAllInfo: { id: 'app.infrastructures.addNormRatesToAllInfo' }
};

function AddNormRates({
    parentID, open, onClose, selectedNames, colType
}) {
    const defaultValues = {
        type: NormRates.NORM,
        counterType: null,
        unit: null,
        value: null,
        date: getCurrentDate()
    };
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const displaySnackbar = useDisplaySnackbar({ intl, enqueueSnackbar });
    const {
        control, reset, handleSubmit
    } = useForm({ defaultValues });
    const { counterTypes } = useCounterTypes();
    const { measureUnits } = useMeasureUnits();

    const addingType = [
        {
            label: intl.formatMessage(messages.norm),
            value: NormRates.NORM
        },
        {
            label: intl.formatMessage(messages.rate),
            value: NormRates.RATE
        }
    ];

    const handleAdd = async (formData) => {
        setLoading(true);
        const {
            type, counterType, unit, value, date
        } = formData;
        const data = {
            type,
            counterType,
            unit,
            value: value.replace(/\s/g, ''),
            date,
            parentID
        };
        const result = await RestRequestsHelper.setNormRatesGlobally(data);
        setLoading(false);
        if (result?.data.success) {
            displaySnackbar('success', messages.updateSuccess);
            reset(defaultValues);
            onClose();
        } else {
            displaySnackbar('error', messages.updateError);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
        >
            <form onSubmit={handleSubmit(handleAdd)}>
                <DialogTitle id='form-dialog-title'>
                    {intl.formatMessage(messages.addNormsRates)}
                </DialogTitle>
                <DialogContent
                    /* Workaround for clipped TextField label https://github.com/mui/material-ui/issues/29892 */
                    sx={{ pt: `${theme.spacing(2)} !important` }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <SelectedInfrastructureDisplay
                                data={selectedNames}
                                colType={colType}
                                addingInfo={intl.formatMessage({ id: messages.addNormRatesInfo.id })}
                                addingAllInfo={intl.formatMessage({ id: messages.addNormRatesToAllInfo.id })}
                            />
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name='type'
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                    >
                                        <Grid container>
                                            {
                                                addingType.map((type) => (
                                                    <Grid item xs={4} key={type.label}>
                                                        <FormControlLabel
                                                            label={type.label}
                                                            value={type.value}
                                                            control={<Radio />}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CounterTypes
                                required
                                control={control}
                                name='counterType'
                                options={counterTypes || []}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MeasureUnits
                                control={control}
                                name='unit'
                                required
                                options={measureUnits || []}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name='value'
                                render={({ field }) => (
                                    <MoneyTextField
                                        textFieldParams={{
                                            label: intl.formatMessage(messages.value),
                                            fullWidth: true,
                                            name: 'value',
                                            required: true
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='date'
                                control={control}
                                defaultValue={null}
                                render={({ field: fieldDate }) => (
                                    <CustomDatePicker
                                        inputFormat={dateFormat.format}
                                        label={intl.formatMessage(messages.date)}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                fullWidth
                                                {...params}
                                            />
                                        )}
                                        {...fieldDate}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        onClick={onClose}
                        color='primary'
                    >
                        <FormattedMessage id={messages.cancelButton.id} />
                    </Button>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        color='secondary'
                        loading={loading}
                    >
                        <FormattedMessage id={messages.addButton.id} />
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}

AddNormRates.propTypes = {
    parentID: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedNames: PropTypes.object.isRequired,
    colType: PropTypes.number.isRequired
};

export default AddNormRates;
