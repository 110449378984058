import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UniversalCombobox, { comboboxTypes } from '../../../../../common/Combobox/UniversalCombobox';
import IdentitySelect, { selectTypes } from '../../../../../common/IdentitySelect/IdentitySelect';
import dateFormat from '../../../../../../constants/dateFormat';
import RestRequestsHelper from '../../../../../../lib/restRequestsHelper';
import onKeyDetect from '../../../../../../lib/onKeyDetect';
import userRoles from '../../../../../../constants/UserRoles';
import CustomDatePicker from '../../../../../common/CustomDatePicker/CustomDatePicker';
import defaultFilter from '../data/inspectionsConfig';

const messages = {
    name: { id: 'app.inspections.name' },
    concerns: { id: 'app.inspections.concerns' },
    type: { id: 'app.inspections.type' },
    leader: { id: 'app.inspections.leader' },
    producers: { id: 'app.inspections.producers' },
    validFrom: { id: 'app.inspections.validFrom' },
    validTo: { id: 'app.inspections.validTo' },
    dateOkButton: { id: 'app.inspections.okButton' },
    dateCancelButton: { id: 'app.inspections.cancelButton' },
    dateTodayButton: { id: 'app.inspections.todayButton' },
    dateClearButton: { id: 'app.inspections.clearButton' },
    author: { id: 'app.inspections.author' }
};

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: theme.spacing(1)
    }
}));

function InspectionsFilter({
    onChange, concernFilter, setLoaded
}) {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const isUserInspections = query.has('userInspections');
    const user = useSelector((state) => state.user);
    const {
        control, watch, setValue
    } = useForm({ defaultValues: defaultFilter() });
    const intl = useIntl();
    const classes = useStyles();
    const watchAll = watch();

    // If we are on route '/inspections/browse?userInspections' then
    // add this current user to received field
    useEffect(() => {
        const asyncSetup = async () => {
            if (user && isUserInspections) {
                const { data } = await RestRequestsHelper.getUsers('', [userRoles.admin, userRoles.user]);
                const [match] = data.filter(({ _id }) => _id === user.sub);
                setValue('leader', [match]);
            }
        };
        asyncSetup();
    }, [user]);

    useEffect(() => {
        onChange({ ...watchAll });
    }, [watchAll]);

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (concernFilter.length) {
            setValue('concerns', concernFilter);
        }
    }, [JSON.stringify(concernFilter)]);

    return (
        <div className={classes.main}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Controller
                        name='concerns'
                        control={control}
                        render={({ field }) => (
                            <UniversalCombobox
                                src={comboboxTypes.communities()}
                                label={intl.formatMessage(messages.concerns)}
                                id='concerns'
                                multiple
                                showColors
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        name='name'
                        control={control}
                        render={({
                            field: {
                                onChange: onChangeField, name, ref, value
                            }
                        }) => (
                            <TextField
                                fullWidth
                                variant='outlined'
                                name={name}
                                defaultValue={value}
                                inputRef={ref}
                                label={intl.formatMessage(messages.name)}
                                onKeyDown={(event) => onKeyDetect(event, () => {
                                    onChangeField(event);
                                })}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='type'
                        control={control}
                        render={({ field }) => (
                            <IdentitySelect
                                id='type'
                                src={selectTypes.inspectionTypes}
                                label={intl.formatMessage(messages.type)}
                                multiple
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='validFrom'
                        control={control}
                        render={({ field }) => (
                            <CustomDatePicker
                                showTodayButton
                                clearable
                                variant='outlined'
                                inputFormat={dateFormat.format}
                                label={intl.formatMessage(messages.validFrom)}
                                okLabel={intl.formatMessage(messages.dateOkButton)}
                                cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                clearLabel={intl.formatMessage(messages.dateClearButton)}
                                renderInput={(params) => <TextField fullWidth {...params} name='validFrom' />}
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Controller
                        name='validTo'
                        control={control}
                        render={({ field }) => (
                            <CustomDatePicker
                                showTodayButton
                                clearable
                                variant='outlined'
                                inputFormat={dateFormat.format}
                                label={intl.formatMessage(messages.validTo)}
                                okLabel={intl.formatMessage(messages.dateOkButton)}
                                cancelLabel={intl.formatMessage(messages.dateCancelButton)}
                                todayLabel={intl.formatMessage(messages.dateTodayButton)}
                                clearLabel={intl.formatMessage(messages.dateClearButton)}
                                renderInput={(params) => <TextField fullWidth {...params} name='validTo' />}
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        name='producer'
                        control={control}
                        render={({ field }) => (
                            <UniversalCombobox
                                id='producer'
                                src={comboboxTypes.producers()}
                                label={intl.formatMessage(messages.producers)}
                                showColors
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        name='author'
                        control={control}
                        render={({ field }) => (
                            <UniversalCombobox
                                id='author'
                                src={comboboxTypes.users([userRoles.admin, userRoles.user])}
                                label={intl.formatMessage(messages.author)}
                                multiple
                                showColors
                                {...field}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        name='leader'
                        control={control}
                        render={({ field }) => (
                            <UniversalCombobox
                                id='leader'
                                src={comboboxTypes.users([userRoles.admin, userRoles.user])}
                                label={intl.formatMessage(messages.leader)}
                                showColors
                                multiple
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

InspectionsFilter.propTypes = {
    onChange: PropTypes.func,
    concernFilter: PropTypes.array,
    setLoaded: PropTypes.func
};

InspectionsFilter.defaultProps = {
    onChange: () => {},
    setLoaded: () => {},
    concernFilter: []
};

export default InspectionsFilter;
