const formatBankAccount = (value) => (
    (value || '')
        // Remove everything but numbers
        .replace(/[^0-9]/g, '')
        // Trim down to 26 digits
        .substring(0, 26)
        // Separate first two digits
        .replace(/^([0-9]{2})/, '$1 ')
        // Separate the rest 4 digits
        .replace(/([0-9]{4})/g, '$1 ')
        // Trim the last space
        .trim()
);
export default formatBankAccount;
