import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from '@mui/material';

const messages = {
    dataGridMessage: { id: 'app.payoffs.tally.dataGridMessage' }
};

export default function DataGridMessage() {
    return (
        <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'
        }}
        >
            <FormattedMessage id={messages.dataGridMessage.id} />
        </Box>
    );
}
