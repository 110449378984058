import React from 'react';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { useGridApiContext } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import dateFormat from '../../../constants/dateFormat';

function GridEditDateCell({
    id, field, value, colDef
}) {
    const apiRef = useGridApiContext();

    const Component = colDef.type === 'dateTime' ? DateTimePicker : DatePicker;

    const handleChange = (newValue) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <Component
            value={value}
            autoFocus
            onChange={handleChange}
            inputFormat={dateFormat.format}
            renderInput={(params) => <TextField required {...params} />}
        />
    );
}

GridEditDateCell.propTypes = {
    id: PropTypes.any.isRequired,
    field: PropTypes.object.isRequired,
    value: PropTypes.any.isRequired,
    colDef: PropTypes.object.isRequired
};

export default GridEditDateCell;
