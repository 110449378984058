import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ExposureIcon from '@mui/icons-material/Exposure';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { Stack, Tooltip } from '@mui/material';
import AddInfrastructure from '../../Pages/Infrastructures/AddInfrastructure/AddInfrastructure';
import INFRASTRUCTURE_TYPES from '../../../constants/infrastructureTypes';
import AddNormsRates from '../../Pages/Infrastructures/AddNormsRates/AddNormsRates';
import AddIngredients from '../../Pages/Infrastructures/Addingredients/AddIngredients';
import DisplayPremisePeople from './shared/ui/DisplayPremisePeople';

const messages = {
    summary: { id: 'app.infrastructures.summary' },
    addInfrastructure: { id: 'app.infrastructures.addInfrastructure' },
    addNormsRates: { id: 'app.infrastructures.addNormsRates' },
    addIngredients: { id: 'app.infrastructures.addIngredients' },
    addInfrastructureTooltip: { id: 'app.infrastructures.addInfrastructureTooltip' },
    addNormsRatesTooltip: { id: 'app.infrastructures.addNormsRatesTooltip' },
    itemsLengthTooltip: { id: 'app.infrastructures.itemsLengthTooltip' }
};

const useStyles = makeStyles((theme) => ({
    main: {
        flex: '1 1 0px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    list: {
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        flex: '1 1 0px'
    },
    title: {
        background: theme.palette.background.default,
        '&:hover > button': {
            display: 'inline-flex'

        }
    },
    loading: {
        alignSelf: 'center'
    },
    footer: {
        fontSize: '12px',
        padding: '15px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.default
    },
    summary: {
        fontSize: '13px'
    },
    orderIconHidden: {
        display: 'none',
        color: theme.palette.grey[500]
    }
}));

const sort = {
    none: 0,
    ascending: 1,
    descending: 2
};

function Column({
    properties, title, items, onSelect, loading, selectedItem, selectedParent, selectedNames, footer,
    onDoubleClick, type, onCreate, activeAddButtons
}) {
    const classes = useStyles();
    const [openAddInfrastructure, setOpenAddInfrastructure] = useState(false);
    const [openAddNormRates, setOpenAddNormRates] = useState(false);
    const [openAddIngredients, setOpenAddIngredients] = useState(false);
    const [order, setOrder] = useState(sort.none);

    const onClick = (item) => {
        onSelect(item);
    };

    const getItems = () => {
        if (order === sort.none) {
            return items;
        }

        return items.sort((first, second) => {
            if (order === sort.ascending) {
                return first.name.localeCompare(second.name);
            }
            return second.name.localeCompare(first.name);
        });
    };
    const handleChangeSortOrder = () => {
        const newOrder = order === sort.none || order === sort.descending ? sort.ascending : sort.descending;
        setOrder(newOrder);
    };

    const handleOpenAddItemDialog = () => {
        setOpenAddInfrastructure(true);
    };
    const handleOpenNormsRatesDialog = () => {
        setOpenAddNormRates(true);
    };
    const handleOpenAddIngredientsDialog = () => {
        setOpenAddIngredients(true);
    };
    const handleCloseAddDialog = () => {
        setOpenAddInfrastructure(false);
        onCreate();
    };

    const handleCloseNormsRatesDialog = () => {
        setOpenAddNormRates(false);
    };
    const handleCloseAddIngredientsDialog = () => {
        setOpenAddIngredients(false);
    };

    const getTooltip = () => {
        if (!selectedParent && !activeAddButtons) {
            return (
                <FormattedMessage
                    id={messages.addNormsRatesTooltip.id}
                />
            );
        }

        if (items.length === 0) {
            return (
                <FormattedMessage
                    id={messages.itemsLengthTooltip.id}
                />
            );
        }
        return (
            <FormattedMessage
                id={messages.addIngredients.id}
            />
        );
    };

    return (
        <div className={classes.main}>
            {loading ? <CircularProgress className={classes.loading} /> : (
                <List
                    className={classes.list}
                    sx={{
                        width: '100%', bgcolor: 'background.paper'
                    }}
                    subheader={(
                        <Grid container alignItems='center'>
                            <Grid item xs={8}>
                                <ListSubheader className={classes.title} component='div'>
                                    <span>{title}</span>
                                    <IconButton
                                        className={order === sort.none ? classes.orderIconHidden : undefined}
                                        onClick={handleChangeSortOrder}
                                    >
                                        {order === sort.ascending || order === sort.none ? (<ArrowUpwardIcon />) : (<ArrowDownwardIcon />)}
                                    </IconButton>
                                </ListSubheader>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack direction='row'>
                                    <Tooltip
                                        title={
                                            !selectedParent && !activeAddButtons
                                                ? (
                                                    <FormattedMessage
                                                        id={messages.addInfrastructureTooltip.id}
                                                    />
                                                )
                                                : (
                                                    <FormattedMessage
                                                        id={messages.addInfrastructure.id}
                                                    />
                                                )
                                        }
                                    >
                                        {/* Span is necessary here to display tooltip for disabled icon */}
                                        <span>
                                            <IconButton
                                                onClick={handleOpenAddItemDialog}
                                                disabled={!selectedParent && !activeAddButtons}
                                            >
                                                <AddBusinessIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip
                                        title={
                                            !selectedParent && !activeAddButtons
                                                ? (
                                                    <FormattedMessage
                                                        id={messages.addNormsRatesTooltip.id}
                                                    />
                                                )
                                                : (
                                                    <FormattedMessage
                                                        id={messages.addNormsRates.id}
                                                    />
                                                )
                                        }
                                    >
                                        <span>
                                            <IconButton
                                                onClick={handleOpenNormsRatesDialog}
                                                disabled={!selectedParent && !activeAddButtons}
                                            >
                                                <ExposureIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title={getTooltip()}>
                                        <span>
                                            <IconButton
                                                onClick={handleOpenAddIngredientsDialog}
                                                disabled={(!selectedParent && !activeAddButtons) || items.length === 0}
                                            >
                                                <AvTimerIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                        </Grid>
                    )}
                >
                    {getItems().map((item) => (
                        <ListItemButton
                            selected={item._id === selectedItem}
                            button
                            key={item._id}
                            onDoubleClick={() => {
                                onDoubleClick(item);
                            }}
                            onClick={() => onClick(item)}
                        >
                            <ListItemText primary={(
                                <Typography>
                                    {item.name}
                                    {type === INFRASTRUCTURE_TYPES.PREMISES && (
                                        <DisplayPremisePeople owners={item.owners || null} tenants={item.tenants || null} />
                                    )}
                                </Typography>
                            )}
                            />
                        </ListItemButton>
                    ))}

                </List>
            )}
            {loading || (
                <div className={classes.footer}>
                    {Object.keys(footer).length > 0 && (
                        <Typography variant='h6'>
                            <FormattedMessage
                                id={messages.summary.id}
                            />
                        </Typography>
                    )}
                    <Grid container spacing={1}>
                        {Object.keys(footer).map((key, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={8}>
                                    {properties[key]}
                                    :
                                </Grid>
                                <Grid item xs={4}>
                                    {key === '1' ? (footer[key] / 100) : footer[key]}
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </div>
            )}
            <AddInfrastructure
                open={openAddInfrastructure}
                onClose={handleCloseAddDialog}
                selectedNames={selectedNames}
                parentID={selectedParent}
                type={type}
            />
            <AddNormsRates
                open={openAddNormRates}
                onClose={handleCloseNormsRatesDialog}
                selectedNames={selectedNames}
                parentID={selectedParent}
                colType={type}
            />
            <AddIngredients
                open={openAddIngredients}
                type={type}
                selectedNames={selectedNames}
                onClose={handleCloseAddIngredientsDialog}
                parentID={selectedParent}
            />
        </div>
    );
}
Column.defaultProps = {
    onSelect: () => {},
    onDoubleClick: () => {},
    selectedParent: '',
    selectedNames: {},
    loading: false,
    activeAddButtons: false,
    footer: {},
    properties: {},
    type: null,
    onCreate: () => {}
};
Column.propTypes = {
    onDoubleClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    selectedItem: PropTypes.string.isRequired,
    selectedParent: PropTypes.string,
    selectedNames: PropTypes.object,
    onSelect: PropTypes.func,
    activeAddButtons: PropTypes.bool,
    loading: PropTypes.bool,
    properties: PropTypes.object,
    footer: PropTypes.object,
    type: PropTypes.number,
    onCreate: PropTypes.func
};
export default Column;
