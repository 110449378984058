import React, { useEffect } from 'react';
import {
    BrowserRouter, Route, Switch
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import {
    ThemeProvider, StyledEngineProvider, createTheme
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { plPL } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Header from './components/common/Header/Header';
import localePl from './lang/pl.json';
import localeEn from './lang/en.json';
import getTheme from './constants/theme';
import HomePage from './components/Pages/HomePage/HomePage';
import SignInPage from './components/Pages/SignInPage/SignInPage';
import SignUpPage from './components/Pages/SignUpPage/SignUpPage';
import AddInvoiceManually from './components/Pages/Invoices/AddInvoice/Manually/AddInvoiceManually';
import ImportInvoice from './components/Pages/Invoices/AddInvoice/ImportInvoice/ImportInvoice';
import SettingsPage from './components/Pages/SettingsPage/SettingsPage';
import Contractors from './components/Pages/Contractors/Contractors';
import Invoices from './components/Pages/Invoices/Invoices';
import AddCorrespondence from './components/Pages/Correspondence/AddCorrespondence';
import CorrespondencePage from './components/Pages/Correspondence/CorrespodencePage';
import TrashCommon from './components/Pages/ReportsPage/Trash/TrashCommon';
import TrashIndividual from './components/Pages/ReportsPage/Trash/TrashIndividual';
import MediaReadings from './components/Pages/ReportsPage/Media/MediaReadings';
import ImportBankStatements from './components/Pages/BankStatements/ImportBankStatements/ImportBankStatements';
import AddBankStatementsManually from './components/Pages/BankStatements/AddBankStatementsManually/AddBankStatementsManually';
import AddTask from './components/Pages/Tasks/AddTask/AddTask';
import Tasks from './components/Pages/Tasks/BrowseTasks/Tasks';
import Inspections from './components/Pages/Inspections/BrowseInspections/Inspections';
import AddInspection from './components/Pages/Inspections/AddInspection/AddInspection';
import Transactions from './components/Pages/Transactions/Transactions';
import './App.css';
import './config/day';
import AddReadings from './components/Pages/Media/AddReadings/AddReadings';
import BrowseReadings from './components/Pages/Media/BrowseReadings/BrowseReadings';
import Infrastructures from './components/Pages/Infrastructures/Infrastructures';
import About from './components/Pages/About/About';
import PayoffsMedia from './components/Pages/Payoffs/Media/PayoffsMedia';
import RoutesPermissions from './components/common/RoutesPermissions/RoutesPermissions';
import useShowConsoleInfo from './hooks/useShowConsoleInfo';
import AddIncome from './components/Pages/Income/AddIncome/AddIncome';
import Incomes from './components/Pages/Income/Incomes';
import defaultTaskStatusesFilter from './constants/defaultTaskStatusesFilter';
import defaultVindicationStatusesFilter from './constants/defaultVindicationStatusesFilter';
import AddContractors from './components/Pages/Contractors/AddContractors/AddContractors';
import AddIncomeManually from './components/Pages/Income/AddIncome/components/Manually/AddIncomeManually';
import Settlements from './components/Pages/Settlements/Settlements';
import Vindications from './components/Pages/Vindication/BrowseVindications/Vindications';
import AddVindication from './components/Pages/Vindication/AddVindication/AddVindication';
import Notifications from './components/Pages/Generators/Notifications/Notifications';
import PayoffsTally from './components/Pages/Payoffs/Tally/PayoffsTally';
import CorrespondenceList from './components/Pages/Generators/CorrespondenceList/CorrespondenceList';

const queryClient = new QueryClient();

const messages = {
    en: localeEn,
    pl: localePl
};

function App() {
    const locale = useSelector((state) => state.locale);
    const user = useSelector((state) => state.user);
    const theme = useSelector((state) => state.theme);
    const currentTheme = getTheme(theme);
    const resultTheme = createTheme(currentTheme, plPL);
    const showConsoleInfo = useShowConsoleInfo();
    useEffect(() => showConsoleInfo(), []);
    return (
        <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={resultTheme}>
                    <CssBaseline />
                    <div className='App'>
                        <IntlProvider messages={messages[locale]} locale={locale}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                <BrowserRouter>
                                    <Header />
                                    <Switch>
                                        <RoutesPermissions userRole={user?.role}>
                                            <Route path='/' component={HomePage} exact />
                                            <Route path='/signIn' component={SignInPage} />
                                            <Route path='/signUp' component={SignUpPage} />
                                            <Route path='/addInvoice/manually' component={AddInvoiceManually} />
                                            <Route path='/addInvoice/import' component={ImportInvoice} />
                                            <Route path='/settings' component={SettingsPage} />
                                            <Route path='/contractors/browse' component={Contractors} />
                                            <Route path='/contractors/add' component={AddContractors} />
                                            <Route path='/invoices/browse' component={Invoices} />
                                            <Route path='/correspondence/add' component={AddCorrespondence} />
                                            <Route path='/correspondence/browse' component={CorrespondencePage} />
                                            <Route path='/reports/trash/common' component={TrashCommon} />
                                            <Route path='/reports/trash/individual' component={TrashIndividual} />
                                            <Route path='/reports/media/readings' component={MediaReadings} />
                                            <Route path='/reports/settlements' component={Settlements} />
                                            <Route path='/bankStatements/import' component={ImportBankStatements} />
                                            <Route path='/bankStatements/addManually' component={AddBankStatementsManually} />
                                            <Route path='/bankStatements/browse' component={Transactions} />
                                            <Route path='/tasks/add' component={AddTask} />
                                            <Route path='/tasks/browse' render={() => <Tasks defaultStatusesFilter={defaultTaskStatusesFilter} />} />
                                            <Route path='/vindication/browse' render={() => <Vindications defaultStatusesFilter={defaultVindicationStatusesFilter} />} />
                                            <Route path='/vindication/add' component={AddVindication} />
                                            <Route path='/inspections/browse' render={() => <Inspections />} />
                                            <Route path='/inspections/add' component={AddInspection} />
                                            <Route path='/readings/add' component={AddReadings} />
                                            <Route path='/readings/browse' component={BrowseReadings} />
                                            <Route path='/infrastructures/browse' component={Infrastructures} />
                                            <Route path='/about' component={About} />
                                            <Route path='/payoffs/media' component={PayoffsMedia} />
                                            <Route path='/payoffs/tally' component={PayoffsTally} />
                                            <Route path='/income/add' component={AddIncome} />
                                            <Route path='/income/browse' component={Incomes} />
                                            <Route path='/income/addManually' component={AddIncomeManually} />
                                            <Route path='/generators/notifications' component={Notifications} />
                                            <Route path='/generators/correspondenceList' component={CorrespondenceList} />
                                        </RoutesPermissions>
                                    </Switch>
                                </BrowserRouter>
                            </LocalizationProvider>
                        </IntlProvider>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        </QueryClientProvider>
    );
}

export default App;
